import { Component } from "react";
import autoBind from "auto-bind/react";
import PropTypes from "prop-types";
import { cleanFileName } from "../../helpers/common";
import { formatDateTimeFilename } from "../../helpers/time";

import { withStyles } from "@material-ui/core/styles";
import { TextField, Typography, MenuItem } from "@material-ui/core";
import DialogWrapper from "../common/DialogWrapper";

import { networkKeys } from "./StudyNetworkInputs";

const styles = theme => {
  return {
    form: {
      display: "flex",
      margin: theme.spacing(1),
      flexWrap: "wrap",
    },
    formInput: {
      margin: theme.spacing(1),
      flexGrow: 1,
    },
    networkContainer: {
      display: "flex",
    },
    network: {
      maxWidth: 160,
      minWidth: 160,
    },
  };
};

class StudyExportDialog extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      network: "otn",
      errorNetwork: false,
      study: null,
    };
  }

  onEnter() {
    this.setState({
      study: this.props.study,
    });
  }

  getFileName() {
    return this.props.study
      ? cleanFileName(`${this.props.study.name}-${formatDateTimeFilename()}.zip`)
      : "";
  }

  validate() {
    let valid = true;

    // network is required:
    if (this.state.network === "") {
      valid = false;
      this.setState({ errorNetwork: true });
    }

    return valid;
  }

  changeNetwork(e) {
    const network = e.target.value;
    this.setState({ network: network, errorNetwork: false });
  }

  handleExport() {
    if (this.validate()) {
      // will export to their files and start download
      this.props.handleStudyExport(
        this.getFileName(),
        this.state.study.id,
        this.state.network,
        false
      );
      this.props.onClose();
    }
  }

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <DialogWrapper
        open={open}
        onEnter={this.onEnter}
        onClose={this.closeDialog}
        title="Export Study"
        okAction={this.handleExport}
        okButtonContent="Export"
        cancelAction={onClose}
      >
        <Typography paragraph>
          Your study data will be exported to a ZIP archive and will be saved to your Files as "
          {this.getFileName()}".
        </Typography>

        <form autoComplete="off" noValidate className={classes.form}>
          <div className={classes.networkContainer}>
            <TextField
              select
              defaultValue={"otn"}
              label={"Export Format"}
              helperText={this.state.errorNetwork ? "Export format is required" : ""}
              onChange={this.changeNetwork}
              error={this.state.errorNetwork}
              className={`${classes.formInput} ${classes.network}`}
              margin="dense"
            >
              {networkKeys.map(network => (
                <MenuItem key={network} value={network}>
                  {network.toUpperCase()}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </form>
      </DialogWrapper>
    );
  }
}

StudyExportDialog.propTypes = {
  handleStudyExport: PropTypes.func.isRequired,
  study: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(StudyExportDialog);
