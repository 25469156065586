import { Component } from "react";
import autoBind from "auto-bind/react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  createStudy,
  deleteStudy,
  updateStudy,
  exportStudyApi,
  querySummaryData,
} from "../redux/study/study-actions";
import history from "../helpers/history";
import StudyCard from "../components/study/StudyCard";
import StudyIcon from "../components/study/StudyIcon";
import StudyExportDialog from "../components/study/StudyExportDialog";
import StudyNetworkSubmitDialog from "../components/study/StudyNetworkSubmitDialog";
import StudyCopyDialog from "../components/study/StudyCopyDialog";
import { SpinnerInnovasea } from "../fathom-brella";
// material ui
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Tooltip, IconButton } from "@material-ui/core";
import StudyAddDialog from "../components/study/StudyAddDialog";
import StudyDeleteDialog from "../components/study/StudyDeleteDialog";
import { Add as IconAdd } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import BrowserWarning from "../components/top-level-dialogs/BrowserWarning";

const styles = theme => {
  return {
    studyContainer: {
      display: "flex",
      flexGrow: 1,
      width: "100%",
      flexDirection: "column",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: theme.spacing(10),
      paddingBottom: theme.spacing(2),
      justifyContent: "space-between",
      width: 800,
    },
    divider: {
      width: "100%",
      borderTop: "1.5px solid lightgray",
    },
    studySection: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  };
};

class Home extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      studyToDelete: null,
      studyToExport: null,
      studyIdToSubmit: null,
      addDialogOpen: false,
      studyToCopy: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedWorkspace !== prevProps.selectedWorkspace) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(querySummaryData());
  }

  /* Make a request to export a study.
   * Returns a promise that resolves when the study has been exported.
   * Rejects if study export fails. */
  handleStudyExport(filename, studyId, network, submitToNetwork) {
    return this.props.dispatch(exportStudyApi(filename, studyId, network, submitToNetwork));
  }

  onStudyAdd(input) {
    this.props.dispatch(createStudy(input));
    this.setState({ addDialogOpen: false });
  }

  startStudyDelete(studyToDelete) {
    this.setState({ studyToDelete });
  }

  deleteStudy(studyId) {
    this.props.dispatch(deleteStudy(studyId));
    this.setState({ studyToDelete: null });
  }

  startStudyExport(studyToExport) {
    this.setState({ studyToExport });
  }

  startStudySubmit(study) {
    this.setState({ studyIdToSubmit: study.id });
  }

  onStudyEdit(input) {
    this.props.dispatch(updateStudy(input));
  }

  copyStudy(studyToCopy) {
    this.setState({ studyToCopy });
  }

  render() {
    const {
      classes,
      studies,
      studiesLoaded,
      filesCount,
      devicesCount,
      animalsCount,
      deploymentsCount,
      studySelectedId,
      selectedWorkspace,
    } = this.props;
    const { addDialogOpen, studyToDelete, studyToExport, studyIdToSubmit, studyToCopy } =
      this.state;

    if (!studiesLoaded) {
      return (
        <div className={classes.studyContainer}>
          <SpinnerInnovasea />
        </div>
      );
    }

    return (
      <div className={classes.studyContainer}>
        <BrowserWarning />
        <div style={{ padding: 16 }}>
          <Typography variant="h5">{selectedWorkspace?.name} Workspace Summary</Typography>
          <div className={classes.flexRow}>
            <StudyIcon
              quantity={filesCount}
              icon={<DescriptionIcon style={{ fontSize: 64 }} />}
              title={"Files"}
              label={`View all the files`}
              onClick={() => history.push("/files")}
            />
            <StudyIcon
              quantity={devicesCount}
              imgSrc={`/img/receiver.svg`}
              title={"Devices"}
              label={`View all the devices`}
              onClick={() => history.push("/devices")}
            />
            <StudyIcon
              quantity={animalsCount}
              imgSrc={`/img/animals.svg`}
              title={"Animals"}
              label={`View all the animals`}
              onClick={() => history.push("/animals")}
            />
            <StudyIcon
              quantity={deploymentsCount}
              imgSrc={`/img/fathom-pin.png`}
              title={"Deployments"}
              label={`View all the deployments`}
              size={45}
              margin={8}
              onClick={() => history.push("/deployments")}
            />
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.studySection}>
          <Typography variant="h5">Studies</Typography>
          <Tooltip title={"Add new study"}>
            <IconButton onClick={() => this.setState({ addDialogOpen: true })}>
              <IconAdd />
            </IconButton>
          </Tooltip>
        </div>
        <div style={{ padding: 20, paddingTop: 0, paddingBottom: 120, overflowY: "auto" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            {studies.map(s => (
              <Grid key={s.id} item lg={3} md={4} sm={6} xs={12}>
                <StudyCard
                  study={s}
                  deleteStudy={this.startStudyDelete}
                  exportStudy={this.startStudyExport}
                  editStudy={this.onStudyEdit}
                  submitStudy={this.startStudySubmit}
                  copyStudy={this.copyStudy}
                  isSelected={studySelectedId === s.id}
                />
              </Grid>
            ))}
          </Grid>
        </div>
        <StudyAddDialog
          open={addDialogOpen}
          onClose={() => this.setState({ addDialogOpen: false })}
          onSubmit={this.onStudyAdd}
        />
        <StudyDeleteDialog
          open={Boolean(studyToDelete)}
          studyName={studyToDelete && studyToDelete.name}
          okAction={() => this.deleteStudy(studyToDelete.id)}
          cancelAction={() => this.setState({ studyToDelete: null })}
        />
        <StudyExportDialog
          open={Boolean(studyToExport)}
          study={studyToExport}
          handleStudyExport={this.handleStudyExport}
          onClose={() => this.setState({ studyToExport: null })}
        />
        <StudyNetworkSubmitDialog
          open={Boolean(studyIdToSubmit)}
          study={studies.find(s => s.id === studyIdToSubmit)}
          handleStudySubmit={this.handleStudyExport}
          onClose={() => this.setState({ studyIdToSubmit: null })}
        />
        <StudyCopyDialog
          open={Boolean(studyToCopy)}
          study={studyToCopy}
          onClose={() => this.setState({ studyToCopy: null })}
        />
      </div>
    );
  }
}

Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  studies: PropTypes.array.isRequired,
  studiesLoaded: PropTypes.bool.isRequired,
  filesCount: PropTypes.number.isRequired,
  devicesCount: PropTypes.number.isRequired,
  animalsCount: PropTypes.number.isRequired,
  deploymentsCount: PropTypes.number.isRequired,
};

const mapStateToProps = ({ study, workspaces }) => {
  return {
    studies: study.studies,
    studiesLoaded: study.isLoaded,
    studySelectedId: study.selectedId,
    filesCount: study.filesCount,
    devicesCount: study.devicesCount,
    animalsCount: study.animalsCount,
    deploymentsCount: study.deploymentsCount,
    selectedWorkspace: workspaces.selectedWorkspace,
  };
};

export default compose(withStyles(styles), connect(mapStateToProps))(Home);
