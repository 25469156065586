import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardActionArea,
  CardHeader,
  CardActions,
  IconButton,
  Tooltip,
  Collapse,
} from "@material-ui/core/";

import {
  Delete as IconDelete,
  Lock as IconLock,
  People as IconPeople,
  Person as IconPerson,
  RestoreFromTrash as IconRestoreFromTrash,
  Edit as IconEdit,
  ExpandMore as IconExpand,
} from "@material-ui/icons";

import IconLogout from "../common/IconLogout";
import DialogWrapper from "../common/DialogWrapper";
import WorkspaceUsers from "./WorkspaceUsers";

const useStyles = makeStyles(theme => ({
  workspace: {
    position: "relative",
    maxWidth: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  workspacePrimaryAction: {
    "&:hover": {
      boxShadow: `0px 0px 2px inset ${theme.palette.secondary.light}`,
    },
  },
  workspaceActionContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
  },
  workspaceDeleted: {
    backgroundColor: theme.palette.error.light,
    opacity: 0.5,
  },
  workspaceSummary: {
    paddingTop: 0,
    display: "flex",
  },
  shares: {
    display: "flex",
    cursor: "pointer",
  },
  shareIcon: {
    marginRight: theme.spacing(1),
  },
  expand: {
    marginLeft: theme.spacing(1),
    transition: "all 250ms ease-out",
  },
  expandCollapse: {
    transform: "rotate(180deg)",
  },
  membersExpanded: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}));

function WorkspaceCard({
  workspace,
  userId,
  editWorkspace,
  deleteWorkspace,
  selectWorkspace,
  restoreWorkspace,
  leaveWorkspace,
}) {
  const classes = useStyles();
  const isUserWorkspaceAdmin =
    workspace.users.filter(workspaceUser => workspaceUser.id === userId && workspaceUser.isOwner)
      .length > 0;

  const [confirmDeleteWorkspace, setConfirmDeleteWorkspace] = useState(false);
  const [cardFocused, setCardFocused] = React.useState(true);
  const [membersExpanded, setMembersExpanded] = React.useState(false);

  const onMouseOver = () => setCardFocused(false);
  const onMouseOut = () => setCardFocused(true);

  const actions = workspace.isDeleted
    ? [
        {
          icon: <IconRestoreFromTrash />,
          name: "Restore workspace",
          onClick: () => restoreWorkspace({ workspaceId: workspace.id }),
          disabled: !isUserWorkspaceAdmin,
          tooltip: isUserWorkspaceAdmin
            ? "Restore this workspace"
            : "Only workspace admins may restore this workspace",
        },
      ]
    : [
        {
          icon: <IconEdit />,
          name: "Edit workspace",
          onClick: () => editWorkspace(workspace.id),
          disabled: workspace.isPersonal || !isUserWorkspaceAdmin,
          tooltip: workspace.isPersonal
            ? "The personal workspace cannot be edited or deleted"
            : isUserWorkspaceAdmin
            ? "Edit this workspace"
            : "Only workspace admins may edit this workspace",
        },
        {
          icon: <IconDelete />,
          name: "Delete workspace",
          onClick: () => setConfirmDeleteWorkspace(true),
          disabled: workspace.isPersonal || !isUserWorkspaceAdmin,
          tooltip: workspace.isPersonal
            ? "The personal workspace cannot be edited or deleted"
            : isUserWorkspaceAdmin
            ? "Delete this workspace"
            : "Only workspace admins may delete this workspace",
        },
        {
          icon: <IconLogout />,
          name: "Leave workspace",
          onClick: () => leaveWorkspace(workspace.id),
          disabled: workspace.isPersonal,
          tooltip: workspace.isPersonal
            ? "You cannot leave your personal workspace"
            : "Leave this workspace",
        },
      ];

  const IconShare =
    workspace.isPersonal === true
      ? IconLock
      : workspace.users.length === 1
      ? IconPerson
      : IconPeople;

  const shareText =
    workspace.isPersonal === true
      ? "Private"
      : workspace.users.length === 1
      ? "Not shared"
      : `Shared with ${workspace.users.length - 1} others`;

  return (
    <>
      <Card
        raised={!cardFocused}
        className={classes.workspace}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <Tooltip
          title={
            workspace.isDeleted
              ? "Cannot enter a deleted workspace"
              : "Click to enter this workspace"
          }
        >
          <span>
            <CardActionArea
              centerRipple
              onClick={() => selectWorkspace(workspace.id)}
              className={
                workspace.isDeleted ? classes.workspaceDeleted : classes.workspacePrimaryAction
              }
              disabled={workspace.isDeleted}
            >
              <CardHeader title={workspace.name} subheader={workspace.description} />
              {/* This would be a great place for count of studies, deployments, etc
          <CardContent className={classes.workspaceSummary}>
          </CardContent> */}
            </CardActionArea>
          </span>
        </Tooltip>

        <CardActions className={classes.workspaceActionContainer}>
          <div
            className={`${classes.shares} ${membersExpanded && classes.sharesInExpanded}`}
            onClick={() => setMembersExpanded(!membersExpanded)}
          >
            <IconShare className={classes.shareIcon} />
            <Typography>{shareText}</Typography>
            <IconExpand
              className={`${classes.expand} ${membersExpanded ? classes.expandCollapse : ""}`}
            />
          </div>

          <div>
            {actions.map(action => (
              <Tooltip
                key={action.name}
                title={action.tooltip || action.name}
                aria-label={action.tooltip || action.name}
              >
                <span>
                  <IconButton
                    size="small"
                    onClick={() => {
                      action.onClick();
                    }}
                    disabled={action.disabled}
                  >
                    {action.icon}
                  </IconButton>
                </span>
              </Tooltip>
            ))}
          </div>
        </CardActions>

        <Collapse in={membersExpanded} classes={{ wrapperInner: classes.membersExpanded }}>
          {workspace.isPersonal ? (
            <Typography>* No other users can be added to your personal workspace *</Typography>
          ) : (
            <WorkspaceUsers userId={userId} workspace={workspace} isReadOnly={true} />
          )}
        </Collapse>
      </Card>

      <DialogWrapper
        open={confirmDeleteWorkspace}
        title={"Confirm delete"}
        okButtonContent={"Delete"}
        okAction={() => {
          deleteWorkspace({ workspaceId: workspace.id });
          setConfirmDeleteWorkspace(false);
        }}
        cancelAction={() => setConfirmDeleteWorkspace(false)}
      >
        Are you sure you want to delete this workspace? (this can be undone)
      </DialogWrapper>
    </>
  );
}

WorkspaceCard.propTypes = {
  workspace: PropTypes.object,
  userId: PropTypes.string,
  editWorkspace: PropTypes.func,
  deleteWorkspace: PropTypes.func,
  selectWorkspace: PropTypes.func,
  restoreWorkspace: PropTypes.func,
};

export default WorkspaceCard;
