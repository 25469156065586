import {
  WORKSPACES_SELECT,
  WORKSPACES_DONE_LOADING,
  WORKSPACES_SET_LIST,
  WORKSPACES_INCLUDE_DELETED,
  WORKSPACES_SET_CREATED_ID,
  WORKSPACES_QUERY_FAILED,
} from "../action-types";

const initialState = {
  loading: true,
  failed: false,
  workspaces: [],
  selectedWorkspace: null,
  includeDeleted: false,
  createdId: null,
};

export default function workspaceReducer(state = initialState, { type, payload }) {
  switch (type) {
    case WORKSPACES_SELECT: {
      return {
        ...state,
        loading: false,
        selectedWorkspace: state.workspaces.find(w => w.id === payload.workspaceId),
      };
    }
    case WORKSPACES_DONE_LOADING: {
      return {
        ...state,
        loading: false,
        failed: false,
      };
    }
    case WORKSPACES_SET_LIST: {
      return {
        ...state,
        workspaces: payload.workspaces.sort(workspaceSortFn),
      };
    }
    case WORKSPACES_INCLUDE_DELETED:
      return {
        ...state,
        includeDeleted: payload.includeDeleted,
      };
    case WORKSPACES_SET_CREATED_ID:
      return {
        ...state,
        createdId: payload.createdId,
      };
    case WORKSPACES_QUERY_FAILED:
      return {
        ...state,
        failed: true,
      };
    default:
      return state;
  }
}

// personal workspace always first, then sort by id
const workspaceRank = w => (w.isPersonal ? 0 : parseInt(w.id));
const workspaceSortFn = (a, b) => workspaceRank(a) - workspaceRank(b);
