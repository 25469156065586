import { DetectionAnalysisState, DetectionAnalysisAction } from "./detection-analysis-types";

const initialState: DetectionAnalysisState = {
  txList: [],
  rxList: [],
  error: null,
  data: null,
  dataStatus: "unloaded",
  dataRequestTimestamp: null,
};

export default function detDiagReducer(
  state: DetectionAnalysisState = initialState,
  action: DetectionAnalysisAction
): DetectionAnalysisState {
  switch (action.type) {
    case "DETECTION_ANALYSIS_SET_TXLIST":
      return { ...state, txList: action.payload.txList };

    case "DETECTION_ANALYSIS_SET_RXLIST":
      return { ...state, rxList: action.payload.rxList };

    case "DETECTION_ANALYSIS_START_LOADING_DATA":
      return { ...state, dataStatus: "loading", dataRequestTimestamp: action.payload.timestamp };

    case "DETECTION_ANALYSIS_FINISH_LOADING_DATA":
      return { ...state, data: action.payload.data, dataStatus: "loaded" };

    case "DETECTION_ANALYSIS_SET_DATA_ERROR": {
      const { timestamp, error } = action.payload;
      return timestamp === state.dataRequestTimestamp
        ? {
            ...state,
            dataStatus: "error",
            error: error,
          }
        : state;
    }

    case "DETECTION_ANALYSIS_CLEAR_DATA":
      return initialState;

    default:
      return state;
  }
}
