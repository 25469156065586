import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { toTitleCase } from "../../helpers/common";
const styles = {
  textLine: ({ dense }) => ({
    padding: dense ? undefined : "0.5em 0 0",
    display: "flex",
    justifyContent: "flex-start",
  }),
  textLabel: ({ alignCenter, alignPercent }) => ({
    width: alignCenter ? "50%" : alignPercent ? `${alignPercent}%` : "",
    textAlign: alignCenter || alignPercent ? "right" : "left",
    flexShrink: 0,
    fontWeight: "normal",
    whiteSpace: "nowrap",
  }),
  textValue: ({ normalFont }) => ({
    fontWeight: normalFont ? "normal" : "bold",
    padding: "0 0.5em",
  }),
};

function LabeledLine({ label, value, classes, titleCase }) {
  if (value === null || value === undefined) {
    return null;
  }

  return (
    <div className={classes.textLine}>
      <span className={classes.textLabel}>{label}: </span>
      <span className={classes.textValue}>{titleCase ? toTitleCase(value) : value} </span>
    </div>
  );
}

LabeledLine.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  alignCenter: PropTypes.bool,
  normalFont: PropTypes.bool,
  titleCase: PropTypes.bool,
  alignPercent: PropTypes.number,
};

export default withStyles(styles)(LabeledLine);
