import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export function setAppPath(appPath) {
  history.push(appPath);
}

export function removeQString(name) {
  const qParams = new URLSearchParams(history.search);
  qParams.delete(name);
  history.replace({
    search: qParams.toString() ? `?${qParams.toString()}` : null,
  });
}

export default history;
