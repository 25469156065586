import { ReactChild, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

type Props = {
  children: ReactChild | ReactChild[];
  /** Fills the width of the parent */
  fullWidth?: boolean;
  /** Fills the height of the parent */
  fullHeight?: boolean;
  /** Children are spaced evenly (overrides vAlign) */
  spaceBetween?: boolean;
  /** Horizontal alignment of children */
  hAlign?: "left" | "center" | "right";
  /** Vertical alignment of children */
  vAlign?: "top" | "center" | "bottom";
  /** Padding multiplier for theme.spacing  */
  paddingLevel?: number;
  /** Margin multiplier (around outside of container) for theme.spacing  */
  marginLevel?: number;
  /** Margin multiplier for space between direct child elements */
  itemSpacing?: number;
  /** Allow style overrides */
  className?: string;
  /** Elevation adds drop-shadow effect */
  elevation?: number;
  /** Rounded border or not */
  rounded?: boolean;
  /** Sets `overflow-y` to auto. NOTE: requires parent to have a deterministic height */
  autoScrollY?: boolean;
  /** Sets `overflow-x` to auto. NOTE: requires parent to have a deterministic width */
  autoScrollX?: boolean;
} & any;

const useStyles = makeStyles(
  theme =>
    ({
      col: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        padding: ({ paddingLevel }) => paddingLevel !== undefined && theme.spacing(paddingLevel),
        margin: ({ marginLevel }) => marginLevel !== undefined && theme.spacing(marginLevel),
        width: ({ fullWidth }) => fullWidth && "100%",
        height: ({ fullHeight }) => fullHeight && "100%",
        alignItems: ({ hAlign }) =>
          (hAlign === "left" && "flex-start") ||
          (hAlign === "center" && "center") ||
          (hAlign === "right" && "flex-end"),
        justifyContent: ({ vAlign, spaceBetween }) =>
          spaceBetween
            ? "space-between"
            : vAlign
            ? (vAlign === "top" && "flex-start") ||
              (vAlign === "center" && "center") ||
              (vAlign === "bottom" && "flex-end")
            : null,
        boxShadow: ({ elevation }) => elevation && theme.shadows[elevation],
        borderRadius: ({ rounded }) => rounded && 5,
        "& > *:not(:first-child)": {
          marginTop: ({ itemSpacing }) => itemSpacing !== undefined && theme.spacing(itemSpacing),
        },
        overflowY: ({ autoScrollY }) => (autoScrollY && "auto") || null,
        overflowX: ({ autoScrollX }) => (autoScrollX && "auto") || null,
      },
    } as any)
);

const FlexCol = forwardRef(function FlexCol(
  {
    children,
    fullWidth,
    fullHeight,
    spaceBetween,
    hAlign,
    vAlign,
    paddingLevel,
    marginLevel,
    itemSpacing,
    className = "",
    elevation = 0,
    rounded = false,
    autoScrollY,
    autoScrollX,
    ...props
  }: Props,
  ref
) {
  const classes: any = useStyles({
    fullWidth,
    fullHeight,
    spaceBetween,
    hAlign,
    vAlign,
    paddingLevel,
    marginLevel,
    itemSpacing,
    elevation,
    rounded,
    autoScrollY,
    autoScrollX,
  });
  return (
    <div className={`${classes.col} ${className}`} {...props} ref={ref}>
      {children}
    </div>
  );
});

export default FlexCol;
