import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import LabeledLine from "../common/LabeledLine";
import { formatMeasurement } from "./utils";
import MenuMultiButton from "../common/MenuMultiButton";
import { measurementSetFields } from "./constants";
import { formatDateTime } from "../../helpers/time";
import { Add as IconAdd } from "@material-ui/icons";
import { Card, CardContent, Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100%",
    paddingRight: theme.spacing(2),
  },
  measurementSet: {
    width: "100%",
    display: "flex",
    margin: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  measurementSetData: {
    width: "100%",
  },
  addSection: {
    display: "flex",
    flexDirection: "row",
    marginTop: 0,
    marginLeft: theme.spacing(3),
  },
  addButton: {
    display: "flex",
    flexDirection: "row",
    filter: "opacity(60%)",
    "&:hover": {
      filter: "opacity(100%)",
    },
    cursor: "pointer",
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
  },
  card: {
    width: "100%",
  },
});

const orderedFields = [
  "measurerName",
  "totalLength",
  "forkLength",
  "standardLength",
  "hoodLength",
  "width",
  "girth",
  "mass",
  "sex",
  "age",
  "lifeStage",
];

function AnimalMeasurements({
  measurementSets,
  classes,
  editMeasurementSet,
  deleteMeasurementSet,
  addMeasurementSet,
}) {
  return (
    <div className={classes.root}>
      {measurementSets.sort(measurementSetSortFn).map(measurementSet => {
        const time = measurementSet.time;
        const displayTime = time ? formatDateTime(time) : "Not specified";

        const measurementLines = orderedFields.map(field =>
          renderMeasurementLine(measurementSet[field], field)
        );

        return (
          <div key={measurementSet.id} className={classes.measurementSet}>
            <Card variant="outlined" className={classes.card}>
              <CardContent>
                <div className={classes.cardContent}>
                  <div className={classes.measurementSetData}>
                    <LabeledLine label="Time" value={displayTime} />
                    {measurementLines}
                  </div>
                  <MenuMultiButton
                    options={[
                      {
                        text: "Edit",
                        onClick: () => editMeasurementSet(measurementSet.id),
                      },
                      {
                        text: "Delete",
                        onClick: () => deleteMeasurementSet(measurementSet.id),
                      },
                    ]}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        );
      })}
      <div className={classes.addSection}>
        <div className={classes.addButton} onClick={() => addMeasurementSet()}>
          <IconAdd />
          <Typography> New Set </Typography>
        </div>
      </div>
    </div>
  );
}

function renderMeasurementLine(measurement, field) {
  const { label } = measurementSetFields[field];
  const value = measurement && measurement.unit ? formatMeasurement(measurement) : measurement;
  return <LabeledLine key={label} label={label} value={value} />;
}

function measurementSetSortFn(a, b) {
  return a.time && b.time ? b.time.localeCompare(a.time) : a.time ? -1 : 1;
}

AnimalMeasurements.propTypes = {
  measurementSets: PropTypes.array,
  editMeasurement: PropTypes.func,
  deleteMeasurementSet: PropTypes.func,
  editMeasurementSet: PropTypes.func,
  addMeasurementSet: PropTypes.func,
};

export default withStyles(styles)(AnimalMeasurements);
