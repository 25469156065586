import { useRef } from "react";
import * as d3 from "d3";
import { makeStyles } from "@material-ui/core/styles";
import { HOUR_IN_MS } from "../../../helpers/time";

import Chart from "./Chart";
import XAxisSandwich from "../../common/XAxisSandwich";
import Resizable from "../../common/Resizable";
import { MARGIN } from "./constants";

import { WorkspaceCountRecord, CountType } from "./types";

const X_AXIS_HEIGHTS: [number, number] = [20, 55];

const useStyles = makeStyles({
  main: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  chartsWrapper: {
    width: "100%",
    position: "relative",
  },
});

type Props = {
  height: number;
  width: number;
  yVariable: string;
  data: WorkspaceCountRecord[];
  varColorScale: d3.ScaleOrdinal<string, string>;
  varsToShow: CountType[];
  yStartAtZero: boolean;
};

function ChartWrapper({
  height,
  width,
  yVariable,
  data,
  varColorScale,
  varsToShow,
  yStartAtZero,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const domain = getTimeExtent(data);

  const innerChartWidth = width - MARGIN.left - MARGIN.right;

  const chartHeight = Math.max(height - d3.sum(X_AXIS_HEIGHTS), 0);

  const yScale = createYScale(data, varsToShow, chartHeight, yStartAtZero);

  const xScale = d3.scaleUtc().range([0, innerChartWidth]).domain(domain);

  return (
    <div className={classes.main} ref={ref}>
      <XAxisSandwich
        xScale={xScale}
        label="Date"
        marginLeft={MARGIN.left}
        xAxisHeights={X_AXIS_HEIGHTS}
      >
        <div style={{ height: chartHeight }} className={classes.chartsWrapper}>
          {yScale && (
            <Chart
              yVariable={yVariable}
              xScale={xScale}
              yScale={yScale}
              data={data}
              height={chartHeight}
              colorScale={varColorScale}
              varsToShow={varsToShow}
              width={width}
            />
          )}
        </div>
      </XAxisSandwich>
    </div>
  );
}

const TIME_PADDING = HOUR_IN_MS * 0;

function getTimeExtent(data: WorkspaceCountRecord[]): [Date, Date] {
  return [
    new Date(data[0].countDate.getTime() - TIME_PADDING),
    new Date(data[data.length - 1].countDate.getTime() + TIME_PADDING),
  ];
}

function createYScale(
  data: WorkspaceCountRecord[],
  varsToShow: CountType[],
  height: number,
  startAtZero: boolean
) {
  if (!data) {
    return null;
  }

  let yMax = 0;
  let yMin = Infinity;
  for (const record of data) {
    for (const key of varsToShow) {
      const yVal = record[key];
      if (yVal > yMax) yMax = yVal;
      if (yVal < yMin) yMin = yVal;
    }
  }

  const domain = startAtZero ? [0, yMax] : [yMin, yMax];

  return d3.scaleLinear().domain(domain).range([height, 0]);
}

export default Resizable(ChartWrapper);
