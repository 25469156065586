import React, { Component } from "react";
import autoBind from "auto-bind";
import { withStyles } from "@material-ui/core/styles";
import {
  CardContent,
  CardActions,
  TextField,
  Button,
  Link,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import LogoHeader from "./LogoHeader";
import ErrorBox from "./ErrorBox";
import { isEmailValid } from "../../helpers/common";

const styles = theme => ({
  root: {
    background: "top / cover no-repeat url(/img/fathom-landing-bg.png)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1),
  },
  textField: {
    minWidth: 250,
    marginBottom: theme.spacing(1.5),
  },
  middle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
  },
  errorBox: {
    backgroundColor: `${red[50]}`,
    borderRadius: 4,
    border: `1px solid ${red[600]}`,
    display: "flex",
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
});

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      fieldErrors: {
        email: "",
        password: "",
      },
      values: {
        email: "",
        password: "",
      },
    };
    autoBind(this);
  }

  onChange(value, field) {
    this.setState({ values: { ...this.state.values, [field]: value } });
  }

  validate(email, password) {
    let emailValid = true;
    let passValid = true;
    let fieldErrors = {
      email: "",
      password: "",
    };
    if (!email) {
      fieldErrors = { ...fieldErrors, email: "Email is required" };
      emailValid = false;
    }

    if (!isEmailValid(email)) {
      fieldErrors = { ...fieldErrors, email: "Invalid Email" };
      emailValid = false;
    }

    if (!password) {
      fieldErrors = { ...fieldErrors, password: "Password is required" };
      passValid = false;
    }

    if (emailValid) {
      fieldErrors = { ...fieldErrors, email: "" };
    }

    if (passValid) {
      fieldErrors = { ...fieldErrors, password: "" };
    }
    this.setState({ fieldErrors });

    return emailValid && passValid;
  }

  signIn(event) {
    event.preventDefault();
    const { email, password } = this.state.values;
    const valid = this.validate(email, password);

    if (!valid) return;

    this.props.onSignIn(email, password);
  }

  render() {
    const { classes, errorMsg, logoUrl, loading, onForgotPassword } = this.props;
    const { fieldErrors } = this.state;
    return (
      <Card elevation={4}>
        <LogoHeader title="Log In" logoUrl={logoUrl} />
        <form className={classes.form} onSubmit={this.signIn}>
          <CardContent className={classes.form}>
            <TextField
              autoComplete="username"
              name="username"
              label="Email"
              type="email"
              className={classes.textField}
              onChange={e => this.onChange(e.target.value, "email")}
              error={Boolean(fieldErrors.email)}
              helperText={fieldErrors.email}
            />
            <TextField
              autoComplete="current-password"
              label="Password"
              type="password"
              name="password"
              className={classes.textField}
              onChange={e => this.onChange(e.target.value, "password")}
              error={Boolean(fieldErrors.password)}
              helperText={fieldErrors.password}
            />
            <Link
              onClick={() => onForgotPassword(this.state.values.email)}
              style={{ cursor: "pointer" }}
            >
              Forgot Password?
            </Link>
            <ErrorBox style={{ maxWidth: 230 }} hidden={Boolean(errorMsg)}>
              {errorMsg}
            </ErrorBox>
          </CardContent>
          <CardActions
            // style={{ display: "flex", justifyContent: "flex-end", marginRight: 20 }}
            className={classes.middle}
          >
            {loading && <CircularProgress size={20} />}
            <Button color="primary" variant="contained" type="submit">
              Log In
            </Button>
          </CardActions>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(SignIn);
