import { Component } from "react";
import history from "../helpers/history";
import Auth from "@aws-amplify/auth";
import { withStyles } from "@material-ui/core/styles";
import { Card, CircularProgress, Typography } from "@material-ui/core";
import { LogoHeader } from "../fathom-brella";

const styles = theme => ({
  root: {
    background: "top / cover no-repeat url(/img/fathom-landing-bg.png)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
});

class EmailVerificationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      loading: true,
    };
  }

  componentDidMount() {
    if (history.location && history.location.search) {
      const qParams = new URLSearchParams(history.location.search);
      const email = qParams.get("email");
      const code = qParams.get("code");
      const redirect = qParams.get("redirect") || "/signin";

      if (!email || !code) {
        this.setState({ error: "Could not verify email" });
      } else {
        Auth.confirmSignUp(email.toLowerCase(), code)
          .then(() => {
            // send them to the redirect url (sign in page)
            setTimeout(() => {
              window.location.assign(decodeURIComponent(redirect));
            }, 1500);
          })
          .catch(err => {
            console.log(err);
            let message = "";
            if (err.message === "User cannot be confirmed. Current status is CONFIRMED") {
              message = "Email already verified";
            } else if (err.code === "UserNotFoundException") {
              message = "No user registered to this email address";
            } else if (err.code === "CodeMismatchException") {
              message =
                "Link no longer valid, this is likely because a new validation email was sent. Please check your inbox for a newer email";
            } else {
              message = err.message;
            }
            this.setState({ error: message });
          })
          .finally(() => this.setState({ loading: false }));
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, error } = this.state;
    const message = loading
      ? "Verifying Email"
      : error
      ? "Error verifying email"
      : "Email Verified!";
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <LogoHeader title={message} logoUrl="img/fathom-default.png" />
          {loading && <CircularProgress />}
          {error && <Typography style={{ maxWidth: 230 }}> {error} </Typography>}
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(EmailVerificationPage);
