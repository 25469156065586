import { makeStyles } from "@material-ui/core/styles";
import ColorSample from "../../common/ColorSample";

import { roundFixed } from "../../../helpers/common";
import { Typography } from "@material-ui/core";
import { ChartListSeries } from "../ChartList";
import { interpolateLinear, interpolatePrev } from "./interpolate";
import { GAP_BETWEEN_CHARTS } from "../ChartList";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    rowGap: GAP_BETWEEN_CHARTS,
  },
  section: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: 1,
    flexShrink: 1,
  },
  unitHeader: {
    textAlign: "center",
    padding: theme.spacing(1),
    flexShrink: 4,
  },
  labelledReading: {
    display: "flex",
    flexShrink: 5,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "auto",
    height: "auto",
    minWidth: 0,
    minHeight: 0,
  },
}));

type Props = {
  legendHeight: number;
  totalHeight: number;
  seriesByYVal: ChartListSeries[];
  yScales: d3.ScaleLinear<number, number>[];
  serialColorScale: d3.ScaleOrdinal<string, string>;
  t: number;
};

function ReadoutOverlay({
  legendHeight,
  totalHeight,
  seriesByYVal,
  yScales,
  serialColorScale,
  t,
}: Props) {
  const classes = useStyles();

  return (
    <div style={{ height: totalHeight }} className={classes.root}>
      {seriesByYVal.map(({ dataByFile, category, yVariable }, i) => {
        const interpolate = category === "count" ? interpolatePrev : interpolateLinear;

        return (
          <div key={yVariable} className={classes.section} style={{ height: legendHeight }}>
            <Typography variant="body2" className={classes.unitHeader} noWrap>
              {yVariable}
            </Typography>

            {dataByFile.map(({ filename, data, serial }) => {
              const yScale = yScales[i];
              const interpolatedValue = yScale === null ? null : interpolate(t, data);
              const color = serialColorScale(serial);

              let content = "NA";
              if (interpolatedValue !== null && !isNaN(interpolatedValue)) {
                const nDecimals = category === "count" ? 0 : 1;
                content = roundFixed(interpolatedValue, nDecimals);
              }

              return (
                <div className={classes.labelledReading} key={filename}>
                  <ColorSample color={color} size={18} />
                  <div style={{ paddingTop: 4, paddingLeft: 4 }}>{content}</div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default ReadoutOverlay;
