import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LabeledLine from "../common/LabeledLine";
import { formatMeasurement } from "./utils";
import { animalOrigins, animalFields, measurementSetFields } from "./constants";
import { Edit as IconEdit } from "@material-ui/icons";
import { IconButton, Typography } from "@material-ui/core";
import TagCard from "./TagCard";
import { formatDevice } from "../../helpers/device";
const animalDetailFields = { ...animalFields, ...measurementSetFields };

const styles = theme => ({
  root: {
    width: "100%",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    display: "flex",
    alignItems: "flex-start",
  },
  details: {
    width: "100%",
  },
  editIcon: {
    margin: theme.spacing(1),
  },
  subTitle: {
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
});

const orderedFields = [
  "name",
  "speciesCommonName",
  "speciesScientificName",
  "origin",
  "stock",
  "notes",
];

function AnimalDetails({ animal, classes, onAnimalEdit }) {
  const hasTags = animal.devices.length > 0;

  return (
    <div className={classes.root}>
      <div className={classes.details}>
        <Typography className={classes.subTitle} color="textSecondary" gutterBottom>
          Core Data
        </Typography>
        {orderedFields.map(field => renderDetailLine(animal[field], field))}
        {/* {animal.devices.length > 0 && ( */}
        <Typography className={classes.subTitle} color="textSecondary" gutterBottom>
          Attached Tags
        </Typography>
        {/* // )} */}
        {hasTags
          ? animal.devices.map(device => <TagCard key={device.id} device={device} />)
          : "This animal is not currently tagged."}
        {animal.detSummary && (
          <>
            <Typography className={classes.subTitle} color="textSecondary" gutterBottom>
              Detected by receivers:
            </Typography>
            {animal.detSummary.receivers
              .map(r => (r.device ? formatDevice(r.device) : r.serial))
              .join(", ")}
          </>
        )}
      </div>
      <IconButton
        aria-label="Edit"
        size="small"
        onClick={() => onAnimalEdit(animal.id)}
        color="primary"
        className={classes.editIcon}
      >
        <IconEdit fontSize="small" />
      </IconButton>
    </div>
  );
}

function renderDetailLine(detail, field) {
  if (!detail) {
    return null;
  }
  const { label } = animalDetailFields[field];
  const value =
    field === "origin" ? animalOrigins[detail] : detail.unit ? formatMeasurement(detail) : detail;

  return <LabeledLine label={label} value={value} key={label} alignCenter />;
}

AnimalDetails.propTypes = {
  animal: PropTypes.object,
  onAnimalEdit: PropTypes.func,
};

export default withStyles(styles)(AnimalDetails);
