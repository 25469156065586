import { SignalListeners, VisualizationSpec, View } from "react-vega";
import { Actions } from "vega-embed";
import { VegaAutosized } from "../common/charts/VegaAutosized";
import { debounce, max, min } from "lodash";
import { useEffect, useState } from "react";
import {
  DOMAIN_SCROLL_WIDTH,
  LOADING_INDICATOR_POSITION,
  detectionsVegaConfig,
} from "./detection-consts";
import { ZoomOverride, SelectionInterval, TimeRangeTs } from "./detection-types";
import { getBinInfo } from "../../containers/Detections";

type CountPerInterval = {
  /** the interval is ISO date string or numeric timestamp, ideally at the beginning of each interval */
  dt: string | number;
  count: number;
};

const zoomOverrideFactor = {
  in: 0.5,
  out: 2,
  manual: 1,
};

export function DetectionOverview({
  countPerInterval,
  onSelectInterval,
  actions,
  loading,
  zoomOverride,
  resetZoomOverride,
  setZoomOverrideManual,
  binSeconds,
  filterInterval,
  dataInterval,
}: {
  countPerInterval: CountPerInterval[];
  onSelectInterval: (interval: SelectionInterval) => void;
  actions?: Actions;
  loading: boolean;
  zoomOverride: ZoomOverride;
  resetZoomOverride: () => void;
  setZoomOverrideManual: () => void;
  binSeconds: number;
  filterInterval: TimeRangeTs;
  dataInterval: TimeRangeTs;
}) {
  const [view, setView] = useState(null as View | null);

  const onSelectIntervalDBounced = debounce(onSelectInterval, 100);
  const setZoomOverrideManualDBounced = debounce(setZoomOverrideManual, 100);

  //get largest count to know upper bound of domain range for the yAxis scale
  const countArray = countPerInterval.map(element => element.count);
  const countMax = Math.max(...countArray);

  const bin = getBinInfo(binSeconds, 1);

  const spec: VisualizationSpec = {
    usermeta: { embedOptions: { renderer: "svg" } },
    padding: detectionsVegaConfig.chartPadding,
    params: [
      {
        name: "brush",
        select: {
          type: "interval",
          encodings: ["x"],
          mark: {
            stroke: "black",
            strokeWidth: 2,
          },
        },
      },
    ],
    data: { name: "countPerInterval" },
    mark: "bar",
    encoding: {
      x: {
        field: "dt",
        type: "temporal",
        timeUnit: {
          binned: true,
          utc: true,
          unit: bin.getVegaTimeUnit(),
          step: bin.value,
        },
        axis: {
          title: "",
          ...detectionsVegaConfig.font,
          ...detectionsVegaConfig.xAxis,
        },
        scale: {
          domain: [filterInterval.min || dataInterval.min, filterInterval.max || dataInterval.max],
        },
      },
      y: {
        field: "count",
        type: "quantitative",
        axis: {
          title: "# Dets",
          ...detectionsVegaConfig.font,
          ...detectionsVegaConfig.yAxis,
        },
        scale: { domain: [0, Math.ceil(countMax * 1.1)] },
      },
      tooltip: {
        value: {
          signal: `{
            'From': utcFormat(datum.dt, '%b %d, %Y %H:%M'),
            'Duration': '${bin.getDisplay()}',
            'Count': datum.count
          }`,
        },
      },
    },
  };

  // Handle time selection changes:
  useEffect(() => {
    if (zoomOverride && view) {
      if (zoomOverride === "clear") {
        view.signal("brush_x", []);
      } else {
        const state = view.getState();
        const viewWidth = view.width();
        const brushX = [...state.signals.brush_x];
        const interval = {
          min: min(brushX) || 0, // the start and end switch positions depending on which direction the selection is made
          max: max(brushX) || 0,
          width: 0,
        };

        // allow zooming in when there is "no selection"
        if (zoomOverride === "in" && interval.min == interval.max) {
          interval.min = 0;
          interval.max = viewWidth;
        }
        interval.width = interval.max - interval.min;

        // handle scaling zoom by a factor:
        if (zoomOverride === "in" || zoomOverride === "out" || zoomOverride === "manual") {
          const zoomFactor = zoomOverrideFactor[zoomOverride];
          const centerValue = (interval.max + interval.min) / 2;
          const newWidth = interval.width * zoomFactor;
          const newStart = centerValue - newWidth / 2 > 0 ? centerValue - newWidth / 2 : 0;
          const newEnd =
            centerValue + newWidth / 2 < viewWidth ? centerValue + newWidth / 2 : viewWidth;
          const timeDifference =
            new Date(view.scale("x").invert(newEnd)).getTime() -
            new Date(view.scale("x").invert(newStart)).getTime();
          if (timeDifference / 1000 > binSeconds) {
            const updatedInterval: SelectionInterval =
              min(brushX) === brushX[0] ? [newStart, newEnd] : [newEnd, newStart];
            view.signal("brush_x", [...updatedInterval]);
          } else if (timeDifference) {
            const updatedStartOffset = view.scale("x")(
              (new Date(view.scale("x").invert(centerValue)).getTime() / 1000 - binSeconds / 2) *
                1000
            );
            const updatedEndOffset = view.scale("x")(
              (new Date(view.scale("x").invert(centerValue)).getTime() / 1000 + binSeconds / 2) *
                1000
            );
            const updatedInterval: SelectionInterval =
              min(brushX) === brushX[0]
                ? [
                    updatedStartOffset > 0 ? updatedStartOffset : 0,
                    updatedEndOffset < viewWidth ? updatedEndOffset : viewWidth,
                  ]
                : [
                    updatedEndOffset < viewWidth ? updatedEndOffset : viewWidth,
                    updatedStartOffset > 0 ? updatedStartOffset : 0,
                  ];

            view.signal("brush_x", [...updatedInterval]);
          }
        }
        // handle overriding zoom with a set interval:
        if (zoomOverride.constructor === Array) {
          const getXScale = view.scale("x");
          view.signal("brush_x", [getXScale(zoomOverride[0]), getXScale(zoomOverride[1])]);
        }

        // handle overriding zoom by next or prev:
        if (zoomOverride === "next" && interval.width) {
          view.signal("brush_x", [interval.max, interval.max + interval.width]);
        }
        if (zoomOverride === "prev" && interval.width) {
          view.signal("brush_x", [interval.min - interval.width, interval.min]);
        }

        // handle overriding zoom to end:
        if (zoomOverride === "end" && interval.width) {
          view.signal("brush_x", [viewWidth - interval.width, viewWidth]);
        }
        // handle overriding zoom by beginning
        if (zoomOverride === "beginning" && interval.width) {
          view.signal("brush_x", [0, interval.width]);
        }
      }

      // clear the signal
      resetZoomOverride();
    }
  }, [zoomOverride, resetZoomOverride, view, binSeconds]);

  const signalListeners: SignalListeners = {
    brush: (_, { dt }) => {
      if (!dt || Math.abs(dt[1] - dt[0]) / 1000 >= binSeconds)
        onSelectIntervalDBounced(dt as SelectionInterval);
      else setZoomOverrideManualDBounced();
    },
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexGrow: 1,
        marginRight: DOMAIN_SCROLL_WIDTH,
      }}
    >
      <VegaAutosized
        spec={spec}
        data={{ countPerInterval }}
        signalListeners={signalListeners}
        actions={actions}
        fit="both"
        loading={loading}
        onNewView={view => setView(view)}
        loaderPosition={LOADING_INDICATOR_POSITION}
      />
    </div>
  );
}
