import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind/react";
import { TableSearchBar } from "../../fathom-brella";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Switch, FormControlLabel } from "@material-ui/core";

import WorkspaceDialog from "../../components/workspaces/WorkspaceDialog";
import WorkspaceAddDialog from "../../components/workspaces/WorkspaceAddDialog";
import WorkspacesTable from "../../components/workspaces/WorkspacesTable";
import Spinner from "../../components/common/Spinner";
import { getUsers } from "../../redux/admin/user/admin-user-actions";
import {
  getAllWorkspaces,
  updateWorkspace,
  deleteWorkspaces,
  restoreWorkspaces,
  toggleIncludeDeletedWorkspaces,
  removeWorkspaceUser,
  addWorkspaceUser,
  updateWorkspaceUser,
  createWorkspace,
} from "../../redux/admin/workspace/admin-workspace-actions";
import { setCreatedWorkspaceId } from "../../redux/workspace/workspace-actions";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  toolbar: {
    borderBottom: "1px solid lightgray",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tool: {
    marginLeft: theme.spacing(1),
  },
});

class AllWorkspaces extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      workspaceToEditId: null,
      searchText: "",
      showPersonal: false,
      workspaceAddDialogOpen: false,
      autoShowInviteUser: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getUsers());
    this.props.dispatch(getAllWorkspaces());
  }

  componentDidUpdate() {
    // Open edit dialog for the workspace just created:
    const createdWorkspaceId = this.props.createdWorkspaceId;
    if (
      createdWorkspaceId !== null &&
      // createdWorkspaceId gets updated before the fetch gets the new data
      this.props.allWorkspaces.filter(workspace => workspace.id === createdWorkspaceId)
    ) {
      this.setWorkspaceToEditId(createdWorkspaceId);
      this.setAutoShowInviteUser(true);
      this.props.dispatch(setCreatedWorkspaceId(null));
    }
  }

  setAutoShowInviteUser(autoShowInviteUser) {
    this.setState({ autoShowInviteUser });
  }

  setWorkspaceToEditId(workspaceToEditId) {
    this.setState({ workspaceToEditId });
  }

  setWorkspaceAddDialogOpen(workspaceAddDialogOpen) {
    this.setState({ workspaceAddDialogOpen });
  }

  handleEditDialogClose() {
    this.setWorkspaceToEditId(null);
    this.setAutoShowInviteUser(false);
  }

  createWorkspace({ name, description }) {
    this.props.dispatch(createWorkspace({ name, description }));
  }

  updateWorkspace({ workspaceId, name, description }) {
    this.props.dispatch(updateWorkspace({ workspaceId, name, description }));
  }

  deleteWorkspace({ workspaceId }) {
    // The API allows multiple delete, but this UI only enables one at a time so reduce it here:
    this.props.dispatch(deleteWorkspaces({ workspaceIds: [workspaceId] }));
    this.setState({ workspaceToEditId: null });
  }

  restoreWorkspace({ workspaceId }) {
    // The API allows multiple restore, but this UI only enables one at a time so reduce it here:
    this.props.dispatch(restoreWorkspaces({ workspaceIds: [workspaceId] }));
  }

  toggleIncludeDeletedWorkspaces() {
    this.props.dispatch(toggleIncludeDeletedWorkspaces());
  }

  removeWorkspaceUser({ workspaceId, userId }) {
    this.props.dispatch(removeWorkspaceUser({ workspaceId, userId }));
  }

  addWorkspaceUser({ workspaceId, userId, isOwner }) {
    this.props.dispatch(addWorkspaceUser({ workspaceId, userId, isOwner }));
  }

  updateWorkspaceUser({ workspaceId, userId, isOwner }) {
    this.props.dispatch(updateWorkspaceUser({ workspaceId, userId, isOwner }));
  }

  render() {
    const { classes, loading, allWorkspaces, includeDeletedWorkspaces, users } = this.props;
    const {
      searchText,
      workspaceToEditId,
      workspaceAddDialogOpen,
      showPersonal,
      autoShowInviteUser,
    } = this.state;
    const workspaceToEdit = allWorkspaces.find(workspace => workspace.id === workspaceToEditId);

    return (
      <>
        {loading ? (
          <Spinner />
        ) : (
          <div className={classes.main}>
            <div className={classes.toolbar}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.setWorkspaceAddDialogOpen(true)}
              >
                Add Workspace
              </Button>

              <FormControlLabel
                className={classes.tool}
                control={
                  <Switch
                    checked={showPersonal}
                    onChange={event => {
                      this.setState({ showPersonal: event.target.checked });
                    }}
                    color="primary"
                  />
                }
                label="Show personal"
              />

              <FormControlLabel
                className={classes.tool}
                control={
                  <Switch
                    checked={includeDeletedWorkspaces}
                    onChange={this.toggleIncludeDeletedWorkspaces}
                    color="primary"
                  />
                }
                label="Show Deleted"
              />

              <TableSearchBar
                className={classes.tool}
                searchText={searchText}
                handleSearch={searchText => this.setState({ searchText })}
              />
            </div>

            <WorkspacesTable
              allWorkspaces={
                this.state.showPersonal
                  ? allWorkspaces
                  : allWorkspaces.filter(workspace => !workspace.isPersonal)
              }
              searchText={searchText}
              setWorkspaceToEditId={this.setWorkspaceToEditId}
              restoreWorkspace={this.restoreWorkspace}
              deleteWorkspace={this.deleteWorkspace}
            />

            <WorkspaceDialog
              open={workspaceToEditId ? true : false}
              handleClose={this.handleEditDialogClose}
              workspace={workspaceToEdit}
              updateWorkspace={this.updateWorkspace}
              removeWorkspaceUser={this.removeWorkspaceUser}
              addWorkspaceUser={this.addWorkspaceUser}
              updateWorkspaceUser={this.updateWorkspaceUser}
              autoShowInviteUser={autoShowInviteUser}
              isUserAdmin={true}
              users={users}
            />

            <WorkspaceAddDialog
              open={workspaceAddDialogOpen}
              createWorkspace={this.createWorkspace}
              handleClose={() => this.setWorkspaceAddDialogOpen(false)}
            />
          </div>
        )}
      </>
    );
  }
}

AllWorkspaces.propTypes = {
  allWorkspaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          user: PropTypes.shape({ id: PropTypes.string, email: PropTypes.string }),
          isOwner: PropTypes.bool,
        })
      ),
    })
  ),
  loading: PropTypes.bool,
  includeDeletedWorkspaces: PropTypes.bool,
  /** For auto-spawning workspace edit dialog on workspace creation */
  createdWorkspaceId: PropTypes.string,
  users: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    users: state.adminUsers.users,
    allWorkspaces: state.adminWorkspaces.allWorkspaces,
    includeDeletedWorkspaces: state.workspaces.includeDeleted,
    createdWorkspaceId: state.workspaces.createdId,
    loading: state.adminWorkspaces.loading,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AllWorkspaces));
