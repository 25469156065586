import { useEffect, forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, CircularProgress, Icon } from "@material-ui/core";
import { Check as IconCheck } from "@material-ui/icons";

import { SnackbarContent } from "notistack";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    width: 350,
    backgroundColor: theme.palette.background.lightGrey,
    padding: theme.spacing(2),
  },
}));

const FileProcessingSnack = forwardRef(({ onClose }, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inProgFiles = useSelector(state => state.files.fileList).filter(f => f.processing);
  const uploadCount = useSelector(state => state.files.sessionUploadCount);
  const numProcFiles = Object.keys(inProgFiles).length;

  const [done, setDone] = useState(false);

  useEffect(() => {
    if (numProcFiles === 0) {
      setDone(true);
      setTimeout(() => {
        onClose();
        dispatch({ type: "FILE_RESET_UPLOAD_COUNT" });
      }, 4000);
    }
  }, [numProcFiles, dispatch, onClose]);

  return (
    <SnackbarContent ref={ref}>
      <Card className={classes.card}>
        <div className={classes.header}>
          {!done ? (
            <>
              <Typography>{`${
                uploadCount - numProcFiles
              } Files Processed, ${numProcFiles} Remaining`}</Typography>
              <CircularProgress size={20}></CircularProgress>
            </>
          ) : (
            <>
              <Typography>{`${uploadCount} Files Processed`}</Typography>
              <Icon>
                <IconCheck />
              </Icon>
            </>
          )}
        </div>
      </Card>
    </SnackbarContent>
  );
});

FileProcessingSnack.propTypes = {
  snackbarKey: PropTypes.string,
};

export default FileProcessingSnack;
