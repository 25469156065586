import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { TextField, Typography } from "@material-ui/core";
import { Check as CheckIcon, Close as CloseIcon } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { passwordPolicyValidation } from "../../helpers/common";

const styles = () => ({
  main: {
    display: "flex",
    flexDirection: "column",
  },
  check: {
    display: "flex",
    flexDirection: "row",
  },
  password: {
    minWidth: 200,
  },
});

class PasswordChange extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordConfirm: "",
      passwordsMatch: false,
      lowerCaseCheck: false,
      upperCaseCheck: false,
      numberCheck: false,
      lengthCheck: false,
      whitespaceCaseCheck: false,
      allValid: false,
    };
  }

  onChange(password, field) {
    const { onChange: parentOnChange } = this.props;
    const validationResult = passwordPolicyValidation(password);
    const passwordsMatch =
      field === "password"
        ? password === this.state.passwordConfirm
        : password === this.state.password;
    this.setState({
      [field]: password,
      passwordsMatch,
      ...validationResult,
    });
    parentOnChange(passwordsMatch && validationResult.allValid, password);
  }

  renderCheck(checkText, checkKey) {
    const pass = this.state[checkKey];
    return (
      <div className={this.props.classes.check} style={{ color: pass ? "green" : "red" }}>
        {pass ? <CheckIcon /> : <CloseIcon />}
        <Typography> {checkText}</Typography>
      </div>
    );
  }
  render() {
    const { classes, style } = this.props;
    const { password, passwordConfirm } = this.state;
    return (
      <div className={classes.main} style={style}>
        <TextField
          autoComplete="new-password"
          label="Password"
          type="password"
          value={password}
          onChange={e => this.onChange(e.target.value, "password")}
          className={classes.password}
        />
        <TextField
          autoComplete="new-password"
          label="Confirm Password"
          type="password"
          value={passwordConfirm}
          onChange={e => this.onChange(e.target.value, "passwordConfirm")}
          className={classes.password}
        />
        <div style={{ marginTop: 16 }}>
          {this.renderCheck("Lower case letter", "lowerCaseCheck")}
          {this.renderCheck("Upper case letter", "upperCaseCheck")}
          {this.renderCheck("Number", "numberCheck")}
          {this.renderCheck("At least 8 characters", "lengthCheck")}
          {this.renderCheck("No white space", "whitespaceCaseCheck")}
          {this.renderCheck("Passwords Match", "passwordsMatch")}
        </div>
      </div>
    );
  }
}

PasswordChange.propTypes = {
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default withStyles(styles)(PasswordChange);
