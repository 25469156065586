import { withStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@material-ui/core";
import { FATHOM_POSITION_URL, VEMCO_LIVE_URL } from "../config";
import FlexCol from "../components/common/FlexCol";
import FlexRow from "../components/common/FlexRow";
import Testimonial from "../components/common/Testimonial";
import Header from "./Header";

const styles = theme => ({
  root: {
    // background: "top / cover no-repeat url(/img/fathom-landing-bg.png)",
    background: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "'Nunito Sans', sans-serif", // 20200414 JAB: I haven't figured out why the theme provider doesn't affect this page. This sets the font for now. (I even tried adding <MuiThemeProvider> directly, but no effect)
    overflow: "auto",
  },
  logo: {
    height: 42,
    cursor: "pointer",
    "@media screen and (max-width: 450px)": {
      marginBottom: theme.spacing(2),
      height: 70,
    },
  },
  signin: {
    "&:last-of-type": {
      marginLeft: theme.spacing(2),
    },
  },
  promoContent: {
    justifyContent: "space-around",
    flexGrow: 1,
    marginBottom: 60,
    height: 900,
    boxShadow: `0px 4px 8px -4px ${theme.palette.primary.dark}`,
    "@media screen and (max-width: 1300px)": {
      height: 700,
    },
    "@media screen and (max-width: 1000px)": {
      height: 680,
    },
  },
  testimonials: {
    overflowY: "auto",
    marginLeft: 60,
    marginRight: 60,
  },
  productContainer: {
    display: "flex",
    flexDirection: "column",
    width: "25%",
    minWidth: "220px",
    textAlign: "center",
    alignItems: "center",
    borderLeft: "2px solid silver",
    "&:first-of-type": {
      borderLeft: "0px",
    },
    "@media screen and (max-width: 1300px)": {
      width: "50%",
      paddingBottom: 30,
      "&:nth-of-type(3)": {
        borderLeft: "0px",
      },
    },
    "@media screen and (max-width: 500px)": {
      width: "100%",
      paddingBottom: 30,
      borderLeft: "0px",
      "&:nth-of-type(4)": {
        paddingBottom: 0,
      },
    },
  },
  productLogo: {
    height: 60,
    marginTop: 20,
    marginBottom: 10,
  },
  responsiveBox: {
    width: 1000,
    height: 600,
    borderRadius: 3,
    boxShadow: `2px 2px 8px ${theme.palette.primary.light}`,
    "@media screen and (max-width: 1300px)": {
      width: 560,
      height: 315,
    },
    "@media screen and (max-width: 1000px)": {
      width: 480,
      height: 270,
    },
    "@media screen and (max-width: 500px)": {
      width: 240,
      height: 135,
    },
  },
  footer: {
    display: "flex",
    flexWrap: "wrap",
    padding: "60px 0px 30px",
    //background: "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.7) 30px, white 100px)",
    "@media screen and (max-width: 1000px)": {
      flexGrow: 1,
    },
  },
});

const products = [
  {
    name: "Fathom",
    logo: "fathom-central.png",
    link: "/signin",
    description:
      "A powerful software platform designed to get you from raw data to sharing your insights into animal behaviors, quickly and easily.",
  },
  {
    name: "Fathom Live",
    logo: "fathom-live.png",
    link: VEMCO_LIVE_URL,
    description: "Visualize animal movements and environmental conditions in real time.",
  },
  {
    name: "Fathom Position",
    logo: "fathom-position.png",
    link: FATHOM_POSITION_URL,
    description: "Calculate fine scale positions of your animals.",
  },
  {
    name: "Fathom Mobile",
    logo: "fathom-mobile.png",
    link: "https://play.google.com/store/apps/details?id=com.innovasea.fathom&hl=en_CA&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
    description: "Streamline your deployments and offload your receivers using your mobile device.",
  },
];

const testimonials = [
  {
    customer: "Brian Stirling",
    company: "Research Technician Shark Lab, California State University, Long Beach",
    description:
      "Fathom Central has been useful in reducing the number of trips the Shark Lab team needs to take to offload receivers maintained by our collaborators since using VUE wasn't an option for many of them.",
  },
  {
    customer: "Mitchell Rider, MS, PhD Student",
    company:
      "Acoustic Telemetry Array Manager at Shark Research and Conservation Program, University of Miami, RSMAS",
    description:
      "We just acquired an Android tablet so this is the first time I've been able to use Fathom Central and Mobile. So far so great! I usually conduct downloads every six months or so and this time around I don't have to replace batteries so using the mobile app on the tablet has saved a lot of time. Plus, I don't have to worry about having my computer out on the boat. Using the mobile app has been wonderful!",
  },
];

function LandingPage(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Header classes={classes} />

      <FlexCol>
        <FlexRow fullWidth itemSpacing={4} paddingLevel={4} className={classes.promoContent}>
          <FlexCol paddingLevel={3} vAlign="center">
            <Typography variant="h3" gutterBottom>
              A New Era in Fish Tracking.
            </Typography>
            <Typography variant="h5">
              Fathom is a collection of web-based applications that make storing, managing,
              visualizing and analyzing your acoustic telemetry data a breeze – whether you’re
              sitting at your desk or out in the field.
            </Typography>
          </FlexCol>
          <FlexCol vAlign="top" paddingLevel={2}>
            <div className={classes.responsiveBox}>
              <iframe
                title="Fathom Software: A new Era in Fish Tracking"
                src="https://www.youtube.com/embed/KW_2mUGdvx8?rel=0"
                width="100%"
                height="100%"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <div className={classes.footer}>
                {products.map(product => (
                  <Tooltip key={product.name} title={product.description}>
                    <div className={classes.productContainer}>
                      <a href={product.link}>
                        <img
                          className={classes.productLogo}
                          alt={product.name}
                          src={`/img/${product.logo}`}
                        />
                      </a>
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          </FlexCol>
        </FlexRow>
        <FlexRow>
          <FlexCol paddingLevel={1} itemSpacing={3} className={classes.testimonials}>
            {testimonials.map(testimonial => (
              <Testimonial
                key={testimonial.customer}
                customer={testimonial.customer}
                company={testimonial.company}
                description={testimonial.description}
              />
            ))}
          </FlexCol>
        </FlexRow>
      </FlexCol>
    </div>
  );
}

export default withStyles(styles)(LandingPage);
