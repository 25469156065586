import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { ReactNode } from "react";

// Component for providing customized buttons that use the parent's cancelAction and okAction
export type CustomButtonsProps = {
  cancelAction: () => void;
  okAction?: () => void;
};

type Props = Omit<DialogProps, "title"> & {
  title: ReactNode;
  open: boolean;
  cancelAction: () => void;
  okAction?: () => void;
  okButtonContent?: string | Node;
  okDisabled?: boolean;
  buttons?: (props: CustomButtonsProps) => ReactNode;
  /** @deprecated This prop becomes transitionProps.onEnter in mui v5. It is mapped correctly in this wrapper component however. */
  onEnter?: () => void;
  TransitionProps?: TransitionProps;
  children?: ReactNode;
  centerButtons?: boolean;
};

export default function DialogWrapper({
  title,
  open,
  children,
  okAction,
  okButtonContent,
  okDisabled = false,
  cancelAction,
  buttons,
  onEnter,
  TransitionProps,
  centerButtons = false,
  ...dialogProps
}: Props) {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      onKeyDown={event => {
        if (event.key === "Escape") {
          cancelAction();
        }
        if (okDisabled === false) {
          if (event.key === "Enter") {
            (okAction?.constructor == Function && okAction()) || cancelAction();
          }
        }
      }}
      fullWidth
      TransitionProps={{ onEnter, ...TransitionProps }}
      {...dialogProps}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {children && (
        <DialogContent>
          {typeof children == "object" ? (
            children
          ) : (
            <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
          )}
        </DialogContent>
      )}
      <DialogActions style={{ justifyContent: centerButtons ? "center" : undefined }}>
        {buttons ? (
          buttons({ okAction, cancelAction })
        ) : (
          <>
            {cancelAction && (
              <Button onClick={cancelAction} variant="outlined">
                Cancel
              </Button>
            )}
            {okAction && (
              <Button onClick={okAction} color="primary" variant="contained" disabled={okDisabled}>
                {okButtonContent || "OK"}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
