import * as d3 from "d3";
import { findClosest } from "../helpers/common";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30;
const YEAR = DAY * 365;

const IDEAL_TICK_SPACING = 150;

const time5Days = d3.utcDay.every(5).filter(d => d.getUTCDate() !== 31);
const time10Days = d3.utcDay.every(10).filter(d => d.getUTCDate() !== 31);
const days1_15 = d3.utcDay.filter(d => [1, 15].includes(d.getUTCDate()));

// prettier-ignore
/* These intervals determine the size and display format of a variety of different tick intervals.
 * Only the intervals included in axisSpecs are actually used by TimeAxis */
const intervals = {
  "second":   { duration: SECOND,      interval: d3.utcSecond,           minFormat: ":%S" },
  "5second":  { duration: 5 * SECOND,  interval: d3.utcSecond.every(5),  minFormat: ":%S" },
  "15second": { duration: 15 * SECOND, interval: d3.utcSecond.every(15), minFormat: ":%S" },
  "minute":   { duration: MINUTE,      interval: d3.utcMinute,           minFormat: "%H:%M",  majFormat: "%d %b %Y %H:%M" },
  "5minute":  { duration: 5 * MINUTE,  interval: d3.utcMinute.every(5),  minFormat: "%H:%M" },
  "15minute": { duration: 15 * MINUTE, interval: d3.utcMinute.every(15), minFormat: "%H:%M" },
  "hour":     { duration: HOUR,        interval: d3.utcHour,             minFormat: "%H:%M" },
  "4hour":    { duration: HOUR * 4,    interval: d3.utcHour.every(4),    minFormat: "%H:%M" },
  "8hour":    { duration: HOUR * 8,    interval: d3.utcHour.every(8),    minFormat: "%H:%M" },
  "day":      { duration: DAY,         interval: d3.utcDay,              minFormat: "%d",     majFormat: "%d %b %Y" },
  "2day":     { duration: DAY * 2,     interval: d3.utcDay.every(2),     minFormat: "%d" },
  "5day":     { duration: DAY * 5,     interval: time5Days,              minFormat: "%d" },
  "10day":    { duration: DAY * 10,    interval: time10Days,             minFormat: "%d" },
  "15day":    { duration: DAY * 15,    interval: days1_15,               minFormat: "%d" },
  "month":    { duration: MONTH,       interval: d3.utcMonth,            minFormat: "%b",     majFormat: "%b %Y" },
  "4month":   { duration: MONTH * 4,   interval: d3.utcMonth.every(4),   minFormat: "%b" },
  "year":     { duration: YEAR,        interval: d3.utcYear,             minFormat: "%Y",     majFormat: "%Y" },
};

/* This determines the set of axis format specifications that can be used by TimeAxis.
 * Whenever the axis scale updates, the spec that gives a minor tick spacing closest to
 * IDEAL_TICK_SPACING is selected from this list. */
const axisSpecs = [
  { major: intervals["minute"], minor: intervals["second"] },
  { major: intervals["minute"], minor: intervals["5second"] },
  { major: intervals["minute"], minor: intervals["15second"] },
  { major: intervals["day"], minor: intervals["minute"] },
  { major: intervals["day"], minor: intervals["5minute"] },
  { major: intervals["day"], minor: intervals["15minute"] },
  { major: intervals["day"], minor: intervals["hour"] },
  { major: intervals["day"], minor: intervals["4hour"] },
  { major: intervals["day"], minor: intervals["8hour"] },
  { major: intervals["month"], minor: intervals["day"] },
  { major: intervals["month"], minor: intervals["2day"] },
  { major: intervals["month"], minor: intervals["5day"] },
  { major: intervals["year"], minor: intervals["month"] },
  { major: intervals["year"], minor: intervals["4month"] },
  { major: null, minor: intervals["year"] },
];

export default class TimeAxis {
  // A two-level time axis that attempts to be readable while still displaying all necessary info
  constructor(
    g, // d3-selection (of node), where to attach axis
    scale, // d3-scale object
    type, // "top" or "bottom" axis
    displayMajor = false // use secondary larger axis "ticks" ?
  ) {
    const d3axis = type === "top" ? d3.axisTop : d3.axisBottom;

    this.g = g;
    this.minorAxis = d3axis(scale);
    this.majorAxis = d3axis(scale);
    this.displayMajor = displayMajor;

    this.majorAxisGroup = this.g
      .append("g")
      .attr("class", "major")
      .attr("transform", `translate(0, ${type === "top" ? -15 : 15})`);

    this.minorAxisGroup = this.g.append("g").attr("class", "minor");
    this.updateScale(scale);
  }

  updateScale(_scale) {
    const scale = _scale.copy();
    const domain = scale.domain();

    if (!scale.domain().every(isFinite)) {
      return;
    }

    const tickDuration = scale.invert(IDEAL_TICK_SPACING).getTime() - domain[0].getTime();

    if (tickDuration <= 0) {
      return;
    }

    const { major, minor } =
      axisSpecs[
        findClosest(
          axisSpecs.map(d => d.minor.duration),
          tickDuration
        )
      ];

    const minorTicks = scale.ticks(minor.interval);
    this.minorAxis.scale(scale).tickValues(minorTicks).tickFormat(d3.utcFormat(minor.minFormat));

    this.minorAxisGroup.call(this.minorAxis);

    if (this.displayMajor && major) {
      const majorTicks = scale.ticks(major.interval);
      let addInitialTick = false;

      if (majorTicks.length < 1) {
        // If no major ticks, add one at start of domain.
        majorTicks.unshift(domain[0]);
        addInitialTick = true;
      }
      this.majorAxis.scale(scale).tickValues(majorTicks).tickFormat(d3.utcFormat(major.majFormat));
      this.majorAxisGroup
        .call(this.majorAxis)
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll(".tick line").remove());
      if (addInitialTick) {
        this.majorAxisGroup.select(".tick text").attr("text-anchor", "start");
      }
    }
    d3.selectAll(".tick text").attr("font-size", 12);
  }
}
