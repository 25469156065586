import { ReactChild } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormLabel, FormControl, FormGroup } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    position: "relative",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formGroup: {
    marginLeft: theme.spacing(1),
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

type Props<T> = {
  options: { value: T; label: string; extra?: ReactChild | null }[];
  selection: T[];
  label: string;
  enabledValues: T[];
  selectionHandler: (selection: T[]) => any;
  size?: "medium" | "small";
};

function CheckboxGroup<T>({
  options,
  selection,
  label,
  enabledValues,
  selectionHandler,
  size = "medium",
}: Props<T>) {
  const classes = useStyles();
  const handleSelectionChange = value => {
    const newSelection = [...selection];
    const i = newSelection.indexOf(value);

    if (i < 0) {
      newSelection.push(value);
    } else {
      newSelection.splice(i, 1);
    }

    selectionHandler(newSelection);
  };

  if (options.length < 1) {
    return null;
  }

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl} fullWidth>
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup className={classes.formGroup}>
          {options.map(d => (
            <div className={classes.optionContainer} key={String(d.value)}>
              <div style={{ width: "50%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selection.includes(d.value)}
                      onChange={() => handleSelectionChange(d.value)}
                      value={d.value}
                      disabled={!enabledValues.includes(d.value)}
                      size={size}
                    />
                  }
                  label={d.label}
                />
              </div>
              {d.extra && <div style={{ width: "50%" }}>{d.extra}</div>}
            </div>
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default CheckboxGroup;
