import { Live2State } from "./live-2-types";

const initialState: Live2State = {
  detections: [],
  keepPolling: true,
  companies: [],
};

export default function live2Reducer(state = initialState, { type, payload }) {
  switch (type) {
    case "SET_LATEST_DETECTIONS": {
      return { ...state, detections: [...payload.detections] };
    }
    case "SET_COMPANIES": {
      return { ...state, companies: [...payload.companies] };
    }
    case "ADD_DETECTION": {
      return { ...state, detections: [...state.detections, payload.detection] };
    }
    default:
      return state;
  }
}
