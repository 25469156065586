import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DATETIME_FORMAT } from "../../helpers/time";
import { ifEnterOrEsc } from "../../helpers/input";

// moment & @date-io/date-fns use a different date time format spec string...
const DATE_FNS_DATETIME_FORMAT = DATETIME_FORMAT.replace("YYYY", "yyyy").replace("DD", "dd");
type Props = {
  value: string;
  className: string;
  label: string;
  fullWidth: boolean;
  error: string;
  helperText: string;
  onChange: (value: string) => any;
  onSubmit?: () => void;
  onCancel?: () => void;
} & any;

function DateTimePicker(props: Props) {
  const {
    value,
    className,
    label,
    fullWidth,
    error,
    helperText,
    onSubmit,
    onCancel,
    onChange,
    ...restProps
  } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        variant="inline"
        label={label}
        ampm={false}
        // format prop determines the format of the "value" string attribute returned when the time is set from the picker
        format={DATE_FNS_DATETIME_FORMAT}
        emptyLabel=""
        initialFocusedDate={new Date().toISOString().substring(0, 10) + " 00:00"} // round default picker time to the hour
        className={className}
        onChange={(_, value) => onChange(value)}
        fullWidth={fullWidth}
        error={error}
        helperText={helperText}
        {...restProps}
        value={null}
        inputValue={value}
        onKeyDown={keyEvent =>
          ifEnterOrEsc({
            keyEvent,
            onEnter: onSubmit,
            onEsc: onCancel,
          })
        }
      />
    </MuiPickersUtilsProvider>
  );
}

export default DateTimePicker;
