import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

import history from "../../helpers/history";

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,1)",
  },
  msgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "100px",
    maxWidth: "500px",
    maxHeight: "160px",
  },
  title: {
    padding: "30px",
  },
};

function NotFound({ classes }) {
  return (
    <div className={classes.root} data-testid="not-found-root">
      <div className={classes.msgContainer}>
        <Typography className={classes.title} align="center" variant="h2">
          Page not found
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth={false}
          onClick={() => history.push("/home")}
        >
          Take me home
        </Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(NotFound);
