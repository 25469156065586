import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind/react";
import memoize from "memoize-one";
import { WindowedTable } from "../../fathom-brella";

import { withStyles } from "@material-ui/core/styles";
import { FormHelperText } from "@material-ui/core";

import { connect } from "react-redux";
import { updateDeployment } from "../../redux/deployments/deployments-actions";

import StationPicker from "./StationPicker";
import { formatLatLon, validateFormField, parseFormField } from "../../helpers/common";
import { formatTimeFromISOStringUTC, formatTimeFromISOStringDt } from "../../helpers/time";
import DateTimePicker from "../common/DateTimePicker";
import InlineEditor from "../common/InlineEditor";
import WarningIcon from "../common/WarningIcon";
import { getAttachedDevices } from "./utils";
import StudyLinkChips from "../study/StudyLinkChips";
import { studyNames } from "../../helpers/study";

const styles = {
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
};

class DeploymentTable extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      editAnchorEl: null, // the anchor element for the edit field popup
      editValue: "", // the current edited value
      editKey: null, // the datakey for the column being edited
      editId: null, // the id of the deployment being edited
      editError: null, // the error message displayed in the edit field popup
    };

    this.columns = [
      {
        dataKey: "stationName",
        label: "Station",
        width: 50,
        editable: props.editable,
        flexGrow: 1,
        onEdit: props.editable
          ? (event, rowData) => this.startEdit(event, rowData, "stationId")
          : null,
      },
      {
        dataKey: "attachedDevices",
        label: "Attached devices",
        width: 100,
        flexGrow: 1,
        renderFn: devices => devices.join(", "),
      },
      {
        dataKey: "start",
        label: "Start Time",
        width: 100,
        editable: props.editable,
        flexGrow: 2,
        onEdit: props.editable ? (event, rowData) => this.startEdit(event, rowData, "start") : null,
      },
      {
        dataKey: "end",
        label: "End Time",
        width: 100,
        editable: props.editable,
        flexGrow: 2,
        onEdit: props.editable ? (event, rowData) => this.startEdit(event, rowData, "end") : null,
      },
      {
        dataKey: "latestPosition",
        label: "Latest Lat / Lon",
        width: 150,
        disableSort: true,
        flexGrow: 2,
        renderFn: renderLatLonColumn,
      },
    ];

    this.getColumns = memoize(deployments => {
      const columns = [...this.columns];

      if (deployments.some(deployment => deployment.inStudies)) {
        columns.push({
          width: 90,
          flexGrow: 1,
          label: "In Studies",
          dataKey: "inStudies",
          searchTextFn: studyNames,
          renderFn: (studies, rowData) => {
            return (
              <StudyLinkChips
                studies={studies}
                removeFromStudy={this.props.handleRemoveFromStudy}
                objectId={rowData.id}
              />
            );
          },
          noEllipses: true,
        });
      }

      if (deployments.some(deployment => deployment.hasConflict)) {
        columns.push({
          dataKey: "hasConflict",
          label: "",
          width: 40,
          disableSort: true,
          flexShrink: 1,
          searchTextFn: () => "",
          renderFn: conflict => conflict && <WarningIcon tooltip="Deployment data has conflicts" />,
        });
      }

      return columns;
    });
  }

  startEdit(el, rowData, dataKey) {
    this.setState({
      editAnchorEl: el,
      editValue: rowData[dataKey],
      editKey: dataKey,
      editId: rowData.id,
      editError: null,
    });
  }

  stopEdit() {
    this.setState({ editAnchorEl: null });
  }

  submitEdit() {
    const { editValue, editKey, editId } = this.state;
    const inputType = ["start", "end"].includes(editKey) ? "datetime" : "select";
    const isRequired = editKey === "start";
    const editError = validateFormField(editValue, inputType, isRequired);

    this.setState({ editError });
    if (!editError) {
      const parsedValue = parseFormField(inputType, editValue);
      this.props.dispatch(updateDeployment(editId, { [editKey]: parsedValue }));
      this.stopEdit();
    }
  }

  handleEditChange(value) {
    this.setState({ editValue: value });
  }

  render() {
    const {
      classes,
      deployments,
      handleHoverOff,
      stations,
      searchText,
      setSelection,
      selection,
      scrollToId,
      selectedTimezone,
    } = this.props;
    const { editAnchorEl, editValue, editKey, editError } = this.state;
    const rows = getRows(deployments, selectedTimezone);
    const columns = this.getColumns(deployments);

    return (
      <div className={classes.root} onMouseOut={handleHoverOff}>
        <WindowedTable
          rows={rows}
          rowIdKey={"id"}
          selectable={true}
          onSelect={setSelection}
          selection={selection}
          selectOnRowClick
          initialSortBy={"stationName"}
          columns={columns}
          searchText={searchText}
          {...(scrollToId ? { scrollToId: scrollToId } : null)}
        />
        <InlineEditor anchorEl={editAnchorEl} onSubmit={this.submitEdit} onCancel={this.stopEdit}>
          {editKey === "start" || editKey === "end" ? (
            <div style={{ padding: 10 }}>
              <DateTimePicker
                value={editValue}
                onChange={this.handleEditChange}
                onSubmit={this.submitEdit}
                onCancel={this.stopEdit}
                helperText={editError || ""}
                autoFocus
                disableFuture={true}
              />
            </div>
          ) : (
            <>
              <StationPicker
                stations={stations}
                selectedId={editValue}
                handleInput={this.handleEditChange}
              />
              <FormHelperText error>{editError}</FormHelperText>
            </>
          )}
        </InlineEditor>
      </div>
    );
  }
}

function renderLatLonColumn(p) {
  return p ? `(${formatLatLon(p.latLon.latitude)}, ${formatLatLon(p.latLon.longitude)})` : "";
}

const getRows = memoize((deployments, selectedTimezone) =>
  deployments.map(de => {
    // If the user selected utc the formatTimeFromISOStringUTC fn is much faster
    const timeFormatFn =
      selectedTimezone === "UTC" ? formatTimeFromISOStringUTC : formatTimeFromISOStringDt;
    return {
      id: de.id,
      stationName: de.station && de.station.name,
      stationId: de.station && de.station.id,
      latestPosition: de.latestPosition,
      start: timeFormatFn(de.start),
      end: timeFormatFn(de.end),
      attachedDevices: getAttachedDevices(de),
      hasConflict: de.hasConflict,
      inStudies: de.inStudies,
    };
  })
);

DeploymentTable.propTypes = {
  deployments: PropTypes.arrayOf(PropTypes.object),
  stations: PropTypes.array,
  searchText: PropTypes.string,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
  scrollToId: PropTypes.string,
  handleHoverOff: PropTypes.func,
};

DeploymentTable.defaultProps = {
  editable: true,
};

export default connect()(withStyles(styles)(DeploymentTable));
