import { NetworkState, NetworkErrorDetail, NetworkAction } from "./network-types";

const noError: NetworkErrorDetail = {
  message: "",
  detail: null,
};

const initialState: NetworkState = {
  isOK: true,
  error: { ...noError },
  retryCount: 0,
};

export function networkReducer(
  state: NetworkState = initialState,
  action: NetworkAction
): NetworkState {
  switch (action.type) {
    case "NETWORK_SET_ERROR":
      return {
        isOK: false,
        error: action.payload,
        retryCount: state.retryCount + 1,
      };
    case "NETWORK_RESET_RETRYCOUNT":
      return {
        ...state,
        retryCount: 0,
      };
    default:
      return state;
  }
}
