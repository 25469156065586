//
// AWS Amplify / Cognito
//
export const AuthConfig = {
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  region: "us-east-1",
};

/**
 * Store data in the cookie if configured. We only store the Auth session data
 * in cookies in the production environment because the cookie was becoming
 * too large when we had staging & production session stored in cookie @ .fathomcentral domain
 */
if (process.env.REACT_APP_COOKIE_STORAGE_DOMAIN) {
  AuthConfig.cookieStorage = {
    domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
    expires: 30,
    secure: process.env.NODE_ENV === "development" ? false : true,
  };
}

//
// Innovasea services
//
export const FILE_SERVER_URL = process.env.REACT_APP_FILE_SERVER_URL;

export const DATA_SERVER_URL = process.env.REACT_APP_DATA_SERVER_URL;

export const GQL_SERVER_URL = process.env.REACT_APP_GQL_SERVER_URL;

export const FATHOM_POSITION_URL = process.env.REACT_APP_FATHOM_POSITION_URL;

export const VEMCO_LIVE_URL = process.env.REACT_APP_VEMCO_LIVE_URL;

export const SYSTEM_MANAGER_URL = process.env.REACT_APP_SYSTEM_MANAGER_URL;

export const LOGROCKET_URL = process.env.REACT_APP_LOGROCKET;

// External APIs
export const MapboxToken =
  "pk.eyJ1IjoiZnJhbmtzbWl0aCIsImEiOiJjanRwbnpzdGYwNm5jM3ltMjU0ZHp2MnhqIn0.mRK6Sv3AtWDfNIFKvLRQsw";
