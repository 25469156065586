import { useState, useEffect, useRef, RefObject } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NominalDomain } from "./detection-types";
import { DOMAIN_SCROLL_WIDTH, X_AXIS_EXTENT } from "./detection-consts";

/** Parameters used to calculate the domain window */
type WindowParams = {
  grabberHeight: number;
  /** the distance in pixels of the handle top relative to the top of the track */
  grabberTop: number;
  /** the size in pixels of each "step" the handle has to move to change the visible domain */
  trackStepHeight: number;
  /** The max length of the domainWindow */
  domainWindowLength: number;
};

const useStyles = makeStyles({
  outer: {
    position: "relative",
    display: "flex",
    height: "100%",
    paddingBottom: X_AXIS_EXTENT,
  },
  track: {
    position: "relative",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    width: DOMAIN_SCROLL_WIDTH,
    height: "100%",
    backgroundColor: "#DFDFDF",
    borderRadius: 5,
  },
  trackCenterLine: {
    position: "absolute",
    height: "100%",
    width: 1,
    backgroundColor: "#999",
  },
  handle: {
    position: "absolute",
    width: DOMAIN_SCROLL_WIDTH - 4, // gives a couple px on either side
    minHeight: 15,
    backgroundColor: "#777",
    borderRadius: 5,
    cursor: "grab",
    "&.disabled": {
      backgroundColor: "#EEE",
      cursor: "not-allowed",
    },
  },
});

function stopDragging() {
  // stop moving when mouse button is released:
  document.onmouseup = null;
  document.onmousemove = null;
}

/** Keep the grabber within the track */
function keepInBounds({ grabberHeight, trackHeight, grabberTop }) {
  const grabberBottom = grabberTop + grabberHeight;
  let adjustedGrabbertop = grabberTop;

  // set to start if past:
  if (grabberTop < 0) {
    adjustedGrabbertop = 0;
  }
  // set to end if past:
  if (grabberBottom > trackHeight) {
    adjustedGrabbertop = trackHeight - grabberHeight;
  }
  return adjustedGrabbertop;
}

export function DomainScrollbar({
  domain,
  stepHeight,
  setViewDomain,
  // debounceMs = 200,
  scrollRef,
  style,
  className,
}: {
  domain: NominalDomain;
  /** The number of pixels per step */
  stepHeight: number;
  setViewDomain: (domainWindow: NominalDomain) => void;
  /** Ref of container to add scroll listener for. Otherwise only scroll events on track itself
   *  will be captured. */
  scrollRef?: RefObject<HTMLDivElement>;
  // debounceMs?: number;
  style?: any;
  className?: string;
}) {
  const classes = useStyles();

  const trackRef = useRef<HTMLDivElement>(null);
  const grabberRef = useRef<HTMLDivElement>(null);
  const observer = useRef<ResizeObserver | null>(null);

  const [wheelDelta, setWheelDelta] = useState<number | null>(null);
  const [trackHeight, setTrackHeight] = useState(0);
  const [windowParams, setWindowParams] = useState<WindowParams>({
    grabberHeight: 0,
    grabberTop: 0,
    trackStepHeight: 0,
    domainWindowLength: 0,
  });

  // Use a ResizeObserver to trigger trackHeight state update and listen for scroll / click event to move the handle
  useEffect(() => {
    function handleScroll(e) {
      setWheelDelta(e.deltaY);
    }

    if (trackRef.current) {
      const track = trackRef.current;
      const scrollElem = scrollRef?.current || track;
      observer.current = new ResizeObserver(entries => {
        for (const entry of entries) {
          const height = entry.target.clientHeight;
          setTrackHeight(height);
        }
      });
      observer.current.observe(track);
      scrollElem.addEventListener("wheel", handleScroll);

      // clean up on unmount:
      return () => {
        observer.current?.unobserve(track);
        scrollElem.removeEventListener("wheel", handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Events that may change the domain window dimensions (track height, fullDomain) require
  // re-calculating domain scrolling variables:
  useEffect(() => {
    if (trackRef.current && grabberRef.current && domain) {
      /** the number of discrete nominal items (i.e. the domain array index length) */
      const domainSteps = domain.length;
      const domainHeight = domainSteps * stepHeight;
      // these are the domain scrolling variables that depend on the track & domain:
      let grabberHeight = grabberRef.current.clientHeight;
      let grabberTop = grabberRef.current.offsetTop;
      let domainWindowLength = domainSteps;
      let trackStepHeight = 1;

      // if the space calculated for the chart exceeds the available space, set the scroll grabber
      // height to equal the possible size:
      if (domainHeight > trackHeight) {
        domainWindowLength = Math.trunc(trackHeight / stepHeight);
        trackStepHeight = trackHeight / domainSteps;
        grabberHeight = domainWindowLength * trackStepHeight;
        // if the new size pushes the grabber past the extent, shift the grabber to the beginning
        if (grabberTop + grabberHeight >= trackHeight) {
          grabberTop = 0;
        }
      } else {
        // no need for scrolling:
        grabberHeight = trackHeight;
        grabberTop = 0;
      }
      setWindowParams({ grabberHeight, grabberTop, trackStepHeight, domainWindowLength });
    }
  }, [domain, trackHeight, stepHeight]);

  // Use changes in position of the grabber to shift the "view domain":
  useEffect(() => {
    const { grabberHeight, grabberTop, trackStepHeight } = windowParams;

    if (grabberHeight > 0 && trackStepHeight > 0 && domain?.length) {
      const domainStart = Math.floor(grabberTop / trackStepHeight);
      const domainEnd = domainStart + windowParams.domainWindowLength - 1;
      const viewDomain = domain.filter(
        (_, i) => i >= domainStart && i <= domainEnd
      ) as NominalDomain;
      setViewDomain(viewDomain);
    }
  }, [windowParams, domain, setViewDomain]);

  // Use wheel event to shift position of the grabber:
  useEffect(() => {
    if (wheelDelta !== null) {
      setWheelDelta(null);
      const { grabberHeight, trackStepHeight } = windowParams;
      if (grabberHeight > 0 && trackStepHeight > 0) {
        const shiftPx = (wheelDelta > 0 ? 1 : -1) * trackStepHeight;
        const grabberTop = keepInBounds({
          grabberHeight,
          trackHeight,
          grabberTop: windowParams.grabberTop + shiftPx,
        });
        if (grabberTop != windowParams.grabberTop) {
          setWindowParams(prev => ({ ...prev, grabberTop }));
        }
      }
    }
  }, [windowParams, wheelDelta, trackHeight]);

  /**
   * Handle the user dragging the scrollbar. The enclosed state variables should not change
   * whilst dragging (except for grabberTop) because that would require two cursors
   */
  function startDragging(e) {
    e.preventDefault();
    let mouseY0 = e.clientY; // initial mouse pos
    let offsetAccumulator = 0; // handle will slide in discrete steps (trackStepHeight)

    const { grabberHeight, trackStepHeight } = windowParams;
    const maxTop = trackHeight - grabberHeight;

    function dragging(e) {
      e.preventDefault();
      const mouseY = e.clientY;
      const offset = mouseY0 - mouseY;
      let _grabberTop = Number(grabberRef?.current?.offsetTop || 0);

      offsetAccumulator += offset;
      if (Math.abs(offsetAccumulator) >= trackStepHeight) {
        const shiftPx = Math.trunc(offsetAccumulator / trackStepHeight) * trackStepHeight;
        const grabberTop = keepInBounds({
          grabberHeight,
          trackHeight,
          grabberTop: _grabberTop - shiftPx,
        });

        if (grabberTop >= 0 && grabberTop <= maxTop) {
          setWindowParams(prev => ({ ...prev, grabberTop }));
          offsetAccumulator -= shiftPx; // if slightly over, keep the extra
          _grabberTop = _grabberTop - shiftPx; // need current value for preventing drift when limits reached
        }
      }
      if (_grabberTop >= 0 && _grabberTop <= maxTop) {
        mouseY0 = mouseY; // ensure mouse only effects the handle whilst in bounds
      }
    }
    // remove listeners when mouse released:
    document.onmouseup = stopDragging;
    // call a function whenever the cursor moves:
    document.onmousemove = dragging;
  }

  /** Clicking the track shifts the domain one "page" */
  function handleTrackClick(e) {
    e.preventDefault();

    // need to convert viewport coords to be relative to track:
    const target = trackRef.current as HTMLDivElement;
    const rect = target.getBoundingClientRect();
    const mouseY = e.clientY - rect.top;

    let grabberTop: any = null;
    if (mouseY < windowParams.grabberTop) {
      grabberTop = windowParams.grabberTop - windowParams.grabberHeight;
    } else if (mouseY > windowParams.grabberTop + windowParams.grabberHeight) {
      grabberTop = windowParams.grabberTop + windowParams.grabberHeight;
    }

    if (grabberTop !== null) {
      if (grabberTop < 0) grabberTop = 0;
      if (grabberTop + windowParams.grabberHeight > trackHeight) {
        grabberTop = trackHeight - windowParams.grabberHeight;
      }

      setWindowParams(prev => ({ ...prev, grabberTop }));
    }
  }

  const disabled = windowParams.grabberHeight == trackHeight;

  return (
    <div className={classes.outer}>
      <div
        ref={trackRef}
        onClick={!disabled ? handleTrackClick : undefined}
        className={`${classes.track} ${className || ""}`}
        style={style}
      >
        <div className={classes.trackCenterLine}></div>
        <div
          ref={grabberRef}
          className={`${classes.handle} ${(disabled && "disabled") || ""}`}
          style={{
            top: `${windowParams.grabberTop}px`,
            height: `${windowParams.grabberHeight}px`,
          }}
          onMouseDown={!disabled ? startDragging : undefined}
        ></div>
      </div>
    </div>
  );
}
