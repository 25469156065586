import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { WindowedTable } from "../../../fathom-brella";
import { useSelectorTyped as useSelector, useThunkDispatch } from "../../../redux/common";
import { importLiveCompany, getLiveCompanies } from "../../../redux/admin/live2/live-2-actions";
import { Button, Typography, TextField, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FlexRow from "../../common/FlexRow";
import DialogWrapper from "../../common/DialogWrapper";
import { getAllWorkspaces } from "../../../redux/admin/workspace/admin-workspace-actions";
import FlexCol from "../../common/FlexCol";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    padding: theme.spacing(2),
  },
  left: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginRight: theme.spacing(2),
    borderRight: "1px solid lightgray",
    minWidth: 300,
  },
  right: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  rightTitle: {
    marginRight: theme.spacing(2),
  },
  titleContainer: {
    minHeight: 50,
  },
  tableRoot: {
    minHeight: 200,
    maxHeight: "100%",

    height: "100%",
    width: "100%",
  },
}));

function SiteImporter() {
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const companies = useSelector(state => state.adminLive2.companies);
  companies.sort((a, b) => Number(Boolean(a.mappedWorkspace)) - Number(Boolean(b.mappedWorkspace)));

  const [selectedCompany, setSelectedCompany] = React.useState<any>(null);

  const [createLoading, setCreateLoading] = React.useState(false);
  const [selection, setSelection] = React.useState<string[]>([]);

  React.useEffect(() => {
    dispatch(getLiveCompanies());
    dispatch(getAllWorkspaces());
  }, [dispatch]);

  const importedCompanies = companies.filter(c => c.mappedWorkspace);

  function clearForm() {
    setDialogOpen(false);
    setSelectedCompany("");
  }

  async function submit() {
    setCreateLoading(true);
    dispatch(importLiveCompany(selectedCompany.id))
      .then(() => {
        clearForm();
      })
      .finally(() => setCreateLoading(false));
  }

  return (
    <div className={classes.main}>
      <div className={classes.right}>
        <FlexRow className={classes.titleContainer} spaceBetween>
          <Typography className={classes.rightTitle} onClick={() => setDialogOpen(false)}>
            Imported Live Companies into Central
          </Typography>
          <FlexRow>
            <Button
              variant="contained"
              color="primary"
              style={{ height: 35 }}
              onClick={() => setDialogOpen(true)}
            >
              Import Company
            </Button>
          </FlexRow>
        </FlexRow>

        <WindowedTable
          initialSortBy={"siteName"}
          initialSortDir={"ASC"}
          rows={importedCompanies}
          rowIdKey={"id"}
          selectable={true}
          selection={selection}
          onSelect={setSelection}
          columns={[
            {
              width: 125,
              label: "Company",
              dataKey: "id",
            },
            {
              width: 125,
              label: "Workspace",
              dataKey: "mappedWorkspace",
              renderFn: workspace => workspace.name,
            },
            {
              width: 125,
              label: "Site(s)",
              dataKey: "sites",
              renderFn: sites => sites.map(s => s.name).join(", "),
            },
          ]}
        />
      </div>
      <DialogWrapper
        title={"Import Live Company to Central"}
        cancelAction={() => setDialogOpen(false)}
        okAction={submit}
        open={dialogOpen}
        buttons={({ cancelAction, okAction }) => (
          <>
            {createLoading && <CircularProgress size={20} />}
            <Button variant="outlined" onClick={cancelAction}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedCompany}
              onClick={okAction}
            >
              Import
            </Button>
          </>
        )}
      >
        {/* Company selector */}
        <FlexCol>
          <Autocomplete
            id="site-selector"
            options={companies}
            value={selectedCompany}
            onChange={(_, value) => setSelectedCompany(value)}
            getOptionLabel={(company: any) => company.id || ""}
            groupBy={company => (company.mappedWorkspace ? "Re-Import" : "Not Currently Imported")}
            renderInput={params => (
              <TextField
                {...params}
                label="Select Company"
                helperText={"Please select live company to import"}
              />
            )}
          />
        </FlexCol>
        {selectedCompany && (
          <FlexCol style={{ marginTop: 20 }}>
            <Typography>
              Region{selectedCompany.sites.length > 1 ? "s" : ""} to be imported:{" "}
            </Typography>
            <ul>
              {selectedCompany.sites.map(s => {
                return <li key={s.id}>{s.name}</li>;
              })}
            </ul>
            <Typography variant="caption">
              {selectedCompany.mappedWorkspace ? (
                <>
                  Note: This company has already been imported. The workspace already linked to this
                  company will be emptied and the data will be re-imported from scratch. A new study
                  will be created for each region.
                </>
              ) : (
                <>
                  Note: A new workspace will be created for the company, containing a new study for
                  each region.
                </>
              )}
            </Typography>
          </FlexCol>
        )}
      </DialogWrapper>
    </div>
  );
}

export default SiteImporter;
