import { ANIMALS_SET_LIST, ANIMALS_UPDATE_ONE } from "../action-types";

const initialState = {
  animals: null,
  loading: true,
  error: null,
};
export default function animalsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ANIMALS_SET_LIST:
      return {
        ...state,
        animals: payload.animals,
        loading: false,
      };
    case ANIMALS_UPDATE_ONE: {
      const animals = [...state.animals];
      const index = animals.findIndex(animal => animal.id === payload.animalId);
      const updateAnimal = animals[index];
      updateAnimal.measurementSets = updateAnimal.measurementSets || [];
      const newMeasurementSet = payload.measurementSet;
      newMeasurementSet.id = payload.measurementSetId;
      updateAnimal.measurementSets.push(newMeasurementSet);
      animals[index] = updateAnimal;
      return {
        ...state,
        animals: animals,
      };
    }
    default:
      return state;
  }
}
