import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import autoBind from "auto-bind/react";
import { withStyles } from "@material-ui/core/styles";

import { addDeploymentPosition } from "../../redux/deployments/deployments-actions";
import { deploymentSizeUnits, positionLabels } from "./constants";
import FormDialog from "../common/FormDialog";
import { initialFormState, typeCastValues, removeEmptyFields } from "../../helpers/common";
import { isEmpty } from "lodash";
import { validateDeploymentSubObject } from "./utils";
import GraphFieldInput from "../common/GraphFieldInput";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  bodyText: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
});

const fields = [
  {
    dataKey: "latitude",
    label: positionLabels.latitude.label,
    helperText: positionLabels.latitude.helperText,
    inputType: "number",
    isRequired: true,
  },
  {
    dataKey: "longitude",
    label: positionLabels.longitude.label,
    helperText: positionLabels.longitude.helperText,
    inputType: "number",
    isRequired: true,
  },
  {
    dataKey: "depth",
    label: positionLabels.depth.label,
    helperText: positionLabels.depth.helperText,
    inputType: "measurement",
    valueWidth: 120,
    unitWidth: 60,
    unitOptions: deploymentSizeUnits,
    unitDefault: "m",
    isDefaultSelected: true,
  },
  {
    dataKey: "start",
    label: positionLabels.start.label,
    helperText: positionLabels.start.helperText,
    inputType: "datetime",
    disableFuture: true,
  },
  {
    dataKey: "end",
    label: positionLabels.end.label,
    helperText: positionLabels.end.helperText,
    inputType: "datetime",
    disableFuture: true,
  },
];

class AddPositionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = initialFormState(fields);
    autoBind(this);
  }

  validate() {
    const { fieldErrors, formErrors } = validateDeploymentSubObject(
      this.state.values,
      fields,
      this.props.deployment
    );
    this.setState({ fieldErrors, formErrors });
    return isEmpty(fieldErrors) && isEmpty(formErrors);
  }

  handleSubmit() {
    if (this.validate()) {
      const parsedValues = typeCastValues(this.state.values, fields);
      const cleanedValues = removeEmptyFields(parsedValues, fields);
      const { start, end, latitude, longitude, depth } = cleanedValues;
      const positionInput = { depth, manualLatLon: { latitude, longitude }, start, end };
      this.props.dispatch(addDeploymentPosition(this.props.deployment.id, positionInput));
      this.close();
    }
  }

  close() {
    this.setState(initialFormState(fields));
    this.props.toggle();
  }

  handleInput(dataKey, value) {
    this.setState({
      values: { ...this.state.values, [dataKey]: value },
      errors: { ...this.state.errors, [dataKey]: null },
    });
  }

  renderInput(dataKey) {
    return (
      <GraphFieldInput
        field={fields.find(fld => fld.dataKey === dataKey)}
        fieldValue={this.state.values[dataKey]}
        fieldError={this.state.fieldErrors[dataKey]}
        handleInput={this.handleInput}
      />
    );
  }

  render() {
    const { open, classes } = this.props;
    const { formErrors } = this.state;
    return (
      <FormDialog
        open={open}
        mode={"add"}
        title={"Record a Position"}
        handleSubmit={this.handleSubmit}
        handleClose={this.close}
        maxWidth="md"
        formErrors={formErrors}
      >
        <div className={classes.row}>
          {this.renderInput("latitude")}
          {this.renderInput("longitude")}
          {this.renderInput("depth")}
        </div>
        <Typography variant="body2" className={classes.bodyText}>
          If start and end times are blank the position will apply for the whole deployment.
        </Typography>
        <div className={classes.row}>
          {this.renderInput("start")}
          {this.renderInput("end")}
        </div>
      </FormDialog>
    );
  }
}

AddPositionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  deployment: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(AddPositionDialog));
