import {
  RX_SERIES_TYPES,
  RxSeries,
  RxSeriesType,
  RxDiagAction,
  LoadStatus,
  RxEvent,
  RxEventType,
} from "./rx-diag-types";

export type RxDiagState = {
  selectedFiles: string[]; // the files that the user has selected in the UI
  selectedSeriesTypes: RxSeriesType[]; // the series types the user has selected in the UI
  selectedEventTypes: RxEventType[]; // the event types the user has selected in the UI

  dataListStatusByFile: { [filename: string]: LoadStatus }; // status of first API call to see which datatypes exist for the given file

  seriesStatuses: { filename: string; type: RxSeriesType; status: LoadStatus }[]; // status of follow-up calls for each type of series data in flat format
  eventsStatuses: { filename: string; status: LoadStatus }[]; // status of follow-up call to fetch event data in flat format

  loadedSeries: RxSeries[]; // all loaded series in flat format
  loadedEvents: RxEvent[]; // all loaded events in flat format
};

const initialState: RxDiagState = {
  selectedFiles: [],
  selectedSeriesTypes: [],
  selectedEventTypes: [],

  dataListStatusByFile: {},

  seriesStatuses: [],
  eventsStatuses: [],

  loadedSeries: [],
  loadedEvents: [],
};

export default function rxDiagReducer(
  state: RxDiagState = initialState,
  action: RxDiagAction
): RxDiagState {
  switch (action.type) {
    case "RXDIAG_SELECT_FILES":
      return { ...state, selectedFiles: action.payload.filenames };
    case "RXDIAG_SELECT_SERIES_TYPES":
      return { ...state, selectedSeriesTypes: action.payload.seriesTypes };
    case "RXDIAG_SELECT_EVENT_TYPES":
      return { ...state, selectedEventTypes: action.payload.eventTypes };

    case "RXDIAG_DATALIST_LOADING": {
      return {
        ...state,
        dataListStatusByFile: {
          ...state.dataListStatusByFile,
          [action.payload.filename]: "loading",
        },
      };
    }

    case "RXDIAG_DATALIST_LOADED": {
      const { filename, dataTypes } = action.payload;
      const eventsAvailable = dataTypes.includes("events");
      const seriesTypes = RX_SERIES_TYPES.filter(type => dataTypes.includes(type));

      return {
        ...state,
        dataListStatusByFile: {
          ...state.dataListStatusByFile,
          [action.payload.filename]: "loaded",
        },
        seriesStatuses: state.seriesStatuses
          .filter(status => status.filename !== filename)
          .concat(seriesTypes.map(type => ({ type, filename, status: "unloaded" }))),
        eventsStatuses: eventsAvailable
          ? state.eventsStatuses
              .filter(status => status.filename !== filename)
              .concat({ filename, status: "unloaded" })
          : state.eventsStatuses,
      };
    }

    case "RXDIAG_SERIES_LOADING": {
      const { filename, seriesType } = action.payload;

      const seriesStatuses = state.seriesStatuses
        .filter(status => status.filename !== filename || status.type !== seriesType)
        .concat({ filename, type: seriesType, status: "loaded" });

      return {
        ...state,
        seriesStatuses: seriesStatuses,
      };
    }

    case "RXDIAG_SERIES_LOADED": {
      const { series } = action.payload;
      const { filename, type } = series;

      const seriesStatuses = state.seriesStatuses
        .filter(status => status.filename !== filename || status.type !== type)
        .concat({ filename, type, status: "loaded" });

      return {
        ...state,
        seriesStatuses: seriesStatuses,
        loadedSeries: [...state.loadedSeries, series],
      };
    }

    case "RXDIAG_EVENTS_LOADING": {
      const { filename } = action.payload;
      const eventsStatuses = state.eventsStatuses
        .filter(status => status.filename !== filename)
        .concat({ filename, status: "loading" });

      return {
        ...state,
        eventsStatuses,
      };
    }

    case "RXDIAG_EVENTS_LOADED": {
      const { filename, events } = action.payload;

      const eventsStatuses = state.eventsStatuses
        .filter(status => status.filename !== filename)
        .concat({ filename, status: "loaded" });

      const fullEvents = events.map(event => ({ filename, ...event }));

      return {
        ...state,
        eventsStatuses,
        loadedEvents: [...state.loadedEvents, ...fullEvents],
      };
    }

    default:
      return state;
  }
}
