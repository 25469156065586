import { TxSpec, Receiver, TimeInterval } from "../containers/DetectionAnalysis";
import { getTxInterval } from "./detection";
import { getDistanceFromLatLonInKm } from "./map";
import { formatTimeFromISOStringDt } from "./time";

type DetCount = {
  period: string;
  serial: string;
  fullId: string;
  count: number;
};

type DetectionData = {
  detectionCount: DetCount[];
};

type DetRx = {
  deviceName: string;
  stationName: string;
  latitude: number | null;
  longitude: number | null;
};

type DetTx = {
  fullId: string;
  latitude: number | null;
  longitude: number | null;
  idealDetCount: number;
};

export type DetectionDataPoint = {
  period: string;
  rx: DetRx;
  tx: DetTx;
  detections: number;
  detPercent: number;
  distance: number;
};

/** Simple map of count of tx ids per device id. This is used to properly calculate the
 * transmission time for the case where more than one id (e.g. L/H or dual saensor)
 */
export type DeviceTxCounts = {
  [key: string]: number;
};

export function parseData({
  txList = [],
  rxList = [],
  detectionData,
  timeInterval,
  deviceTxCounts,
}: {
  txList: TxSpec[];
  rxList: Receiver[];
  detectionData: DetectionData | null;
  timeInterval: TimeInterval;
  deviceTxCounts: DeviceTxCounts;
}): DetectionDataPoint[] {
  const counts: DetectionDataPoint[] = [];

  if (!detectionData || !txList.length || !rxList.length) {
    return counts;
  }

  const intervalSeconds = timeInterval * 60 * 60;
  const intervalMS = intervalSeconds * 1000;
  const txMap: { [fullId: string]: DetTx } = {};
  const rxMap: { [serial: string]: DetRx } = {};
  const distMap: { [serialIdPair: string]: number } = {};
  const errors: any = [];

  let overallStartTS = new Date(txList[0].position!.start).getTime();
  let overallEndTS = new Date(txList[0].position!.end).getTime();

  txList.forEach(({ deviceId, fullId, position, delayMin, delayMax }) => {
    // track overall start/end from all txs
    const txStart = new Date(position!.start).getTime();
    if (txStart > overallStartTS) {
      overallStartTS = txStart;
    }
    const txEnd = new Date(position!.end).getTime();
    if (txEnd < overallEndTS) {
      overallEndTS = txEnd;
    }

    // Make sure the transmit interval includes the time to transmit both ids if more than one:
    const txIntervalSeconds =
      (getTxInterval({ fullId, delayMin, delayMax }) || 0) * deviceTxCounts[deviceId];

    if (position?.latitude && position?.longitude && txIntervalSeconds) {
      txMap[fullId] = {
        fullId,
        latitude: position.latitude,
        longitude: position.longitude,
        idealDetCount: Math.round(intervalSeconds / txIntervalSeconds),
      };
    } else {
      errors.push(`${fullId} missing position or transmission interval`);
    }
  });

  rxList.forEach(({ model, serial, position }) => {
    const deviceName = `${model}-${serial}`;
    if (position?.latitude && position?.longitude) {
      rxMap[serial] = {
        stationName: position.stationName || deviceName,
        deviceName,
        latitude: position.latitude,
        longitude: position.longitude,
      };
    } else {
      errors.push(`${deviceName} missing position`);
    }
  });

  for (const fullId in txMap) {
    for (const serial in rxMap) {
      distMap[`${serial}_${fullId}`] = Number(
        (
          getDistanceFromLatLonInKm(
            txMap[fullId].latitude,
            txMap[fullId].longitude,
            rxMap[serial].latitude,
            rxMap[serial].longitude
          ) * 1000
        ).toFixed(2)
      );
    }
  }

  // The data in detectionData.detectionCount doesn't include periods with zero counts
  // We need manually add them so that they are included in the avg. calculations
  // Each rx needs a count for each tx, for each interval

  // reorganize detectionData.detectionCount into a map of period_serial_fullid to DetCount
  const detectionCountMap = new Map<string, DetCount>();
  detectionData?.detectionCount?.forEach(dc => {
    const compositeKey = generateCompositeKey(dc.period, dc.serial, dc.fullId);
    detectionCountMap.set(compositeKey, dc);
  });

  // use overall start end from txlist to build a zero-filled array of detection counts
  const zeroFilledDetectionCounts = zeroFillDetectionCounts(
    overallStartTS,
    overallEndTS,
    rxList,
    txList,
    detectionCountMap,
    intervalMS
  );

  zeroFilledDetectionCounts.forEach(dc => {
    const rx = rxMap[dc.serial];
    const tx = txMap[dc.fullId];

    if (rx && tx?.idealDetCount) {
      const detDataPt: DetectionDataPoint = {
        period: formatTimeFromISOStringDt(dc.period),
        detections: Number(dc.count) || 0,
        rx,
        tx,
        distance: distMap[`${dc.serial}_${dc.fullId}`],
        detPercent: dc.count / tx.idealDetCount,
      };

      counts.push(detDataPt);
    }
  });

  return counts;
}

function zeroFillDetectionCounts(
  startTS: number,
  endTS: number,
  rxList: Receiver[],
  txList: TxSpec[],
  detectionCountMap: Map<string, DetCount>,
  intervalMS: number
): DetCount[] {
  const zeroFilledDetectionCount: DetCount[] = [];
  let currentPeriodTS = Math.floor(startTS / intervalMS) * intervalMS;
  const lastPeriodTS = Math.ceil(endTS / intervalMS) * intervalMS;

  while (currentPeriodTS < lastPeriodTS) {
    const currentPeriodISO = new Date(currentPeriodTS).toISOString();
    txList.forEach(tx => {
      rxList.forEach(rx => {
        const compositeKey = generateCompositeKey(currentPeriodISO, rx.serial, tx.fullId);
        if (detectionCountMap.has(compositeKey)) {
          const detCountForPeriod = detectionCountMap.get(compositeKey);
          zeroFilledDetectionCount.push(detCountForPeriod!);
        } else {
          const fillerDetCount: DetCount = {
            period: currentPeriodISO,
            serial: rx.serial,
            fullId: tx.fullId,
            count: 0,
          };
          zeroFilledDetectionCount.push(fillerDetCount);
        }
      });
    });
    currentPeriodTS = currentPeriodTS + intervalMS;
  }

  return zeroFilledDetectionCount;
}

function generateCompositeKey(periodISO: string, rxSerial: string, txFullId: string): string {
  const compositeKey = `${periodISO}_${txFullId}_${rxSerial}`;
  return compositeKey;
}
