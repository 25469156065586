import React from "react";
import PropTypes from "prop-types";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  search: {
    position: "relative",
    flexGrow: 1,
    alignItems: "center",
    display: "flex",
    padding: "0px 10px 0px 10px",
    borderRadius: 20,
    border: "1px solid lightgray",
    "&:hover": {
      border: "1px solid gray",
    },
  },
  inputRoot: {
    flexGrow: 1,
    marginRight: 20,
  },
  input: {
    fontSize: 12,
    paddingLeft: 4,
    paddingRight: 0,
    minWidth: 80,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  iconClear: {
    position: "absolute",
    right: 0,
    color: "lightgray",
    "&:hover": {
      color: "gray",
    },
  },
};

function TableSearchBar({ searchText, handleSearch, classes }) {
  return (
    <div className={classes.search}>
      <SearchIcon fontSize="small" color="disabled" />
      <InputBase
        placeholder="Search the table"
        classes={{
          root: classes.inputRoot,
          input: classes.input,
        }}
        inputProps={{ "aria-label": "search the table" }}
        value={searchText}
        onChange={event => handleSearch(event.target.value)}
      />
      <IconButton
        size="small"
        disabled={!searchText}
        onClick={() => handleSearch("")}
        className={classes.iconClear}
      >
        {searchText ? <ClearIcon fontSize="small" /> : null}
      </IconButton>
    </div>
  );
}

TableSearchBar.propTypes = {
  seatchText: PropTypes.string,
  handleSearch: PropTypes.func,
};

export default withStyles(styles)(TableSearchBar);
