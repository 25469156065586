import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import {
  Edit as IconEdit,
  Check as IconCheck,
  Close as IconClose,
  RestoreFromTrash as IconRestoreFromTrash,
  DeleteForever as IconDelete,
} from "@material-ui/icons";
import { WindowedTable } from "../../fathom-brella";

const styles = {
  root: {
    minHeight: 200,
    maxHeight: "100%",
    height: "100%",
    width: "100%",
  },
};

class WorkspacesTable extends Component {
  render() {
    const {
      classes,
      allWorkspaces,
      searchText,
      setWorkspaceToEditId,
      restoreWorkspace,
      deleteWorkspace,
    } = this.props;

    return (
      <div className={classes.root}>
        <WindowedTable
          rows={allWorkspaces}
          rowIdKey={"id"}
          searchText={searchText}
          columns={[
            {
              label: "Workspace Id",
              dataKey: "id",
              width: 130,
              flexGrow: 0,
              flexSrink: 1,
              searchTextFn: id => id,
              renderFn: (id, rowData) => (
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 8, width: 30 }}>{id}</div>
                  {rowData.isDeleted ? (
                    <IconButton
                      size="small"
                      onClick={() => restoreWorkspace({ workspaceId: rowData.id })}
                      title="Restore this workspace"
                    >
                      <IconRestoreFromTrash />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        size="small"
                        onClick={() => setWorkspaceToEditId(rowData.id)}
                        title="Edit this workspace"
                      >
                        <IconEdit />
                      </IconButton>
                      <IconButton
                        style={{ marginLeft: 8 }}
                        size="small"
                        onClick={() => deleteWorkspace({ workspaceId: rowData.id })}
                        title="Delete this workspace"
                      >
                        <IconDelete />
                      </IconButton>
                    </>
                  )}
                </div>
              ),
            },
            {
              label: "Name",
              dataKey: "name",
              width: 80,
              flexGrow: 1,
              flexSrink: 0,
            },
            {
              label: "Description",
              dataKey: "description",
              width: 100,
              flexGrow: 2,
              flexSrink: 0,
            },
            {
              label: "Created by",
              dataKey: "creator",
              width: 70,
              renderFn: creator => creator.email,
            },
            {
              label: "Created on",
              dataKey: "created",
              width: 200,
              flexGrow: 0,
              flexSrink: 1,
            },

            {
              label: "Users",
              dataKey: "users",
              width: 50,
              flexGrow: 0,
              flexSrink: 1,
              searchTextFn: () => "",
              renderFn: users => (users || []).length,
            },
            {
              width: 80,
              label: "isPersonal",
              dataKey: "isPersonal",
              flexGrow: 0,
              flexSrink: 1,
              searchTextFn: () => "",
              renderFn: isPersonal => (isPersonal ? <IconCheck /> : <IconClose />),
              sortFn: isPersonal => (isPersonal ? 1 : -1),
            },
            {
              width: 80,
              label: "isDeleted",
              dataKey: "isDeleted",
              flexGrow: 0,
              flexSrink: 1,
              searchTextFn: () => "",
              renderFn: isDeleted => (isDeleted ? <IconCheck /> : <IconClose />),
              sortFn: isDeleted => (isDeleted ? 1 : -1),
            },
          ]}
        />
      </div>
    );
  }
}

WorkspacesTable.propTypes = {
  allWorkspaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      isPersonal: PropTypes.bool,
      isDeleted: PropTypes.bool,
      created: PropTypes.string,
      syspropContactId: PropTypes.string,
      creator: PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
      }),
      users: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  setWorkspaceToEditId: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  restoreWorkspace: PropTypes.func.isRequired,
  deleteWorkspace: PropTypes.func.isRequired,
};

export default withStyles(styles)(WorkspacesTable);
