export type PipelineFailure = {
  id: number;
  fileId: string;
  fileName: string;
  workspaceId: number;
  workspaces?: string[];
  errorPhase: string;
  errorStep: string;
  errorMessage: string;
  creationTime: string;
  updateTime: string;
  mergeInfo?: MergeInfo;
  resolved: boolean;
};

type MergeInfo = {
  mergeTime: string;
  mergeStatus: PhaseStatus;
};

type PhaseStatus = {
  phaseStatusId: number;
  phaseStatus: string;
};

export type FailedFile = PipelineFailure & {
  history: PipelineFailure[];
  status?: "PROCESSING" | "COMPLETE" | "FAILED";
};
export const REPROCESS_STATUS_COLOR = {
  PROCESSING: "skyblue",
  COMPLETE: "lightgreen",
  FAILED: "coral",
};

export type PipelineAction =
  | {
      type: "PIPELINE_FAILURES_START_LOADING_DATA";
    }
  | {
      type: "PIPELINE_FAILURES_SET_DATA";
      payload: { list: PipelineFailure[] };
    }
  | {
      type: "PIPELINE_FAILURES_ERROR";
      payload: { message: string };
    }
  | {
      type: "PIPELINE_MONITORING_SET_ACTIVE";
    }
  | {
      type: "PIPELINE_STOP_MONITORING";
    }
  | {
      type: "PIPELINE_MONITORING_STOPPED";
    }
  | {
      type: "PIPELINE_ADD_REPROCESSING";
      payload: { files: FailedFile[] };
    }
  | {
      type: "PIPELINE_SET_REPROCESSING";
      payload: { files: FailedFile[] };
    }
  | {
      type: "PIPELINE_CLEAR_REPROCESSED";
    };

/** Top level redux state for pipeline */
export type PipelineState = {
  failures: {
    loading: boolean;
    error: string | null;
    list: PipelineFailure[];
  };
  reprocessing: {
    active: boolean;
    stop?: boolean;
    files: FailedFile[];
  };
};
