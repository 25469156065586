import { useState, useEffect, useRef, Fragment } from "react";
import classNames from "classnames";
import * as d3 from "d3";
import { makeStyles } from "@material-ui/core/styles";
import { MARGIN } from "./constants";
import { WorkspaceCountRecord, CountType } from "./types";
import { COUNT_TYPE_OPTIONS } from "./constants";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    width: "100%",
    display: "flex",
  },
  svg: {
    display: "block",
    flexGrow: 1,
  },
  plotLine: {
    fill: "none",
    strokeWidth: 3,
    transition: "stroke-width 0.5s",
  },
  plotLineHovered: {
    strokeWidth: 6,
    transition: "stroke-width 0.2s",
  },
  plotLineHoverTrigger: {
    fill: "none",
    strokeWidth: 12,
    stroke: "black",
    opacity: 0,
    "&:hovered": {
      strokeWidth: 18,
    },
  },
  toolTip: {
    position: "fixed",
    pointerEvents: "none",
    transition: "opacity 0.2s",
    background: "white",
    borderRadius: 3,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1)}px`,
    boxShadow: theme.shadows[5],
    textAlign: "start",
  },
  interiorBackground: {
    fill: "lightgray",
    opacity: 0.1,
  },
}));

type Props = {
  height: number;
  data: WorkspaceCountRecord[];
  xScale: d3.ScaleTime<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  colorScale: d3.ScaleOrdinal<string, string>;
  yVariable: string;
  varsToShow: CountType[];
  width: number;
};

function Chart({ height, yVariable, data, colorScale, xScale, yScale, varsToShow, width }: Props) {
  const classes = useStyles();
  const svgRef = useRef<SVGSVGElement>(null);
  const [hoveredType, setHoveredType] = useState<CountType | null>(null);
  const [toolTipMouse, setToolTipMouse] = useState<{ x: number; y: number } | null>(null);
  const [toolTipVisible, setToolTipVisible] = useState(false);

  const innerWidth = Math.max(width - MARGIN.left - MARGIN.right, 0);
  const yAxisGroupRef = useRef<SVGGElement>(null);
  const lineGenerator = (type: CountType) =>
    d3
      .line<WorkspaceCountRecord>()
      .x(d => xScale(d.countDate) as number)
      .y(d => yScale(d[type]) as number)
      .curve(d3.curveLinear);

  // update the yAxis if the scale changes
  useEffect(() => {
    const ticks = yScale.ticks(4).filter(tick => Math.abs(tick - Math.round(tick)) < 0.001);
    const yAxis = d3.axisLeft(yScale).tickValues(ticks).tickSizeOuter(0).tickFormat(d3.format("d"));
    d3.select(yAxisGroupRef.current).call(yAxis);
  }, [yScale]);

  return (
    <>
      <div
        className={classes.root}
        onMouseMove={({ clientX, clientY }) => setToolTipMouse({ x: clientX, y: clientY })}
      >
        <svg ref={svgRef} className={classes.svg} style={{ height }} overflow="visible">
          <text
            transform="rotate(-90)"
            y={0}
            x={-height / 2}
            dy="1em"
            fontFamily="sans-serif"
            fontSize="12px"
            textAnchor="middle"
            style={{ pointerEvents: "none", userSelect: "none" }}
          >
            {yVariable}
          </text>
          <g name="margin-group" transform={`translate(${MARGIN.left}, 0)`}>
            <rect height={height} width={innerWidth} className={classes.interiorBackground} />
            <g ref={yAxisGroupRef} style={{ pointerEvents: "none", userSelect: "none" }} />
            <clipPath id="clip">
              <rect x={0} y={0} width={innerWidth} height={height} />
            </clipPath>
            <g clipPath="url(#clip)">
              <g name="interior">
                {varsToShow.map(type => {
                  const d = lineGenerator(type)(data)!;
                  return (
                    <Fragment key={type}>
                      <path
                        d={d}
                        stroke={colorScale(type)}
                        className={classNames(classes.plotLine, {
                          [classes.plotLineHovered]: toolTipVisible && type === hoveredType,
                        })}
                      />
                      <path
                        d={d}
                        className={classes.plotLineHoverTrigger}
                        onMouseOver={() => {
                          setHoveredType(type);
                          setToolTipVisible(true);
                        }}
                        onMouseLeave={() => setToolTipVisible(false)}
                      />
                    </Fragment>
                  );
                })}
              </g>
            </g>
          </g>
        </svg>
      </div>
      <Typography
        className={classes.toolTip}
        style={{
          left: toolTipMouse ? toolTipMouse.x - 45 : 0,
          top: toolTipMouse ? toolTipMouse.y - 45 : 0,
          opacity: toolTipVisible ? 1 : 0,
        }}
      >
        {COUNT_TYPE_OPTIONS.find(opt => opt.value === hoveredType)?.label}
      </Typography>
    </>
  );
}

export default Chart;
