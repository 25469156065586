import { bisector } from "d3-array";
import { RxSeriesDatum } from "../../../redux/rxdiag/rx-diag-types";
const xBisector = bisector<RxSeriesDatum, number>(d => d.x.getTime()).left;

export function interpolateLinear(x: number, data: RxSeriesDatum[]) {
  if (liesOnOrOutsideExtent(x, data)) {
    return null;
  }

  const nextIdx = xBisector(data, x);

  const prev = data[nextIdx - 1];
  const next = data[nextIdx];

  return _interpolateLinear(x, prev.x.getTime(), next.x.getTime(), prev.y, next.y);
}

export function interpolatePrev(x: number, data: RxSeriesDatum[]) {
  if (liesOnOrOutsideExtent(x, data)) {
    return null;
  }
  const nextIdx = xBisector(data, x);

  return data[nextIdx - 1].y;
}

function _interpolateLinear(x: number, x0: number, x1: number, y0: number, y1: number) {
  return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0);
}

function liesOnOrOutsideExtent(x: number, data: RxSeriesDatum[]) {
  return data.length === 0 || x <= data[0].x.getTime() || data[data.length - 1].x.getTime() <= x;
}
