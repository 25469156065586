import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  line: {
    stroke: "black",
    strokeWidth: "1px",
    pointerEvents: "none",
  },
  text: {
    pointerEvents: "none",
    alignmentBaseline: "middle",
    fontSize: "10pt",
    fontFamily: theme.typography.fontFamily,
  },
  circle: {
    pointerEvents: "none",
  },
});

function ReadoutLine({ x, y, timeString, classes }) {
  return (
    <>
      <line x1={x} x2={x} y1={0} y2={"100%"} className={classes.line} />
      <text x={x + 20} y={y} className={classes.text}>
        {timeString}
      </text>
      {/* {valuesBySeries.flat().map((d, i) => (
        <circle r={3} fill="black" cx={x} cy={d.y} key={i} className={classes.circle} />
      ))} */}
    </>
  );
}

ReadoutLine.propTypes = {
  // valuesBySeries: PropTypes.array,
  mouse: PropTypes.arrayOf(PropTypes.number),
  x: PropTypes.number,
  y: PropTypes.number,
  timeString: PropTypes.string,
};

export default withStyles(styles)(ReadoutLine);
