import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";

const styles = theme => ({
  root: {
    margin: "auto",
    padding: theme.spacing(2),
    maxWidth: 600,
  },
});

const CenterDialog = React.forwardRef(({ classes, children }, ref) => (
  <Paper className={classes.root} ref={ref}>
    {children}
  </Paper>
));

export default withStyles(styles)(CenterDialog);
