import { FILE_SERVER_URL, DATA_SERVER_URL } from "../../config";
import { axios, callGqlApi, extractMessage } from "../../helpers/api";
import {
  snackbarError,
  basicSnackbar,
  customSnackbar,
  removeSnackbar,
  startProgressSnackbar,
  updateProgressSnackbar,
  fileProcessingSnack,
} from "../snackbar/snackbar-actions";
import { addToStudy, removeFromStudy } from "../study/study-actions";
import { formatDateTimeFilename } from "../../helpers/time";
import { Thunk } from "../common";
import { FilesAction } from "./files-types";
import { SnackBarAction } from "../snackbar/snackbar-types";
import gql from "../gqlTag";
import { handleGqlErrors } from "../gql-error/gql-error-actions";
import { isObjEmpty, isInternalEmail } from "../../helpers/common";

const FILE_API_URL = FILE_SERVER_URL + "/files";

function workspaceIsInternal(workspace: any): boolean {
  return workspace.creator.email && isInternalEmail(workspace.creator.email);
}
// API CALLS
export function listFilesApi(): Thunk<Promise<void>, FilesAction | SnackBarAction> {
  const filesQuery = gql`
    {
      files {
        id
        name
        lastModified
        sizeInBytes
        type
        processing
        error
        rxLogStatus {
          merging
          parquet
        }
        rxLogProperties {
          model
          receiverFwVersion {
            major
            minor
            patch
          }
          serial
          initializationTime
          offloadTime
          firstRecordTime
          lastRecordTime
          minRecordTime
          maxRecordTime
          counts {
            ppmExternalAccepted
            ppmExternalRejected
            ppmSyncTagAccepted
            ppmSyncTagRejected
            hrExternalAccepted
            hrExternalRejected
            hrSyncTagAccepted
            hrSyncTagRejected
            pings
          }
        }
      }
    }
  `;
  return (dispatch, getState) => {
    const initialLoaded = getState().files.isLoaded;
    return callGqlApi(filesQuery)
      .then(data => {
        const fileList = data.files.map(f => ({
          ...f,
          // because of GQL limitations on int size it's passed as string
          size: parseInt(f.sizeInBytes),
          error: f?.rxLogStatus?.parquet === "fail" || f?.rxLogStatus?.merging === "fail",
          logFileReady: f?.rxLogStatus?.parquet === "ready",
          mergingReady: f?.rxLogStatus?.merging === "ready",
          rxLogProperties: !isObjEmpty(f.rxLogProperties) ? f.rxLogProperties : null,
        }));
        if (fileList.some(f => f.error)) {
          const selectedWorkspace = getState()?.workspaces?.selectedWorkspace;
          const errorDetails = {
            error: "Workspace has log files that contain errors",
            workspace: selectedWorkspace,
            files: fileList
              .filter(f => f.error)
              .map(f => ({ id: f.id, name: f.name, rxLogStatus: f.rxLogStatus })),
          };
          if (workspaceIsInternal(selectedWorkspace)) console.warn(errorDetails);
          else console.error(errorDetails);
        }
        dispatch({ type: "FILES_SET_LIST", payload: { fileList } });
        // if some files are still processing refresh again in 5s
        if (fileList.some(f => f.processing)) {
          setTimeout(() => {
            dispatch(listFilesApi());
          }, 30000);
        }
        try {
          if (fileList.some(f => f.processing)) {
            if (!initialLoaded) {
              dispatch({
                type: "FILE_SET_UPLOAD_COUNT",
                payload: { value: fileList.filter(f => f.processing)?.length || 0 },
              });
            }
            dispatch(fileProcessingSnack());
          } else {
            setTimeout(() => dispatch({ type: "FILE_RESET_UPLOAD_COUNT" }), 4500);
          }
        } catch (e) {
          console.log(e);
        }

        return fileList;
      })
      .catch(e => {
        dispatch(handleGqlErrors(e));
      });
  };
}

export function uploadFilesApi(files, studyLinkId) {
  return (dispatch, getState) => {
    const snackKey = `upload-${Date.now()}`;

    const filenames = files.map(file => file.uploadName);
    dispatch(startProgressSnackbar(snackKey));

    const n = files.length;
    const message = `Uploading ${n} file${n > 1 ? "s" : ""}...`;
    dispatch(customSnackbar("progress", { message, progressVariant: "determinate" }, snackKey));

    const formData = createMultiUploadForm(files);
    const config = {
      params: {
        dir: "",
      },
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: progressEvent => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader("x-decompressed-content-length");
        if (totalLength !== null) {
          const progress = Math.round((progressEvent.loaded / totalLength) * 100);
          // For some reason it can hit 100 here before it's actually complete
          // the .then will finally clear the progress bar when it's actually complete
          if (progress < 100) {
            dispatch(updateProgressSnackbar(progress, snackKey));
          }
        }
      },
    };

    axios
      .post(FILE_API_URL, formData, config)
      .then(() => dispatch(listFilesApi()))
      .then(() => {
        if (studyLinkId) {
          // check that the study still exists (could have been deleted during upload)
          const studyExists = Boolean(getState().study.studies.find(s => s.id === studyLinkId));
          if (studyExists) {
            dispatch(addToStudy({ studyId: studyLinkId, filePaths: filenames }, false));
          }
        }
      })
      .then(() => {
        dispatch(updateProgressSnackbar(100, snackKey));
        dispatch(removeSnackbar(snackKey));
        dispatch(basicSnackbar("File Upload Complete", "success"));
        dispatch({ type: "FILE_INC_UPLOAD_COUNT", payload: { value: n } });
      })
      .catch(error => {
        console.error(error);
        dispatch(snackbarError(extractMessage(error)));
        dispatch(removeSnackbar(snackKey));
      });
  };
}

// Executes the delete method of the file API and updates the study data.
type Study = { id: string; files: { name: string }[] };
export function deleteFilesApi(
  dir: string,
  files: string[],
  studies: Study[]
): Thunk<void, FilesAction | SnackBarAction> {
  return dispatch => {
    // File counts on success and fail:
    const snackKey = `delete-${Date.now()}`;
    const filesDeleted: { success: string[]; fail: string[] } = { success: [], fail: [] };
    // Initiate progress bar:
    dispatch(
      customSnackbar(
        "progress",
        {
          message: `Deleting ${files.length} file${files.length > 1 ? "s" : ""}...`,
          progressVariant: "determinate",
        },
        snackKey
      )
    );
    dispatch(startProgressSnackbar(snackKey));

    // Call delete API for each file
    files.forEach(file => {
      axios
        .delete(FILE_API_URL, {
          params: {
            file: "/" + file,
          },
          // @TODO 20190726: The file API should be updated to accept a list of files, rather than
          //   having to send a request for each file. Then we can pass { data: filePaths }
        })
        .then(() => {
          filesDeleted.success.push(file);
        })
        .catch(error => {
          console.error(error);
          filesDeleted.fail.push(file);
          dispatch(snackbarError(extractMessage(error)));
        })
        .finally(() => {
          // unlink successfully deleted files from studies
          studies.forEach(study => {
            const deletedFiles = study.files
              .filter(file => filesDeleted.success.includes(file.name))
              .map(file => file.name);
            if (deletedFiles.length > 0) {
              dispatch(removeFromStudy({ studyId: study.id, filePaths: deletedFiles }, true));
            }
          });
          const doneCount = filesDeleted.success.length + filesDeleted.fail.length;
          // Update file delete progress:
          dispatch(updateProgressSnackbar(Math.round((doneCount / files.length) * 100), snackKey));

          if (doneCount === files.length) {
            // When all files have been processed, get the new list
            dispatch(listFilesApi());
          }
        });
    });
  };
}

export function startFileDownloadProgress(fileName: string, key: string): FilesAction {
  return {
    type: "FILE_DOWNLOAD_START_PROGRESS",
    payload: {
      key,
      fileName,
    },
  };
}

export function updateFileDownloadProgress(key: string, progress: number): FilesAction {
  return {
    type: "FILE_DOWNLOAD_UPDATE_PROGRESS",
    payload: {
      key,
      progress,
    },
  };
}

export function endFileDownloadProgress(key: string): FilesAction {
  return {
    type: "FILE_DOWNLOAD_END_PROGRESS",
    payload: {
      key,
    },
  };
}

export function openFileDownload() {
  return dispatch => {
    dispatch(customSnackbar("file-download", null, `file-download-${Date.now()}`));
  };
}

export function downloadFilesApi(dir, files) {
  return async (dispatch, getState) => {
    const now = Date.now();
    const snackKeys: string[] = [];
    const filesMeta = getState()
      .files.fileList.filter(f => files.includes(f.name))
      .map(f => ({ progress: 0, ...f }));

    const totalSize = filesMeta.reduce((total, f) => total + f.size, 0) / 1e6; // Conver to MB
    const doParallel = totalSize < 50; // 50MB

    const openSnack = Object.keys(getState().files.downloads).length === 0;

    files.forEach(f => {
      const snackKey = `download-${now}-${f}`;
      snackKeys.push(snackKey);
      dispatch(startFileDownloadProgress(f, snackKey));
    });

    // open if the snack is closed - there aren't other files downloading
    if (openSnack) {
      dispatch(openFileDownload());
    }

    function progressCallback(fileName, progress) {
      const i = filesMeta.findIndex(f => f.name === fileName);
      const snackKey = snackKeys[i];
      filesMeta[i].progress = progress;
      dispatch(
        updateFileDownloadProgress(snackKey, Math.round((progress / filesMeta[i].size) * 100))
      );
    }

    if (!doParallel) {
      // download files serially
      for (const f of files) {
        const i = filesMeta.findIndex(ff => ff.name === f);
        try {
          await downloadFile(f, progressCallback);
          dispatch(endFileDownloadProgress(snackKeys[i]));
        } catch (e) {
          console.error(e);
          dispatch(endFileDownloadProgress(snackKeys[i]));
          dispatch(snackbarError(`Error downloading ${f}`));
        }
      }
    } else {
      // parallel
      await Promise.all(
        files.map((f, i) =>
          downloadFile(f, progressCallback)
            .catch(e => {
              console.error(e);
              dispatch(snackbarError(`Error downloading ${f}`));
            })
            .finally(() => dispatch(endFileDownloadProgress(snackKeys[i])))
        )
      );
    }
  };
}

export function createDownloadFromBlob(blob, name) {
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.setAttribute("download", name);
  a.click();
  window.URL.revokeObjectURL(a.href);
}

function downloadFile(file, progressCallback) {
  const filePath = "/" + file;
  return axios
    .get(FILE_API_URL, {
      params: { action: "download", file: filePath },
      responseType: "blob",
      onDownloadProgress: progressEvent => {
        progressCallback(file, progressEvent.loaded);
      },
    })
    .then(res => {
      createDownloadFromBlob(res.data, file);
    });
}

function createMultiUploadForm(files) {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    formData.append("file#" + (i + 1), file, file.uploadName);
  }
  return formData;
}

export function exportFilesApi(files, fileType) {
  const isDownload = fileType === "default";
  return dispatch => {
    const url = DATA_SERVER_URL + "/files";
    const config = {
      params: {
        action: "archive",
        options: fileType, // csv or vrl
      },
    };

    axios
      .post(url, { files: files.map(file => "/" + file) }, config)
      .then(exportFiles(dispatch, isDownload))
      .catch(error => {
        console.error(error);
        dispatch(dispatch(snackbarError(extractMessage(error))));
      });
  };
}

function exportFiles(dispatch, isDownload = false) {
  return async function (response) {
    const jobId = response.data.id;

    // keys for notifications
    const progressKey = "files-export-progress-" + jobId;
    const snkMsg = isDownload ? "Zipping files for download" : "Preparing Export";

    dispatch(startProgressSnackbar(progressKey));
    dispatch(
      customSnackbar("progress", { message: snkMsg, progressVariant: "determinate" }, progressKey)
    );

    const url = DATA_SERVER_URL + "/jobs/" + jobId;

    const handleResponse = response => {
      const resCode = response.status;

      if (resCode === 200) {
        // Download export
        downloadJobFiles(url, () => null)
          .catch(e => {
            console.error(e);
            dispatch(snackbarError("Error downloading export"));
          })
          .finally(() => {
            dispatch(updateProgressSnackbar(100, progressKey));
            dispatch(basicSnackbar("Export complete", "success"));
          });

        // tell poll loop we're done
        return true;
      } else if (resCode === 202) {
        // active - poll again
        return false;
      }
    };

    const handleError = error => {
      console.error(error);
      dispatch(removeSnackbar(progressKey));
      dispatch(snackbarError("Error creating export"));
      return true;
    };
    // Rough estimate of progress to give them something to look at :-)
    let estimatedProgress = 0;
    while (true) {
      const finished = await axios.get(url).then(handleResponse).catch(handleError);
      if (estimatedProgress < 90) {
        estimatedProgress += 10;
        dispatch(updateProgressSnackbar(estimatedProgress, progressKey));
      }
      if (finished) {
        break;
      }
    }
  };
}

function downloadJobFiles(url, progressCallback) {
  const fileName = `fathom-export-${formatDateTimeFilename()}.zip`;
  return axios
    .get(url, {
      params: { action: "download" },
      responseType: "blob",
      onDownloadProgress: progressEvent => {
        const progress =
          progressEvent.loaded / progressEvent.srcElement.getResponseHeader("content-length");
        progressCallback(progress);
      },
    })
    .then(res => createDownloadFromBlob(res.data, fileName));
}
