import { Typography, IconButton } from "@material-ui/core";
import { Link as IconLink, Add as IconAdd } from "@material-ui/icons";
import FlexRow from "../common/FlexRow";

function StudyEmptyMessage({
  typeName,
  studyName,
  newVerb,
  iconNew = null,
  onNewClicked,
  onLinkClicked,
}) {
  return (
    <Typography variant="subtitle1">
      There are no {typeName} linked to {studyName}. To link {typeName} to this study you can:
      <div>
        <FlexRow>
          <IconButton onClick={onLinkClicked}>
            <IconLink />
          </IconButton>
          <Typography style={{ marginTop: 12 }}> Link existing {typeName} </Typography>
        </FlexRow>
        <FlexRow>
          <IconButton onClick={onNewClicked}>{iconNew ? iconNew : <IconAdd />}</IconButton>
          <Typography style={{ marginTop: 12 }}>
            {newVerb ? newVerb : "Add"} new {typeName}{" "}
          </Typography>
        </FlexRow>
      </div>
    </Typography>
  );
}

export default StudyEmptyMessage;
