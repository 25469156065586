import { Badge } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const TabBadge = withStyles(theme => ({
  badge: {
    right: -14,
    top: 14,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Badge);

export default TabBadge;
