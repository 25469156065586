import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Chip, InputAdornment, Button } from "@material-ui/core";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@material-ui/icons";
import { ReactNode, useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    // hack to hide chips when select menu is open:
    "&[aria-expanded=true] .MuiAutocomplete-tag": {
      display: "none",
    },
  },
  tag: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  option: {
    padding: 0,
    transitionDuration: "50",
    backgroundColor: "unset",
    "&[aria-selected=true]": {
      backgroundColor: "unset",
    },
  },
  paper: {
    padding: 2,
    marginTop: -3,
    marginLeft: 2,
    boxShadow: "0px 6px 12px -4px #222",
    border: `2px solid ${theme.palette.primary.main}`,
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  groupLabel: {
    color: theme.palette.primary.main,
    lineHeight: "18px",
    paddingTop: theme.spacing(1.2),
  },
  input: {
    // for some reason setting `fullWidth` prop in renderInput does not work, so this does:
    width: "100%!important",
  },
  hiddenTagsButton: {
    // make the `+n` text look like a button
    padding: 3,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: "50%",
    cursor: "pointer",
    border: "1px solid grey",
    fontSize: "12px",
    "&:hover": {
      boxShadow: `1px 1px 5px -1px #333`,
    },
  },
}));

export type AutoCompleteOptions = {
  key: string;
  label: string;
  labelPrefix?: ReactNode | string;
  labelSuffix?: ReactNode | string;
};

type Props = {
  options: AutoCompleteOptions[];
  selection: AutoCompleteOptions[];
  selectionHandler: (selection: AutoCompleteOptions[]) => void;
  label?: string;
  placeholder?: string;
  helperText?: string;
  /** the number of tags (chips) to show before collapsing into `+n` */
  limitTags?: number;
  /* Use standat MUI button instead of "X" that shows on hover */
  buttonClear?: boolean;
};

export function AutocompleteChips(props: Props) {
  const {
    options,
    selection,
    selectionHandler,
    label,
    placeholder,
    helperText,
    limitTags,
    buttonClear = true,
  } = props;
  const classes = useStyles(props);
  const { hiddenTagsButton, ...autocompleteClasses } = classes; // MUI complains if classes has unexpected keys
  const [popperOpen, setPopperOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  return (
    <div id="myAutocomplete">
      <Autocomplete
        disableClearable
        fullWidth
        multiple
        disableCloseOnSelect
        size="small"
        limitTags={limitTags}
        getLimitTagsText={more => <span className={hiddenTagsButton}>+{more}</span>}
        classes={autocompleteClasses}
        options={options}
        value={selection}
        onChange={(_, selected) => {
          selectionHandler(selected);
        }}
        inputValue={inputValue}
        onInputChange={(_, value, reason) => {
          if (reason != "reset") {
            // the search value was reset on each selection but
            // user may want to select >1 from filtered list
            setInputValue(value);
          }
        }}
        open={popperOpen}
        onOpen={() => setPopperOpen(true)}
        onClose={(_, reason) => {
          if (reason != "toggleInput") {
            // the popper would close when user clicked on input box
            setPopperOpen(false);
            setInputValue("");
          }
        }}
        renderOption={(option, { selected }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#333",
                alignItems: "center",
                padding: 8,
                width: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {selected ? (
                  <CheckBoxOutlined style={{ marginRight: 8 }} />
                ) : (
                  <CheckBoxOutlineBlank style={{ marginRight: 8 }} />
                )}
                {option.label}
              </div>
              <div>{option.labelSuffix}</div>
            </div>
          );
        }}
        getOptionLabel={option => option.label}
        getOptionSelected={(option, value) => {
          return option.key == value.key;
        }}
        renderInput={params => (
          <>
            <TextField
              {...params}
              id="myText1"
              variant="outlined"
              size="small"
              label={label}
              placeholder={placeholder}
              helperText={helperText}
              InputProps={
                buttonClear
                  ? {
                      id: "myText1",
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          {selection.length > 0 && (
                            <div style={{ position: "absolute", right: 36 }}>
                              <InputAdornment position="end">
                                <Button
                                  onClick={() => selectionHandler([])}
                                  variant="outlined"
                                  style={{
                                    fontSize: "12px",
                                    padding: "4px 8px",
                                  }}
                                >
                                  Clear
                                </Button>
                              </InputAdornment>
                            </div>
                          )}
                        </>
                      ),
                    }
                  : params.InputProps
              }
            />
          </>
        )}
        renderTags={(value, getTagProps) => (
          /* Padding to avoid overlap of CLEAR ALL btn */
          <div style={{ paddingRight: buttonClear ? 50 : undefined }}>
            {value.map((option, index) => (
              <Chip key={index} size="small" label={option.label} {...getTagProps({ index })} />
            ))}
          </div>
        )}
      />
    </div>
  );
}
