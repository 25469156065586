import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { Error as IconWarning } from "@material-ui/icons";

const styles = theme => ({
  icon: {
    color: theme.palette.warning.main,
  },
});

function WarningIcon({ tooltip, classes }) {
  return (
    <Tooltip title={tooltip}>
      <IconWarning fontSize="small" className={classes.icon} />
    </Tooltip>
  );
}

WarningIcon.propTypes = {
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default withStyles(styles)(WarningIcon);
