import { Thunk } from "../common";
import { NetworkAction } from "./network-types";
import { callGqlApi } from "../../helpers/api";
import { handleGqlErrors } from "../gql-error/gql-error-actions";
import gql from "../gqlTag";

export function checkNetwork(): Thunk<void, NetworkAction> {
  return async dispatch => {
    const query = gql`
      query checkWorkspaces {
        workspaces {
          id
        }
      }
    `;
    callGqlApi(query)
      .then(() => window.location.reload())
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

export function resetRetryCount(): NetworkAction {
  return { type: "NETWORK_RESET_RETRYCOUNT" };
}
