import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import history from "./helpers/history";
import Notifier from "./containers/Notifier";
import { SnackbarProvider } from "notistack";
import TopLevelDialogs from "./components/top-level-dialogs/TopLevelDialogs";

import store from "./redux/store";
import Routes from "./Routes";

import customTheme from "./styles/customTheme";

const App = () => (
  <Provider store={store}>
    <CssBaseline />
    <MuiThemeProvider theme={customTheme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        disableWindowBlurListener
      >
        <Notifier />
        <Router history={history}>
          <TopLevelDialogs />
          <Routes />
        </Router>
      </SnackbarProvider>
    </MuiThemeProvider>
  </Provider>
);

export default App;
