import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DialogWrapper from "../components/common/DialogWrapper";
import { addToStudy } from "../redux/study/study-actions";

import { MenuItem, TextField, Typography } from "@material-ui/core";

function StudyChooseDialog({
  open,
  handleClose,
  objectType,
  objectTypeLabel,
  objectIds,
  studies,
  dispatch,
}) {
  const [selectedStudyId, setSelectedStudyId] = useState("");
  if (!open) {
    return null;
  }
  function submitAddToStudy() {
    dispatch(addToStudy({ studyId: selectedStudyId, [objectType]: objectIds }, true));
    handleClose();
  }

  return (
    <DialogWrapper
      open={open}
      title={`Link ${objectTypeLabel}(s) to Study`}
      okAction={submitAddToStudy}
      okButtonContent="Link"
      okDisabled={!selectedStudyId}
      cancelAction={handleClose}
      onClose={handleClose}
    >
      <Typography gutterBottom>
        Please select the Study to link the selected {objectTypeLabel}(s) to:
      </Typography>
      <TextField
        label="Study"
        size="small"
        required
        select
        fullWidth={true}
        value={selectedStudyId}
        onChange={e => setSelectedStudyId(e.target.value)}
      >
        {studies.map(study => (
          <MenuItem key={study.id} value={study.id}>
            {study.name}
          </MenuItem>
        ))}
      </TextField>
    </DialogWrapper>
  );
}

StudyChooseDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  objectType: PropTypes.oneOf(["deviceIds", "animalIds", "deploymentIds", "filePaths"]),
  objectTypeLabel: PropTypes.string.isRequired,
  objectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ study }) => {
  return {
    studies: study.studies,
  };
};

export default connect(mapStateToProps)(StudyChooseDialog);
