import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind/react";
import { connect } from "react-redux";

// MATERIAL UI
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

// COMPONENTS
import DeploymentDevicesTable from "./DeploymentDevicesTable";
import DeploymentPositionsTable from "./DeploymentPositionsTable";
import AddDevAttachmentDialog from "./AddDevAttachmentDialog";
import AddPositionDialog from "./AddPositionDialog";
import DialogWrapper from "../common/DialogWrapper";

// redux
import {
  deleteDeviceAttachments,
  deletePositions,
} from "../../redux/deployments/deployments-actions";
import { formatDevice } from "../../helpers/device";
import { formatLatLon } from "../../helpers/common";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  subPanel: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    padding: theme.spacing(0.5),
    borderRadius: 1,
    boxShadow: "-5px -5px 5px -5px #9a9a9a",
  },
  placeHolder: {
    padding: theme.spacing(1),
  },
});

class DeploymentDetailsPanel extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      deviceAttachmentToDelete: null,
      positionToDelete: null,
      addDialog: null, // null | deviceAttachment | position
    };
  }

  deletePosition() {
    this.props.dispatch(deletePositions([this.state.positionToDelete.id]));
    this.setState({ positionToDelete: null });
  }

  deleteDeviceAttachment() {
    this.props.dispatch(deleteDeviceAttachments([this.state.deviceAttachmentToDelete.id]));
    this.setState({ deviceAttachmentToDelete: null });
  }

  render() {
    const { deploymentsSelected, deviceOptions, classes } = this.props;
    const { addDialog, deviceAttachmentToDelete, positionToDelete } = this.state;

    const onlyDeploymentSelected = deploymentsSelected.length === 1 ? deploymentsSelected[0] : null;

    const deviceAttachmentToDeleteLabel = deviceAttachmentToDelete && (
      <span style={{ whiteSpace: "nowrap" }}>{formatDevice(deviceAttachmentToDelete.device)}</span>
    );

    return (
      <>
        <div className={classes.root} style={{ minHeight: onlyDeploymentSelected ? 200 : 50 }}>
          {deploymentsSelected.length > 1 ? (
            <Typography variant="body2" className={classes.placeHolder}>
              {deploymentsSelected.length} deployments selected. Select a single deployment to view
              details about its attached devices and positions.
            </Typography>
          ) : deploymentsSelected.length === 0 ? (
            <Typography variant="body2" className={classes.placeHolder}>
              Select a deployment to view details about its attached devices and positions.
            </Typography>
          ) : (
            <>
              <div className={classes.subPanel}>
                <DeploymentDevicesTable
                  deviceAttachments={onlyDeploymentSelected.deviceAttachments}
                  handleAddClick={() => this.setState({ addDialog: "deviceAttachment" })}
                  deviceOptions={deviceOptions}
                  onDeleteClick={devAtt => this.setState({ deviceAttachmentToDelete: devAtt })}
                />
              </div>
              <div className={classes.subPanel}>
                <DeploymentPositionsTable
                  deploymentPositions={onlyDeploymentSelected.positions}
                  handleAddClick={() => this.setState({ addDialog: "position" })}
                  onDeleteClick={position => this.setState({ positionToDelete: position })}
                />
              </div>
            </>
          )}
        </div>
        {onlyDeploymentSelected && (
          <AddDevAttachmentDialog
            open={addDialog === "deviceAttachment"}
            toggle={() => this.setState({ addDialog: null })}
            deployment={onlyDeploymentSelected}
            deviceOptions={deviceOptions}
          />
        )}
        {onlyDeploymentSelected && (
          <AddPositionDialog
            open={addDialog === "position"}
            toggle={() => this.setState({ addDialog: null })}
            deployment={onlyDeploymentSelected}
          />
        )}
        <DialogWrapper
          open={Boolean(deviceAttachmentToDelete)}
          title="Delete Device Attachment"
          okAction={this.deleteDeviceAttachment}
          okButtonContent="Delete"
          cancelAction={() => this.setState({ deviceAttachmentToDelete: null })}
        >
          <>
            Are you sure you want to delete the device attachment for{" "}
            {deviceAttachmentToDeleteLabel}?
          </>
        </DialogWrapper>
        <DialogWrapper
          open={Boolean(positionToDelete)}
          title="Delete Position"
          okAction={this.deletePosition}
          cancelAction={() => this.setState({ positionToDelete: null })}
          okButtonContent="Delete"
          fullWidth
          maxWidth="sm"
        >
          {positionToDelete && (
            <>
              Are you sure you want to delete the position at{" "}
              {
                <span style={{ whiteSpace: "nowrap" }}>
                  ({formatLatLon(positionToDelete.latLon.latitude)},{" "}
                  {formatLatLon(positionToDelete.latLon.longitude)})
                </span>
              }
              ?
            </>
          )}
        </DialogWrapper>
      </>
    );
  }
}

DeploymentDetailsPanel.propTypes = {
  deploymentsSelected: PropTypes.array,
  deviceOptions: PropTypes.array,
};

export default connect()(withStyles(styles)(DeploymentDetailsPanel));
