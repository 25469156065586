export type LoadStatus = "unloaded" | "loading" | "loaded";

// DATALIST
export type DataListEntry = {
  name: string;
  size: number;
  lastModified: string;
};

// SERIES
export const RX_SERIES_TYPES = ["tilt", "depth", "noise", "temp", "detects", "pings"] as const;
export type RxSeriesType = typeof RX_SERIES_TYPES[number];
export type RxSeriesCategory = "sensor" | "count";
export type RxSeriesDatum = { x: Date; y: number };
export type RxSeries = {
  filename: string;
  type: RxSeriesType;
  yVariable: string;
  data: RxSeriesDatum[];
  serial: string;
};

// EVENTS
export const RX_EVENT_TYPES = ["INIT", "OFFLOAD"] as const;
export type RxEventType = typeof RX_EVENT_TYPES[number];
export type RxEvent = {
  filename: string;
  time: Date;
  type: RxEventType;
};

// REDUX ACTIONS
type EventNoFile = Omit<RxEvent, "filename">;

export type RxDiagAction =
  | { type: "RXDIAG_SELECT_FILES"; payload: { filenames: string[] } }
  | { type: "RXDIAG_DATALIST_LOADING"; payload: { filename: string } }
  | { type: "RXDIAG_SELECT_SERIES_TYPES"; payload: { seriesTypes: RxSeriesType[] } }
  | { type: "RXDIAG_SELECT_EVENT_TYPES"; payload: { eventTypes: RxEventType[] } }
  | { type: "RXDIAG_DATALIST_LOADED"; payload: { filename: string; dataTypes: string[] } }
  | { type: "RXDIAG_SERIES_LOADING"; payload: { filename: string; seriesType: RxSeriesType } }
  | { type: "RXDIAG_SERIES_LOADED"; payload: { series: RxSeries } }
  | { type: "RXDIAG_EVENTS_LOADING"; payload: { filename: string } }
  | { type: "RXDIAG_EVENTS_LOADED"; payload: { filename: string; events: EventNoFile[] } };
