import { callGqlApi, uploadFileGqlApi } from "../../helpers/api";
import {
  DEPLOYMENTS_SET_LIST,
  DEPLOYMENTS_SET_ERROR,
  DEPLOYMENTS_SET_STATIONS_LIST,
  DEPLOYMENTS_DELETE,
} from "../action-types";
import { handleGqlErrors } from "../gql-error/gql-error-actions";
import { basicSnackbar, snackbarError } from "../snackbar/snackbar-actions";
import { addToStudy } from "../study/study-actions";
import gql from "../gqlTag";
import { deploymentSpecFormats } from "../../components/common/SpecUploadDialogs/types";

// DATA LIST
export function readDeploymentList() {
  return async dispatch => {
    const deploymentsQuery = gql`
      query listDeployments {
        deployments {
          id
          start
          end
          station {
            name
            id
          }
          deviceAttachments {
            id
            device {
              id
              capabilities
              serial
              model
              transmitters {
                displayId
              }
              deviceClasses
            }
            start
            end
            height {
              value
              unit
            }
            conflict {
              conflictingDeploymentIds
            }
            studyConflicts {
              studyId
              conflict {
                conflictingDeploymentIds
              }
            }
          }
          positions {
            id
            start
            end
            latLon {
              latitude
              longitude
            }
            depth {
              value
              unit
            }
            hasConflict
          }
        }
        stations {
          id
          name
        }
      }
    `;
    callGqlApi(deploymentsQuery, {})
      .then(response => {
        dispatch({
          type: DEPLOYMENTS_SET_LIST,
          payload: {
            deployments: response.deployments,
            stations: response.stations,
          },
        });
      })
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

function readStationList() {
  return dispatch => {
    const readStationsQuery = gql`
      query {
        stations {
          name
          id
        }
      }
    `;
    callGqlApi(readStationsQuery, {})
      .then(response => {
        dispatch({
          type: DEPLOYMENTS_SET_STATIONS_LIST,
          payload: {
            deployments: response.deployments,
            stations: response.stations,
          },
        });
      })
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function addStation({ name }) {
  return async dispatch => {
    const createStationMutation = `
      mutation ($input: CreateStationInput!) {
        createStation(input: $input) {
          station {
            id
            name
          }
        }
      }
    `;
    return callGqlApi(createStationMutation, { input: { name } })
      .then(data => {
        dispatch(readStationList());
        return data?.createStation?.station;
      })
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function updateStation(station) {
  return async dispatch => {
    const updateStationMutation = gql`
      mutation ($input: UpdateStationInput!) {
        updateStation(input: $input) {
          station {
            id
            name
            deployments {
              id
            }
          }
        }
      }
    `;
    callGqlApi(updateStationMutation, { input: station })
      .then(() => {
        dispatch(readStationList());
        dispatch(readDeploymentList());
      })
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function deleteStation(id) {
  return async dispatch => {
    const deleteStationMutation = gql`
      mutation ($input: DeleteStationsInput!) {
        deleteStations(input: $input) {
          deletedStationIds
        }
      }
    `;
    callGqlApi(deleteStationMutation, { input: { stationIds: [id] } })
      .then(() => {
        dispatch(readStationList());
        dispatch(readDeploymentList());
      })
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function addDeployment(deploymentInput, studyId) {
  return async (dispatch, getState) => {
    const createDeploymentQuery = gql`
      mutation createDeployment($input: CreateDeploymentInput!) {
        createDeployment(input: $input) {
          deployment {
            id
          }
        }
      }
    `;

    callGqlApi(createDeploymentQuery, { input: deploymentInput })
      .then(response => {
        if (studyId) {
          // check if device is linked to the deployment but not the study and add it to the study:
          const deviceIdsInDeployment =
            deploymentInput.deviceAttachments?.map(da => da.deviceId) || [];
          const deviceIdsInStudy = (
            getState().study.studies.find(s => s.id === studyId).devices || []
          ).map(d => d.id);
          const deviceIds = deviceIdsInDeployment.filter(id => !deviceIdsInStudy.includes(id));

          return dispatch(
            addToStudy({
              studyId,
              deploymentIds: [response.createDeployment.deployment.id],
              ...(deviceIds.length ? { deviceIds } : {}),
            })
          );
        }
      })
      .catch(errors => dispatch(handleGqlErrors(errors)))
      .finally(() => dispatch(readDeploymentList()));
  };
}

export function addDeviceAttachment(deploymentId, deviceAttachment) {
  return async (dispatch, getState) => {
    const createDeviceAttachment = gql`
      mutation createDeviceAttachmentMutation($input: CreateDeviceAttachmentInput!) {
        createDeviceAttachment(input: $input) {
          deviceAttachment {
            id
          }
        }
      }
    `;

    // If deployment is in any study(s) and the new device isn't, link it to the study:
    const studiesWithDeployment = getState().study.studies.filter(
      study => study.deployments?.filter(deployment => deployment.id === deploymentId).length > 0
    );
    const studyLinks = []; // array of { studyId, deviceIds }
    if (studiesWithDeployment.length > 0) {
      studiesWithDeployment.forEach(study => {
        const deviceIdsInStudy = study.devices?.map(d => d.id) || [];
        const deviceIdsNotInStudy = deviceIdsInStudy.includes(deviceAttachment.deviceId)
          ? []
          : [deviceAttachment.deviceId];
        if (deviceIdsNotInStudy.length > 0) {
          studyLinks.push({ studyId: study.id, deviceIds: deviceIdsNotInStudy });
        }
      });
    }

    callGqlApi(createDeviceAttachment, { input: { deploymentId, deviceAttachment } })
      .then(() => {
        dispatch(readDeploymentList());
        if (studyLinks.length > 0) {
          for (const studyLink of studyLinks) {
            dispatch(addToStudy(studyLink, true));
          }
        }
      })
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function addDeploymentPosition(deploymentId, position) {
  return async dispatch => {
    const createPosition = gql`
      mutation createPositionMutation($input: CreatePositionInput!) {
        createPosition(input: $input) {
          position {
            id
          }
        }
      }
    `;

    callGqlApi(createPosition, { input: { deploymentId, position } })
      .then(() => {
        dispatch(readDeploymentList());
      })
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function uploadDeploymentSpec(file, format) {
  if (!deploymentSpecFormats.includes(format)) {
    console.error("Invalid deployment spec format");
    return;
  }

  return async (dispatch, getState) => {
    const mutation = gql`
      mutation ($input: UploadDeploymentDataFileInput!) {
        uploadDeploymentDataFile(input: $input) {
          success
          deploymentIds
          missingDevices {
            serial
            txDisplayIds
            model
            deviceClasses
          }
          specErrors {
            message
            row
            cols
          }
        }
      }
    `;

    try {
      const studyId = getState().study.selectedId;
      const gqlFormat =
        format === "deployment-simple"
          ? "SIMPLE"
          : format === "deployment-standard"
          ? "STANDARD"
          : "OTN";
      const variables = { input: { format: gqlFormat } };
      const response = await uploadFileGqlApi(mutation, "input.file", file, variables);
      const result = response.uploadDeploymentDataFile;
      const { success, deploymentIds } = result;

      if (success) {
        if (studyId) {
          dispatch(addToStudy({ studyId, deploymentIds }));
        }
        dispatch(
          basicSnackbar(
            `File Upload successful! Deployments imported: ${deploymentIds.length}`,
            "success"
          )
        );
        dispatch(readDeploymentList());
      }

      return result;
    } catch (errors) {
      dispatch(snackbarError("Error uploading deployment sheet"));
      dispatch(handleGqlErrors(errors));
      return { success: false, deploymentIds: [], missingDevices: [] };
    }
  };
}

export function deleteDeployments(ids) {
  const deleteDeployments = gql`
    mutation ($input: DeleteDeploymentsInput!) {
      deleteDeployments(input: $input) {
        deletedDeploymentIds
      }
    }
  `;
  const deleteDeploymentsInput = {
    deploymentIds: ids,
  };
  return dispatch => {
    // delete from the current deployment list to update ui
    dispatch({ type: DEPLOYMENTS_DELETE, payload: { ids } });

    callGqlApi(deleteDeployments, { input: deleteDeploymentsInput })
      .then(() => {
        dispatch(readDeploymentList());
      })
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function deleteDeviceAttachments(ids) {
  const deleteDeviceAttachmentsInput = {
    deviceAttachmentIds: ids,
  };
  const deleteDeviceAttachments = gql`
    mutation ($input: DeleteDeviceAttachmentsInput!) {
      deleteDeviceAttachments(input: $input) {
        deleteDeviceAttachmentIds
      }
    }
  `;
  return async dispatch => {
    callGqlApi(deleteDeviceAttachments, { input: deleteDeviceAttachmentsInput })
      .then(() => {
        dispatch(readDeploymentList());
      })
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function deletePositions(ids) {
  const deletePositions = gql`
    mutation ($input: DeletePositionsInput!) {
      deletePositions(input: $input) {
        deletedPositionIds
      }
    }
  `;
  const deletePositionsInput = {
    positionIds: ids,
  };
  return async dispatch => {
    callGqlApi(deletePositions, { input: deletePositionsInput })
      .then(() => {
        dispatch(readDeploymentList());
      })
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function updateDeployment(id, { start, end, stationId }) {
  return async dispatch => {
    const mutation = gql`
      mutation updateDeploymentMutation($input: UpdateDeploymentInput!) {
        updateDeployment(input: $input) {
          deployment {
            id
          }
        }
      }
    `;

    callGqlApi(mutation, { input: { id, ...{ start, end, stationId } } })
      .then(() => dispatch(readDeploymentList()))
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function updatePosition(positionId, { start, end, depth, manualLatLon }) {
  return async dispatch => {
    const mutation = gql`
      mutation ($input: UpdatePositionInput!) {
        updatePosition(input: $input) {
          position {
            id
          }
        }
      }
    `;

    callGqlApi(mutation, { input: { positionId, position: { start, end, depth, manualLatLon } } })
      .then(() => dispatch(readDeploymentList()))
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function updateDeviceAttachment(deviceAttachmentId, { deviceId, start, end, height }) {
  return async dispatch => {
    const mutation = gql`
      mutation updateDeviceAttachmentMutation($input: UpdateDeviceAttachmentInput!) {
        updateDeviceAttachment(input: $input) {
          deviceAttachment {
            id
          }
        }
      }
    `;

    const deviceAttachment = { deviceId, start, end, height };

    callGqlApi(mutation, { input: { deviceAttachmentId, deviceAttachment } })
      .then(() => dispatch(readDeploymentList()))
      .catch(errors => {
        dispatch({
          type: DEPLOYMENTS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}
