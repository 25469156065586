import PropTypes from "prop-types";
import { Box, List, ListItem } from "@material-ui/core";

// Simple scrollable list for display of data inside dialogs
function ListBox({ maxHeight, children, textItems }) {
  const contents = textItems
    ? textItems.map(item => <ListItem key={item}>{item}</ListItem>)
    : children;

  return (
    <Box
      style={{ maxHeight: maxHeight, overflow: "auto" }}
      borderRadius="borderRadius"
      boxShadow={2}
    >
      <List>{contents}</List>
    </Box>
  );
}

ListBox.defaultProps = {
  maxHeight: 265,
};

ListBox.propTypes = {
  maxHeight: PropTypes.number,
  textItems: PropTypes.arrayOf(PropTypes.string),
};

export default ListBox;
