import { useState } from "react";

import SpecUploadDialog from "./SpecUploadDialog";
import MissingDevicesDialog from "./MissingDevicesDialog";
import SpecErrorDialog from "./SpecErrorDialog";

import { readDeviceList, addDeviceManually } from "../../../redux/devices/devices-actions";
import { uploadDeploymentSpec } from "../../../redux/deployments/deployments-actions";
import { useThunkDispatch, useSelectorTyped as useSelector } from "../../../redux/common";
import { MissingDevice, SpecError, SpecFormat } from "./types";
import { uploadAnimalSpec } from "../../../redux/animals/animals-actions";

type Props = {
  open: boolean;
  dataType: "animal" | "deployment";
  close: () => void;
};

function SpecUploadDialogs({ open, dataType, close }: Props) {
  const dispatch = useThunkDispatch();

  const [format, setFormat] = useState<SpecFormat | null>(null);

  const isPersonalWorkspace: boolean = useSelector(
    ({ workspaces }: any) => workspaces.selectedWorkspace.isPersonal
  );
  const billingAccountLinked = useSelector(({ user }) => Boolean(user.user.sysproContactId));

  // stage of the spec upload flow
  const [stage, setStage] = useState<
    | "file-selection" // initial state: selecting a file to upload
    | "missing-devices" // handling any missing devices found in the spec
    | "error-handling" // displaying any errors after upload completes
  >("file-selection");
  // the last spec file uploaded (for retries)
  const [lastSpecFile, setLastSpecFile] = useState<File | null>(null);
  // devices missing from the last spec upload
  const [missingDevices, setMissingDevices] = useState<MissingDevice[]>([]);
  // user errors in the specsheet
  const [specErrors, setSpecErrors] = useState<SpecError[]>([]);
  // progress out of 100 for manual device creation (undefined => not currently creating devices)
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [processing, setProcessing] = useState(false);

  // reset to initial state & tell parent to close
  const resetAndClose = () => {
    setStage("file-selection");
    setMissingDevices([]);
    setSpecErrors([]);
    setProgress(undefined);
    setLastSpecFile(null);
    setFormat(null);
    close();
  };

  // upload the currently selected spec file
  async function uploadSpec(specFile: File) {
    setProcessing(true);
    const action =
      dataType === "animal"
        ? uploadAnimalSpec(specFile, format)
        : uploadDeploymentSpec(specFile, format);
    const { success, missingDevices, specErrors } = await dispatch(action as any);

    setProcessing(false);
    setLastSpecFile(specFile);
    setSpecErrors(specErrors);
    setMissingDevices(missingDevices);

    if (success) {
      resetAndClose();
    } else if (specErrors?.length > 0) {
      setStage("error-handling");
    } else if (missingDevices.length > 0) {
      setStage("missing-devices");
    }
  }

  // manually create missing devices and retry importing the currently selected spec file
  async function addMissingAndRetry() {
    setProgress(0);

    const total = missingDevices.length;
    let complete = 0;
    for (const { serial, txDisplayIds, model, deviceClasses } of missingDevices) {
      const transmitters = txDisplayIds ? parseTransmitters(txDisplayIds) : [];
      const deviceInput = { serial, model, deviceClasses, source: "MANUAL", transmitters };
      await dispatch(addDeviceManually(deviceInput));
      complete++;
      setProgress((complete / total) * 100);
    }

    dispatch(readDeviceList());
    if (lastSpecFile) {
      uploadSpec(lastSpecFile);
    }
  }

  return (
    <>
      <SpecUploadDialog
        open={open && stage === "file-selection"}
        dataType={dataType || "deployment"} // default value just a placeholder
        format={format}
        setFormat={setFormat}
        onSubmit={uploadSpec}
        onCancel={resetAndClose}
        processing={processing}
      />
      <SpecErrorDialog
        open={open && stage === "error-handling"}
        specErrors={specErrors}
        onClose={resetAndClose}
      />
      <MissingDevicesDialog
        open={open && stage === "missing-devices" && missingDevices.length > 0}
        missingDevices={missingDevices}
        isPersonalWorkspace={isPersonalWorkspace}
        billingAccountLinked={billingAccountLinked}
        onClose={resetAndClose}
        onRetry={addMissingAndRetry}
        progress={progress}
      />
    </>
  );
}

// parse a transmitter display id into the transmitters field of a CreateDeviceInput
function parseTransmitters(txDisplayIds: string[]) {
  const transmitters = txDisplayIds.map(txDisplayId => {
    const displayIdArray = txDisplayId.split("-");
    const transmitter =
      displayIdArray.length > 2
        ? {
            codedTransmitter: {
              transmitId: parseInt(displayIdArray[2]),
              codespace: { displayString: `${displayIdArray[0]}-${displayIdArray[1]}` },
            },
          }
        : {
            codedTransmitter: {
              transmitId: parseInt(displayIdArray[1]),
              codespace: { displayString: displayIdArray[0] },
            },
          };

    return transmitter;
  });
  return transmitters;
}

export default SpecUploadDialogs;
