/**
 * @typedef {{ displayId: string }} Transmitter
 * @typedef {{ transmitters: Transmitter[], serial: string, model: string }} Device
 */

/**
 * Formats a device object (from API) into a human readable string.
 *
 *  - If the device has displayId & is not a receiver: "<displayId> (<model>)"
 *  - No displayId: "<model>-<serial>"
 *
 * @param {Device} device - Device object from API
 * @returns {string} Formatted device string
 */
export function formatDevice(device) {
  if (!device) {
    return "";
  }

  const txSorted = device.transmitters?.sort((t1, t2) => t1.displayId.localeCompare(t2.displayId));

  const firstDisplayId = txSorted && txSorted[0] && txSorted[0].displayId;
  const modelString = device.model ? device.model : "";
  const serialString = `${modelString ? modelString + "-" : ""}${device.serial}`;
  if (
    device.deviceClasses?.includes("RECEIVER") ||
    (!firstDisplayId && !device.deviceClasses?.includes("TAG"))
  ) {
    return serialString;
  } else {
    return `${firstDisplayId || (device.serial ? `SN: ${device.serial}` : "")}${
      modelString ? " (" + modelString + ")" : ""
    }`;
  }
}

export function formatDeviceOptions({
  devices,
  selectedStudy,
  deployments,
  animals,
  restrictToClass,
}) {
  const deviceOptions = [];
  const deviceIdsInStatus = {
    inStudy: [],
    deployed: [],
    tagged: [],
  };
  const deviceStatuses = {
    inStudy: { title: "Available in Current Study", sortOrder: 1 },
    inWorkspace: {
      title: "Available in Workspace", // the remaining devices in the workspace that are not in the study, deployed or tagged.
      sortOrder: 2,
    },
    deployed: {
      title: selectedStudy ? "Deployed in Study" : "Deployed in Workspace",
      sortOrder: 3,
    },
    tagged: { title: selectedStudy ? "Tagged in Study" : "Tagged in Workspace", sortOrder: 4 },
  };

  // Add device ids that are deployed:
  deployments?.forEach(deployment => {
    deployment?.deviceAttachments?.forEach(devAttach => {
      deviceIdsInStatus.deployed.push(devAttach.device.id);
    });
  });

  // Add device ids that are tagged:
  animals?.forEach(animal => {
    animal?.devices?.forEach(device => {
      deviceIdsInStatus.tagged.push(device.id);
    });
  });

  // Add device ids that are in current study but not tagged or deployed:
  selectedStudy?.devices?.forEach(device => {
    if (
      !deviceIdsInStatus.deployed.includes(device.id) &&
      !deviceIdsInStatus.tagged.includes(device.id)
    ) {
      deviceIdsInStatus.inStudy.push(device.id);
    }
  });

  // Generate device options including data about which status(es) it's in.
  // Note: it is allowed for a device to be both tagged and deployed, but a
  //   a device may not be "available in study" whilst either tagged or
  //   deployed, and may not be "available in workspace" whilst in study.
  devices?.forEach(device => {
    if (
      device &&
      device.retired === false &&
      (!restrictToClass || device.deviceClasses?.includes(restrictToClass))
    ) {
      let notInStatus = true;
      for (const statusKey of Object.keys(deviceIdsInStatus)) {
        if (deviceIdsInStatus[statusKey].includes(device.id)) {
          deviceOptions.push({
            label: formatDevice(device),
            id: device.id,
            inStatus: deviceStatuses[statusKey].title,
            statusSort: deviceStatuses[statusKey].sortOrder,
          });
          notInStatus = false;
        }
      }
      if (notInStatus) {
        deviceOptions.push({
          label: formatDevice(device),
          id: device.id,
          inStatus: deviceStatuses.inWorkspace.title,
          statusSort: deviceStatuses.inWorkspace.sortOrder,
        });
      }
    }
  });

  return deviceOptions
    .sort((d1, d2) => d1.label.localeCompare(d2.label))
    .sort((d1, d2) => d1.statusSort - d2.statusSort);
}

export function addDeviceLabels(devices) {
  devices?.forEach(device => {
    if (device) {
      device.label = formatDevice(device);
    }
  });
}
