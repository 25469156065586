import { useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import DialogWrapper from "../common/DialogWrapper";

function StudyAddDialog(props) {
  const { open, onSubmit, onClose } = props;
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  function handleNameChange(event) {
    setNameError("");
    setName(event.target.value);
  }

  function handleDescriptionChange(event) {
    setDescription(event.target.value);
  }

  function resetDialog() {
    setDescription("");
    setName("");
    setNameError("");
  }

  function onFormSubmit() {
    if (!name) {
      setNameError("A name is required");
      return;
    }
    onSubmit({ name, description });
    resetDialog();
  }

  function onFormClose() {
    resetDialog();
    onClose();
  }

  return (
    <DialogWrapper
      open={open}
      title="Add Study"
      okAction={onFormSubmit}
      okButtonContent="Add"
      cancelAction={onFormClose}
    >
      <TextField
        label="Name"
        value={name}
        onChange={handleNameChange}
        required
        autoFocus
        error={Boolean(nameError)}
        helperText={nameError ? nameError : ""}
      />
      <TextField
        label="Description"
        value={description}
        onChange={handleDescriptionChange}
        style={{ width: 400 }}
      />
    </DialogWrapper>
  );
}

StudyAddDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default StudyAddDialog;
