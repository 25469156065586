import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Typography, IconButton, TextField, FormControlLabel } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Add as IconAdd, Done as IconDone, Close as IconClose } from "@material-ui/icons";
import HelpPopover from "../common/HelpPopover";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 0,
  },
  checkbox: {
    whiteSpace: "nowrap",
  },
  input: {
    marginTop: theme.spacing(1),
    flexGrow: 1,
  },
  cardActions: {
    display: "flex",
    paddingRight: theme.spacing(1),
    paddingTop: 0,
  },
  lastRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(1),
  },
  userAttr: {
    display: "flex",
    alignItems: "center",
  },
}));

function WorkspaceUserAdd({
  workspaceId,
  handleClose,
  inviteUserToWorkspace,
  isUserAdmin,
  users,
  addWorkspaceUser,
}) {
  const classes = useStyles();

  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [userIsOwner, setUserIsOwner] = useState(false);
  const [errorUser, setErrorUser] = useState(false);
  const [inviteMessage, setInviteMessage] = useState("");

  const submitNewUser = () => {
    if (isUserAdmin) {
      if (!userId) {
        setErrorUser(true);
        return false;
      }
      addWorkspaceUser({
        workspaceId,
        userId: userId.id,
        isOwner: userIsOwner,
      });
    } else {
      if (!userEmail) {
        setErrorUser(true);
        return false;
      }
      // Invoke action to send invite by email
      inviteUserToWorkspace({
        workspaceId,
        email: userEmail,
        isOwner: userIsOwner,
        message: inviteMessage,
      });
    }
    return true;
  };

  return (
    <div>
      <div className={classes.form}>
        <Typography variant="subtitle2">
          {isUserAdmin ? "Add user directly" : "Invite User"}
        </Typography>
        {isUserAdmin ? (
          <>
            <Autocomplete
              id="user-id"
              className={classes.input}
              value={userId}
              onChange={(_, value) => {
                if (errorUser) {
                  setErrorUser(false);
                }
                setUserId(value);
              }}
              options={users}
              getOptionLabel={option => option.email || ""}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select User"
                  error={errorUser}
                  helperText={errorUser ? "Please select a user" : ""}
                />
              )}
            />
          </>
        ) : (
          <>
            <TextField
              className={classes.input}
              value={userEmail}
              id="user-email"
              onChange={ev => {
                if (errorUser) {
                  setErrorUser(false);
                }
                setUserEmail(ev.target.value);
              }}
              label="Email"
              error={errorUser}
              helperText={errorUser ? "Please enter the user's email address" : ""}
              autoFocus
            />
            <TextField
              className={classes.input}
              value={inviteMessage}
              onChange={ev => {
                setInviteMessage(ev.target.value);
              }}
              label="Invitation message"
            />
          </>
        )}
      </div>
      <div className={classes.lastRow}>
        <div className={classes.userAttr}>
          <FormControlLabel
            control={
              <Checkbox
                checked={userIsOwner}
                onChange={event => setUserIsOwner(event.target.checked)}
              />
            }
            label="Admin"
            title="Give admin priviledge (does not remove yours)"
            classes={{ root: classes.checkbox }}
          />
          <HelpPopover
            tooltip="What is an admin?"
            maxWidth={500}
            helpContent={
              <>
                <Typography gutterBottom>
                  A workspace admin may edit the name and description of the workspace, as well as
                  inviting or removing members.
                </Typography>
                <Typography>Any admin may change the admin status of any member.</Typography>
              </>
            }
          />
        </div>
        <div className={classes.cardActions}>
          <IconButton size="small" title="Cancel adding user" onClick={handleClose}>
            <IconClose />
          </IconButton>
          <IconButton
            size="small"
            title="Add this user"
            onClick={() => {
              if (submitNewUser()) {
                handleClose();
              }
            }}
          >
            <IconDone />
          </IconButton>
          <div style={{ position: "relative" }}>
            <IconAdd
              style={{
                position: "absolute",
                transform: "scale(0.5)",
                right: -5,
                bottom: 0,
              }}
            />
            <IconButton
              size="small"
              title="Add this user (and keep adding users)"
              onClick={() => {
                if (submitNewUser()) {
                  if (isUserAdmin) {
                    setUserId(null);
                    document.getElementById("user-id").focus();
                  } else {
                    setUserEmail("");
                    document.getElementById("user-email").focus();
                  }
                }
              }}
            >
              <IconDone />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

WorkspaceUserAdd.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  inviteUserToWorkspace: PropTypes.func.isRequired,
  // These are only for admin mode, i.e. in the admin page:
  isUserAdmin: PropTypes.bool,
  users: PropTypes.func,
  addWorkspaceUser: PropTypes.func,
};

export default WorkspaceUserAdd;
