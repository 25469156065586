import { Typography, IconButton, Tooltip } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import history from "../helpers/history";

import FlexCol from "../components/common/FlexCol";

export default function ServerDownPage() {
  console.error("Network failure encountered");
  return (
    <FlexCol hAlign="center" vAlign="center" fullHeight fullWidth>
      <FlexCol hAlign="center" style={{ maxWidth: 800 }}>
        <img src="/img/sad-shark.svg" style={{ maxWidth: 300 }} />
        <Typography variant="h5" align="center">
          {`Fathom central is currently unavailable. We're sorry for any inconvenience, please try again later.`}
        </Typography>
        <Tooltip title={"Try again"}>
          <IconButton onClick={() => history.push("/")}>
            <RefreshIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </FlexCol>
    </FlexCol>
  );
}
