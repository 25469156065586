export enum AnimalEventType {
  CAPTURE = "AnimalEventCapture",
  RECOVERY = "AnimalEventDeviceRecovery",
  TAGGING = "AnimalEventTagging",
  RELEASE = "AnimalEventRelease",
}

export function getLatestEventTimeOfType(events, eventType: AnimalEventType): number | null {
  const times = events
    .filter(event => event.__typename === eventType)
    .map(event => new Date(event.time).getTime());
  return times.length > 0 ? Math.max(...times) : null;
}
