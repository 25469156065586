import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import DialogWrapper from "../common/DialogWrapper";

import WorkspaceEdit from "./WorkspaceEdit";

function WorkspaceDialog({
  open,
  handleClose,
  userId,
  isUserAdmin,
  users,
  workspace,
  updateWorkspace,
  deleteWorkspace,
  removeWorkspaceUser,
  addWorkspaceUser,
  updateWorkspaceUser,
  inviteUserToWorkspace,
  autoShowInviteUser,
  resendWorkspaceInvite,
}) {
  if (!workspace) return null;
  return (
    <DialogWrapper
      title={`Edit workspace "${workspace.name}"`}
      open={open}
      cancelAction={handleClose}
      buttons={({ cancelAction }) => (
        <Button variant="outlined" onClick={cancelAction}>
          Close
        </Button>
      )}
      fullWidth={false}
    >
      <WorkspaceEdit
        key={workspace.id}
        userId={userId}
        isUserAdmin={isUserAdmin}
        users={users}
        workspace={workspace}
        updateWorkspace={updateWorkspace}
        deleteWorkspace={deleteWorkspace}
        removeWorkspaceUser={removeWorkspaceUser}
        addWorkspaceUser={addWorkspaceUser}
        updateWorkspaceUser={updateWorkspaceUser}
        inviteUserToWorkspace={inviteUserToWorkspace}
        autoShowInviteUser={autoShowInviteUser}
        resendWorkspaceInvite={resendWorkspaceInvite}
      />
    </DialogWrapper>
  );
}

WorkspaceDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  userId: PropTypes.string,
  workspace: PropTypes.object,
  updateWorkspace: PropTypes.func.isRequired,
  deleteWorkspace: PropTypes.func.isRequired,
  removeWorkspaceUser: PropTypes.func.isRequired,
  addWorkspaceUser: PropTypes.func.isRequired,
  updateWorkspaceUser: PropTypes.func.isRequired,
  inviteUserToWorkspace: PropTypes.func,
  autoShowInviteUser: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  users: PropTypes.array,
};

export default WorkspaceDialog;
