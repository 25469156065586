import * as d3 from "d3";

export function parseBubblePlotData(deploymentsCsv, detCountCsv) {
  const deployments = d3.csvParse(deploymentsCsv).map(function (d) {
    return {
      lonlat: [+d.lon, +d.lat],
      deployment: +d.deployment,
      station: d.station,
    };
  });

  const hourlyDetCount = d3
    .csvParse(detCountCsv)
    .map(function (d) {
      return {
        period: d3.isoParse(d.period),
        deployment: +d.deployment,
        N: +d.N,
        fullid: d.fullid,
      };
    })
    .filter(d => d.deployment > -1);

  return {
    deployments: deployments,
    hourlyDetCount: hourlyDetCount,
  };
}

export async function fetchBubblePlotData(dataServerUrl, axios, studyId, tagIds) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const url = dataServerUrl + "/studies/" + studyId;

    /* Error flag that will be populated if any error occurs during data loading
     * and parsing. It is an object with two fields, type and message. Type is a
     * string that is one of (client, server, unknown).
     *  - client: Problem with study data caught by server, i.e. uncookable.
     *            Requires a change to the system to ever work.
     *  - server: Internal error server caught by server.
     *            May or may not be recoverable.
     *  - unknown: Catch all for all other errors. May or may not be recoverable. */
    let errorFlag;

    /* First trigger study processing. This tells the server to check if there have been
     * any changes to the system since the last processing (if any), and if so, to reprocess.
     * Processing means to take the receiver log data and system information and combine
     * them into CSV files that will be served separately and used to built the plot. */
    axios.post(url).catch(() => {
      errorFlag = { type: "unknown" };
    });

    /* Data is requested in a loop with timing defined by the server. A 200 indicates
     * that the data is ready, while a 202 indicates that processing is underway and
     * another call should be made. An error indicates that processing has failed and
     * no more requests should be made for now. */
    let callAgain = true;
    let firstEmptyDataList = true;

    while (callAgain) {
      callAgain = false;

      await axios
        .get(url)
        .then(response => {
          switch (response.status) {
            case 200:
              // Processing completed successfully

              if (!validDataList(response.data.contents.data)) {
                /* In this case the server reported successful processing however
                 * there is no data available on the server. This is likely due to
                 * a known race condition bug on the server. This can usually be
                 * resolved by simply calling one more time.
                 *
                 * If the workaround does not resolve the issue, this situation
                 * must be treated as an "unknown" error because we can't know whether
                 * the problem is due to a server error or a problem with the study. */
                if (firstEmptyDataList) {
                  callAgain = true;
                  firstEmptyDataList = false;
                  break;
                } else {
                  errorFlag = { type: "unknown" };
                  return;
                }
              }

              Promise.all([
                axios.get(url, {
                  params: { action: "read", datatype: "deployment" },
                }),
                axios.get(url, {
                  params: {
                    action: "read",
                    datatype: "det_count_1h",
                    select: tagIds && Array.isArray(tagIds) ? tagIds.join(",") : tagIds,
                  },
                }),
              ])
                .then(([deploymentRes, detCountRes]) => {
                  resolve({ deploymentCsv: deploymentRes.data, detCountCsv: detCountRes.data });
                })
                .catch(e => {
                  // data read failed
                  console.log(e);
                  errorFlag = { type: "unknown" };
                });
              break;
            case 202:
              // Data is being processed: call again (server determines wait time)
              callAgain = true;
              break;
            default:
              console.warn("Unexpected HTTP code: " + response.status);
          }
        })
        .catch(error => {
          let type, message;

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            switch (error.response.status) {
              case 404:
                type = "client";
                message = error.response.data.message;
                break;
              case 500:
                type = "server";
                message =
                  "There was a internal server error generating your detection map. Please try again later.";
                break;
              default:
                type = "unknown";
            }
          } else if (error.request) {
            // The request was made but no response was received
            type = "unknown";
          } else {
            // Something happened in setting up the request that triggered an Error
            type = "unknown";
          }

          errorFlag = { type: type, message: message };
        });

      if (errorFlag) {
        reject(errorFlag);
      }
    }
  });
}

// Verify that the server has the correct files to build the plot
function validDataList(dataList) {
  const names = dataList.map(d => d.name);
  return names.includes("det_count_1h") && names.includes("deployment");
}
