import { Duration } from "./detection-helper";
import { FontWeight } from "vega";

export const LOADING_INDICATOR_POSITION = { top: 8, left: 106 };

export const IDEAL_BINS = 200;

export const BIN_WIDTHS = [
  new Duration("minute", 1),
  new Duration("minute", 2),
  new Duration("minute", 4),
  new Duration("minute", 5),
  new Duration("minute", 10),
  new Duration("minute", 20),
  new Duration("minute", 30),
  new Duration("hour", 1),
  new Duration("hour", 2),
  new Duration("hour", 3),
  new Duration("hour", 4),
  new Duration("hour", 6),
  new Duration("hour", 8),
  new Duration("hour", 12),
  new Duration("day", 1),
  new Duration("day", 2),
  new Duration("day", 4),
  new Duration("day", 7),
  new Duration("day", 14),
  new Duration("day", 28),
];

/** The maximum space for the y axis labels */
export const Y_AXIS_EXTENT = 120;
/**
 * The the space the y-axis title takes up. This + Y_AXIS_EXTENT is the space between the left
 * edge of the container and the left edge of the plot area.
 */
export const Y_AXIS_TITLE_SPACE = 17;
/** The maximum space for the x axis labels */
export const X_AXIS_EXTENT = 20;
/** The desired height of a "row" for a nominal y-axis */
export const Y_STEP = 20;

/** Space taken up by the domain scroller. Best if odd number else centerLine won't be ... center */
export const DOMAIN_SCROLL_WIDTH = 19;
export const DEFAULT_BIN_SECONDS = 86400;
/**
 * These magic numbers depend on the font, font size, and expected number on characters of the
 * y-axis labels. So to make this programatically determined is more complicated than paying
 * attention to this if you change any of the above.
 * The expected max number of characters for RX is 6.  E.g.: `250467`
 * The expected max number of characters for TX is 15. E.g.: `A180-1702-27130`
 */
export const LABEL_OFFSET = { TX: 110, RX: 55 };
const AXIS_FONT = "'Nunito Sans', Arial, sans-serif";

type VegaConfig = {
  chartPadding: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
  font: {
    titleFont: string;
    titleFontSize: number;
    titleFontWeight: FontWeight;
    labelFont: string;
    labelFontSize: number;
  };
  xAxis: {
    minExtent: number;
    maxExtent: number;
    labelBound: boolean; // this prevents the axis labels from exceeding the chart container
  };
  yAxis: {
    minExtent: number;
    maxExtent: number;
  };
};

export const detectionsVegaConfig: VegaConfig = {
  chartPadding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 90,
  },
  font: {
    titleFont: AXIS_FONT,
    titleFontSize: 14,
    titleFontWeight: 500,
    labelFont: AXIS_FONT,
    labelFontSize: 12,
  },
  xAxis: {
    minExtent: X_AXIS_EXTENT,
    maxExtent: X_AXIS_EXTENT,
    labelBound: true,
  },
  yAxis: {
    minExtent: Y_AXIS_EXTENT,
    maxExtent: Y_AXIS_EXTENT,
  },
};
