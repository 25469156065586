import React from "react";
import autoBind from "auto-bind/react";

function Resizable<P>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<Omit<P, "width" | "height">> {
  /* Higher order component that measures the WrappedComponent's container's dimensions
   * and sends them in as props. It does this by adding a div around the WrappedComponent
   * with position absolute and 100% height and width, and then detecting changes to the
   * outer div using ResizeSensor."
   */

  return class extends React.Component<P, { width: number; height: number }> {
    ref = React.createRef<HTMLDivElement>();
    constructor(props) {
      super(props);
      autoBind(this);
      this.state = {
        width: 0,
        height: 0,
      };
    }

    componentDidMount() {
      this.setState({
        width: this.ref?.current ? this.ref.current.clientWidth : 0,
        height: this.ref?.current ? this.ref.current.clientHeight : 0,
      });

      if (window.ResizeObserver && this.ref?.current) {
        const resizeObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            this.setState({ width: entry.contentRect.width, height: entry.contentRect.height });
          }
        });
        resizeObserver.observe(this.ref.current);
      }
    }

    render() {
      const { height, width } = this.state;

      return (
        <div ref={this.ref} style={{ position: "absolute", height: "100%", width: "100%" }}>
          <WrappedComponent height={height} width={width} {...this.props} />
        </div>
      );
    }
  };
}
export default Resizable;
