import { ReactNode } from "react";
import XAxis from "./XAxis";
import * as d3 from "d3";

type Props = {
  xScale: d3.ScaleTime<number, number>;
  xAxisHeights: [number, number];
  label: string;
  marginLeft: number;
  hidden?: boolean;
  children?: ReactNode;
};

function XAxisSandwich({
  xScale,
  xAxisHeights,
  label,
  marginLeft,
  hidden = false,
  children,
}: Props) {
  if (hidden) return <>{children}</>;

  return (
    <>
      <XAxis xScale={xScale} orientation="top" height={xAxisHeights[0]} marginLeft={marginLeft} />
      <div style={{ position: "relative" }}>{children}</div>
      <XAxis
        xScale={xScale}
        orientation="bottom"
        height={xAxisHeights[1]}
        label={label}
        marginLeft={marginLeft}
      />
    </>
  );
}

export default XAxisSandwich;
