import SvgIcon from "@material-ui/core/SvgIcon";

export default function IconExport(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 17.783024,6.5229167 V 10.26665 C 10.92187,10.366754 4.9945853,12 5.0443544,18.271821 8.5849362,13.686675 12,13.80749 17.783024,13.880382 v 3.02626 L 23.838902,12 17.783024,7.0819469 Z" />
      <path d="m 1.1601562,2.1783899 c 0,6.54774 0,13.0954801 0,19.6432201 5.2848659,0 10.5697318,0 15.8545978,0 0,-1.462891 0,-2.925781 0,-4.388672 -0.731771,0 -1.463542,0 -2.195313,0 0,0.731771 0,1.463541 0,2.195312 -3.821975,0 -7.6439503,0 -11.4659254,0 0,-5.0855 0,-10.1710005 0,-15.2565008 3.8219751,0 7.6439504,0 11.4659254,0 0,0.7317709 0,1.4635417 0,2.1953126 0.731771,0 1.463542,0 2.195313,0 0,-1.4628906 0,-2.9257813 0,-4.3886719 -5.284866,0 -10.5697319,0 -15.8545978,0 z" />
    </SvgIcon>
  );
}
