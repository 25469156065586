import { Component } from "react";
import autoBind from "auto-bind";
import { withStyles } from "@material-ui/core/styles";
import history from "../helpers/history";
import { connect } from "react-redux";
import { compose } from "redux";
import { PasswordChange, LogoHeader, ErrorBox } from "../fathom-brella";
import Auth from "@aws-amplify/auth";
import {
  Card,
  CardContent,
  TextField,
  Button,
  CardActions,
  Typography,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import { Check, ExpandMore as IconExpand } from "@material-ui/icons";
import Header from "./Header";

const styles = theme => ({
  root: {
    background: "top / cover no-repeat url(/img/fathom-landing-bg.png)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "'Nunito Sans', sans-serif",
    overflow: "auto",
  },
  body: {
    background: "top / cover no-repeat url(/img/fathom-landing-bg.png)",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center",
    height: "100%",
    padding: theme.spacing(5),
  },
  signin: {
    "&:last-of-type": {
      marginLeft: theme.spacing(2),
    },
  },
  flexColCenter: { display: "flex", flexDirection: "column", alignItems: "center" },
  flexRowCenter: { display: "flex", flexDirection: "row", alignItems: "center" },
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
    minWidth: 250,
    width: "100%",
  },
  input: {
    marginBottom: theme.spacing(1),
  },
  middle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80px",
  },
  actions: {
    display: "flex",
  },
  resendHelp: {
    marginTop: theme.spacing(4),
    cursor: "pointer",
  },
  actionsContainer: {
    marginTop: theme.spacing(1.5),
    position: "relative",
  },
  loadingContainer: {
    position: "absolute",
    right: -30,
  },
  signUpContent: {
    width: 400,
    "@media screen and (max-width: 400px)": {
      width: 325,
    },
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
});

class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      values: {
        password: "",
        email: "",
        givenName: "",
        familyName: "",
      },
      errors: {
        password: "",
        email: "",
        givenName: "",
        familyName: "",
      },
      passwordValid: false,

      redirect: window.location.origin + "/signin",
      complete: false,

      // resending verification email
      showResend: false,
      resending: false,
      resent: false,
    };
    autoBind(this);
  }

  componentDidMount() {
    // just in case we had another user already signed in
    Auth.signOut();
    if (history.location && history.location.search) {
      const qParams = new URLSearchParams(history.location.search);
      const redirect = qParams.get("redirect");
      // allow pre-filled email for accepting workspace invite as new user
      const workspaceInviteId = qParams.get("workspaceInviteId");
      const workspaceInviteEmail = qParams.get("workspaceInviteEmail");
      const values = { ...this.state.values, email: workspaceInviteEmail };
      this.setState({ redirect, values, workspaceInviteId });
    }
  }
  onSubmit() {
    const valid = this.validate();
    if (!valid) return;
    const { email, password, givenName, familyName } = this.state.values;
    let redirect = this.state.redirect;
    const { workspaceInviteId } = this.state;

    if (workspaceInviteId) {
      redirect = encodeURIComponent(
        `${window.location.origin}/accept-ws-invite?id=${workspaceInviteId}&email=${email}`
      );
    }

    Auth.signUp({
      username: email.toLowerCase(),
      password,
      attributes: {
        email: email.toLowerCase(),
        given_name: givenName,
        family_name: familyName,
      },
      // Some extra metadata to build the email verification link
      clientMetadata: {
        redirect,
        origin: window.location.origin,
      },
    })
      .then(() => {
        this.setState({ complete: true, error: "" });
      })
      .catch(err => {
        this.setState({ error: err.message });
        console.warn(err);
      });
  }

  validate() {
    const { email, givenName, familyName } = this.state.values;
    const errors = {};

    if (!email) errors.email = "Email is required";
    if (!givenName && !familyName) {
      errors.givenName = "A given or family name is required";
      errors.familyName = "A given or family name is required";
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0 && this.state.passwordValid;
  }

  onChange(value, field) {
    this.setState({ values: { ...this.state.values, [field]: value } });
  }

  resendEmail() {
    const { email } = this.state.values;
    const { redirect } = this.state;
    this.setState({ resending: true, resent: false });

    Auth.resendSignUp(
      email.toLowerCase(),
      // Some extra metadata to build the email verification link
      {
        redirect,
        origin: window.location.origin,
      }
    )
      .then(() => {
        this.setState({ resending: false, resent: true, error: "" });
      })
      .catch(err => {
        this.setState({ resending: false, resent: false, error: err.message });
        console.error(err);
      });
  }

  render() {
    const { classes } = this.props;
    const { errors, complete, showResend, resending, resent, values } = this.state;
    return (
      <div className={classes.root}>
        <Header classes={classes} />
        <div className={classes.body}>
          <Card>
            <CardContent className={`${classes.signUpContent} ${classes.flexColCenter}`}>
              {!complete ? (
                <>
                  <LogoHeader title="Register" logoUrl="img/fathom-central.png" />
                  <form onSubmit={this.onSubmit} className={classes.form}>
                    <TextField
                      label="Email"
                      name="email"
                      type="email"
                      value={values.email}
                      onChange={e => this.onChange(e.target.value, "email")}
                      error={Boolean(errors.email)}
                      helperText={errors.email}
                      className={classes.input}
                    />
                    <TextField
                      label="Given Name"
                      name="givenName"
                      value={values.givenName}
                      onChange={e => this.onChange(e.target.value, "givenName")}
                      error={Boolean(errors.givenName)}
                      helperText={errors.givenName}
                      className={classes.input}
                    />
                    <TextField
                      label="Family Name"
                      name="familyName"
                      value={values.familyName}
                      onChange={e => this.onChange(e.target.value, "familyName")}
                      error={Boolean(errors.familyName)}
                      helperText={errors.familyName}
                      className={classes.input}
                    />
                    <PasswordChange
                      style={{ marginBottom: 8 }}
                      onChange={(valid, password) => {
                        this.setState({
                          values: { ...this.state.values, password },
                          passwordValid: valid,
                        });
                      }}
                    />
                    <ErrorBox style={{ maxWidth: 250 }} hidden={Boolean(this.state.error)}>
                      {this.state.error}
                    </ErrorBox>
                  </form>
                </>
              ) : (
                <>
                  <LogoHeader title="Sign up almost complete!" logoUrl="img/fathom-central.png" />
                  <Typography>
                    Thanks for signing up for Fathom! You must verify your email address to complete
                    sign up. Please check your email and follow the link.
                  </Typography>
                  <Typography
                    component="div"
                    gutterBottom
                    className={`${classes.flexRowCenter} ${classes.resendHelp}`}
                    onClick={() => this.setState({ showResend: !showResend })}
                  >
                    Don't see the verification email?
                    <IconExpand />
                  </Typography>
                  <Collapse in={showResend} classes={{ wrapperInner: classes.flexColCenter }}>
                    <Typography align="center">
                      Please check all categories / folders and spam. If it's still not there, try
                      resending it:
                    </Typography>
                    <div className={`${classes.flexRowCenter} ${classes.actionsContainer}`}>
                      <Button onClick={this.resendEmail} variant="contained" color="primary">
                        Resend
                      </Button>
                      <div className={classes.loadingContainer}>
                        {resending && <CircularProgress size={25} />}
                        {resent && <Check />}
                      </div>
                    </div>
                  </Collapse>
                  <ErrorBox style={{ maxWidth: 250 }} hidden={Boolean(this.state.error)}>
                    {this.state.error}
                  </ErrorBox>
                </>
              )}
            </CardContent>
            {!complete ? (
              <CardActions className={classes.middle}>
                <Button color="primary" variant="contained" onClick={this.onSubmit}>
                  Register
                </Button>
              </CardActions>
            ) : null}
          </Card>
        </div>
      </div>
    );
  }
}

export default compose(withStyles(styles), connect())(SignUpPage);
