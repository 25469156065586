import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const styles = theme => {
  return {
    spinnerContainer: {
      display: "flex",
      height: "100%",
      width: "100%",
      backgroundColor: "rgb(255,255,255,0.9)",
      alignItems: "center",
      justifyContent: "center",
    },
    progress: {
      margin: theme.spacing(2),
    },
  };
};

function Spinner(props) {
  const { classes } = props;

  return (
    <div className={classes.spinnerContainer}>
      <CircularProgress size={80} className={classes.progress} />
    </div>
  );
}

export default withStyles(styles)(Spinner);
