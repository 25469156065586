import React from "react";
import * as d3 from "d3";
import XBrush from "./XBrush";
import { withStyles } from "@material-ui/core/styles";
import ResizeSensor from "css-element-queries/src/ResizeSensor";
import TimeAxis from "../../../TimeAxis";
import { HOUR_IN_MS } from "../../../../../helpers/time";

const ZOOM_TRANSITION_DURATION = 350;

const margin = { top: 10, right: 55, bottom: 15, left: 70 };

const styles = {
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  countRect: {
    fill: "lightgray",
    stroke: "#B8B8B8",
  },
  axis: {
    color: "lightgray",
  },
  // used to overwrite the d3 brush styling
  "@global": {
    ".brush .selection": {
      stroke: "lightgray",
    },
  },
};

class BrushableTimeline extends React.PureComponent {
  constructor(props) {
    super(props);
    this.container = React.createRef();

    this.windowResizeHandler = this.windowResizeHandler.bind(this);
    this.brushHandler = this.brushHandler.bind(this);
  }

  componentDidMount() {
    const classes = this.props.classes;

    const rootNode = this.container.current;
    const root = d3.select(rootNode);

    const containerHeight = 72;
    const height = containerHeight - margin.top - margin.bottom;

    // internal state variables
    const containerWidth = rootNode.getBoundingClientRect().width;
    const width = containerWidth - margin.left - margin.right;

    const svg = root.append("svg").attr("height", "100%").attr("width", containerWidth);

    // main svg group off which to build chart
    const g = svg.append("g").attr("transform", translate(margin.left, margin.top));

    const rectClipPath = g
      .append("clipPath")
      .attr("id", "rect-clip")
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", height);

    // scales: data value -> pixel value
    const xScale = d3.scaleTime();
    const yScale = d3.scaleLinear().range([height, 0]);

    // maps: observation -> pixel value
    const xMap = d => xScale(new Date(d.period.getTime() + this.props.timeOffset));
    const yMap = d => height - yScale(d.N);

    // x Axis setup
    const xAxisGroup = g
      .append("g")
      .attr("class", classes.axis)
      .attr("transform", translate(0, height));
    const xAxis = new TimeAxis(xAxisGroup, xScale, "bottom", true);

    // y Axis setup
    const yAxis = d3.axisLeft(yScale).ticks(2);
    const yAxisGroup = g.append("g").attr("class", classes.axis).call(yAxis);

    // vertically flipped svg group for bars
    const rectsG = g
      .append("g")
      .attr("transform", translate(0, height) + " " + scale(1, -1))
      .attr("class", classes.countRect)
      .attr("clip-path", "url(#rect-clip)");

    // set up brush
    const brush = new XBrush(
      g.append("g"),
      xScale,
      this.brushHandler,
      this.props.playbackStatusHandler,
      height,
      "brush"
    );

    this.xScale = xScale;
    this.yScale = yScale;
    this.width = width;
    this.xMap = xMap;
    this.yMap = yMap;
    this.xAxis = xAxis;
    this.yAxis = yAxis;
    this.yAxisGroup = yAxisGroup;
    this.brush = brush;
    this.rectsG = rectsG;
    this.rectClipPath = rectClipPath;
    this.containerWidth = containerWidth;
    this.svg = svg;
    this.rootNode = rootNode;

    this.updateXScale();
    this.updateYScale();

    this.barWidth = durationToPx(xScale, this.props.binSize);

    this.rectsG
      .selectAll("rect")
      .data(this.props.data, d => d.period)
      .enter()
      .append("rect")
      .attr("y", 0)
      .attr("x", this.xMap)
      .attr("height", this.yMap)
      .attr("width", this.barWidth);

    new ResizeSensor(rootNode, this.windowResizeHandler);
  }

  brushHandler() {
    const sel = d3.event.selection;
    const timeRange = sel ? sel.map(this.xScale.invert) : null;
    const timeRangeUnshifted = timeRange && timeRange.map(t => new Date(t - this.props.timeOffset));
    this.props.timeRangeHandler(timeRangeUnshifted);
  }

  async componentDidUpdate(prevProps) {
    const dataChanged = this.props.data !== prevProps.data;
    const zoomChanged = this.props.timeZoom !== prevProps.timeZoom;
    const offsetChanged = this.props.timeOffset !== prevProps.timeOffset;

    if (zoomChanged) {
      const binSizeChange = this.props.binSize / prevProps.binSize;

      if (binSizeChange > 1) {
        // Bin size increasing => Zooming OUT

        // First, switch to using wider bars (lower resolution data)
        this.barWidth = durationToPx(this.xScale, this.props.binSize);
        this.updateYScale();
        this.updateBars();

        // Then, zoom out smoothly
        this.updateXScale(ZOOM_TRANSITION_DURATION);
        this.barWidth = durationToPx(this.xScale, this.props.binSize);
        this.zoomBars();
      } else if (binSizeChange < 1) {
        // Bin size decreasing => Zooming IN

        // First, zoom in gradually with existing bars
        this.updateXScale(ZOOM_TRANSITION_DURATION);
        this.barWidth = durationToPx(this.xScale, prevProps.binSize);
        await this.zoomBars();

        // Then, switch to using narrower bars (higher resolution data)
        this.updateYScale();
        this.barWidth = durationToPx(this.xScale, this.props.binSize);
        this.updateBars();
      } else {
        // Bin size unchanged => Zooming IN or OUT, doesn't matter

        /* Just do a smooth zoom transition, then update the bar data
         * to make sure no merged updates could ever be lost */
        this.updateXScale(ZOOM_TRANSITION_DURATION);
        this.barWidth = durationToPx(this.xScale, this.props.binSize);
        this.updateYScale();
        await this.zoomBars();
        this.updateBars();
      }
    } else if (dataChanged) {
      // Zoom has not changed but detection count data has -- likely due to change in tag selection

      // Just update the y-scale and bar heights
      this.updateYScale();
      this.updateBars();
    } else if (offsetChanged) {
      this.updateXScale();
      this.updateBars();
    }
  }

  updateBars() {
    // update the bars to use new data & bar width
    const rects = this.rectsG.selectAll("rect").data(this.props.data, d => d.period);
    rects
      .enter()
      .append("rect")
      .attr("y", 0)
      .merge(rects)
      .attr("x", this.xMap)
      .attr("width", this.barWidth)
      .attr("height", this.yMap);
    rects.exit().remove();
  }

  zoomBars() {
    // zoom the bars smoothly based on new xScale
    return this.rectsG
      .selectAll("rect")
      .transition()
      .duration(ZOOM_TRANSITION_DURATION)
      .attr("x", this.xMap)
      .attr("width", this.barWidth)
      .end();
  }

  updateWidth(newContainerWidth) {
    /* Handle width change by adjusting the DOMAIN of the X axis scale.
     * This update takes place instantly. */
    const { svg, xMap, rectsG, rectClipPath } = this;

    this.containerWidth = newContainerWidth;
    this.width = newContainerWidth - margin.left - margin.right;

    svg.attr("width", newContainerWidth);
    rectClipPath.attr("width", this.width);

    this.updateXScale();

    const barWidth = durationToPx(this.xScale, this.props.binSize);
    const rects = rectsG.selectAll("rect").data(this.props.data, d => d.period);

    rects.attr("x", xMap).attr("width", barWidth);
    rects.exit().remove();
  }

  windowResizeHandler() {
    const { rootNode, containerWidth } = this;
    const newContainerWidth = rootNode.getBoundingClientRect().width;
    if (containerWidth !== newContainerWidth) {
      this.updateWidth(newContainerWidth);
    }
  }

  getShiftedZoom() {
    return this.props.timeZoom.map(t => new Date(t.getTime() + this.props.timeOffset));
  }

  updateXScale(transitionDuration = 0) {
    const { xScale, width, xAxis, brush } = this;
    const zoomShifted = this.getShiftedZoom();
    xScale.domain(zoomShifted);

    xScale.range([0, width]);

    xAxis.updateScale(xScale);
    brush.updateScale(xScale, !!zoomShifted, transitionDuration);
  }

  updateYScale() {
    const zoomShifted = this.getShiftedZoom();

    const timeZoomedData = zoomShifted
      ? this.props.data.filter(d => d.period >= zoomShifted[0] && d.period <= zoomShifted[1])
      : this.props.data;

    const yExtent = [0, d3.max(timeZoomedData, d => d.N)];

    this.yScale.domain(yExtent);
    this.yAxis.scale(this.yScale);
    this.yAxisGroup.call(this.yAxis);
  }

  play() {
    this.brush.play();
  }

  pause() {
    this.brush.pause();
  }

  restart() {
    this.brush.restart();
  }

  render() {
    return <div ref={this.container} className={this.props.classes.root} />;
  }
}

function translate(x, y) {
  return "translate(" + x + " " + y + ")";
}

function scale(x, y) {
  return "scale(" + x + " " + y + ")";
}

function durationToPx(scale, duration) {
  return scale(new Date(scale.domain()[0].getTime() + duration * HOUR_IN_MS));
}

export default withStyles(styles)(BrushableTimeline);
