export type GlossaryTerm = {
  title: string;
  text: string;
  pluralTitle?: string;
  pluralText?: string;
};

export const AbacusPlot: GlossaryTerm = {
  title: "Chart",
  text: "chart",
};

export const Serial: GlossaryTerm = {
  title: "Serial Number",
  text: "serial number",
  pluralTitle: "Serial Numbers",
  pluralText: "serial numbers",
};

export const Transmitter: GlossaryTerm = {
  title: "Full ID",
  text: "full ID",
  pluralText: "full IDs",
};
