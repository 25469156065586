import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField } from "@material-ui/core";
import { formatDateTime } from "../../helpers/time";
import WorkspaceUsers from "./WorkspaceUsers";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    paddingTop: 0,
    flexGrow: 1,
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    width: "100%",
  },
  workspaceFields: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  subheading: {
    color: theme.palette.text.secondary,
    paddingBottom: "1em",
  },
  input: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
}));

function WorkspaceEdit({
  userId,
  workspace,
  updateWorkspace,
  removeWorkspaceUser,
  updateWorkspaceUser,
  inviteUserToWorkspace,
  autoShowInviteUser,
  isUserAdmin,
  users,
  addWorkspaceUser,
  resendWorkspaceInvite,
}) {
  const classes = useStyles();

  const [workspaceName, setWorkspaceName] = useState(workspace.name);
  const [workspaceDescription, setWorkspaceDescription] = useState(workspace.description);
  const [errorName, setErrorName] = useState(false);
  const isUserOwner =
    workspace.users.filter(workspaceUser => workspaceUser.id === userId && workspaceUser.isOwner)
      .length > 0;

  const handleUpdateWorkspace = () => {
    let doUpdate = false;
    if (workspaceDescription !== workspace.description) {
      doUpdate = true;
    }
    if (workspaceName !== workspace.name) {
      doUpdate = true;
      if (workspaceName.trim() === "") {
        doUpdate = false;
        setErrorName(true);
      }
    }
    if (doUpdate) {
      updateWorkspace({
        workspaceId: workspace.id,
        name: workspaceName,
        description: workspaceDescription,
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.flexCol}>
        <Typography className={classes.subheading}>
          Created by {workspace.creator.email} on {formatDateTime(workspace.created)}
        </Typography>

        <div className={classes.workspaceFields}>
          <TextField
            label="Workspace name"
            id="workspace-name"
            className={classes.input}
            value={workspaceName}
            onChange={event => {
              if (errorName) {
                setErrorName(false);
              }
              setWorkspaceName(event.target.value);
            }}
            onBlur={handleUpdateWorkspace}
            error={errorName}
            helperText={errorName ? "What is a workspace without a name?" : ""}
            disabled={!isUserOwner}
          />
          <TextField
            label="Description"
            id="workspace-description"
            className={classes.input}
            value={workspaceDescription}
            onChange={event => setWorkspaceDescription(event.target.value)}
            onBlur={handleUpdateWorkspace}
            disabled={!isUserOwner}
          />
        </div>
        <WorkspaceUsers
          userId={userId}
          workspace={workspace}
          removeWorkspaceUser={removeWorkspaceUser}
          updateWorkspaceUser={updateWorkspaceUser}
          inviteUserToWorkspace={inviteUserToWorkspace}
          autoShowInviteUser={autoShowInviteUser}
          isUserAdmin={isUserAdmin}
          users={users}
          addWorkspaceUser={addWorkspaceUser}
          isReadOnly={false}
          resendWorkspaceInvite={resendWorkspaceInvite}
        />
      </div>
    </div>
  );
}

WorkspaceEdit.propTypes = {
  workspace: PropTypes.object,
  isReadOnly: PropTypes.bool,
  userId: PropTypes.string,
  updateWorkspace: PropTypes.func,
  removeWorkspaceUser: PropTypes.func,
  updateWorkspaceUser: PropTypes.func,
  inviteUserToWorkspace: PropTypes.func,
  autoShowInviteUser: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  users: PropTypes.array,
  addWorkspaceUser: PropTypes.func,
};

export default WorkspaceEdit;
