import { CountType } from "./types";

export const MARGIN = {
  left: 60,
  right: 25,
};

export const COUNT_TYPE_OPTIONS: { value: CountType; label: string }[] = [
  { value: "animals", label: "Animals" },
  { value: "deployments", label: "Deployments" },
  { value: "studies", label: "Studies" },
  { value: "devicesSyspro", label: "Devices (Syspro)" },
  { value: "devicesManual", label: "Devices (Manual)" },
  { value: "devicesMobile", label: "Devices (Mobile)" },
  { value: "invitesPending", label: "Invites (Pending)" },
  { value: "invitesAccepted", label: "Invites (Accepted)" },
  { value: "invitesDeclined", label: "Invites (Declined)" },
  { value: "submissionsOtn", label: "Submissions (OTN)" },
  { value: "usersInternal", label: "Users (Internal)" },
  { value: "usersExternal", label: "Users (External)" },
  { value: "files", label: "Files (All)" },
  { value: "rxLogs", label: "Files (Rx-Logs)" },
];
