import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
}));

/*
 * A wrapper for WindowedTable so that it fills the available space and scales correctly.
 */
export default function TableContainer({ children, ...props }) {
  const classes = useStyles();
  return (
    <div className={classes.tableContainer} {...props}>
      <div className={classes.grow}>{children}</div>
    </div>
  );
}
