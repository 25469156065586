import { useState } from "react";
import { Card, CardContent, Typography, IconButton, Tooltip, CardActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import DescriptionIcon from "@material-ui/icons/Description";
import history from "../../helpers/history";
import {
  Edit as IconEdit,
  Delete as IconDelete,
  Publish as IconPublish,
  Archive as IconArchive,
} from "@material-ui/icons";
import TextFieldWithSubmit from "../common/TextFieldWithSubmit";
import classNames from "classnames";
import StudyIcon from "./StudyIcon";

const useStyles = makeStyles(theme => ({
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColCenter: {
    display: "flex",
    flexDirection: "column",
  },
  cardTextContainer: {
    padding: theme.spacing(0.5),
  },
  iconRow: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: theme.spacing(1),
  },
  studyCard: {
    border: "1px solid transparent",
  },
  selected: {
    border: `1px solid ${theme.palette.common.coral}`,
  },
}));

function StudyCard({
  study,
  deleteStudy,
  exportStudy,
  editStudy,
  submitStudy,
  copyStudy,
  isSelected,
}) {
  const classes = useStyles();

  const [nameEdit, setNameEditing] = useState({
    value: "",
    editing: false,
    hovering: false,
  });
  const [descEdit, setDescEditing] = useState({
    value: "",
    editing: false,
    hovering: false,
  });

  function onSubmit(input) {
    editStudy({
      ...input,
      studyId: study.id,
    });
  }

  const missingForExport = [];
  if (!study.deviceCount) {
    missingForExport.push("devices");
  }
  if (!study.deploymentCount) {
    missingForExport.push("deployments");
  }

  const missingForExportMsg =
    (missingForExport.length &&
      ` (DISABLED: requires ${missingForExport.join(", ")} linked to the study first)`) ||
    "";

  return (
    <Card raised className={classNames(classes.studyCard, { [classes.selected]: isSelected })}>
      <CardContent>
        <div className={classes.flexRow}>
          <div style={{ flexGrow: 1, minHeight: 65, display: "flex", flexDirection: "column" }}>
            {!nameEdit.editing ? (
              <div
                className={classNames(classes.flexRow, classes.cardTextContainer)}
                onMouseEnter={() => setNameEditing({ ...nameEdit, hovering: true })}
                onMouseLeave={() => setNameEditing({ ...nameEdit, hovering: false })}
              >
                <div className={classes.flexColCenter}>
                  <Typography variant="h5" component="h2">
                    {study.name}
                  </Typography>
                </div>
                <div className={classes.flexColCenter}>
                  {nameEdit.hovering && (
                    <Tooltip title={"Edit name"}>
                      <IconButton
                        size="small"
                        onClick={() =>
                          setNameEditing({ ...nameEdit, editing: true, value: study.name })
                        }
                      >
                        <IconEdit />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            ) : (
              <TextFieldWithSubmit
                value={nameEdit.value}
                onChange={value => setNameEditing({ ...nameEdit, value })}
                onSubmit={() => {
                  onSubmit({ name: nameEdit.value });
                  setNameEditing({ ...nameEdit, editing: false, value: "", hovering: false });
                }}
                onCancel={() => setNameEditing({ ...nameEdit, editing: false, value: "" })}
                submitDisabled={Boolean(!nameEdit.value)}
              />
            )}

            {!descEdit.editing ? (
              <div
                className={classNames(classes.flexRow, classes.cardTextContainer)}
                style={{ minHeight: 50 }}
                onMouseEnter={() => setDescEditing({ ...descEdit, hovering: true })}
                onMouseLeave={() => setDescEditing({ ...descEdit, hovering: false })}
              >
                <div className={classes.flexColCenter}>
                  {study.description ? (
                    <Typography variant="h6" gutterBottom>
                      {study.description}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Study description ...
                    </Typography>
                  )}
                </div>
                <div className={classes.flexColCenter}>
                  {descEdit.hovering && (
                    <Tooltip title="Edit Description">
                      <IconButton
                        size="small"
                        onClick={() =>
                          setDescEditing({ ...descEdit, editing: true, value: study.description })
                        }
                      >
                        <IconEdit />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            ) : (
              <TextFieldWithSubmit
                value={descEdit.value}
                placeholder="Description of the study"
                onChange={value => setDescEditing({ ...descEdit, value })}
                onSubmit={() => {
                  onSubmit({ description: descEdit.value });
                  setDescEditing({ ...descEdit, editing: false, value: "", hovering: false });
                }}
                onCancel={() => setDescEditing({ ...descEdit, editing: false, value: "" })}
              />
            )}
          </div>
        </div>
        <div className={classNames(classes.flexRow, classes.iconRow)}>
          <StudyIcon
            quantity={study.fileCount}
            icon={<DescriptionIcon style={{ fontSize: 32 }} />}
            title={"Files"}
            label={`View this study's files`}
            onClick={() => history.push("/files?study=" + study.id)}
          />
          <StudyIcon
            size={32}
            quantity={study.deviceCount}
            imgSrc={`/img/receiver.svg`}
            title={"Devices"}
            label={`View this study's devices`}
            onClick={() => history.push("/devices?study=" + study.id)}
          />
        </div>
        <div className={classNames(classes.flexRow, classes.iconRow)}>
          <StudyIcon
            size={32}
            quantity={study.animalCount}
            imgSrc={`/img/animals.svg`}
            title={"Animals"}
            label={`View this study's animals`}
            onClick={() => history.push("/animals?study=" + study.id)}
          />
          <StudyIcon
            size={24}
            quantity={study.deploymentCount}
            imgSrc={`/img/fathom-pin.png`}
            title={"Deployments"}
            label={`View this study's deployments`}
            margin={4}
            onClick={() => history.push("/deployments?study=" + study.id)}
          />
        </div>
      </CardContent>
      <CardActions style={{ paddingLeft: 16, paddingRight: 16 }}>
        <div
          style={{
            borderTop: "1px solid lightgrey",
            padding: 8,
            display: "flex",
            justifyContent: "flex-end",
            flexGrow: 1,
          }}
        >
          <Tooltip title="Copy to another workspace">
            <IconButton onClick={() => copyStudy(study)}>
              <img
                src={"/img/content_copy-24px.svg"}
                alt={"Copy Study"}
                width={20}
                height={20}
                style={{ filter: "opacity(60%)" }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Submit to telemetry network" + missingForExportMsg}>
            <span>
              <IconButton onClick={() => submitStudy(study)} disabled={missingForExport.length > 0}>
                <IconPublish />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={"Export to file" + missingForExportMsg}>
            <span>
              <IconButton onClick={() => exportStudy(study)} disabled={missingForExport.length > 0}>
                <IconArchive />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => deleteStudy(study)}>
              <IconDelete />
            </IconButton>
          </Tooltip>
        </div>
      </CardActions>
    </Card>
  );
}

StudyCard.propTypes = {
  study: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    fileCount: PropTypes.number,
    deviceCount: PropTypes.number,
    animalCount: PropTypes.number,
    deploymentCount: PropTypes.number,
  }).isRequired,
  deleteStudy: PropTypes.func.isRequired,
  editStudy: PropTypes.func.isRequired,
  exportStudy: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export default StudyCard;
