import { useRef, useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import memoize from "memoize-one";
import * as d3 from "d3";

import BrushOverlay from "./BrushOverlay";
import EventOverlay from "./EventOverlay";
import ReadoutLine from "./ReadoutLine";

import { formatD3DateTime } from "../../../helpers/time";
import ReadoutOverlay from "./ReadoutOverlay";
import { ChartListSeries } from "../ChartList";
import { MARGIN } from "../constants";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
  },
  svg: {
    height: "100%",
    width: "100%",
    display: "block",
    flexGrow: 1,
  },
  line: {
    stroke: "black",
    strokeWidth: "1px",
    pointerEvents: "none",
  },
  readoutOverlayContainer: {
    flexShrink: 1,
    height: "100%",
    width: MARGIN.right,
    borderLeft: "1px solid black",
    background: "rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
  },
});

type Props = {
  seriesByYVal: ChartListSeries[];
  events: any[];
  xScale: d3.ScaleTime<number, number>;
  yScales: d3.ScaleLinear<number, number>[];
  serialColorScale: d3.ScaleOrdinal<string, string>;
  width: number;
  zoomHandler: (sel: number[] | null) => any;
  chartHeight: number;
  totalHeight: number;
};

function Overlay({
  seriesByYVal,
  events,
  xScale,
  yScales,
  serialColorScale,
  width,
  zoomHandler,
  chartHeight,
  totalHeight,
}: Props) {
  const svgRef = useRef<SVGSVGElement>(null);
  const classes = useStyles();
  const [mouseOver, setMouseOver] = useState<number[] | undefined>(undefined);
  useEffect(() => {
    const startListening = () => {
      const svg = svgRef.current;
      if (svg) {
        d3.select(window).on("mousemove.overlay", () => setMouseOver(d3.mouse(svg)), true);
      }
    };
    const stopListening = () => {
      d3.select(window).on("mousemove.overlay", null);
      setMouseOver(undefined);
    };

    d3.select(svgRef.current).on("mouseover", startListening).on("mouseout", stopListening);

    return stopListening;
  }, [svgRef]);

  const visible = mouseOver && scaleIsValid(xScale);

  const t = visible ? xScale.invert(mouseOver![0]).getTime() : undefined; // assert mouseOver defined if visible
  const timeString = t ? formatD3DateTime(t) : undefined;

  const resetZoom = () => zoomHandler(null);

  return (
    <div className={classes.root}>
      <svg ref={svgRef} className={classes.svg} onDoubleClick={resetZoom}>
        <BrushOverlay height={totalHeight} width={width} selectionHandler={zoomHandler} />
        {visible && (
          <ReadoutLine
            timeString={timeString}
            x={mouseOver![0]} // assert mouseOver defined, because visible
            y={mouseOver![1]}
          />
        )}
        <EventOverlay events={events} xScale={xScale} />
      </svg>

      <div className={classes.readoutOverlayContainer}>
        {visible && (
          <ReadoutOverlay
            legendHeight={chartHeight}
            totalHeight={totalHeight}
            seriesByYVal={seriesByYVal}
            yScales={yScales}
            serialColorScale={serialColorScale}
            t={t!} // assert t defined, because visible
          />
        )}
      </div>

      <Button
        variant="outlined"
        onClick={resetZoom}
        style={{ position: "absolute", top: 10, right: 110 }}
      >
        reset zoom
      </Button>
    </div>
  );
}

const scaleIsValid = memoize(scale => scale.domain().every(isFinite));

export default Overlay;
