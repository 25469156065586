import { useEffect } from "react";
import DialogWrapper from "../common/DialogWrapper";
import { useSelectorTyped as useSelector, useThunkDispatch } from "../../redux/common";
import { logout } from "../../redux/user/user-actions";
import { checkNetwork, resetRetryCount } from "../../redux/network/network-actions";
import FlexCol from "../common/FlexCol";
import { Button, LinearProgress, Typography } from "@material-ui/core";

const RETRY_INTERVAL_MS = 1000;
const MAX_RETRIES = 100; // try for just over a minute
let retryTimer;

export function NetworkError() {
  const dispatch = useThunkDispatch();
  const { open, retryCount } = useSelector(({ network }) => ({
    open: !network.isOK,
    retryCount: network.retryCount,
  }));

  useEffect(() => {
    if (open && retryCount < MAX_RETRIES) {
      clearTimeout(retryTimer);
      retryTimer = setTimeout(() => dispatch(checkNetwork()), RETRY_INTERVAL_MS);
    }
  }, [dispatch, open, retryCount]);

  return (
    <DialogWrapper
      open={open}
      title={"Server Temporarily Unreachable"}
      cancelAction={() => dispatch(logout())}
      okAction={() => dispatch(resetRetryCount())}
      buttons={({ cancelAction, okAction }) => (
        <>
          {retryCount >= MAX_RETRIES && (
            <Button onClick={okAction} variant="outlined">
              Keep Trying
            </Button>
          )}
          <Button onClick={cancelAction} variant="outlined">
            Log Out
          </Button>
        </>
      )}
      maxWidth="sm"
    >
      <FlexCol fullWidth hAlign="center">
        <img src="/img/sad-shark.svg" style={{ maxWidth: 300 }} />
        <Typography variant="subtitle1" style={{ alignSelf: "flex-start" }}>
          {(retryCount >= MAX_RETRIES &&
            "Max retries reached, but you may choose to keep trying if you wish.") ||
            "Trying to connect to the server ..."}
        </Typography>
        <div style={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={retryCount} />
        </div>
      </FlexCol>
    </DialogWrapper>
  );
}
