import React, { useEffect, useState } from "react";
import DialogWrapper from "./DialogWrapper";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import FlexRow from "./FlexRow";
import Check from "@material-ui/icons/Check";
import { handleGqlErrors } from "../../redux/gql-error/gql-error-actions";

import { callGqlApi } from "../../helpers/api";
import gql from "../../redux/gqlTag";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  codeTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
  },
  securityMessage: {
    marginBottom: theme.spacing(2.5),
  },
}));

type OPActivationDialogProps = {
  open: boolean;
  cancelAction: () => void;
};
type OPCodePair = {
  serial: string;
  key: string;
};

function OPActivationDialog({ open, cancelAction }: OPActivationDialogProps) {
  const [codes, setCodes] = useState<OPCodePair[]>([]);
  const [codesLoading, setCodesLoading] = useState<boolean>(false);
  const [cpToClipboardRun, setCpToClipboardRun] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      // Load codes
      setCodesLoading(true);
      callGqlApi(gql`
        {
          opActivationKeys {
            serial
            key
          }
        }
      `)
        .then(({ opActivationKeys }) => setCodes(opActivationKeys))
        .catch(handleGqlErrors)
        .finally(() => setCodesLoading(false));
    } else {
      setCodes([]);
      setCodesLoading(false);
      setCpToClipboardRun(false);
    }
  }, [open]);

  function codesToClipboard() {
    const codeString = codes.map(c => `${c.serial},${c.key}`).join("\n");
    navigator.clipboard.writeText(codeString);
    setCpToClipboardRun(true);
  }

  return (
    <DialogWrapper
      title={"OP Activation"}
      open={open}
      cancelAction={cancelAction}
      buttons={({ cancelAction }) => (
        <Button onClick={cancelAction} color="primary" variant="contained">
          Dismiss
        </Button>
      )}
    >
      <Typography className={classes.securityMessage}>
        OP Activation keys should be treated as sensitive information, for that reason they should
        not be shared or distributed
      </Typography>
      {codesLoading ? (
        <CircularProgress size={20} />
      ) : codes.length > 0 ? (
        <>
          <Typography className={classes.codeTitle}>
            Receivers Eligible for OP Activation:
          </Typography>
          <div
            // Display serials in 3 columns with larger amount of serials to use more horizontal space
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${codes.length > 8 ? 3 : 1}, 1fr)`,
              gap: "2px",
            }}
          >
            {codes.map(c => (
              <div key={c.serial}>{c.serial}</div>
            ))}
          </div>
          <FlexRow hAlign="center" paddingLevel={3}>
            <>
              <Button
                id="copy"
                variant="outlined"
                color="primary"
                size="large"
                onClick={codesToClipboard}
              >
                Copy to clipboard
              </Button>
              {cpToClipboardRun && <Check style={{ marginTop: 8, marginLeft: 8 }} />}
            </>
          </FlexRow>
        </>
      ) : (
        <Typography className={classes.codeTitle}>
          No Receivers Eligible for OP Activation
        </Typography>
      )}
    </DialogWrapper>
  );
}

export default OPActivationDialog;
