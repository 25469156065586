import PropTypes from "prop-types";
import DetailsPanel from "../common/DetailsPanel";
import { Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LabeledLine from "../common/LabeledLine";
import PanelPlaceHolder from "../common/PanelPlaceHolder";
import { formatFileSize } from "../../helpers/common";
import { formatDateTime } from "../../helpers/time";
import HRFileMessage from "../detection/HRFileMessage";
import { Serial } from "../../helpers/glossary";

const styles = theme => ({
  header: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(1),
  },
  subTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: 16,
  },
  detail: {
    paddingLeft: theme.spacing(3),
  },
  rxDiagBtn: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

function renderCounts(counts, aP) {
  const hasPPM = counts.ppmExternalAccepted > 0;
  const hasHR = counts.hrExternalAccepted > 0;
  const hasPPMSelf = counts.ppmSyncTagAccepted > 0;
  return (
    <>
      {hasPPM && (
        <LabeledLine
          label={"# of detections (PPM)"}
          value={counts.ppmExternalAccepted?.toLocaleString()}
          alignPercent={aP}
        />
      )}
      {hasPPMSelf && (
        <LabeledLine
          label={"# of self detections (PPM)"}
          value={counts.ppmSyncTagAccepted?.toLocaleString()}
          alignPercent={aP}
        />
      )}
      {hasHR && (
        <LabeledLine
          label={"# of detections (HR)"}
          value={counts.hrExternalAccepted?.toLocaleString()}
          alignPercent={aP}
        />
      )}
    </>
  );
}

function FilesDetailsPanel(props) {
  const { classes, details } = props;
  const rxLogProperties = details?.rxLogProperties;
  const rxLogStatus = details?.rxLogStatus;
  const receiverFwVersion = rxLogProperties?.receiverFwVersion;

  const counts = rxLogProperties?.counts;
  const aP = 40;

  return (
    <DetailsPanel
      header={
        <div className={classes.header}>
          <Typography variant="h6"> Details </Typography>
        </div>
      }
      content={
        <div className={classes.content}>
          {details ? (
            <>
              <LabeledLine label={"File Name"} value={details.name} alignPercent={aP} />
              <LabeledLine label={"Size"} value={formatFileSize(details.size)} alignPercent={aP} />
              <LabeledLine
                label={"Upload Time"}
                value={formatDateTime(details.lastModified)}
                alignPercent={aP}
              />

              <br />
              {rxLogProperties && (
                <>
                  <LabeledLine
                    label={`${Serial.title}`}
                    value={rxLogProperties.serial}
                    alignPercent={aP}
                  />
                  <LabeledLine
                    label={"Receiver Model"}
                    value={rxLogProperties.model}
                    alignPercent={aP}
                  />
                  {receiverFwVersion && (
                    <LabeledLine
                      label={"Firmware Version"}
                      value={`${receiverFwVersion.major ?? 0}.${receiverFwVersion.minor ?? 0}.${
                        receiverFwVersion.patch ?? 0
                      }`}
                      alignPercent={aP}
                    />
                  )}
                  <br />
                  <LabeledLine
                    label={"Initialization"}
                    value={formatDateTime(rxLogProperties.initializationTime)}
                    alignPercent={aP}
                  />
                  <LabeledLine
                    label={"Offload"}
                    value={formatDateTime(rxLogProperties.offloadTime)}
                    alignPercent={aP}
                  />
                  <br />
                  <LabeledLine
                    label={"First Record"}
                    value={formatDateTime(rxLogProperties.firstRecordTime)}
                    alignPercent={aP}
                  />
                  <LabeledLine
                    label={"Last Record"}
                    value={formatDateTime(rxLogProperties.lastRecordTime)}
                    alignPercent={aP}
                  />

                  <br />
                  {counts && renderCounts(counts, aP)}
                  <br />
                  {rxLogStatus?.merging == "unsupported" ? (
                    rxLogProperties.model.includes("HR") ? (
                      <HRFileMessage />
                    ) : (
                      "File is not supported"
                    )
                  ) : (
                    <div
                      className={classes.rxDiagBtn}
                      onClick={() => props.selectLogRxDiag(details.name)}
                    >
                      <Button variant="outlined"> View in Receiver Diagnostics </Button>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <PanelPlaceHolder> Select a file to view details </PanelPlaceHolder>
          )}
        </div>
      }
    />
  );
}

FilesDetailsPanel.propTypes = {
  details: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    lastModified: PropTypes.string.isRequired,
  }),
};
export default withStyles(styles)(FilesDetailsPanel);
