import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles({
  root: ({ width, flexGrow, flexShrink }) => ({
    width,
    flexGrow,
    flexShrink,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "0px 5px!important",
    height: "100%",
  }),
  ellipses: {
    //text-overflow: "ellipsis" doesn't work with display: flex, so we have to use an inner span
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

function Cell(
  {
    width,
    numeric,
    flexGrow = 1,
    flexShrink = 0,
    noEllipses,
    children,
    disabled = false,
    ...restProps
  },
  ref
) {
  const classes = useStyles({ width, flexGrow, flexShrink });
  const align = numeric ? "right" : "left";
  return (
    <TableCell
      component="div"
      className={classes.root}
      variant="body"
      align={align}
      ref={ref}
      {...restProps}
    >
      {noEllipses ? (
        disabled ? (
          /* 
            Only wrap with a span when disabled
            Wrapping in a span was causing the EditableCell ellipses not to display
            Editable should never be rendered with the row is disabled anyways 
          */
          <span style={{ opacity: "50%" }}>{children}</span>
        ) : (
          children
        )
      ) : (
        <span style={{ opacity: disabled ? "50%" : "unset" }} className={classes.ellipses}>
          {children}
        </span>
      )}
    </TableCell>
  );
}

export default React.memo(forwardRef(Cell));
