import { useState } from "react";
import { connect } from "react-redux";
import { copyStudyToWorkspace } from "../../redux/study/study-actions";

import { TextField, Typography, MenuItem, CircularProgress } from "@material-ui/core";
import DialogWrapper from "../common/DialogWrapper";

function StudyCopyDialog({ open, onClose, study, otherWorkspaces, dispatch }) {
  const [targetWorkspaceId, setTargetWorkspaceId] = useState("");
  const [isCopying, setIsCopying] = useState(false);

  const handleCopy = () => {
    setIsCopying(true);
    dispatch(copyStudyToWorkspace({ studyId: study.id, targetWorkspaceId })).then(() => {
      setIsCopying(false);
      onClose();
    });
  };

  return (
    <DialogWrapper
      open={open}
      onClose={onClose}
      title="Copy Study to Another Workspace"
      okAction={handleCopy}
      okButtonContent={isCopying ? <CircularProgress size={24} /> : "Copy"}
      okDisabled={!targetWorkspaceId || isCopying}
      cancelAction={onClose}
    >
      <Typography paragraph>
        Your study and all of its files, devices, animals and deployments will be copied to the
        target workspace.
      </Typography>

      <TextField
        select
        label="Target Workspace"
        value={targetWorkspaceId}
        onChange={event => setTargetWorkspaceId(event.target.value)}
        margin="dense"
        fullWidth
      >
        {otherWorkspaces.map(workspace => (
          <MenuItem key={workspace.id} value={workspace.id}>
            {workspace.name}
          </MenuItem>
        ))}
      </TextField>
    </DialogWrapper>
  );
}

const mapStateToProps = ({ workspaces }) => {
  return {
    otherWorkspaces: workspaces.workspaces.filter(w => w.id !== workspaces.selectedWorkspace.id),
  };
};

export default connect(mapStateToProps)(StudyCopyDialog);
