import path from "path";
import { round, cloneDeep } from "lodash";
import { parseDateTime } from "./time";
//
// FUNCTIONS
//

export function toTitleCase(str) {
  if (!str) return null;
  return str
    .split(" ")
    .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(" ");
}

/**
 * Formats file size.
 *
 * @param {number} sizeInBytes - Number of bytes.
 * @returns {string} Formatted size.
 */
export function formatFileSize(sizeInBytes) {
  if (sizeInBytes === null || sizeInBytes === undefined || isNaN(sizeInBytes)) {
    return "";
  }
  let size = sizeInBytes / 1000000000;
  if (Math.floor(size) > 0) {
    return Math.round(size) + " GB";
  }
  size = sizeInBytes / 1000000;
  if (Math.floor(size) > 0) {
    return Math.round(size) + " MB";
  }
  size = sizeInBytes / 1000;
  if (Math.floor(size) > 0) {
    return Math.round(size) + " KB";
  }
  return Math.round(sizeInBytes) + " B";
}

export function addDirToFiles(dir, files) {
  return files.map(f => path.join(dir, f));
}

export function isObjEmpty(obj) {
  if (!obj) return true;
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function treatOnlyWhitespaceAsEmpty(value) {
  if (value.trim() === "") return "";
  else return value;
}

/**
 * Remove characters illegal on most operating systems from a file name.
 *
 * @param {string} fileName the raw file name to clean
 *
 * @returns {string} the cleaned file name
 */
export function cleanFileName(fileName) {
  return fileName.replace(/[<>:"/\\|?*]/g, "-");
}

/**
 * Round a number to n digits, with trailing digits to
 * ensure that there are always exactly n digits after the decimal
 *
 * Note: uses lodash.round. Native approach `Math.round(x / a) * a` does not work in all cases
 * See https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
 *
 * @param {number} x the original number
 * @param {number} nDigits the number of digits
 *
 * @returns {string} the rounded number as a string
 */
export function roundFixed(x, nDigits) {
  const rounded = round(x, nDigits);
  return rounded.toFixed(nDigits);
}

/**
 *
 * @param {string} email - email to validate
 *
 * @returns {boolean} - true if email is valid
 */
export function validateEmail(email) {
  const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!emailRegex.test(email)) {
    return false;
  }
  return true;
}

/**
 *
 * @param {string} email - email to test
 *
 * @returns {boolean} - true if email is internal, else false
 */
export function isInternalEmail(email) {
  const internalEmailRegex = /(innovasea|vemco)\.com$/;
  if (internalEmailRegex.test(email)) {
    return true;
  }
  return false;
}

export function formatLatLon(coord) {
  if (coord === null || coord === undefined) {
    return coord;
  }
  return round(coord, 8);
}

// perform routine validation on a set of form field values
export function validateFormFields(values, fields, requiredErrorMsg) {
  const fieldErrors = {};
  const formErrors = [];

  fields.forEach(field => {
    const value = values[field.dataKey];
    const { inputType, isRequired } = field;
    const fieldError = validateFormField(value, inputType, isRequired, requiredErrorMsg);
    if (fieldError) {
      fieldErrors[field.dataKey] = fieldError;
    }
  });

  return { fieldErrors, formErrors };
}

export function validateFormField(
  value,
  inputType,
  isRequired,
  requiredErrorMsg = "Required field"
) {
  if (isRequired && (!value || !value === [])) {
    return requiredErrorMsg;
  }

  // field not submitted: skip validation
  if (value === undefined) {
    return;
  }

  if (inputType === "datetime") {
    if (value && !parseDateTime(value).isValid()) {
      return "Invalid datetime.";
    }
  } else if (inputType === "number") {
    if (value !== "" && isNaN(Number(value))) {
      return "Invalid number.";
    }
  } else if (inputType === "measurement") {
    if (value === null) {
      return;
    }
    if (value.value !== "" && isNaN(Number(value.value))) {
      return "Invalid number.";
    }
    if (value.unit === "") {
      return "Unit is required.";
    }
  }
}

/**
 * Remove empty form fields from animal subobject (e.g. taggingEvent, measurementSet)
 *
 * @param {object} values - The current values input into the subobject
 * @param {object[]} fields - The field objects that specify the configuration of the form
 * @param {boolean} replaceWithNull - If true, empty values will be replaced with null.
 *   Useful when editing sub-objects to nullify existing fields.
 * @returns {object} - cleaned values, with empty values removed
 */
export function removeEmptyFields(values, fields, replaceWithNull = false) {
  const cleanedValues = {};

  fields.forEach(field => {
    let submitField = true;
    const fieldKey = field.dataKey;
    const fieldValue = values[fieldKey];

    if (fieldValue === undefined || fieldValue === null || fieldValue === "") {
      // no value populated
      submitField = false;
    } else if (field.inputType === "measurement" && fieldValue.value === "") {
      // no value populated in measurement { unit, value } pair
      submitField = false;
    }

    // add the data for submission if it is filled out
    if (submitField) {
      cleanedValues[fieldKey] = fieldValue;
    } else if (replaceWithNull) {
      // in edit mode we want to null the fields so data can be unset
      cleanedValues[fieldKey] = null;
    }
  });
  return cleanedValues;
}

// Cast all values in an animal subobject input to the types required by API
export function typeCastValues(values, fields) {
  const castValues = cloneDeep(values);

  fields.forEach(field => {
    const fieldKey = field.dataKey;
    const fieldValue = values[fieldKey];
    if (fieldValue !== undefined && fieldValue !== null) {
      const value = parseFormField(field.inputType, fieldValue);
      castValues[fieldKey] = value;
    }
  });

  return castValues;
}

// Convert string values from inputs to types required by API
export function parseFormField(inputType, value) {
  if (value === "") {
    return "";
  } else if (inputType === "number") {
    return parseFloat(value);
  } else if (inputType === "datetime") {
    return parseDateTime(value).toISOString();
  } else if (inputType === "measurement") {
    const newValue = value.value === "" ? "" : parseFloat(value.value);
    return { value: newValue, unit: value.unit };
  } else {
    return value;
  }
}

// initial state for a form section representing an animal sub-object
// e.g. measurementSet, taggingEvent, deviceRecoveryEvent, etc.
export function initialFormState(fields) {
  const state = { selectedFields: [], values: {}, fieldErrors: {}, formErrors: [] };

  fields.forEach(field => {
    if (field.isDefaultSelected || field.isRequired) {
      state.selectedFields.push(field.dataKey);
    }
    if (field.inputType === "measurement") {
      state.values[field.dataKey] = { value: "", unit: field.unitDefault };
    } else {
      state.values[field.dataKey] = field.defaultValue || "";
    }
  });

  return state;
}

export function userDisplayName(user) {
  return `${user.givenName || ""}${user.familyName && user.familyName ? " " : ""}${
    user.familyName || ""
  }`;
}

export function randomStr() {
  return (Math.random() + 1).toString(36).substring(7);
}

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
