import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import memoize from "memoize-one";
import { Autocomplete } from "@material-ui/lab";

import {
  FormLabel,
  TextField,
  IconButton,
  MenuItem,
  Checkbox,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import { RemoveCircleOutline as RemoveCircleOutlineIcon } from "@material-ui/icons";
import FlexCol from "../common/FlexCol";
import FlexRow from "../common/FlexRow";

const manualSensorTypes = ["TEMPERATURE", "ACCELERATION", "DEPTH", "PREDATION_35D"];
const htiModels = ["V3", "V6", "HR3"];
const htiPulseWidths = [
  "0.5 ms CW",
  "1.0 ms CW",
  "2.0 ms CW",
  "3.0 ms CW",
  "2.0 ms Coded",
  "3.0 ms Coded",
];
const htiModulations = {
  "Single Pulse": 1,
  "Double Pulse": 2,
};

const useStyles = makeStyles(theme => {
  return {
    transmitterInputs: {
      marginBottom: theme.spacing(1),
      display: "flex",
      flexGrow: 1,
      justifyContent: "space-between",
      alignItems: "center",
    },
    transmitterInputGroup: {
      borderRadius: "5px",
      border: "1px solid lightgray",
      padding: theme.spacing(2),
      flexGrow: 1,
    },
    group: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 10,
      marginTop: 5,
    },
  };
});

function TransmitterIdInput({
  codespaces,
  deviceClass,
  setValue,
  values,
  errors,
  handleRemove,
  model,
}) {
  const classes = useStyles();

  const codespaceOptions = cachedCodespaces(codespaces);
  /** Note: transmissionType in this context does not directly correspond to the database table
   * device_transmission_types; it is basically "HTI or everything else" */
  const htiEnabled = htiModels.includes(model);
  const transmissionType =
    values.transmissionType == "ACOUSTIC_HTI" && htiEnabled ? "ACOUSTIC_HTI" : "CODED_TX";

  const isNotPredation = values.dimension.indexOf("PREDATION") < 0;

  return (
    <div className={classes.transmitterInputs}>
      <div className={classes.transmitterInputGroup}>
        <FormControl>
          <FormLabel style={{ marginBottom: 8 }}>Transmission Type</FormLabel>
          <RadioGroup
            value={transmissionType}
            onChange={event => setValue("transmissionType", event.target.value)}
            row
            style={{ marginTop: -8, marginBottom: 8 }}
          >
            <FormControlLabel value="CODED_TX" control={<Radio />} label="PPM / HR" />
            <Tooltip
              title={
                !htiEnabled
                  ? `HTI is only valid for the following models: ${htiModels.join(", ")}`
                  : ""
              }
            >
              <span>
                <FormControlLabel
                  value="ACOUSTIC_HTI"
                  control={<Radio />}
                  label="HTI"
                  disabled={!htiEnabled}
                />
              </span>
            </Tooltip>
          </RadioGroup>
        </FormControl>
        {transmissionType === "CODED_TX" && (
          <>
            <FlexCol fullWidth itemSpacing={2}>
              <FormLabel>Code Space *</FormLabel>
              <FlexRow vAlign="center" spaceBetween itemSpacing={2}>
                <Autocomplete
                  size="medium"
                  fullWidth={true}
                  options={codespaceOptions}
                  onChange={(_, option) => setValue("codespace", option)}
                  value={values.codespace}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      error={Boolean(errors.codespace)}
                      helperText={errors.codespace}
                      label="Codespace"
                    />
                  )}
                />
              </FlexRow>

              <TextField
                size="small"
                required
                fullWidth={true}
                value={values.transmitId}
                label={deviceClass === "RECEIVER" ? "Self-ID number" : "ID number"}
                onChange={event => setValue("transmitId", idFilter(event.target.value))}
                error={Boolean(errors.transmitId)}
                helperText={errors.transmitId}
              />
            </FlexCol>

            {deviceClass === "TAG" && (
              <>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <Checkbox
                    color="primary"
                    checked={values.isSensor}
                    onChange={event => setValue("isSensor", event.target.checked)}
                  />
                  <span style={{ color: "gray" }}>Sensor Tag</span>
                </div>

                {values.isSensor && (
                  <>
                    <TextField
                      style={{ marginBottom: 10 }}
                      size="small"
                      required
                      select
                      fullWidth={true}
                      value={values.dimension}
                      label={"Type"}
                      onChange={event => setValue("dimension", event.target.value)}
                      error={Boolean(errors.dimension)}
                      helperText={errors.dimension}
                    >
                      {manualSensorTypes.map(type => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </TextField>

                    {isNotPredation && (
                      <div className={classes.group}>
                        <TextField
                          style={{ marginRight: 10 }}
                          size="small"
                          required
                          fullWidth={true}
                          value={values.slope}
                          label={"Slope"}
                          onChange={event => setValue("slope", event.target.value)}
                          error={Boolean(errors.slope)}
                          helperText={errors.slope}
                        />

                        <TextField
                          size="small"
                          fullWidth={true}
                          required
                          value={values.intercept}
                          label="Intercept"
                          onChange={event => setValue("intercept", event.target.value)}
                          error={Boolean(errors.intercept)}
                          helperText={errors.intercept}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {transmissionType === "ACOUSTIC_HTI" && (
          // htiPeriodMs, htiSubcode, htiPulseWidth, htiModulation
          <FlexCol fullWidth itemSpacing={2}>
            <FlexRow vAlign="center" spaceBetween itemSpacing={2}>
              <TextField
                size="small"
                required
                value={values.htiPeriodMs}
                label="Period (ms)"
                onChange={event => setValue("htiPeriodMs", event.target.value)}
                error={Boolean(errors.htiPeriodMs)}
                helperText={errors.htiPeriodMs}
              />
              <TextField
                size="small"
                required
                value={values.htiSubcode}
                label="Subcode"
                onChange={event => setValue("htiSubcode", event.target.value)}
                error={Boolean(errors.htiSubcode)}
                helperText={errors.htiSubcode}
              />
            </FlexRow>

            <TextField
              size="small"
              required
              fullWidth={true}
              select
              value={values.htiPulseWidth || ""}
              label="Pulse Width"
              onChange={event => setValue("htiPulseWidth", event.target.value)}
              error={Boolean(errors.htiPulseWidth)}
              helperText={errors.htiPulseWidth}
            >
              {htiPulseWidths.map(htiPulseWidth => (
                <MenuItem key={htiPulseWidth} value={htiPulseWidth}>
                  {htiPulseWidth}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              size="small"
              required
              fullWidth={true}
              select
              value={values.htiModulation || ""}
              label="Modulation"
              onChange={event => setValue("htiModulation", event.target.value)}
              error={Boolean(errors.htiModulation)}
              helperText={errors.htiModulation}
            >
              {Object.keys(htiModulations).map(option => (
                <MenuItem key={option} value={htiModulations[option]}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </FlexCol>
        )}
      </div>
      <IconButton edge="end" color="primary" title="Remove Transmitter ID" onClick={handleRemove}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </div>
  );
}

function idFilter(x) {
  return x.replace(/[^0-9]/g, "");
}

const cachedCodespaces = memoize(codespaces => {
  return [...new Set(codespaces.map(cs => cs.displayString))];
});

TransmitterIdInput.propTypes = {
  codespaces: PropTypes.array.isRequired,
  deviceClass: PropTypes.string,
  values: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default TransmitterIdInput;
