import { createRef, Component } from "react";
import PropTypes from "prop-types";

import * as d3 from "d3";
import { withStyles } from "@material-ui/core/styles";
import { TimeAxis } from "../../fathom-brella";

const styles = {
  root: {
    width: "100%",
    display: "block",
  },
};

class XAxis extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  componentDidMount() {
    const { height, orientation, marginLeft } = this.props;
    const svg = d3.select(this.ref.current);
    const bottom = orientation === "bottom";

    this.xAxisGroup = svg.append("g");

    if (bottom) {
      this.xAxisGroup.attr("transform", `translate(${marginLeft}, 0)`);
      this.xAxis = new TimeAxis(this.xAxisGroup, this.props.xScale, "bottom", true);
    } else {
      this.xAxisGroup.attr("transform", `translate(${marginLeft}, ${height - 1})`);
      this.xAxis = new TimeAxis(this.xAxisGroup, this.props.xScale, "top", false);
    }

    this.xAxisLabel = svg
      .append("text")
      .attr("x", d3.sum(this.props.xScale.range()) / 2)
      .attr("y", bottom ? this.props.height : 0)
      .style("dominant-baseline", bottom ? "text-after-edge" : "text-before-edge")
      .attr("font-family", "sans-serif")
      .attr("font-size", "12px")
      .style("text-anchor", "middle")
      .text(this.props.label);
  }

  componentDidUpdate() {
    this.xAxis.updateScale(this.props.xScale);
    this.xAxisLabel.attr("x", this.props.xScale.range()[1] / 2).text(this.props.label);
  }

  render() {
    return (
      <svg
        ref={this.ref}
        className={this.props.classes.root}
        style={{ height: this.props.height }}
      />
    );
  }
}

XAxis.propTypes = {
  height: PropTypes.number.isRequired,
  xScale: PropTypes.func.isRequired,
  orientation: PropTypes.string.isRequired,
  label: PropTypes.string,
  marginLeft: PropTypes.number,
};

export default withStyles(styles)(XAxis);
