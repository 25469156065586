import { Typography } from "@material-ui/core";

/** Common style element for main headings */
export function Title({ children, ...restProps }) {
  return (
    <Typography variant="h6" {...restProps}>
      {children}
    </Typography>
  );
}
