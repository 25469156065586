import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const styles = {
  root: {
    padding: 6,
    display: "block",
    color: "lightgray",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "rgb(128, 128, 123, 0.5)",
    },
  },
  dark: {
    color: "#444444",
  },
};

function CompactIconButton(props) {
  const className = classNames(props.classes.root, { [props.classes.dark]: props.lightBackground });

  return (
    <IconButton className={className} onClick={props.onClick}>
      {props.children}
    </IconButton>
  );
}

export default withStyles(styles)(CompactIconButton);
