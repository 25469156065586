import { CSSProperties } from "@material-ui/styles";

type Props = {
  children: string | number | null;
  width?: string | number;
  className?: string;
  style?: CSSProperties;
};

/** Wraps text in div that fits width and content is truncated with `...` if overflow.
 * NOTE: Parent (or ancestor) must have defined width.
 */
export default function TextOverflowEllipsis({ children = null, width, className, style }: Props) {
  const contentStr = children === null ? "" : children.toString();
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width,
        ...style,
      }}
      className={className}
      title={contentStr}
    >
      {contentStr}
    </div>
  );
}
