import React, { useState } from "react";
import DialogWrapper from "../common/DialogWrapper";
import { useSelectorTyped as useSelector, useThunkDispatch } from "../../redux/common";
import { callGqlApi } from "../../helpers/api";
import { refreshUserData, logout, closePP } from "../../redux/user/user-actions";
import { handleGqlErrors } from "../../redux/gql-error/gql-error-actions";
import { Button, CircularProgress } from "@material-ui/core";

function PrivacyPolicy() {
  const mode = useSelector(state => state.user.privacyPolicyMode);
  const open = mode === "accept" || mode === "view";
  const [loading, setLoading] = useState(false);
  const dispatch = useThunkDispatch();

  function buttons(mode, cancelAction, okAction) {
    if (mode === "accept") {
      return (
        <>
          {loading && (
            <div style={{ padding: 8 }}>
              <CircularProgress size={25} />
            </div>
          )}
          <Button variant="outlined" onClick={cancelAction} disabled={loading}>
            Decline
          </Button>
          <Button variant="contained" color="primary" onClick={okAction} disabled={loading}>
            Accept
          </Button>
        </>
      );
    } else {
      return (
        <Button variant="outlined" onClick={() => dispatch(closePP())}>
          Close
        </Button>
      );
    }
  }

  function accept() {
    setLoading(true);
    callGqlApi(`
      mutation {
        acceptPrivacyPolicy
      }
    `)
      .then(() => {
        dispatch(refreshUserData()).then(() => {
          dispatch(closePP());
          setLoading(false);
        });
      })
      .catch(e => dispatch(handleGqlErrors(e)));
  }

  return (
    <DialogWrapper
      open={open}
      title={mode === "view" ? "Privacy policy" : "Please review and accept our privacy policy"}
      cancelAction={() => dispatch(logout())}
      okAction={accept}
      okButtonContent="Accept"
      maxWidth="lg"
      buttons={({ cancelAction, okAction }) => buttons(mode, cancelAction, okAction)}
    >
      <p>Last updated: August 13, 2021</p>
      <p>
        Innovasea wants you to use our mobile and web-based applications (collectively, the “Apps”)
        secure in the knowledge that we have implemented fair and transparent information policies
        and practices designed to protect your privacy.
      </p>
      <p>
        This Privacy Notice describes our policies and practices regarding our collection and use of
        personal information about users of our Apps and sets forth your privacy rights. This
        Privacy Notice applies only to information we collect through our Apps that link to this
        Privacy Policy, and does not apply to information we collect through mobile or web-based
        applications that do not link to this Privacy Policy, or through other means, including
        offline or via our website, <a href="http://www.innovsea.com">www.innovasea.com</a>, or any
        other website or application operated by a third party.
      </p>
      <p>
        We may update this Privacy Notice from time to time, and any such revisions will be
        reflected by the effective date above. Please revisit this page to stay aware of any
        changes. Any information that we collect from you will be used in accordance with the
        version of this Privacy Notice in effect at the time the information was collected, unless
        we receive your consent to change our practices with respect to previously collected
        information.
      </p>
      <h3>Who We Are</h3>
      <p>
        Innovasea Systems, Inc. (“Innovasea”) is a Delaware corporation with offices in Boston,
        Massachusetts, in the United States. Innovasea’s full contact information is provided at the
        end of this Privacy Notice.
      </p>
      <p>
        Innovasea has appointed an internal privacy officer for you to contact if you have any
        questions or concerns about Innovasea’s information privacy policies or practices. Please
        direct such inquiries to <a href="mailto:privacy@innovasea.com">privacy@innovasea.com</a>.
      </p>
      <h3>Information We Collect</h3>
      <p>When you use our Apps, we may collect the following types of information:</p>
      <ul>
        <li>
          <strong>Account Data.</strong> We collect and store the information you provide when you
          log into our Apps using your Innovasea account login credentials, which may include, for
          example, your name, email address, or other username, and in some cases, a password.
        </li>
        <li>
          <strong>User Preferences.</strong> We collect and store your preferences concerning the
          Apps’ settings, including, for example, your preferences regarding notifications and how
          Hardware Data (described below) is presented within the Apps. In some of Innovasea’s
          web-based Apps, your User Preferences may be collected by and stored in browser cookies.
        </li>
        <li>
          <strong>Content.</strong> Where we provide the functionality within our Apps, you may
          choose to submit additional information, including, for example, text, photographs, or
          audio or video recordings (collectively, “Content”). Submissions of photographs or video
          or audio recordings may require you to grant permission for our Apps to access your
          device’s camera, microphone or photo library.
        </li>
        <li>
          <strong>Usage Data.</strong> When you use our Apps, we may automatically receive certain
          information about your use of the Apps and your mobile device or browser, including, for
          example, your IP address, browser or device type, operating system, unique device
          identifiers, precise location data, the time, date, and duration of your use of the Apps,
          information about your interactions with the Apps, and diagnostic or performance
          information. Usage Data may also be collected by cookies and similar data-collection
          technologies, including those placed by our third-party service providers (e.g., Google
          Analytics).
        </li>
        <li>
          <strong>Hardware Data.</strong> Our Apps are intended for use in connection with the
          Innovasea hardware and technologies you have deployed in the field (“Hardware”). Data
          collected and generated by such Hardware is communicated to and displayed within the Apps.
          The precise categories of Hardware Data displayed in the Apps varies depending on the
          particular type of Hardware, and may include such data as Hardware-specific diagnostic and
          performance data, precise location data, environmental data, and wildlife data.
        </li>
      </ul>
      <p>
        Please note that we may supplement the above information we collect through the Apps with
        information we receive from third parties and information we collect through other means,
        such as offline or through other websites.
      </p>
      <h3>How We Use Your Information</h3>
      <p>We use the above-mentioned information for the following purposes:</p>
      <ul>
        <li>
          <strong>To Provide the Services.</strong> We use your Innovasea Account Data to sync the
          Apps you use to the Innovasea Hardware you deploy in the field, which allows us to provide
          our services to you. We also use Usage and Hardware Data, Content, and your chosen User
          Preferences in order to provide our services to you.
        </li>
        <li>
          <strong>To Maintain, Improve, and Develop Our Services, Apps, and Hardware.</strong> We
          use the information we collect to improve our existing services, Apps, and Hardware, as
          well as to develop new ones. For example, we may use Usage Data to troubleshoot and
          protect against errors; to assist you if you contact us for technical support; to perform
          data analysis and testing; and to develop new features for our Apps.
        </li>
        <li>
          <strong>To Communicate with You.</strong> We may use your email address to communicate
          with you, including to provide technical information and support concerning our Apps,
          Hardware, and related services, or to otherwise respond to you when you contact us.
        </li>
        <li>
          <strong>Reports and Benchmarking.</strong> We may use data that is aggregated and
          anonymized so that it cannot reasonably be used to identify an individual in order to
          generate and/or publish reports about our products and services, or about the industry
          more generally, or as part of the benchmarking information we provide to customers and
          potential clients.
        </li>
        <li>
          <strong>To Promote Safety and Security.</strong> We use the data we collect and receive to
          promote the safety and security of the services, our Apps and Hardware, our users, and
          other parties. For example, we may use the above data to authenticate users, protect
          against fraud and abuse, respond to a legal request or claim, conduct audits, and enforce
          our terms and policies.
        </li>
      </ul>
      <h3>When and How We Share Information with Others</h3>
      <p>
        Except as described in this Privacy Notice, Innovasea will not share your personal
        information with third parties.
      </p>
      <ul>
        <li>
          <strong>Service Providers.</strong> We use service providers (which may include Innovasea
          affiliates) that perform functions on our behalf, including software development and
          operations, user authentication services, and cloud infrastructure and hosting services.
          These entities may have access to your information if necessary or useful to performing
          their functions. We also use service providers to perform data management and analytics
          services on our behalf, including, for example, Google Analytics. The collection and use
          of your information by Google Analytics is governed by Google’s privacy policy, which can
          be found
          <a
            href="https://policies.google.com/technologies/partner-sites"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </li>
        <li>
          <strong>Affiliates.</strong> We may share your information with Innovasea affiliates for
          the purposes set forth above in the section titled “How We Use Your Information”.
        </li>
        <li>
          <strong>Aggregated and De-Identified Data.</strong> We may use non-personal information
          that is aggregated or de-identified so that it cannot reasonably be used to identify an
          individual. We may disclose such information publicly and to third parties, for example,
          in public reports about the industry, or as part of the benchmarking information we
          provide to customers and potential clients.
        </li>
        <li>
          <strong>Change of Control.</strong> We may share your information if we are involved in a
          merger, acquisition, or sale of assets.
        </li>
        <li>
          <strong>Law Enforcement / Protection.</strong> Innovasea reserves the right to share
          information about you and your use of Innovasea Apps and Hardware where we believes that
          such disclosure is necessary to comply with a legal requirement, such as a subpoena or
          court order, or to protect the rights or property of Innovasea, you, or third parties.
        </li>
        <li>
          <strong>Consent.</strong> Innovasea may share your information in any other manner to
          which you consent.
        </li>
      </ul>
      <h3>
        <strong>Protection of Your Information</strong>
      </h3>
      <p>
        Innovasea makes reasonable efforts to guard your personal information against accidental
        loss and from unauthorized access, use, alteration, and disclosure. We update and test our
        security technology on an ongoing basis. We restrict access to your personal information to
        those Innovasea staff who need to know that information to provide services to you. In
        addition, we train our Innovasea staff about the importance of confidentiality and
        maintaining the privacy and security of your information.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet is not completely secure.
        Although we take measures to protect your personal information, we cannot guarantee its
        security. Any transmission of personal information to or using our Apps is at your own risk.
        We are not responsible for circumvention of any privacy settings or security measures
        contained within the Apps.
      </p>
      <h3>Retention of Information</h3>
      <p>
        We will retain the information we collect for the length of time needed to fulfill our
        business purposes outlined in the section titled “How We Use Information” above, unless a
        longer retention period is required or permitted by law.
      </p>
      <h3>Your Rights and Choices</h3>
      <ul>
        <li>
          <strong>Rights and Requests:</strong> You can contact us at any time using the contact
          information listed in the last section of this Privacy Notice to request we do any of the
          following: (i) erase or delete all or some of your information; (ii) change, update, or
          correct your information; (iii) restrict how we use all or some of your information; (iv)
          access your information and, where applicable and feasible, request to receive that
          information in a commonly used electronic format (or ask for this information to be
          provided in that format to a third party); or (v) disclose what information we collect,
          use, disclose, or sell. We will consider and respond to any such requests promptly, and
          otherwise in accordance with applicable laws. Although we use good faith efforts to
          respond to your request, we may not be able to provide and/or correct the requested
          information in all situations. For example, we may not be able to provide the information
          requested if it imposes an undue burden or expense, requires us to release confidential
          commercial information, requires the disclosure of information relating to another person,
          or requires authentication of the request that is not feasible.
        </li>
        <li>
          <strong>Note for California Residents:</strong> if you are a resident of California,
          please read the supplemental notice to this Privacy Notice by clicking
          <a href="/california-consumer-privacy-act-notice/">here</a>. The supplemental notice
          provides certain information to you as required by the California Consumer Privacy Act.
        </li>
        <li>
          <strong>Note for European Economic Area and UK Residents:</strong> although most of our
          operations are based in North America, insofar as we may from time to time process
          personal data of individuals in the European Union, Iceland, Liechtenstein, Norway, or the
          United Kingdom, which processing may be subject to the European Union’s General Data
          Protection Regulation (“GDPR”) or the United Kingdom General Data Protection Regulation
          (“UK-GDPR”), please note the following:
          <ul>
            <li>
              We will only process your personal data to the extent applicable law allows us to do
              so.
            </li>
            <li>
              The United States has not sought nor received a finding of “adequacy” from the
              European Union under article 45 of the GDPR or from the UK under article 45 of the
              UK-GDPR. To the extent we process your data in the U.S., we rely on derogations for
              specific situations as set forth in article 49 of each the GDPR and the UK-GDPR. In
              particular, we collect and transfer to the U.S. personal information only: with your
              consent; to perform a contract with you; or to fulfill a compelling legitimate
              interest in a manner that does not outweigh your rights and freedoms.
            </li>
            <li>
              If you have any complaints regarding our privacy practices or how we handle any
              requests you make in accordance with the “rights and requests” section above (which
              requests you may have a legal right to make and we may have a legal obligation to
              honor, as dictated by applicable law), you may also lodge a complaint with your
              national data protection authority.
            </li>
          </ul>
        </li>
      </ul>
      <h3>Children</h3>
      <p>
        Innovasea does not knowingly collect personal information from children under the age of 13
        (or 16 in Europe). If we learn that we have collected personal information from children
        under the age of 13 (or older, if applicable law provides for different protections), then
        unless we obtain parental consent to use or maintain that information, we will delete it.
      </p>
      <h3>Contact Information</h3>
      <p>
        For further information or comments on our Privacy Notice, please contact our privacy
        officer at <a href="mailto:privacy@innovasea.com">privacy@innovasea.com</a>, or contact
        Innovasea at the following address:
      </p>
      <p>
        Innovasea
        <br />
        266 Summer Street
        <br />
        Boston, Massachusetts 02210
        <br />
        Phone: 617-862-0568
      </p>
    </DialogWrapper>
  );
}

export default PrivacyPolicy;
