import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, TextField } from "@material-ui/core";
import { FilterList as IconFilterList } from "@material-ui/icons";

const styles = {
  root: {
    display: "flex",
    margin: "1px",
    marginLeft: "5px",
  },
  filterIcon: {
    transform: "scale(0.7)",
    opacity: 0.4,
    transitionDuration: "250ms",
    "&:hover": {
      transform: "scale(1)",
      opacity: 1,
    },
  },
  filterIconOn: {
    opacity: 1,
    transform: "scale(1)",
    borderRadius: "50%",
    boxShadow: "0px 0px 8px -2px yellow inset",
  },
  filterInputContainer: {
    padding: "0px 5px",
    margin: 0,
  },
};

function FilterIndicator({ dataLabel, value, onChange, FilterInputComponent }) {
  const classes = makeStyles(styles)();
  const [anchorEl, setAnchorEl] = useState(null);
  const refInput = useRef();

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const filterOn = anchorEl || value;

  return (
    <div
      className={classes.root}
      onClick={handleClick}
      title={
        filterOn
          ? `The data is being filtered by ${dataLabel} containing '${value}'`
          : `Click and type to filter by ${dataLabel}`
      }
    >
      <IconFilterList
        fontSize="small"
        className={classNames(classes.filterIcon, { [classes.filterIconOn]: filterOn })}
      />
      <Menu
        variant="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={e => e.stopPropagation()}
        onClose={handleClose}
        onEntered={() => {
          refInput.current && refInput.current.focus();
        }}
        classes={{ list: classes.filterInputContainer }}
      >
        {FilterInputComponent ? (
          <FilterInputComponent ref={refInput} value={value || ""} onChange={onChange} />
        ) : (
          <TextField
            // autoFocus doesn't seem to work, so use inputRef
            inputRef={refInput}
            label={`${dataLabel} must contain ...`}
            type="search"
            margin="dense"
            variant="outlined"
            onChange={e => onChange(e.target.value)}
            value={value || ""}
          />
        )}
      </Menu>
    </div>
  );
}

FilterIndicator.propTypes = {
  dataLabel: PropTypes.any,
  filterValue: PropTypes.any,
  filterInputChange: PropTypes.any,
  FilterInputComponent: PropTypes.any,
};

export default FilterIndicator;
