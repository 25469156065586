import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    padding: 20,
    textAlign: "center",
  },
};

function PanelPlaceHolder({ children, classes }) {
  return <div className={classes.root}>{children}</div>;
}

export default withStyles(styles)(PanelPlaceHolder);
