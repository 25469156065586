import { Component } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import customTheme from "../../styles/customTheme";
import PropTypes from "prop-types";

/**
 * Wrapper for Mui Button. Mui Button only allows it to be either
 * the primary or secondary colour. This allows us to create a coloured button
 * of an color. It's restricted to the named colours in our palette of the
 * site theme (customTheme)
 */
export default class CustomButton extends Component {
  render() {
    const { color, ...otherProps } = this.props;
    const btnTheme = createTheme({ palette: { primary: customTheme.palette[color] } });
    return (
      <MuiThemeProvider theme={btnTheme}>
        <Button {...otherProps} color="primary" />
      </MuiThemeProvider>
    );
  }
}

CustomButton.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "error", "success", "warning"]), // supported palette in customTheme
};
