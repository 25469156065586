import { forwardRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { deploymentColorMap } from "./utils";

export const styles = {
  legendContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: 10,
  },
  root: {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "middle",
    WebkitTapHighlightColor: "transparent",
    marginLeft: 0,
    marginRight: 16, // used for row presentation of radio/checkbox
  },
};

function TimelineLegend({ classes }) {
  return (
    <div className={classes.legendContainer}>
      {Object.entries(deploymentColorMap).map(([type, color]) => (
        <LegendLabel key={type} classes={classes} label={type} legendColor={color} />
      ))}
    </div>
  );
}

const LegendLabel = forwardRef(({ classes, legendColor, label }, ref) => (
  <label className={classes.root} ref={ref}>
    {legendColor && (
      <div style={{ width: "100%" }}>
        <ColorSample color={legendColor} />
      </div>
    )}
    <Typography component="span" className={classes.label}>
      {label}
    </Typography>
  </label>
));

// SVG element of same size & shape as a MUI checkbox, just in a solid color
function ColorSample(props) {
  return (
    <svg
      style={{ height: "1em", width: "1em", fontSize: "24px", float: "right" }}
      viewBox="0 0 24 24"
    >
      <path
        d="M5,3 c-1.1 0-2 .9-2 2 v14 c0 1.1.9 2 2 2 h14 c1.1 0 2-.9 2-2 V5 c0-1.1-.9-2-2-2 z"
        style={{ fill: props.color, opacity: 1 }}
      />
    </svg>
  );
}

export default withStyles(styles)(TimelineLegend);
