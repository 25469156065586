import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import autoBind from "auto-bind/react";

import { TextField, Typography } from "@material-ui/core";

import { validateEmail } from "../../../helpers/common";
import UserAccountActions from "./UserAccountActions";

import { isEqual } from "lodash";
import { SoftTitle } from "../../common/typography";
import { Check, Close } from "@material-ui/icons";
import DialogWrapper from "../../common/DialogWrapper";
const styles = theme => ({
  dialogContent: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  workspacesContainer: {
    marginTop: theme.spacing(3),
  },
});

class UserDialog extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.initialValues = {
      email: "",
      sysproContactId: "",
      givenName: "",
      familyName: "",
      nickname: "",
    };

    this.state = {
      values: {
        ...this.initialValues,
      },
      errors: {},
    };
  }

  validate() {
    const requiredFields = ["email"];
    const { values } = this.state;
    const errors = {};

    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = "This field is required";
      }
    });

    if (!validateEmail(values.email)) {
      errors.email = "Invalid email format";
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  }

  handleSubmit() {
    const isValid = this.validate();
    const { createUser, updateUser, userToEdit, mode } = this.props;
    const { values } = this.state;

    if (isValid && mode === "add") {
      createUser(values);
    } else if (isValid && mode === "edit") {
      const input = { id: userToEdit.id };
      userToEdit.email !== values.email && (input.email = values.email);
      userToEdit.givenName !== values.givenName && (input.givenName = values.givenName);
      userToEdit.familyName !== values.familyName && (input.familyName = values.familyName);
      userToEdit.nickname !== values.nickname && (input.nickname = values.nickname);
      userToEdit.sysproContactId !== values.sysproContactId &&
        (input.sysproContactId = values.sysproContactId);
      updateUser(input);
    }
    this.setState({
      values: this.initialValues,
      errors: {},
    });
  }

  handleInputChange(valueName, event) {
    this.setState({
      values: {
        ...this.state.values,
        [valueName]: event.target.value,
      },
    });
  }

  reset() {
    if (this.props.mode === "add") {
      this.setState({
        values: this.initialValues,
        errors: {},
      });
    } else if (this.props.mode === "edit") {
      this.setState({
        values: this.props.userToEdit,
        errors: {},
      });
    }
  }

  render() {
    const { values, errors } = this.state;
    const { mode, handleClose, userToEdit, classes } = this.props;
    const editSubset = ({ givenName, familyName, sysproContactId, nickname, email }) => ({
      givenName,
      familyName,
      sysproContactId,
      nickname,
      email,
    });
    const editSubmitDisabled =
      mode === "edit" && isEqual(editSubset(values), editSubset(userToEdit));
    return (
      <DialogWrapper
        open={mode === "add" || mode === "edit"}
        title={mode === "add" ? "Add User" : "Edit User"}
        onEnter={this.reset}
        okAction={this.handleSubmit}
        okDisabled={editSubmitDisabled}
        okButtonContent={mode === "add" ? "Add" : "Save"}
        cancelAction={handleClose}
        maxWidth="lg"
        onBackdropClick={handleClose}
        fullWidth={false}
      >
        <div className={classes.dialogContent}>
          <Typography variant="h6">Details</Typography>
          <TextField
            size="small"
            className={classes.input}
            required
            fullWidth={true}
            label="Email"
            id="email"
            value={values.email}
            onChange={event => this.handleInputChange("email", event)}
            error={errors.email ? true : false}
            helperText={
              errors.email
                ? errors.email
                : mode === "edit"
                ? "Warning: Changing email will change their login email for Fathom Mobile & Fathom Live as well"
                : ""
            }
          />
          <TextField
            size="small"
            className={classes.input}
            fullWidth={true}
            label="Syspro Contact Id"
            id="sysproContactId"
            value={values.sysproContactId || ""}
            onChange={event => this.handleInputChange("sysproContactId", event)}
            helperText={
              mode === "add" &&
              "If a syspro contact id is not provided we will attempt to lookup one by the email"
            }
          />
          <div style={{ display: "flex" }}>
            <TextField
              size="small"
              className={classes.input}
              label="Given Name"
              id="givenName"
              value={values.givenName}
              onChange={event => this.handleInputChange("givenName", event)}
            />
            <TextField
              size="small"
              className={classes.input}
              label="Family Name"
              id="familyName"
              value={values.familyName}
              onChange={event => this.handleInputChange("familyName", event)}
            />
            <TextField
              size="small"
              className={classes.input}
              label="Nickname"
              id="nickname"
              value={values.nickname}
              onChange={event => this.handleInputChange("nickname", event)}
            />
          </div>
          {userToEdit && (
            <>
              <div style={{ display: "flex", flexDirection: "row", marginTop: 24 }}>
                <div style={{ marginRight: 16 }}>
                  <SoftTitle> Status </SoftTitle>
                  <Typography> {userToEdit.status} </Typography>
                </div>
                <div>
                  <SoftTitle> Enabled </SoftTitle>
                  <Typography> {userToEdit.enabled ? <Check /> : <Close />}</Typography>
                </div>
              </div>
              <UserAccountActions user={userToEdit} />
            </>
          )}

          {mode === "add" && (
            <div className={classes.workspacesContainer}>
              <Typography>A personal workspace will be created automatically.</Typography>
            </div>
          )}
        </div>
      </DialogWrapper>
    );
  }
}

UserDialog.propTypes = {
  mode: PropTypes.oneOf(["add", "edit", ""]),
  handleClose: PropTypes.func,
  createUser: PropTypes.func,
  updateUser: PropTypes.func,
  userToEdit: PropTypes.shape({
    id: PropTypes.string,
    cognitoUuid: PropTypes.string,
    email: PropTypes.string,
    sysproContactId: PropTypes.string,
  }),
};

export default withStyles(styles)(UserDialog);
