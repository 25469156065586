import React, { useState } from "react";
import DialogWrapper from "../common/DialogWrapper";
import { useSelectorTyped as useSelector, useThunkDispatch } from "../../redux/common";
import { closeWhatsNew } from "../../redux/user/user-actions";
import { Button, CircularProgress } from "@material-ui/core";
import { callGqlApi } from "../../helpers/api";
import { handleGqlErrors } from "../../redux/gql-error/gql-error-actions";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FlexRow from "../common/FlexRow";
import gql from "../../redux/gqlTag";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  centerContainer: {
    paddingLeft: 88, // Width of dismiss btn
    display: "flex",
    justifyContent: "center",
    flex: 1,
  },
}));

function WhatsNew() {
  const whatsNewState = useSelector(state => state.user.whatsNew);
  const dispatch = useThunkDispatch();
  const userFeatures = useSelector(state => state.user.user.newFeatures);
  const allNewFeatures = useSelector(state => state.user.user.allNewFeatures);
  const isAuthenticating = useSelector(state => state.user.isAuthenticating);
  const newFeatures = whatsNewState === "view" ? userFeatures : allNewFeatures;
  const loading = isAuthenticating || !newFeatures;
  const open = whatsNewState === "view" || whatsNewState === "review";

  const classes = useStyles();
  const [index, setIndex] = useState(0);

  function handleNext(featureId) {
    setIndex(prevIndex => prevIndex + 1);
    if (userFeatures.map(f => f.id).includes(featureId)) {
      return callGqlApi(
        gql`
          mutation viewFeature($input: ViewedNewFeatureInput!) {
            viewedNewFeature(input: $input)
          }
        `,
        { input: { featureId } }
      ).catch(e => dispatch(handleGqlErrors(e)));
    }
  }

  function handlePrev() {
    setIndex(prevIndex => prevIndex - 1);
  }

  function handleDismiss() {
    setIndex(0);
    dispatch(closeWhatsNew());
  }

  function buttons(classes, loading, newFeatures) {
    return (
      <>
        {loading ? (
          <div style={{ padding: 8 }}>
            <CircularProgress size={25} />
          </div>
        ) : (
          <FlexRow fullWidth spaceBetween>
            <div className={classes.centerContainer}>
              {newFeatures.length > 1 && (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handlePrev}
                    disabled={index === 0}
                  >
                    Prev
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNext(newFeatures[index].id)}
                    disabled={index + 1 === newFeatures.length}
                    style={{ marginLeft: 4 }}
                  >
                    Next
                  </Button>
                  <Typography style={{ marginTop: 6, marginLeft: 8 }}>
                    ( {index + 1} / {newFeatures.length} )
                  </Typography>
                </>
              )}
            </div>
            <Button variant="contained" color="primary" onClick={handleDismiss} disabled={loading}>
              Dismiss
            </Button>
          </FlexRow>
        )}
      </>
    );
  }

  return (
    <DialogWrapper
      open={open}
      title={"What's New?"}
      cancelAction={handleDismiss}
      buttons={() => buttons(classes, loading, newFeatures)}
      maxWidth="sm"
    >
      {!loading && newFeatures.length > 0 && (
        <>
          <Typography variant="h6" style={{ marginBottom: 10, fontWeight: "bold" }}>
            {newFeatures[index].title}
          </Typography>
          {/* Not actually dangerous - only our curated html from the db */}
          <div dangerouslySetInnerHTML={{ __html: newFeatures[index].htmlBody }} />
        </>
      )}
    </DialogWrapper>
  );
}

export default WhatsNew;
