import {
  USER_SET_CURRENT,
  USER_REFRESH_DATA,
  USER_CHANGE_OFFSET,
  USER_CLOSE_PRIVACY_POLICY,
  USER_VIEW_PRIVACY_POLICY,
  USER_ACCEPT_PRIVACY_POLICY,
  USER_CLOSE_WHATS_NEW,
  USER_ACCEPT_WHATS_NEW,
  USER_VIEW_WHATS_NEW,
  USER_REVIEW_WHATS_NEW,
} from "../action-types";
const initialState = {
  isAuthenticating: false,
  isAuthenticated: false,
  isAdmin: false,
  needPWChange: false,
  user: {},
  selectedOffset: "UTC",
  whatsNew: "closed",
  privacyPolicyMode: "closed", // "accept", "view", "closed"
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case USER_SET_CURRENT: {
      // Check if user obj === {}
      const { user, isAdmin } = action.payload;

      const isAuth = Object.keys(user).length === 0 && user.constructor === Object ? false : true;
      state = {
        ...state,
        isAuthenticating: false,
        isAuthenticated: isAuth,
        user,
        isAdmin,
      };
      return state;
    }

    case USER_REFRESH_DATA: {
      // Just refreshing the user data from DB, leaving everything else as it was
      const { user } = action.payload;
      return {
        ...state,
        user: { ...state.user, ...user },
      };
    }
    case USER_CHANGE_OFFSET: {
      return { ...state, selectedOffset: action.payload.offset };
    }
    case USER_CLOSE_PRIVACY_POLICY: {
      return { ...state, privacyPolicyMode: "closed" };
    }

    case USER_ACCEPT_PRIVACY_POLICY: {
      return { ...state, privacyPolicyMode: "accept" };
    }

    case USER_VIEW_PRIVACY_POLICY: {
      return { ...state, privacyPolicyMode: "view" };
    }

    case USER_ACCEPT_WHATS_NEW: {
      return { ...state, whatsNew: "accept" };
    }

    case USER_CLOSE_WHATS_NEW: {
      return { ...state, whatsNew: "closed", user: { ...state.user, newFeatures: [] } };
    }

    case USER_VIEW_WHATS_NEW: {
      return { ...state, whatsNew: "view" };
    }

    case USER_REVIEW_WHATS_NEW: {
      return { ...state, whatsNew: "review" };
    }

    default:
      return state;
  }
};

export default userReducer;
