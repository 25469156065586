import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { WindowedTable } from "../../../fathom-brella";
import { userDisplayName } from "../../../helpers/common";
import { Check as IconCheck, Close as IconClose } from "@material-ui/icons";

const styles = {
  root: {
    minHeight: 200,
    maxHeight: "100%",
    height: "100%",
    width: "100%",
  },
};

class UsersTable extends Component {
  render() {
    const { users, startEdit, searchText, classes } = this.props;
    if (!users) {
      return null;
    }
    const tableUsers = users.map(u => ({ ...u, name: userDisplayName(u) }));
    return (
      <div className={classes.root}>
        <WindowedTable
          rows={tableUsers}
          rowIdKey={"id"}
          searchText={searchText}
          columns={[
            {
              width: 100,
              label: "Email",
              dataKey: "email",
              searchTextFn: email => email,
              renderFn: (email, rowData) => (
                <>
                  <IconButton
                    size="small"
                    onClick={() => startEdit(rowData)}
                    title="Edit this user"
                  >
                    <Edit />
                  </IconButton>
                  <span>{email}</span>
                </>
              ),
              flexGrow: 1.5,
              flexShrink: 0,
            },
            {
              width: 70,
              label: "Name",
              dataKey: "name",
            },
            {
              width: 60,
              label: "Nickname",
              dataKey: "nickname",
            },
            {
              width: 70,
              label: "Enabled",
              dataKey: "enabled",
              flexShrink: 1,
              flexGrow: 0,
              renderFn: enabled => (enabled ? <IconCheck /> : <IconClose />),
              sortFn: enabled => (enabled ? 1 : -1),
            },
            {
              width: 100,
              label: "Account Status",
              dataKey: "status",
            },
            {
              width: 100,
              label: "Syspro Contact Id",
              dataKey: "sysproContactId",
            },
            {
              width: 100,
              label: "Cognito Uuid",
              dataKey: "cognitoUuid",
              searchTextFn: uuid => uuid,
              renderFn: uuid => {
                return (
                  <Link
                    href={`https://console.aws.amazon.com/cognito/users/?region=us-east-1#/pool/${process.env.REACT_APP_USER_POOL_ID}/users/${uuid}`}
                    target="_blank"
                    underline="always"
                  >
                    {uuid}
                  </Link>
                );
              },
            },
            {
              width: 120,
              label: "Fathom User Id",
              dataKey: "id",
              flexGrow: 0,
              flexShrink: 1,
            },
          ]}
        />
      </div>
    );
  }
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      cognitoUuid: PropTypes.string,
      email: PropTypes.string,
      syspropContactId: PropTypes.string,
    })
  ),
  startEdit: PropTypes.func.isRequired,
  searchText: PropTypes.string,
};

export default withStyles(styles)(UsersTable);
