import { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import PanelPlaceHolder from "../common/PanelPlaceHolder";
import { formatDateTime } from "../../helpers/time";
import history from "../../helpers/history";
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
  IconButton,
  Link,
} from "@material-ui/core";
import {
  LocationOn as IconLocationOn,
  BatteryFull as IconBattery,
  Save as IconSave,
  PowerOff as IconRetire,
  FlashOn as IconRevive,
  Delete as IconDelete,
} from "@material-ui/icons";

import LabeledLine from "../common/LabeledLine";
import DialogWrapper from "../common/DialogWrapper";

const styles = theme => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(1),
  },
  content: {
    marginTop: 0,
  },
  connectorLine: {
    minHeight: 6,
  },
  stepConnectorVert: {
    padding: 0,
  },
  eventHeaderRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const placeHolderText = "There are currently no events for this device.";

function compareEventDate(a, b) {
  return b.date.localeCompare(a.date);
}

function getDeployUrl(deployId) {
  const search = new URLSearchParams(history.location.search);
  search.set("initDeployId", deployId);
  return `/deployments?${search.toString()}`;
}

function getAnimalUrl(animalId) {
  const search = new URLSearchParams(history.location.search);
  search.set("initAnimalId", animalId);
  return `/animals?${search.toString()}`;
}

function DeviceEventTimeline({ events, classes, deleteEvent, deviceId }) {
  const [eventToDelete, setEventToDelete] = useState(null);

  if (events.length === 0) {
    return <PanelPlaceHolder>{placeHolderText}</PanelPlaceHolder>;
  }

  return (
    <div className={classes.root}>
      <Stepper
        className={classes.root}
        orientation="vertical"
        connector={
          <StepConnector
            classes={{
              vertical: classes.stepConnectorVert,
              line: classes.connectorLine,
            }}
          />
        }
      >
        {events.sort(compareEventDate).map(event => (
          <Step key={event.id} active={true} className={classes.content}>
            <StepLabel StepIconComponent={getEventIcon(event)}>
              <div className={classes.eventHeaderRow}>
                <Typography variant="h6">{getEventName(event)}</Typography>
                <Typography>{formatDateTime(event.date, false)}</Typography>
                {event.__typename !== "DeviceEventDeployment" &&
                  event.__typename !== "DeviceEventRetrieval" &&
                  event.__typename !== "DeviceEventTagging" &&
                  event.__typename !== "DeviceEventRecovery" && (
                    <IconButton
                      aria-label="Delete"
                      size="small"
                      onClick={() => setEventToDelete(event)}
                      color="primary"
                    >
                      <IconDelete fontSize="small" />
                    </IconButton>
                  )}
              </div>
            </StepLabel>

            <StepContent className={classes.content}>
              <div style={{ marginTop: 0, paddingTop: 0, paddingBottom: 6 }}>
                <LabeledLineConditional
                  label="Latitude"
                  value={event.latLon && event.latLon.latitude}
                />
                <LabeledLineConditional
                  label="Longitude"
                  value={event.latLon && event.latLon.longitude}
                />
                <LabeledLineConditional
                  label="Station"
                  value={
                    event.deployment && event.deployment.station && event.deployment.station.name
                  }
                />
                <LabeledLineConditional label="Animal" value={event.animal && event.animal.name} />
                {(event.__typename === "DeviceEventDeployment" ||
                  event.__typename === "DeviceEventRetrieval") && (
                  <div style={{ paddingTop: 8 }}>
                    <Link
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        history.push(getDeployUrl(event.deployment.id));
                      }}
                    >
                      View in deployments
                    </Link>
                  </div>
                )}
                {(event.__typename === "DeviceEventTagging" ||
                  event.__typename === "DeviceEventRecovery") && (
                  <div style={{ paddingTop: 8 }}>
                    <Link
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        history.push(getAnimalUrl(event.animal.id));
                      }}
                    >
                      View in Animals
                    </Link>
                  </div>
                )}
                <LabeledLineConditional label="File Name" value={event.fileId} />
              </div>

              <Typography variant="body2" style={{ fontStyle: "italic" }}>
                {event.notes}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <DialogWrapper
        open={Boolean(eventToDelete)}
        title="Delete device event"
        okAction={() => {
          deleteEvent(eventToDelete.id, deviceId);
          setEventToDelete(null);
        }}
        okButtonContent="Delete"
        cancelAction={() => setEventToDelete(null)}
      >
        {eventToDelete && (
          <>Are you sure you want to delete the {getEventName(eventToDelete)} event ?</>
        )}
      </DialogWrapper>
    </div>
  );
}

function LabeledLineConditional({ label, value }) {
  return value ? <LabeledLine label={label} value={value} /> : null;
}

function getEventName(event) {
  switch (event.__typename) {
    case "DeviceEventTagging":
      return "Tagging";
    case "DeviceEventRecovery":
      return "Recovery";
    case "DeviceEventDeployment":
      return "Deployment";
    case "DeviceEventOffload":
      return "Offload";
    case "DeviceEventRebattery":
      return "Rebattery";
    case "DeviceEventRetrieval":
      return "Retrieval";
    default:
      return "Event";
  }
}

function AnimalTaggingIcon() {
  return <img src="/img/icon-tag.png" alt="Tagging Event Icon" width={24} height={24} />;
}
function AnimalRecoveryIcon() {
  return (
    <img src="/img/icon-device-recovery.png" alt="Recovery Event Icon" width={24} height={24} />
  );
}

function getEventIcon(event) {
  switch (event.__typename) {
    case "DeviceEventTagging":
      return AnimalTaggingIcon;
    case "DeviceEventRecovery":
      return AnimalRecoveryIcon;
    case "DeviceEventDeployment":
      return IconLocationOn;
    case "DeviceEventOffload":
      return IconSave;
    case "DeviceEventRebattery":
      return IconBattery;
    case "DeviceEventRetrieval":
      return IconLocationOn;
    case "DeviceEventRetire":
      return IconRetire;
    case "DeviceEventRevive":
      return IconRevive;
    default:
      return null;
  }
}

DeviceEventTimeline.propTypes = {
  events: PropTypes.array,
  deleteEvent: PropTypes.func,
  deviceId: PropTypes.string,
};

export default withStyles(styles)(DeviceEventTimeline);
