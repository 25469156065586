import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip, Popover } from "@material-ui/core";
import { Help as IconHelp } from "@material-ui/icons";
import { amber } from "@material-ui/core/colors";
import FlexCol from "./FlexCol";

const useStyles = makeStyles(() => ({
  iconButton: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
  },
}));
export const HelperText = ({ helpContent, maxWidth }) => (
  <span
    style={{
      display: "block",
      padding: 8,
      color: "#333",
      backgroundColor: `${amber[50]}`,
      boxShadow: `0px 0px 2px inset ${amber[600]}`,
      borderRadius: 5,
      fontSize: 12,
      maxWidth,
    }}
  >
    {helpContent}
  </span>
);

function HelpPopover({ tooltip, helpContent, maxWidth, iconSize }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span className={classes.iconButton}>
      <Tooltip title={tooltip}>
        <IconButton size="small" onClick={handleClick}>
          <IconHelp style={{ fontSize: iconSize }} />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FlexCol itemSpacing={1}>
          <HelperText helpContent={helpContent} maxWidth={maxWidth} />
        </FlexCol>
      </Popover>
    </span>
  );
}

HelpPopover.propTypes = {
  /** Text to display on hover of the help icon */
  tooltip: PropTypes.string.isRequired,
  /** Text or JSX to display as the content of the popover */
  helpContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /** Limit the width of the container. Accepts number or valid CSS width string. */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Size of the help icon (fontSize style) */
  iconSize: PropTypes.number,
};

export default HelpPopover;
