import PropTypes from "prop-types";
import { connect } from "react-redux";

import DialogWrapper from "../components/common/DialogWrapper";
import { removeFromStudy } from "../redux/study/study-actions";

import { Typography } from "@material-ui/core";

function StudyUnlinkDialog({
  open,
  handleClose,
  objectType,
  objectTypeLabel,
  objectIds = [],
  selectedStudyId,
  dispatch,
}) {
  if (!open) {
    return null;
  }
  function submitRemoveFromStudy() {
    dispatch(removeFromStudy({ studyId: selectedStudyId, [objectType]: objectIds }, true));
    handleClose();
  }

  return (
    <DialogWrapper
      open={open}
      title={`Confirm Unlink From Study`}
      okAction={submitRemoveFromStudy}
      okButtonContent="Unlink"
      okDisabled={!selectedStudyId}
      cancelAction={handleClose}
      onClose={handleClose}
    >
      <Typography gutterBottom>
        Please confirm unlinking {objectIds.length} {objectTypeLabel}(s) from this study.
      </Typography>
      <Typography variant="body2">
        These {objectTypeLabel}(s) will remain in the workspace.
      </Typography>
    </DialogWrapper>
  );
}

StudyUnlinkDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  selectedStudyId: PropTypes.string.isRequired,
  objectType: PropTypes.oneOf(["deviceIds", "animalIds", "deploymentIds", "filePaths"]),
  objectTypeLabel: PropTypes.string.isRequired,
  objectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default connect()(StudyUnlinkDialog);
