import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: 16,
  },
  content: {
    "&:last-child": {
      padding: theme.spacing(0.5),
    },
  },
});

function TagCard({ device, classes }) {
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.content}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {device.model} ({device.serial})
        </Typography>
        <Typography variant="body2" component="p">
          {device.transmitters.map(tx => tx.displayId).join(", ")}
        </Typography>
      </CardContent>
    </Card>
  );
}

TagCard.propTypes = {
  device: PropTypes.shape({
    serial: PropTypes.string,
    model: PropTypes.string,
    transmitters: PropTypes.array,
  }).isRequired,
};

export default withStyles(styles)(TagCard);
