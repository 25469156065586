import { memo, useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreHoriz as IconMoreHoriz } from "@material-ui/icons";

export default memo(function MenuMore({ options = [], onSelect, title }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleClick} title={title}>
        <IconMoreHoriz />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {options.map((option, i) => (
          <MenuItem key={i} onClick={onSelect} value={i}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
});
