import { Component, createRef } from "react";
import autoBind from "auto-bind/react";
import * as d3 from "d3";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  brush: {
    "& .selection": {
      stroke: "none",
    },
    pointerEvents: "none",
  },
};

class BrushOverlay extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    autoBind(this);

    this.brush = d3.brushX().on("end", this.endHandler);
  }

  componentDidMount() {
    this.brushGroup = d3
      .select(this.ref.current)
      .append("g")
      .attr("class", this.props.classes.brush)
      .call(this.brush);
  }

  componentDidUpdate() {
    this.brush.extent([
      [0, 0],
      [this.props.width, this.props.height],
    ]);
    this.brushGroup.call(this.brush);
  }

  endHandler() {
    const sel = d3.event.selection;
    if (sel) {
      this.props.selectionHandler(sel);
      this.brushGroup.call(this.brush.move, null);
    }
  }

  render() {
    return <g ref={this.ref} />;
  }
}

export default withStyles(styles)(BrushOverlay);
