import { callGqlApi, uploadFileGqlApi } from "../../helpers/api";
import { ANIMALS_SET_LIST, ANIMALS_UPDATE_ONE, ANIMALS_SET_ERROR } from "../action-types";
import { handleGqlErrors } from "../gql-error/gql-error-actions";
import { addToStudy } from "../study/study-actions";
import { basicSnackbar, snackbarError } from "../snackbar/snackbar-actions";
import gql from "../gqlTag";
import { animalSpecFormats } from "../../components/common/SpecUploadDialogs/types";

// DATA LIST
export function readAnimalList() {
  return async dispatch => {
    const animalsQuery = gql`
      query listAnimals {
        animals {
          id
          name
          creationTime
          lastModified
          speciesCommonName
          speciesScientificName
          origin
          stock
          notes
          devices {
            id
            serial
            model
            transmitters {
              displayId
            }
          }
          events {
            id
            time
            lastModified
            __typename
            latLon {
              latitude
              longitude
            }
            locationName
            researcherName
            notes
            ... on AnimalEventTagging {
              devices {
                id
                serial
                model
                deviceClasses
                transmitters {
                  displayId
                }
              }
              taggingMethod
              anaesthetic
              sedative
              buffer
              anatomicLocation
              pitTagId
              floyTagId
            }
            ... on AnimalEventCapture {
              captureMethod
            }
            ... on AnimalEventDeviceRecovery {
              devices {
                id
                serial
                model
                deviceClasses
                transmitters {
                  displayId
                }
              }
            }
          }
          measurementSets {
            id
            totalLength {
              value
              unit
            }
            forkLength {
              value
              unit
            }
            standardLength {
              value
              unit
            }
            hoodLength {
              value
              unit
            }
            width {
              value
              unit
            }
            girth {
              value
              unit
            }
            mass {
              value
              unit
            }
            age {
              value
              unit
            }
            sex
            lifeStage

            measurerName
            time
          }

          conflicts {
            conflictingAnimalIds
            __typename
            ... on AnimalDeviceConflict {
              deviceId
            }
          }
          studyConflicts {
            studyId
            conflict {
              conflictingAnimalIds
              __typename
              ... on AnimalDeviceConflict {
                deviceId
              }
            }
          }
        }
      }
    `;

    callGqlApi(animalsQuery)
      .then(response => {
        dispatch({
          type: ANIMALS_SET_LIST,
          payload: {
            animals: response.animals,
          },
        });
      })
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function addAnimal(animalInput, studyId) {
  return async (dispatch, getState) => {
    const createAnimalQuery = gql`
      mutation createAnimal($input: CreateAnimalInput!) {
        createAnimal(input: $input) {
          animal {
            id
          }
        }
      }
    `;

    callGqlApi(createAnimalQuery, { input: animalInput })
      .then(response => {
        if (studyId) {
          // check if device id is linked to the study; if not, add it as well:
          const deviceIdsInAnimal = animalInput.taggingEvent?.deviceIds || [];
          const deviceIdsInStudy = (
            getState().study.studies.find(s => s.id === studyId).devices || []
          ).map(d => d.id);
          const deviceIds = deviceIdsInAnimal.filter(id => !deviceIdsInStudy.includes(id));
          return dispatch(
            addToStudy({
              studyId,
              animalIds: [response.createAnimal.animal.id],
              ...(deviceIds.length ? { deviceIds } : {}),
            })
          );
        }
      })
      .catch(errors => dispatch(handleGqlErrors(errors)))
      .finally(() => dispatch(readAnimalList()));
  };
}

export function deleteAnimals(animals, clearSelection) {
  const deleteInput = {
    animalIds: animals.map(animal => animal.id),
  };

  return async dispatch => {
    const deleteAnimalsQuery = gql`
      mutation deleteAnimals($input: DeleteAnimalsInput!) {
        deleteAnimals(input: $input) {
          deletedAnimalIds
        }
      }
    `;

    callGqlApi(deleteAnimalsQuery, { input: deleteInput })
      .then(() => {
        clearSelection();
        dispatch(readAnimalList());
      })
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function measureAnimal(animalId, measurementSet) {
  const animalMeasurementSetInput = {
    animalId: animalId,
    measurementSet: measurementSet,
  };
  return async dispatch => {
    const createAnimalMeasurementSet = gql`
      mutation createAnimalMeasurementSet($input: CreateAnimalMeasurementSetInput!) {
        createAnimalMeasurementSet(input: $input) {
          id
        }
      }
    `;

    callGqlApi(createAnimalMeasurementSet, { input: animalMeasurementSetInput })
      .then(response => {
        dispatch({
          type: ANIMALS_UPDATE_ONE,
          payload: {
            animalId: animalId,
            measurementSet: measurementSet,
            measurementSetId: response.createAnimalMeasurementSet.id,
          },
        });
      })
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function releaseAnimals(animalIds, releaseEvent) {
  const releaseEventInput = {
    animalIds: animalIds,
    releaseEvent: releaseEvent,
  };
  return async dispatch => {
    const createReleaseEvent = gql`
      mutation createReleaseEvent($input: CreateReleaseEventInput!) {
        createReleaseEvent(input: $input) {
          eventIds
        }
      }
    `;

    callGqlApi(createReleaseEvent, { input: releaseEventInput })
      .then(() => {
        dispatch(readAnimalList());
      })
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function captureAnimals(animalIds, captureEvent) {
  const captureEventInput = {
    animalIds: animalIds,
    captureEvent: captureEvent,
  };
  return async dispatch => {
    const createCaptureEvent = gql`
      mutation createCaptureEvent($input: CreateCaptureEventInput!) {
        createCaptureEvent(input: $input) {
          eventIds
        }
      }
    `;

    callGqlApi(createCaptureEvent, { input: captureEventInput })
      .then(() => {
        dispatch(readAnimalList());
      })
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function tagAnimal(animalId, taggingEvent) {
  const taggingEventInput = {
    animalId: animalId,
    taggingEvent: taggingEvent,
  };
  return async (dispatch, getState) => {
    const createTaggingEvent = gql`
      mutation createTaggingEvent($input: CreateTaggingEventInput!) {
        createTaggingEvent(input: $input) {
          eventIds
        }
      }
    `;

    // If animal is in any study(s) and the new device isn't, link it to the study:
    const studiesWithAnimal = getState().study.studies.filter(
      study => study.animals?.filter(animal => animal.id === animalId).length > 0
    );
    const studyLinks = []; // array of { studyId, deviceIds }
    if (studiesWithAnimal.length > 0) {
      studiesWithAnimal.forEach(study => {
        const deviceIdsInStudy = study.devices?.map(d => d.id) || [];
        const deviceIdsNotInStudy = taggingEvent.deviceIds.filter(
          id => !deviceIdsInStudy.includes(id)
        );
        if (deviceIdsNotInStudy.length > 0) {
          studyLinks.push({ studyId: study.id, deviceIds: deviceIdsNotInStudy });
        }
      });
    }

    callGqlApi(createTaggingEvent, { input: taggingEventInput })
      .then(() => {
        dispatch(readAnimalList());
        if (studyLinks.length > 0) {
          for (const studyLink of studyLinks) {
            dispatch(addToStudy(studyLink, true));
          }
        }
      })
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function updateReleaseEvent(eventId, releaseEventInput) {
  const updateReleaseInput = {
    eventId,
    releaseEvent: { ...releaseEventInput },
  };

  const updateReleaseMutation = gql`
    mutation updateRelease($input: UpdateReleaseEventInput!) {
      updateReleaseEvent(input: $input) {
        eventIds
      }
    }
  `;

  return dispatch => {
    callGqlApi(updateReleaseMutation, { input: updateReleaseInput })
      .then(() => dispatch(readAnimalList()))
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function updateCaptureEvent(eventId, captureEventInput) {
  const updateCaptureInput = {
    eventId,
    captureEvent: { ...captureEventInput },
  };

  const updateCaptureMutation = gql`
    mutation updateCapture($input: UpdateCaptureEventInput!) {
      updateCaptureEvent(input: $input) {
        eventIds
      }
    }
  `;

  return dispatch => {
    callGqlApi(updateCaptureMutation, { input: updateCaptureInput })
      .then(() => dispatch(readAnimalList()))
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function updateTaggingEvent(eventId, taggingEventInput) {
  const updateTaggingInput = {
    eventId,
    taggingEvent: { ...taggingEventInput },
  };

  const updateTaggingMutation = gql`
    mutation updateTagging($input: UpdateTaggingEventInput!) {
      updateTaggingEvent(input: $input) {
        eventIds
      }
    }
  `;

  return dispatch => {
    callGqlApi(updateTaggingMutation, { input: updateTaggingInput })
      .then(() => dispatch(readAnimalList()))
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function updateRecoveryEvent(eventId, deviceRecoveryEvent) {
  const updateRecoveryEventInput = {
    eventId,
    deviceRecoveryEvent: { ...deviceRecoveryEvent },
  };

  const updateRecoveryMutation = gql`
    mutation updateDeviceRecoveryEvent($input: UpdateDeviceRecoveryEventInput!) {
      updateDeviceRecoveryEvent(input: $input) {
        eventIds
      }
    }
  `;

  return dispatch => {
    callGqlApi(updateRecoveryMutation, { input: updateRecoveryEventInput })
      .then(() => dispatch(readAnimalList()))
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function updateAnimal(animalId, animalBasicInfo) {
  const updatedData = {
    animalId: animalId,
    ...animalBasicInfo,
  };

  const updateAnimalMutation = gql`
    mutation updateAnimalMutation($input: UpdateAnimalInput!) {
      updateAnimal(input: $input) {
        animal {
          id
          name
          speciesCommonName
          speciesScientificName
          origin
          stock
          notes
        }
      }
    }
  `;

  return dispatch => {
    callGqlApi(updateAnimalMutation, { input: updatedData })
      .then(() => dispatch(readAnimalList()))
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function recoverTags(animalId, deviceRecoveryEvent) {
  const deviceRecoveryEventInput = {
    animalId: animalId,
    deviceRecoveryEvent: deviceRecoveryEvent,
  };
  return async dispatch => {
    const createDeviceRecoveryEvent = gql`
      mutation createDeviceRecoveryEvent($input: CreateDeviceRecoveryEventInput!) {
        createDeviceRecoveryEvent(input: $input) {
          eventIds
        }
      }
    `;

    callGqlApi(createDeviceRecoveryEvent, { input: deviceRecoveryEventInput })
      .then(() => {
        dispatch(readAnimalList());
      })
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function deleteAnimalMeasurementSets(measurementSetIds) {
  const deleteInput = {
    measurementSetIds: measurementSetIds,
  };

  return async dispatch => {
    const deleteAnimalMeasurementSets = gql`
      mutation deleteAnimalMeasurementSets($input: DeleteAnimalMeasurementSetsInput!) {
        deleteAnimalMeasurementSets(input: $input) {
          deletedMeasurementSetIds
        }
      }
    `;

    callGqlApi(deleteAnimalMeasurementSets, { input: deleteInput })
      .then(() => {
        dispatch(readAnimalList());
      })
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function deleteAnimalEvents(eventIds) {
  const deleteInput = {
    eventIds: eventIds,
  };

  return async dispatch => {
    const deleteAnimalEvents = gql`
      mutation deleteAnimalEvents($input: DeleteAnimalEventsInput!) {
        deleteAnimalEvents(input: $input) {
          deletedEventIds
        }
      }
    `;

    callGqlApi(deleteAnimalEvents, { input: deleteInput })
      .then(() => {
        dispatch(readAnimalList());
      })
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function updateMeasurementSet(measurementSetId, measurementSet) {
  const updateMeasurementSetInput = {
    measurementSetId,
    measurementSet,
  };

  const updateMeasurementSetMutation = gql`
    mutation updateMeasurementSet($input: UpdateAnimalMeasurementSetInput!) {
      updateAnimalMeasurementSet(input: $input) {
        id
      }
    }
  `;

  return dispatch => {
    callGqlApi(updateMeasurementSetMutation, { input: updateMeasurementSetInput })
      .then(() => {
        dispatch(readAnimalList());
      })
      .catch(errors => {
        dispatch({
          type: ANIMALS_SET_ERROR,
          payload: {
            error: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function uploadAnimalSpec(file, format) {
  if (!animalSpecFormats.includes(format)) {
    console.error("Invalid animal spec format");
    return;
  }

  return async (dispatch, getState) => {
    const mutation = gql`
      mutation ($input: UploadAnimalDataFileInput!) {
        uploadAnimalDataFile(input: $input) {
          success
          animalIds
          missingDevices {
            serial
            txDisplayIds
            model
            deviceClasses
          }
          specErrors {
            message
            row
            cols
          }
        }
      }
    `;

    try {
      const studyId = getState().study.selectedId;
      const gqlFormat = format === "animal-standard" ? "STANDARD" : "OTN";
      const variables = { input: { format: gqlFormat } };
      const response = await uploadFileGqlApi(mutation, "input.file", file, variables);
      const result = response.uploadAnimalDataFile;
      const { success, animalIds } = result;

      if (success) {
        if (studyId) {
          dispatch(addToStudy({ studyId, animalIds }));
        }
        dispatch(
          basicSnackbar(`File Upload successful! Animals imported: ${animalIds.length}`, "success")
        );
        dispatch(readAnimalList());
      }

      return result;
    } catch (errors) {
      dispatch(snackbarError("Error uploading animal sheet"));
      dispatch(handleGqlErrors(errors));
      return { success: false, animalIds: [], missingDevices: [] };
    }
  };
}
