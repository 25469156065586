import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind/react";
import { TableSearchBar } from "../../fathom-brella";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import UsersTable from "../../components/admin/users/UsersTable";
import UserDialog from "../../components/admin/users/UserDialog";
import Spinner from "../../components/common/Spinner";
import { getUsers, createUser, updateUser } from "../../redux/admin/user/admin-user-actions";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  aboveTable: {
    borderBottom: "1px solid lightgray",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
});

class Users extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      dialogMode: "",
      userToEditId: null,
      searchText: "",
    };
  }

  componentDidMount() {
    this.props.dispatch(getUsers());
  }

  handleDialogClose() {
    this.setState({ dialogMode: "", userToEditId: null });
  }

  createUser(email, sysproContactId) {
    this.props.dispatch(createUser(email, sysproContactId));
    this.handleDialogClose();
  }

  updateUser(input) {
    this.props.dispatch(updateUser(input));
    this.handleDialogClose();
  }

  startEdit(user) {
    this.setState({ dialogMode: "edit", userToEditId: user.id });
  }

  render() {
    const { classes, users, loading } = this.props;
    const { dialogMode, searchText } = this.state;
    const userToEdit = users.find(u => u.id === this.state.userToEditId);

    return (
      <>
        {loading ? (
          <Spinner />
        ) : (
          <div className={classes.main}>
            <UserDialog
              mode={dialogMode}
              userToEdit={userToEdit}
              handleClose={this.handleDialogClose}
              createUser={this.createUser}
              updateUser={this.updateUser}
            />
            <div className={classes.aboveTable}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.setState({ dialogMode: "add" })}
              >
                Add User
              </Button>
              <div style={{ width: 500, paddingLeft: 20 }}>
                <TableSearchBar
                  searchText={searchText}
                  handleSearch={searchText => this.setState({ searchText })}
                />
              </div>
            </div>

            <UsersTable users={users} startEdit={this.startEdit} searchText={searchText} />
          </div>
        )}
      </>
    );
  }
}

Users.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      cognitoUuid: PropTypes.string,
      email: PropTypes.string,
      sysproContactId: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    user: state.user.user, // this is required for passing the userId of the admin user for workspace management
    users: state.adminUsers.users,
    error: state.adminUsers.errors,
    loading: state.adminUsers.loading && state.adminWorkspaces.loading,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Users));
