export const animalSizeUnits = {
  m: "m",
  cm: "cm",
  mm: "mm",
};

export const animalMassUnits = {
  g: "g",
  kg: "kg",
};

export const animalAgeUnits = {
  DAYS: "Days",
  WEEKS: "Weeks",
  MONTHS: "Months",
  YEARS: "Years",
};

export const animalOrigins = {
  WILD: "Wild",
  HATCHERY: "Hatchery",
  UNKNOWN: "Unknown",
};

export const animalSexes = {
  FEMALE: "Female",
  MALE: "Male",
  BOTH: "Both",
  NONE: "None",
  UNKNOWN: "Unknown",
};

export const taggingMethods = {
  SURGERY: "Surgery",
  EXTERNAL: "External",
  SUBCUTANEOUS: "Subcutaneous",
  ORAL: "Oral",
  OTHER: "Other",
};

export const animalFields = {
  name: { label: "Nickname", helperText: "Unique name for this animal" },
  notes: { label: "Notes", helperText: "Notes about this animal" },
  speciesCommonName: { label: "Species", helperText: "Common name for species" },
  speciesScientificName: { label: "Scientific Name", helperText: "Taxonomic name for species" },
  origin: { label: "Origin", helperText: "Wild or captive animal?" },
  stock: { label: "Stock", helperText: "Waterbody of origin" },
};

export const captureEventFields = {
  captureMethod: { label: "Capture Method", helperText: "How the animal was captured" },
  time: { label: "Capture Time", helperText: "When the animal was captured" },
  locationName: { label: "Location", helperText: "Where the animal was captured" },
  latitude: { label: "Latitude", helperText: "" },
  longitude: { label: "Longitude", helperText: "" },
  researcherName: { label: "Captured By", helperText: "Who captured the animal" },
  notes: { label: "Notes", helperText: "Any extra information about the capture event" },
};

export const taggingEventFields = {
  devices: { label: "Tag(s)", helperText: "The tag(s) attached to this animal" },
  time: { label: "Attachment Time", helperText: "When the tag was attached" },
  taggingMethod: { label: "Tagging Method", helperText: "How the animal was tagged" },
  anatomicLocation: {
    label: "Anatomic Location",
    helperText: "The location on the animal where the tags were attached",
  },
  pitTagId: { label: "Pit Tag", helperText: "ID of pit tag" },
  floyTagId: { label: "Floy Tag", helperText: "ID of floy tag" },
  anaesthetic: { label: "Anaesthetic", helperText: "Name of anaesthetic used" },
  sedative: { label: "Sedative", helperText: "Name of sedative used" },
  buffer: { label: "Buffer", helperText: "Name of buffer used" },
  notes: { label: "Notes", helperText: "Notes specific to tagging event" },
  researcherName: { label: "Tagged By", helperText: "Who attached the tag" },
  locationName: { label: "Location", helperText: "Where the tag was attached" },
  latitude: { label: "Latitude", helperText: "" },
  longitude: { label: "Longitude", helperText: "" },
};

export const releaseEventFields = {
  time: { label: "Release Time", helperText: "When the animal was released" },
  locationName: { label: "Location", helperText: "Where the animal was released" },
  latitude: { label: "Latitude", helperText: "" },
  longitude: { label: "Longitude", helperText: "" },
  researcherName: { label: "Released By", helperText: "Who released the animal" },
  notes: { label: "Notes", helperText: "Release notes" },
};

export const deviceRecoveryEventFields = {
  devices: { label: "Tag(s)", helperText: "The tag(s) that were recovered" },
  time: { label: "Recovery Devices Time", helperText: "When the tag(s) were recovered" },
  locationName: { label: "Location", helperText: "Where the tag(s) were recovered" },
  latitude: { label: "Latitude", helperText: "" },
  longitude: { label: "Longitude", helperText: "" },
  researcherName: { label: "Recovered By", helperText: "Who recovered the tag(s) from the animal" },
  notes: { label: "Notes", helperText: "Any extra information about the tag recovery event" },
};

export const measurementSetFields = {
  time: { label: "Measurement Time", helperText: "When the measurements were taken" },
  measurerName: { label: "Measured By", helperText: "Who measured the animal" },
  mass: { label: "Weight", helperText: "" },
  lifeStage: { label: "Life Stage", helperText: "" },
  age: { label: "Age", helperText: "" },
  sex: { label: "Sex", helperText: "" },
  totalLength: { label: "Total Length", helperText: "" },
  forkLength: { label: "Fork Length", helperText: "" },
  standardLength: { label: "Standard Length", helperText: "" },
  hoodLength: { label: "Hood Length", helperText: "" },
  width: { label: "Width", helperText: "" },
  girth: { label: "Girth", helperText: "" },
};

export const animalEventFields = {
  AnimalEventCapture: captureEventFields,
  AnimalEventTagging: taggingEventFields,
  AnimalEventRelease: releaseEventFields,
  AnimalEventDeviceRecovery: deviceRecoveryEventFields,
};
