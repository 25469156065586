import { ADMIN_WORKSPACES_SET_DATA, ADMIN_WORKSPACES_SET_ERROR } from "../../action-types";

const initialState = {
  allWorkspaces: [],
  loading: true,
  error: null,
};

export default function adminWorkspaceReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADMIN_WORKSPACES_SET_DATA:
      return {
        ...state,
        allWorkspaces: payload.allWorkspaces,
        loading: false,
      };
    case ADMIN_WORKSPACES_SET_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    default:
      return state;
  }
}
