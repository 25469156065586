import React from "react";
import PropTypes from "prop-types";

// MATERIAL UI
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
  },
  heading: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  content: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "100%",
    position: "relative",
  },
});

const MainPanel = React.forwardRef(({ classes, heading, children }, ref) => (
  <main className={classes.root} ref={ref}>
    {heading ? (
      <Typography variant="h6" noWrap className={classes.heading}>
        {heading}
      </Typography>
    ) : null}
    <div className={classes.content}>{children}</div>
  </main>
));

MainPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainPanel);
