import { PipelineAction, PipelineFailure, PipelineState } from "./pipeline-types";

const initialState: PipelineState = {
  failures: {
    loading: false,
    error: null,
    list: [] as PipelineFailure[],
  },
  reprocessing: {
    active: false,
    files: [],
  },
};

export function pipelineReducer(
  state: PipelineState = initialState,
  action: PipelineAction
): PipelineState {
  switch (action.type) {
    case "PIPELINE_FAILURES_START_LOADING_DATA":
      return {
        ...state,
        failures: {
          ...state.failures,
          loading: true,
        },
      };
    case "PIPELINE_FAILURES_SET_DATA":
      return {
        ...state,
        failures: {
          loading: false,
          error: null,
          list: action.payload.list,
        },
      };
    case "PIPELINE_FAILURES_ERROR":
      return {
        ...state,
        failures: {
          loading: false,
          error: action.payload.message,
          list: [],
        },
      };
    case "PIPELINE_MONITORING_SET_ACTIVE":
      return {
        ...state,
        reprocessing: {
          ...state.reprocessing,
          active: true,
        },
      };
    case "PIPELINE_STOP_MONITORING":
      return {
        ...state,
        reprocessing: {
          ...state.reprocessing,
          stop: true,
        },
      };
    case "PIPELINE_MONITORING_STOPPED":
      return {
        ...state,
        reprocessing: {
          ...state.reprocessing,
          stop: false,
          active: false,
        },
      };
    case "PIPELINE_ADD_REPROCESSING":
      return {
        ...state,
        reprocessing: {
          ...state.reprocessing,
          files: state.reprocessing.files.concat(action.payload.files),
        },
      };
    case "PIPELINE_SET_REPROCESSING":
      return {
        ...state,
        reprocessing: {
          ...state.reprocessing,
          files: [...action.payload.files],
        },
      };
    case "PIPELINE_CLEAR_REPROCESSED":
      return {
        ...state,
        reprocessing: {
          ...state.reprocessing,
          files: state.reprocessing.files.filter(q => q.status == "PROCESSING"),
        },
      };
    default:
      return state;
  }
}
