import React from "react";
import { TableSortLabel } from "@material-ui/core";

import Cell from "./Cell";
import FilterIndicator from "./FilterIndicator";

function HeaderCell({
  sortable,
  sortDir,
  handleSort,
  filterable,
  filterValue,
  handleColFilter,
  FilterInputComponent,
  numeric,
  width,
  flexGrow,
  flexShrink,
  children,
}) {
  return (
    <Cell
      width={width}
      flexGrow={flexGrow}
      flexShrink={flexShrink}
      variant="head"
      numeric={numeric}
      onClick={handleSort}
      style={sortable ? { cursor: "pointer" } : null}
      noEllipses
    >
      <div style={{ display: "flex", cursor: sortable && "pointer" }}>
        {sortable ? (
          <TableSortLabel
            active={Boolean(sortDir)}
            direction={sortDir ? sortDir.toLowerCase() : "asc"}
            hideSortIcon
          >
            {children}
          </TableSortLabel>
        ) : (
          <span>{children}</span>
        )}
        {filterable && (
          <FilterIndicator
            dataLabel={children}
            value={filterValue}
            onChange={handleColFilter}
            FilterInputComponent={FilterInputComponent}
          />
        )}
      </div>
    </Cell>
  );
}

export default React.memo(HeaderCell);
