import { animalSizeUnits, animalMassUnits, animalAgeUnits } from "./constants";
import { round } from "lodash";
import { validateFormFields } from "../../helpers/common";

const MAX_DECIMALS = 5;
const displayUnits = { ...animalSizeUnits, ...animalMassUnits, ...animalAgeUnits };

export function formatMeasurement(measurement) {
  if (!measurement) {
    return measurement;
  }

  const displayValue = round(measurement.value, MAX_DECIMALS);
  const displayUnit = displayUnits[measurement.unit];
  return `${displayValue} ${displayUnit}`;
}

// check that a measurement set has at least one measurement populated
export function hasNoMeasurements(measurementSet) {
  const measurementFields = [
    "totalLength",
    "forkLength",
    "standardLength",
    "hoodLength",
    "width",
    "girth",
    "mass",
    "age",
    "sex",
    "lifeStage",
  ];

  for (const field of measurementFields) {
    const measurement = measurementSet[field];
    if (measurement && measurement.value !== "") {
      return false;
    }
  }
  return true;
}

// check an animal subobject input for correct format & required fields
export function validateAnimalSubObj(values, graph, type, mode, animalsSelected) {
  const requiredErrorMsg = `Required if you enter ${graph.label}`;

  // generic validation
  const { fieldErrors, formErrors } = validateFormFields(values, graph.fields, requiredErrorMsg);

  // type-specific validation
  if (type === "animal" && mode === "edit") {
    const animal = animalsSelected[0];
    if (animal.devices.length === 0 && !animal.name) {
      fieldErrors.name = "A nickname is required if no tags are attached";
    }
  }
  if (type === "taggingEvent" && mode === "edit") {
    if (values.devices.length === 0) {
      fieldErrors.devices = "A tag is required";
    }
  }
  if (type === "measurementSet") {
    if (hasNoMeasurements(values)) {
      formErrors.push("A measurement set must include at least one measurement.");
    }
  }

  return { fieldErrors, formErrors };
}

// extract all of the unique tag ids included in an animal device list
export function getTagIds(devices) {
  const ids = [];
  devices.forEach(device => {
    device.transmitters.forEach(tx => {
      if (!ids.includes(tx.displayId)) {
        ids.push(tx.displayId);
      }
    });
  });
  return ids;
}
