/** Burst times taken from the range test tool code (rangeTest.dat) */
const burstTimeMap = {
  "9001": {
    avg: 4.96,
    "8": 5.12,
    "27": 5.12,
    "35": 5.12,
    "45": 5.12,
    "1011": 4.96,
    "1028": 4.96,
    "1035": 4.96,
    "1044": 4.96,
    "1087": 4.96,
    "1143": 4.96,
    "1202": 4.96,
    "1203": 4.96,
    "13485": 5.02,
    "17488": 4.02,
    "19625": 5.02,
    "131971": 4.02,
    "180395": 4.02,
  },
  "9002": {
    avg: 4.96,
  },
  "9004": {
    avg: 5.14,
  },
  "9006": {
    avg: 4.78,
  },
  "9007": {
    avg: 4.52,
  },
  "1008": {
    avg: 2.7196,
  },
  "1105": {
    avg: 3.54,
  },
  "1206": {
    avg: 3.08,
  },
  "1303": {
    avg: 3.26,
    "57340": 4.02,
    "61165": 4.02,
    "61180": 4.02,
    "61390": 4.02,
    "61405": 4.02,
    "64765": 4.02,
    "64990": 4.02,
    "65005": 4.02,
    "65215": 4.02,
    "65230": 4.02,
    "65440": 4.02,
    "65455": 4.02,
  },
  "1701": {
    avg: 3.29,
    "1063": 2.85,
    "1110": 2.85,
    "1113": 2.85,
    "2125": 2.85,
  },
  "1702": {
    avg: 1.5,
    "1063": 1.248,
    "1110": 1.248,
    "1113": 1.248,
    "2125": 1.248,
  },
  "1703": {
    avg: 1.5,
  },
  "1704": {
    avg: 1.824,
  },
  "1601": {
    avg: 3.12,
    "5": 2.6,
    "626": 2.6,
    "972": 3.1,
    "1066": 3.1,
    "1757": 2.58,
    "2459": 2.58,
    "2687": 2.58,
    "3315": 2.58,
    "4331": 2.58,
    "4632": 2.58,
    "7798": 2.58,
    "8137": 2.58,
    "8355": 3.1,
    "52087": 2.58,
    "52423": 2.58,
  },
  "1602": {
    avg: 2.98,
  },
  "1604": {
    avg: 3.34,
  },
  "1605": {
    avg: 3.2,
  },
  "1801": {
    avg: 0.006,
  },
  "8001": {
    avg: 3.02,
  },
  "8002": {
    avg: 3.49,
  },
};

const OP_CODESPACES = { OPI: 8001, OPS: 8002 };

function getBurstTime(fullId: string): number | null {
  if (!fullId) return null;
  const displayArray = fullId.split("-");
  let codingId, transmitId;
  if (displayArray.length > 2) {
    codingId = displayArray[1];
    transmitId = displayArray[2];
  } else {
    codingId = OP_CODESPACES[displayArray[0]];
    transmitId = displayArray[1];
  }
  if (transmitId === undefined) return null;
  const burst = burstTimeMap[codingId]
    ? burstTimeMap[codingId][transmitId] || burstTimeMap[codingId].avg
    : null;
  return burst;
}

/** The expected time from start of one transmission to the next based on tag id and delays.
 * @returns Transmission interval (sec)
 */
export function getTxInterval({
  fullId,
  delayMin,
  delayMax,
}: {
  fullId: string;
  delayMin: number;
  delayMax: number;
}): number | null {
  const burst = getBurstTime(fullId);
  if (burst === null) return null;
  const interval = (Number(delayMin) + Number(delayMax)) / 2 + burst;
  return interval;
}
