import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Typography, IconButton, Tooltip, Collapse } from "@material-ui/core";
import {
  Add as IconAdd,
  Delete as IconDelete,
  Send as IconSend,
  Replay as IconReplay,
} from "@material-ui/icons";
import { WindowedTable } from "../../fathom-brella";
import WorkspaceUserAdd from "./WorkspaceUserAdd";
import DialogWrapper from "../common/DialogWrapper";

const useStyles = makeStyles(theme => ({
  flexRow: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    width: "100%",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    width: "100%",
  },
  add: {
    marginLeft: theme.spacing(1),
  },
  addUser: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    width: "100%",
  },
}));

function WorkspaceUsers({
  isReadOnly,
  userId,
  workspace,
  removeWorkspaceUser,
  updateWorkspaceUser,
  inviteUserToWorkspace,
  autoShowInviteUser,
  isUserAdmin,
  users,
  addWorkspaceUser,
  resendWorkspaceInvite,
}) {
  const classes = useStyles();

  const isUserOwner =
    workspace.users.filter(workspaceUser => workspaceUser.id === userId && workspaceUser.isOwner)
      .length > 0;

  const [showInviteUser, setShowInviteUser] = useState(autoShowInviteUser);
  const [resendInviteSelection, setResendInviteSelection] = useState(null);
  const [worspaceUserChange, setWorspaceUserChange] = useState(null);
  const [userToRemove, setUserToRemove] = useState(null);

  const isMembershipPending = status => {
    // to make it less complicated to control enums defined in the data graph
    return status === "PENDING";
  };
  const pendingStyle = { opacity: 0.8, fontStyle: "italic" };
  const pendingTooltip = "This user has been invited, but has not yet responded";

  // Add pending invites to workspace users:
  const workspaceUsers = [...workspace.users];
  if (workspace.invites.length) {
    for (const invite of workspace.invites.filter(i => isMembershipPending(i.status))) {
      workspaceUsers.push({
        inviteId: invite.id,
        email: invite.recipientEmail,
        status: invite.status,
        isOwner: invite.isOwner,
      });
    }
  }
  // sort users by ID so they are always in the same order (by userId ASC):
  workspaceUsers.sort((a, b) => a.id - b.id);

  function changePermission({ workspaceId, userIdToChange, isOwner }) {
    // If admin is removing own admin permission, ask for confirmation:
    if (userIdToChange === userId && isOwner === false) {
      setWorspaceUserChange({ workspaceId, userId, isOwner });
    } else {
      updateWorkspaceUser({
        workspaceId,
        userId: userIdToChange,
        isOwner,
      });
    }
  }

  const tableColumns = [
    {
      width: 40,
      label: " ",
      dataKey: "none",
      flexShrink: 1,
      flexGrow: 0,
      renderFn: (_, rowData) => {
        const pending = isMembershipPending(rowData.status);
        if ((isUserOwner || isUserAdmin) && !pending) {
          return (
            <Tooltip title={"Remove user from workspace"}>
              <IconButton
                size="small"
                onClick={() => setUserToRemove({ workspaceId: workspace.id, userId: rowData.id })}
              >
                <IconDelete />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={"Resend invitation"}>
              <div style={{ position: "relative", marginLeft: 2 }}>
                <IconReplay
                  style={{
                    position: "absolute",
                    transform: "scale(0.45)",
                    right: -5,
                    bottom: -6,
                    opacity: 0.6,
                  }}
                />
                <IconButton size="small" onClick={() => setResendInviteSelection(rowData)}>
                  <IconSend style={{ fontSize: 20 }} />
                </IconButton>
              </div>
            </Tooltip>
          );
        }
      },
      sortable: false,
    },
    {
      width: 150,
      label: "Email",
      dataKey: "email",
      renderFn: (email, rowData) => {
        const pending = isMembershipPending(rowData.status);
        return (
          <span style={pending ? pendingStyle : {}} title={pending ? pendingTooltip : ""}>
            {email} {pending ? "(invitation sent)" : ""}
          </span>
        );
      },
    },
    {
      width: 60,
      label: "Admin",
      dataKey: "isOwner",
      flexShrink: 1,
      flexGrow: 0,
      renderFn: (isOwner, rowData) => {
        const pending = isMembershipPending(rowData.status);
        return (
          <div
            title={
              pending
                ? pendingTooltip
                : isReadOnly
                ? "If you are admin, you may edit the workspace to change permissions"
                : isUserOwner
                ? "As admin, you may add and remove admins of this workspace."
                : "You may change workspace admins only if you are admin of this workspace."
            }
          >
            <Checkbox
              checked={isOwner}
              disabled={(!isUserOwner && !isUserAdmin) || pending || isReadOnly}
              onChange={ev =>
                changePermission({
                  workspaceId: workspace.id,
                  userIdToChange: rowData.id,
                  isOwner: ev.target.checked,
                })
              }
              title={pending ? pendingTooltip : ""}
              style={pending ? pendingStyle : {}}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  if (isReadOnly) {
    tableColumns.shift();
  }

  return (
    <div className={classes.flexCol}>
      {!isReadOnly && (
        <div className={classes.flexRow}>
          <Typography variant="subtitle1">Members</Typography>
          {!showInviteUser && !workspace.isPersonal && (isUserOwner || isUserAdmin) ? (
            <Tooltip title={"Invite a user to workspace"}>
              <IconButton
                size="small"
                className={classes.add}
                onClick={() => setShowInviteUser(true)}
              >
                <IconAdd />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      )}

      {workspace.isPersonal ? (
        <Typography>* No other users can be added to your personal workspace *</Typography>
      ) : (
        <>
          {!isReadOnly && (
            <Collapse in={showInviteUser} className={showInviteUser ? classes.addUser : null}>
              <WorkspaceUserAdd
                workspaceId={workspace.id}
                handleClose={() => setShowInviteUser(false)}
                inviteUserToWorkspace={inviteUserToWorkspace}
                isUserAdmin={isUserAdmin}
                users={users}
                addWorkspaceUser={addWorkspaceUser}
              />
            </Collapse>
          )}
          <div
            style={{
              height: 42 + workspaceUsers.length * 32,
              maxHeight: 300,
              minWidth: 400,
              width: "100%",
            }}
          >
            <WindowedTable rows={workspaceUsers} columns={tableColumns} />
          </div>
        </>
      )}
      <DialogWrapper
        open={Boolean(resendInviteSelection)}
        title={
          resendInviteSelection &&
          `Are you sure you want resend invite to ${resendInviteSelection.email}`
        }
        cancelAction={() => setResendInviteSelection(null)}
        okAction={() => {
          resendWorkspaceInvite({ inviteId: resendInviteSelection.inviteId });
          setResendInviteSelection(null);
        }}
        okButtonContent={"Send"}
      />
      <DialogWrapper
        open={Boolean(worspaceUserChange)}
        title={
          "Are you sure you want to remove your admin permission? You will have to ask another admin to reinstate it"
        }
        cancelAction={() => setWorspaceUserChange(null)}
        okAction={() => {
          setWorspaceUserChange(null);
          updateWorkspaceUser(worspaceUserChange);
        }}
        okButtonContent={"Yes I'm sure"}
      />
      <DialogWrapper
        open={Boolean(userToRemove)}
        title={"Are you sure you want to remove this user?"}
        cancelAction={() => setUserToRemove(null)}
        okAction={() => {
          setUserToRemove(null);
          removeWorkspaceUser(userToRemove);
        }}
        okButtonContent={"Yes I'm sure"}
      />
    </div>
  );
}

WorkspaceUsers.propTypes = {
  workspace: PropTypes.object,
  isReadOnly: PropTypes.bool,
  userId: PropTypes.string,
  removeWorkspaceUser: PropTypes.func,
  updateWorkspaceUser: PropTypes.func,
  inviteUserToWorkspace: PropTypes.func,
  autoShowInviteUser: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  users: PropTypes.array,
  addWorkspaceUser: PropTypes.func,
};

export default WorkspaceUsers;
