import ReactDOM from "react-dom";
import "./styles/index.css";
import LogRocket from "logrocket";
import { LOGROCKET_URL, AuthConfig } from "./config";
import Cookies from "js-cookie";
import App from "./App";

/**
 * Since the introduction of Heap analytics and Logrocket we hit the limit for
 * some web servers / browsers cookie size limit. The vast majority of our cookie
 * usage is from AWS amplify's Auth session. We moved the session back to local storage
 * and this code makes sure that old cookies are removed so users don't hit that hard limit.
 * and get HTTP 400 errors
 *
 * The AWS Amplify cookies automatically expire after 30 days after we switch to local storage
 * so this code can be removed after cookie expiration.
 */
const { REACT_APP_COGNITO_COOKIE_REMOVE_DOMAIN: removeDomain } = process.env;
if (!AuthConfig.cookieStorage && removeDomain) {
  Object.keys(Cookies.get()).forEach(key => {
    if (key.startsWith("CognitoIdentityServiceProvider")) {
      // providing exact domain / path is important or remove will not work
      Cookies.remove(key, { path: "/", domain: removeDomain });
    }
  });
}

if (process.env.NODE_ENV === "production") {
  console.log("Version: " + process.env.REACT_APP_VERSION);
}

ReactDOM.render(<App />, document.getElementById("root"));

LOGROCKET_URL &&
  LogRocket.init(LOGROCKET_URL, {
    console: {
      /**
       * Allows console.error() to show up in Error tab in logrocket dashboard
       * By default log rocket only classifies uncaught exceptions as errors
       */
      shouldAggregateConsoleErrors: true,
    },
  });
