import { Component } from "react";
import autoBind from "auto-bind";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import Auth from "@aws-amplify/auth";
import { SignInCard, PasswordChangeCard } from "../fathom-brella";
import { setCurrentUser } from "../redux/user/user-actions";
import history from "../helpers/history";
import { Typography, Button, Collapse } from "@material-ui/core";
import { ExpandMore as IconExpandMore } from "@material-ui/icons";
import { amber } from "@material-ui/core/colors";
import Header from "./Header";

const styles = theme => ({
  root: {
    background: "top / cover no-repeat url(/img/fathom-landing-bg.png)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "'Nunito Sans', sans-serif", // 20200414 JAB: I haven't figured out why the theme provider doesn't affect this page. This sets the font for now. (I even tried adding <MuiThemeProvider> directly, but no effect)
    overflow: "auto",
  },
  body: {
    background: "top / cover no-repeat url(/img/fathom-landing-bg.png)",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center",
    height: "100%",
    padding: theme.spacing(3),
  },
  signInCard: {
    alignContent: "center",
  },
  actions: {
    display: "flex",
  },
  workspaceInvite: {
    flexWrap: "wrap",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 5,
    backgroundColor: `${amber[50]}`,
    boxShadow: `5px 5px 10px -5px ${theme.palette.primary.main}`,
    color: theme.palette.primary.dark,
    padding: theme.spacing(2),
    margin: theme.spacing(1.5),
    width: 314,
    height: 405,
    "& .wi-accept-container": {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .wi-accept-msg": {
      width: 180,
    },
    "& .wi-accept-email": {
      fontWeight: "bold",
      marginTop: theme.spacing(2),
      whiteSpace: "nowrap",
    },
    "& .wi-no-account": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    "& .wi-sign-up-content": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .wi-signup-button": {
      margin: theme.spacing(1),
    },
  },
  "@media (max-width: 723px)": {
    workspaceInvite: {
      height: "auto",
    },
  },
  expand: {
    transition: "all 250ms ease-out",
  },
  expandCollapse: {
    transform: "rotate(180deg)",
  },
});

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "signIn", // signIn || changePassword
      error: "",
      newPasswordUser: null,
      loading: false,
      workspaceInvite: { id: null, email: null },
      noAccountExpanded: false,
    };
    autoBind(this);
  }

  async componentDidMount() {
    // Check for workspace invitations and show info about it.
    const workspaceInvite = {
      id: localStorage.getItem("workspaceInviteId"),
      email: localStorage.getItem("workspaceInviteEmail"),
    };
    // keep the workspace invite clicked info in the state, since logout clears it:
    this.setState({ workspaceInvite });

    try {
      if (await Auth.currentAuthenticatedUser()) {
        history.push("/home");
      }
    } catch {
      // not an error, just not authenticated
    }
  }

  async completeSignIn(user) {
    const { workspaceInvite } = this.state;
    const isUserAdmin =
      user.signInUserSession.accessToken.payload["cognito:groups"] &&
      user.signInUserSession.accessToken.payload["cognito:groups"].includes("admin");

    await this.props.dispatch(setCurrentUser(user, isUserAdmin));
    if (workspaceInvite.id) {
      localStorage.setItem("workspaceInviteId", workspaceInvite.id);
      localStorage.setItem("workspaceInviteEmail", workspaceInvite.email);
      history.push("/workspaces");
    } else {
      history.push("/home");
    }
  }

  changeTempPassword(newPassword) {
    this.setState({ loading: true });
    const { newPasswordUser } = this.state;
    Auth.completeNewPassword(
      newPasswordUser, // the Cognito User Object
      newPassword
    )
      .then(user =>
        // for some reason the returned user object from completeNewPassword attributes are stored
        // under a different property (user.challengeParam.userAttributes) rather than (user.attributes)
        this.completeSignIn({ ...user, attributes: user?.challengeParam?.userAttributes })
      )
      .catch(e => {
        console.error(e);
        this.setState({ error: e.message });
      })
      .finally(() => this.setState({ loading: false }));
  }

  cancelChangePassword() {
    // The user actually gets signed in when the temp password is correct
    Auth.signOut().finally(() => this.setState({ mode: "signIn" }));
  }

  signIn(email, password) {
    this.setState({ loading: true });

    Auth.signIn(email.toLowerCase(), password)
      .then(user => {
        if (!user.challengeName) {
          this.completeSignIn(user);
        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.setState({ mode: "changePassword", newPasswordUser: user });
        } else {
          console.error("Error logging user in with challenge: ", user.challengeName);
          this.setState({ error: "Error signing in, please contact administrator" });
          Auth.signOut();
        }
      })
      .catch(err => {
        if (err.code === "UserNotConfirmedException") {
          this.setState({
            error:
              "Your email is not verified, please check your email again for a new verification email",
          });
          Auth.resendSignUp(
            email.toLowerCase(),
            // Some extra metadata to build the email verification link
            { origin: window.location.origin }
          ).catch(e => this.setState({ error: e.message }));
        } else {
          this.setState({ error: err.message });
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  forgotPassword(email) {
    let redir = "/forgot-password";

    if (email) {
      redir += `?email=${email}`;
    }

    history.push(redir);
  }

  setNoAccountExpanded(noAccountExpanded) {
    this.setState({ noAccountExpanded });
  }

  render() {
    const { classes } = this.props;
    const { mode, error, loading, workspaceInvite, noAccountExpanded } = this.state;

    return (
      <div className={classes.root}>
        <Header classes={classes} />
        <div className={classes.body}>
          {mode === "signIn" ? (
            <>
              {workspaceInvite.id ? (
                <div className={classes.workspaceInvite}>
                  <Typography align="center" variant="h6">
                    You have been invited to a shared Fathom workspace!
                  </Typography>
                  <div className="wi-accept-container">
                    <Typography align="center" className="wi-accept-msg">
                      To accept the invitation, please log in as:
                    </Typography>
                    <Typography align="center" className="wi-accept-email">
                      {workspaceInvite.email}
                    </Typography>
                  </div>
                  <div
                    className="wi-no-account"
                    onClick={() => this.setNoAccountExpanded(!noAccountExpanded)}
                  >
                    <Typography variant="subtitle2" align="center">
                      Don't have a Fathom account yet?
                    </Typography>
                    <IconExpandMore
                      className={`${classes.expand} ${
                        noAccountExpanded ? classes.expandCollapse : ""
                      }`}
                    />
                  </div>
                  <Collapse in={noAccountExpanded} classes={{ wrapperInner: "wi-sign-up-content" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className="wi-signup-button"
                      onClick={() =>
                        history.push(
                          `/signup?workspaceInviteId=${workspaceInvite.id}&workspaceInviteEmail=${workspaceInvite.email}`
                        )
                      }
                    >
                      Register
                    </Button>
                    <Typography variant="subtitle2" align="center">
                      to accept the invitation.
                    </Typography>
                  </Collapse>
                </div>
              ) : null}
              <div className={classes.signInCard}>
                <SignInCard
                  logoUrl="img/fathom-central.png"
                  onSignIn={this.signIn}
                  errorMsg={error}
                  loading={loading}
                  onForgotPassword={email => this.forgotPassword(email)}
                />
              </div>
            </>
          ) : (
            <PasswordChangeCard
              onSubmit={this.changeTempPassword}
              onCancel={this.cancelChangePassword}
              loading={loading}
            />
          )}
        </div>
      </div>
    );
  }
}

export default compose(withStyles(styles), connect())(SignInPage);
