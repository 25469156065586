import { BubblePlotAction, BubblePlotData, BubblePlotError } from "./bubble-plot-types";

type BubblePlotState = {
  data: null | BubblePlotData;
  dataStatus: "unloaded" | "loading" | "loaded" | "error";
  dataRequestTimestamp: null | number;
  dataError: BubblePlotError | null;
  demoMode: boolean;
};

const initialState: BubblePlotState = {
  data: null,
  dataStatus: "unloaded",
  dataRequestTimestamp: null,
  dataError: null,
  demoMode: false,
};

export default function bubblePlotReducer(
  state: BubblePlotState = initialState,
  action: BubblePlotAction
): BubblePlotState {
  switch (action.type) {
    case "BUBBLEPLOT_START_LOADING_DATA":
      return {
        ...state,
        dataStatus: "loading",
        dataRequestTimestamp: action.payload.timestamp,
      };

    case "BUBBLEPLOT_FINISH_LOADING_DATA":
      if (action.payload.timestamp === state.dataRequestTimestamp) {
        return {
          ...state,
          data: action.payload.data,
          dataStatus: "loaded",
        };
      } else {
        return state;
      }

    case "BUBBLEPLOT_SET_DATA_ERROR": {
      const { timestamp, error } = action.payload;
      return timestamp === state.dataRequestTimestamp
        ? {
            ...state,
            dataStatus: "error",
            dataError: error,
          }
        : state;
    }

    case "BUBBLEPLOT_ENTER_DEMO_MODE":
      return { ...state, demoMode: true };

    case "BUBBLEPLOT_LEAVE_DEMO_MODE":
      return { ...state, demoMode: false };

    case "BUBBLEPLOT_CLEAR_DATA":
      return initialState;

    default:
      return state;
  }
}
