import moment, { parseDateTime } from "../../helpers/time";
import { validateFormFields } from "../../helpers/common";
import { formatDevice } from "../../helpers/device";

/**
 * @typedef {import('moment').Moment} Moment
 * @typedef {{start: Moment, end: Moment }} Bound
 * @typedef {string} TimeString
 * @typedef {{start: TimeString, end: TimeString }} TimeBound
 *
 * @typedef {{ fieldErrors: object, formErrors: string[]}} FormErrors
 */

/**
 * Parse a {start, end} pair of date strings into moments
 *
 * @param {{ start: TimeString, end: TimeString }} bound - input as strings
 * @returns {Bound} - output as moments
 */
export function parseBounds({ start, end }) {
  return { start: parseDateTime(start), end: parseDateTime(end) };
}

/**
 * Checks if end time come before start time.
 *
 * Returns nothing if either of the times are not valid.
 *
 * @param {Bound} bound - input as moments
 * @returns {string} - optional error message
 */
export function validateBounds({ start, end }) {
  if (start && end) {
    if (start.isValid() && end.isValid() && end.isBefore(start)) {
      return "End time cannot be before start time";
    }
  }
}

export function validateEndTime({ end }) {
  const endTime = parseDateTime(end);
  if (endTime.isValid() && moment().isBefore(endTime)) {
    return "Cannot be in the future";
  }
}

export function validateLatitude(latitude) {
  const latNum = parseFloat(latitude);
  if (isNaN(latNum) || latNum < -90 || latNum > 90) {
    return "Must be number between -90 and 90";
  }
}

export function validateLongitude(longitude) {
  const lonNum = parseFloat(longitude);
  if (isNaN(lonNum) || lonNum < -180 || lonNum > 180) {
    return "Must be number between -180 and 180";
  }
}

/**
 * Validate a child object of a deployment (device attachment or position).
 *
 * This function performs all of the standard form validation, plus validates
 * the start/end times of the device attachment or position.
 *
 * @param {Bound} subObjBounds - The form values to validate
 * @param {Bound} deploymentBounds - The field specification for the form
 * @returns {FormErrors} - Errors related to start & end
 */
export function validateSubObjBounds(subObjBounds, deploymentBounds) {
  // rename for brevity
  const d = deploymentBounds;
  const { start, end } = subObjBounds;

  const fieldErrors = {};
  const formErrors = [];

  const startEndError = validateBounds(subObjBounds);
  if (startEndError) {
    formErrors.push(startEndError);
  }
  const error = validateEndTime(subObjBounds);
  if (error) {
    fieldErrors.end = error;
  }

  if (d.start.isValid() && d.end.isValid()) {
    const msg = "Must lie within the deployment time";

    if (start.isValid() && (start < d.start || d.end < start)) {
      fieldErrors.start = msg;
    }
    if (end.isValid() && (end < d.start || d.end < end)) {
      fieldErrors.end = msg;
    }
  } else {
    const msg = "Must be after deployment start time";

    if (start.isValid() && start < d.start) {
      fieldErrors.start = msg;
    }
    if (end.isValid() && (end < d.start || d.end < end)) {
      fieldErrors.end = msg;
    }
  }

  return { fieldErrors, formErrors };
}

/**
 * Validate a child object of a deployment (device attachment or position).
 *
 * This function performs all of the standard form validation, plus validates
 * the start/end times of the device attachment or position.
 *
 * @param {object.<string, string>} values - The form values to validate
 * @param {object[]} fields - The field specification for the form
 * @param {object} deployment - The parent deployment object (from API)
 * @returns {FormErrors} - Errors in "standard format"
 */
export function validateDeploymentSubObject(values, fields, deployment) {
  const genericErrors = validateFormFields(values, fields);

  const deploymentBounds = { start: moment(deployment.start), end: moment(deployment.end) };
  const boundErrors = validateSubObjBounds(parseBounds(values), deploymentBounds);

  const fieldErrors = { ...genericErrors.fieldErrors, ...boundErrors.fieldErrors };
  const formErrors = [...genericErrors.formErrors, ...boundErrors.formErrors];

  return { formErrors, fieldErrors };
}

/**
 * @param {object[]} deviceAttachments - Deployment object
 * @returns {string} - Deployment attached devices type
 *
 */
export function getAttachedDevicesType(deviceAttachments = []) {
  const devices = deviceAttachments.map(da => da.device);
  const transmitters = devices.filter(device => device.capabilities.includes("TRANSMITTER"));
  const receivers = devices.filter(device => device.capabilities.includes("RECEIVER"));
  return transmitters.length > 0 && receivers.length > 0
    ? "Both"
    : transmitters.length > 0
    ? "Transmitter"
    : receivers.length > 0
    ? "Receiver"
    : "None";
}

export const deploymentColorMap = {
  Receiver: "red",
  Transmitter: "green",
  Both: "orange",
  None: "gray",
};

export function getDeploymentColor(deployment) {
  const deviceCombination = getAttachedDevicesType(deployment.deviceAttachments);
  return deploymentColorMap[deviceCombination];
}

// extract all of the unique devices
export function getAttachedDevices(deployment) {
  if (!deployment || !deployment.deviceAttachments) return null;
  const attachedDevices = [];
  // sort the device attachment list to make receivers before tags
  deployment.deviceAttachments.sort((a, b) => {
    if (a.device && b.device) {
      if (a.device.deviceClasses.includes("TAG") && b.device.deviceClasses.includes("RECEIVER")) {
        return 1;
      }
    }
    return -1;
  });
  deployment.deviceAttachments.forEach(da => {
    if (da.device) {
      const device = formatDevice(da.device);
      if (!attachedDevices.includes(device)) {
        attachedDevices.push(device);
      }
    }
  });
  return attachedDevices;
}

export function latestPosition(deployment) {
  const { positions } = deployment;
  if (positions.length === 0) {
    return null;
  }

  let latestPosition = positions[0];
  for (let i = 1; i < positions.length; i++) {
    const p = positions[i];
    if (!latestPosition.start || (p.start && p.start > latestPosition.start)) {
      latestPosition = p;
    }
  }

  return latestPosition;
}

/**
 * Checks if deployment time period overlaps the given bound(s). It does NOT require that the time
 * bounds be completely within the deployment time.
 *
 * @param {*} deployment - Deployment object
 * @param {TimeBound[]} timeBounds - Array of start, end times
 * @param {boolean} requireAll - Require deployment period to overlap every bound
 * @returns {boolean | null} True if the deployment period overlap the given timeBound(s)
 */
export function deploymentOverlapsTimeBounds(deployment, timeBounds, requireAll = false) {
  if (!deployment) return false; // no deployment
  if (!timeBounds || timeBounds.length === 0) return true; // no constraints
  if (!(timeBounds.constructor === Array)) return null; // invalid input
  if (timeBounds.length > 0) {
    const da = deployment.start && new Date(deployment.start); // has to be a valid date
    if (!da) return null;
    const dz = deployment.end && new Date(deployment.end); // may be null
    const overlapsBounds = timeBounds.filter(bound => {
      const ba = bound.start && new Date(bound.start);
      const bz = bound.end && new Date(bound.end);

      if (dz && dz < ba) return false; // deployment ends before bound starts
      if (da > bz) return false; // deployment starts after bound ends
      return true;
    });
    // if requireAll is true, then the deployment time must overlap every given bound. Otherwise, any will do.
    return requireAll ? overlapsBounds.length === timeBounds.length : overlapsBounds.length > 0;
  }
  return true;
}
