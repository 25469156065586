import { makeStyles, Theme } from "@material-ui/core/styles";
import { FormControlLabel, Radio } from "@material-ui/core";
import FlexRow from "../common/FlexRow";
import FlexCol from "../common/FlexCol";
import TextOverflowEllipsis from "../common/TextOverflowEllipsis";

import { DevicePosition } from "../../containers/DetectionAnalysis";
import { formatTimeFromISOStringDt } from "../../helpers/time";
import { formatLatLon } from "../../helpers/common";

type Props = {
  position: DevicePosition;
  selectedPositionId?: string;
  selectable?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  position: {
    position: "relative",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    boxSizing: "border-box",
    width: "100%",
  },
  stationName: {
    width: "30%",
    fontWeight: "bold",
  },
  latlon: {
    width: "70%",
  },
  timeFrame: {
    fontStyle: "italic",
  },
  padLR: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  radioControl: {
    position: "absolute",
    top: 0,
    left: -theme.spacing(1),
  },
}));

export default function PositionSummary({ position, selectedPositionId, selectable }: Props) {
  const classes = useStyles();
  const { positionId, stationName, latitude, longitude, start, end } = position;

  if (!positionId) return null;

  return (
    <FlexCol key={positionId} className={classes.position}>
      {selectable && (
        <FormControlLabel
          value={positionId}
          checked={selectedPositionId == positionId}
          control={<Radio size="small" />}
          className={classes.radioControl}
          label=""
        />
      )}
      <FlexRow vAlign="center" itemSpacing={1}>
        <TextOverflowEllipsis
          className={classes.stationName}
          style={{ paddingLeft: selectable ? 24 : undefined }}
        >
          {stationName}
        </TextOverflowEllipsis>
        <FlexRow vAlign="center" hAlign="right" itemSpacing={1} className={classes.latlon}>
          (<TextOverflowEllipsis>{formatLatLon(latitude)}</TextOverflowEllipsis>,
          <TextOverflowEllipsis>{formatLatLon(longitude)}</TextOverflowEllipsis>)
        </FlexRow>
      </FlexRow>
      <FlexRow vAlign="center" hAlign="right" className={classes.timeFrame}>
        <TextOverflowEllipsis>{formatTimeFromISOStringDt(start, false)}</TextOverflowEllipsis>
        <div className={classes.padLR}>⤑</div>
        <TextOverflowEllipsis>{formatTimeFromISOStringDt(end, false) || "∞"}</TextOverflowEllipsis>
      </FlexRow>
    </FlexCol>
  );
}
