import { BubblePlotAction, BubblePlotData, BubblePlotError } from "./bubble-plot-types";
import { axios, callGqlApi } from "../../helpers/api";

import { parseBubblePlotData } from "../../fathom-brella";
import { Thunk, GqlErrors } from "../common";
import gql from "../gqlTag";
import { SnackBarAction } from "../snackbar/snackbar-types";
import { handleGqlErrors } from "../gql-error/gql-error-actions";

const DEMO_DEPLOYMENT_URL = "data/bubbleplot/walleye_deployment.csv";
const DEMO_DETCOUNT_URL = "data/bubbleplot/walleye_det_count.csv";

function startLoadingData(timestamp: number): BubblePlotAction {
  return {
    type: "BUBBLEPLOT_START_LOADING_DATA",
    payload: { timestamp },
  };
}

function finishLoadingData(data: BubblePlotData, timestamp: number): BubblePlotAction {
  return {
    type: "BUBBLEPLOT_FINISH_LOADING_DATA",
    payload: { data, timestamp },
  };
}

function setDataError(error: BubblePlotError, timestamp: number): BubblePlotAction {
  return {
    type: "BUBBLEPLOT_SET_DATA_ERROR",
    payload: { error, timestamp },
  };
}

export function clearData(): BubblePlotAction {
  return { type: "BUBBLEPLOT_CLEAR_DATA" };
}

function fetchBubblePlotDataGql(studyId: string, binInterval = 4): Promise<any> {
  return callGqlApi(gql`
      {
        hourlyDetectionCounts(studyId: ${studyId}, binIntervalHours: ${binInterval}) {
          detections {
            data
          }
          deployments {
            data
          } 
      }
    }
  `).then(({ hourlyDetectionCounts }) => {
    return {
      deploymentCsv: hourlyDetectionCounts.deployments.data,
      detCountCsv: hourlyDetectionCounts.detections.data,
    };
  });
}

export function fetchData(
  demoMode = false,
  binInterval = 4
): Thunk<void, BubblePlotAction | SnackBarAction> {
  return async (dispatch, getState) => {
    const studyId = getState().study.selectedId;

    if (!studyId && !demoMode) {
      // shouldn't happen
      return;
    }

    const timestamp = new Date().getTime();

    dispatch(startLoadingData(timestamp));

    let deploymentCsv: string | null = null;
    let detCountCsv: string | null = null;

    if (demoMode) {
      dispatch({ type: "BUBBLEPLOT_ENTER_DEMO_MODE" });

      try {
        deploymentCsv = (await axios.get(DEMO_DEPLOYMENT_URL)).data;
        detCountCsv = (await axios.get(DEMO_DETCOUNT_URL)).data;
      } catch (e) {
        dispatch(setDataError({ type: "server", message: "Error fetching demo data" }, timestamp));
        return;
      }
    } else {
      dispatch({ type: "BUBBLEPLOT_LEAVE_DEMO_MODE" });

      try {
        try {
          console.log("Fetching detection map GQL");
          const response = await fetchBubblePlotDataGql(studyId, binInterval);
          ({ deploymentCsv, detCountCsv } = response);
        } catch (errors) {
          const gqlErrors = errors as GqlErrors;
          // Known GQL error handle it and do not fall back to data server
          if (gqlErrors[0]?.extensions?.code === "BAD_USER_INPUT") {
            dispatch(
              setDataError({ type: "GQL_ERROR", message: gqlErrors[0]?.message }, timestamp)
            );
            return;
          } else {
            // Unknown GQL error - fall back to data server
            dispatch(handleGqlErrors(errors));
          }
        }
      } catch (e) {
        console.error(e);
        dispatch(setDataError(e.error, timestamp));
        return;
      }
    }

    if (!deploymentCsv || !detCountCsv) {
      console.error(`deploymentCsv or detCountCsv not found. demoMode = ${demoMode}.`);
      dispatch(setDataError({ type: "unkown", message: "Missing data" }, timestamp));
      return;
    }

    const parsedData: BubblePlotData = parseBubblePlotData(deploymentCsv, detCountCsv);

    dispatch(finishLoadingData(parsedData, timestamp));
  };
}
