import {
  WORKSPACES_SELECT,
  WORKSPACES_DONE_LOADING,
  ROOT_RESET_WORKSPACE,
  WORKSPACES_SET_LIST,
  WORKSPACES_SET_ERROR,
  WORKSPACES_SET_CREATED_ID,
  WORKSPACES_INCLUDE_DELETED,
  WORKSPACES_QUERY_FAILED,
} from "../action-types";
import history from "../../helpers/history";
import { listStudies, selectStudy } from "../study/study-actions";
import { snackbarError, basicSnackbar } from "../snackbar/snackbar-actions";
import { callGqlApi, setApiWorkspace } from "../../helpers/api";
import gql from "../gqlTag";
import { handleGqlErrors } from "../gql-error/gql-error-actions";
import { refreshUserData } from "../user/user-actions";

export function setCreatedWorkspaceId(createdId) {
  return {
    type: WORKSPACES_SET_CREATED_ID,
    payload: { createdId },
  };
}

export function setIncludeDeleted(includeDeleted) {
  return {
    type: WORKSPACES_INCLUDE_DELETED,
    payload: { includeDeleted },
  };
}

export function listWorkspaces() {
  return (dispatch, getState) => {
    const includeDeleted = getState().workspaces.includeDeleted || false;
    const query = gql`
      query {
        workspaces (includeDeleted: ${includeDeleted}){
          id
          creator {
            email
          }
          name
          description
          created
          users {
            id
            email
            isOwner
          }
          isPersonal
          isDeleted
          invites {
            id
            recipientEmail
            isOwner
            status
          }
        }
      }
    `;

    return callGqlApi(query)
      .then(({ workspaces }) => {
        dispatch({
          type: WORKSPACES_SET_LIST,
          payload: { workspaces },
        });

        dispatch({
          type: WORKSPACES_DONE_LOADING,
        });

        return { workspaces };
      })
      .catch(errors => {
        console.error(errors);
        handleGqlErrors(errors);

        dispatch({
          type: WORKSPACES_QUERY_FAILED,
        });
      });
  };
}

export function selectWorkspace(workspaceId, currentRoute) {
  return (dispatch, getState) => {
    localStorage.setItem("workspaceId", workspaceId);

    /**
     * It's very important this is called when changing workspaces
     * there is 1 instance of the axios object and this function changes
     * the workspace id header for that instance that is used by all
     * the other api requests
     */
    setApiWorkspace(workspaceId);

    /* Clear study selection, unless this is this initial call to selectWorkspace (when the app is first opened)
     * In that case, if there is a study selected is comes from the study query parameter in the URL. */
    if (getState().workspaces.selectedWorkspace) {
      dispatch(selectStudy(null));
    }

    dispatch({
      type: WORKSPACES_SELECT,
      payload: { workspaceId },
    });
    dispatch({ type: ROOT_RESET_WORKSPACE });
    // studies visible on all pages (nav bar)
    dispatch(listStudies());
    if (!currentRoute || currentRoute.startsWith("/workspace")) {
      return;
    } else if (currentRoute.startsWith("/detection-map")) {
      // detection map is in context of study so send them home to select another
      history.push("/home");
    }
  };
}

export function createWorkspace({ name, description }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: CreateWorkspaceInput!) {
          createWorkspace(input: $input) {
            workspace {
              id
            }
          }
        }
      `,
      { input: { name, description } }
    )
      .then(result => {
        const workspaceId = result.createWorkspace.workspace.id;
        dispatch(listWorkspaces());
        dispatch(basicSnackbar(`Created new workspace`, "success"));
        dispatch(setCreatedWorkspaceId(workspaceId));
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function toggleIncludeDeletedWorkspaces() {
  return (dispatch, getState) => {
    dispatch(setIncludeDeleted(!getState().workspaces.includeDeleted));
    dispatch(listWorkspaces());
  };
}

export function updateWorkspace({ workspaceId, name, description }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: UpdateWorkspaceInput!) {
          updateWorkspace(input: $input) {
            workspace {
              id
            }
          }
        }
      `,
      { input: { workspaceId, name, description } }
    )
      .then(() => {
        dispatch(listWorkspaces());
        dispatch(basicSnackbar(`Updated workspace`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function deleteWorkspaces({ workspaceIds }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: DeleteWorkspacesInput!) {
          deleteWorkspaces(input: $input) {
            deletedWorkspaceIds
          }
        }
      `,
      { input: { workspaceIds } }
    )
      .then(() => {
        dispatch(listWorkspaces());
        dispatch(basicSnackbar(`Deleted workspace`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function restoreWorkspaces({ workspaceIds }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: DeleteWorkspacesInput!) {
          restoreWorkspaces(input: $input) {
            restoredWorkspaceIds
          }
        }
      `,
      { input: { workspaceIds } }
    )
      .then(() => {
        dispatch(listWorkspaces());
        dispatch(basicSnackbar(`Restored workspace`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function removeWorkspaceUser({ workspaceId, userId }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: RemoveWorkspaceUserInput!) {
          removeWorkspaceUser(input: $input) {
            workspace {
              id
            }
          }
        }
      `,
      { input: { workspaceId, userId } }
    )
      .then(() => {
        dispatch(listWorkspaces());
        dispatch(basicSnackbar(`Removed user from workspace`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function addWorkspaceUser({ workspaceId, userId, isOwner }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: UpdateWorkspaceUserInput!) {
          addWorkspaceUser(input: $input) {
            workspace {
              id
            }
          }
        }
      `,
      { input: { workspaceId, userId, isOwner } }
    )
      .then(() => {
        dispatch(listWorkspaces());
        dispatch(basicSnackbar(`Added user to workspace`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function inviteUserToWorkspace({ workspaceId, email, message, isOwner }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: InviteUserToWorkspaceInput!) {
          inviteUserToWorkspace(input: $input) {
            invite {
              id
            }
          }
        }
      `,
      { input: { workspaceId, email, message, isOwner } }
    )
      .then(() => {
        dispatch(listWorkspaces()); // retrieve invitations added
        dispatch(basicSnackbar(`Sent workspace invitation to ${email}`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function updateWorkspaceUser({ workspaceId, userId, isOwner }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: UpdateWorkspaceUserInput!) {
          updateWorkspaceUser(input: $input) {
            workspace {
              id
            }
          }
        }
      `,
      { input: { workspaceId, userId, isOwner } }
    )
      .then(() => {
        dispatch(listWorkspaces());
        dispatch(basicSnackbar(`Updated workspace user`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function acceptWorkspaceInvite({ inviteId }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: WorkspaceInviteInput!) {
          acceptWorkspaceInvite(input: $input) {
            invite {
              id
            }
          }
        }
      `,
      { input: { inviteId } }
    )
      .then(() => {
        dispatch(listWorkspaces());
        dispatch(refreshUserData()); // get user's workspace invites again
        dispatch(basicSnackbar(`Invitation Accepted`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function declineWorkspaceInvite({ inviteId }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: WorkspaceInviteInput!) {
          declineWorkspaceInvite(input: $input) {
            invite {
              id
            }
          }
        }
      `,
      { input: { inviteId } }
    )
      .then(() => {
        dispatch(listWorkspaces());
        dispatch(refreshUserData()); // get user's workspace invites again
        dispatch(basicSnackbar(`Invitation Declined`, "warning"));
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function resendWorkspaceInvite({ inviteId }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: WorkspaceInviteInput!) {
          resendWorkspaceInvite(input: $input) {
            invite {
              id
            }
          }
        }
      `,
      { input: { inviteId } }
    )
      .then(() => {
        dispatch(basicSnackbar(`Invitation resent`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}
