import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";

const styles = theme => ({
  root: {
    width: 300,
    paddingRight: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(0.5),
  },
  linearBarColourPrimary: {
    backgroundColor: theme.palette.primary.dark,
  },
  linearColourPrimary: {
    backgroundColor: theme.palette.secondary.light,
    opacity: 0.7,
  },
});

function ProgressBarSnack({ snackbarKey, message, variant, progress = 0, classes }) {
  const { closeSnackbar } = useSnackbar();

  // Auto close when progress complete
  if (progress >= 100) {
    setTimeout(() => closeSnackbar(snackbarKey), 500);
  }

  return (
    <div className={classes.root}>
      <div className={classes.message}>{message}</div>
      <div>
        <LinearProgress
          classes={{
            barColorPrimary: classes.linearBarColourPrimary,
            colorPrimary: classes.linearColourPrimary,
          }}
          variant={variant}
          value={progress}
        />
      </div>
    </div>
  );
}

ProgressBarSnack.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
  snackbarKey: PropTypes.string,
};

const mapStateToProps = (state, { snackbarKey }) => {
  return {
    progress: state.snackbar.progress[snackbarKey],
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ProgressBarSnack));
