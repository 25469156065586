import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DialogWrapper from "../DialogWrapper";
import { WindowedTable } from "../../../fathom-brella";
import { SpecError } from "./types";

// these should match the WindowedTable default, but defined here incase the default changes
const TABLE_HEADER_HEIGHT = 40;
const TABLE_ROW_HEIGHT = 32;

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    maxHeight: 300,
    overflowY: "auto",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  open: boolean;
  specErrors: SpecError[];
  onClose: () => void;
};

function SpecErrorDialog({ open, specErrors = [], onClose }: Props) {
  const classes = useStyles();

  // adjust & format the column names
  const rows = specErrors.map(({ message, row, cols }, i) => {
    const colsAdjusted = cols?.map(col => col.replace(/\s+\(yyyy-mm-ddThh:mm:ss\)/, ""));
    return { i, message, row, cols: colsAdjusted?.join(", ") };
  });

  return (
    <DialogWrapper
      open={open}
      title="Errors Detected in Datasheet"
      cancelAction={onClose}
      maxWidth="lg"
      buttons={({ cancelAction }) => (
        <div className={classes.buttonContainer}>
          <Button onClick={cancelAction} variant="contained" color="primary">
            OK
          </Button>
        </div>
      )}
    >
      {rows.length === 1 && !rows[0].row ? (
        <>
          <Typography paragraph>
            The following error was detected in the datasheet you uploaded:
          </Typography>
          <Typography paragraph color="error">
            {rows[0].message}
          </Typography>
          <Typography paragraph>
            Please address this error by editing your datasheet and try again.
          </Typography>
        </>
      ) : (
        <>
          <Typography>The following errors were detected in the datasheet you uploaded:</Typography>

          <div
            className={classes.tableContainer}
            style={{
              height: Math.min(200, TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT * specErrors.length),
            }}
          >
            <WindowedTable
              rowHeight={TABLE_ROW_HEIGHT}
              rowIdKey="i"
              rows={rows}
              columns={[
                { width: 70, label: "Error", dataKey: "message" },
                { width: 40, label: "Row", dataKey: "row", flexGrow: 0, numeric: true },
                { width: 70, label: "Column(s)", dataKey: "cols" },
              ]}
            />
          </div>

          <Typography paragraph>
            Please address these errors by editing your datasheet and try again.
          </Typography>
        </>
      )}
    </DialogWrapper>
  );
}

export default SpecErrorDialog;
