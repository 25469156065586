import React from "react";
import BrushableTimeline from "./BrushableTimeline";
import * as d3 from "d3";
import CompactIconButton from "../CompactIconButton";
import { withStyles } from "@material-ui/core/styles";

import {
  ZoomIn as IconZoomIn,
  ZoomOut as IconZoomOut,
  PlayArrow as IconPlayArrow,
  Pause as IconPause,
  Replay as IconReplay,
} from "@material-ui/icons";

const styles = {
  root: {
    position: "absolute",
    height: 100,
    bottom: 15,
    left: 15,
    right: 15,
    zIndex: "3",
    backgroundColor: "rgba(66, 66, 66, 0.55)",
    border: "1px solid lightgray",
    borderRadius: 10,
  },
  toolBar: {
    color: "lightgray",
    position: "absolute",
    top: 8,
    bottom: 8,
    display: "block",
  },
  dateReadout: {
    position: "absolute",
    top: -25,
    right: 8,
    color: "lightgray",
    fontSize: "10pt",
    borderRadius: 4,
    padding: 2,
    backgroundColor: "rgba(66, 66, 66, 0.55)",
    fontFamily: "sans-serif",
  },
};

const formatDate = d3.utcFormat("%Y-%m-%d %H:%M");

class TimelinePanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.timeline = React.createRef();

    this.state = {
      playbackStatus: null,
    };

    this.playbackControlHandler = this.playbackControlHandler.bind(this);
    this.playbackStatusHandler = this.playbackStatusHandler.bind(this);
  }

  playbackStatusHandler(playbackStatus) {
    this.setState({ playbackStatus: playbackStatus });
  }

  playbackControlHandler() {
    if (this.state.playbackStatus === "playing") {
      this.timeline.current.pause();
    } else if (this.state.playbackStatus === "finished") {
      this.timeline.current.restart();
    } else {
      this.timeline.current.play();
    }
  }

  render() {
    const {
      detCount,
      zoomTimeRange,
      brushTimeRange,
      brushTimeRangeHandler,
      binSize,
      timeOffset,
      zoomInHandler,
      resetZoomHandler,
      classes,
    } = this.props;

    const readoutTimeRange = (brushTimeRange || zoomTimeRange)
      .map(t => t.getTime() + timeOffset)
      .map(formatDate)
      .join(" → ");

    return (
      <div className={classes.root}>
        <BrushableTimeline
          innerRef={this.timeline}
          data={detCount}
          timeZoom={zoomTimeRange}
          timeRangeHandler={brushTimeRangeHandler}
          playbackStatusHandler={this.playbackStatusHandler}
          binSize={binSize}
          timeOffset={timeOffset}
        />

        <div className={classes.dateReadout}>{readoutTimeRange}</div>

        <div className={classes.toolBar} style={{ right: 6 }}>
          <CompactIconButton onClick={zoomInHandler}>
            <IconZoomIn />
          </CompactIconButton>
          <CompactIconButton onClick={resetZoomHandler}>
            <IconZoomOut />
          </CompactIconButton>
        </div>

        <div className={classes.toolBar} style={{ left: 6 }}>
          <CompactIconButton onClick={this.playbackControlHandler}>
            {status === "playing" ? (
              <IconPause />
            ) : status === "finished" ? (
              <IconReplay />
            ) : (
              <IconPlayArrow />
            )}
          </CompactIconButton>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TimelinePanel);
