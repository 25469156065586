import * as d3 from "d3";
import gql from "../gqlTag";
import { Thunk, GqlErrors } from "../common";
import { callGqlApi } from "../../helpers/api";
import { SnackBarAction } from "../snackbar/snackbar-types";
import { handleGqlErrors } from "../gql-error/gql-error-actions";

import { TxSpec, Receiver } from "../../containers/DetectionAnalysis";
import {
  DetectionAnalysisAction,
  DetectionAnalysisData,
  DetectionAnalysisError,
} from "./detection-analysis-types";

export function setTxList(txList: TxSpec[]): DetectionAnalysisAction {
  return {
    type: "DETECTION_ANALYSIS_SET_TXLIST",
    payload: { txList },
  };
}

export function setRxList(rxList: Receiver[]): DetectionAnalysisAction {
  return {
    type: "DETECTION_ANALYSIS_SET_RXLIST",
    payload: { rxList },
  };
}

function startLoadingData(timestamp: number): DetectionAnalysisAction {
  return {
    type: "DETECTION_ANALYSIS_START_LOADING_DATA",
    payload: { timestamp },
  };
}

function finishLoadingData(
  data: DetectionAnalysisData,
  timestamp: number
): DetectionAnalysisAction {
  return {
    type: "DETECTION_ANALYSIS_FINISH_LOADING_DATA",
    payload: { data, timestamp },
  };
}

function setDataError(error: DetectionAnalysisError, timestamp: number): DetectionAnalysisAction {
  return {
    type: "DETECTION_ANALYSIS_SET_DATA_ERROR",
    payload: { error, timestamp },
  };
}

export function clearData(): DetectionAnalysisAction {
  return { type: "DETECTION_ANALYSIS_CLEAR_DATA" };
}

function fetchDetectionAnalysisDataGql(
  binIntervalHours: number,
  serials: string[],
  fullIds: string[],
  start: string,
  end: string
): Promise<any> {
  return callGqlApi(
    gql`
      query detectionCountsBinned(
        $serials: [String!]!
        $fullIds: [String]
        $binIntervalHours: Float
        $start: String
        $end: String
      ) {
        detectionCountsBinned(
          binIntervalHours: $binIntervalHours
          serials: $serials
          fullIds: $fullIds
          start: $start
          end: $end
        ) {
          detections {
            data
          }
        }
      }
    `,
    { binIntervalHours, serials, fullIds, start, end }
  )
    .then(data => {
      return {
        detCountCsv: data?.detectionCountsBinned?.detections?.data,
      };
    })
    .catch(errors => {
      console.error(errors);
    });
}

export function fetchDetectionAnalysisData(
  binInterval,
  serials,
  fullIds,
  start,
  end
): Thunk<void, DetectionAnalysisAction | SnackBarAction> {
  return async dispatch => {
    const timestamp = new Date().getTime();
    dispatch(startLoadingData(timestamp));

    let detCountCsv: string | null = null;

    try {
      try {
        const response = await fetchDetectionAnalysisDataGql(
          binInterval,
          serials,
          fullIds,
          start,
          end
        );
        detCountCsv = response?.detCountCsv;
      } catch (errors) {
        const gqlErrors = errors as GqlErrors;
        // Known GQL error handle it and do not fall back to data server
        if (gqlErrors[0]?.extensions?.code === "BAD_USER_INPUT") {
          dispatch(setDataError({ type: "GQL_ERROR", message: gqlErrors[0]?.message }, timestamp));
          return;
        } else {
          // Unknown GQL error - fall back to data server
          dispatch(handleGqlErrors(errors));
        }
      }
    } catch (e) {
      console.error(e);
      dispatch(setDataError(e.error, timestamp));
      return;
    }

    if (!detCountCsv) {
      console.error(`detCountCsv not found.`);
      dispatch(setDataError({ type: "NO_DATA", message: "Missing data" }, timestamp));
      return;
    }

    const parsedData: DetectionAnalysisData = {
      detectionCount: d3.csvParse(detCountCsv),
    };

    dispatch(finishLoadingData(parsedData, timestamp));
  };
}
