import PropTypes from "prop-types";
import { TextField, IconButton, InputAdornment } from "@material-ui/core";
import { Close as IconClose, Check as IconCheck } from "@material-ui/icons";
import { ifEnterOrEsc } from "../../helpers/input";

function TextFieldWithSubmit({
  value,
  onChange,
  onSubmit,
  onCancel,
  submitDisabled,
  placeholder,
  ...rest
}) {
  return (
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={event => onChange(event.target.value)}
      variant="outlined"
      margin="dense"
      onKeyDown={keyEvent =>
        ifEnterOrEsc({
          keyEvent,
          onEnter: onSubmit,
          onEsc: onCancel,
        })
      }
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <IconButton size="small" onClick={onCancel}>
              <IconClose />
            </IconButton>
            <IconButton size="small" onClick={onSubmit} disabled={submitDisabled}>
              <IconCheck />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}

TextFieldWithSubmit.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
};

export default TextFieldWithSubmit;
