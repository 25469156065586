import React from "react";
import { useState, createRef, useEffect } from "react";

// MATERIAL UI
import { makeStyles } from "@material-ui/core/styles";
import {
  ChevronLeft as IconChevronLeft,
  ChevronRight as IconChevronRight,
} from "@material-ui/icons";

const DIVIDER_SIZE = 20;
const BORDER_BASE_COLOR = "#333";
const BUTTON_WIDTH = 16;
const BUTTON_HEIGHT = 26;
const BUTTONS_OFFSET = 20;

const useStyles = makeStyles(
  () =>
    ({
      root: {
        display: "flex",
        position: "relative",
        width: "100%",
        height: "100%",
        flexGrow: 1,
        overflow: "hidden",
        "&.vertical": {
          flexDirection: "column",
        },
      },
      panel: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        flexGrow: 1,
        overflow: "hidden",
      },
      collapseBtnContainer: {
        position: "absolute",
        display: "flex",
        borderRadius: 5,
        boxShadow: `0px 0px 3px 1px ${BORDER_BASE_COLOR}`,
        backgroundColor: "#DDD",
        "&:not(.collapsed) >:first-child": {
          borderRight: `1px solid ${BORDER_BASE_COLOR}`,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        "&.vertical": { transform: "rotate(90deg)" },
      },
      collapseBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: BUTTON_WIDTH,
        height: BUTTON_HEIGHT,
        borderRadius: 5,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "white",
        },
      },
      dividerAnchor: {
        zIndex: 10,
        position: "relative",
        display: "flex",
        alignItems: "center",
        opacity: 0.3,
        transitionDelay: 100,
        // styles for collapseBtnScaler are defined within the scope of dividerAnchor to
        // maintain specificity and define style based on parent :hover
        "& .collapseBtnScaler": {
          display: "flex",
          alignItems: "center",
          transform: "scale(0.55)",
          transitionDuration: 150,
          transitionDelay: 100,
        },
        "&:hover": {
          opacity: 1,
          "& .collapseBtnScaler": {
            transform: "scale(1)",
          },
        },
        "&.horizontal": {
          cursor: "col-resize",
          paddingTop: BUTTONS_OFFSET, // for expand/collapse buttons
          width: DIVIDER_SIZE,
          flexDirection: "column",
          "& .collapseBtnScaler": {
            flexDirection: "column",
            height: BUTTON_HEIGHT, // so buttons scale from center
            marginLeft: 1, // account for button border
          },
        },
        "&.vertical": {
          cursor: "row-resize",
          paddingLeft: BUTTONS_OFFSET,
          height: DIVIDER_SIZE,
          flexDirection: "row",
          "& .collapseBtnScaler": {
            flexDirection: "row",
            width: BUTTON_HEIGHT,
            marginTop: 1,
          },
        },
        "&.collapsed": {
          opacity: 0.8, // make collapsed state more visible
          cursor: "pointer",
        },
      },
      divider: {
        transitionDuration: "250ms",
        position: "absolute",
        backgroundColor: BORDER_BASE_COLOR,

        "&.horizontal": {
          top: 0,
          height: "100%",
          width: 1,
        },
        "&.vertical": {
          left: 0,
          height: 1,
          width: "100%",
        },
      },
    } as any)
);

function triggerResizing({
  event,
  direction,
  refFirst,
  refRoot,
  refSecond,
  _firstMin,
  _secondMin,
  onResize,
}: {
  event: React.MouseEvent<HTMLDivElement, MouseEvent>;
  direction: string;
  refRoot: React.RefObject<HTMLDivElement>;
  refFirst: React.RefObject<HTMLDivElement>;
  refSecond: React.RefObject<HTMLDivElement>;
  _firstMin?: number | null;
  _secondMin?: number | null;
  onResize?: () => void;
}) {
  event.preventDefault();
  const elemFirst = refFirst.current;
  const elemRoot = refRoot.current;
  const elemSecond = refSecond.current;
  let mouseLeftPrev = event.clientX;
  let mouseTopPrev = event.clientY;

  // Set mouse event handlers on document:
  document.onmousemove = devent => {
    devent.preventDefault();
    const dx = mouseLeftPrev - devent.clientX;
    const dy = mouseTopPrev - devent.clientY;
    mouseLeftPrev = devent.clientX;
    mouseTopPrev = devent.clientY;

    if (elemFirst && elemRoot && elemSecond) {
      const sizeClientName = direction == "horizontal" ? "clientWidth" : "clientHeight";
      const sizeStyleName = direction == "horizontal" ? "width" : "height";
      const sizeDelta = direction == "horizontal" ? dx : dy;

      const rootSize = elemRoot[sizeClientName] - DIVIDER_SIZE;
      const firstMin = (_firstMin && _firstMin < 1 ? _firstMin * rootSize : _firstMin) || 0;
      const secondMin = (_secondMin && _secondMin < 1 ? _secondMin * rootSize : _secondMin) || 0;

      let firstSize = elemFirst[sizeClientName] - sizeDelta;
      firstSize = firstSize < firstMin ? firstMin : firstSize;

      let secondSize = rootSize - firstSize;
      secondSize = secondSize < secondMin ? secondMin : secondSize;

      elemFirst.style[sizeStyleName] = firstSize + "px";
      elemSecond.style[sizeStyleName] = secondSize + "px";
    }
  };
  document.onmouseup = () => {
    document.onmouseup = null;
    document.onmousemove = null;
    onResize && onResize();
  };
}

function toNumberOrPercent(n: number | string | undefined): number | null {
  return n?.constructor === String && n.includes("%")
    ? Number(n.replace(/[^0-9]/g, "")) / 100
    : Number(n) || null;
}

function ResizableSplitPanel({
  direction,
  firstContent,
  secondContent,
  firstInit,
  firstMin = 10,
  secondMin = 10,
  firstName = "panel",
  secondName = "panel",
  onResize,
}: {
  /** Panel can resize vertically or horizontally */
  direction: "vertical" | "horizontal";
  /** The content for the first container */
  firstContent: any;
  /** The content for the second container */
  secondContent: any;
  /** Initial width or height of first container */
  firstInit?: string | number;
  /** Minimum width or height of first container */
  firstMin?: string | number;
  /** Minimum width or height of second container */
  secondMin?: string | number;
  firstName?: string;
  secondName?: string;
  onResize?: () => void;
}) {
  const classes: any = useStyles();
  const refRoot = createRef<HTMLDivElement>();
  const refFirst = createRef<HTMLDivElement>();
  const refSecond = createRef<HTMLDivElement>();

  // A positive value indicates the panel is collapsed, and the number is the previous size:
  const [firstCollapsedPrev, setFirstCollapsedPrev] = useState(0);
  const [secondCollapsedPrev, setSecondCollapsedPrev] = useState(0);

  // Allow % for minimum size:
  const _firstMin = toNumberOrPercent(firstMin);
  const _secondMin = toNumberOrPercent(secondMin);

  // The property names based on direction:
  const styleProp = (direction == "horizontal" && "width") || "height";
  const clientSize = (direction == "horizontal" && "clientWidth") || "clientHeight";

  function collapse(side) {
    let prev = 0;
    const prevStateFn = side == "first" ? setFirstCollapsedPrev : setSecondCollapsedPrev;
    if (refSecond.current && refRoot.current && refFirst.current) {
      const collapseElem = side == "first" ? refFirst.current : refSecond.current;
      const growElem = side == "first" ? refSecond.current : refFirst.current;

      prev = collapseElem[clientSize] - 0;
      collapseElem.style.display = "none"; // leaving the size set is ok
      delete growElem.style[styleProp]; // this allows the flexGrow attribute to take over
    }
    prevStateFn(prev);
    onResize && onResize();
  }

  function restore(side) {
    const prevStateFn = side == "first" ? setFirstCollapsedPrev : setSecondCollapsedPrev;
    const prevStateVal = side == "first" ? firstCollapsedPrev : secondCollapsedPrev;
    if (refRoot.current && refFirst.current && refSecond.current) {
      const restoreElem = side == "first" ? refFirst.current : refSecond.current;
      const shrinkElem = side == "first" ? refSecond.current : refFirst.current;

      restoreElem.style.display = "flex";
      restoreElem.style[styleProp] = prevStateVal + "px";
      shrinkElem.style[styleProp] =
        refRoot.current[clientSize] - prevStateVal - DIVIDER_SIZE + "px";
    }
    prevStateFn(0);
    onResize && onResize();
  }

  // set the initial sizes based on the initial size of first container, or 50-50
  useEffect(() => {
    // these are necessarily true when this runs, but typescript doesn't know that
    if (refFirst.current && refRoot.current && refSecond.current) {
      const elemRoot = refRoot.current;
      const elemFirst = refFirst.current;
      const elemSecond = refSecond.current;
      const rootSize = elemRoot[clientSize];
      let firstSize = toNumberOrPercent(firstInit);
      if (firstSize) {
        if (firstSize < 1) firstSize = firstSize * rootSize; // if firstInit given as percent
      } else {
        firstSize = rootSize / 2;
      }
      elemFirst.style[styleProp] = firstSize + "px";
      elemSecond.style[styleProp] = rootSize - firstSize + "px";

      const onWindowResize = () => {
        // if either panel is collapsed, just change size of other panel
        const firstCollapsed = elemFirst[clientSize] == 0;
        const secondCollapsed = elemSecond[clientSize] == 0;
        if (firstCollapsed) {
          elemSecond.style[styleProp] = elemRoot[clientSize] - DIVIDER_SIZE + "px";
        } else if (secondCollapsed) {
          elemFirst.style[styleProp] = elemRoot[clientSize] - DIVIDER_SIZE + "px";
        } else {
          // use initial width% of first panel to preserve ratio:
          const firstPercent =
            elemFirst[clientSize] / (elemFirst[clientSize] + elemSecond[clientSize]);
          elemFirst.style[styleProp] = elemRoot[clientSize] * firstPercent + "px";
          elemSecond.style[styleProp] = elemRoot[clientSize] * (1 - firstPercent) + "px";
        }
        onResize && onResize();
      };

      window.addEventListener("resize", onWindowResize);

      // Unmount cleanup:
      return () => {
        window.removeEventListener("resize", onWindowResize);
      };
    }
  });

  const firstCollapsed = (firstCollapsedPrev && "collapsed") || "";
  const secondCollapsed = (secondCollapsedPrev && "collapsed") || "";

  return (
    <div ref={refRoot} className={`${classes.root} ${direction}`}>
      <div ref={refFirst} className={`${classes.panel} ${direction} ${firstCollapsed}`}>
        {firstContent}
        {/* <DimensionTest /> */}
      </div>
      <div
        className={`${classes.dividerAnchor} ${direction} ${secondCollapsed} ${firstCollapsed}`}
        onMouseDown={
          secondCollapsed
            ? () => restore("second")
            : firstCollapsed
            ? () => restore("first")
            : event =>
                triggerResizing({
                  event,
                  direction,
                  refRoot,
                  refFirst,
                  refSecond,
                  _firstMin,
                  _secondMin,
                  onResize,
                }) // only resize if neither collapsed
        }
      >
        <div className={`${classes.divider} ${direction}`}></div>
        <div className="collapseBtnScaler">
          <div
            className={`${classes.collapseBtnContainer} ${direction} ${secondCollapsed} ${firstCollapsed}`}
          >
            {!firstCollapsed && (
              <div
                className={classes.collapseBtn}
                title={
                  secondCollapsed
                    ? `Restore ${direction == "vertical" ? "bottom" : "right"} ${secondName}`
                    : `Collapse ${direction == "vertical" ? "top" : "left"} ${firstName}`
                }
                onClick={secondCollapsed ? () => restore("second") : () => collapse("first")}
              >
                <IconChevronLeft />
              </div>
            )}
            {!secondCollapsed && (
              <div
                className={classes.collapseBtn}
                title={
                  firstCollapsed
                    ? `Restore ${direction == "vertical" ? "top" : "left"} ${secondName}`
                    : `Collapse ${direction == "vertical" ? "bottom" : "right"} ${firstName}`
                }
                onClick={firstCollapsed ? () => restore("first") : () => collapse("second")}
              >
                <IconChevronRight />
              </div>
            )}
          </div>
        </div>
      </div>
      <div ref={refSecond} className={`${classes.panel} ${direction} ${secondCollapsed}`}>
        {secondContent}
        {/* <DimensionTest /> */}
      </div>
    </div>
  );
}

export default ResizableSplitPanel;
