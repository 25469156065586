import React from "react";
import * as d3 from "d3";
import { withStyles } from "@material-ui/core/styles";
import autoBind from "auto-bind/react";

const styles = {
  root: {
    backgroundColor: "rgba(66, 66, 66, 0.75)",
    borderRadius: 4,
    border: "1px solid rgba(211,211,211,0.75)",
  },
  detectionBubble: {
    fillOpacity: "0.5",
    stroke: "black",
    strokeWidth: "1.5",
    cursor: "pointer",
  },
  guideline: {
    stroke: "black",
    strokeWidth: 1.5,
    shapeRendering: "crispEdges",
  },
};

/* The breakmap determines the 'breaks' or breakpoints
 * in the legend, based on the size of the largest bubble.
 * The idea is that the breaks should be human-readable
 * and appear somewhat regularly spaced.
 * The map is based only on the first significant
 * figure of the largest size, so it is independent
 * of the order-of-magnitude. */

const breakMap = new Map([
  [1, [1, 0.5, 0.1]],
  [2, [2, 1, 0.2]],
  [3, [3, 1.5, 0.5]],
  [4, [4, 2, 0.5]],
  [5, [5, 2.5, 0.5]],
  [6, [6, 3, 1]],
  [7, [8, 4, 1]],
  [8, [8, 4, 1]],
  [9, [10, 5, 1]],
  [10, [10, 5, 1]],
]);

class SizeLegend extends React.PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.container = React.createRef();
  }

  componentDidMount() {
    this.svg = d3
      .select(this.container.current)
      .append("svg")
      .attr("height", 0)
      .attr("width", 0)
      .style("display", "block");
    this.g = this.svg.append("g");

    setTimeout(() => {
      draw(this.svg, this.g, this.props.bubbleSizeScale, this.props.classes);
      this.updateBubbleColor(this.props.bubbleColor);
    }, 25);
  }

  componentDidUpdate() {
    draw(this.svg, this.g, this.props.bubbleSizeScale, this.props.classes);
    this.updateBubbleColor(this.props.bubbleColor);
  }

  updateBubbleColor(color) {
    this.svg.selectAll("circle").style("fill", color);
  }

  render() {
    return <div className={this.props.classes.root} ref={this.container} />;
  }
}

/* Redraw the legend based on new sizeScale. */

function draw(svg, g, sizeScale, classes) {
  const breaks = getBreaks(sizeScale.domain()[1]);
  const prettyR = breaks.map(sizeScale);
  const maxBubbleRadius = sizeScale.range()[1];

  const legendData = breaks.map(function (d, i) {
    return {
      r: prettyR[i],
      N: breaks[i],
    };
  });

  // BIND DATA

  const legendItems = g.selectAll(".legend-item").data(legendData);

  // ENTER

  const legendEnter = legendItems.enter().append("g").attr("class", "legend-item");

  legendEnter.append("circle").attr("class", classes.detectionBubble);

  const labelEnter = legendEnter.append("g").attr("class", "legend-label");

  labelEnter
    .append("text")
    .attr("text-anchor", "middle")
    .attr("dominant-baseline", "middle")
    .attr("font-size", "8pt")
    .attr("fill", "lightgray")
    .attr("x", maxBubbleRadius + 15);

  labelEnter.append("line").attr("class", classes.guideline);

  // UPDATE

  const legendUpdate = legendEnter.merge(legendItems);
  const labelUpdate = legendUpdate.select(".legend-label");

  labelUpdate.attr("transform", d => `translate(0, ${-d.r * 2})`);

  legendUpdate
    .select("circle")
    .attr("cx", 0)
    .attr("cy", d => -d.r)
    .attr("r", d => d.r);

  labelUpdate.select("text").text(d => d3.format("~s")(d.N));

  labelUpdate
    .select("line")
    .attr("x1", 0)
    .attr("x2", function () {
      return d3.select(this.parentElement).select("text").node().getBBox().x;
    });

  // EXIT

  legendItems.exit().remove();

  // UPDATE SIZE

  /* Want the size of the SVG to fit that of the text
     labels. There is probably a more graceful way to do
     this, but finding the BBox of the main group element
     works with some adjustment to the margins.*/

  const margin = { top: 6, right: 14, bottom: 10, left: 10 };
  const height = g.node().getBoundingClientRect().height;
  const width = g.node().getBoundingClientRect().width;

  svg
    .attr("height", height + margin.top + margin.bottom)
    .attr("width", width + margin.right + margin.left);

  g.attr(
    "transform",
    `translate(
    ${width - maxBubbleRadius * 2 + margin.left},
    ${height + margin.top})`
  );
}

/* Helper function that generates a list of breakpoints
 * for the legend, given the largest N value to be
 * represented in the legend. */

function getBreaks(maxN) {
  if (maxN < 3) {
    return [maxN];
  }

  const magnitude = Math.floor(Math.log10(maxN));
  const sigfig = Math.round(maxN / 10 ** magnitude);
  return breakMap
    .get(sigfig)
    .map(d => Math.round(d * 10 ** magnitude))
    .filter(d => d > 0);
}

export default withStyles(styles)(SizeLegend);
