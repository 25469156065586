import { useRef } from "react";
import classNames from "classnames";

import * as d3 from "d3";
import { makeStyles } from "@material-ui/core/styles";

import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import Chart, { FileData } from "./Chart";
import XAxisSandwich from "./../common/XAxisSandwich";
import Overlay from "./Overlay";
import Resizable from "../common/Resizable";
import { RxSeriesDatum, RxSeriesCategory, RxSeriesType } from "../../redux/rxdiag/rx-diag-types";
import { MARGIN } from "./constants";
const X_AXIS_HEIGHTS: [number, number] = [20, 55];
const MIN_CHART_HEIGHT = 80;
const Y_AXIS_PADDING = 9; // distance from the top/bottom of chart to first/last axis ticks
export const GAP_BETWEEN_CHARTS = 5;

const useStyles = makeStyles({
  main: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  scrollPanel: {
    height: "300px",
  },
  overlayContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    left: MARGIN.left,
    cursor: "crosshair",
  },
  eventsBackground: {
    background: "lightgray",
    position: "absolute",
    height: "100%",
    left: MARGIN.left,
    right: 0,
  },
  chartBackground: {
    position: "absolute",
    left: MARGIN.left,
    right: 0,
    height: "100%",
    cursor: "crosshair",
  },
  chartBorder: {
    position: "absolute",
    left: MARGIN.left,
    right: 0,
    height: "100%",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
    boxSizing: "border-box",
  },
  last: {
    borderBottom: "none",
  },
  loadingMessage: {
    position: "absolute",
    marginLeft: MARGIN.left,
  },
  chartsWrapper: {
    width: "100%",
    position: "relative",
  },
  graphingArea: {
    display: "flex",
    flexDirection: "column",
    rowGap: GAP_BETWEEN_CHARTS,
  },
});

export type ChartListSeries = {
  yVariable: string;
  type: RxSeriesType;
  category: RxSeriesCategory;
  countPeriod: number | null;
  dataByFile: FileData[];
};

type Props = {
  height: number;
  width: number;
  seriesByYVar: ChartListSeries[];
  events: any[];
  fullExtent: Date[] | null;
  serialColorScale: d3.ScaleOrdinal<string, string>;
  selectedOffset: string;
  timeOffsetMs: number;
  showNoiseGuide?: boolean;
  highlightedFileNames?: string[];
  zoomSelectionUtc: Date[] | null;
  setZoomSelection: (value: Date[] | null) => any;
};

function ChartList({
  height,
  width,
  seriesByYVar,
  events,
  fullExtent,
  serialColorScale,
  selectedOffset,
  timeOffsetMs,
  showNoiseGuide = false,
  highlightedFileNames,
  zoomSelectionUtc,
  setZoomSelection,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const zoomSelection = zoomSelectionUtc
    ? zoomSelectionUtc.map(t => applyOffset(t, timeOffsetMs))
    : null;

  const nCharts = seriesByYVar.length || (events.length && 1);

  const innerChartWidth = width - MARGIN.left - MARGIN.right;

  const availInnerHeight = height - d3.sum(X_AXIS_HEIGHTS); // the total height available for the charts, excluding the shared X-axes
  const chartHeight = computeChartHeight(availInnerHeight, nCharts); // the height of each chart
  const fullInnerHeight = chartHeight * nCharts + GAP_BETWEEN_CHARTS * (nCharts - 1); // the full height of the content between the x-axes
  const scrollHeight = Math.min(fullInnerHeight, availInnerHeight); // the height of the scrolling container -- will only scroll if fullInnerHeight > availInnerHeight

  const yScales: d3.ScaleLinear<number, number>[] = [];
  const yTickValues: number[][] = [];
  seriesByYVar.forEach(series => {
    const { dataByFile, countPeriod, type } = series;
    const res = createYScale(
      dataByFile,
      chartHeight,
      zoomSelection,
      countPeriod,
      type,
      showNoiseGuide
    );
    if (res) {
      yScales.push(res.yScale);
      yTickValues.push(res.tickValues);
    }
  });

  const xScale = d3
    .scaleUtc()
    .range([0, innerChartWidth])
    .domain(zoomSelection || fullExtent || [NaN, NaN]);

  const zoomHandler = (sel: number[] | null) => {
    setZoomSelection(sel && sel.map(xScale.invert).map(t => applyOffset(t, -timeOffsetMs)));
  };

  return (
    <div className={classes.main} ref={ref}>
      <XAxisSandwich
        xScale={xScale}
        xAxisHeights={X_AXIS_HEIGHTS}
        marginLeft={MARGIN.left}
        label={`Time (${selectedOffset})`}
        hidden={!(seriesByYVar.length || events.length)}
      >
        <div style={{ height: scrollHeight }}>
          <ScrollBar>
            <div className={classes.graphingArea}>
              {seriesByYVar.map((series, i) => {
                const backgroundColor = i % 2 === 0 ? "lightgray" : "lightsteelblue";

                const key = series.yVariable;
                const last = i === seriesByYVar.length - 1;

                return (
                  <div key={key} style={{ height: chartHeight }} className={classes.chartsWrapper}>
                    <div
                      className={classNames(classes.chartBackground)}
                      style={{ backgroundColor }}
                    />

                    {yScales[i] && (
                      <Chart
                        yVariable={series.yVariable}
                        xScale={xScale}
                        yScale={yScales[i]}
                        yTickValues={yTickValues[i]}
                        dataByFile={series.dataByFile}
                        height={chartHeight}
                        category={series.category}
                        seriesType={series.type}
                        serialColorScale={serialColorScale}
                        showNoiseGuide={series.type === "noise" && showNoiseGuide}
                        highlightedFileNames={highlightedFileNames}
                      />
                    )}

                    <div className={classNames(classes.chartBorder, { [classes.last]: last })} />
                  </div>
                );
              })}
            </div>

            {seriesByYVar.length < 1 && events.length > 0 && (
              <div className={classes.eventsBackground} />
            )}

            <div
              className={classes.overlayContainer}
              style={{ height: fullInnerHeight, left: MARGIN.left }}
            >
              <Overlay
                seriesByYVal={seriesByYVar}
                events={events}
                xScale={xScale}
                yScales={yScales}
                serialColorScale={serialColorScale}
                zoomHandler={zoomHandler}
                chartHeight={chartHeight}
                totalHeight={fullInnerHeight}
                width={width}
              />
            </div>
          </ScrollBar>
        </div>
      </XAxisSandwich>
    </div>
  );
}

// Compute the height of each chart, based on the amount of vertical space available, but also
// ensuring that the height of the chart is at least MIN_CHART_HEIGHT. If there is not enough
// vertical space available, then the total height of the charts and margins will exceed
// availInnerHeight, and this will need to be handled in the final display via scrolling.
function computeChartHeight(availInnerHeight: number, nCharts: number) {
  if (nCharts === 0) return 0;
  const marginTotal = (nCharts - 1) * GAP_BETWEEN_CHARTS; // the total vertical space needed for the margins between the charts
  const availableChartHeight = availInnerHeight - marginTotal; // the total height available for the charts, excluding the margins
  const chartHeight = Math.floor(availableChartHeight / nCharts); // divide up the available height and round down to nearest pixel
  return Math.max(chartHeight, MIN_CHART_HEIGHT); // make sure the height is at least MIN_CHART_HEIGHT
}

function createYScale(
  dataByFile: { filename: string; data: RxSeriesDatum[] }[],
  height: number,
  zoomSelection: Date[] | null,
  countPeriod: number | null,
  seriesType: RxSeriesType,
  showNoiseGuide: boolean
) {
  if (!dataByFile) {
    return null;
  }

  const yExtentsByLog = dataByFile.reduce((acc, { data }) => {
    const extentMaybe = getYExtent(data, zoomSelection, countPeriod);
    return extentMaybe ? [...acc, extentMaybe] : acc;
  }, []);

  if (yExtentsByLog.length === 0) {
    return null;
  }

  const yExtent = yExtentsByLog.reduce((acc, extent) => {
    return [Math.min(acc[0], extent[0]), Math.max(acc[1], extent[1])];
  });

  let yDomain = yExtent;

  if (seriesType === "tilt") {
    yDomain = [0, Math.max(yDomain[1], 90)];
  }

  const yScale = d3.scaleLinear().range([height, 0]).domain(yDomain);
  const yBoundary = yScale.ticks(4).concat([300, 650]);

  const tickValues =
    seriesType === "tilt"
      ? [0, 45, 90, 135, 180]
      : seriesType === "noise" && showNoiseGuide
      ? [...new Set(yBoundary)]
      : yScale.ticks(4);

  // extend the y domain to provide padding between the first/last ticks and the top/bottom of chart
  // if all y values are identical, just use dummy buffer value "1"
  const buffer =
    yDomain[0] === yDomain[1]
      ? 1
      : Y_AXIS_PADDING / ((yScale(0) as number) - (yScale(1) as number)); // update to

  const finalDomain = [yDomain[0] - buffer, yDomain[1] + buffer];

  if (seriesType === "depth") {
    finalDomain.reverse();
  }

  yScale.domain(finalDomain);

  return { yScale, tickValues };
}

function getYExtent(
  data: RxSeriesDatum[],
  zoomSelection: Date[] | null,
  countPeriod: number | null
): number[] | null {
  const displayedData = zoomSelection
    ? data.filter(d => {
        // eslint-disable-next-line prefer-const
        let [start, end] = zoomSelection;
        /* If the series is a binned count, expand the start of the displayed data one time period earlier,
         * so that that bin will be used to determine the y extent as well. */
        if (countPeriod) {
          start = new Date(start.getTime() - countPeriod);
        }
        return start < d.x && d.x < end;
      })
    : data;

  const extentMaybe = d3.extent(displayedData, d => d.y);

  return extentMaybe[0] === undefined ? null : extentMaybe;
}

function applyOffset(t: Date, offsetMs: number): Date {
  return new Date(t.getTime() + offsetMs);
}

export default Resizable(ChartList);
