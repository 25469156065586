import React from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import * as d3 from "d3";
import { rollup } from "d3-array";
import { withStyles } from "@material-ui/core/styles";

import BubbleMap from "./components/BubbleMap";
import TimelinePanel from "./components/TimelinePanel";
import SizeLegend from "./components/SizeLegend";
import MainPanel from "../MainPanel";
import SpinnerInnovasea from "../SpinnerInnovasea";
import CenterDialog from "../CenterDialog";

import { finalizeData } from "./helpers";
import { findClosest, floorHours, ceilHours } from "../../helpers/common";
import { HOUR_IN_MS } from "../../../helpers/time";
import BubblePlotSideBar from "./components/BubblePlotSideBar";

const styles = {
  legendContainer: {
    position: "absolute",
    top: 5,
    left: 5,
    zIndex: "3",
    pointerEvents: "none",
  },
};

// number of bins to aim for on the timeline (used to choose bin size)
const TARGET_BINS = 250;

const defaultMapStyle = "mapbox://styles/mapbox/satellite-v9";
const maxBubbleRadius = 23;
const timeRangeClearedTransDuration = 150;

class BubblePlot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      zoomTimeRange: null,
      brushTimeRange: null,
      timeBrushJustCleared: false,
      bubbleColor: "#B80000",
      mapStyle: defaultMapStyle,
      idSelection: [],
      onlyUserAnimals: false,
    };

    this.timeZoomToBrush = this.timeZoomToBrush.bind(this);
    this.resetTimeZoom = this.resetTimeZoom.bind(this);
    this.brushTimeRangeHandler = this.brushTimeRangeHandler.bind(this);
    this.colorHandler = this.colorHandler.bind(this);
    this.mapStyleHandler = this.mapStyleHandler.bind(this);
    this.idHandler = this.idHandler.bind(this);
    this.handleOnlyAnimalSwitch = this.handleOnlyAnimalSwitch.bind(this);
    this.getFinalizedData = this.getFinalizedData.bind(this);

    this.finalizeData = memoize(finalizeData);
  }

  componentDidMount() {
    this.initTagIdSelection();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.initTagIdSelection();
    }
  }

  initTagIdSelection() {
    const data = this.getFinalizedData();

    if (data) {
      const idSelection = data.detectionsPerId.map(d => d.id);
      const onlyUserAnimals = this.props.animalIds.length > 0;

      this.setState({
        idSelection: idSelection,
        onlyUserAnimals: onlyUserAnimals,
      });
    }
  }

  // Handler callbacks

  timeZoomToBrush() {
    this.setState(state => ({
      zoomTimeRange: state.brushTimeRange,
      brushTimeRange: null,
    }));
  }

  resetTimeZoom() {
    this.setState({ zoomTimeRange: null });
  }

  brushTimeRangeHandler(brushTimeRange) {
    this.setState({ brushTimeRange });
  }

  colorHandler(color) {
    this.setState({ bubbleColor: color.hex });
  }

  mapStyleHandler(mapStyle) {
    this.setState({ mapStyle });
  }

  idHandler(selection) {
    this.setState({ idSelection: selection });
  }

  handleOnlyAnimalSwitch(e) {
    this.setState({ onlyUserAnimals: e.target.checked });
  }

  getFinalizedData() {
    return this.finalizeData(this.props.data, this.props.minBinSize);
  }

  render() {
    const {
      onlyUserAnimals,
      idSelection,
      zoomTimeRange,
      brushTimeRange,
      mapStyle,
      bubbleColor,
      timeBrushJustCleared,
    } = this.state;
    const {
      dataStatus,
      classes,
      animalIds,
      singleTagMode,
      customError,
      offsetMinutes,
      demoMode,
      handleBinSizeClicked,
      minBinSize,
    } = this.props;

    const data = this.getFinalizedData();

    const tagList =
      !singleTagMode && data
        ? onlyUserAnimals
          ? filterTagList(data.detectionsPerId, animalIds)
          : data.detectionsPerId
        : [];

    // ALWAYS: generate the sidebar, no matter the state of the data
    const sidebar = (
      <BubblePlotSideBar
        demoMode={demoMode}
        singleTagMode={singleTagMode}
        tagList={tagList}
        idSelection={idSelection}
        onlyUserAnimals={onlyUserAnimals}
        mapStyle={mapStyle}
        mapStyleHandler={this.mapStyleHandler}
        animalIds={animalIds}
        dataAvailable={Boolean(data)}
        colorHandler={this.colorHandler}
        idHandler={this.idHandler}
        handleOnlyAnimalSwitch={this.handleOnlyAnimalSwitch}
        handleBinSizeClicked={handleBinSizeClicked}
        minBinSize={minBinSize}
      />
    );

    // Custom Error can be injected manually by the client application (ie. Fathom Central or Live)
    if (customError) {
      return (
        <>
          {sidebar}
          <CenterDialog>{customError}</CenterDialog>
        </>
      );
    }

    // DATA NOT YET LOADED: just show the sidebar and the spinner
    if (dataStatus === "unloaded" || dataStatus === "loading") {
      return (
        <>
          {sidebar}
          <SpinnerInnovasea text={"Fetching your detections"} />
        </>
      );
    }

    // ERROR LOADING DATA: just show the sidebar and the error message
    if (dataStatus === "error") {
      return (
        <>
          {sidebar}
          <CenterDialog>
            {"Oops! There was an error generating your detection map. Please try again later."}
          </CenterDialog>
        </>
      );
    }

    // DATA LOADED: compute everything necessary to generate the bubble chart

    // calculate defaults (memoized: only recomputed if data changes)
    const binSizes = data.detCountsByBinSize.binSizes;
    const hourlyDetCount = data.detCountsByBinSize.get(binSizes[0]);
    const { defaultTimeRange, defaultBinSize } = getDefaults(hourlyDetCount, binSizes);

    // choose bin size based on zoomed time extent
    const binSize = zoomTimeRange ? chooseBinSize(zoomTimeRange, binSizes) : defaultBinSize;
    // extract det-count for selected bin size
    const fullDetCount = data.detCountsByBinSize.get(binSize);
    // Filter det-count based on time line zoom
    const zoomDetCountAll = filterTimeZoom(fullDetCount, zoomTimeRange);
    // Filter det-count based on displayed tag ids
    const zoomDetCount = singleTagMode
      ? zoomDetCountAll
      : filterIds(zoomDetCountAll, idSelection, onlyUserAnimals && animalIds);
    // Generate the bubble size scale based on the time range and selected ids
    const bubbleSizeScale = createBubbleSizeScale(zoomDetCount);
    // Sum the detections by period (for the timeline)
    const detCountByPeriod = sumByPeriod(zoomDetCount);
    // Filter det-count based on selected time range (either brush or if no brush, time line zoom)
    const selectedDetCount = filterTimeRange(zoomDetCount, brushTimeRange);
    // Sum the detections by deployment (for the bubble sizes)
    const detCountByDeployment = sumByDeployment(selectedDetCount);
    // Create function that computes # unique detections given deployment id
    const idCounter = singleTagMode ? null : createIdCounter(selectedDetCount);
    // Convert time offset in minutes to ms
    const timeOffset = (offsetMinutes || 0) * 60000;

    return (
      <>
        {sidebar}

        <MainPanel>
          {bubbleSizeScale && (
            <div className={classes.legendContainer}>
              <SizeLegend
                bubbleSizeScale={bubbleSizeScale}
                bubbleColor={bubbleColor}
                mapStyle={mapStyle}
              />
            </div>
          )}
          <TimelinePanel
            detCount={detCountByPeriod}
            zoomTimeRange={zoomTimeRange || defaultTimeRange}
            zoomInHandler={this.timeZoomToBrush}
            resetZoomHandler={this.resetTimeZoom}
            brushTimeRangeHandler={this.brushTimeRangeHandler}
            binSize={binSize}
            timeOffset={timeOffset}
          />
        </MainPanel>

        <BubbleMap
          deployments={data.deployments}
          detCountMap={detCountByDeployment}
          idCounter={idCounter}
          bubbleColor={bubbleColor}
          bubbleSizeScale={bubbleSizeScale}
          mapStyle={mapStyle}
          transDuration={timeBrushJustCleared ? timeRangeClearedTransDuration : 0}
        />
      </>
    );
  }
}

const filterIds = memoize(function (detCount, idSelection, animalIds) {
  const displayedIds = animalIds ? idSelection.filter(id => animalIds.includes(id)) : idSelection;
  // Filter detection count records to keep only records for ids in selection
  return detCount.filter(d => displayedIds.includes(d.fullid));
});

function filterTimeRange(detCount, timeRange) {
  // Filter detection count records to keep only records that lie inside the timeRange
  return timeRange
    ? detCount.filter(d => d.period >= timeRange[0] && d.period <= timeRange[1])
    : detCount;
}

const filterTimeZoom = memoize(filterTimeRange);

function sumByDeployment(detCount) {
  /* Add up detection counts of all records by deployment.
   * I.e. combine detection counts for all periods & ids within each deployment.
   * Return: Map from deployment to count. */
  return rollup(
    detCount,
    v => d3.sum(v, v => v.N),
    d => d.deployment
  );
}

function getUniqueIds(detCount) {
  // Find all unique fullids in a set of detCount records
  return [...new Set(detCount.map(d => d.fullid))];
}

const createIdCounter = detCount => {
  /* Create a function that returns the number of unique fullids detected at a given deployment,
   * based on a given set of detection count records. */
  return deployment => getUniqueIds(detCount.filter(d => d.deployment === deployment)).length;
};

const filterTagList = memoize(function (tagList, ids) {
  return tagList.filter(tag => ids.includes(tag.id));
});

const sumByPeriod = memoize(function (detCount) {
  const map = rollup(
    detCount,
    v => d3.sum(v, d => d.N),
    d => d.period.getTime()
  );
  return Array.from(map, ([key, val]) => ({ period: new Date(key), N: val }));
});

const createBubbleSizeScale = memoize(function (detCount) {
  if (detCount.length === 0) {
    return null;
  }

  const fullDetCountByDeployment = sumByDeployment(detCount);
  const nMax = Math.max(...fullDetCountByDeployment.values());

  return d3.scaleSqrt().domain([0, nMax]).range([0, maxBubbleRadius]);
});

export const chooseBinSize = memoize(function (timeRange, availSizes) {
  // select the bin size that gives a number of bins closest to the target
  const duration = (timeRange[1].getTime() - timeRange[0].getTime()) / HOUR_IN_MS;
  const x = availSizes.map(d => d * TARGET_BINS);
  const i = findClosest(x, duration);
  return availSizes[i];
});

export const getDefaults = memoize(function (hourlyDetCount, availSizes) {
  // use the extent of the hourly data to determine the default bin size
  const [firstHourStart, lastHourStart] = d3.extent(hourlyDetCount, d => d.period);
  const lastHourEnd = new Date(lastHourStart.getTime() + HOUR_IN_MS);
  const defaultBinSize = chooseBinSize([firstHourStart, lastHourEnd], availSizes);

  // find the precise full time range for the default bin size
  const defaultTimeRange = [
    floorHours(firstHourStart, defaultBinSize),
    ceilHours(lastHourEnd, defaultBinSize),
  ];

  return {
    defaultTimeRange: defaultTimeRange,
    defaultBinSize: defaultBinSize,
  };
});

BubblePlot.propTypes = {
  data: PropTypes.shape({
    deployments: PropTypes.array,
    hourlyDetCount: PropTypes.array,
  }),
  dataStatus: PropTypes.string,
  demoMode: PropTypes.bool,
  animalIds: PropTypes.arrayOf(PropTypes.string),
  customError: PropTypes.any,
  offsetMinutes: PropTypes.number,
  handleBinSizeClicked: PropTypes.func,
  minBinSize: PropTypes.number,
};

export default withStyles(styles)(BubblePlot);
