import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
const styles = theme => ({
  header: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

class LogoHeader extends PureComponent {
  render() {
    const { classes, logoUrl, title } = this.props;
    return (
      <div className={classes.header}>
        <img alt="" src={logoUrl} width="200"></img>
        <Typography style={{ marginTop: 20 }} variant="h5">
          {title}
        </Typography>
      </div>
    );
  }
}

LogoHeader.propTypes = {
  logoUrl: PropTypes.string,
  title: PropTypes.string,
};

export default withStyles(styles)(LogoHeader);
