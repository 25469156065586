import { MenuItem, TextField } from "@material-ui/core";

export type WorkspaceItem = {
  id: string;
  name: string;
};

type Props = {
  workspaces: WorkspaceItem[];
  selectedWorkspace: string;
  setSelectedWorkspace: (workspaceId: string) => void;
};

export function WorkspaceSelector({ workspaces, selectedWorkspace, setSelectedWorkspace }: Props) {
  return (
    <TextField
      select
      label="Select Workspace"
      placeholder="Select a workspace to import the devices to"
      value={selectedWorkspace}
      onChange={event => setSelectedWorkspace(event.target.value)}
      fullWidth
    >
      {workspaces.map(workspace => (
        <MenuItem key={workspace.id} value={workspace.id}>
          {workspace.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
