import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  TextField,
  Popover,
  List,
  ListItem,
  Typography,
  Divider,
  Select,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add as IconAdd, Close as IconClose, Check as IconCheck } from "@material-ui/icons";
import StationListItem from "./StationListItem";
import {
  addStation,
  updateStation,
  deleteStation,
} from "../../redux/deployments/deployments-actions";
import { ifEnterOrEsc } from "../../helpers/input";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  addContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addListItem: {
    padding: theme.spacing(1),
    paddingTop: 0,
  },
  addText: {
    marginLeft: theme.spacing(1),
  },
  selectText: {
    color: "grey",
  },
}));

function StationPicker({ stations = [], handleInput, selectedId, dispatch }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [addMode, setAddMode] = useState(false);
  const [hoverId, setHoverId] = useState(null);
  const [editId, setEditId] = useState(null);

  const handleClick = event => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAddMode(false);
    setAnchorEl(null);
  };

  const stationClicked = station => {
    handleInput(station.id);
    handleClose();
  };

  const clearStation = () => {
    handleInput(null);
    handleClose();
  };

  const editClicked = stationId => {
    setEditId(stationId);
  };

  const stationHovered = station => {
    setHoverId(station.id);
  };

  function handleStationAdd() {
    const value = document.getElementById("station-add-val").value;
    if (value) {
      dispatch(addStation({ name: value })).then(station => {
        handleInput(station?.id);
        handleClose();
      });
    }
    setAddMode(false);
  }

  const selectedStation = stations && stations.find(station => station.id === selectedId);

  const open = Boolean(anchorEl);
  return (
    <div className={classes.root}>
      <Select
        onClick={handleClick}
        value={""}
        style={{ minWidth: 185 }}
        open={false}
        renderValue={() =>
          selectedStation ? (
            selectedStation.name
          ) : (
            <div className={classes.selectText}>No Station</div>
          )
        }
        displayEmpty
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List dense style={{ minWidth: 250 }}>
          {!addMode ? (
            <ListItem button onClick={() => setAddMode(true)}>
              <IconAdd className={classes.IconAdd} />
              <Typography className={classes.addText}>New Station</Typography>
            </ListItem>
          ) : (
            <ListItem className={classes.addListItem}>
              <div className={classes.addContainer}>
                <TextField
                  size="small"
                  fullWidth
                  autoFocus
                  id="station-add-val"
                  onKeyDown={keyEvent =>
                    ifEnterOrEsc({
                      keyEvent,
                      onEnter: handleStationAdd,
                      onEsc: () => setAddMode(false),
                    })
                  }
                />
                <div className={classes.buttonContainer}>
                  <IconButton size="small" onClick={handleStationAdd}>
                    <IconCheck />
                  </IconButton>
                  <IconButton size="small" onClick={() => setAddMode(false)}>
                    <IconClose />
                  </IconButton>
                </div>
              </div>
            </ListItem>
          )}

          <Divider />
          <ListItem button onClick={clearStation}>
            <Typography fontStyle="italic">No Station</Typography>
          </ListItem>
          <Divider />

          {stations
            .sort((a, b) => a.name && b.name && a.name.localeCompare(b.name))
            .map(s => (
              <ListItem button key={s.id} value={s.name} onMouseOver={() => stationHovered(s)}>
                <StationListItem
                  station={s}
                  hoverId={hoverId}
                  editId={editId}
                  stationClicked={stationClicked}
                  editClicked={editClicked}
                  updateStation={input => dispatch(updateStation(input))}
                  deleteStation={input => dispatch(deleteStation(input))}
                />
              </ListItem>
            ))}
        </List>
      </Popover>
    </div>
  );
}

StationPicker.propTypes = {
  stations: PropTypes.arrayOf(PropTypes.object),
  selectedId: PropTypes.string,
  handleInput: PropTypes.func,
};

export default connect()(StationPicker);
