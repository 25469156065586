import {
  STUDY_SET_LIST,
  STUDY_SET_SELECTED,
  STUDY_NOT_LOADED,
  STUDY_UPDATE_EXPORT_STATUS,
  STUDY_SHOW_EXPORT_DETAILS,
  STUDY_HIDE_EXPORT_DETAILS,
  STUDY_UPDATE,
  ALL_DATA_SET_LIST,
  STUDY_SET_ONE,
  STUDY_LINKING_DONE,
  STUDY_LINKING_IN_PROGESS,
} from "../action-types";

const initialState = {
  isLoaded: false,
  studies: [],
  selectedId: null, // the id of the currently selected study
  exports: {},
  showExportDetailsId: null, // the exported studyId to show details for
  newStudyId: null,
  filesCount: 0,
  devicesCount: 0,
  animalsCount: 0,
  deploymentsCount: 0,
  linkingInProgress: false,
};

const studyReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STUDY_SET_LIST: {
      // keep in consistent order by id
      const sortedStudies = action.payload.studies.sort((s1, s2) => (s1.id > s2.id ? 1 : -1)) || [];
      state = {
        ...state,
        isLoaded: true,
        studies: sortedStudies,
      };
      return state;
    }

    case STUDY_UPDATE: {
      const studiesCpy = [...state.studies];
      const studyIndex = state.studies.findIndex(s => s.id === action.payload.studyId);
      studiesCpy[studyIndex] = { ...studiesCpy[studyIndex], ...action.payload };
      state = {
        ...state,
        studies: studiesCpy,
      };
      return state;
    }

    case STUDY_SET_SELECTED:
      return { ...state, selectedId: action.payload.selectedId };

    case STUDY_NOT_LOADED: {
      return { ...state, isLoaded: false };
    }

    case STUDY_UPDATE_EXPORT_STATUS: {
      const exports = { ...state.exports };
      const { studyId } = action.payload;
      exports[studyId] = { ...exports[studyId], ...action.payload };
      state = { ...state, exports };
      return state;
    }

    case STUDY_SHOW_EXPORT_DETAILS:
      return { ...state, showExportDetailsId: action.payload.studyId };

    case STUDY_HIDE_EXPORT_DETAILS: {
      // This removes the export details from state as well
      const exports = { ...state.exports };
      delete exports[action.payload.studyId];
      return { ...state, showExportDetailsId: null, exports };
    }

    case ALL_DATA_SET_LIST: {
      return {
        ...state,
        filesCount: action.payload.filesCount,
        devicesCount: action.payload.devicesCount,
        animalsCount: action.payload.animalsCount,
        deploymentsCount: action.payload.deploymentsCount,
      };
    }

    case STUDY_SET_ONE: {
      const { study } = action.payload;
      const studies = [...state.studies];
      const i = studies.findIndex(s => s.id === study.id);
      if (i < 0) {
        studies.push(study);
      } else {
        studies[i] = study;
      }

      return { ...state, studies };
    }

    case STUDY_LINKING_IN_PROGESS: {
      return {
        ...state,
        linkingInProgress: true,
      };
    }

    case STUDY_LINKING_DONE: {
      return {
        ...state,
        linkingInProgress: false,
      };
    }

    default:
      return state;
  }
};

export default studyReducer;
