import { Component } from "react";
import DialogWrapper from "./DialogWrapper";
import autoBind from "auto-bind";
import { Typography } from "@material-ui/core";

class HelpingDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: "Help",
    };
    autoBind(this);
  }

  render() {
    const { open } = this.props;

    return (
      <DialogWrapper
        open={open}
        title={"Need Help? We're Here for You."}
        okButtonContent={"Dismiss"}
        okAction={() => this.props.onClose()}
      >
        <Typography paragraph>
          Visit our comprehensive{" "}
          <a href="https://support.fishtracking.innovasea.com/s/">Support Portal</a> to access
          helpful articles, guides, and troubleshooting tips.
        </Typography>

        <Typography paragraph>
          Prefer to contact us directly? You can email our support team at{" "}
          <a href="mailto:support.team@innovasea.com">support.team@innovasea.com</a>, and we'll get
          back to you as soon as possible.
        </Typography>
      </DialogWrapper>
    );
  }
}

export default HelpingDialog;
