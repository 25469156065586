import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SpinnerInnovasea } from "../../fathom-brella";
import { listWorkspaces, selectWorkspace } from "../../redux/workspace/workspace-actions";
import { snackbarError } from "../../redux/snackbar/snackbar-actions";

function WorkspaceProvider({ children }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const storedWorkspaceId = localStorage.getItem("workspaceId");
  const { workspacesList, workspacesLoaded, workspaceSelected, workspacesQueryFailed } =
    useSelector(({ workspaces }) => ({
      workspacesList: workspaces.workspaces,
      workspacesLoaded: !workspaces.loading,
      workspaceSelected: workspaces.selectedWorkspace,
      workspacesQueryFailed: workspaces.failed,
    }));

  useEffect(() => {
    dispatch(listWorkspaces());
  }, [dispatch]);

  useEffect(() => {
    if (workspacesList.length === 0) {
      return;
    }
    if (storedWorkspaceId && workspacesList.some(w => w.id === storedWorkspaceId)) {
      // Stored workspace found and valid
      dispatch(selectWorkspace(storedWorkspaceId));
    } else {
      // No stored workspace or stored workspace invalid
      const personalWorkspace = workspacesList.find(w => w.isPersonal);
      if (personalWorkspace) {
        dispatch(selectWorkspace(personalWorkspace.id));
      } else {
        dispatch(snackbarError("No personal workspace found"));
      }
    }
  }, [dispatch, workspacesList, storedWorkspaceId]);

  if (workspacesQueryFailed == true) {
    return children; // allow downstream handling of network failure
  }
  const workspaceMode = history.location.pathname.startsWith("/workspaces") ? true : false;
  const workspacesReady = workspacesLoaded && (workspaceMode || workspaceSelected);

  return workspacesReady ? children : <SpinnerInnovasea />;
}

export default WorkspaceProvider;
