import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { addToStudy } from "../redux/study/study-actions";

import DialogWrapper from "../components/common/DialogWrapper";
import MenuBar from "../components/common/MenuBar";
import { TableSearchBar } from "../fathom-brella";
import DevicesTable from "../components/devices/DevicesTable";
import FilesTable from "../components/files/FilesTable";
import AnimalsTable from "../components/animals/AnimalsTable";
import DeploymentTable from "../components/deployments/DeploymentTable";

import { Typography } from "@material-ui/core";

function StudyLinkDialog({
  open,
  handleClose,
  objectType,
  objectTypeLabel,
  selectedStudyId,
  dispatch,
  devices,
  files,
  animals,
  deployments,
}) {
  const [selection, setSelection] = useState([]);
  const [searchText, setSearchText] = useState("");

  if (!open) {
    return null;
  }

  function submitAddToStudy() {
    dispatch(addToStudy({ studyId: selectedStudyId, [objectType]: selection }, true));
    handleClose();
  }

  return (
    <DialogWrapper
      open={open}
      maxWidth="md"
      title={`Link ${objectTypeLabel}(s) From Workspace to Current Study`}
      okAction={submitAddToStudy}
      okButtonContent="Link"
      okDisabled={!selection.length}
      cancelAction={handleClose}
      onBackdropClick={handleClose}
    >
      <Typography style={{ marginBottom: 16 }}>
        Please select the {objectTypeLabel}(s) from the current Workspace to link to the current
        Study:
      </Typography>
      {objectType !== "deviceIds" ? (
        <MenuBar
          paddingLevel={0}
          contentRight={<TableSearchBar searchText={searchText} handleSearch={setSearchText} />}
        />
      ) : null}
      <div style={{ height: 400 }}>
        {objectType === "deviceIds" ? (
          <DevicesTable devices={devices} selection={selection} setSelection={setSelection} />
        ) : objectType === "filePaths" ? (
          <FilesTable
            files={files}
            selection={selection}
            setSelection={setSelection}
            searchText={searchText}
          />
        ) : objectType === "animalIds" ? (
          <AnimalsTable
            animals={animals}
            loading={!animals}
            selection={selection}
            setSelection={setSelection}
            searchText={searchText}
          />
        ) : objectType === "deploymentIds" ? (
          <DeploymentTable
            deployments={deployments}
            selection={selection}
            setSelection={setSelection}
            editable={false}
            searchText={searchText}
          />
        ) : null}
      </div>
    </DialogWrapper>
  );
}

StudyLinkDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  objectType: PropTypes.oneOf(["deviceIds", "animalIds", "deploymentIds", "filePaths"]),
  objectTypeLabel: PropTypes.string.isRequired,
};

const mapStateToProps = ({ study, devices, deployments, animals, files }) => {
  const selectedStudy = study.selectedId
    ? study.studies.find(s => s.id === study.selectedId)
    : null;

  // To minimize array iteration, remove the relevant objects rather than filtering:
  const notLinked = {
    devices: (devices.devices && [...devices.devices]) || [],
    files: (files.fileList && [...files.fileList]) || [],
    animals: (animals.animals && [...animals.animals]) || [],
    deployments: (deployments.deployments && [...deployments.deployments]) || [],
  };

  selectedStudy?.devices?.forEach(sd => {
    const i = notLinked.devices.findIndex(d => d.id === sd.id);
    if (i !== -1) {
      notLinked.devices.splice(i, 1);
    }
  });
  selectedStudy?.files?.forEach(sf => {
    const i = notLinked.files.findIndex(f => f.name === sf.name);
    if (i !== -1) {
      notLinked.files.splice(i, 1);
    }
  });
  selectedStudy?.animals?.forEach(sa => {
    const i = notLinked.animals.findIndex(a => a.id === sa.id);
    if (i !== -1) {
      notLinked.animals.splice(i, 1);
    }
  });
  selectedStudy?.deployments?.forEach(sd => {
    const i = notLinked.deployments.findIndex(d => d.id === sd.id);
    if (i !== -1) {
      notLinked.deployments.splice(i, 1);
    }
  });

  return {
    devices: notLinked.devices,
    files: notLinked.files,
    animals: notLinked.animals,
    deployments: notLinked.deployments,
  };
};

export default connect(mapStateToProps)(StudyLinkDialog);
