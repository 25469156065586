import React, { Component } from "react";
import autoBind from "auto-bind";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import PasswordChange from "./PasswordChange";
import LogoHeader from "./LogoHeader";
import ErrorBox from "./ErrorBox";

class PasswordChangeCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "signIn", // || changePassword
      newPassword: "",
      newPasswordValid: false,
    };
    autoBind(this);
  }

  render() {
    const { onSubmit, onCancel, style, loading, errorMsg } = this.props;
    const { newPasswordValid, newPassword } = this.state;
    return (
      <Card elevation={4} style={{ width: 300, ...style }}>
        <CardContent>
          <LogoHeader title="Change Password" logoUrl="img/fathom-central.png" />
          <Typography style={{ fontSize: 13 }}>
            A password change is required on first log in
          </Typography>
          <form
            onSubmit={e => {
              e.preventDefault();
              onSubmit(newPassword);
            }}
          >
            <PasswordChange
              onChange={(valid, password) => {
                this.setState({
                  newPassword: password,
                  newPasswordValid: valid,
                });
              }}
            />
            <ErrorBox style={{ maxWidth: 230 }} hidden={Boolean(errorMsg)}>
              {errorMsg}
            </ErrorBox>
            <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
              {loading && <CircularProgress size={20} />}
              <Button onClick={onCancel}> Cancel </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!newPasswordValid}
                type="submit"
              >
                Change
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    );
  }
}

PasswordChangeCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  style: PropTypes.object,
  loading: PropTypes.bool,
};

export default PasswordChangeCard;
