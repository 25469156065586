import { Thunk } from "../common";
import { SnackBarAction } from "./snackbar-types";

// convenience function for pushing an error to the snackbar queue
export function snackbarError(message: string): SnackBarAction {
  return basicSnackbar(message, "error");
}

export function basicSnackbar(
  message: string,
  variant: string,
  options?,
  key: string = randomString()
): SnackBarAction {
  // Add 'standard' text-only snackbar notification to store
  return {
    type: "SNACKBAR_ENQUEUE",
    notification: {
      type: "basic",
      key,
      message,
      options,
      variant,
    },
  };
}

export function customSnackbar(
  componentId: string,
  componentProps: any,
  key: string = randomString()
): SnackBarAction {
  // Add a custom pre-built snackbar notification to store (by id)
  return {
    type: "SNACKBAR_ENQUEUE",
    notification: {
      type: "custom",
      key,
      componentId,
      componentProps,
    },
  };
}

export function removeSnackbar(key: string): SnackBarAction {
  // Remove snackbar from state
  return {
    type: "SNACKBAR_REMOVE",
    key,
  };
}

// Starts the progress of a progress snackbar. See Notifier.jsx and ProgressBarSnack.jsx
export function startProgressSnackbar(snackbarKey: string): SnackBarAction {
  return {
    type: "SNACKBAR_START_PROGRESS",
    payload: {
      snackbarKey,
    },
  };
}

/**
 * Updates the progress of a progress snackbar. See Notifier.jsx and ProgressBarSnack.jsx
 *
 * @param {number?} progress - Number from 0-100 or null to close progress bar
 * @param {string} snackbarKey - Unique key that identifies the snackbar to update
 * @returns {object} Redux action for updating delete progress
 */
export function updateProgressSnackbar(
  progress: number | null,
  snackbarKey: string
): SnackBarAction {
  return {
    type: "SNACKBAR_UPDATE_PROGRESS",
    payload: {
      snackbarKey,
      progress,
    },
  };
}

// helper function to generate a random key
function randomString(): string {
  return String(new Date().getTime() + Math.random());
}

export function fileProcessingSnack(): Thunk<void, SnackBarAction> {
  return (dispatch, getState) => {
    const notifications = getState().snackbar.notifications;
    if (!notifications.some(n => n.componentId === "file-processing")) {
      dispatch(customSnackbar("file-processing", {}));
    }
  };
}
