import { SnackBarAction } from "./snackbar-types";

type SnackBarState = {
  notifications: any[];
  progress: { [snackbarKey: string]: number | null }; //job then the progress out of 100
};

const initialState = {
  notifications: [],
  progress: {},
};

function snackbarReducer(
  state: SnackBarState = initialState,
  action: SnackBarAction
): SnackBarState {
  switch (action.type) {
    case "SNACKBAR_ENQUEUE":
      // Add snackbar to state (may or may not be displayed based on max display setting)
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
      };

    case "SNACKBAR_REMOVE": {
      // Remove snackbar from state
      // Remove progress value in case it was one:
      const progress = { ...state.progress };
      delete progress[action.key];
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key),
        progress,
      };
    }

    case "SNACKBAR_START_PROGRESS":
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.payload.snackbarKey]: 0,
        },
      };

    case "SNACKBAR_UPDATE_PROGRESS":
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.payload.snackbarKey]: action.payload.progress,
        },
      };

    default:
      return state;
  }
}

export default snackbarReducer;
