import { FILE_SERVER_URL } from "../../../config";
import { Thunk } from "../../common";
import { PipelineAction, PipelineFailure, FailedFile } from "./pipeline-types";
import { snackbarError } from "../../snackbar/snackbar-actions";
import { axios, callGqlApi, extractMessage } from "../../../helpers/api";
import gql from "../../gqlTag";
import { AxiosResponse } from "axios";
import { SnackBarAction } from "../../snackbar/snackbar-types";
import { sleep } from "../../../helpers/common";

const REPROCESS_POLL_INTERVAL = 2000;

export function queryPipelineFailures(): Thunk<void, PipelineAction> {
  return async dispatch => {
    dispatch({ type: "PIPELINE_FAILURES_START_LOADING_DATA" });

    const gqlPipelineFailures = gql`
      query {
        pipelineFailures {
          id
          fileId
          fileName
          workspaceId
          errorPhase
          errorStep
          errorMessage
          creationTime
          updateTime
          mergeInfo {
            mergeTime
            mergeStatus {
              phaseStatusId
              phaseStatus
            }
          }
        }
      }
    `;
    callGqlApi(gqlPipelineFailures)
      .then(res => {
        dispatch({ type: "PIPELINE_FAILURES_SET_DATA", payload: { list: res.pipelineFailures } });
      })
      .catch(errors => {
        console.error(errors);
        dispatch({ type: "PIPELINE_FAILURES_ERROR", payload: { message: errors.message } });
        dispatch(
          snackbarError(`Error fetching file pipeline failure data: ${errors.message}`) as any
        );
      });
  };
}

/** Polls the pipeline failures data and manipulates the `reprocessing` PipelineState
 * This function calls itself. If `start` is set to true and the polling is already active
 * it does not start again.
 */
export function checkReprocessing(start = false): Thunk<void, PipelineAction> {
  let reprocessTimer: NodeJS.Timeout;
  return async (dispatch, getState) => {
    // if calling this from adding files to the queue, don't start polling if already active:
    if (start && getState().adminPipeline.reprocessing.active) {
      return;
    }
    if (getState().adminPipeline.reprocessing.stop) {
      clearTimeout(reprocessTimer);
      dispatch({ type: "PIPELINE_MONITORING_STOPPED" });
      return;
    }
    dispatch({ type: "PIPELINE_MONITORING_SET_ACTIVE" });
    dispatch(queryPipelineFailures());
    while (getState().adminPipeline.failures.loading) {
      await sleep(500);
    }
    const failedFiles = getState().adminPipeline.failures.list;
    const reprocessQueue = getState().adminPipeline.reprocessing.files;
    const reprocessMap: {
      [key: string]: { queueItem: FailedFile; history: PipelineFailure[] };
    } = {};
    reprocessQueue.forEach(queueItem => {
      reprocessMap[queueItem.fileId] = { queueItem, history: [] };
    });
    const reprocessFileIds = Object.keys(reprocessMap);
    failedFiles.forEach(f => {
      if (reprocessFileIds.includes(f.fileId)) {
        reprocessMap[f.fileId].history.push(f);
      }
    });
    // this function can only get invoked from a failed file list, so the list will have to have the original failure still in it.
    // compare the latest merge info to get the result
    reprocessFileIds.forEach(fileId => {
      const { queueItem, history } = reprocessMap[fileId];
      history.sort((t1, t2) =>
        t1.updateTime == t2.updateTime ? 0 : t1.updateTime > t2.updateTime ? -1 : 1
      );
      const latest = history[0];
      queueItem.history = history;
      if (latest?.mergeInfo?.mergeStatus?.phaseStatus == "READY") {
        // if the latest failure record has had successful merge:
        queueItem.status = "COMPLETE";
      } else if (latest.id > queueItem.id) {
        // if a new failure record has been created for this file id, then failed:
        queueItem.status = "FAILED";
      } else {
        queueItem.status = "PROCESSING";
      }
    });
    const filesProcessing = reprocessQueue.filter(q => q.status === "PROCESSING");

    dispatch({ type: "PIPELINE_SET_REPROCESSING", payload: { files: reprocessQueue } });

    if (filesProcessing.length) {
      clearTimeout(reprocessTimer);
      reprocessTimer = setTimeout(() => dispatch(checkReprocessing()), REPROCESS_POLL_INTERVAL);
    } else {
      clearTimeout(reprocessTimer); // should not need, but just in case
      dispatch({ type: "PIPELINE_MONITORING_STOPPED" });
    }
  };
}

export function actionClearDone(): PipelineAction {
  return { type: "PIPELINE_CLEAR_REPROCESSED" };
}

export function actionStopMonitoring(): PipelineAction {
  return { type: "PIPELINE_STOP_MONITORING" };
}

export function reprocessFiles(files: FailedFile[]): Thunk<void, PipelineAction | SnackBarAction> {
  return async dispatch => {
    const reprocessQueue: FailedFile[] = [];

    await Promise.all(
      files.map(async file => {
        const config = {
          params: {
            action: "process",
            file: file.fileName,
            phase: file.errorPhase,
            workspace: file.workspaceId,
            force: true,
          },
        };

        await axios
          .post(FILE_SERVER_URL + "/admin", {}, config)
          .then((response: AxiosResponse) => {
            console.log("reprocess response", response);
            file.status = "PROCESSING";
            reprocessQueue.push(file);
          })
          .catch(error => {
            console.error(error);
            dispatch(
              snackbarError(
                `File server denied re-process request for file: ${
                  file.fileName
                }. Reason: ${extractMessage(error)}`
              )
            );
          });
      })
    );

    if (reprocessQueue.length) {
      dispatch({ type: "PIPELINE_ADD_REPROCESSING", payload: { files: reprocessQueue } });
      dispatch(checkReprocessing(true));
    }
  };
}
