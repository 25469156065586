import { formatDateTime, isTimeWithinBounds } from "../../helpers/time";
import { toTitleCase } from "../../helpers/common";
import { Serial, Transmitter } from "../../helpers/glossary";

import { useThunkDispatch } from "../../redux/common";
import { downloadDetectionsCSV } from "../../redux/detections/detection-actions";
import {
  DetectionRow,
  DetectionDataStatus,
  sensorUnits,
} from "../../redux/detections/detection-types";

import { WindowedTable, TableSearchBar } from "../../fathom-brella";
import FlexCol from "../../components/common/FlexCol";
import FlexRow from "../../components/common/FlexRow";
import IconMenuHoriz from "../../components/common/IconMenuHoriz";
import { SoftTitle } from "../../components/common/typography";
import { GetApp as GetAppIcon } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { SelectionInterval } from "./detection-types";

export function DetectionTable({
  searchText,
  setSearchText,
  detectionRows,
  detectionState,
  detectionCount,
  selectionInterval,
  tableLimitReached,
}: {
  detectionState: DetectionDataStatus;
  searchText: string;
  setSearchText: (value: string) => void;
  detectionRows: DetectionRow[];
  selectionInterval: SelectionInterval;
  detectionCount?: number;
  tableLimitReached: boolean;
}) {
  const dispatch = useThunkDispatch();
  const start = (selectionInterval?.[0] && new Date(selectionInterval[0]).toISOString()) || null;
  const end = (selectionInterval?.[1] && new Date(selectionInterval[1]).toISOString()) || null;

  const detectionRowsFiltered = detectionRows.filter(({ time }) =>
    isTimeWithinBounds({ time, start, end })
  );
  const stateLoading = detectionState === "LOADING" || detectionState === "INITIAL_LOADING";
  return (
    <FlexCol fullWidth fullHeight hAlign="center" vAlign="center" paddingLevel={1} itemSpacing={1}>
      <FlexRow fullWidth spaceBetween vAlign="center">
        <IconMenuHoriz
          items={[
            {
              labelAbove: "Download CSV",
              icon: <GetAppIcon />,
              onClick: () => dispatch(downloadDetectionsCSV()),
              hoverText: ["COMPLETE", "CANCELED"].includes(detectionState)
                ? "Download table results CSV"
                : "Detections need to be fully loaded to download CSV",
              disabled: !["COMPLETE", "CANCELED"].includes(detectionState) || !detectionRows.length,
            },
          ]}
        />
        <FlexRow vAlign="center" hAlign="left" spaceBetween={3}>
          <SoftTitle>
            {tableLimitReached ? (
              "Please filter down to less than 1,000,000 detections to load table"
            ) : (
              <>
                {detectionRows?.length > 0 &&
                  `${detectionRows?.length.toLocaleString()} of ${detectionCount?.toLocaleString()} detections loaded `}
                {(detectionRowsFiltered.length < detectionRows.length &&
                  ` (${detectionRowsFiltered.length.toLocaleString()} selected)`) ||
                  ""}
                {stateLoading && <CircularProgress size={14} />}
              </>
            )}
          </SoftTitle>
        </FlexRow>
        <FlexCol vAlign="center">
          <div style={{ minWidth: 400, height: 40 }}>
            <TableSearchBar
              searchText={searchText}
              handleSearch={searchText => setSearchText(searchText)}
            />
          </div>
        </FlexCol>
      </FlexRow>

      <FlexCol fullWidth fullHeight>
        <WindowedTable
          searchText={searchText}
          rows={detectionRowsFiltered}
          rowIdKey={"id"}
          columns={[
            {
              width: 150,
              label: "Date Time",
              dataKey: "time",
              renderFn: time => formatDateTime(time),
            },
            {
              width: 125,
              label: Transmitter.title,
              dataKey: "full_id",
            },
            {
              width: 125,
              label: Serial.title,
              dataKey: "serial",
            },
            {
              width: 125,
              label: "Sensor Value",
              dataKey: "sensor_value",
              sortFn: (a, b) => {
                const numberA = parseFloat(a);
                const numberB = parseFloat(b);
                return !isNaN(numberB) && !isNaN(numberA) ? numberA - numberB : a > b ? 1 : -1;
              },
              renderFn: (_, row) => {
                if (row.sensor_value === null) return "";
                const unit = sensorUnits[row.sensor_type] || "";
                const sensorValueNumber = parseFloat(row.sensor_value);
                return `${
                  !isNaN(sensorValueNumber)
                    ? Number(sensorValueNumber.toFixed(4))
                    : row.sensor_value?.constructor === String
                    ? row.sensor_value
                    : ""
                } ${unit}`;
              },
            },
            {
              width: 100,
              label: "Sensor",
              dataKey: "sensor_type",
              renderFn: type => (type ? (type === "ADC" ? "ADC" : toTitleCase(type)) : ""),
            },
          ]}
        />
      </FlexCol>
    </FlexCol>
  );
}

export default DetectionTable;
