import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Typography, IconButton, Tooltip } from "@material-ui/core";
import { Add as IconAdd } from "@material-ui/icons";

const styles = theme => {
  return {
    root: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexGrow: 1,
    },
    title: {
      marginRight: theme.spacing(1),
      alignSelf: "center",
    },
    sectionBtn: {
      cursor: "pointer",
      alignSelf: "center",
    },
  };
};

function TableTitleWithIcon({ title, addClick, classes, toolTip }) {
  return (
    <div className={classes.root}>
      <Typography variant="h6" title={title} className={classes.title}>
        {title}
      </Typography>
      <Tooltip title={toolTip}>
        <IconButton size="small" onClick={addClick}>
          <IconAdd className={classes.sectionBtn} />
        </IconButton>
      </Tooltip>
    </div>
  );
}

TableTitleWithIcon.propTypes = {
  title: PropTypes.string,
  addClick: PropTypes.func,
  toolTip: PropTypes.string,
};

export default withStyles(styles)(TableTitleWithIcon);
