import { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Input, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Check as IconCheck,
  Edit as IconEdit,
  Delete as IconDelete,
  Close as IconClose,
} from "@material-ui/icons";
import DialogWrapper from "../common/DialogWrapper";

const useStyles = makeStyles({
  listItemContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "25px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  input: {
    width: "130px",
  },
});

function StationListItem({
  station,
  hoverId,
  editId,
  stationClicked,
  editClicked,
  updateStation,
  deleteStation,
}) {
  const classes = useStyles();
  const [name, setName] = useState(station.name);
  const [deleteId, setDeleteId] = useState(null);

  function onEditClick(event) {
    event.stopPropagation();
    editClicked(station.id);
  }

  function handleStationNameChange(event) {
    event.stopPropagation();
    setName(event.target.value);
  }
  function handleStationEdit() {
    if (name) {
      updateStation({ name: name, id: station.id });
    }
    editClicked(null);
  }

  function onDeleteClick(event) {
    event.stopPropagation();
    setDeleteId(station.id);
  }

  function handleStationDelete() {
    deleteStation(station.id);
    editClicked(null);
  }

  function keyPress(e) {
    if (e.keyCode === 13) {
      // enter key is pressed
      handleStationEdit({ name: e.target.value, id: station.id });
    }
  }

  return (
    <>
      <>
        {editId === station.id ? (
          <div className={classes.listItemContainer}>
            <Input value={name} onChange={e => handleStationNameChange(e)} onKeyDown={keyPress} />
            <div className={classes.buttonContainer}>
              <IconButton size="small" onClick={handleStationEdit}>
                <IconCheck />
              </IconButton>
              <IconButton size="small" onClick={() => editClicked(null)}>
                <IconClose />
              </IconButton>
            </div>
          </div>
        ) : (
          <div className={classes.listItemContainer} onClick={() => stationClicked(station)}>
            <Typography>{station.name} </Typography>
            {hoverId === station.id && (
              <div className={classes.buttonContainer}>
                <IconButton size="small" onClick={onEditClick}>
                  <IconEdit />
                </IconButton>
                <IconButton size="small" onClick={onDeleteClick}>
                  <IconDelete />
                </IconButton>
              </div>
            )}
          </div>
        )}
      </>
      <DialogWrapper
        open={Boolean(deleteId)}
        title="Delete Station"
        okAction={handleStationDelete}
        okButtonContent="Delete"
        cancelAction={() => setDeleteId(null)}
      >
        <Typography paragraph>Are you sure you want to delete station {station.name}?</Typography>
        <Typography paragraph>This will not delete the associated deployments.</Typography>
      </DialogWrapper>
    </>
  );
}

StationListItem.propTypes = {
  station: PropTypes.object.isRequired,
  hoverId: PropTypes.string,
  editId: PropTypes.string,
  stationClicked: PropTypes.func,
  editClicked: PropTypes.func,
  updateStation: PropTypes.func,
  deleteStation: PropTypes.func,
};

export default StationListItem;
