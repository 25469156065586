import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Typography, Tab, Tabs } from "@material-ui/core";

import DeviceEventTimeline from "./DeviceEventTimeline";
import DeviceDetails from "./DeviceDetails";
import DetailsPanel from "../common/DetailsPanel";
import PanelPlaceHolder from "../common/PanelPlaceHolder";
import TabBadge from "../common/TabBadge";

const styles = theme => ({
  tab: {
    textTransform: "none",
    minWidth: 70,
  },
  badgeTab: {
    textTransform: "none",
    minWidth: 70,
    paddingRight: theme.spacing(4.5),
  },
});

const placeHolderText = "Select a device to view detailed information.";

class DeviceDetailsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "details",
    };
  }

  render() {
    const { activeTab } = this.state;
    const { devicesSelected, classes, editDevice, deleteEvent } = this.props;
    const device = devicesSelected.length > 0 ? devicesSelected[0] : null;
    const multipleSelected = devicesSelected.length > 1;

    return (
      <DetailsPanel
        header={
          <Tabs value={activeTab} onChange={(event, tab) => this.setState({ activeTab: tab })}>
            <Tab
              className={classes.tab}
              label={<Typography variant="subtitle1">Details</Typography>}
              value="details"
            />
            <Tab
              className={classes.badgeTab}
              label={
                <TabBadge
                  color="secondary"
                  badgeContent={device && device.events ? device.events.length.toString() : "0"}
                  overlap="rectangular"
                >
                  <Typography variant="subtitle1">Events</Typography>
                </TabBadge>
              }
              value="events"
            />
          </Tabs>
        }
        content={
          multipleSelected ? (
            <PanelPlaceHolder>
              {devicesSelected.length} devices selected. Select a single device to see details.
            </PanelPlaceHolder>
          ) : device ? (
            activeTab === "details" ? (
              <DeviceDetails device={device} editDevice={editDevice} />
            ) : activeTab === "events" ? (
              <DeviceEventTimeline
                events={device.events}
                deleteEvent={deleteEvent}
                deviceId={device.id}
              />
            ) : null
          ) : (
            <PanelPlaceHolder>{placeHolderText.none}</PanelPlaceHolder>
          )
        }
      />
    );
  }
}

DeviceDetailsPanel.propTypes = {
  devicesSelected: PropTypes.array,
  editDevice: PropTypes.func,
  deleteEvent: PropTypes.func,
};

export default withStyles(styles)(DeviceDetailsPanel);
