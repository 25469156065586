type VegaTimeUnit = "yearmonthdate" | "yearmonthdatehours" | "yearmonthdatehoursminutes";
type DurationUnit = "minute" | "hour" | "day";

export class Duration {
  readonly value: number;
  readonly unit: DurationUnit;

  constructor(unt: DurationUnit, val: number) {
    this.unit = unt;
    this.value = val;
  }

  getDisplay(): string {
    if (this.unit === "day" && this.value >= 7) {
      const weekvalue = this.value / 7;
      return weekvalue + (weekvalue == 1 ? " week" : " weeks");
    } else {
      return this.value.toString() + " " + this.unit + (this.value > 1 ? "s" : "");
    }
  }

  getValueSeconds(): number {
    if (this.unit === "minute") {
      return 60 * this.value;
    } else if (this.unit === "hour") {
      return 3600 * this.value;
    } else if (this.unit === "day") {
      return 86400 * this.value;
    } else {
      throw new Error("Unknown Duration unit: " + this.unit);
    }
  }

  getVegaTimeUnit(): VegaTimeUnit {
    if (this.unit === "minute") {
      return "yearmonthdatehoursminutes";
    } else if (this.unit === "hour") {
      return "yearmonthdatehours";
    } else if (this.unit === "day") {
      return "yearmonthdate";
    } else {
      throw new Error("Unknown Duration unit: " + this.unit);
    }
  }
}
