import {
  ADMIN_USERS_SET_DATA,
  ADMIN_USERS_SET_ERROR,
  ADMIN_USER_ACCOUNT_ACTION_START,
  ADMIN_USER_ACCOUNT_ACTION_END,
} from "../../action-types";

const initialState = {
  users: [],
  loading: true,
  error: null,
  accountActionLoading: false,
};

export default function adminUserReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADMIN_USERS_SET_DATA:
      return {
        ...state,
        users: payload.users,
        loading: false,
      };
    case ADMIN_USERS_SET_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case ADMIN_USER_ACCOUNT_ACTION_START:
      return {
        ...state,
        accountActionLoading: true,
      };
    case ADMIN_USER_ACCOUNT_ACTION_END:
      return {
        ...state,
        accountActionLoading: false,
      };
    default:
      return state;
  }
}
