import { Component } from "react";
import autoBind from "auto-bind";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  Typography,
  Link,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import history from "../helpers/history";
import { validateEmail } from "../helpers/common";
import Auth from "@aws-amplify/auth";
import { SoftTitle } from "../components/common/typography";
import { ErrorBox, PasswordChange } from "../fathom-brella";

const styles = theme => ({
  root: {
    background: "top / cover no-repeat url(/img/fathom-landing-bg.png)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1),
  },
  textField: {
    width: 250,
    marginBottom: theme.spacing(1.5),
  },
  codeField: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      sentCode: false,
      values: {
        email: "",
        code: "",
        password: "",
      },
      emailError: "",
      passwordValid: false,
      redirect: null,
      loading: true,
      complete: false,
    };
    autoBind(this);
  }

  componentDidMount() {
    if (history.location && history.location.search) {
      const qParams = new URLSearchParams(this.props.location.search);
      const email = qParams.get("email");
      const redirect = qParams.get("redirect");
      const logo = qParams.get("logo");

      this.setState({ values: { ...this.state.values, email }, redirect, logo });
    }
  }

  sendCode(email) {
    if (!email || !validateEmail(email)) {
      this.setState({ error: "Please enter a valid email address." });
      return;
    }

    Auth.forgotPassword(email.toLowerCase())
      .then(() => {
        this.setState({ sentCode: true, error: "" });
      })
      .catch(e => this.setState({ error: e.message, sentCode: false }));
  }

  onChange(event, field) {
    this.setState({ values: { ...this.state.values, [field]: event.target.value } });
  }

  submitChange() {
    const { code, email, password } = this.state.values;

    Auth.forgotPasswordSubmit(email.toLowerCase(), code, password)
      .then(() => this.setState({ complete: true }))
      .catch(err => this.setState({ error: err.message }));
  }

  renderSuccess() {
    const { redirect } = this.state;
    // Show them it was successful then go to sign in or back to other application
    setTimeout(() => (redirect ? window.location.assign(redirect) : history.push("/signin")), 1500);
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Check color="success" style={{ fontSize: 40, marginBottom: 16 }} />
        <Typography> Password Change Successful! </Typography>
      </div>
    );
  }

  getLogoUrl() {
    const { logo } = this.state;

    if (logo === "fathom-live") {
      return "img/fathom-live.png";
    }

    if (logo === "fathom-mobile") {
      return "img/fathom-mobile.png";
    }

    return "img/fathom-default.png";
  }

  render() {
    const { classes } = this.props;
    const { values, sentCode, complete, passwordValid } = this.state;
    const logoUrl = this.getLogoUrl();
    return (
      <div className={classes.root}>
        <Card>
          <div className={classes.header}>
            <img alt="" src={logoUrl} width="200"></img>
            <Typography style={{ marginTop: 20 }} variant="h5">
              Change Password
            </Typography>
          </div>
          <form
            className={classes.form}
            onSubmit={e => {
              e.preventDefault();
              if (sentCode) {
                this.submitChange();
              } else {
                this.sendCode(values.email);
              }
            }}
          >
            <CardContent className={classes.form}>
              {sentCode ? (
                !complete ? (
                  <>
                    <SoftTitle>Email</SoftTitle>
                    <Typography> {values.email}</Typography>
                    <TextField
                      autoComplete="off"
                      label="Verification Code"
                      id="code"
                      helperText="The code you should have just received by email"
                      onChange={e => this.onChange(e, "code")}
                      value={values.code}
                    />
                    <Link
                      style={{ fontSize: 11 }}
                      onClick={() => this.setState({ sentCode: false, error: "" })}
                    >
                      Resend Code
                    </Link>
                    <PasswordChange
                      style={{ marginTop: 8, marginBottom: 8 }}
                      onChange={(valid, password) => {
                        this.setState({
                          values: { ...this.state.values, password },
                          passwordValid: valid,
                        });
                      }}
                    />
                    <ErrorBox style={{ maxWidth: 250 }} hidden={Boolean(this.state.error)}>
                      {this.state.error}
                    </ErrorBox>
                    <CardActions
                      style={{ display: "flex", justifyContent: "flex-end", marginRight: 20 }}
                    >
                      <Button onClick={() => history.push("/signin")}> Back </Button>
                      <Button
                        disabled={!passwordValid || !values.code}
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        Change
                      </Button>
                    </CardActions>
                  </>
                ) : (
                  this.renderSuccess()
                )
              ) : (
                <>
                  <TextField
                    label="Email"
                    className={classes.textField}
                    id="username"
                    onChange={e => this.onChange(e, "email")}
                    value={values.email}
                    helperText="A verification code will be sent to this address. It will be needed to change your password in the next step."
                  />
                  <ErrorBox style={{ marginBottom: 8 }} hidden={Boolean(this.state.error)}>
                    {this.state.error}
                  </ErrorBox>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.sendCode(values.email)}
                  >
                    {" "}
                    Send Verification Code{" "}
                  </Button>
                </>
              )}
            </CardContent>
          </form>
        </Card>
      </div>
    );
  }
}

export default compose(withStyles(styles), connect())(ForgotPassword);
