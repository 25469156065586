import { Divider, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

function SidebarList({ items }) {
  return items.map(({ onClick, icon, text, divider, ...other }, i) =>
    divider ? (
      <Divider key={i} />
    ) : (
      <ListItem key={text || i} button onClick={onClick} {...other}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    )
  );
}

export default SidebarList;
