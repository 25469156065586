import * as d3 from "d3";

/* Wrapper around d3-brush.
 * Main purpose is to associate the x-scale with the brush
 * and help keep them in sync.
 * Also provides convenient methods for moving the brush
 * programmatically, recalling the brush function to update
 * the DOM and enabling/disabling multiple event listeners.
 */

const fullPlayDuration = 10e3;
const defaultSelectionProportion = 0.1;

class XBrush {
  constructor(group, scale, brushHandler, playbackStatusHandler, height, className) {
    this.d3brush = d3.brushX();
    this.group = group;
    this.scale = scale.copy();
    this.brushHandler = brushHandler;
    this.height = height;
    this.playbackStatusHandler = playbackStatusHandler;
    this.group.attr("class", className);

    this.startListening();

    this.play = this.play.bind(this);
  }

  getSelection() {
    return d3.brushSelection(this.group.node());
  }

  startListening() {
    this.d3brush.on("brush", this.brushHandler).on("end", this.brushHandler);
  }

  stopListening() {
    this.d3brush.on("brush", null).on("end", null);
  }

  clear() {
    /* Clear the brushed region */
    this.setSelection(null);
  }

  setSelection(sel) {
    /* Set the brushed region programmatically, in pixels */
    return this.group.call(this.d3brush.move, sel);
  }

  getSelectionLength() {
    /* Return the current length of the selection, in pixels */
    const sel = this.getSelection();
    return sel && sel[1] - sel[0];
  }

  async updateScale(newScale, clearAfter, duration) {
    /* Smoothly transition the scale associated with the brush.
     * If a brushed region exists, maintain the same selected
     * region of data throughout the transition. (This means the
     * selection of pixels must change.) */

    this.playbackStatusHandler(null);

    this.width = newScale.range()[1];
    const sel = this.getSelection();

    if (sel) {
      const timeSel = sel.map(this.scale.invert);
      const newTimeSel = timeSel.map(newScale);
      const transition = this.group
        .transition()
        .duration(duration)
        .on("start", () => this.stopListening())
        .on("end", () => {
          if (clearAfter) {
            this.clear();
          }
          this.startListening();
        });

      await transition.call(this.d3brush.move, newTimeSel);
    }

    this.d3brush.extent([
      [0, 0],
      [this.width, this.height],
    ]);
    this.scale = newScale.copy();
    this.group.call(this.d3brush);
  }

  play() {
    /* Beginning "playing", i.e. begin a d3-transition of the brush from its
     * current position to the end of brushable region. */
    const sel = this.getSelection() || this.initializeSelectionForAnimation();
    const selLength = sel[1] - sel[0];
    const newsel = [this.width - selLength, this.width];
    const transitionTime = (fullPlayDuration * (this.width - sel[1])) / this.width;

    const transition = this.group
      .transition()
      .ease(d3.easeLinear)
      .duration(transitionTime)
      .on("start", () => this.playbackStatusHandler("playing"));

    transition
      .end()
      .then(() => {
        this.d3brush.on("end.whenFinished", () => {
          this.playbackStatusHandler(null);
          this.d3brush.on("end.whenFinished", null);
        });
        this.playbackStatusHandler("finished");
      })
      .catch(() => this.playbackStatusHandler(null));

    transition.call(this.d3brush.move, newsel);
  }

  pause() {
    /* Pause any current animation (re: d3 transition) of the brush */
    this.setSelection(this.getSelection());
  }

  async restart() {
    /* Move current brush back to beginning of brushable region
       and begin playing. */
    const selLength = this.getSelectionLength();
    if (selLength) {
      const newsel = [0, selLength];
      this.setSelection(newsel);
      this.play();
    }
  }

  initializeSelectionForAnimation() {
    /* If an animation is to start but no brush selection exsists,
     * this function is used to generate a default brush at the
     * start of the brushable region for the animation. */
    const start = 0;
    const end = this.width;
    const selSize = (end - start) * defaultSelectionProportion;
    const newsel = [start, start + selSize];
    this.setSelection(newsel);
    return newsel;
  }
}

export default XBrush;
