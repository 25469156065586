import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formErrors: {
    marginLeft: theme.spacing(2),
  },
});

function FormDialog({
  open,
  mode,
  title,
  handleSubmit,
  handleClose,
  handleAnother,
  formErrors,
  noActions,
  classes,
  children,
  ...dialogProps
}) {
  return (
    <Dialog open={open} maxWidth="lg" onClose={handleClose} {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <form className={classes.root} noValidate>
          {children}
        </form>
        <div className={classes.formErrors}>
          {formErrors &&
            formErrors.map(error => (
              <Typography color="error" variant="body2" key={error}>
                {error}
              </Typography>
            ))}
        </div>
      </DialogContent>
      {!noActions && (
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            {mode === "add" ? "Add" : "Save"}
          </Button>
          {handleAnother && (
            <Button onClick={handleAnother} color="primary" variant="contained">
              Add Another
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(["closed", "add", "edit"]).isRequired,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAnother: PropTypes.func,
  formErrors: PropTypes.arrayOf(PropTypes.string),
};

export default withStyles(styles)(FormDialog);
