import { KeyboardEvent } from "react";

/** Helper function to check if keypress is "enter" or "esc" and execute the functions supplied for each case */
export function ifEnterOrEsc({
  keyEvent,
  onEnter,
  onEsc,
  enterCanBeEsc = false,
}: {
  keyEvent: KeyboardEvent<HTMLElement>;
  onEnter?: () => void;
  onEsc?: () => void;
  enterCanBeEsc?: boolean;
}): void {
  if (keyEvent.key === "Escape" && onEsc?.constructor === Function) {
    onEsc();
  }
  if (keyEvent.key === "Enter") {
    if (onEnter?.constructor === Function) {
      onEnter();
    } else if (enterCanBeEsc && onEsc?.constructor === Function) {
      // option to execute the onEsc function if onEnter is not supplied:
      onEsc();
    }
  }
}
