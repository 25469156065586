import PropTypes from "prop-types";
import { Popover, Paper, IconButton } from "@material-ui/core";
import { Close as IconClose, Check as IconCheck } from "@material-ui/icons";

export function InlineEditor({ anchorEl, onSubmit, onCancel, children }) {
  return (
    <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onCancel}>
      <Paper elevation={3} style={{ display: "flex" }}>
        <div style={{ display: "flex" }}> {children}</div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton size="small" onClick={onSubmit}>
            <IconCheck />
          </IconButton>
          <IconButton size="small" onClick={onCancel}>
            <IconClose />
          </IconButton>
        </div>
      </Paper>
    </Popover>
  );
}

InlineEditor.propTypes = {
  anchorEl: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default InlineEditor;
