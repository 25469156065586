import * as d3 from "d3";
import { extractMessage, callGqlApi } from "../../helpers/api";
import { snackbarError } from "../snackbar/snackbar-actions";
import { Thunk } from "../common";

import { parseISOTimeUTC, HOUR_IN_MS } from "../../helpers/time";

import {
  RxSeriesType,
  RxDiagAction,
  RxEvent,
  RxSeries,
  RxSeriesDatum,
  RxEventType,
} from "./rx-diag-types";
import { SnackBarAction } from "../snackbar/snackbar-types";

import gql from "../gqlTag";

// used to determine the duration of the count period from the column header in the CSV
export const COUNT_PERIOD_LOOKUP: { [yHeader: string]: number | undefined } = {
  Dets_1H: HOUR_IN_MS,
  Pings_1H: HOUR_IN_MS,
  Pings_24H: HOUR_IN_MS * 24,
};

/* Update the selection of log files.
 *
 * For each file:
 *   - If it's the first time the file has been selected, its datalist is fetched from the server,
 *     which tells us data types are available for that file.
 *   - If there are any series types selected they will be automatically fetched, if not already, and if available.
 *   - If there are any event types selected (e.g. "OFFLOAD"), the events will be fetched, if not already, and if available. */
export function selectFiles(filenames: string[]): Thunk<Promise<void>, RxDiagAction> {
  return async (dispatch, getState) => {
    dispatch({ type: "RXDIAG_SELECT_FILES", payload: { filenames } });

    for (const filename of filenames) {
      const status = getState().rxdiag.dataListStatusByFile[filename];
      if (!status) {
        // first time file has been selected
        await dispatch(readDataList(filename));
      }
    }

    dispatch(loadSeriesAsNeeded());
    dispatch(loadEventsAsNeeded());
  };
}

/* Update the selection of data series types (e.g. "noise" or "tilt")

 * For each selected file:
 *   - If there are any series types selected they will be automatically fetched, if not already, if available. */
export function selectSeriesTypes(seriesTypes: RxSeriesType[]): Thunk<void, RxDiagAction> {
  return dispatch => {
    dispatch({ type: "RXDIAG_SELECT_SERIES_TYPES", payload: { seriesTypes } });
    dispatch(loadSeriesAsNeeded());
  };
}

/* Handle the event types that the user has selected in the RxDiag app.
 * For each selected file:
 *   - If there are any events selected, the events will be fetched, if not already, if available. */
export function selectEventTypes(eventTypes: RxEventType[]): Thunk<void, RxDiagAction> {
  return (dispatch, getState) => {
    dispatch({ type: "RXDIAG_SELECT_EVENT_TYPES", payload: { eventTypes } });

    if (eventTypes.length === 0) return;

    const { selectedFiles, eventsStatuses } = getState().rxdiag;
    for (const filename of selectedFiles) {
      const status = eventsStatuses.find(d => d.filename === filename)?.status;
      if (status === "unloaded") {
        // first time events have been selected for this file
        dispatch(loadEvents(filename));
      }
    }
  };
}

// HELPER ACTIONS

// load any missing series
function loadSeriesAsNeeded(): Thunk<void, RxDiagAction> {
  return (dispatch, getState) => {
    const { selectedFiles, selectedSeriesTypes, seriesStatuses } = getState().rxdiag;
    for (const filename of selectedFiles) {
      for (const type of selectedSeriesTypes) {
        const status = seriesStatuses.find(d => d.filename === filename && d.type === type)?.status;
        if (status === "unloaded") {
          // series is available but not yet loaded: load it
          dispatch(loadSeries(filename, type));
        }
      }
    }
  };
}

// load any missing events
function loadEventsAsNeeded(): Thunk<void, RxDiagAction> {
  return (dispatch, getState) => {
    const { selectedFiles, selectedEventTypes, eventsStatuses } = getState().rxdiag;
    if (selectedEventTypes.length === 0) return;

    for (const filename of selectedFiles) {
      const status = eventsStatuses.find(d => d.filename === filename)?.status;
      if (status === "unloaded") {
        // first time events have been selected for this file: load them
        dispatch(loadEvents(filename));
      }
    }
  };
}

// DATA LIST

// Fetch and parse the datalist for a given file.
function readDataList(filename: string): Thunk<Promise<void>, RxDiagAction | SnackBarAction> {
  return async (dispatch, state) => {
    dispatch({ type: "RXDIAG_DATALIST_LOADING", payload: { filename } });

    try {
      const fileId: string = state().files.fileList.find(f => f.name === filename)?.id || "";
      const availableQuery = gql`
        query {
          availableRxFileDiagnostics(fileId: "${fileId}")
        }
      `;
      const availableTypes = await callGqlApi(availableQuery);
      dispatch({
        type: "RXDIAG_DATALIST_LOADED",
        payload: { filename, dataTypes: availableTypes.availableRxFileDiagnostics },
      });
    } catch (error) {
      dispatch(snackbarError(extractMessage(error)));
    }
  };
}

// SERIES DATA

// Fetch series data for a given file & type
function loadSeries(
  filename: string,
  seriesType: RxSeriesType
): Thunk<void, RxDiagAction | SnackBarAction> {
  return (dispatch, state) => {
    dispatch({ type: "RXDIAG_SERIES_LOADING", payload: { filename, seriesType } });

    // Get serial per file from existing state. Note: this assumes the file data is already
    // populated. In current usage, this has to be so because the data for a file cannot be
    // requested until the files are known. However, this may not always be so.
    const serialByFile = {};
    state().files.fileList?.forEach(file => {
      if (file.rxLogProperties?.serial) {
        serialByFile[file.name] = file.rxLogProperties.serial;
      }
    });
    const fileId: string = state().files.fileList.find(f => f.name === filename)?.id || "";

    fetchLogDataGQL(fileId, seriesType)
      .then(res => parseSeries(res.data, seriesType))
      .then(({ yVariable, data }) => {
        const series = {
          filename,
          type: seriesType,
          yVariable,
          data,
          serial: serialByFile[filename],
        };
        dispatch({
          type: "RXDIAG_SERIES_LOADED",
          payload: { series },
        });
      })
      .catch(error => {
        console.error(error);
        dispatch(snackbarError(extractMessage(error)));
      });
  };
}

// Parse the csv data for a given series type
function parseSeries(csv: string, dataType: RxSeriesType): Pick<RxSeries, "yVariable" | "data"> {
  const csvData = d3.csvParse(csv);
  const yColName = Object.keys(csvData[0])[1];

  const countPeriod = COUNT_PERIOD_LOOKUP[yColName];
  const yVariable = adjustYHeader(yColName);

  let data = csvData.map(d => ({
    x: parseISOTimeUTC(d.Time as string),
    y: +(d[yColName] as string),
  }));

  if (countPeriod) {
    if (dataType === "pings") {
      // ping counts apply to the period _before_ the time: adjust the time values accordingly
      data.forEach(d => {
        d.x = new Date(d.x.getTime() - countPeriod);
      });
    }
    /* If the data is counted in bins, insert records with zero counts at the start of any gaps in the data
     * This ensures that the values on the charts read zero where there is no data. */
    data = insertZeroCounts(data, countPeriod);

    // Makes sure a line is displayed when there is only data point
    if (data.length === 1) {
      data.push({ x: new Date(data[0].x.getTime() + countPeriod), y: data[0].y });
    }
  }

  return { yVariable, data };
}

// insert records with zero counts at the start of any gaps in the data
function insertZeroCounts(data: RxSeriesDatum[], countPeriod: number): RxSeriesDatum[] {
  const result: RxSeriesDatum[] = [];
  const threshold = countPeriod * 1.1;

  for (let i = 0; i < data.length; i++) {
    result.push(data[i]);
    if (i < data.length - 2) {
      const diff = data[i + 1].x.getTime() - data[i].x.getTime();
      if (diff > threshold) {
        result.push({ x: new Date(data[i].x.getTime() + countPeriod), y: 0 });
      }
    }
  }

  return result;
}

// adhoc adjustments to yVariable names
function adjustYHeader(yColName: string): string {
  switch (yColName) {
    case "Dets_1H":
      return "Dets. / Hour";
    case "Pings_1H":
      return "Pings / Hour";
    case "Pings_24H":
      return "Pings / Day";
    default:
      return yColName.replace("Temperature", "Temp.").replace(/\s*deg\s*/, "°");
  }
}

// Parse the csv response for a file's series type
function loadEvents(filename: string): Thunk<void, RxDiagAction | SnackBarAction> {
  return (dispatch, state) => {
    dispatch({ type: "RXDIAG_EVENTS_LOADING", payload: { filename } });

    const fileId: string = state().files.fileList.find(f => f.name === filename)?.id || "";
    fetchLogDataGQL(fileId, "events")
      .then(parseEvents)
      .then(events => dispatch({ type: "RXDIAG_EVENTS_LOADED", payload: { filename, events } }))
      .catch(error => {
        console.error(error);
        dispatch(snackbarError(extractMessage(error)));
      });
  };
}

// Parse the csv reponse for a file's event data
function parseEvents(response: any): Omit<RxEvent, "filename">[] {
  const events = d3.csvParse(response.data);
  return events.map(d => {
    return {
      time: parseISOTimeUTC(d.Time as string),
      type: d.Event as RxEventType,
    };
  });
}

// API HELPERS
async function fetchLogDataGQL(fileId: string, dataType: RxSeriesType | "events") {
  const query = gql`
    {
      rxFileDiagnostic(
        fileId: "${fileId}", 
        type: ${dataType}
      ) {
        data
      }
    }
  `;
  const res = await callGqlApi(query);
  return res.rxFileDiagnostic;
}
