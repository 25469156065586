import {
  DEPLOYMENTS_SET_LIST,
  DEPLOYMENTS_SET_STATIONS_LIST,
  DEPLOYMENTS_SET_ERROR,
  DEPLOYMENTS_DELETE,
} from "../action-types";
const initialState = {
  deployments: null,
  loading: true,
  error: null,
};
export default function deploymentsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case DEPLOYMENTS_SET_LIST:
      return {
        ...state,
        deployments: payload.deployments,
        stations: payload.stations,
        loading: false,
      };
    case DEPLOYMENTS_SET_STATIONS_LIST:
      return {
        ...state,
        stations: payload.stations,
        loading: false,
      };
    case DEPLOYMENTS_SET_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case DEPLOYMENTS_DELETE: {
      const deployments = [...state.deployments].filter(
        deployment => !payload.ids.includes(deployment.id)
      );
      return {
        ...state,
        deployments,
      };
    }
    default:
      return state;
  }
}
