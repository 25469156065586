import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";

//#region TYPES

/* ThunkAction takes 4 type parameters:
 *   R: the type of the return value of the ThunkAction
 *   S: the type of the full redux state
 *   E: any extra arguments the ThunkAction takes
 *   A: the type of actions that the ThunkAction dispatches
 *
 * In our app, we haven't added any extra arguments and S will always be the same,
 * so this is a helper type that simplifies the ThunkAction type. */
export type Thunk<R, A extends Action<any>> = ThunkAction<R, RootState, undefined, A>;

export type GqlErrors = GqlError[];

export type GqlError = {
  extensions: {
    code: string;
    customErrorCode?: string;
  };
  message: string;
  path: string[];
};

/* Correctly typed of the dispatch for using redux-thunk
 * See https://github.com/DefinitelyTyped/DefinitelyTyped/issues/45575 */
export type StoreDispatch = ThunkDispatch<RootState, undefined, Action<any>>;

//#endregion TYPES

//#region FUNCTIONS

/* Typed version of useSelector that takes into account the type of the store */
export const useSelectorTyped: TypedUseSelectorHook<RootState> = useSelector;

export const useThunkDispatch = () => useDispatch<StoreDispatch>();

//#endregion FUNCTIONS
