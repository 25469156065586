type Props = {
  distance?: number;
  units?: string;
};
/** Shows a number with lines pointing to two textfield inputs (sizing is hard coded for now)*/
export default function DistanceNote({ distance = 0, units = "m" }: Props) {
  return (
    <div
      style={{
        display: "flex",
        width: 50,
        paddingTop: 52,
        paddingBottom: 8,
        paddingLeft: 10,
        paddingRight: 14,
      }}
    >
      <div
        style={{
          flexGrow: 1,
          position: "relative",
          borderRadius: 8,
          border: "1px solid #333",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: "0px",
        }}
      >
        <span
          style={{
            position: "absolute",
            height: 30,
            left: "calc(50% - 5px)",
            backgroundColor: "white",
            top: "calc(50% - 15px)",
            paddingTop: 6,
            fontStyle: "oblique",
            fontSize: 12,
          }}
        >
          {`${distance}${units}`}
        </span>
      </div>
    </div>
  );
}
