import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import HeaderCell from "./HeaderCell";
import CheckboxCell from "./CheckboxCell";

const useStyles = makeStyles({
  root: ({ height, width }) => ({
    display: "flex",
    height,
    width,
  }),
});

function HeaderRow({
  columns,
  height,
  width,
  selectable,
  selectDisabled,
  handleSelectHeader,
  allSelected,
  sortBy,
  sortDir,
  handleSort,
  colFilters,
  handleColFilter,
}) {
  const classes = useStyles({ height, width });

  return (
    <div className={classes.root}>
      {selectable && (
        <CheckboxCell
          selected={allSelected}
          onClick={handleSelectHeader}
          disabled={selectDisabled}
        />
      )}

      {columns.map(col => {
        const sortable = !col.disableSort;
        return (
          <HeaderCell
            sortable={sortable}
            sortDir={col.dataKey === sortBy ? sortDir : null}
            handleSort={sortable ? () => handleSort(col.dataKey) : null}
            filterable={col.filterable}
            filterValue={colFilters[col.dataKey]}
            handleColFilter={col.filterable ? value => handleColFilter(col.dataKey, value) : null}
            FilterInputComponent={col.FilterInputComponent}
            numeric={col.numeric}
            width={col.width}
            flexGrow={col.flexGrow}
            flexShrink={col.flexShrink}
            key={col.dataKey}
          >
            {col.label}
          </HeaderCell>
        );
      })}
    </div>
  );
}

export default React.memo(HeaderRow);
