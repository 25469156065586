export const deploymentSpecFormats = [
  "deployment-standard",
  "deployment-simple",
  "deployment-otn",
] as const;
type DeploymentSpecFormat = typeof deploymentSpecFormats[number];

export const animalSpecFormats = ["animal-standard", "animal-otn"] as const;
type AnimalSpecFormat = typeof animalSpecFormats[number];

export type SpecFormat = DeploymentSpecFormat | AnimalSpecFormat;

export type MissingDevice = {
  serial?: string;
  txDisplayIds?: string[];
  model?: string;
  deviceClasses: string;
};

export type SpecError = {
  message: string;
  row: number;
  cols?: string[];
};
