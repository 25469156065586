import { Component } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import HelpPopover from "../../common/HelpPopover";
import CustomButton from "../../common/CustomButton";
import DialogWrapper from "../../common/DialogWrapper";
import AdminPasswordChange from "./AdminPasswordChange";

import { connect } from "react-redux";
import {
  enableUser,
  disableUser,
  resendUserInvite,
  setPassword,
} from "../../../redux/admin/user/admin-user-actions";
import autoBind from "auto-bind";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    marginTop: theme.spacing(3),
  },
  actions: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    margin: `${theme.spacing(1)}px  0px ${theme.spacing(1)}px ${theme.spacing(1)}px `,
  },
});

class UserAccountActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmResendOpen: false,
      passwordChangeOpen: false,
    };
    autoBind(this);
  }

  enableUser() {
    this.props.dispatch(enableUser(this.props.user));
  }

  disableUser() {
    this.props.dispatch(disableUser(this.props.user));
  }

  resendInvite() {
    this.setState({ confirmResendOpen: false });
    this.props.dispatch(resendUserInvite(this.props.user.email));
  }

  changePassword(password) {
    this.setState({ passwordChangeOpen: false });
    this.props.dispatch(setPassword(this.props.user, password));
  }

  render() {
    const { user, classes, loading } = this.props;
    const { confirmResendOpen, passwordChangeOpen } = this.state;

    return (
      <div className={classes.main}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography variant="h6"> Account Actions</Typography>
          {loading && <CircularProgress size={20} style={{ marginLeft: 8, marginTop: 4 }} />}
        </div>

        <div className={classes.actions}>
          {user.enabled ? (
            <CustomButton
              className={classes.button}
              variant="outlined"
              onClick={this.disableUser}
              disabled={loading}
              color="error"
            >
              Disable
            </CustomButton>
          ) : (
            <CustomButton
              className={classes.button}
              variant="outlined"
              onClick={this.enableUser}
              disabled={loading}
              color="success"
            >
              Enable
            </CustomButton>
          )}
          <Button
            className={classes.button}
            variant="outlined"
            disabled={user.status !== "FORCE_CHANGE_PASSWORD" || loading || !user.enabled}
            onClick={() => this.setState({ confirmResendOpen: true })}
          >
            Resend Welcome Email
          </Button>
          <HelpPopover
            tooltip="What does this do?"
            maxWidth={600}
            helpContent="If the user's status is FORCE_CHANGE_PASSWORD you can resend the invite email. Invites only last for 90 days."
          />
          <Button
            className={classes.button}
            variant="outlined"
            disabled={loading || !user.enabled}
            onClick={() => this.setState({ passwordChangeOpen: true })}
          >
            Set temporary password
          </Button>
          <HelpPopover
            tooltip="What does this do?"
            maxWidth={600}
            helpContent="Set the user's password. This is useful if the user is having trouble receiving the welcome email or if you need to get into their account."
          />
        </div>
        <DialogWrapper
          open={confirmResendOpen}
          title={"Are you sure you want to resend the welcome email?"}
          okButtonContent={"Send"}
          okAction={this.resendInvite}
          cancelAction={() => this.setState({ confirmResendOpen: false })}
        />
        <AdminPasswordChange
          open={passwordChangeOpen}
          onSubmit={password => this.changePassword(password)}
          onClose={() => this.setState({ passwordChangeOpen: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.adminUsers.accountActionLoading,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(UserAccountActions));
