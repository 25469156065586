import { HOUR_IN_MS } from "../../helpers/time";
import { bisect } from "d3-array";

export function findClosest(arr, x) {
  /* Find the index of the element in arr closest to x,
     where arr is an array sorted in ascending order */
  if (x <= arr[0]) {
    return 0;
  } else if (x >= arr[arr.length - 1]) {
    return arr.length - 1;
  } else {
    const next = bisect(arr, x);
    const prev = next - 1;
    const idx = x - arr[prev] < arr[next] - x ? prev : next;
    return idx;
  }
}

export function floorHours(t, mult) {
  /* Round a timestamp down to a multiple of hours */
  const coeff = HOUR_IN_MS * mult;
  return new Date(Math.floor(t.getTime() / coeff) * coeff);
}

export function ceilHours(t, mult) {
  /* Round a timestamp up to a multiple of hours */
  const coeff = HOUR_IN_MS * mult;
  return new Date(Math.ceil(t.getTime() / coeff) * coeff);
}

export function removeByIndex(arr, idx) {
  /* Remove an element from an array by index, immutably */
  const newArr = [...arr];
  newArr.splice(idx, 1);
  return newArr;
}

/**
 *
 * @param {string} email - email to validate
 *
 * @returns {boolean} - true if email is valid
 */
export function isEmailValid(email) {
  const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!emailRegex.test(email)) {
    return false;
  }
  return true;
}

export function passwordPolicyValidation(password) {
  const lowerRegex = /^(?=.*[a-z])/;
  const upperRegex = /^(?=.*[A-Z])/;
  const numberRegex = /^(?=.*[0-9])/;
  const whitespaceCharRegex = /\s/;

  const lowerCaseCheck = lowerRegex.test(password);
  const upperCaseCheck = upperRegex.test(password);
  const numberCheck = numberRegex.test(password);
  const whitespaceCaseCheck = !whitespaceCharRegex.test(password);

  const lengthCheck = password.length > 7;
  const allValid =
    lowerCaseCheck && upperCaseCheck && numberCheck && whitespaceCaseCheck && lengthCheck;

  return {
    lowerCaseCheck,
    upperCaseCheck,
    numberCheck,
    whitespaceCaseCheck,
    lengthCheck,
    allValid,
  };
}
