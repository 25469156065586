// Users
export const USER_SET_CURRENT = "USER_SET_CURRENT";
export const USER_REFRESH_DATA = "USER_REFRESH_DATA";
export const USER_CHANGE_OFFSET = "USER_CHANGE_OFFSET";
export const USER_ACCEPT_PRIVACY_POLICY = "USER_ACCEPT_PRIVACY_POLICY";
export const USER_VIEW_PRIVACY_POLICY = "USER_VIEW_PRIVACY_POLICY";
export const USER_CLOSE_PRIVACY_POLICY = "USER_CLOSE_PRIVACY_POLICY";
export const USER_ACCEPT_WHATS_NEW = "USER_ACCEPT_WHATS_NEW";
export const USER_CLOSE_WHATS_NEW = "USER_CLOSE_WHATS_NEW";
export const USER_VIEW_WHATS_NEW = "USER_VIEW_WHATS_NEW";
export const USER_REVIEW_WHATS_NEW = "USER_REVIEW_WHATS_NEW";

// Study
export const STUDY_SET_LIST = "STUDY_SET_LIST";
export const STUDY_SET_SELECTED = "STUDY_SET_SELECTED";
export const STUDY_NOT_LOADED = "STUDY_NOT_LOADED";
export const STUDY_UPDATE_EXPORT_STATUS = "STUDY_UPDATE_EXPORT_STATUS";
export const STUDY_SHOW_EXPORT_DETAILS = "STUDY_SHOW_EXPORT_DETAILS";
export const STUDY_HIDE_EXPORT_DETAILS = "STUDY_HIDE_EXPORT_DETAILS";
export const STUDY_UPDATE = "STUDY_UPDATE";
export const ALL_DATA_SET_LIST = "ALL_DATA_SET_LIST";
export const STUDY_SET_ONE = "STUDY_SET_ONE";
export const STUDY_LINKING_IN_PROGESS = "STUDY_LINKING_IN_PROGESS";
export const STUDY_LINKING_DONE = "STUDY_LINKING_DONE";

// Animals
export const ANIMALS_SET_LIST = "ANIMALS_SET_LIST";
export const ANIMALS_SET_ERROR = "ANIMALS_SET_ERROR";
export const ANIMALS_UPDATE_ONE = "ANIMALS_UPDATE_ONE";

// Devices
export const DEVICES_SET_LIST = "DEVICES_SET_LIST";
export const DEVICES_SET_ERROR = "DEVICES_SET_ERROR";
export const DEVICES_SET_CODESPACES = "DEVICES_SET_CODESPACES";
export const DEVICES_SET_CODESPACES_ERROR = "DEVICES_SET_CODESPACES_ERROR";
export const DEVICES_SET_MODELS = "DEVICES_SET_MODELS";
export const DEVICES_SET_PERSONAL = "DEVICES_SET_PERSONAL";
export const DEVICES_SET_PERSONAL_LOADING = "DEVICES_SET_PERSONAL_LOADING";
export const DEVICES_SET_PERSONAL_ERROR = "DEVICES_SET_PERSONAL_ERROR";
export const DEVICES_UPDATE_ONE = "DEVICES_UPDATE_ONE";
export const DEVICE_DELETE_EVENTS = "DEVICE_DELETE_EVENTS";

// Deployments
export const DEPLOYMENTS_SET_LIST = "DEPLOYMENTS_SET_LIST";
export const DEPLOYMENTS_SET_STATIONS_LIST = "DEPLOYMENTS_SET_STATIONS_LIST";
export const DEPLOYMENTS_SET_ERROR = "DEPLOYMENTS_SET_ERROR";
export const DEPLOYMENTS_DELETE = "DEPLOYMENTS_DELETE";

// Workspaces
export const WORKSPACES_SELECT = "WORKSPACES_SELECT";
export const WORKSPACES_DONE_LOADING = "WORKSPACES_DONE_LOADING";
export const WORKSPACES_SET_LIST = "WORKSPACES_SET_LIST";
export const WORKSPACES_SET_ERROR = "WORKSPACES_SET_ERROR";
export const WORKSPACES_SET_CREATED_ID = "WORKSPACES_SET_CREATED_ID";
export const WORKSPACES_INCLUDE_DELETED = "WORKSPACES_INCLUDE_DELETED";
export const WORKSPACES_QUERY_FAILED = "WORKSPACES_QUERY_FAILED";

// Request access
export const USER_REQUEST_ACCESS = "USER_REQUEST_ACCESS";

// admin users
export const ADMIN_USERS_SET_DATA = "ADMIN_USERS_SET_DATA";
export const ADMIN_USERS_SET_ERROR = "ADMIN_USERS_SET_ERROR";
export const ADMIN_USER_ACCOUNT_ACTION_START = "ADMIN_USER_ACCOUNT_ACTION_START";
export const ADMIN_USER_ACCOUNT_ACTION_END = "ADMIN_USER_ACCOUNT_ACTION_END";

// admin workspaces
export const ADMIN_WORKSPACES_SET_DATA = "ADMIN_WORKSPACES_SET_DATA";
export const ADMIN_WORKSPACES_SET_ERROR = "ADMIN_WORKSPACES_SET_ERROR";

// Root actions
export const ROOT_RESET_WORKSPACE = "ROOT_RESET_WORKSPACE";
