import * as d3 from "d3";
import { rollup } from "d3-array";

import { floorHours } from "../../helpers/common";

const BIN_SIZES = [1, 2, 4, 8, 12, 24];

function getBinSizesFromMin(minBinSize) {
  if (minBinSize === 1) {
    return BIN_SIZES;
  } else {
    const binSizes = [];
    let currentMinBin = minBinSize;
    while (currentMinBin <= 24) {
      binSizes.push(currentMinBin);
      currentMinBin = currentMinBin + minBinSize;
    }
    return binSizes;
  }
}

export function finalizeData(data, minBinSize = 1) {
  console.log("finalizeData");
  if (!data) return null;
  const availableBinSizes = getBinSizesFromMin(minBinSize);
  const { deployments, hourlyDetCount } = data;

  // merge the hourly det count to create det counts at different time
  const detCountsByBinSize = deriveDetCountsByBinSize(hourlyDetCount, availableBinSizes);

  // Use the coarsest det count to compute summaries based on tag id
  const coarsestDetCount = [...detCountsByBinSize.values()].pop();
  const detectionsPerId = getDetectionsPerId(coarsestDetCount);

  return {
    deployments: deployments,
    detCountsByBinSize: detCountsByBinSize,
    detectionsPerId: detectionsPerId,
  };
}

export function deriveDetCountsByBinSize(hourlyDetCount, binSizes) {
  /* Create a map from period size in hours to detection count tables, beginning with the hourly
   * count returned by the server. Where possible finer counts are merged to produce
   * coarser and coarser counts. */

  // Data must be sorted by deployment, then id, then period.
  const sortedHourly = [...hourlyDetCount].sort(
    (a, b) =>
      a.deployment - b.deployment ||
      (a.fullid > b.fullid && 1) ||
      (a.fullid < b.fullid && -1) ||
      a.period - b.period
  );

  const detCountsByBinSize = new Map([[binSizes[0], sortedHourly]]);

  binSizes.slice(1).forEach(binSize => {
    /* find the coarsest existing detection count that can be merged to create the new det count
     * eg. for a bin size of 12 hrs, an existing det count at 8 hr bin size cannot be used,
     * but one at 4 hrs can. */
    const gcd = [...detCountsByBinSize.keys()].filter(d => binSize % d === 0).pop();
    const parentDetCount = detCountsByBinSize.get(gcd);
    const detCount = mergeDetCount(parentDetCount, binSize);

    detCountsByBinSize.set(binSize, detCount);
  });

  detCountsByBinSize.binSizes = binSizes;
  return detCountsByBinSize;
}

function mergeDetCount(detCount, newBinSize) {
  /* Take an existing detection count table and merge its entries to generate a coarser
   * detection count table. */
  const result = [];

  let dep, fid, per, last;

  detCount.forEach(d => {
    const newPeriod = floorHours(d.period, newBinSize);
    const newRecord = { ...d, period: newPeriod };

    if (d.deployment !== dep) {
      dep = d.deployment;
      fid = d.fullid;
      per = newPeriod.getTime();
      last = result.push(newRecord) - 1;
    } else if (d.fullid !== fid) {
      fid = d.fullid;
      per = newPeriod.getTime();
      last = result.push(newRecord) - 1;
    } else if (newPeriod.getTime() !== per) {
      per = newPeriod.getTime();
      last = result.push(newRecord) - 1;
    } else {
      result[last].N += d.N;
    }
  });

  return result;
}

function getDetectionsPerId(fullDetCount) {
  /* Generate list of tag ids and total detection counts, sorted by descending order of count.
   * Includes field that indicates whether a tag is marked as an animal tag. */
  const map = rollup(
    fullDetCount,
    v => d3.sum(v, d => d.N),
    d => d.fullid
  );
  const arr = Array.from(map, ([key, val]) => ({
    id: key,
    dets: val,
  })).sort((a, b) => b.dets - a.dets);
  return arr;
}
