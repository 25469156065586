import { useState } from "react";
import {
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import DialogWrapper from "../common/DialogWrapper";
import HelpPopover from "../common/HelpPopover";

type FeatureKey = "ABACUS_PLOT" | "OP_KEYS" | "CH_DETECTIONS";
export type Feature = {
  key: FeatureKey;
  description: string;
  enabledByDefault?: boolean;
};

export const AbacusPlot: Feature = {
  key: "ABACUS_PLOT",
  description: "Enable Abacus plot in Detection Tab",
  enabledByDefault: true,
};
export const OPKeys: Feature = {
  key: "OP_KEYS",
  description: "Enable OP Key Activation",
};
export const SpeedLayerDetections: Feature = {
  key: "CH_DETECTIONS",
  description: "Enable querying detection data from speed layer",
  enabledByDefault: true,
};
const availableFeatures: Feature[] = [AbacusPlot, OPKeys, SpeedLayerDetections];

export const experimentalFeaturesStorageKey = "feature-flags";
export const editedFeatureKey = "edited-feature-flags";

function loadFromStorage(experimentalFeatures: Feature[] = availableFeatures): Feature[] {
  const enabledFeatures: Feature[] = [];
  const enabledFeatureFlags = localStorage.getItem(experimentalFeaturesStorageKey);
  experimentalFeatures.forEach((feature: Feature) => {
    if (enabledFeatureFlags?.includes(feature.key)) {
      enabledFeatures.push(feature);
    }
  });
  return enabledFeatures;
}

export function isFeatureEnabled(feature: Feature): boolean {
  const enabledInStorage = loadFromStorage();
  return enabledInStorage.map(f => f.key).includes(feature.key);
}

export function ExperimentalFeaturesDialog({
  isOpen,
  onClose,
  experimentalFeatures = availableFeatures,
}) {
  const location = useLocation();

  const defaultFeatures = experimentalFeatures.filter(f => f.enabledByDefault);
  const hasEditFeatures = Boolean(localStorage.getItem(editedFeatureKey));

  // Put default in local storage if they never open dialog
  if (!hasEditFeatures) {
    localStorage.setItem(
      experimentalFeaturesStorageKey,
      defaultFeatures.map(f => f.key).toString()
    );
  }
  const featuresFromStorage = loadFromStorage(experimentalFeatures);
  const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>(
    !hasEditFeatures ? defaultFeatures : featuresFromStorage
  );

  function toggleFeature(feature: Feature) {
    const newFeatures = [...selectedFeatures];
    if (selectedFeatures.includes(feature)) {
      const idx = selectedFeatures.indexOf(feature, 0);
      newFeatures.splice(idx, 1);
    } else {
      newFeatures.push(feature);
    }
    setSelectedFeatures(newFeatures);
  }

  function checkPageNeedsReload() {
    const storageFlags = localStorage.getItem(experimentalFeaturesStorageKey) || "";
    const abacusChanged =
      storageFlags.includes("ABACUS_PLOT") !==
      selectedFeatures.map(f => f.key).includes("ABACUS_PLOT");
    const chChanged =
      storageFlags.includes("CH_DETECTIONS") !==
      selectedFeatures.map(f => f.key).includes("CH_DETECTIONS");
    if (chChanged) {
      window.location.reload();
    } else if (location.pathname === "/detections" && abacusChanged) {
      window.location.reload();
    }
  }

  function storeFlags() {
    checkPageNeedsReload();
    localStorage.setItem(editedFeatureKey, "true");
    localStorage.setItem(
      experimentalFeaturesStorageKey,
      selectedFeatures.map(f => f.key).toString()
    );
    onClose();
  }

  function revertFlags() {
    setSelectedFeatures(loadFromStorage(experimentalFeatures));
    onClose();
  }

  return (
    <DialogWrapper
      open={isOpen}
      onClose={onClose}
      title={[
        <span key="1" style={{ float: "left" }}>
          Enable Experimental Features
        </span>,
        <span key="2" style={{ float: "left" }}>
          <HelpPopover
            tooltip="What does 'Experimental Features' do?"
            maxWidth={500}
            iconSize={24}
            helpContent={
              <>
                <Typography gutterBottom>
                  Certain features are rolled out to internal users before being released to
                  customers.
                </Typography>
                <Typography gutterBottom>
                  Enabling 'Experimental Features' will turn on or change various functionality that
                  is best confirmed and tested by daily users in the appropriate environment.
                </Typography>
                <Typography>The feature flags will remain enabled until you log out.</Typography>
              </>
            }
          />
        </span>,
      ]}
      okAction={storeFlags}
      cancelAction={revertFlags}
    >
      <FormControl>
        <FormLabel>Feature Flags</FormLabel>
        <FormGroup>
          {experimentalFeatures.length > 0 ? (
            experimentalFeatures.map((availableFeature: Feature) => {
              return (
                <FormControlLabel
                  key={availableFeature.key}
                  control={
                    <Switch
                      name={availableFeature.key}
                      checked={selectedFeatures.includes(availableFeature)}
                      onClick={() => toggleFeature(availableFeature)}
                      color="primary"
                    />
                  }
                  label={availableFeature.description}
                />
              );
            })
          ) : (
            <div>There are currently no feature flags to enable.</div>
          )}
        </FormGroup>
      </FormControl>
    </DialogWrapper>
  );
}
