import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "../styles/customMapBox.css";

import { merge } from "lodash";
import { MapboxToken } from "../config";
mapboxgl.accessToken = MapboxToken;

const defaultMapOptions = {
  style: "mapbox://styles/mapbox/satellite-v9",
  center: [31.4606, 20.7927], //global center
  fitBoundsOptions: { padding: 150 },
  attributionControl: false,
};

const defaultControlOptions = {
  dragRotate: false,
  touchZoomRotate: false,
  navigationControl: {
    display: true,
    options: { showCompass: false },
    position: "top-right",
  },
  attributionControl: {
    display: true,
    options: { compact: true },
    position: "top-right",
  },
};

export function mapboxMap(container, mapOptions = {}, controlOptions = {}) {
  const cOpts = merge(defaultControlOptions, controlOptions);
  const mOpts = merge(defaultMapOptions, mapOptions);

  const map = new mapboxgl.Map({
    container,
    ...mOpts,
  });

  //Disable rotation using right click and drag
  cOpts.dragRotate === false && map.dragRotate.disable();

  //Disable rotation using touch rotation on certain mice
  cOpts.touchZoomRotate === false && map.touchZoomRotate.disable();

  cOpts.navigationControl.display &&
    map.addControl(
      new mapboxgl.NavigationControl(cOpts.navigationControl.options),
      cOpts.navigationControl.position
    );

  cOpts.attributionControl.display &&
    map.addControl(
      new mapboxgl.AttributionControl(cOpts.attributionControl.options),
      cOpts.attributionControl.position
    );

  // MRT - HACK: Map canvas is not fitting the container force resize to fit
  map.on("style.load", () => {
    window.dispatchEvent(new Event("resize"));
  });

  return map;
}

export function getBounds(coords, latKey = "lat", lonKey = "lon") {
  if (!coords?.length) return null;

  const latitudes = coords.map(dp => dp[latKey]);
  const longitudes = coords.map(dp => dp[lonKey]);

  return [
    [Math.min(...longitudes), Math.min(...latitudes)],
    [Math.max(...longitudes), Math.max(...latitudes)],
  ];
}

export function mapboxLatLng(latLon) {
  return { lat: latLon.latitude, lng: latLon.longitude };
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  let d = 0;
  try {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    d = R * c; // Distance in km
  } catch (ex) {
    console.error(ex);
  }
  // console.log({ lat1, lon1, lat2, lon2, d });
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
