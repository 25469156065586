import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Tooltip } from "@material-ui/core";

import { setAppPath } from "../../helpers/history";

const styles = theme => {
  return {
    appBar: {
      backgroundColor: theme.palette.primary.light,
    },
    appBarSysAdmin: {
      boxShadow:
        "0px 2px 4px -1px rgba(200,0,0,0.2),0px 4px 5px 0px rgba(200,0,0,0.14),0px 1px 30px 0px rgba(200,0,0,0.4)",
    },
    tabsRoot: {
      minHeight: "unset",
      height: theme.spacing(3.5),
      "& .MuiTab-wrapper": { transitionDuration: 100 },
      "& .MuiTab-wrapper:hover": { transform: "translateY(-2px)" },
    },
    tab: {
      textShadow: `1px 1px 0.5px ${theme.palette.primary.dark}, 0px 0px 2px ${theme.palette.primary.dark}`,
      minHeight: "unset",
      height: theme.spacing(3.5),
      fontWeight: 400,
    },
    tabsIndicator: {
      backgroundColor: theme.palette.error.main,
    },
    tabSelected: {
      fontWeight: 500,
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    allowPointer: {
      pointerEvents: "unset!important",
    },
    lightTooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.9)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  };
};

function AppNavBar({ appPath, studySelected, classes, query, tabs, sysAdminMode }) {
  return (
    <AppBar
      position="static"
      className={`${classes.appBar} ${sysAdminMode && classes.appBarSysAdmin}`}
    >
      <Tabs
        value={appPath}
        onChange={(event, value) => {
          setAppPath(value);
        }}
        variant="scrollable"
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        {tabs.map(tab => (
          <Tab
            key={tab.route}
            value={tab.route + query}
            className={classes.tab}
            label={
              <Tooltip title={tab.toolTip}>
                <span>{tab.title}</span>
              </Tooltip>
            }
            disabled={tab.requiresStudy && !studySelected}
            classes={{ disabled: classes.allowPointer, selected: classes.tabSelected }}
          />
        ))}
      </Tabs>
    </AppBar>
  );
}

AppNavBar.propTypes = {
  appPath: PropTypes.string,
  studySelected: PropTypes.bool,
  query: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      toolTip: PropTypes.string,
      route: PropTypes.string,
      title: PropTypes.string,
      requireStudy: PropTypes.bool,
    })
  ),
  sysAdminMode: PropTypes.bool,
};

export default withStyles(styles)(AppNavBar);
