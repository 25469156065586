import React from "react";
import { Checkbox } from "@material-ui/core";
import Cell from "./Cell";

function CheckboxCell({ selected, onClick, disabled }) {
  const onClickStopped = event => {
    event.stopPropagation(); // to prevent selection by row click
    onClick(event.shiftKey);
  };

  return (
    <Cell width={45} onClick={!disabled ? onClickStopped : null} flexGrow={0} noEllipses>
      <Checkbox checked={selected} disabled={disabled} />
    </Cell>
  );
}

export default React.memo(CheckboxCell);
