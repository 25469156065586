import {
  STUDY_SET_LIST,
  STUDY_SET_SELECTED,
  STUDY_NOT_LOADED,
  STUDY_UPDATE_EXPORT_STATUS,
  STUDY_SHOW_EXPORT_DETAILS,
  STUDY_HIDE_EXPORT_DETAILS,
  STUDY_UPDATE,
  STUDY_SET_ONE,
  ALL_DATA_SET_LIST,
  STUDY_LINKING_IN_PROGESS,
  STUDY_LINKING_DONE,
} from "../action-types";

import { DATA_SERVER_URL } from "../../config";
import {
  snackbarError,
  basicSnackbar,
  removeSnackbar,
  customSnackbar,
} from "../snackbar/snackbar-actions";
import { listFilesApi, downloadFilesApi } from "../files/files-actions";
import { readAnimalList } from "../animals/animals-actions";
import { readDeploymentList } from "../deployments/deployments-actions";
import { readDeviceList } from "../devices/devices-actions";
import { axios, extractMessage, callGqlApi } from "../../helpers/api";
import { handleGqlErrors } from "../gql-error/gql-error-actions";
import history, { removeQString } from "../../helpers/history";
import distinctColors from "distinct-colors";
import gql from "../gqlTag";
import { typeToApiFields } from "../../components/study/constants";
/*
  Default set to 15 so we'll have consistent colouring as long as studies <= 15
  > 15 studies adding a new study may change an old studies colour.
  This is a compromise to get consistent colouring most of the time without hard coding
  a bunch of colours
*/
function assignStudyColour(studies) {
  let colour_count = 15;
  if (studies.length > 15) {
    colour_count = studies.length;
  }
  const palette = distinctColors({ count: colour_count, lightMin: 50 });
  return studies.map((study, i) => {
    return {
      colour: palette[i].hex(),
      ...addCountsToStudy(study),
    };
  });
}

function addCountsToStudy(study) {
  return {
    ...study,
    fileCount: study.files.length,
    deviceCount: study.devices.length,
    animalCount: study.animals.length,
    deploymentCount: study.deployments.length,
  };
}

// got data
function setStudies(studies) {
  return {
    type: STUDY_SET_LIST,
    payload: { studies: assignStudyColour(studies), isLoaded: true },
  };
}

// get data - set loading
export function setStudiesNotLoaded() {
  return {
    type: STUDY_NOT_LOADED,
  };
}

// One of the studies has been selected:
function setSelectedStudy(selectedId) {
  localStorage.setItem("selectedStudyId", selectedId);
  return {
    type: STUDY_SET_SELECTED,
    payload: { selectedId },
  };
}

export function selectStudy(study) {
  return dispatch => {
    if (!study) {
      removeQString("study");
      dispatch(setSelectedStudy(null));
    } else {
      dispatch(setSelectedStudy(study.id));
    }
  };
}

export function createStudy(input) {
  const createMutation = gql`
    mutation ($input: CreateStudyInput!) {
      createStudy(input: $input) {
        study {
          id
        }
      }
    }
  `;
  return dispatch => {
    callGqlApi(createMutation, { input })
      .then(response => dispatch(listStudies()).then(() => response.createStudy.study.id))
      .then(studyId => history.push("?study=" + studyId))
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

export function updateStudy(input) {
  const updateMutation = gql`
    mutation ($input: UpdateStudyInput!) {
      updateStudy(input: $input) {
        study {
          id
        }
      }
    }
  `;
  return dispatch => {
    dispatch({
      type: STUDY_UPDATE,
      payload: input,
    });
    callGqlApi(updateMutation, { input })
      .then(() => {
        dispatch(listStudies());
      })
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

export function deleteStudy(studyId) {
  const deleteMutation = gql`
    mutation ($input: DeleteStudyInput!) {
      deleteStudy(input: $input) {
        deletedStudyId
      }
    }
  `;
  return (dispatch, getState) => {
    // if deleting currently selected study, remove studyId from URL
    if (getState().study.selectedId === studyId) {
      history.push(history.location.pathname);
    }

    callGqlApi(deleteMutation, { input: { studyId } })
      .then(() => {
        dispatch(listStudies());
        dispatch(basicSnackbar("Study deleted", "success"));
      })
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

const studyFields = gql`
  fragment studyFields on Study {
    id
    name
    description
    devices {
      id
    }
    animals {
      id
      devices {
        transmitters {
          displayId
        }
      }
    }
    deployments {
      id
    }
    files {
      name
      type
      processing
      error
    }
    hasDevicePositionConflicts
    networkMetadata {
      otn {
        collectionCode
      }
      submissions {
        id
        network
        status
        time
      }
    }
  }
`;

export function listStudies() {
  const studiesQuery = gql`
    query {
      studies {
        ...studyFields
      }
    }
    ${studyFields}
  `;

  return dispatch => {
    return callGqlApi(studiesQuery)
      .then(data => dispatch(setStudies(data.studies)))
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

export function readStudy(id, includeDevicePositions) {
  const query = gql`
    query study($id: ID!) {
      study(id: $id) {
        ...studyFields
        ${includeDevicePositions ? gql`devicePositions { start }` : ``}
      }
    }
    ${studyFields}
  `;

  return dispatch => {
    return callGqlApi(query, { id })
      .then(data => {
        dispatch({
          type: STUDY_SET_ONE,
          payload: { study: addCountsToStudy(data.study) },
        });
        return data.study;
      })
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

function updateExportStatus({ studyId, filename, title, subTitle, actionMessage, details }) {
  return {
    type: STUDY_UPDATE_EXPORT_STATUS,
    payload: {
      studyId,
      filename,
      title,
      subTitle,
      actionMessage,
      details,
    },
  };
}

export function exportStudyApi(filename, studyId, network, submitToNetwork, comment) {
  return (dispatch, getState) => {
    const study = studyId
      ? getState().study.studies.find(study => study.id === studyId)
      : getState().study.selected;

    const email = getState().user.user.attributes.email;

    if (!study) {
      dispatch(snackbarError("No study found for submission."));
    }

    const url = DATA_SERVER_URL + "/studies/" + study.id;
    const data = submitToNetwork
      ? {
          name: filename,
          email,
          comment,
          network,
        }
      : {};

    const config = {
      params: {
        action: submitToNetwork ? "submit" : "export",
        name: filename,
      },
    };

    axios
      .post(url, data, config)
      .then(exportStudy(dispatch, filename, submitToNetwork, study.id))
      .catch(error => {
        const message = extractMessage(error);
        const exportStatus = {
          studyId: study.id,
          title: `Study ${submitToNetwork ? "submission" : "export"} error`,
          subTitle: submitToNetwork
            ? "Uploading files to Telemetry Network failed:"
            : "Creation of export files failed:",
          actionMessage: `Please address these issues and then ${
            submitToNetwork ? "submit" : "export"
          } your study again.`,
          details: [{ mode: "error", message }],
        };
        dispatch(updateExportStatus(exportStatus));
        dispatch(
          customSnackbar("study-export-generic-error", {
            message: exportStatus.title,
            studyId: study.id,
          })
        );
      });
  };
}

function exportStudy(dispatch, filename, submitToNetwork, studyId) {
  return async function (response) {
    const jobId = response.data.id;

    // keys for notifications
    const progressKey = "study-export-progress-" + jobId;
    const successKey = "study-export-complete" + jobId;

    const snkMsg = submitToNetwork ? "Study Submission in Progress" : "Preparing Study Export";

    dispatch(customSnackbar("progress", { message: snkMsg }, progressKey));
    dispatch(
      updateExportStatus({
        studyId,
        filename,
        details: [{ mode: "info", message: response.data.error }],
      })
    );

    const url = DATA_SERVER_URL + "/jobs/" + jobId;
    let warnings = [];
    while (true) {
      try {
        const response = await axios.get(url);
        const resCode = response.status;

        if (resCode === 202) {
          // active
          if (response.data.warnings?.length > 0) {
            warnings = [...warnings, ...response.data.warnings];
          }
        } else if (resCode === 200) {
          // success
          const exportStatus = {
            studyId,
            filename,
            title: `Study ${!submitToNetwork ? "export" : "submission"} success ${
              warnings.length ? " with warnings" : ""
            }`,
            subTitle: warnings.length
              ? `Creation of export files warning${warnings.length > 1 ? "s" : ""}:`
              : "Well done!",
            actionMessage: "",
            details: warnings.map(message => ({ mode: "warning", message })),
          };
          if (warnings && warnings.length > 0) {
            dispatch(
              customSnackbar("study-export-generic-warnings", {
                studyId,
                message: exportStatus.title,
              })
            );
          }
          if (!submitToNetwork) {
            dispatch(listFilesApi()).then(async fileList => {
              /**
               * Due to file server behaviour the created export file is
               * not available in the file list right away. This will
               * poll until it is in the file list then download the
               * export file
               */
              let exists = fileList.find(f => f.name === filename);
              while (!exists || !exists.id) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                fileList = await dispatch(listFilesApi());
                exists = fileList.find(f => f.name === filename);
              }
              dispatch(removeSnackbar(progressKey));
              dispatch(updateExportStatus(exportStatus));
              dispatch(downloadFilesApi("", [filename]));
            });
          } else {
            dispatch(updateExportStatus(exportStatus));
            dispatch(removeSnackbar(progressKey));
            dispatch(customSnackbar("study-export-complete", { filename: filename }, successKey));
            // update submissions
            dispatch(readStudy(studyId));
          }
          dispatch(listFilesApi());
          break;
        } else {
          dispatch(snackbarError("Unknown study submission state."));
          break;
        }
      } catch (e) {
        const error = e.response.data || {};
        const details = (error.detail?.results || [error.message]).map(message => ({
          mode: "error",
          message,
        }));
        const exportStatus = {
          studyId,
          filename,
          title: `Study ${
            error.id === "study_validate" ? "validation" : submitToNetwork ? "submission" : "export"
          } error`,
          subTitle:
            error.id === "study_validate"
              ? `There ${
                  details.length > 1 ? "were issues" : "was an issue"
                } detected in your study that made it invalid for submission to OTN:`
              : submitToNetwork
              ? "Uploading files to Telemetry Network failed:"
              : "Creation of export files failed:",
          actionMessage: `Please address these issues and then ${
            submitToNetwork ? "submit" : "export"
          } your study again.`,
          details,
        };
        dispatch(updateExportStatus(exportStatus));
        if (error.id === "study_validate") {
          // validation error: includes details
          dispatch(customSnackbar("study-export-validation-error", { studyId }));
        } else {
          console.error(error);
          // other error: just message
          dispatch(
            customSnackbar("study-export-generic-error", { message: exportStatus.title, studyId })
          );
        }
        dispatch(removeSnackbar(progressKey));
        break;
      }
    }
  };
}

export function showStudyExportDetails(studyId) {
  return dispatch => {
    dispatch({
      type: STUDY_SHOW_EXPORT_DETAILS,
      payload: { studyId },
    });
  };
}

export function hideStudyExportDetails(studyId) {
  return {
    type: STUDY_HIDE_EXPORT_DETAILS,
    payload: { studyId },
  };
}

// eagerly updates the study in the state assuming the api request completes
export function updateStudyNoApi(linkInput, mode) {
  return (dispatch, getState) => {
    const currentStudy = getState().study.studies.find(s => s.id === linkInput.studyId);

    function remove(field) {
      const apiField = typeToApiFields[field];
      const idField = field === "files" ? "name" : "id";

      // filter out removed items
      currentStudy[field] = [
        ...currentStudy[field].filter(d => !linkInput[apiField].includes(d[idField])),
      ];
    }

    function add(field) {
      const apiField = typeToApiFields[field];
      const idField = field === "files" ? "name" : "id";

      // get which items have been added
      const currentData = field === "files" ? getState().files.fileList : getState()[field][field];
      const currentIds = currentStudy[field].map(d => d[idField]);
      const idsToAdd = linkInput[apiField].filter(id => !currentIds.includes(id));

      // combine current items and new items
      const newData = [
        ...currentStudy[field],
        ...currentData.filter(d => idsToAdd.includes(d[idField])),
      ];

      currentStudy[field] = newData;
    }

    if (mode === "add") {
      linkInput.deviceIds && add("devices", linkInput);
      linkInput.animalIds && add("animals", linkInput);
      linkInput.deploymentIds && add("deployments", linkInput);
      linkInput.filePaths && add("files", linkInput);
    } else {
      linkInput.deviceIds && remove("devices", linkInput);
      linkInput.animalIds && remove("animals", linkInput);
      linkInput.deploymentIds && remove("deployments", linkInput);
      linkInput.filePaths && remove("files", linkInput);
    }

    dispatch({
      type: STUDY_SET_ONE,
      payload: { study: addCountsToStudy(currentStudy) },
    });
  };
}

export function addToStudy(linkStudyInput, eagerRefresh = false) {
  return dispatch => {
    dispatch({
      type: STUDY_LINKING_IN_PROGESS,
    });
    const addToStudyMutation = gql`
      mutation addToStudy($input: AddToStudyInput!) {
        addToStudy(input: $input) {
          study {
            id
          }
        }
      }
    `;

    // update the data before the api request is complete to give good linking experience in table
    if (eagerRefresh) {
      dispatch(updateStudyNoApi(linkStudyInput, "add"));
    }

    callGqlApi(addToStudyMutation, { input: linkStudyInput })
      .then(() => {
        if (!eagerRefresh) {
          dispatch(updateStudies(linkStudyInput));
        }
      })
      .catch(err => dispatch(handleGqlErrors(err)))
      .finally(() => {
        dispatch({
          type: STUDY_LINKING_DONE,
        });
      });
  };
}

export function removeFromStudy(unLinkStudyInput, eagerRefresh = false) {
  return dispatch => {
    dispatch({
      type: STUDY_LINKING_IN_PROGESS,
    });
    const removeFromStudyMutation = gql`
      mutation removeFromStudy($input: RemoveFromStudyInput!) {
        removeFromStudy(input: $input) {
          study {
            id
          }
        }
      }
    `;

    // update the data before the api request is complete to give good linking experience in table
    if (eagerRefresh) {
      dispatch(updateStudyNoApi(unLinkStudyInput, "remove"));
    }

    callGqlApi(removeFromStudyMutation, { input: unLinkStudyInput })
      .then(() => {
        if (!eagerRefresh) {
          dispatch(updateStudies(unLinkStudyInput));
        }
      })
      .catch(err => dispatch(handleGqlErrors(err)))
      .finally(() => {
        dispatch({
          type: STUDY_LINKING_DONE,
        });
      });
  };
}

export function updateStudies(input) {
  return dispatch => {
    if (input.deviceIds) {
      dispatch(readDeviceList());
    }
    if (input.animalIds) {
      dispatch(readAnimalList());
    }
    if (input.deploymentIds) {
      dispatch(readDeploymentList());
    }
    if (input.filePaths) {
      dispatch(listFilesApi());
    }
    dispatch(listStudies());
  };
}

export function querySummaryData() {
  return async dispatch => {
    const allQuery = gql`
      query {
        files {
          name
        }
        devices {
          id
        }
        animals {
          id
        }
        deployments {
          id
        }
      }
    `;

    callGqlApi(allQuery)
      .then(response => {
        dispatch({
          type: ALL_DATA_SET_LIST,
          payload: {
            filesCount: response.files.length,
            devicesCount: response.devices.length,
            animalsCount: response.animals.length,
            deploymentsCount: response.deployments.length,
          },
        });
      })
      .catch(() => {
        dispatch(snackbarError("Failed to load summary data"));
      });
  };
}

export function updateStudyMetadata(input) {
  return dispatch => {
    const mutation = gql`
      mutation ($input: UpdateStudyNetworkMetadataInput!) {
        updateStudyNetworkMetadata(input: $input) {
          study {
            id
            networkMetadata {
              otn {
                collectionCode
              }
            }
          }
        }
      }
    `;
    return callGqlApi(mutation, { input })
      .then(data => {
        dispatch(readStudy(data.updateStudyNetworkMetadata.study.id));
      })
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

export function copyStudyToWorkspace(input) {
  return dispatch => {
    const mutation = gql`
      mutation ($input: CopyStudyToWorkspaceInput!) {
        copyStudyToWorkspace(input: $input) {
          study {
            id
          }
        }
      }
    `;

    return callGqlApi(mutation, { input })
      .then(() => {
        dispatch(basicSnackbar("Study copied successfully", "success"));
      })
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}
