import React, { useEffect, useState, useRef } from "react";
import * as d3 from "d3";

import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Typography,
  Tooltip,
  IconButton,
  TextField,
  MenuItem,
  Popover,
  Button,
} from "@material-ui/core";
import {
  Delete as IconDelete,
  Add as IconAdd,
  Menu as IconMenu,
  SaveAlt,
  Check as IconCheck,
} from "@material-ui/icons";

//#region Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartMore from "highcharts/highcharts-more";
import HighchartExport from "highcharts/modules/exporting";
import HighchartExportData from "highcharts/modules/export-data";
HighchartMore(Highcharts);
HighchartExport(Highcharts);
HighchartExportData(Highcharts);
//#endregion Highcharts

import { useSelectorTyped as useSelector, useThunkDispatch, StoreDispatch } from "../redux/common";
import { listFilesApi } from "../redux/files/files-actions";
import { readDeploymentList } from "../redux/deployments/deployments-actions";
import { readDeviceList } from "../redux/devices/devices-actions";

import { WindowedTable, SpinnerInnovasea } from "../fathom-brella";
import DeploymentMap from "../components/deployments/DeploymentMap";
import FlexRow from "../components/common/FlexRow";
import ResizeHandler from "../components/common/ResizeHandler";
import ResizableSplitPanel from "../components/common/ResizableSplitPanel";
import DialogWrapper from "../components/common/DialogWrapper";
import { formatDevice } from "../helpers/device";
import {
  DetectionDataPoint,
  DeviceTxCounts,
  parseData,
} from "../helpers/detection-analysis-parser";

import SelectTx from "../components/detection-analysis/SelectTx";
import SelectRx from "../components/detection-analysis/SelectRx";
import PositionSummary from "../components/detection-analysis/PositionSummary";

import { formatTimeFromISOStringDt, parseDateTime } from "../helpers/time";

import { getDistanceFromLatLonInKm } from "../helpers/map";

import {
  setTxList as _setTxList,
  setRxList as _setRxList,
  fetchDetectionAnalysisData,
} from "../redux/detection-analysis/detection-analysis-actions";
import FlexCol from "../components/common/FlexCol";
import DateTimePicker from "../components/common/DateTimePicker";
import DistanceNote from "../components/detection-analysis/DistanceNote";
import { SubTitle, NoDataPrompt } from "../components/common/typography";

//#region TYPES

export type Receiver = {
  deviceId: string;
  model: string;
  serial: string;
  positions: DevicePosition[] | null;
  rxLogFiles: string[];
  selectedPositionId: string;
  position?: DevicePosition;
};

export type Transmitter = {
  deviceId: string;
  fullId: string;
  // tagIds: string[];
  model: string;
  serial: string;
  delays: TransmitterDelay[];
  positions: DevicePosition[] | null;
  // selectedTagId: string;
  selectedDelay: TransmitterDelay;
  selectedPositionId: string;
};

export type TransmitterDelayStep = {
  number: number;
  state: string;
  duration: number;
  power: string;
  ppmCodespace: string;
  ppmMin: number;
  ppmMax: number;
  sampleWindow?: string;
  hrCodespace: string;
  hrMin: number;
  hrMax: number;
  htiConfigOption?: string;
  htiPingRatio?: string;
};

export type TransmitterDelay = {
  /** One of { 1 | 2 | 3 | 4 } as transmitter delay sequence step number, or 0 for manual */
  number: number;
  // isPPM: string;
  min?: number;
  max?: number;
  minMan?: number;
  maxMan?: number;
};

export type TxSpec = {
  deviceId: string;
  /** Transmitter ID (full ID string) */
  fullId: string;
  /** Reference to step number of transmitterDelaySequence, or 0 if user custom */
  delaySequenceStep: number;
  /** Min delay */
  delayMin: number;
  /** Max delay */
  delayMax: number;
  /** Limit to one position to reduce complexity */
  position: DevicePosition | null;
};

export type DevicePosition = {
  deviceId: string;
  deploymentId: string;
  /** This is the position id, BUT the start and end times in this struct may not be the ones in
   * the database record; i.e. they may be a combination of deployment / device / position times */
  positionId: string;
  stationName: string | null;
  start: string;
  end: string;
  latitude: number | null;
  longitude: number | null;
};

type SelectOption<T> = { value: T; label: string };

type ChartMode = "distance" | "time";
const CHART_MODE_OPTIONS: SelectOption<ChartMode>[] = [
  { value: "distance", label: "By distance" },
  { value: "time", label: "By time" },
];

type GroupByVar = "all" | "daynight" | "tag" | "receiver";
const GROUP_BY_OPTIONS: SelectOption<GroupByVar>[] = [
  { value: "all", label: "All" },
  { value: "daynight", label: "Day / Night" },
  { value: "tag", label: "Tag" },
  { value: "receiver", label: "Receiver" },
];

export type TimeInterval = 1 | 2 | 3 | 4 | 6 | 8 | 12;
const TIME_INTERVAL_OPTIONS: SelectOption<TimeInterval>[] = [
  { value: 1, label: "1 hour" },
  { value: 2, label: "2 hours" },
  { value: 3, label: "3 hours" },
  { value: 4, label: "4 hours" },
  { value: 6, label: "6 hours" },
  { value: 8, label: "8 hours" },
  { value: 12, label: "12 hours" },
];

/** Receiver units with "self" transmitters (e.g. VR2Tx) are often (but not necessarily) configured
 * with these nominal delays. The programming is configuratble by the user, so no attempt is made
 * to use the data from syspro. */
const selfTransmitterDelays: TransmitterDelay[] = [
  { number: 1, min: 60, max: 120 },
  { number: 2, min: 90, max: 90 },
  { number: 3, min: 540, max: 660 },
];
/** recievers of the following models are pre-populated with the selfTransmitterDelays */
const modelsUseNominalDelays = ["VR2Tx", "VR2AR"]; // there is also "HR2" but the HR support is pending
//#endregion

function getChartOptions(
  chartMode: ChartMode,
  groupBy: GroupByVar,
  dataPoints: DetectionDataPoint[],
  dayStart: string,
  dayEnd: string,
  txSelected: TxSpec,
  rxSelected: Receiver,
  timeInterval: TimeInterval
) {
  const distanceGroupByTitles = {
    all: "All devices",
    daynight: "All devices, day vs. night",
    tag: "Tag comparison",
    receiver: "Receiver comparison",
  };

  // For rx/tx comparison only:
  const rxStr = `${rxSelected?.model}-${rxSelected?.serial}`;
  const txStr = txSelected?.fullId;
  const distance = distanceBetween(txSelected, rxSelected) + "m";

  const titles = {
    chart: `Detection % over ${chartMode}`,
    sub: `${
      chartMode === "distance"
        ? distanceGroupByTitles[groupBy]
        : `${rxStr} : ${txStr} [${distance}]`
    } (${timeInterval}hr intervals)`,
    xAxis: (chartMode === "distance" && "Distance (m)") || "Time",
    yAxis: `Detection %`,
  };
  const series: any[] = [];

  // default options for all charts:
  const chartOptions: Highcharts.Options = {
    time: {
      useUTC: false,
    },
    title: {
      text: titles.chart,
      y: 25,
    },
    subtitle: { text: titles.sub, y: 45 },
    exporting: { enabled: false }, // disables default chart menu so we can customise
    chart: {
      animation: false,
      marginTop: 60,
      panning: { enabled: true, type: "x" },
      zoomType: "x",
    },
    plotOptions: {
      line: {
        shadow: true,
      },
      errorbar: {
        showInLegend: true,
        lineWidth: 2,
        shadow: true,
      },
      scatter: {
        showInLegend: true,
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      floating: false,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: "#3333",
      backgroundColor: "white",
    },
    xAxis: {
      title: {
        text: titles.xAxis,
      },
      type: chartMode === "time" ? "datetime" : "linear",
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      min: chartMode === "time" ? null : 0,
    },
    yAxis: {
      title: {
        text: titles.yAxis,
      },
      max: 120,
      min: 0,
    },
    series: series,
  };
  if (chartMode === "time") {
    const detPerInterval: { [interval: string]: number[] } = {};
    dataPoints
      .filter(dp => dp?.rx?.deviceName == rxStr && dp?.tx?.fullId == txStr)
      .forEach(({ period, detPercent }) => {
        if (!detPerInterval[period]) {
          detPerInterval[period] = [];
        }
        detPerInterval[period].push(detPercent);
      });
    const intervals = Object.keys(detPerInterval).sort((a, b) => Number(a) - Number(b));

    series.push({
      type: "line",
      name: `Detection % @ ${distance}`,
      shadow: true,
      color: "rgba(4, 60, 74, 0.9)",
      data: intervals.map(interval => [
        new Date(interval).valueOf(),
        Number(((d3.mean(detPerInterval[interval]) || 0) * 100).toFixed(2)),
      ]),
      marker: {
        lineWidth: 1,
        lineColor: "#333",
        fillColor: "rgba(255,255,255,0.5)",
        symbol: "triangle",
      },
      animation: false,
      tooltip: {
        headerFormat: "<b>{series.name}</b><br>",
        pointFormat: "{point.y}%",
      },
    });
  } else {
    switch (groupBy) {
      case "tag": {
        /** Array of distance (x) and detection % (y) per receiver */
        const dataByTx: {
          [fullId: string]: {
            /** distance */
            x: number;
            /** detection % */
            y: number;
          }[];
        } = {};

        // organise data by receiver:
        dataPoints.forEach(({ tx, distance, detPercent }) => {
          if (!tx || distance === undefined || distance === null) return;
          const fullId = tx.fullId;
          const x = Number(distance.toFixed(2));
          const y = Number((detPercent * 100).toFixed(2));
          if (!dataByTx[fullId]) {
            dataByTx[fullId] = [];
          }
          dataByTx[fullId].push({ x, y });
        });

        const fullIds = Object.keys(dataByTx);
        /** colors per rx */
        const txColorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(fullIds);

        fullIds?.forEach(fullId => {
          const dataByDistance: { [distance: string]: number[] } = {};

          const errorBarData: {
            x: number;
            low: number;
            high: number;
          }[] = [];

          const samplesPercent: {
            x: number;
            y: number;
          }[] = [];

          const averagesAll: number[][] = [];

          dataByTx[fullId].forEach(({ x, y }) => {
            if (!dataByDistance[x]) {
              dataByDistance[x] = [];
            }
            dataByDistance[x].push(y);
            samplesPercent.push({ x, y });
          });

          const distKeys = Object.keys(dataByDistance).sort((a, b) => Number(a) - Number(b));
          distKeys.forEach(distKey => {
            const x = Number(distKey);
            const yValues = dataByDistance[distKey].sort(d3.ascending);
            const mean = d3.mean(yValues) || 0;
            const deviation = d3.deviation(yValues) || 0;
            averagesAll.push([Number(x.toFixed(2)), Number(mean.toFixed(2))]);
            errorBarData.push({
              x,
              low: Number((mean - deviation).toFixed(2)),
              high: Number((mean + deviation).toFixed(2)),
            });
          });

          series.push({
            type: "errorbar",
            name: `± 1 SD (${fullId})`,
            data: errorBarData,
            whiskerLength: "50%",
            color: "#222A",
            animation: false,
          });

          series.push({
            type: "scatter",
            name: `Detect % (${fullId})`,
            marker: {
              enabled: true,
              radius: 3,
              fillColor: txColorScale(fullId),
              lineWidth: 0,
              symbol: "circle",
            },
            animation: false,
            tooltip: {
              headerFormat: "<b>{series.name}</b><br>",
              pointFormat: "{point.y}% at {point.x}m",
            },
            data: samplesPercent,
          });

          series.push({
            type: "line",
            name: `Average Detection % (${fullId})`,
            shadow: true,
            color: txColorScale(fullId),
            data: averagesAll,
            marker: {
              lineWidth: 1,
              lineColor: "#333",
              fillColor: "rgba(255,255,255,0.5)",
              symbol: "triangle",
            },
            animation: false,
            tooltip: {
              headerFormat: "<b>{series.name}</b><br>",
              pointFormat: "{point.y}% at {point.x}m",
            },
          });
        });
        break;
      }
      case "all": {
        const dataByDistance: { [distance: string]: number[] } = {};

        const errorBarData: {
          x: number;
          low: number;
          high: number;
        }[] = [];

        const samplesPercent: {
          x: number;
          y: number;
        }[] = [];

        const averagesAll: number[][] = [];

        dataPoints.forEach(({ distance, detPercent }) => {
          if (distance === undefined || distance === null) return;
          const distKey = distance.toFixed(2);
          const y = Number((detPercent * 100).toFixed(2));
          if (!dataByDistance[distKey]) {
            dataByDistance[distKey] = [];
          }
          dataByDistance[distKey].push(y);
          samplesPercent.push({ x: Number(distKey), y });
        });

        const distKeys = Object.keys(dataByDistance).sort((a, b) => Number(a) - Number(b));
        distKeys.forEach(distKey => {
          const x = Number(distKey);
          const yValues = dataByDistance[distKey].sort(d3.ascending);
          const mean = d3.mean(yValues) || 0;
          const deviation = d3.deviation(yValues) || 0;
          averagesAll.push([Number(x.toFixed(2)), Number(mean.toFixed(2))]);
          errorBarData.push({
            x,
            low: Number((mean - deviation).toFixed(2)),
            high: Number((mean + deviation).toFixed(2)),
          });
        });

        series.push({
          type: "errorbar",
          name: "± 1 SD",
          data: errorBarData,
          whiskerLength: "50%",
          color: "#222A",
          animation: false,
        });

        series.push({
          type: "scatter",
          name: "Detect %",
          marker: {
            enabled: true,
            radius: 3,
            fillColor: "rgba(54, 99, 110,0.4)",
            lineWidth: 0,
            symbol: "circle",
          },
          animation: false,
          tooltip: {
            headerFormat: "<b>{series.name}</b><br>",
            pointFormat: "{point.y}% at {point.x}m",
          },
          data: samplesPercent,
        });

        series.push({
          type: "line",
          name: "Average Detection %",
          shadow: true,
          color: "rgba(4, 60, 74, 0.9)",
          data: averagesAll,
          marker: {
            lineWidth: 1,
            lineColor: "#333",
            fillColor: "rgba(255,255,255,0.5)",
            symbol: "triangle",
          },
          animation: false,
          tooltip: {
            headerFormat: "<b>{series.name}</b><br>",
            pointFormat: "{point.y}% at {point.x}m",
          },
        });
        break;
      }
      case "daynight": {
        const colors = {
          day: "rgba(181, 207, 204, 0.9)",
          night: "rgba(4, 60, 74, 0.9)",
        };
        const dataByDistance = {
          day: {} as { [distance: string]: number[] },
          night: {} as { [distance: string]: number[] },
        };

        const averages = {
          day: [] as number[][],
          night: [] as number[][],
        };

        dataPoints.forEach(({ period, distance, detPercent }) => {
          if (distance === undefined || distance === null) return;
          const distKey = distance.toFixed(2);
          const y = detPercent * 100;
          const timeStr = period.substring(11, 16); // this works because we know is ISO timestamp
          const dayOrNight = timeStr >= dayStart && timeStr <= dayEnd ? "day" : "night";

          if (!dataByDistance[dayOrNight][distKey]) {
            dataByDistance[dayOrNight][distKey] = [];
          }
          dataByDistance[dayOrNight][distKey].push(y);
          // samplesPercent.push({ x: Number(distKey), y });
        });

        ["day", "night"].forEach(dn => {
          const errorBarData: {
            x: number;
            low: number;
            high: number;
          }[] = [];

          const distKeys = Object.keys(dataByDistance[dn]).sort((a, b) => Number(a) - Number(b));
          distKeys.forEach(distKey => {
            const x = Number(distKey);
            const yValues = dataByDistance[dn][distKey].sort(d3.ascending);
            const mean = d3.mean(yValues) || 0;
            const deviation = d3.deviation(yValues) || 0;
            averages[dn].push([x, Number(mean.toFixed(2))]);
            errorBarData.push({
              x,
              low: Number((mean - deviation).toFixed(2)),
              high: Number((mean + deviation).toFixed(2)),
            });
          });

          series.push({
            type: "errorbar",
            name: `± 1 SD (${dn})`,
            data: errorBarData,
            whiskerLength: "50%",
            color: colors[dn],
            animation: false,
          });
        });

        series.push({
          type: "line",
          name: "Day Average",
          shadow: true,
          color: colors.day,
          data: averages.day,
          marker: {
            lineWidth: 1,
            lineColor: "#333",
            fillColor: "rgba(255,255,255,0.5)",
            symbol: "triangle",
          },
          animation: false,
          tooltip: {
            headerFormat: "<b>{series.name}</b><br>",
            pointFormat: "{point.y}% at {point.x}m",
          },
        });

        series.push({
          type: "line",
          name: "Night Average",
          shadow: true,
          color: colors.night,
          data: averages.night,
          marker: {
            lineWidth: 1,
            lineColor: "#339",
            fillColor: "rgba(255,255,255,0.5)",
            symbol: "square",
          },
          animation: false,
          tooltip: {
            headerFormat: "<b>{series.name}</b><br>",
            pointFormat: "{point.y}% at {point.x}m",
          },
        });
        break;
      }
      case "receiver": {
        /** Array of distance (x) and detection % (y) per receiver */
        const dataByRx: {
          [rxDeviceName: string]: {
            /** distance */
            x: number;
            /** detection % */
            y: number;
          }[];
        } = {};

        // organise data by receiver:
        dataPoints.forEach(({ rx, distance, detPercent }) => {
          if (!rx || distance === undefined || distance === null) return;
          const rxDeviceName = rx.deviceName;
          const x = Number(distance.toFixed(2));
          const y = Number((detPercent * 100).toFixed(2));
          if (!dataByRx[rxDeviceName]) {
            dataByRx[rxDeviceName] = [];
          }
          dataByRx[rxDeviceName].push({ x, y });
        });

        const rxDeviceNames = Object.keys(dataByRx);
        /** colors per rx */
        const rxColorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(rxDeviceNames);

        for (const rxDeviceName of rxDeviceNames) {
          /** with the constraint of only one position per receiver, there should be only
           * one "x" in this array */
          const rxDataPoints = dataByRx[rxDeviceName];
          const checkX = new Set<number>();
          const yValues: number[] = [];
          const errorBarData: {
            x: number;
            low: number;
            high: number;
          }[] = [];

          // get array of yValues and check for distance error
          rxDataPoints.forEach(({ x, y }) => {
            checkX.add(x);
            yValues.push(y);
          });
          const x = Array.from(checkX)[0];
          if (checkX.size > 1) {
            console.error(`More than one distance for ${rxDeviceName}`);
          }

          // calculate error bar data
          yValues.sort(d3.ascending);
          const mean = d3.mean(yValues) || 0;
          const deviation = d3.deviation(yValues) || 0;
          errorBarData.push({
            x,
            low: Number((mean - deviation).toFixed(2)),
            high: Number((mean + deviation).toFixed(2)),
          });

          // series for scatter points for each rx
          series.push({
            type: "scatter",
            name: rxDeviceName,
            data: rxDataPoints,
            marker: {
              enabled: true,
              radius: 3,
              fillColor: rxColorScale(rxDeviceName),
              lineWidth: 0,
              symbol: "circle",
            },
            animation: false,
            tooltip: {
              headerFormat: "<b>{series.name}</b><br>",
              pointFormat: "{point.y}% at {point.x}m",
            },
            showInLegend: false,
          });

          // series for errorbar for each rx
          series.push({
            type: "errorbar",
            name: rxDeviceName,
            data: errorBarData,
            whiskerLength: "50%",
            whiskerWidth: 2,
            color: rxColorScale(rxDeviceName),
            animation: false,
            // tooltip: {
            //   headerFormat: "<b>{series.name}</b><br>",
            //   pointFormat: "average: {point.x}%",
            // },
          });
        }
        break;
      }
      default:
        break;
    }
  }

  return chartOptions;
}

function TimeTextField({ label, value, onChange }) {
  return (
    <TextField
      label={label}
      style={{ width: 80 }}
      InputLabelProps={{ shrink: true }}
      onChange={event => onChange(event.target.value)}
      value={value}
      size="small"
    />
  );
}

function ChartMenu({ children, onOpen, onClose }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

  const menuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
    onClose();
  };

  const open = Boolean(menuAnchorEl);
  const menuId = open ? "chart-menu" : undefined;

  return (
    <div style={{ position: "absolute", top: 10, right: 10 }}>
      <IconButton
        size="small"
        // color="secondary"
        aria-describedby={menuId}
        aria-label="menu"
        onClick={menuClick}
      >
        <IconMenu />
      </IconButton>
      <Popover
        id={menuId}
        open={open}
        anchorEl={menuAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FlexRow itemSpacing={1} paddingLevel={1}>
          {children}
          <div>
            <IconButton
              size="small"
              // color="secondary"
              title="Apply changes"
              onClick={handleClose}
            >
              <IconCheck />
            </IconButton>
          </div>
        </FlexRow>
      </Popover>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  setupPanel: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    boxShadow: "inset 2px 0px 5px -4px #555",
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  timeRange: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "& .MuiFormLabel-root": { whiteSpace: "nowrap" },
  },
  controlButton: { opacity: 0.3, "&:hover": { opacity: 1 } },
}));

function fetchData(dispatch: StoreDispatch) {
  dispatch(listFilesApi());
  dispatch(readDeploymentList());
  dispatch(readDeviceList());
}

function distanceBetween(tx: TxSpec, rx: Receiver) {
  if (rx?.position && tx?.position) {
    return Math.floor(
      getDistanceFromLatLonInKm(
        tx.position.latitude,
        tx.position.longitude,
        rx.position.latitude,
        rx.position.longitude
      ) * 1000
    );
  }
  return;
}

function DetectionAnalysis() {
  const dispatch = useThunkDispatch();
  const classes: any = useStyles();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  //#region STATE
  const [selectTagsOpen, setSelectTagsOpen] = useState(false);
  const [selectRxOpen, setSelectRxOpen] = useState(false);
  const [removeTag, setRemoveTag] = useState<string | null>(null);
  const [removeRx, setRemoveRx] = useState<string | null>(null);

  const [chartMode, setChartMode] = useState<ChartMode>("distance");
  const [groupVar, setGroupVar] = useState<GroupByVar>("all");
  const [timeInterval, setTimeInterval] = useState<TimeInterval>(1);

  const [dayStart, setDayStart] = useState("06:00");
  const [dayEnd, setDayEnd] = useState("18:00");
  const [timeStart, setTimeStart] = useState(""); // get date range from deployments
  const [timeEnd, setTimeEnd] = useState("");
  const [timeSetByUser, setTimeSetByUser] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [rxSelectedIdx, setRxSelectedIdx] = useState(0);
  const [txSelectedIdx, setTxSelectedIdx] = useState(0);

  //#endregion

  function setTxList(txList: TxSpec[]) {
    dispatch(_setTxList(txList));
  }
  function setRxList(rxList: Receiver[]) {
    dispatch(_setRxList(rxList));
  }

  const {
    workspaceId,
    deployments,
    transmitters,
    receivers,
    txList,
    rxList,
    detectionData,
    dataStatus,
    deviceTxCounts,
    utcOffset,
    error,
    dataReady,
  } = useSelector(({ workspaces, deployments, devices, detdiag, user, study }) => {
    const dataReady =
      !workspaces.loading && !devices.loading && !deployments.loading && study.isLoaded;
    const transmitters: Transmitter[] = [];
    const receivers: Receiver[] = [];
    const positionMap: { [key: string]: DevicePosition[] } = {};
    const deviceTxCounts: DeviceTxCounts = {};
    const utcOffset: string = user.selectedOffset;
    const selected = {
      dataReady,
      workspaceId: (workspaces as any).selectedWorkspace?.id,
      txList: detdiag.txList,
      rxList: detdiag.rxList,
      detectionData: detdiag.data,
      dataStatus: detdiag.dataStatus,
      error: detdiag.error,
      deployments: [] as any,
      transmitters,
      receivers,
      deviceTxCounts,
      utcOffset,
    };
    if (!dataReady) {
      return selected;
    }
    const studySelectedId = study.selectedId;
    const selectedStudy =
      studySelectedId && study.studies.find((study: any) => study.id === studySelectedId);

    // filter for objects in study (or all if no study selected)
    let _deployments, _devices;
    if (selectedStudy) {
      const studyObjectIds = {
        devices: (selectedStudy.devices || []).map(d => d.id),
        deployments: (selectedStudy.deployments || []).map(d => d.id),
      };
      _deployments = (deployments.deployments || []).filter(d =>
        studyObjectIds.deployments.includes(d.id)
      );
      _devices = (devices.devices || []).filter(d => studyObjectIds.devices.includes(d.id));
    } else {
      _deployments = deployments.deployments || [];
      _devices = devices.devices || [];
    }

    // Index deployments by deviceId and reduce structure to simpler model for processing:
    _deployments.forEach(d => {
      // Establish deployment time range:
      // Start is required and device attach and station position times cannot be earlier, but end
      // is optional. Determine effective end by the greatest device attachment time or
      // position end time or now.
      const dStart = d.start;
      const deploymentId = d.id;
      const stationName = d.station?.name || "";
      let dEnd = d.end || d.start;
      if (dEnd === dStart) {
        // no end time given
        d.deviceAttachments?.forEach(da => {
          if (da?.end > dEnd) dEnd = da.end;
        });
        d.positions?.forEach(p => {
          if (p?.end > dEnd) dEnd = p.end;
        });
        // if still no end time, send end to right now:
        if (dEnd === dStart) dEnd = new Date().toISOString();
      }

      d.deviceAttachments?.forEach(da => {
        const deviceId = da.device.id;
        // Flatten deployment positions since this device attachment time frame may or may not
        // overlap the position(s') time frame
        if (!positionMap[deviceId]) {
          positionMap[deviceId] = [];
        }
        // Discerning positions gets tricky with the structure we have:
        // Simple case: [start, end] is only deployment's [start, end] and there is one position.
        //    *** This accounts for 99% of the production data as of 2021-10-28 (I checked - all
        //        the deployments with device and position times are actually the same times, and
        //        was from an import I did for SC DNR) ***
        // Complications:
        // 1: Device has a [start, end] (but no position times)
        //    * Solution: use device times (already done above; this is simple)
        // 2: Position has a [start, end] (but no device times)
        //    * Solution: use position times
        // 3: device & position have times (but there is only one of each)
        //    * Solution: resolve overlap (largest start, smallest end && start < end)
        // 4: There is more than one position (then they must have times)
        //    * Solution: If no device times, just use position times
        //        If device times, resolve overlap

        const dp = d.positions;
        dp?.forEach(p => {
          let start = da.start || dStart;
          let end = da.end || dEnd;
          // Determine the effective position times and if it is a valid position for tihs device
          const pStart = p.start || start;
          const pEnd = p.end || end;
          let isValidPos = true;

          if (pStart > end || pEnd < start || !p.latLon?.latitude || !p.latLon?.longitude) {
            isValidPos = false; // posittion start time does not lie within device time
          }
          if (isValidPos) {
            // if there is a position start time, use it only if it is after than the device start
            // time and before the device end time
            if (pStart > start && pStart < end) {
              start = pStart;
            }

            // if there is a position end time, use it only if it is before than the device end time
            // and after the device start time.
            if (pEnd > start && pEnd < end) {
              end = pEnd;
            }
            positionMap[deviceId].push({
              deviceId,
              positionId: p.id,
              deploymentId,
              stationName,
              start,
              end,
              latitude: p.latLon?.latitude,
              longitude: p.latLon?.longitude,
            });
          }
        });
      });
    });

    // Parse devices into transmitters and receivers:
    _devices.forEach(dev => {
      // Transmitters:
      if (dev.transmitters.length > 0) {
        deviceTxCounts[dev.id] = dev.transmitters.length;
        dev.transmitters.forEach(({ displayId: fullId, codingId }) => {
          if (fullId === null) {
            return;
          }

          const delays: TransmitterDelay[] = [];
          // This is a bit tricky since a single "transmit delay step" can have both an HR
          // and PPM component (i.e. same power and state for both IDs)
          // select the first "ON" step that matches the first transmit id codespace
          dev.transmitterDelaySequence?.steps?.forEach((delayStep: TransmitterDelayStep) => {
            if (codingId == delayStep.ppmCodespace) {
              delays.push({
                number: delayStep.number,
                min: delayStep.ppmMin,
                max: delayStep.ppmMax,
              });
            } else if (codingId == delayStep.hrCodespace) {
              delays.push({ number: delayStep.number, min: delayStep.hrMin, max: delayStep.hrMax });
            }
          });

          // Add options for delays for receiver self transmitters
          if (modelsUseNominalDelays.includes(dev.model)) {
            selfTransmitterDelays.forEach(delay => delays.push({ ...delay }));
          }

          transmitters.push({
            deviceId: dev.id,
            model: dev.model,
            serial: dev.serial,
            fullId,
            delays: [...delays],
            positions: positionMap[dev.id] || null,
            selectedDelay: { ...delays?.[0] },
            selectedPositionId: positionMap[dev.id]?.[0]?.positionId || "",
          });
        });
      }
      if (dev.capabilities.includes("RECEIVER")) {
        receivers.push({
          deviceId: dev.id,
          model: dev.model,
          serial: dev.serial,
          positions: positionMap[dev.id] || null,
          selectedPositionId: positionMap[dev.id]?.[0]?.positionId || "",
          rxLogFiles: dev.rxLogFiles,
        });
      }
    });
    // Sort transmitter & receiver list by station number
    transmitters.length &&
      transmitters.sort((a, b) => {
        const aStationNum = Number(
          (
            (a.positions?.filter(p => p.positionId === a.selectedPositionId) || [])[0]
              ?.stationName || ""
          ).replace(/\D/g, "")
        );
        const bStationNum = Number(
          (
            (b.positions?.filter(p => p.positionId === b.selectedPositionId) || [])[0]
              ?.stationName || ""
          ).replace(/\D/g, "")
        );
        return aStationNum - bStationNum;
      });

    receivers.length &&
      receivers.sort((a, b) => {
        const aStationNum = Number(
          (
            (a.positions?.filter(p => p.positionId === a.selectedPositionId) || [])[0]
              ?.stationName || ""
          ).replace(/\D/g, "")
        );
        const bStationNum = Number(
          (
            (b.positions?.filter(p => p.positionId === b.selectedPositionId) || [])[0]
              ?.stationName || ""
          ).replace(/\D/g, "")
        );
        return aStationNum - bStationNum;
      });

    selected.deployments = _deployments;
    return selected;
  });

  // Save references to parameters that determine the need for an expensive re-query when changed
  const paramRefInterval = useRef(timeInterval);
  const paramRefStart = useRef(timeStart);
  const paramRefEnd = useRef(timeEnd);
  const paramRefTxList = useRef(txList);
  const paramRefRxList = useRef(rxList);

  // Fetch Data:
  useEffect(() => {
    fetchData(dispatch);
  }, [dispatch, workspaceId, utcOffset]);

  useEffect(() => {
    if (
      !optionsOpen &&
      (paramRefInterval.current !== timeInterval ||
        paramRefStart.current !== timeStart ||
        paramRefEnd.current !== timeEnd ||
        paramRefTxList.current !== txList ||
        paramRefRxList.current !== rxList)
    ) {
      const serials = rxList?.map(rx => rx.serial) || [];
      const fullIds = txList?.map(tx => tx.fullId) || [];
      if (serials.length && fullIds.length) {
        dispatch(fetchDetectionAnalysisData(timeInterval, serials, fullIds, timeStart, timeEnd));
      }
      paramRefInterval.current = timeInterval;
      paramRefStart.current = timeStart;
      paramRefEnd.current = timeEnd;
      paramRefTxList.current = txList;
      paramRefRxList.current = rxList;
    }
  }, [dispatch, txList, rxList, timeInterval, timeStart, timeEnd, optionsOpen]);

  // Automatically set time range unless it has been set by the user:
  useEffect(() => {
    if (!timeSetByUser) {
      let minTime = "";
      let maxTime = "";
      txList?.forEach(tx => {
        if (tx.position) {
          if (!minTime) {
            minTime = tx.position.start;
          }
          if (!maxTime) {
            maxTime = tx.position.end;
          }
          if (tx.position.start < minTime) {
            minTime = tx.position.start;
          }
          if (tx.position.end > maxTime) {
            maxTime = tx.position.end;
          }
        }
      });
      rxList?.forEach(rx => {
        if (rx.position) {
          if (!minTime) {
            minTime = rx.position.start;
          }
          if (!maxTime) {
            maxTime = rx.position.end;
          }
          if (rx.position.start < minTime) {
            minTime = rx.position.start;
          }
          if (rx.position.end > maxTime) {
            maxTime = rx.position.end;
          }
        }
      });
      if (minTime && maxTime) {
        setTimeStart(minTime);
        setTimeEnd(maxTime);
      }
    }
  }, [txList, rxList, timeSetByUser]);

  const dataPoints = parseData({ txList, rxList, detectionData, timeInterval, deviceTxCounts });

  const visibleDeploymentIds = [
    ...txList.map(t => t.position?.deploymentId),
    ...rxList.map(r => r.position?.deploymentId),
  ];

  const rxSelected = rxList[rxSelectedIdx];
  const txSelected = txList[txSelectedIdx];
  const distanceBetweenSelected =
    chartMode === "time" ? distanceBetween(txSelected, rxSelected) : undefined;

  if (
    chartComponentRef.current?.container.current &&
    chartComponentRef.current.container.current.ondblclick === null
  ) {
    chartComponentRef.current.container.current.ondblclick = () => {
      chartComponentRef.current?.chart?.zoomOut();
    };
  }

  return (
    <>
      <ResizableSplitPanel
        direction="vertical"
        firstInit={210}
        firstMin={210}
        firstContent={
          <FlexRow fullWidth fullHeight style={{ overflow: "hidden" }}>
            <div className={classes.setupPanel}>
              <FlexRow itemSpacing={2} vAlign="center">
                <SubTitle>Transmitters</SubTitle>
                <Tooltip title="Add Tags">
                  <IconButton size="small" onClick={() => setSelectTagsOpen(true)}>
                    <IconAdd />
                  </IconButton>
                </Tooltip>
              </FlexRow>
              <div style={{ flexGrow: 1 }}>
                <WindowedTable
                  rows={txList}
                  rowIdKey={"id"}
                  columns={[
                    {
                      dataKey: "fullId",
                      label: "Transmitter ID",
                      width: 120,
                    },
                    {
                      dataKey: "delayMin",
                      label: "Min Delay",
                      width: 25,
                    },
                    {
                      dataKey: "delayMax",
                      label: "Max Delay",
                      width: 25,
                    },
                    {
                      dataKey: "position",
                      label: "Station",
                      width: 100,
                      renderFn: position => (
                        <Tooltip title={<PositionSummary position={position} />}>
                          <span>{position?.stationName || "..."}</span>
                        </Tooltip>
                      ),
                      sortFn: (a, b) => {
                        const sa = a?.stationName || "";
                        const sb = b?.stationName || "";
                        return sa < sb ? -1 : sa > sb ? 1 : 0;
                      },
                    },
                    {
                      dataKey: "00",
                      label: "",
                      width: 20,
                      renderFn: (_, rowData) => (
                        <IconButton
                          size="small"
                          onClick={() => setRemoveTag(rowData.deviceId)}
                          className={classes.controlButton}
                        >
                          <IconDelete />
                        </IconButton>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
            <div className={classes.setupPanel}>
              <FlexRow itemSpacing={2} vAlign="center">
                <SubTitle>Receivers</SubTitle>

                <Tooltip title="Select Receivers">
                  <IconButton size="small" onClick={() => setSelectRxOpen(true)}>
                    <IconAdd />
                  </IconButton>
                </Tooltip>
              </FlexRow>
              <div style={{ flexGrow: 1 }}>
                <WindowedTable
                  rowIdKey="serial"
                  rows={rxList}
                  columns={[
                    {
                      dataKey: "serial",
                      label: "Receiver",
                      width: 80,
                      renderFn: (_, rowData) => formatDevice(rowData),
                    },
                    {
                      dataKey: "rxLogFiles",
                      label: "Log Files",
                      width: 150,
                      disableSort: true,
                      renderFn: rxLogFiles => rxLogFiles?.join(", ") || "",
                    },
                    {
                      dataKey: "position",
                      label: "Station",
                      width: 80,
                      renderFn: position => (
                        <Tooltip title={<PositionSummary position={position} />}>
                          <span>{position?.stationName || ""}</span>
                        </Tooltip>
                      ),
                      sortFn: (a, b) => {
                        const sa = a?.stationName || "";
                        const sb = b?.stationName || "";
                        return sa < sb ? -1 : sa > sb ? 1 : 0;
                      },
                    },
                    {
                      dataKey: "00",
                      label: "",
                      width: 20,
                      renderFn: (_, rowData) => (
                        <IconButton
                          size="small"
                          onClick={() => setRemoveRx(rowData.deviceId)}
                          className={classes.controlButton}
                        >
                          <IconDelete />
                        </IconButton>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </FlexRow>
        }
        secondContent={
          <ResizableSplitPanel
            direction="horizontal"
            // firstMin={100}
            // firstMax={300}
            firstContent={
              <DeploymentMap
                deployments={deployments.filter(d => visibleDeploymentIds.includes(d.id))}
                selectedDeploymentIds={
                  chartMode === "time"
                    ? [txSelected?.position?.deploymentId, rxSelected?.position?.deploymentId]
                    : []
                }
                handleSelectDeployment={() => {}}
                fitBoundsOnChange
                showLegend={false}
              />
            }
            secondContent={
              dataStatus === "loaded" ? (
                <ResizeHandler
                  onResize={(width, height) => {
                    // The HighChartsReact component seems to get height stuck at 400. So this works:
                    if (chartComponentRef.current && chartComponentRef.current.container.current) {
                      chartComponentRef.current.container.current.style.height = `${height}px`;
                      chartComponentRef.current.container.current.style.width = `${width}px`;
                      chartComponentRef.current.chart.reflow();
                    }
                  }}
                >
                  <HighchartsReact
                    highcharts={Highcharts}
                    ref={chartComponentRef}
                    options={getChartOptions(
                      chartMode,
                      groupVar,
                      dataPoints,
                      dayStart,
                      dayEnd,
                      txSelected,
                      rxSelected,
                      timeInterval
                    )}
                  />
                  <ChartMenu
                    onOpen={() => setOptionsOpen(true)}
                    onClose={() => setOptionsOpen(false)}
                  >
                    <FlexCol style={{ padding: 8, paddingTop: 0 }}>
                      <TextField
                        select
                        label="Chart"
                        value={chartMode}
                        size="small"
                        onChange={event => setChartMode(event.target.value as ChartMode)}
                        fullWidth
                        className={classes.textField}
                      >
                        {CHART_MODE_OPTIONS.map(({ value, label }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </TextField>
                      {chartMode === "distance" ? (
                        <>
                          <TextField
                            select
                            label="Group By"
                            value={groupVar}
                            size="small"
                            onChange={event => setGroupVar(event.target.value as GroupByVar)}
                            fullWidth
                            className={classes.textField}
                          >
                            {GROUP_BY_OPTIONS.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </TextField>

                          {groupVar === "daynight" && (
                            <div>
                              <div className={classes.timeRange}>
                                <TimeTextField
                                  value={dayStart}
                                  label="Day Start"
                                  onChange={setDayStart}
                                />
                                <TimeTextField value={dayEnd} label="End" onChange={setDayEnd} />
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <FlexCol elevation={2} rounded paddingLevel={1} marginLevel={1}>
                          <Typography variant="caption" color="textSecondary">
                            Select RX/TX pair to compare over time:
                          </Typography>
                          <FlexRow>
                            <FlexCol>
                              <TextField
                                select
                                label="Receiver"
                                value={rxSelectedIdx}
                                size="small"
                                onChange={event => setRxSelectedIdx(Number(event.target.value))}
                                fullWidth
                                className={classes.textField}
                              >
                                {rxList.map((rx, i) => {
                                  const value = formatDevice(rx as any);
                                  return (
                                    <MenuItem key={value} value={i}>
                                      {value}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              <TextField
                                select
                                label="Transmitter"
                                value={txSelectedIdx}
                                size="small"
                                onChange={event => setTxSelectedIdx(Number(event.target.value))}
                                fullWidth
                                className={classes.textField}
                              >
                                {txList.map((tx, i) => {
                                  return (
                                    <MenuItem key={tx.fullId} value={i}>
                                      {tx.fullId}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </FlexCol>
                            <DistanceNote distance={distanceBetweenSelected} />
                          </FlexRow>
                        </FlexCol>
                      )}

                      <DateTimePicker
                        className={classes.textField}
                        label="Start"
                        fullWidth={true}
                        // error={}
                        // helperText={errors.date}
                        value={formatTimeFromISOStringDt(timeStart)}
                        onChange={value => {
                          setTimeStart(parseDateTime(value).toISOString());
                          setTimeSetByUser(true);
                        }}
                      />
                      <DateTimePicker
                        className={classes.textField}
                        label="End"
                        fullWidth={true}
                        // error={}
                        // helperText={errors.date}
                        value={formatTimeFromISOStringDt(timeEnd)}
                        onChange={value => {
                          setTimeEnd(parseDateTime(value).toISOString());
                          setTimeSetByUser(true);
                        }}
                      />
                      <TextField
                        select
                        label="Time Interval"
                        value={timeInterval}
                        onChange={event =>
                          setTimeInterval(Number(event.target.value) as TimeInterval)
                        }
                        size="small"
                        fullWidth
                        className={classes.textField}
                      >
                        {TIME_INTERVAL_OPTIONS.map(({ value, label }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <FlexRow
                        itemSpacing={1}
                        hAlign="center"
                        style={{ flexWrap: "wrap", marginTop: 8 }}
                      >
                        <Button
                          startIcon={<SaveAlt />}
                          variant="outlined"
                          title="Download chart data"
                          onClick={() => {
                            chartComponentRef?.current?.chart.downloadCSV();
                          }}
                        >
                          Data
                        </Button>
                        <Button
                          startIcon={<SaveAlt />}
                          variant="outlined"
                          title="Download chart image"
                          onClick={() => {
                            chartComponentRef?.current?.chart.exportChart({}, {});
                          }}
                        >
                          Image
                        </Button>
                      </FlexRow>
                    </FlexCol>
                  </ChartMenu>
                </ResizeHandler>
              ) : (
                <FlexCol fullWidth fullHeight hAlign="center" vAlign="center" paddingLevel={2}>
                  {dataStatus === "unloaded" ? (
                    <NoDataPrompt>
                      Please select transmitters and receivers to analyze detections
                    </NoDataPrompt>
                  ) : dataStatus === "loading" ? (
                    <SpinnerInnovasea text="Fishing for data ..." />
                  ) : dataStatus === "error" ? (
                    error?.type === "NO_DATA" ? (
                      <SubTitle>
                        Huh. No nibbles. No data found for this set of receivers and transmitters.
                      </SubTitle>
                    ) : (
                      <SubTitle>
                        Huh. No nibbles. There was an error loading data; please try again soon.
                      </SubTitle>
                    )
                  ) : null}
                </FlexCol>
              )
            }
          />
        }
      />

      {selectTagsOpen && (
        <SelectTx
          transmitters={transmitters}
          closeFn={() => setSelectTagsOpen(false)}
          txList={txList}
          setTxList={setTxList}
          dataReady={dataReady}
        />
      )}

      {selectRxOpen && (
        <SelectRx
          receivers={receivers}
          closeFn={() => setSelectRxOpen(false)}
          rxList={rxList}
          setRxList={setRxList}
          dataReady={dataReady}
        />
      )}

      {removeTag && (
        <DialogWrapper
          open={true}
          title={"Confirm remove"}
          okButtonContent={"Remove"}
          okAction={() => {
            const newList = [...txList];
            newList.splice(
              txList.findIndex(n => n.deviceId == removeTag),
              1
            );
            setTxList(newList);
            setRemoveTag(null);
          }}
          cancelAction={() => setRemoveTag(null)}
        >
          Are you sure you want to remove this transmitter from this list? You can add it again
          later.
        </DialogWrapper>
      )}

      {removeRx && (
        <DialogWrapper
          open={true}
          title={"Confirm remove"}
          okButtonContent={"Remove"}
          okAction={() => {
            const newList = [...rxList];
            newList.splice(
              newList.findIndex(n => n.deviceId == removeRx),
              1
            );
            setRxList(newList);
            setRemoveRx(null);
          }}
          cancelAction={() => setRemoveRx(null)}
        >
          Are you sure you want to remove this receiver from this list? You can add it again later.
        </DialogWrapper>
      )}
    </>
  );
}

export default DetectionAnalysis;

//#endregion REACT COMPONENT
