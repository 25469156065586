import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import autoBind from "auto-bind/react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography, Switch, FormControlLabel } from "@material-ui/core";

import history from "../helpers/history";
import HelpPopover from "../components/common/HelpPopover";
import WorkspaceCard from "../components/workspaces/WorkspaceCard";
import WorkspaceDialog from "../components/workspaces/WorkspaceDialog";
import WorkspaceAddDialog from "../components/workspaces/WorkspaceAddDialog";

import {
  listWorkspaces,
  selectWorkspace,
  createWorkspace,
  updateWorkspace,
  deleteWorkspaces,
  restoreWorkspaces,
  toggleIncludeDeletedWorkspaces,
  removeWorkspaceUser,
  addWorkspaceUser,
  updateWorkspaceUser,
  inviteUserToWorkspace,
  resendWorkspaceInvite,
  setCreatedWorkspaceId,
} from "../redux/workspace/workspace-actions";
import DialogWrapper from "../components/common/DialogWrapper";

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme.palette.background.lightGrey,
    alignItems: "center",
    padding: theme.spacing(1),
    flexGrow: 1,
    overflowY: "auto",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  workspaceList: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  workspaceToolbar: {
    margin: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  workspaceTool: {
    marginLeft: theme.spacing(1),
  },
});

class Workspaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workspaceToEditId: null,
      workspaceAddDialogOpen: false,
      autoShowInviteUser: false,
      workspaceToLeaveId: null,
    };
    autoBind(this);
  }

  componentDidMount() {
    this.props.dispatch(listWorkspaces());

    const qParams = new URLSearchParams(history.location.search);
    const workspaceAddDialogOpen = qParams.get("create") ? true : false;
    if (workspaceAddDialogOpen) {
      this.setState({ workspaceAddDialogOpen });
    }
  }

  componentDidUpdate() {
    // Open edit dialog for the workspace just created:
    const createdWorkspaceId = this.props.createdWorkspaceId;
    if (
      createdWorkspaceId !== null &&
      // createdWorkspaceId gets updated before the fetch gets the new data
      this.props.workspaces.filter(workspace => workspace.id === createdWorkspaceId)
    ) {
      this.setWorkspaceToEditId(createdWorkspaceId);
      this.setAutoShowInviteUser(true);
      this.props.dispatch(setCreatedWorkspaceId(null));
    }
  }

  selectWorkspace(workspaceId) {
    this.props.dispatch(selectWorkspace(workspaceId, "/home"));
    history.push("/home");
  }

  createWorkspace(props) {
    this.props.dispatch(createWorkspace(props));
  }

  updateWorkspace({ workspaceId, name, description }) {
    this.props.dispatch(updateWorkspace({ workspaceId, name, description }));
  }

  deleteWorkspace({ workspaceId }) {
    // The API allows multiple delete, but this UI only enables one at a time so reduce it here:
    this.props.dispatch(deleteWorkspaces({ workspaceIds: [workspaceId] }));
  }

  restoreWorkspace({ workspaceId }) {
    // The API allows multiple restore, but this UI only enables one at a time so reduce it here:
    this.props.dispatch(restoreWorkspaces({ workspaceIds: [workspaceId] }));
  }

  toggleIncludeDeletedWorkspaces() {
    this.props.dispatch(toggleIncludeDeletedWorkspaces());
  }

  removeWorkspaceUser({ workspaceId, userId }) {
    this.props.dispatch(removeWorkspaceUser({ workspaceId, userId }));
  }

  addWorkspaceUser({ workspaceId, userId, isOwner }) {
    this.props.dispatch(addWorkspaceUser({ workspaceId, userId, isOwner }));
  }

  updateWorkspaceUser({ workspaceId, userId, isOwner }) {
    this.props.dispatch(updateWorkspaceUser({ workspaceId, userId, isOwner }));
  }

  inviteUserToWorkspace({ workspaceId, email, message, isOwner }) {
    this.props.dispatch(inviteUserToWorkspace({ workspaceId, email, message, isOwner }));
  }

  resendWorkspaceInvite({ inviteId }) {
    this.props.dispatch(resendWorkspaceInvite({ inviteId }));
  }
  setWorkspaceAddDialogOpen(workspaceAddDialogOpen) {
    this.setState({ workspaceAddDialogOpen });
  }

  setWorkspaceToEditId(workspaceToEditId) {
    this.setState({ workspaceToEditId });
  }

  setWorkspaceToLeaveId(workspaceToLeaveId) {
    this.setState({ workspaceToLeaveId });
  }

  leaveWorkspace(workspaceId) {
    this.props.dispatch(removeWorkspaceUser({ workspaceId, userId: this.props.user.id }));
  }

  setAutoShowInviteUser(autoShowInviteUser) {
    this.setState({ autoShowInviteUser });
  }

  handleEditDialogClose() {
    this.setWorkspaceToEditId(null);
    this.setAutoShowInviteUser(false);
  }

  render() {
    const { classes, workspaces = [], user, includeDeletedWorkspaces } = this.props;
    const { workspaceToEditId, workspaceAddDialogOpen, autoShowInviteUser, workspaceToLeaveId } =
      this.state;

    const workspaceToLeave = workspaces.find(workspace => workspace.id === workspaceToLeaveId);

    return (
      <div className={classes.main}>
        <div className={classes.flexRow}>
          <Typography variant="h5" style={{ marginRight: "1em" }}>
            Workspaces
          </Typography>

          <HelpPopover
            tooltip="What's a workspace?"
            maxWidth={600}
            helpContent={
              <>
                <Typography variant="h6" gutterBottom>
                  A workspace is a container for managing data independently of any other workspace.
                </Typography>
                <Typography gutterBottom>
                  Every user has their own private personal space and can create shared workspaces
                  to collaborate with others.
                </Typography>
                <Typography gutterBottom>
                  Adding members to a shared workspace is as easy as entering their email address to
                  send them an invitation. No account? No problem! Signing up is easy.
                </Typography>
                <Typography gutterBottom>
                  A workspace starts empty, but it's easy for any member to add the devices they
                  have available in their personal workspace.
                </Typography>
              </>
            }
          />
        </div>
        <div className={classes.workspaceList}>
          <div className={classes.workspaceToolbar}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => this.setWorkspaceAddDialogOpen(true)}
            >
              Create Workspace
            </Button>

            <FormControlLabel
              className={classes.workspaceTool}
              control={
                <Switch
                  checked={includeDeletedWorkspaces}
                  onChange={this.toggleIncludeDeletedWorkspaces}
                  color="primary"
                />
              }
              label="Show Deleted"
            />
          </div>
          {workspaces
            ? workspaces.map(workspace => (
                <WorkspaceCard
                  key={workspace.id}
                  workspace={workspace}
                  userId={user.id}
                  updateWorkspace={this.updateWorkspace}
                  deleteWorkspace={this.deleteWorkspace}
                  restoreWorkspace={this.restoreWorkspace}
                  selectWorkspace={this.selectWorkspace}
                  editWorkspace={this.setWorkspaceToEditId}
                  leaveWorkspace={this.setWorkspaceToLeaveId}
                />
              ))
            : null}
        </div>

        <WorkspaceDialog
          open={workspaceToEditId ? true : false}
          handleClose={this.handleEditDialogClose}
          userId={user.id}
          workspace={workspaces.find(workspace => workspace.id === workspaceToEditId)}
          updateWorkspace={this.updateWorkspace}
          deleteWorkspace={this.deleteWorkspace}
          removeWorkspaceUser={this.removeWorkspaceUser}
          addWorkspaceUser={this.addWorkspaceUser}
          updateWorkspaceUser={this.updateWorkspaceUser}
          inviteUserToWorkspace={this.inviteUserToWorkspace}
          autoShowInviteUser={autoShowInviteUser}
          resendWorkspaceInvite={this.resendWorkspaceInvite}
        />

        <WorkspaceAddDialog
          open={workspaceAddDialogOpen}
          createWorkspace={this.createWorkspace}
          handleClose={() => this.setWorkspaceAddDialogOpen(false)}
        />

        <DialogWrapper
          open={Boolean(workspaceToLeaveId)}
          title={`Are you sure you want to leave the ${workspaceToLeave?.name} workspace?`}
          okButtonContent="Leave"
          okAction={() => {
            this.leaveWorkspace(workspaceToLeaveId);
            this.setState({ workspaceToLeaveId: null });
          }}
          cancelAction={() => this.setState({ workspaceToLeaveId: null })}
        >
          You will have to be re-invited by a workspace admin to regain access to the workspace
          data.
        </DialogWrapper>
      </div>
    );
  }
}

Workspaces.propTypes = {
  user: PropTypes.object,
  users: PropTypes.array,
  workspaces: PropTypes.array,
  includeDeletedWorkspaces: PropTypes.bool,
  /** This is set by the createWorkspace action to allow openeng the edit dialog immediately */
  createdWorkspaceId: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    workspaces: state.workspaces.workspaces,
    includeDeletedWorkspaces: state.workspaces.includeDeleted,
    createdWorkspaceId: state.workspaces.createdId,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(Workspaces));
