import { callGqlApi } from "../../../helpers/api";
import { handleGqlErrors } from "../../gql-error/gql-error-actions";
import gql from "../../gqlTag";

import { AnalyticsAction } from "./admin-analytics-types";

import { Thunk } from "../../common";
import { WorkspaceCountRecord } from "../../../components/admin/analytics/types";

const workspaceCountFields = `
  countDate
  animals
  deployments
  studies
  devicesSyspro
  devicesManual
  devicesMobile
  submissionsOtn
  usersInternal
  usersExternal
  invitesPending
  invitesAccepted
  invitesDeclined
  files
  rxLogs
`;

const WORKSPACE_COUNTS_TOTAL = gql`
  query($includeInternal: Boolean) {
    workspaceDailyCountsTotal(includeInternal: $includeInternal) {
      ${workspaceCountFields}
    }
  }
`;

const WORKSPACE_COUNTS = gql`
  query($workspaceId: ID!) {
    workspaceDailyCounts(workspaceId: $workspaceId) {
      ${workspaceCountFields}
    }
  }
`;

function loadData(data: WorkspaceCountRecord[]): AnalyticsAction {
  return { type: "ANALYTICS_LOAD_DATA", payload: { data } };
}

export function loadAnalyticsDataTotal(includeInternal = false): Thunk<void, AnalyticsAction> {
  return async dispatch => {
    callGqlApi(WORKSPACE_COUNTS_TOTAL, { includeInternal })
      .then(data => {
        dispatch(loadData(parseCounts(data.workspaceDailyCountsTotal)));
      })
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

export function loadAnalyticsDataOne(workspaceId: string): Thunk<void, AnalyticsAction> {
  return async dispatch => {
    callGqlApi(WORKSPACE_COUNTS, { workspaceId })
      .then(data => {
        dispatch(loadData(parseCounts(data.workspaceDailyCounts)));
      })
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

function parseCounts(
  counts: (Omit<WorkspaceCountRecord, "countDate"> & { countDate: string })[]
): WorkspaceCountRecord[] {
  return counts.map(count => ({
    ...count,
    countDate: new Date(count.countDate),
  }));
}
