import { Component } from "react";
import { Typography } from "@material-ui/core";
import FlexCol from "./FlexCol";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error messages to error reporting service:
    console.error("Error boundary encountered:", { error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // Error path
      return (
        <FlexCol hAlign="center" vAlign="center" fullHeight fullWidth>
          <FlexCol hAlign="center" style={{ maxWidth: 800 }}>
            <img src="/img/sad-shark.svg" style={{ maxWidth: 300 }} />
            <Typography variant="h5" align="center" gutterBottom>
              Something unexpected happened that caused a failure.
            </Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>
              A notification has been sent to the software engineering team to address the root
              cause.
            </Typography>

            <Typography
              variant="subtitle2"
              align="center"
              style={{ marginTop: "1em", cursor: "pointer" }}
              onClick={() => window.location.reload()}
            >
              Please refresh the page to continue.
            </Typography>
          </FlexCol>
        </FlexCol>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
