import { Typography, TextField } from "@material-ui/core";
import { useState } from "react";
import { useSelectorTyped as useSelector } from "../../redux/common";
import DialogWrapper from "../common/DialogWrapper";
import FlexCol from "../common/FlexCol";
import { WorkspaceSelector } from "../workspaces/WorkspaceSelector";
import { Serial } from "../../helpers/glossary";

type Props = {
  open: boolean;
  selectedWorkspace: any;
  handleClose: () => void;
  addDevicesBySerial: (workspaceId: string, serials: string[]) => void;
};

function DevicesAddSerial({ open, handleClose, addDevicesBySerial }: Props) {
  const { workspacesList, currentWorkspaceId } = useSelector(({ workspaces }) => ({
    workspacesList: workspaces?.workspaces?.map(({ id, name }) => ({ id, name })),
    currentWorkspaceId: (workspaces?.selectedWorkspace as any)?.id || "",
  }));

  const [serials, setSerials] = useState("");
  const [error, setError] = useState("");
  const [workspaceId, setWorkspaceId] = useState(currentWorkspaceId);

  function submit() {
    const serialArray = serials
      .replaceAll("\n", ",")
      .replaceAll(" ", "")
      .split(",")
      .filter(sn => sn);
    if (workspaceId && serialArray.length) {
      addDevicesBySerial(workspaceId, serialArray);
    } else {
      setError(
        `Please ${(!workspaceId && "select a Workspace") || ""} ${
          (!workspaceId && !serialArray.length && " and ") || ""
        } ${(!serialArray.length && ` enter at least one ${Serial.text}`) || ""}`
      );
    }
  }

  return (
    <DialogWrapper
      okAction={submit}
      cancelAction={handleClose}
      title={`Add Devices by ${Serial.title}`}
      open={open}
      maxWidth={"md"}
    >
      <FlexCol itemSpacing={3} paddingLevel={2}>
        <WorkspaceSelector
          workspaces={workspacesList}
          selectedWorkspace={workspaceId}
          setSelectedWorkspace={wid => {
            setError("");
            setWorkspaceId(wid);
          }}
        />
        <TextField
          label={`Device ${Serial.pluralText} to import`}
          placeholder={`Enter list of ${Serial.pluralText} separated with a comma or new line`}
          value={serials}
          onKeyDown={event => event.stopPropagation()}
          onChange={event => {
            setError("");
            setSerials(event.target.value);
          }}
          margin="normal"
          multiline
          maxRows={8}
          fullWidth
        />
        {error && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
      </FlexCol>
    </DialogWrapper>
  );
}

export default DevicesAddSerial;
