import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind";
// Material UI
import { withStyles } from "@material-ui/core/styles";
import { TextField, CircularProgress, Tooltip } from "@material-ui/core";
import { Close as IconClose, Check as IconCheck } from "@material-ui/icons";
import { axios } from "../../helpers/api";
import { debounce } from "lodash";
import { DATA_SERVER_URL } from "../../config";

export const networkKeys = ["otn"]; // these must be in lower case

const otnStyles = theme => ({
  errorText: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
  flexRow: { display: "flex", flexDirection: "row" },
  flexCol: { display: "flex", flexDirection: "column" },
  iconContainer: { marginTop: 24, marginLeft: 8 },
});

class _OTNFields extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      collectionCode: "",
      projectTitle: "",
      collectionCodeValid: null,
      apiError: false,
      apiLoading: false,
    };
    this.state = this.defaultState;
    autoBind(this);
  }

  verifyCollectionCodeBounce = debounce(async cc => {
    this.setState({ apiLoading: true });
    await this.validateCollectionCode(cc);
  }, 700);

  componentDidMount() {
    if (this.props.metadata && this.props.metadata.collectionCode) {
      this.setState({ collectionCode: this.props.metadata.collectionCode });
      this.validateCollectionCode(this.props.metadata.collectionCode);
    }
  }

  async validateCollectionCode(collectionCode) {
    const discoveryUrl = `${DATA_SERVER_URL}/network/?action=check&networkKey=otn&projectKey=${collectionCode}`;
    let apiError = false;
    let projectExist = false;
    let projectTitle = "";
    let valid = false;

    if (collectionCode === "") {
      this.setState(this.defaultState);
      // update parent
      this.props.onChange(!apiError && valid, { otn: { collectionCode } });
      return;
    }

    // Get the project title to verify with the user:
    try {
      const response = await axios.get(discoveryUrl);
      if (response.status === 200) {
        projectExist = true;
        projectTitle = response.data.projectTitle;
      }
    } catch (ex) {
      if (ex.response?.status === 404) {
        projectExist = false;
      } else {
        console.error(ex);
        apiError = true;
      }
    }
    valid = !apiError && projectExist;

    this.setState({
      collectionCodeValid: valid,
      apiError: apiError,
      apiLoading: false,
      projectTitle,
    });

    // update the parent component
    this.props.onChange(!apiError && valid, { otn: { collectionCode } });
  }

  render() {
    const { classes } = this.props;
    const { collectionCodeValid, apiError, apiLoading, projectTitle } = this.state;
    const error = apiError || collectionCodeValid === false;
    const errorMessage = apiError
      ? "There was an error contacting OTN server to verify collection code"
      : "The collection code does not match any OTN projects";
    return (
      <div className={classes.flexCol}>
        <div className={classes.flexRow}>
          <TextField
            label={"Collection Code"}
            style={{ width: 200 }}
            onChange={e => {
              this.setState({ collectionCode: e.target.value, apiLoading: true });
              this.props.onChange(false, { otn: { collectionCode: e.target.value } });
              this.verifyCollectionCodeBounce(e.target.value);
            }}
            value={this.state.collectionCode}
            error={error}
            helperText={
              error
                ? errorMessage
                : collectionCodeValid && !apiLoading
                ? `Matches OTN Project: ${projectTitle}`
                : "The collection code that corresponds to an OTN Project"
            }
          />
          <div className={classes.iconContainer}>
            {apiLoading && <CircularProgress size={22} />}
            {!apiLoading && collectionCodeValid && (
              <>
                <Tooltip title={"Collection Code Validated"}>
                  <IconCheck />
                </Tooltip>
              </>
            )}
            {!apiLoading && error && <IconClose color="error" />}
          </div>
        </div>
      </div>
    );
  }
}

_OTNFields.propTypes = {
  onChange: PropTypes.func.isRequired,
  metadata: PropTypes.shape({
    collectionCode: PropTypes.string,
  }),
};

const OTNFields = withStyles(otnStyles)(_OTNFields);

/**
 * Generates input fields specific to a telemetry network.
 *
 * @param {object} props - ...
 * @param {object} props.study - The study object
 * @param {string} props.network - Lowercase network key
 * @param {Function} props.onChange - Callback
 * @returns {object} JSX
 */
export function StudyNetworkInputs({ study, network, onChange }) {
  const metadata = study && study.networkMetadata && study.networkMetadata[network];
  if (network === "otn") {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <OTNFields onChange={onChange} metadata={metadata} />
      </div>
    );
  }

  return null;
}
StudyNetworkInputs.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  study: PropTypes.object,
};
