import { WorkspaceCountRecord } from "../../../components/admin/analytics/types";
import { AnalyticsAction } from "./admin-analytics-types";

type AnalyticsState = {
  data: null | WorkspaceCountRecord[];
};

const initialState: AnalyticsState = {
  data: null,
};

export default function analyticsReducer(
  state: AnalyticsState = initialState,
  action: AnalyticsAction
): AnalyticsState {
  switch (action.type) {
    case "ANALYTICS_LOAD_DATA":
      return { ...state, data: action.payload.data };

    default:
      return state;
  }
}
