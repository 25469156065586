import { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core";

class FilesDuplicateDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applyToRemaining: false,
    };
  }

  render() {
    const { applyToRemaining } = this.state;
    const { open, dupFileNames, skip, keepBoth, replace } = this.props;

    if (!open) {
      return null;
    }

    const currentFile = dupFileNames[0];
    const selectedFiles = applyToRemaining ? dupFileNames : dupFileNames.slice(0, 1);
    const nRemainingFiles = dupFileNames.length - 1;

    return (
      <div>
        <Dialog open={true}>
          <DialogTitle>{`File "${currentFile}" already exists.`}</DialogTitle>
          <DialogContent>
            <DialogContentText>What do you want to do with the duplicate file?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => skip(selectedFiles)} color="primary" variant="outlined">
              Skip
            </Button>
            <Button onClick={() => keepBoth(selectedFiles)} color="primary" variant="outlined">
              Keep Both
            </Button>
            <Button onClick={() => replace(selectedFiles)} color="primary" variant="outlined">
              Replace
            </Button>
          </DialogActions>

          {dupFileNames.length > 1 && (
            <DialogContent>
              <FormControlLabel
                control={<Checkbox checked={applyToRemaining} />}
                label={`Do this for ${nRemainingFiles} remaining file(s).`}
                onClick={() => this.setState({ applyToRemaining: !applyToRemaining })}
              />
            </DialogContent>
          )}
        </Dialog>
      </div>
    );
  }
}

FilesDuplicateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  dupFileNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  skip: PropTypes.func.isRequired,
  keepBoth: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
};

export default FilesDuplicateDialog;
