import React from "react";
import { areEqual } from "react-window";
import { makeStyles } from "@material-ui/core/styles";

import EditableCell from "./EditableCell";
import Cell from "./Cell";
import CheckboxCell from "./CheckboxCell";

const useStyles = makeStyles(theme => ({
  row: props => ({
    height: props.rowHeight,
    display: "flex",
    overflow: "hidden",
    cursor: props.handleRowClick && !props.disabled && "pointer",
    "&:hover": {
      backgroundColor: !props.disabled && theme.palette.grey[200],
    },
  }),
}));

function Row({ index, style, data }) {
  const { rows, _props } = data;
  // these extra props have to be passed within the data prop due to how the react-window API works:
  const { columns, selectable, handleRowClick, handleCheckbox, rowIdKey, getDOMId } = _props;

  const rowData = rows[index];
  const rowId = rowData[rowIdKey];

  const classes = useStyles({ ..._props, disabled: rowData.__selectDisabled });
  const rowRef = React.useRef();

  return (
    <div
      style={style}
      className={classes.row}
      onClick={e => handleRowClick(rowId, e.shiftKey)}
      onMouseDown={handleMouseDown}
      tabIndex="0"
      id={getDOMId(index)}
      ref={rowRef}
    >
      {selectable && (
        <CheckboxCell
          selected={Boolean(rowData.__selected)}
          disabled={Boolean(rowData.__selectDisabled)}
          onClick={shiftKey => {
            rowRef.current.focus();
            handleCheckbox(rowId, shiftKey);
          }}
        />
      )}
      {columns.map(col => {
        // Cannot edit a disabled cell
        const CellComponent = col.editable && !rowData.__selectDisabled ? EditableCell : Cell;

        return (
          <CellComponent
            width={col.width}
            flexGrow={col.flexGrow}
            flexShrink={col.flexShrink}
            key={col.dataKey}
            numeric={col.numeric}
            noEllipses={col.noEllipses}
            {...(col.onEdit && { onEdit: col.onEdit, rowData })}
            disabled={rowData.__selectDisabled}
          >
            {col.renderFn ? col.renderFn(rowData[col.dataKey], rowData) : rowData[col.dataKey]}
          </CellComponent>
        );
      })}
    </div>
  );
}

// prevents shift selection from selecting table text, but still sets focus
function handleMouseDown(e) {
  if (e.shiftKey) {
    e.preventDefault();
    e.currentTarget.focus();
  }
}

export default React.memo(Row, areEqual);
