// import PropTypes from "prop-types";
import FlexRow from "./FlexRow";

function MenuBar({ contentLeft, contentRight, paddingLevel = 1, itemSpacing = 1, ...props }) {
  return (
    <FlexRow spaceBetween {...{ paddingLevel, itemSpacing }} {...props}>
      <FlexRow vAlign="center" itemSpacing={itemSpacing}>
        {contentLeft}
      </FlexRow>
      <FlexRow vAlign="center" itemSpacing={itemSpacing}>
        {contentRight}
      </FlexRow>
    </FlexRow>
  );
}

// MenuBar.propTypes = {
// };

export default MenuBar;
