import { useState, useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Auth from "@aws-amplify/auth";
import { SpinnerInnovasea } from "../../fathom-brella";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../../redux/user/user-actions";
import MainLayout from "../../containers/MainLayout";
import WorkspaceProvider from "../workspaces/WorkspaceProvider";
import StudyUrlHandler from "../study/StudyUrlHandler";

function PrivateRoute({ component: Component, admin, useStudy, requireStudy, ...rest }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const [authState, setAuthState] = useState("loading");
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  useEffect(() => {
    // First time check if we have an auth user
    Auth.currentAuthenticatedUser()
      .then(user => {
        const isUserAdmin =
          user.signInUserSession.accessToken.payload["cognito:groups"] &&
          user.signInUserSession.accessToken.payload["cognito:groups"].includes("admin");

        dispatch(setCurrentUser(user, isUserAdmin));
        setAuthState("signedIn");
        setIsUserAdmin(isUserAdmin);
      })
      .catch(() => {
        // If workspace invite but user not signed in, retain the invite in storage and redirect to sign in instead:
        const workspaceInviteId = localStorage.getItem("workspaceInviteId");
        if (workspaceInviteId) {
          const workspaceInviteEmail = localStorage.getItem("workspaceInviteEmail");
          localStorage.clear(); // want to make sure we keep only the invite data
          localStorage.setItem("workspaceInviteId", workspaceInviteId);
          localStorage.setItem("workspaceInviteEmail", workspaceInviteEmail);

          history.push("/signin");
        } else {
          // clear any local cache in case there was a sign out in another app
          localStorage.clear();
          history.push("/learn");
        }
      });
    // eslint-disable-next-line
  }, []);

  if (authState === "loading" || !isAuthenticated) {
    return (
      <MainLayout>
        <SpinnerInnovasea />
      </MainLayout>
    );
  }

  // Private route and they did not successfully auth => send to landing page
  if (authState !== "signedIn") {
    return <Redirect to="/learn" />;
  }

  // Admin route & are they not an admin => send home
  if (admin && !isUserAdmin) {
    return <Redirect to="/home" />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <MainLayout>
          <WorkspaceProvider>
            <StudyUrlHandler useStudy={useStudy} requireStudy={requireStudy}>
              <Component {...props} />
            </StudyUrlHandler>
          </WorkspaceProvider>
        </MainLayout>
      )}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  admin: PropTypes.bool,
  useStudy: PropTypes.bool,
  requireStudy: PropTypes.bool,
};

export default PrivateRoute;
