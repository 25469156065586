import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const styles = theme => {
  return {
    popper: {
      zIndex: theme.zIndex.modal,
    },
  };
};

// MUI popper that closes on mousedown away
function Dropdown(Toggle, Contents) {
  const DropdownUnstyled = class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        anchorEl: null,
        open: false,
      };

      this.toggleRef = React.createRef();

      this.handleClick = this.handleClick.bind(this);
      this.handleClickAway = this.handleClickAway.bind(this);
    }

    handleClick(event) {
      const { currentTarget } = event;
      this.setState(state => ({
        anchorEl: currentTarget,
        open: !state.open,
      }));
    }

    handleClickAway(event) {
      if (this.toggleRef.current.contains(event.target)) {
        return;
      }
      this.setState({
        open: false,
      });
    }

    render() {
      const { classes, ...nonClassProps } = this.props;
      return (
        <div>
          <div ref={this.toggleRef} onClick={this.handleClick}>
            <Toggle {...nonClassProps} />
          </div>
          <Popper className={classes.popper} open={this.state.open} anchorEl={this.state.anchorEl}>
            <ClickAwayListener onClickAway={this.handleClickAway} mouseEvent="onMouseDown">
              <Contents {...nonClassProps} />
            </ClickAwayListener>
          </Popper>
        </div>
      );
    }
  };

  return withStyles(styles, { withTheme: true })(DropdownUnstyled);
}

export default Dropdown;
