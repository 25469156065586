import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import autoBind from "auto-bind/react";
import { formatDateTime, parseDateTime } from "../../helpers/time";
import { formatDevice } from "../../helpers/device";

import {
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from "@material-ui/core";

import { ExpandMore as IconExpandMore } from "@material-ui/icons";

import DateTimePicker from "../common/DateTimePicker";
import DialogWrapper from "../common/DialogWrapper";

const styles = theme => ({
  group: {
    display: "flex",
    flexGrow: 1,
    "justify-content": "space-between",
    "align-items": "center",
  },
  panel: {
    margin: "0px !important",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  },
  input: {
    marginBottom: theme.spacing(2),
  },
});

class RebatteryDialog extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      values: {
        deviceIds: [],
        date: "",
        notes: "",
      },
      errors: {
        date: "",
      },
    };

    this.initialState = this.state;
  }

  handleInputChange(field, event) {
    this.setState({ values: { ...this.state.values, [field]: event.target.value } });
  }

  getErrors() {
    const errors = { ...this.state.errors };
    const values = { ...this.state.values };

    if (!values.date) {
      errors.date = "Required field";
    } else {
      const parsed = parseDateTime(values.date);
      if (!parsed.isValid()) {
        errors.date = "Invalid date / time";
      }
    }

    this.setState({ errors: errors });
    return errors.date;
  }

  handleSubmit() {
    const values = { ...this.state.values };
    const containsErrors = this.getErrors();
    this.props.handleDeviceRebattery(values, containsErrors);
  }

  reset(selection) {
    const state = { ...this.initialState };
    state.values.deviceIds = selection.map(device => device.id);
    state.values.date = formatDateTime(new Date());
    this.setState(state);
  }

  render() {
    const { values, errors } = this.state;
    const { toggle, mode, classes, devices } = this.props;

    const receiverSelection = [];
    const nonReceiverSelection = [];
    devices.forEach(device => {
      if (device.deviceClasses.includes("RECEIVER")) {
        receiverSelection.push(device);
      } else {
        nonReceiverSelection.push(device);
      }
    });

    return (
      <DialogWrapper
        open={mode === "rebattery"}
        onEnter={() => this.reset(receiverSelection)}
        title="Add Rebattery Event"
        okAction={this.handleSubmit}
        okButtonContent="Add Rebattery"
        cancelAction={toggle}
        maxWidth="xs"
      >
        {receiverSelection.length > 0 && (
          <Typography paragraph>
            Rebattery event will be created for
            {receiverSelection.length > 1
              ? ` ${receiverSelection.length} receivers.`
              : ` ${formatDevice(receiverSelection[0])}.`}
          </Typography>
        )}
        {nonReceiverSelection.length > 0 && (
          <ExpansionPanel className={classes.panel}>
            <ExpansionPanelSummary style={{ padding: 0 }} expandIcon={<IconExpandMore />}>
              <div>
                {"Rebattery events can only be applied to receivers and will not be applied to "}
                {nonReceiverSelection.length} device
                {nonReceiverSelection.length > 1 ? "s " : " "}
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ padding: 0, display: "inline" }}>
              {nonReceiverSelection.map(device => (
                <div key={device.serial}>{device.model + " " + device.serial}</div>
              ))}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}

        {receiverSelection.length > 0 && (
          <>
            <DateTimePicker
              className={classes.input}
              label="Date"
              fullWidth={true}
              error={errors.date ? true : false}
              helperText={errors.date}
              value={values.date}
              onChange={value => {
                this.setState({
                  errors: { ...errors, date: false },
                  values: { ...values, date: value },
                });
              }}
            />
            <TextField
              className={classes.input}
              size="small"
              fullWidth={true}
              id="notes"
              label="Notes"
              multiline
              rows={2}
              rowsMax={4}
              value={values.notes}
              onChange={event => {
                this.handleInputChange("notes", event);
              }}
            />
          </>
        )}
      </DialogWrapper>
    );
  }
}

RebatteryDialog.propTypes = {
  mode: PropTypes.string,
  toggle: PropTypes.func,
  handleDeviceRebattery: PropTypes.func,
  devices: PropTypes.array,
};

export default withStyles(styles)(RebatteryDialog);
