import { createTheme } from "@material-ui/core/styles";
import { amber, green } from "@material-ui/core/colors";
/**
 * Colors based on branding guidelines:
 *   https://innovasea.sharepoint.com/sites/InnovaseaHub/Branding%20Standards/Forms/AllItems.aspx?id=%2Fsites%2FInnovaseaHub%2FBranding%20Standards%2FBrand%20Guidelines%2FInnovasea%20brand%20guidelines%20June%202020%2Epdf&parent=%2Fsites%2FInnovaseaHub%2FBranding%20Standards%2FBrand%20Guidelines
 */

const customTheme = createTheme({
  palette: {
    primary: { main: "rgb(4, 60, 74)" },
    secondary: { main: "rgb(139, 212, 223)" },
    error: { main: "rgb(228, 77, 38)" }, // JAB: there is no "tertiary" color so use this ... it kinda fits
    success: { main: green[600] },
    warning: { main: amber[700] },
    common: {
      white: "rgba(255,255,255,0.95)",
      black: "rgba(0,0,0,0.9)",

      // PRIMARY COLOR PALETTE:
      darkBlue: "rgb(4, 60, 74)", // (same as primary.main)
      lightBlue: "rgb(139, 212, 223)", // (same as secondary.main)
      teal: "rgb(78, 175, 174)",
      green: "rgb(191, 227, 214)",

      // SECONDARY COLOR PALETTE:
      coral: "rgb(246,145,126)",
      yellow: "rgb(244,237,121)",
      darkGrey: "rgb(82,84,91)",
      lightGrey: "rgb(216,220,227)",
    },
    background: {
      default: "rgba(255,255,255,0.9)",
      lightGrey: "rgba(245,245,245,1)",
    },

    // If we use the theme properly, the "dark mode" option can be presented to the user
    // without any additional complexity in the code:
    // type: "dark"
  },
  themeName: "innovasea-fathom",
  typography: {
    fontFamily: "'Nunito Sans', Arial, sans-serif",
  },
  //
  // OVERRIDES:
  //
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#666",
        boxShadow: "0px 0px 5px -3px black, 5px 5px 15px -5px black",
        fontSize: "0.8rem",
        fontWeight: 300,
        textShadow: "0.5px 0.5px 0.5px #444",
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: "whitesmoke",
      },
    },
    MuiButton: {
      // The hover effect for text buttons is too subtle; enhance it globally here
      text: {
        border: "1px solid transparent",
        "&:hover": {
          border: "1px solid rgba(4, 60, 74, 0.3)",
        },
      },
      outlinedSizeSmall: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiIconButton: {
      root: {
        border: "1px solid transparent",
        "&:hover:not(.MuiRadio-root):not(.MuiCheckbox-root):not(.MuiSwitch-switchBase)": {
          border: "1px solid rgba(4, 60, 74, 0.3)",
        },
      },
    },
    MuiFormGroup: {
      root: {
        flexGrow: 1,
        "& .MuiFormControlLabel-label": {
          fontSize: 13,
          lineHeight: "unset",
          paddingTop: 2,
        },
        "& .MuiRadio-root": {
          paddingTop: 2,
          paddingBottom: 2,
          paddingRight: 5,
        },
      },
    },
  },
  //
  // NON-STANDARD THEME ADDITIONS:
  //
  sideBarWidth: {
    expanded: 240,
    collapsed: 60,
  },
});

export default customTheme;
