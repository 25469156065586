import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useThunkDispatch } from "../../redux/common";
import { latestDetections, websocketDetections } from "../../redux/admin/live2/live-2-actions";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import GetAppIcon from "@material-ui/icons/GetApp";
import RecentDetections from "../../components/admin/live2/RecentDetections";
import SiteImporter from "../../components/admin/live2/SiteImporter";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    height: "100%",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  top: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  tableRoot: {
    minHeight: 200,
    maxHeight: "100%",
    height: "100%",
    width: "100%",
  },
  side: {
    paddingTop: theme.spacing(1),
    width: 250,
    borderRight: "1px solid lightgrey",
  },
}));

function Live2() {
  const detFeatureEnabled =
    process.env.REACT_APP_LIVE2_API_URL && process.env.REACT_APP_LIVE2_WS_URL;
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const [appSelected, setAppSelected] = useState("site-importer");

  useEffect(() => {
    if (detFeatureEnabled) {
      dispatch(websocketDetections());
      dispatch(latestDetections());
    }
  }, [dispatch, detFeatureEnabled]);

  return (
    <div className={classes.root}>
      <div className={classes.side}>
        {detFeatureEnabled && (
          <ListItem button key="detections" onClick={() => setAppSelected("detections")}>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText primary={"Recent Detections"} />
          </ListItem>
        )}
        <ListItem button key="site-import" onClick={() => setAppSelected("site-importer")}>
          <ListItemIcon>
            <GetAppIcon />
          </ListItemIcon>
          <ListItemText primary={"Company Importer"} />
        </ListItem>
      </div>
      <div className={classes.main}>
        {appSelected === "detections" ? <RecentDetections /> : <SiteImporter />}
      </div>
    </div>
  );
}

export default Live2;
