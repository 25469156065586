import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// MATERIAL UI
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";

// MATERIAL ICONS
import IconButton from "@material-ui/core/IconButton";
import IconChevronLeft from "@material-ui/icons/ChevronLeft";
import IconChevronRight from "@material-ui/icons/ChevronRight";

const styles = theme => ({
  root: {
    width: theme.sideBarWidth.expanded,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: theme.zIndex.drawer,
  },
  paper: {
    position: "relative",
  },
  open: props => ({
    width: props.width || theme.sideBarWidth.expanded,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  closed: {
    width: theme.sideBarWidth.collapsed,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(1),
  },
});

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }
  //_________________________________________________________
  handleDrawerOpen = () => {
    if (this.props.handleDrawerOpen && typeof this.props.handleDrawerOpen === "function") {
      this.props.handleDrawerOpen();
    } else {
      this.setState({ open: true });
    }
  };
  //_________________________________________________________
  handleDrawerClose = () => {
    if (this.props.handleDrawerClose && typeof this.props.handleDrawerClose === "function") {
      this.props.handleDrawerClose();
    } else {
      this.setState({ open: false });
    }
  };
  render() {
    const { classes, theme, open: openFromProps, noShadow, closable = true } = this.props;
    const open = closable ? (openFromProps !== undefined ? openFromProps : this.state.open) : true;
    return (
      <Drawer
        variant="permanent"
        className={classNames(classes.root, {
          [classes.open]: open,
          [classes.closed]: !open,
        })}
        classes={{
          paper: classNames(classes.paper, {
            [classes.open]: open,
            [classes.closed]: !open,
          }),
        }}
        style={{
          boxShadow: !noShadow && theme.shadows[8],
        }}
        open={open}
      >
        {closable && (
          <>
            <div className={classes.toolbar}>
              {open ? (
                <IconButton onClick={this.handleDrawerClose}>
                  {theme.direction === "rtl" ? <IconChevronRight /> : <IconChevronLeft />}
                </IconButton>
              ) : (
                <IconButton onClick={this.handleDrawerOpen}>
                  {theme.direction === "rtl" ? <IconChevronLeft /> : <IconChevronRight />}
                </IconButton>
              )}
            </div>
            <Divider />
          </>
        )}

        {this.props.children}
      </Drawer>
    );
  }
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  open: PropTypes.bool, // can control from parent if desired
  handleDrawerOpen: PropTypes.func,
  handleDrawerClose: PropTypes.func,
  noShadow: PropTypes.bool,
  /** Allow drawer to be closable. Set to false to stay open and hide collapse button. Default is true. */
  closable: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(SideBar);
