import {
  DEVICES_SET_CODESPACES,
  DEVICES_SET_CODESPACES_ERROR,
  DEVICES_SET_MODELS,
  DEVICES_SET_LIST,
  DEVICES_SET_PERSONAL,
  DEVICES_SET_PERSONAL_LOADING,
  DEVICES_SET_PERSONAL_ERROR,
  DEVICES_SET_ERROR,
  DEVICES_UPDATE_ONE,
  DEVICE_DELETE_EVENTS,
} from "../action-types";

const initialState = {
  devices: null,
  personalDevices: [],
  personalDevicesLoading: false,
  personalDevicesError: null,
  models: {},
  codespaces: [],
  loading: true,
  error: null,
};

export default function devicesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case DEVICES_SET_CODESPACES:
      return {
        ...state,
        codespaces: payload.codespaces,
      };

    case DEVICES_SET_CODESPACES_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };

    case DEVICES_SET_MODELS:
      return {
        ...state,
        models: payload.models,
      };

    case DEVICES_SET_LIST:
      return {
        ...state,
        devices: payload.devices,
        loading: false,
      };

    case DEVICES_SET_PERSONAL:
      return {
        ...state,
        personalDevices: payload.devices,
        personalDevicesLoading: false,
        personalDevicesError: null,
      };

    case DEVICES_SET_PERSONAL_LOADING:
      return {
        ...state,
        personalDevicesLoading: true,
      };

    case DEVICES_SET_PERSONAL_ERROR:
      return {
        ...state,
        personalDevicesLoading: false,
        personalDevicesError: payload.error,
      };

    case DEVICES_SET_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };

    case DEVICES_UPDATE_ONE: {
      const devices = [...state.devices];
      const index = devices.findIndex(device => device.id === payload.deviceId);
      devices[index] = { ...devices[index], ...payload.device };
      return {
        ...state,
        devices: devices,
      };
    }

    case DEVICE_DELETE_EVENTS: {
      const devices = [...state.devices];
      const index = devices.findIndex(device => device.id === payload.deviceId);
      const updateDevice = devices[index];
      const deleteEventIds = payload.deleteEventIds;
      const events = updateDevice.events.filter(event => !deleteEventIds.includes(event.id));
      updateDevice.events = events;
      devices[index] = updateDevice;
      return {
        ...state,
        devices: devices,
      };
    }
    default:
      return state;
  }
}
