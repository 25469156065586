import { useEffect, useRef } from "react";

/** NOTE: for this to work properly, the parent element MUST have CSS { position: relative } */
export default function ResizeHandler({
  children,
  onResize,
  debounceTime = 100,
}: {
  children: any;
  /** Function that gets called when the containing element changes in width or height */
  onResize: (width: number, height: number) => void;
  debounceTime?: number;
}) {
  const ref = useRef<HTMLDivElement>(null);

  // Invoke instance of window.ResizeObserver on "component mount"
  useEffect(() => {
    let debounce: NodeJS.Timeout;
    if (window.ResizeObserver && ref?.current) {
      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          clearTimeout(debounce);
          debounce = setTimeout(
            () => onResize(entry.contentRect.width, entry.contentRect.height),
            debounceTime
          );
        }
      });
      resizeObserver.observe(ref.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // the empty array is actually needed to replicate "componentDidMount" behavior

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
}
