import StudyExportDetails from "./StudyExportDetails";
import PrivacyPolicy from "./PrivacyPolicy";
import { NetworkError } from "./NetworkError";
import WhatsNew from "./WhatsNew";
import { useSelectorTyped as useSelector, useThunkDispatch } from "../../redux/common";
import { openAcceptPP } from "../../redux/user/user-actions";
import { useEffect } from "react";

// this component holds dialog components that are to appear on top of any route
function TopLevelDialogs() {
  const user = useSelector(state => state.user.user);
  const dispatch = useThunkDispatch();

  useEffect(() => {
    if (user?.id && !user?.acceptedPrivacyPolicy) {
      dispatch(openAcceptPP());
    }
  });

  return (
    <>
      <NetworkError />
      <StudyExportDetails />
      <PrivacyPolicy />
      <WhatsNew />
    </>
  );
}

export default TopLevelDialogs;
