import { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { WorkspaceCountRecord } from "./types";

type Props = { data: WorkspaceCountRecord[] | null; includeInternal: boolean };
function LatestCounts({ data, includeInternal }: Props) {
  const current = data && data.length > 0 ? data[data.length - 1] : null;

  let nAnimals: number | null = null;
  let nDeployments: number | null = null;
  let nStudies: number | null = null;
  let nDevices: number | null = null;
  let nDevicesSyspro: number | null = null;
  let nDevicesManual: number | null = null;
  let nDevicesMobile: number | null = null;
  let nInvites: number | null = null;
  let nInvitesPending: number | null = null;
  let nInvitesAccepted: number | null = null;
  let nInvitesDeclined: number | null = null;
  let nSubmissionsOtn: number | null = null;
  let nFiles: number | null = null;
  let nRxLogs: number | null = null;

  if (current) {
    nAnimals = current.animals;
    nDeployments = current.deployments;
    nStudies = current.studies;
    nDevicesSyspro = current.devicesSyspro;
    nDevicesManual = current.devicesManual;
    nDevicesMobile = current.devicesMobile;
    nDevices = nDevicesSyspro + nDevicesManual + nDevicesMobile;
    nInvitesPending = current.invitesPending;
    nInvitesAccepted = current.invitesAccepted;
    nInvitesDeclined = current.invitesDeclined;
    nInvites = nInvitesPending + nInvitesAccepted + nInvitesDeclined;
    nSubmissionsOtn = current.submissionsOtn;
    nFiles = current.files;
    nRxLogs = current.rxLogs;
  }

  const nUsers =
    current &&
    (includeInternal ? current.usersExternal + current.usersInternal : current.usersExternal);

  return (
    <>
      <Typography gutterBottom>Totals (as of this morning):</Typography>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          color: current ? "unset" : "gray",
        }}
      >
        <Count n={nUsers} label="Users" />
        <Count n={nAnimals} label="Animals" />
        <Count n={nDeployments} label="Deployments" />
        <Count n={nStudies} label="Studies" />
        <Count
          n={nDevices}
          label="Devices"
          details={[
            { n: nDevicesSyspro, label: "Syspro" },
            { n: nDevicesManual, label: "Manual" },
            { n: nDevicesMobile, label: "Mobile" },
          ]}
        />
        <Count
          n={nInvites}
          label="Invitations"
          details={[
            { n: nInvitesPending, label: "Pending" },
            { n: nInvitesAccepted, label: "Accepted" },
            { n: nInvitesDeclined, label: "Declined" },
          ]}
        />
        <Count n={nSubmissionsOtn} label="Network Submissions" />
        <Count n={nFiles} label="Files" details={[{ n: nRxLogs, label: "Rx-Logs" }]} />
      </div>
    </>
  );
}

function Count({
  n,
  label,
  details,
}: {
  n: number | null;
  label: string;
  details?: { n: number | null; label: string }[];
}) {
  return (
    <div style={{ paddingRight: 12, whiteSpace: "nowrap" }}>
      <Typography>
        <big>
          <strong>{n === null ? "-" : n}</strong> {label}{" "}
        </big>
      </Typography>
      {details && (
        <Typography variant="body2">
          {details.map(({ n, label }, i) => (
            <Fragment key={label}>
              <strong>{n === null ? "-" : n}</strong> {label}
              {i < details.length - 1 ? ", " : ""}
            </Fragment>
          ))}
        </Typography>
      )}
    </div>
  );
}

export default LatestCounts;
