import { useState, useEffect, useMemo } from "react";
import * as d3 from "d3";
import { makeStyles } from "@material-ui/core/styles";
import { useThunkDispatch, useSelectorTyped as useSelector } from "../../redux/common";

import CheckboxGroup from "../../components/common/CheckboxGroup";
import ChartWrapper from "../../components/admin/analytics/ChartWrapper";
import LatestCounts from "../../components/admin/analytics/LatestCounts";
import ColorSample from "../../components/common/ColorSample";

import { CountType, countTypes } from "../../components/admin/analytics/types";
import { TextField, Switch, FormControlLabel } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { COUNT_TYPE_OPTIONS } from "../../components/admin/analytics/constants";
import {
  loadAnalyticsDataOne,
  loadAnalyticsDataTotal,
} from "../../redux/admin/analytics/admin-analytics-actions";

import { getAllWorkspaces } from "../../redux/admin/workspace/admin-workspace-actions";
import { userDisplayName, isInternalEmail } from "../../helpers/common";

type WorkspaceOption = {
  id: string;
  name: string;
  isExternal: boolean;
  isPersonal: boolean;
  displayName: string;
};

const useStyles = makeStyles(theme => ({
  main: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  topPanel: {
    padding: theme.spacing(2),
    borderBottom: "1px solid lightgray",
  },
  workspaceSelector: {
    marginBottom: theme.spacing(2),
    width: 350,
  },
  chartPanel: {
    display: "flex",
    flexGrow: 1,
    whiteSpace: "nowrap",
    minHeight: 200,
  },
  chartSidePanel: {
    whiteSpace: "nowrap",
    width: 275,
    borderRight: "1px solid lightgray",
    overflow: "auto",
  },
  chartMain: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    margin: "20px 10px 20px 15px",
  },
}));

const colorScale = d3
  .scaleOrdinal([
    "#4e79a7",
    "#f28e2c",
    "#e15759",
    "#76b7b2",
    "#59a14f",
    "#edc949",
    "#af7aa1",
    "#ff9da7",
    "#9c755f",
    "#bab0ab",
  ])
  .domain(countTypes);

function Analytics() {
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const [seriesTypeSelection, setSeriesTypeSelection] = useState<CountType[]>([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceOption | null>(null);
  const [includeInternal, setIncludeInternal] = useState(false);
  const [yStartAtZero, setYStartAtZero] = useState(true);

  const sensorOptions = COUNT_TYPE_OPTIONS.map(option => {
    return { ...option, extra: <ColorSample size={24} color={colorScale(option.value)} /> };
  });

  const workspaces = useSelector(state => state.adminWorkspaces.allWorkspaces);
  const workspaceOptions: WorkspaceOption[] = useMemo(
    () =>
      workspaces.map(w => {
        return {
          id: w.id,
          displayName: workspaceDisplayName(w),
          isExternal: workspaceIsExternal(w),
        };
      }),
    [workspaces]
  );

  const displayedWorkspaceOptions = includeInternal
    ? workspaceOptions
    : workspaceOptions.filter(w => w.isExternal);

  const data = useSelector(state => state.adminAnalytics.data);

  const selectedWorkspaceId = selectedWorkspace?.id;

  useEffect(() => {
    dispatch(getAllWorkspaces());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedWorkspace) {
      dispatch(loadAnalyticsDataTotal(includeInternal));
    }
  }, [includeInternal]); //eslint-disable-line

  useEffect(() => {
    if (selectedWorkspaceId) {
      dispatch(loadAnalyticsDataOne(selectedWorkspaceId));
    } else {
      dispatch(loadAnalyticsDataTotal(includeInternal));
    }
  }, [selectedWorkspaceId]); //eslint-disable-line

  useEffect(() => {
    if (selectedWorkspace && !displayedWorkspaceOptions.includes(selectedWorkspace)) {
      setSelectedWorkspace(null);
    }
  }, [displayedWorkspaceOptions, selectedWorkspace]);

  return (
    <div className={classes.main}>
      <div className={classes.topPanel}>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <div className={classes.workspaceSelector}>
            <Autocomplete
              options={displayedWorkspaceOptions}
              getOptionLabel={workspace => workspace.displayName}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Workspace"
                  placeholder="All"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={selectedWorkspace}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(_, workspace) => setSelectedWorkspace(workspace || null)}
            />
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={includeInternal}
                onChange={event => setIncludeInternal(event.target.checked)}
                color="primary"
              />
            }
            label="Include Internal"
          />
        </div>
        <LatestCounts data={data} includeInternal={includeInternal} />
      </div>
      <div className={classes.chartPanel}>
        <div className={classes.chartSidePanel}>
          <div style={{ padding: "8px 0px 10px 20px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={yStartAtZero}
                  onChange={event => setYStartAtZero(event.target.checked)}
                  color="primary"
                />
              }
              label="Start Y-Axis At 0"
            />
          </div>
          <CheckboxGroup
            label="Count Type"
            options={sensorOptions}
            selection={seriesTypeSelection}
            selectionHandler={setSeriesTypeSelection}
            enabledValues={sensorOptions.map(d => d.value)}
            size="small"
          />
        </div>

        <div className={classes.chartMain}>
          {!data || data.length === 0 ? (
            "No data available."
          ) : seriesTypeSelection.length === 0 ? (
            "Select a count type to chart its daily value over time."
          ) : (
            <ChartWrapper
              yVariable="Count"
              data={data}
              varColorScale={colorScale}
              varsToShow={seriesTypeSelection}
              yStartAtZero={yStartAtZero}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function workspaceIsExternal(workspace: any): boolean {
  return workspace.users.some(user => {
    return !isInternalEmail(user.email);
  });
}

function workspaceDisplayName(workspace: any): string {
  if (!workspace.isPersonal) return workspace.name;

  const personalUser = workspace.users[0];
  const displayName = userDisplayName(personalUser);
  return `Personal: ${displayName || personalUser.email}`;
}

export default Analytics;
