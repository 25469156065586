import React from "react";
import autoBind from "auto-bind/react";
import mapboxgl from "mapbox-gl";
import * as d3 from "d3";
import { withStyles } from "@material-ui/core/styles";

import "mapbox-gl/dist/mapbox-gl.css";
import "../styles/customMapBox.css";

import { MapboxToken } from "../../../../config";
mapboxgl.accessToken = MapboxToken;

const initMapPadding = {
  top: 50,
  bottom: 170,
  left: 290,
  right: 50,
};

const styles = {
  root: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  svgOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  detectionBubble: {
    fillOpacity: "0.5",
    stroke: "black",
    strokeWidth: "1.5",
    cursor: "pointer",
    "&:hover": {
      fill: "#e67b5e",
      strokeWidth: "3",
      fillOpacity: "0.75",
    },
  },
  tooltip: {
    position: "absolute",
    textAlign: "center",
    marginLeft: 5,
    padding: 3,
    background: "rgba(3, 224, 178, 0.65)",
    borderRadius: 6,
    fontSize: 12,
    pointerEvents: "none",
    zIndex: 10000,
    color: "black",
  },
};

class BubbleMap extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.mapContainer = React.createRef();

    // member fields (null initialization not actually necessary)
    this.map = null;
    this.mapSvg = null;
    this.tooltip = null;
    this.timeBrushRoot = null;
  }

  componentDidMount() {
    const classes = this.props.classes;

    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: this.props.mapStyle,
      attributionControl: false,
    });

    //Disable rotation using right click and drag
    this.map.dragRotate.disable();

    //Disable rotation using touch rotation on certain mice
    this.map.touchZoomRotate.disable();

    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "top-right");
    this.map.addControl(new mapboxgl.AttributionControl({ compact: true }), "top-right");

    this.map.on("viewreset", this.updateProjection);
    this.map.on("move", this.updateProjection);
    this.map.on("moveend", this.updateProjection);

    this.mapSvg = d3
      .select(this.map.getCanvasContainer())
      .append("svg")
      .attr("class", classes.svgOverlay);

    this.tooltip = d3
      .select("body")
      .append("div")
      .attr("class", classes.tooltip)
      .style("opacity", 0);

    this.setInitialMapCamera();

    this.mapSvg
      .selectAll("circle")
      .data(this.props.deployments, d => d.deployment)
      .enter()
      .append("circle")
      .attr("class", classes.detectionBubble)
      .on("mouseover", this.bubbleMouseoverHandler)
      .on("mouseout", this.bubbleMouseoutHandler)
      .on("click", this.bubbleClickHandler);

    this.updateProjection();
    this.updateBubbleSize();
    this.updateBubbleColor(this.props.bubbleColor);
  }

  componentDidUpdate() {
    this.updateBubbleSize(this.props.transDuration);
    this.updateBubbleColor(this.props.bubbleColor);
    this.map.setStyle(this.props.mapStyle);
  }

  componentWillUnmount() {
    this.tooltip && this.tooltip.remove();
  }

  updateBubbleSize(duration = 0) {
    if (!this.props.bubbleSizeScale) {
      this.mapSvg.selectAll("circle").attr("r", 0);
      return;
    }

    this.mapSvg
      .selectAll("circle")
      .data(this.props.deployments, d => d.deployment)
      .transition()
      .duration(duration)
      .attr("r", d => this.props.bubbleSizeScale(this.getDetCount(d)));
  }

  updateBubbleColor(color) {
    this.mapSvg.selectAll("circle").style("fill", color);
  }

  updateProjection() {
    const circles = this.mapSvg.selectAll("circle");
    const projectedPoints = circles.data().map(d => this.map.project(d.lonlat));
    circles.attr("cx", (d, i) => projectedPoints[i].x).attr("cy", (d, i) => projectedPoints[i].y);
  }

  setInitialMapCamera() {
    const lonExtent = d3.extent(this.props.deployments, d => d.lonlat[0]);
    const latExtent = d3.extent(this.props.deployments, d => d.lonlat[1]);

    const sw = [lonExtent[0], latExtent[0]];
    const ne = [lonExtent[1], latExtent[1]];

    this.map.fitBounds([sw, ne], { padding: initMapPadding, animate: false });
  }

  bubbleMouseoverHandler(d, i, nodes) {
    d3.select(nodes[i]).on("mousemove", this.bubbleMousemoveHandler);

    let idCountMessage = "";

    if (this.props.idCounter) {
      const nIds = this.props.idCounter(d.deployment);
      const multipleIds = nIds > 1;
      idCountMessage = `<br>${nIds} unique ID${multipleIds ? "s" : ""}`;
    }

    this.tooltip
      .html(`Station: ${d.station}<br>${this.getDetCount(d)} Detections` + idCountMessage)
      .style("left", d3.event.pageX + "px")
      .style("top", d3.event.pageY - 35 + "px")
      .transition()
      .duration(200)
      .style("opacity", 0.9);
  }

  bubbleMousemoveHandler() {
    this.tooltip.style("left", d3.event.pageX + "px").style("top", d3.event.pageY - 50 + "px");
  }

  bubbleMouseoutHandler() {
    this.tooltip.transition().duration(350).style("opacity", 0);
  }

  getDetCount(d) {
    return this.props.detCountMap.get(d.deployment);
  }

  getIdCount(d) {
    return this.props.idCountMap.get(d.deployment);
  }

  render() {
    return <div ref={this.mapContainer} className={this.props.classes.root} />;
  }
}

export default withStyles(styles)(BubbleMap);
