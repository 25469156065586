import history from "../helpers/history";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";

const styles = theme => ({
  header: {
    display: "flex",
    "@media screen and (max-width: 450px)": {
      flexDirection: "column",
      flexWrap: "none",
    },
    background: "white",
    boxShadow: `0px 4px 8px -4px ${theme.palette.primary.dark}`,
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  signin: {
    "&:last-of-type": {
      marginLeft: theme.spacing(2),
    },
  },
  logo: {
    height: 42,
    cursor: "pointer",
    "@media screen and (max-width: 450px)": {
      marginBottom: theme.spacing(2),
      height: 70,
    },
  },
  actions: {
    display: "flex",
  },
});

function Header({ classes }) {
  return (
    <div className={classes.header}>
      <img
        src="/img/fathom-default.png"
        alt="fathom logo"
        className={classes.logo}
        onClick={() => history.push("/home")}
      />
      <div className={classes.actions}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.signin}
          onClick={() => history.push("/signin")}
        >
          Log in
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.signin}
          onClick={() => history.push("/signup")}
        >
          Register
        </Button>
      </div>
    </div>
  );
}

export default compose(withStyles(styles), connect())(Header);
