import { Live2Action, Live2Detection } from "./live-2-types";
import { Thunk } from "../../common";
import { SnackBarAction } from "../../snackbar/snackbar-types";
import gql from "../../gqlTag";
import { callGqlApi, getActiveToken } from "../../../helpers/api";
import { handleGqlErrors } from "../../gql-error/gql-error-actions";
import { basicSnackbar, customSnackbar, removeSnackbar } from "../../snackbar/snackbar-actions";
import { listWorkspaces } from "../../workspace/workspace-actions";

let wsClient: any;
const wsUrl = process.env.REACT_APP_LIVE2_WS_URL;
const liveAPIUrl = process.env.REACT_APP_LIVE2_API_URL;

export function websocketDetections(): Thunk<void, Live2Action> {
  return async (dispatch, getState) => {
    const token = await getActiveToken();

    wsClient = new WebSocket(`${wsUrl}?token=${token}`);

    wsClient.onmessage = message => {
      console.log(JSON.parse(message.data));
      dispatch(addDetection(JSON.parse(message.data)));
    };

    wsClient.onopen = () => {
      console.log("WEBSOCKET OPENED!!!");
    };

    if (!getState().adminLive2.keepPolling) {
      wsClient.close();
    }
  };
}

export function websocketDetectionsClose() {
  if (wsClient) {
    wsClient.close();
  }
}

export function latestDetections(): Thunk<void, Live2Action | SnackBarAction> {
  return dispatch => {
    const q = gql`
      {
        latestDetections {
          Timecode
          TransmitterID
          HubID
          SiteID
          ReceiverID
        }
      }
    `;
    callGqlApi(q, {}, liveAPIUrl)
      .then(res => dispatch(setLatestDetections(res.latestDetections)))
      .catch(e => dispatch(handleGqlErrors(e)));
  };
}

function addDetection(detection: Live2Detection): Thunk<void, Live2Action> {
  return (dispatch, getState) => {
    const curDetections: Live2Detection[] = getState().adminLive2.detections;

    if (
      !curDetections.find(
        d => d["TransmitterID"] === detection["TransmitterID"] && d.Timecode === detection.Timecode
      )
    ) {
      dispatch({ type: "ADD_DETECTION", payload: { detection } });
    }
  };
}

function setLatestDetections(detections): Thunk<void, Live2Action> {
  return dispatch => {
    dispatch({ type: "SET_LATEST_DETECTIONS", payload: { detections } });
  };
}

export function getLiveCompanies(): Thunk<void, Live2Action> {
  const q = gql`
    query {
      liveCompanies {
        id
        sites {
          name
          mappedStudy {
            id
            name
          }
        }
        mappedWorkspace {
          id
          name
          creator {
            email
          }
        }
      }
    }
  `;
  return dispatch => {
    callGqlApi(q)
      .then(data => {
        dispatch({ type: "SET_COMPANIES", payload: { companies: data.liveCompanies } });
      })
      .catch(e => dispatch(handleGqlErrors(e)));
  };
}

export function importLiveCompany(
  companyId: string
): Thunk<Promise<any>, Live2Action | SnackBarAction> {
  return async (dispatch, getState) => {
    const key = `live-import-${new Date().valueOf()}`;
    dispatch(customSnackbar("progress", { message: `Importing ${companyId}` }, key));
    const mut = gql`
      mutation mut($input: ImportLiveCompanyInput!) {
        importLiveCompany(input: $input) {
          numAnimalsImported
          company {
            mappedWorkspace {
              id
            }
          }
        }
      }
    `;

    try {
      const res = await callGqlApi(mut, {
        input: {
          companyId,
        },
      });
      const { mappedWorkspace } = res.importLiveCompany.company;
      if (!getState().workspaces.workspaces.find(w => w.id === mappedWorkspace.id)) {
        dispatch(listWorkspaces());
      }
      dispatch(
        basicSnackbar(
          `Imported selected sites, animals imported: ${res.importLiveCompany.numAnimalsImported}`,
          "success"
        )
      );
      dispatch(removeSnackbar(key));
      dispatch(getLiveCompanies());
      return res;
    } catch (e) {
      dispatch(removeSnackbar(key));
      dispatch(handleGqlErrors(e));
    }
  };
}
