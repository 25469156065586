import { callGqlApi } from "../../../helpers/api";
import gql from "../../gqlTag";
import {
  ADMIN_USERS_SET_DATA,
  ADMIN_USERS_SET_ERROR,
  ADMIN_USER_ACCOUNT_ACTION_START,
  ADMIN_USER_ACCOUNT_ACTION_END,
} from "../../action-types";
import { snackbarError, basicSnackbar } from "../../snackbar/snackbar-actions";
import { getGqlUser } from "../../user/user-actions";
import { handleGqlErrors } from "../../gql-error/gql-error-actions";

const userFields = gql`
  fragment userFields on User {
    id
    cognitoUuid
    sysproContactId
    email
    givenName
    familyName
    nickname
    status
    enabled
  }
`;

export function getUsers() {
  return dispatch => {
    const usersQuery = gql`
      {
        users {
          ...userFields
        }
      }
      ${userFields}
    `;

    return callGqlApi(usersQuery)
      .then(data => {
        dispatch({
          type: ADMIN_USERS_SET_DATA,
          payload: {
            users: data.users,
          },
        });
      })
      .catch(errors => {
        dispatch({
          type: ADMIN_USERS_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function createUser(input) {
  return dispatch => {
    const createUserMutation = `
      mutation createUser($input: CreateUserInput!) {
        createUser(input: $input) {
          user {
            id
            cognitoUuid
            sysproContactId
            email
          }
        }
    }`;
    callGqlApi(createUserMutation, { input })
      .then(() => {
        dispatch(getUsers());
        dispatch(basicSnackbar(`Created: ${input.email}`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: ADMIN_USERS_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function updateUser(input) {
  const updateMutation = gql`
    mutation ($input: UpdateUserInput!) {
      updateUser(input: $input) {
        user {
          id
        }
      }
    }
  `;
  return (dispatch, getState) => {
    callGqlApi(updateMutation, { input })
      .then(() => {
        const currentUser = getState().user.user;
        // update main app user if you're updating yourself
        if (input.id === currentUser.id) {
          dispatch(getGqlUser(currentUser, currentUser.isAdmin));
        }
        dispatch(getUsers());
        dispatch(basicSnackbar(`User Updated`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: ADMIN_USERS_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function disableUser(user) {
  const disableMutation = gql`
    mutation {
      disableUser(input: { cognitoUuid: "${user.cognitoUuid}" }) {
        user {
          id
        }
      }
    } 
  `;
  return dispatch => {
    dispatch({ type: ADMIN_USER_ACCOUNT_ACTION_START });
    return callGqlApi(disableMutation)
      .then(() =>
        dispatch(getUsers()).finally(() => dispatch({ type: ADMIN_USER_ACCOUNT_ACTION_END }))
      )
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

export function enableUser(user) {
  const enableMutation = gql`
    mutation {
      enableUser(input: { cognitoUuid: "${user.cognitoUuid}" }) {
        user {
          id
        }
      }
    } 
  `;
  return dispatch => {
    dispatch({ type: ADMIN_USER_ACCOUNT_ACTION_START });
    return callGqlApi(enableMutation)
      .then(() =>
        dispatch(getUsers()).finally(() => dispatch({ type: ADMIN_USER_ACCOUNT_ACTION_END }))
      )
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

export function setPassword(user, password) {
  const setMutation = gql`
    mutation {
      setUserPassword(
        input: { cognitoUuid: "${user.cognitoUuid}", password: "${password}" }
      ) {
        user {
          id
        }
      }
    }
  `;
  return dispatch => {
    dispatch({ type: ADMIN_USER_ACCOUNT_ACTION_START });
    return callGqlApi(setMutation)
      .then(() =>
        dispatch(getUsers()).finally(() => dispatch({ type: ADMIN_USER_ACCOUNT_ACTION_END }))
      )
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}

export function resendUserInvite(email) {
  const resendMutation = gql`
    mutation {
      resendUserInvite(input: { email: "${email}" })
    }
  `;
  return dispatch => {
    dispatch({ type: ADMIN_USER_ACCOUNT_ACTION_START });
    return callGqlApi(resendMutation)
      .then(() => dispatch({ type: ADMIN_USER_ACCOUNT_ACTION_END }))
      .catch(errors => dispatch(handleGqlErrors(errors)));
  };
}
