import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";
import { ifEnterOrEsc } from "../../helpers/input";

const styles = theme => ({
  input: {
    margin: theme.spacing(2),
    marginRight: 0,
    marginTop: 0,
  },
  snapLeft: {
    marginLeft: 0,
  },
  measurementItem: {
    display: "inline-flex",
  },
});

function MeasurementInput({
  label,
  valueWidth,
  unitWidth,
  unitOptions,
  unitDefault,
  onChange,
  onSubmit,
  onCancel,
  value,
  error,
  helperText,
  classes,
  autoFocus,
}) {
  const _value = value ? value.value : "";
  const _unit = value ? value.unit : unitDefault;
  return (
    <div className={classes.measurementItem}>
      <TextField
        className={classes.input}
        style={{ width: valueWidth }}
        label={label || " "} // for alignment
        value={_value}
        onChange={event =>
          onChange({ value: event.target.value.replace(/[^0-9.]/g, ""), unit: _unit })
        }
        error={error}
        helperText={helperText}
        onKeyDown={keyEvent =>
          ifEnterOrEsc({
            keyEvent,
            onEnter: onSubmit,
            onEsc: onCancel,
          })
        }
        autoFocus={autoFocus}
      />
      <TextField
        className={classes.input + " " + classes.snapLeft}
        style={{ width: unitWidth }}
        select
        label={" "} //  for alignment
        onChange={event => onChange({ value: _value, unit: event.target.value })}
        value={_unit}
        onKeyDown={keyEvent =>
          ifEnterOrEsc({
            keyEvent,
            onEnter: onSubmit,
            onEsc: onCancel,
          })
        }
      >
        {unitOptions.map((option, i) => {
          return (
            <MenuItem key={i} value={option.value || option}>
              {option.label || option}
            </MenuItem>
          );
        })}
      </TextField>
    </div>
  );
}

MeasurementInput.propTypes = {
  label: PropTypes.string,
  valueWidth: PropTypes.number,
  unitWidth: PropTypes.number,
  unitOptions: PropTypes.any,
  unitDefault: PropTypes.string,
  helperText: PropTypes.any,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    unit: PropTypes.string,
  }),
  error: PropTypes.bool,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
};

export default withStyles(styles)(MeasurementInput);
