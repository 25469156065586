import { useEffect, useState } from "react";
import { useSelectorTyped, useThunkDispatch } from "../../redux/common";
import { customSnackbar } from "../../redux/snackbar/snackbar-actions";

function useFileProcessingWarning(onlyCurrentStudy = false) {
  const dispatch = useThunkDispatch();
  const [sentWarning, setSentWarning] = useState(false);
  const fileList = useSelectorTyped(({ files, study }) => {
    if (onlyCurrentStudy) {
      const currentStudy = (study.studies as any).find(s => s.id === study.selectedId);
      return currentStudy?.files || [];
    } else {
      return files.fileList;
    }
  });

  useEffect(() => {
    if (sentWarning) return;
    const unfinishedLogFiles = fileList.filter(f => f.processing).length;
    if (unfinishedLogFiles) {
      setSentWarning(true);
      dispatch(customSnackbar("warn-files-processing", { count: unfinishedLogFiles }));
    }
  }, [fileList, dispatch, sentWarning]);
}

export default useFileProcessingWarning;
