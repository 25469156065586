import { Typography } from "@material-ui/core";

/** Common style element for placeholder to prompt for user action when there is no data to display */
export function NoDataPrompt({ children }) {
  return (
    <Typography
      variant="h6"
      align="center"
      color="textSecondary"
      style={{ minWidth: 370, width: 370 }}
    >
      {children}
    </Typography>
  );
}
