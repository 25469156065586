// objectKey = "devices"
// transformedProperties = (device) => ({displayIds: device?.transmitters?.map(tx => tx.displayId)})
export function studyVisibleObjects({
  studies,
  selectedStudy,
  objects,
  objectKey,
  objectIdKey = "id",
  /** Optional function for adding properties of transformed object data
   *
   * @returns {{}} - object
   */
  transformedProperties = () => ({}),
}) {
  // create shallow copy of array objects:
  const objectsInWorkspace = (objects || []).map(object => ({
    ...object,
    ...transformedProperties(object),
  }));

  // Only include objects linked to the study if there is a study selected:
  let _objects = [];
  if (selectedStudy) {
    // This is slightly less readable than array.filter() but it is faster
    selectedStudy[objectKey]?.forEach(objectInStudy => {
      const i = objectsInWorkspace.findIndex(
        object => object[objectIdKey] === objectInStudy[objectIdKey]
      );
      if (i !== -1) {
        _objects.push(objectsInWorkspace[i]);
      }
    });
  } else {
    _objects = objectsInWorkspace;

    // Map of studies per object id:
    const objectsInStudies = {}; // { [object[objectIdKey]]: [{ id: study.id, name: study.name}, ...], ... }
    studies?.forEach(study => {
      study[objectKey]?.forEach(object => {
        if (!objectsInStudies[object[objectIdKey]]) {
          objectsInStudies[object[objectIdKey]] = [];
        }
        objectsInStudies[object[objectIdKey]].push({ id: study.id, name: study.name });
      });
    });
    // Add property "inStudies": array of id, name of each study the object is linked to:
    _objects.forEach(object => {
      object.inStudies = objectsInStudies[object[objectIdKey]] || [];
    });
  }
  return _objects;
}

export function studyNames(studies) {
  return studies && studies.map(study => study && study.name).join(", ");
}
