import PropTypes from "prop-types";
import { List, Tooltip, IconButton, Typography, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FlexCol from "./FlexCol";

const useStyles = makeStyles(theme => ({
  menu: {
    display: "flex",
    padding: 0,
    margin: 0,
  },
  divider: {
    borderLeft: "1px solid lightgray",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingTop: 10,
    paddingBottom: 10,
  },
  iconContainer: {
    display: "flex",
    height: 34,
    alignItems: "center",
  },
  buttonPressed: {
    background: theme.palette.grey[300],
    borderRadius: 4,
  },
  square: {
    borderRadius: 4,
    minWidth: 40,
  },
}));

function IconMenuHoriz({ items }) {
  const classes = useStyles();
  return (
    <List className={classes.menu}>
      {items.map(
        (
          {
            onClick,
            labelAbove,
            hoverText = "",
            icon = null,
            isSwitch = null,
            switchChecked = null,
            visible = true,
            buttonPressed,
            disabled,
            divider,
          },
          i
        ) =>
          !visible ? null : divider ? (
            <div key={i} className={classes.divider} />
          ) : (
            <Tooltip key={i} title={hoverText}>
              <div className={classes.itemContainer}>
                <div className={classes.iconContainer}>
                  {isSwitch ? (
                    <FlexCol hAlign="center">
                      <Typography variant="caption" align="center" gutterBottom>
                        {labelAbove}
                      </Typography>
                      <Switch checked={switchChecked} onChange={onClick} size="small" />
                    </FlexCol>
                  ) : (
                    <IconButton
                      className={buttonPressed ? classes.buttonPressed : classes.square}
                      size="small"
                      onClick={onClick}
                      disabled={disabled}
                    >
                      <FlexCol hAlign="center">
                        <Typography variant="caption" align="center" gutterBottom>
                          {labelAbove}
                        </Typography>
                        {icon}
                      </FlexCol>
                    </IconButton>
                  )}
                </div>
              </div>
            </Tooltip>
          )
      )}
    </List>
  );
}

IconMenuHoriz.propTypes = {
  items: PropTypes.arrayOf(
    // Allows the item object or false (to skip item)
    PropTypes.oneOfType([
      PropTypes.shape({
        onClick: PropTypes.func,
        labelAbove: PropTypes.string,
        hoverText: PropTypes.string,
        icon: PropTypes.object,
        isSwitch: PropTypes.bool,
        switchChecked: PropTypes.bool,
        visible: PropTypes.bool,
        buttonPressed: PropTypes.bool,
        disabled: PropTypes.bool,
        divider: PropTypes.bool,
      }),
      PropTypes.oneOf([false]),
    ])
  ),
};

export default IconMenuHoriz;
