import { Component } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreHoriz as IconMoreHoriz } from "@material-ui/icons";
import PropTypes from "prop-types";
import autoBind from "auto-bind";

class MenuMultiOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    autoBind(this);
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  close() {
    this.setState({ anchorEl: null });
  }

  render() {
    const open = Boolean(this.state.anchorEl);
    const { options, title } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <IconButton variant="outlined" onClick={this.handleClick} title={title} size="small">
          <IconMoreHoriz />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={open} onClose={this.close}>
          {options.map((option, i) => (
            <MenuItem
              key={i}
              onClick={e => {
                option.onClick(e);
                this.close();
              }}
            >
              {option.text}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

MenuMultiOption.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  title: PropTypes.string,
};

export default MenuMultiOption;
