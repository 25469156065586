import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { Typography, Tab, Tabs } from "@material-ui/core";

import AnimalEventTimeline from "./AnimalEventTimeline";
import AnimalDetails from "./AnimalDetails";
import DetailsPanel from "../common/DetailsPanel";
import PanelPlaceHolder from "../common/PanelPlaceHolder";
import AnimalMeasurements from "./AnimalMeasurements";
import TabBadge from "../common/TabBadge";

const styles = theme => ({
  tab: {
    textTransform: "none",
    minWidth: 70,
  },
  badgeTab: {
    textTransform: "none",
    minWidth: 70,
    paddingRight: theme.spacing(4.5),
  },
});

const placeHolderText = "Select an animal to view detailed information.";

class AnimalDetailsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "details",
    };
  }

  render() {
    const { activeTab } = this.state;
    const {
      animalsSelected,
      classes,
      onEventEdit,
      onEventDelete,
      deleteMeasurementSet,
      editMeasurementSet,
      onAnimalEdit,
      onSubObjAdd,
    } = this.props;

    const animal = animalsSelected.length > 0 ? animalsSelected[0] : null;
    const multipleSelected = animalsSelected.length > 1;

    return (
      <DetailsPanel
        header={
          <Tabs value={activeTab} onChange={(event, tab) => this.setState({ activeTab: tab })}>
            <Tab
              className={classes.tab}
              label={<Typography variant="subtitle1">Details</Typography>}
              value="details"
            />
            <Tab
              className={classes.badgeTab}
              label={
                <TabBadge
                  badgeContent={animal && animal.events ? animal.events.length.toString() : "0"}
                  color="secondary"
                  overlap="rectangular"
                >
                  <Typography variant="subtitle1">Events</Typography>
                </TabBadge>
              }
              value="events"
            />
            <Tab
              className={classes.badgeTab}
              label={
                <TabBadge
                  badgeContent={
                    animal && animal.measurementSets
                      ? animal.measurementSets.length.toString()
                      : "0"
                  }
                  color="secondary"
                  overlap="rectangular"
                >
                  <Typography variant="subtitle1">Measurement Sets</Typography>
                </TabBadge>
              }
              value="measurements"
            />
          </Tabs>
        }
        content={
          multipleSelected ? (
            <PanelPlaceHolder>
              {animalsSelected.length} animals selected. Select a single animal to see details.
            </PanelPlaceHolder>
          ) : animal ? (
            activeTab === "details" ? (
              <AnimalDetails animal={animal} onAnimalEdit={onAnimalEdit} />
            ) : activeTab === "measurements" ? (
              <AnimalMeasurements
                measurementSets={animal.measurementSets}
                deleteMeasurementSet={deleteMeasurementSet}
                editMeasurementSet={editMeasurementSet}
                addMeasurementSet={() => onSubObjAdd("measurementSet")}
              />
            ) : activeTab === "events" ? (
              <AnimalEventTimeline
                onEventEdit={onEventEdit}
                events={animal.events}
                onEventDelete={onEventDelete}
                onEventAdd={onSubObjAdd}
              />
            ) : null
          ) : (
            <PanelPlaceHolder>{placeHolderText}</PanelPlaceHolder>
          )
        }
      />
    );
  }
}

AnimalDetailsPanel.propTypes = {
  animalsSelected: PropTypes.array,
  onEventEdit: PropTypes.func,
  onEventDelete: PropTypes.func,
  deleteMeasurementSet: PropTypes.func,
  editMeasurementSet: PropTypes.func,
  onAnimalEdit: PropTypes.func,
  onSubObjAdd: PropTypes.func,
};

export default withStyles(styles)(AnimalDetailsPanel);
