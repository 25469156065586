import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import { withStyles } from "@material-ui/core/styles";
import { WindowedTable } from "../../fathom-brella";
import { animalFields } from "./constants";
import { formatDate } from "../../helpers/time";
import { getTagIds } from "../animals/utils";
import WarningIcon from "../common/WarningIcon";
import StudyLinkChips from "../study/StudyLinkChips";
import { CircularProgress } from "@material-ui/core";
import { studyNames } from "../../helpers/study";
import { getLatestEventTimeOfType, AnimalEventType } from "../../helpers/animals";

const styles = {
  root: {
    minHeight: 200,
    maxHeight: "100%",
    height: "100%",
    width: "100%",
  },
};

class AnimalsTable extends Component {
  constructor(props) {
    super(props);
    this.getColumns = memoize((animals, selectedStudyId, detectionsLoading) => {
      const anyConflicts = Boolean(
        selectedStudyId
          ? animals.find(a => a.studyConflicts.find(sc => sc.studyId === selectedStudyId))
          : animals.find(a => a.conflicts.length > 0)
      );

      const columns = [
        {
          dataKey: "tagIds",
          label: "Tag IDs",
          width: 200,
          searchTextFn: ids => ids.join(", "),
          renderFn: formatTagIds,
          flexGrow: 3,
        },
        {
          dataKey: "name",
          label: animalFields.name.label,
          width: 80,
        },
        {
          dataKey: "speciesCommonName",
          label: animalFields.speciesCommonName.label,
          width: 110,
        },
        {
          dataKey: "releaseDate",
          label: "Release Date",
          width: 100,
        },
        {
          dataKey: "detCount",
          label: "Detections",
          width: 90,
          sortFn: (a = 0, b = 0) => (a > b ? 1 : -1),
          renderFn: detCount =>
            detectionsLoading ? <CircularProgress size={14} /> : detCount?.toLocaleString(),
          numeric: true,
        },
        ...(!selectedStudyId && Boolean(this.props.handleRemoveFromStudy)
          ? [
              {
                width: 150,
                label: "In Studies",
                dataKey: "inStudies",
                searchTextFn: studyNames,
                renderFn: (studies, rowData) => (
                  <StudyLinkChips
                    studies={studies}
                    removeFromStudy={this.props.handleRemoveFromStudy}
                    objectId={rowData.id}
                  />
                ),
                noEllipses: true,
              },
            ]
          : []),
      ];

      const conflictMessages = {
        AnimalNameConflict: "There is more than one animal with this name.",
        AnimalDeviceConflict:
          "A tag on this animal appears on more than one animal at the same time.",
      };

      if (anyConflicts) {
        const icon = conflicts => {
          if (conflicts.length === 0) {
            return null;
          }

          const messages = conflicts.map(conflict => conflictMessages[conflict.__typename]);
          const tooltip =
            messages.length === 1
              ? messages[0]
              : messages.map((message, i) => <div key={i}>{message}</div>);

          return <WarningIcon tooltip={tooltip} />;
        };

        let dataKey, renderFn;
        if (selectedStudyId) {
          dataKey = "studyConflicts";
          renderFn = studyConflicts =>
            icon(
              studyConflicts.filter(sc => sc.studyId === selectedStudyId).map(sc => sc.conflict)
            );
        } else {
          dataKey = "conflicts";
          renderFn = conflicts => icon(conflicts);
        }

        columns.push({
          dataKey,
          label: "",
          renderFn,
          width: 30,
          noEllipses: true,
          disableSort: true,
          flexShrink: 1,
          flexGrow: 0,
        });
      }

      return columns;
    });
  }
  render() {
    const {
      selection,
      setSelection,
      classes,
      animals,
      searchText,
      selectedStudyId,
      detectionsLoading,
    } = this.props;

    const rows = animals.map(animal => {
      return {
        tagIds: getTagIds(animal.devices),
        name: animal.name,
        speciesCommonName: animal.speciesCommonName,
        releaseDate: getLatestReleaseDate(animal.events),
        detCount: animal?.detSummary?.idCount?.count,
        ...animal,
      };
    });

    return (
      <div className={classes.root}>
        <WindowedTable
          rows={rows}
          selectable={true}
          selection={selection}
          onSelect={setSelection}
          selectOnRowClick
          rowIdKey={"id"}
          initialSortDir={"DESC"}
          initialSortBy={"name"}
          searchText={searchText}
          columns={this.getColumns(animals, selectedStudyId, detectionsLoading)}
        />
      </div>
    );
  }
}

// extract the latest release date from a set of animal events
function getLatestReleaseDate(events) {
  const latestReleaseDate = getLatestEventTimeOfType(events, AnimalEventType.RELEASE);
  if (latestReleaseDate) {
    return formatDate(latestReleaseDate);
  }
  return null;
}

// Format tag ids for printing in the table.
// They will wrap only on to multiple lines if needed but IDs will stay together
function formatTagIds(ids) {
  return (
    <span style={{ whiteSpace: "wrap" }}>
      {ids.map((id, i) => (
        <Fragment key={id}>
          <span style={{ whiteSpace: "nowrap" }}>{id}</span>
          {i < ids.length - 1 ? ", " : null}
        </Fragment>
      ))}
    </span>
  );
}

AnimalsTable.propTypes = {
  animals: PropTypes.array,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
  searchText: PropTypes.string,
  selectedStudyId: PropTypes.string,
  handleRemoveFromStudy: PropTypes.func,
};

export default withStyles(styles)(AnimalsTable);
