import { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Radio, RadioGroup, FormControlLabel, FormLabel } from "@material-ui/core";
import { Smartphone as IconMobileAdd, Person as IconManualDevice } from "@material-ui/icons";

import { TableSearchBar, WindowedTable } from "../../fathom-brella";

import TableContainer from "../common/TableContainer";
import { toTitleCase } from "../../helpers/common";
import { Serial } from "../../helpers/glossary";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  loadingText: {
    position: "absolute",
    paddingBottom: 250,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1),
  },
  toolbarCaption: {
    fontSize: "small",
  },
  toolbarGroup: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  toolbarMiddleGrow: {
    flexGrow: 1,
  },
}));

function DevicesTable({ devices, selection, setSelection }) {
  const classes = useStyles();
  const [deviceFilter, setDeviceFilter] = useState("all");
  const [searchText, setSearchText] = useState("");

  const columns = [
    {
      width: 70,
      label: `${Serial.title}`,
      dataKey: "serial",
    },
    {
      width: 95,
      label: "Model",
      dataKey: "model",
      flexShrink: 1,
      flexGrow: 0,
    },
    {
      width: 140,
      label: "Stock Code",
      dataKey: "stockCode",
    },
    {
      width: 180,
      label: deviceFilter === "receivers" ? "Self-Transmitter ID(s)" : "Transmitter ID(s)",
      dataKey: "transmitters",
      renderFn: transmitters => transmitters.map(tx => tx.displayId).join(", "),
      flexGrow: 4,
    },
    {
      width: 100,
      label: "State",
      dataKey: "state",
      renderFn: state => toTitleCase(state),
    },
    {
      width: 100,
      label: "Ship Date",
      dataKey: "shipDate",
      renderFn: shipDate => shipDate && shipDate.split("T")[0],
    },
    {
      width: 60,
      label: "Source",
      dataKey: "source",
      searchTextFn: () => "",
      renderFn: source =>
        source === "MANUAL" ? (
          <IconManualDevice titleAccess="User" />
        ) : source === "MOBILE" ? (
          <IconMobileAdd titleAccess="Mobile" />
        ) : (
          <div style={{ textAlign: "center", width: "24px" }}>
            <img alt="Fathom" src="/img/fathom-pin.png" height="24px" title="Fathom" />
          </div>
        ),
      flexShrink: 1,
      flexGrow: 0,
    },
  ];

  const devicesFiltered =
    deviceFilter === "all"
      ? devices || []
      : deviceFilter === "receivers"
      ? devices.filter(device => {
          return device.deviceClasses.includes("RECEIVER");
        })
      : devices.filter(device => {
          return device.deviceClasses.includes("TAG");
        });

  return (
    <div className={classes.main}>
      <div className={classes.toolbar}>
        <div className={classes.toolbarGroup}>
          <FormLabel component="legend" className={classes.toolbarCaption}>
            Device Type
          </FormLabel>
          <RadioGroup
            row
            name="deviceClasses"
            value={deviceFilter}
            onChange={event => setDeviceFilter(event.target.value)}
          >
            <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
            <FormControlLabel
              value="receivers"
              control={<Radio size="small" />}
              label="Receivers"
            />
            <FormControlLabel value="tags" control={<Radio size="small" />} label="Tags" />
          </RadioGroup>
        </div>

        <div className={classes.toolbarMiddleGrow}></div>

        <TableSearchBar searchText={searchText} handleSearch={setSearchText} />
      </div>

      <TableContainer>
        <WindowedTable
          rows={devicesFiltered}
          rowIdKey="id"
          columns={columns}
          selectable={true}
          selectOnRowClick
          selection={selection}
          onSelect={setSelection}
          searchText={searchText}
        />
      </TableContainer>
    </div>
  );
}

DevicesTable.propTypes = {
  devices: PropTypes.array,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default DevicesTable;
