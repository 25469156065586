import { snackbarError } from "../snackbar/snackbar-actions";
import { NetworkAction } from "../network/network-types";

export function handleGqlErrors(errors) {
  return (dispatch, getState) => {
    if (!errors) {
      return;
    }
    const errorArray = (errors.constructor === Array && errors) || [errors];

    console.log("handleGqlErrors");
    errorArray.forEach(e => {
      // only push snack messages that would be useful to the user
      if (e.extensions && e.extensions.code === "BAD_USER_INPUT") {
        dispatch(snackbarError(e.message));
      } else {
        const workspaceId = getState().workspaces?.selectedWorkspace?.id;
        console.error("handleGqlErrors", { workspaceId, ...e });
        if (e.message === "Network Error") {
          // trigger network retry connection:
          const networkError: NetworkAction = {
            type: "NETWORK_SET_ERROR",
            payload: { message: e.message, detail: e },
          };
          dispatch(networkError);
        } else {
          dispatch(
            snackbarError(`There was an error on the Fathom server. Please try again later`)
          );
        }
      }
    });
  };
}
