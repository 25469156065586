import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DialogWrapper from "../common/DialogWrapper";
import { Button, Typography } from "@material-ui/core";
import bowser from "bowser";
import WarningIcon from "@material-ui/icons/Warning";

const supportedBrowsers = [
  "Chrome",
  "Chromium",
  "Safari",
  "Firefox",
  "Microsoft Edge",
  "Googlebot",
  "Google Search",
];

function BrowserWarning() {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const clientInfo = bowser.parse(window.navigator.userAgent);

  useEffect(() => {
    if (history.location.pathname === "/adst") return; // the ADST page is mobile compatible
    const gaveWarning = localStorage.getItem("gaveBrowserWarning");
    if (!gaveWarning) {
      if (
        clientInfo.platform.type !== "desktop" ||
        !supportedBrowsers.includes(clientInfo?.browser?.name || "")
      ) {
        setOpen(true);
        localStorage.setItem("gaveBrowserWarning", "true");
      }
    }
  }, [clientInfo, history.location]);

  return (
    <DialogWrapper
      title={
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <WarningIcon fontSize="small" />
          <Typography variant="h6" style={{ marginLeft: 8 }}>
            Warning: Unsupported Browser
          </Typography>
        </div>
      }
      open={open}
      cancelAction={() => setOpen(false)}
      buttons={({ cancelAction }) => (
        <Button onClick={cancelAction} color="primary" variant="contained">
          Dismiss
        </Button>
      )}
    >
      <Typography style={{ marginBottom: 20 }}>
        Looks like you are using a browser that isn't fully supported by Fathom Central. We can't
        guarantee all functionality will work as intended.
      </Typography>
      <Typography>
        We officially support desktop versions of Chrome, Safari, Firefox and Microsoft Edge.
      </Typography>
    </DialogWrapper>
  );
}

export default BrowserWarning;
