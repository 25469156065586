import { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { taggingMethods } from "./constants";
import { formatLatLon } from "../../helpers/common";
import { formatDateTime } from "../../helpers/time";
import { animalEventFields } from "./constants";
import TagCard from "./TagCard";
import SidebarList from "../common/SidebarList";
import { Add as IconAdd } from "@material-ui/icons";
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
  Popover,
} from "@material-ui/core";

import MenuMultiButton from "../common/MenuMultiButton";
import LabeledLine from "../common/LabeledLine";
import AnimalEventIcon from "./AnimalEventIcon";

const styles = theme => ({
  root: {
    width: "100%",
    paddingTop: 0,
    paddingRight: theme.spacing(0.5),
    marginBotton: 0,
    paddingBottom: theme.spacing(1),
  },
  content: {
    marginTop: 0,
  },
  connectorLine: {
    minHeight: 6,
  },
  stepConnectorVert: {
    padding: 0,
  },
  eventHeaderRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  eventActions: {
    display: "flex",
    flexDirection: "row",
    marginLeft: theme.spacing(3),
  },
  tagsContainer: {
    marginRight: 48,
  },
  addSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: 0,
    padding: 0,
    marginLeft: theme.spacing(3),
  },
  addButton: {
    display: "flex",
    flexDirection: "row",
    filter: "opacity(60%)",
    "&:hover": {
      filter: "opacity(100%)",
    },
    cursor: "pointer",
  },
});

function AnimalEventTimeline({ events, classes, onEventEdit, onEventDelete, onEventAdd }) {
  const [addAnchorEl, setAddAnchorEl] = useState(null);

  function handleAddClick(event) {
    setAddAnchorEl(event.currentTarget);
  }

  function handleAddClose() {
    setAddAnchorEl(null);
  }

  function handleEventSelect(type) {
    onEventAdd(type);
    handleAddClose();
  }
  const addOpen = Boolean(addAnchorEl);

  return (
    <div className={classes.root}>
      <Stepper
        className={classes.root}
        orientation="vertical"
        connector={
          <StepConnector
            classes={{
              vertical: classes.stepConnectorVert,
              line: classes.connectorLine,
            }}
          />
        }
      >
        {events.sort(sortFn).map(event => {
          const eventType = event.__typename;
          const fields = animalEventFields[eventType];

          return (
            <Step key={event.id} active={true} className={classes.content}>
              <StepLabel
                StepIconComponent={AnimalEventIcon}
                StepIconProps={{ eventType: event.__typename }}
              >
                <div className={classes.eventHeaderRow}>
                  <div className={classes.eventHeaderRow}>
                    <Typography noWrap variant="h6">
                      {getEventName(event)}
                    </Typography>
                    <Typography noWrap>{formatDateTime(event.time, true)}</Typography>
                  </div>
                  <div className={classes.eventActions}>
                    <MenuMultiButton
                      options={[
                        {
                          text: "Edit",
                          onClick: () => onEventEdit(event.id),
                        },
                        {
                          text: "Delete",
                          onClick: () => onEventDelete(event),
                        },
                      ]}
                    />
                  </div>
                </div>
              </StepLabel>

              <StepContent className={classes.content}>
                <div style={{ marginTop: 0, paddingTop: 0, paddingBottom: 6 }}>
                  {event.devices && (
                    <div className={classes.tagsContainer}>
                      {event.devices.map(device => {
                        return <TagCard key={device.id} device={device} />;
                      })}
                    </div>
                  )}

                  <LabeledLine label={fields.researcherName.label} value={event.researcherName} />
                  <LabeledLine label={fields.locationName.label} value={event.locationName} />
                  <LabeledLine
                    label={fields.latitude.label}
                    value={formatLatLon(event.latLon && event.latLon.latitude)}
                  />
                  <LabeledLine
                    label={fields.longitude.label}
                    value={formatLatLon(event.latLon && event.latLon.longitude)}
                  />

                  {eventType === "AnimalEventCapture" && (
                    <LabeledLine label={fields.captureMethod.label} value={event.captureMethod} />
                  )}

                  {eventType === "AnimalEventTagging" && (
                    <>
                      <LabeledLine
                        label={fields.taggingMethod.label}
                        value={taggingMethods[event.taggingMethod]}
                      />
                      <LabeledLine
                        label={fields.anatomicLocation.label}
                        value={event.anatomicLocation}
                      />

                      <LabeledLine label={fields.anaesthetic.label} value={event.anaesthetic} />
                      <LabeledLine label={fields.sedative.label} value={event.sedative} />
                      <LabeledLine label={fields.buffer.label} value={event.buffer} />
                      <LabeledLine label={fields.pitTagId.label} value={event.pitTagId} />
                      <LabeledLine label={fields.floyTagId.label} value={event.floyTagId} />
                    </>
                  )}
                </div>
                <Typography variant="body2" style={{ fontStyle: "italic" }}>
                  {event.notes}
                </Typography>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      <div className={classes.addSection}>
        <div className={classes.addButton} onClick={handleAddClick}>
          <IconAdd />
          <Typography> New Event </Typography>
        </div>
        <Popover
          open={addOpen}
          anchorEl={addAnchorEl}
          onClose={handleAddClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <SidebarList
            items={[
              {
                text: "Capture",
                icon: <AnimalEventIcon eventType="AnimalEventCapture" light />,
                onClick: () => handleEventSelect("captureEvent"),
              },
              {
                text: "Tag",
                icon: <AnimalEventIcon eventType="AnimalEventTagging" light />,
                onClick: () => handleEventSelect("taggingEvent"),
              },
              {
                text: "Release",
                icon: <AnimalEventIcon eventType="AnimalEventRelease" light />,
                onClick: () => handleEventSelect("releaseEvent"),
              },
              {
                text: "Recover Tags",
                icon: <AnimalEventIcon eventType="AnimalEventDeviceRecovery" light />,
                onClick: () => handleEventSelect("recoveryEvent"),
              },
            ]}
          />
        </Popover>
      </div>
    </div>
  );
}

function getEventName(event) {
  switch (event.__typename) {
    case "AnimalEventTagging":
      return "Tagging";
    case "AnimalEventCapture":
      return "Capture";
    case "AnimalEventDeviceRecovery":
      return "Device Recovery";
    case "AnimalEventRelease":
      return "Release";
    default:
      return "Event";
  }
}

function sortFn(a, b) {
  const order = [
    "AnimalEventCapture",
    "AnimalEventDeviceRecovery",
    "AnimalEventTagging",
    "AnimalEventRelease",
  ];
  return b.time.localeCompare(a.time) || order.indexOf(b.__typename) - order.indexOf(a.__typename);
}

AnimalEventTimeline.propTypes = {
  events: PropTypes.array.isRequired,
  onEventEdit: PropTypes.func.isRequired,
  onEventDelete: PropTypes.func.isRequired,
  onEventAdd: PropTypes.func.isRequired,
};

export default withStyles(styles)(AnimalEventTimeline);
