import PropTypes from "prop-types";
import { CenterDialog } from "../../fathom-brella";
import Spinner from "../common/Spinner";
import { Link, Typography } from "@material-ui/core";
import history from "../../helpers/history";

/* During 'loading' states nothing is rendered. This makes sense because
 * most of the loading times are < 1 sec. Moving forwards a spinner could
 * be shown if there is every a > 1 second delay. */

function LandingPage({
  rxLogsReady,
  rxLogOptions,
  rxLogOptionsSelected,
  dataListLoaded,
  selectedStudyId,
}) {
  // show spinner if one of the following is true:
  //   1. the list of files has not yet loaded
  //   2. the user has selected a log but the data has not yet loaded
  if (!rxLogsReady || (rxLogOptionsSelected.length > 0 && !dataListLoaded)) {
    return <Spinner />;
  }

  const message =
    rxLogOptions.length === 0
      ? noSelectionMsg(selectedStudyId)
      : rxLogOptionsSelected.length === 0
      ? "To view data, select at least one receiver log file from the menu on the left."
      : "Select one or more data types using the checkboxes.";

  return (
    <CenterDialog>
      <Typography>{message}</Typography>
    </CenterDialog>
  );
}

function noSelectionMsg(selectedStudyId) {
  const onClick = event => {
    event.preventDefault();
    if (selectedStudyId) {
      const search = new URLSearchParams({ study: selectedStudyId });
      history.push(`/files?${search.toString()}`);
    } else {
      history.push(`/files`);
    }
  };

  const filesLink = (
    <Link href="#" underline="always" onClick={onClick}>
      Files
    </Link>
  );

  if (selectedStudyId) {
    return (
      <>
        There are no receiver logs linked to the selected study. You can add some by using the{" "}
        {filesLink} tab.
      </>
    );
  }

  return (
    <>No receiver logs have been uploaded. You can upload some by using the {filesLink} tab.</>
  );
}

LandingPage.propTypes = {
  rxLogsReady: PropTypes.bool,
  rxLogOptions: PropTypes.array,
  rxLogOptionsSelected: PropTypes.array,
  dataListLoaded: PropTypes.bool,
  selectedStudyId: PropTypes.string,
};

export default LandingPage;
