import { createRef, Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import autoBind from "auto-bind/react";
import * as d3 from "d3";

import mapboxgl from "mapbox-gl";
import { mapboxMap, getBounds, mapboxLatLng } from "../../helpers/map";
import { formatDevice } from "../../helpers/device";
import { formatDate } from "../../helpers/time";
import { getDeploymentColor, deploymentColorMap } from "./utils.js";
import { isEqual } from "lodash";

const styles = {
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  legend: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "3px",
    bottom: "30px",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    padding: "10px",
    position: "absolute",
    right: "10px",
    zIndex: 1,
    fontSize: "16px",
    lineHeight: 1.5,
  },
  legendKey: {
    display: "inline-block",
    width: "10px",
    height: "10px",
    marginRight: "5px",
    borderRadius: "2px",
  },
};

const markerPath =
  "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";

class DeploymentMap extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { width: "100%", height: "100%" };
    this.refRoot = createRef();
    this.refMap = createRef();
    this.toolTip = new mapboxgl.Popup({ closeButton: false, closeOnClick: false });
    this.toolTipExists = false;
    this.pins = {}; // should always 100% contain references to all pins on map
    this.bounds = null;
    this.resizeTimerId = null;
  }

  componentDidMount() {
    const { mapOptions, controlOptions } = this.props;
    this.setState({
      width: this.refRoot?.current ? this.refRoot.current.clientWidth : 0,
      height: this.refRoot?.current ? this.refRoot.current.clientHeight : 0,
    });

    this.map = mapboxMap(this.refMap.current, mapOptions, controlOptions);
    this.updatePins();
    this.fitBounds();

    if (window.ResizeObserver && this.refRoot?.current) {
      const resizeObserver = new ResizeObserver(entries => {
        clearTimeout(this.resizeTimerId);
        this.resizeTimerId = setTimeout(() => {
          for (const entry of entries) {
            this.setState({ width: entry.contentRect.width, height: entry.contentRect.height });
            this.map.resize();
          }
        }, 50);
      });
      resizeObserver.observe(this.refRoot.current);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.deployments !== prevProps.deployments) {
      setTimeout(this.updatePins, 50);
    }

    if (this.props.selectedDeploymentIds !== prevProps.selectedDeploymentIds) {
      this.updateSelectedPins();
    }
  }

  getAllLatlons() {
    const { deployments = [] } = this.props;
    const latLons = [];
    deployments.forEach(de => {
      de.positions.forEach(p => {
        if (p.latLon) {
          latLons.push(p.latLon);
        }
      });
    });
    return latLons;
  }

  // Check if the position time frame overlaps the bounds given, if any
  isWithinTimeBound(deployment, position) {
    const { timeBound } = this.props;
    if (!timeBound) return true;

    let start = position.start || deployment.start;
    let end = position.end || deployment.end;
    if (start) start = new Date(start);
    if (end) end = new Date(end);

    if (!start || !timeBound[0]) {
      console.error("DeploymentMap isWithinTimeBound invalid input", {
        deployment,
        position,
        timeBound,
      });
      return null;
    }

    if (end && end < timeBound[0]) return false; // position ends before bound starts
    if (timeBound[1] && start > timeBound[1]) return false; // position starts after bound ends
    return true;
  }

  // add, remove & update pins to keep them in sync with the deployments prop
  updatePins() {
    const positionIds = {};
    const epsilon = 0.000000001;
    for (const deployment of this.props.deployments) {
      deployment.positions.forEach(pos => {
        if (this.isWithinTimeBound(deployment, pos)) {
          positionIds[pos.id] = 1;
          const pin = this.pins[pos.id];
          if (pin) {
            const { lng, lat } = pin.getLngLat();
            if (
              Math.abs(lat - pos.latLon.latitude) > epsilon ||
              Math.abs(lng - pos.latLon.longitude) > epsilon
            ) {
              // marker exists: update coords
              pin.setLngLat(mapboxLatLng(pos.latLon));
            }
            // also check for color changes if color is per deployment:
            if (this.props.useDeploymentColors) {
              if (pin.getElement().firstChild.getAttribute("fill") != deployment.pinColor) {
                pin.getElement().firstChild.setAttribute("fill", deployment.pinColor);
              }
            }
          } else {
            // marker doesn't exist: create new one
            this.pins[pos.id] = this.createPin(deployment, pos);
          }
        }
      });
    }

    // remove pins that no longer exist
    Object.entries(this.pins).forEach(([positionId, pin]) => {
      if (!positionIds[positionId]) {
        pin.remove();
        delete this.pins[positionId];
      }
    });

    this.updateSelectedPins();

    // fit bounds when changed if enabled:
    this.props.fitBoundsOnChange && this.fitBounds();
  }

  fitBounds() {
    const latLons = this.getAllLatlons();
    const bounds = getBounds(latLons, "latitude", "longitude");
    if (!isEqual(bounds, this.bounds)) {
      this.bounds = bounds;
      if (bounds) {
        this.map.fitBounds(new mapboxgl.LngLatBounds(bounds), { duration: 50, maxZoom: 12 });
      }
    }
  }

  // create a new map pin
  createPin(deployment, position) {
    const { useDeploymentColors } = this.props;
    const color = useDeploymentColors ? deployment.pinColor : getDeploymentColor(deployment);
    const el = document.createElement("div");
    d3.select(el)
      .style("cursor", "pointer")
      .style("display", "flex")
      .attr("width", 26)
      .attr("height", 32)
      .on("mouseover", () => this.handleHoverOn(deployment, position))
      .on("mouseout", this.handleHoverOff)
      .on("click", () => this.onPinClick(deployment.id))
      .append("svg")
      .attr("width", 26)
      .attr("height", 32)
      .attr("fill", color)
      .attr("viewBox", "7.5 1 10 22")
      .append("path")
      .attr("stroke", "black")
      .attr("stroke-width", 1)
      .attr("d", markerPath);

    const pin = new mapboxgl.Marker(el, { anchor: "bottom" })
      .setLngLat(mapboxLatLng(position.latLon))
      .addTo(this.map);

    this.pins[position.id] = pin;
    return pin;
  }

  updateSelectedPins() {
    const { deployments, selectedDeploymentIds } = this.props;
    const selectedDeployments = deployments.filter(d => selectedDeploymentIds?.includes(d.id));

    Object.entries(this.pins).forEach(([positionId, pin]) => {
      const el = pin.getElement();
      let selected = false;
      selectedDeployments.forEach(deployment => {
        if (deployment.positions.find(position => position.id === positionId)) {
          selected = true;
        }
      });

      d3.select(el)
        .select("svg")
        .select("path")
        .attr("stroke", selected ? "white" : "black");
    });
  }

  handleHoverOn(deployment, position) {
    const devicesStr = deployment?.deviceAttachments.map(d => formatDevice(d.device)).join(", ");
    const start = position.start || deployment.start;
    const end = position.end || deployment.end;
    const label =
      !deployment.station?.name && !devicesStr
        ? "No station or devices"
        : "<div style='display:flex; flex-direction:column; align-items: center; font-family: Nunito Sans; font-size: 12px'>" +
          ((deployment.station?.name && `<div>${deployment.station.name}</div>`) || "") +
          ((devicesStr && `<div>${devicesStr}</div>`) || "") +
          `<div>${formatDate(start)} → ${(end && formatDate(end)) || "∞"}</div></div>`;

    this.toolTip.setLngLat(mapboxLatLng(position.latLon)).setHTML(label);
    if (!this.toolTipExists) {
      this.toolTip.addTo(this.map);
      this.toolTipExists = true;
    }
  }

  handleHoverOff() {
    this.toolTip.remove();
    this.toolTipExists = false;
  }

  onPinClick(deploymentId) {
    this.props.handleSelectDeployment(deploymentId);
  }

  render() {
    const { classes, showLegend = true } = this.props;
    const { width, height } = this.state;

    return (
      <div ref={this.refRoot} className={classes.root}>
        <div ref={this.refMap} style={{ width, height }} />
        {showLegend && (
          <div className={classes.legend}>
            {Object.entries(deploymentColorMap).map(([type, color]) => (
              <div key={type}>
                <span className={classes.legendKey} style={{ backgroundColor: color }}></span>
                <span>{type}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

DeploymentMap.propTypes = {
  deployments: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedDeploymentIds: PropTypes.array,
  handleSelectDeployment: PropTypes.func.isRequired,
  /** Automatically fit bounds when update occurs */
  fitBoundsOnChange: PropTypes.bool,
  /** show/hide legend (default true) */
  showLegend: PropTypes.bool,
  /** options object to pass to new mapboxgl.Map */
  mapOptions: PropTypes.object,
  /** options object to pass to mapboxMap wrapper: turns controls on/off with specified customizations */
  controlOptions: PropTypes.object,
  /** options object to pass to mapboxMap wrapper: color pins based on pinColor in deployment object instead of device config. Default false. */
  useDeploymentColors: PropTypes.bool,
  /** <Date[] | null> Only show positions within this time interval */
  timeBound: PropTypes.array,
};

export default withStyles(styles)(DeploymentMap);
