import { eventColorScale as colorScale } from "../../../containers/RxDiag";

const STROKE_WIDTH = 4;

function EventOverlay({ xScale, events }) {
  return events.map(({ time, type, filename }, i) => {
    const x = xScale(time);
    const color = colorScale(type);
    return (
      <g key={`${time}-${type}-${filename}`}>
        <line key={i} x1={x} x2={x} y1={0} y2="100%" stroke={color} strokeWidth={STROKE_WIDTH} />
        <text
          x={0}
          y={x}
          transform="rotate(-90)"
          dy="1em"
          dx={-5}
          fontFamily="sans-serif"
          fontSize="12px"
          textAnchor="end"
          opacity="0.7"
        >
          {filename}
        </text>
      </g>
    );
  });
}

export default EventOverlay;
