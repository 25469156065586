import { createRef, Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import autoBind from "auto-bind/react";
import vis from "vis";
import "vis/dist/vis.min.css";
import "../../styles/visTimeline.css";
import TimelineLegend from "./TimelineLegend";
import { getDeploymentColor } from "./utils.js";
import moment from "moment";
import { min } from "lodash";
import * as d3 from "d3";
import memoize from "memoize-one";
import { getAttachedDevices } from "./utils";

const styles = {
  root: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  timelineRoot: {
    padding: 10,
    paddingTop: 70,
    paddingBottom: 50,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  refContainer: {
    width: "100%",
    maxHeight: "100%",
  },
  toolTip: {
    position: "absolute",
    textAlign: "center",
    marginLeft: 5,
    padding: 3,
    background: "white",
    borderRadius: 3,
    fontSize: 12,
    pointerEvents: "none",
    zIndex: 10000,
    border: "1px solid black",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  },
};
class DeploymentTimeline extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.refContainer = createRef();
    this.getToolTipText = memoize(getToolTipText);
  }

  componentDidMount() {
    const { deployments, classes } = this.props;
    const minDeployDate = min(deployments.map(d => d.start));

    const options = {
      selectable: true,
      groupOrder: "id", // order the groups by id
      stack: false,
      verticalScroll: true,
      maxHeight: "100%",
      showCurrentTime: false,
      moment: date => moment(date),
      // prevent zooming in future / past + add a month padding so bars aren't right on the edge
      max: moment().add(1, "month").valueOf(),
      min: moment(minDeployDate).subtract(1, "month").valueOf(),
    };

    this.timeline = new vis.Timeline(
      this.refContainer.current,
      getItems(deployments),
      getGroups(deployments),
      options
    );

    this.toolTip = d3
      .select("body")
      .append("div")
      .attr("class", classes.toolTip)
      .style("opacity", 0);

    this.timeline.setSelection(this.props.selectedDeploymentIds);

    this.timeline.fit(); //make the timeline scale to show all the data

    this.timeline.on("select", this.handleSelect);
    this.timeline.on("mouseMove", this.handleItemOver);
    this.refContainer.current.addEventListener("mouseleave", this.hideToolTip);
  }

  componentWillUnmount() {
    this.timeline.off("select", this.handleSelect);
    this.timeline.off("mouseMove", this.handleItemOver);
    this.refContainer.current.removeEventListener("mouseleave", this.hideToolTip);
  }

  handleItemOver({ item, event }) {
    if (!item) {
      this.hideToolTip();
      return;
    }

    this.toolTip
      .text(this.getToolTipText(this.props.deployments)[item])
      .style("left", event.pageX + "px")
      .style("top", event.pageY + "px")
      .style("opacity", 1);
  }

  hideToolTip() {
    this.toolTip.style("opacity", 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.deployments !== prevProps.deployments) {
      this.updateTimeline();
    }
    if (this.props.selectedDeploymentIds !== prevProps.selectedDeploymentIds) {
      this.timeline.setSelection(this.props.selectedDeploymentIds);
    }
  }

  updateTimeline() {
    const { deployments } = this.props;
    this.timeline.setGroups(getGroups(deployments));
    this.timeline.setItems(getItems(deployments));
  }

  handleSelect(properties) {
    this.props.handleSelectDeployment(properties.items[0]);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.timelineRoot}>
          <div className={classes.refContainer} ref={this.refContainer} />
          <TimelineLegend />
        </div>
      </div>
    );
  }
}

function getGroupId(deployment) {
  return (deployment.station && deployment.station.name) || "!nostation_" + deployment.id;
}

function getGroups(deployments) {
  return deployments.reduce((acc, de) => {
    const id = getGroupId(de);
    return acc.find(x => x.id === id)
      ? acc // group already exists
      : acc.concat({ id, content: (de.station && de.station.name) || "" }); // add new group
  }, []);
}

function getItems(deployments) {
  return deployments.map(de => {
    const color = getDeploymentColor(de);
    const style = `background-color: ${color};`;

    return {
      id: de.id,
      start: de.start || undefined,
      end: de.end || moment().utc(),
      style,
      className: de.end ? undefined : "no-end",
      group: getGroupId(de),
    };
  });
}

function getToolTipText(deployments) {
  const result = {};
  for (const d of deployments) {
    const devices = getAttachedDevices(d);
    const deviceString = devices.join(", ") || "No attached devices.";
    result[d.id] = deviceString;
  }
  return result;
}

DeploymentTimeline.propTypes = {
  deployments: PropTypes.array,
  selectedDeploymentIds: PropTypes.arrayOf(PropTypes.string),
  handleSelectDeployment: PropTypes.func,
};

export default withStyles(styles)(DeploymentTimeline);
