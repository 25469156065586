import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { userDisplayName } from "../../helpers/common";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
} from "@material-ui/core";

import { Close as IconClose, CreateNewFolder as IconWorkspaceAdd } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  title: {
    marginRight: theme.spacing(6),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    padding: theme.spacing(2),
  },
  inviteCard: {
    paddingBottom: 0,
  },
  inviteBody: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  inviteActions: {
    justifyContent: "flex-end",
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
}));

function WorkspaceInvites({
  open,
  handleClose,
  handleAcceptInvite,
  handleDeclineInvite,
  workspaceInvites = [],
  userEmail,
  workspaceInviteClicked = {},
  signInAgain,
}) {
  const classes = useStyles();
  const isWrongUser = workspaceInviteClicked.id && userEmail !== workspaceInviteClicked.email;

  return (
    <Dialog maxWidth="sm" open={open} onBackdropClick={handleClose}>
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">You have shared workspace invitation(s)</Typography>
        {handleClose ? (
          <IconButton
            aria-label="close"
            size="small"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <IconClose />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {isWrongUser ? (
          <div className={classes.content}>
            <Typography gutterBottom>
              Oops, trying to load workspace invitations for <b>{workspaceInviteClicked.email}</b>,
              but you are signed in as <b>{userEmail}</b>.
            </Typography>
            <Typography>
              Please log in as {workspaceInviteClicked.email} and click the link again.
            </Typography>
          </div>
        ) : workspaceInviteClicked.id && workspaceInvites.length === 0 ? (
          <Typography variant="body1">
            An invite link has been activated, but it has already been completed!
          </Typography>
        ) : (
          workspaceInvites.map(invite => (
            <div className={classes.content} key={invite.id}>
              <Card>
                <CardContent className={classes.inviteCard}>
                  <Typography variant="h5" component="h2" title="Workspace name">
                    {invite.workspace.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    color="textSecondary"
                    title="Workspace description"
                  >
                    {invite.workspace.description}
                  </Typography>
                  <div className={classes.inviteBody}>
                    <Typography variant="body1" gutterBottom>
                      {userDisplayName(invite.sender)} <i>{invite.sender.email}</i> has invited you
                      to collaborate in their shared Fathom workspace: {invite.workspace.name}
                    </Typography>
                    {invite.message ? (
                      <>
                        <Typography variant="subtitle2" gutterBottom>
                          Message from sender:
                        </Typography>
                        <Typography variant="body2">{invite.message}</Typography>
                      </>
                    ) : null}
                  </div>
                </CardContent>
                <CardActions className={classes.inviteActions}>
                  <Button onClick={() => handleDeclineInvite(invite.id)}>Decline</Button>
                  <Button
                    // variant="outlined"
                    color="primary"
                    onClick={() => handleAcceptInvite(invite.id)}
                  >
                    Accept
                  </Button>
                </CardActions>
              </Card>
            </div>
          ))
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {isWrongUser ? (
          <Button variant="outlined" onClick={signInAgain}>
            Sign out
          </Button>
        ) : (
          <>
            <Typography>You may respond later by clicking on the</Typography>
            <IconWorkspaceAdd />
            <Typography>icon in the nav bar.</Typography>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

WorkspaceInvites.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAcceptInvite: PropTypes.func.isRequired,
  handleDeclineInvite: PropTypes.func.isRequired,
  workspaceInvites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      sender: PropTypes.shape({
        email: PropTypes.string,
        givenName: PropTypes.string,
        familyName: PropTypes.string,
      }),
      message: PropTypes.string,
      isOwner: PropTypes.bool,
      workspace: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
      }),
      // ... other members of the types are not used in this component
    })
  ),
  userEmail: PropTypes.string,
  workspaceInviteClicked: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
  }),
  signInAgain: PropTypes.func.isRequired,
};

export default WorkspaceInvites;
