import PropTypes from "prop-types";

const ICONS = {
  AnimalEventRelease: { src: "/img/icon-animal-release.png", alt: "Release Event Icon" },
  AnimalEventCapture: { src: "/img/icon-animal-capture.png", alt: "Capture Event Icon" },
  AnimalEventTagging: { src: "/img/icon-tag.png", alt: "Tagging Event Icon" },
  AnimalEventDeviceRecovery: {
    src: "/img/icon-device-recovery.png",
    alt: "Device Recovery Event Icon",
  },
};

function AnimalEventIcon({ eventType, size = 24, light, disabled }) {
  const { src, alt } = ICONS[eventType];
  const style = disabled ? { filter: "opacity(20%)" } : light ? { filter: "opacity(70%)" } : null; // hack to match lighter font icons
  return <img src={src} alt={alt} width={size} height={size} style={style} />;
}

AnimalEventIcon.propTypes = {
  eventType: PropTypes.oneOf(Object.keys(ICONS)).isRequired,
  size: PropTypes.number,
  light: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default AnimalEventIcon;
