import React, { useState, useRef } from "react";
import { IconButton } from "@material-ui/core";
import { Edit as IconEdit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import Cell from "./Cell";

const useStyles = makeStyles({
  ellipses: {
    //text-overflow: "ellipsis" doesn't work with display: flex, so we have to use an inner span
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

function EditableCell({ children, onEdit, rowData, ...restProps }) {
  const [hover, setHover] = useState(false);
  const ref = useRef();
  const classes = useStyles();

  return (
    <Cell
      ref={ref}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...restProps}
      noEllipses
    >
      {/* With editable content we force ellipses, the edit icon won't be visible if content is overflowing the cell */}
      <span className={classes.ellipses}>{children}</span>
      {hover && (
        <IconButton size="small" onClick={() => onEdit(ref.current, rowData)}>
          <IconEdit />
        </IconButton>
      )}
    </Cell>
  );
}

export default React.memo(EditableCell);
