import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import DialogWrapper from "../common/DialogWrapper";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(1),
    minWidth: 300,
  },
}));

function WorkspaceAddDialog({ open, createWorkspace, handleClose }) {
  const classes = useStyles();
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceDescription, setWorkspaceDescription] = useState("");
  const [errorName, setErrorName] = useState(false);

  const handleCreateWorkspace = () => {
    let valid = true;
    if (!workspaceName) {
      setErrorName(true);
      valid = false;
    }
    if (!valid) return false;

    createWorkspace({
      name: workspaceName,
      description: workspaceDescription,
    });
    handleClose();
    return true;
  };

  return (
    <DialogWrapper
      open={open}
      onBackdropClick={handleClose}
      title="Create New Workspace"
      okAction={handleCreateWorkspace}
      okButtonContent="Next: Add Users"
      cancelAction={handleClose}
    >
      <div className={classes.form}>
        <TextField
          label="Workspace name"
          value={workspaceName}
          onChange={event => {
            if (errorName) {
              setErrorName(false);
            }
            setWorkspaceName(event.target.value);
          }}
          className={classes.input}
          error={errorName}
          helperText={errorName ? "Please enter a workspace name" : ""}
        />
        <TextField
          label="Description"
          value={workspaceDescription}
          onChange={event => setWorkspaceDescription(event.target.value)}
          className={classes.input}
        />
      </div>
    </DialogWrapper>
  );
}

WorkspaceAddDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  createWorkspace: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default WorkspaceAddDialog;
