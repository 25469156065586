import {
  USER_SET_CURRENT,
  USER_REFRESH_DATA,
  USER_CHANGE_OFFSET,
  USER_ACCEPT_PRIVACY_POLICY,
  USER_VIEW_PRIVACY_POLICY,
  USER_CLOSE_PRIVACY_POLICY,
  USER_CLOSE_WHATS_NEW,
  USER_ACCEPT_WHATS_NEW,
  USER_VIEW_WHATS_NEW,
  USER_REVIEW_WHATS_NEW,
} from "../action-types";
import Auth from "@aws-amplify/auth";
import LogRocket from "logrocket";

import gql from "../gqlTag";
import { snackbarError } from "../snackbar/snackbar-actions";
import { callGqlApi, setApiWorkspace } from "../../helpers/api";
import history from "../../helpers/history";
import { userDisplayName } from "../../helpers/common";
import { setGlobalUTCOffset } from "../../helpers/time";
import { selectWorkspace } from "../workspace/workspace-actions";

import { Heap } from "./heap";
import { handleGqlErrors } from "../gql-error/gql-error-actions";

declare global {
  interface Window {
    heap: Heap;
  }
}

const userQuery = gql`
  {
    currentUser {
      id
      sysproContactId
      email
      givenName
      nickname
      familyName
      acceptedPrivacyPolicy
      isExternal
      workspaceInvites {
        id
        message
        isOwner
        sender {
          email
          givenName
          familyName
        }
        status
        workspace {
          id
          name
          description
        }
      }
      newFeatures {
        id
        title
        htmlBody
        componentKey
      }
    }
    allNewFeatures {
      id
      title
      htmlBody
      componentKey
    }
  }
`;

export function selectOffset(offsetName, reloadWorkspace = true) {
  return (dispatch, getState) => {
    setGlobalUTCOffset(offsetName);
    localStorage.setItem("offsetPreference", offsetName);
    dispatch({
      type: USER_CHANGE_OFFSET,
      payload: { offset: offsetName },
    });
    if (
      reloadWorkspace &&
      !history.location.pathname.includes("/detection-map") &&
      !history.location.pathname.includes("/rx-diag")
    ) {
      const workspaceId = getState().workspaces.selectedWorkspace.id;
      dispatch(selectWorkspace(workspaceId, history.location.pathname));
    }
  };
}

function logRocketIdentify(user) {
  if (user.attributes && user.attributes.email) {
    LogRocket.identify(user.attributes.email, {
      email: user.attributes.email,
    });
  }
}

export function setCurrentUser(user, isAdmin = false) {
  return dispatch => {
    // combine cognito user with gql user
    if (user) {
      if (window.heap?.appid && user?.attributes?.email) {
        // identify user to heap (but only if heap is loaded)
        window.heap.identify(user.attributes.email);
      }
      return dispatch(getGqlUser(user, isAdmin));
    } else {
      if (window.heap?.appid) {
        // clear heap identity on sign out so different user on same browser won't overwrite identity (but only if heap is loaded)
        window.heap.resetIdentity();
      }
      dispatch({
        type: USER_SET_CURRENT,
        payload: { user: {} },
      });
    }
  };
}

export function getGqlUser(user, isAdmin) {
  return dispatch => {
    return callGqlApi(userQuery)
      .then(data => {
        dispatch({
          type: USER_SET_CURRENT,
          payload: {
            user: {
              ...data.currentUser,
              ...user,
              displayName: userDisplayName(data.currentUser),
              allNewFeatures: data.allNewFeatures,
            },
            isAdmin,
          },
        });
        // Only load what's new if there are things new to them and don't load on detections if redirected from what's new
        const qParams = new URLSearchParams(history.location.search);
        if (
          data.currentUser.newFeatures.length > 0 &&
          !(history.location.pathname.includes("/detections") && qParams.get("whats-new"))
        ) {
          dispatch(viewWhatsNew());
        }
        const offsetPreference = localStorage.getItem("offsetPreference");
        dispatch(selectOffset(offsetPreference || "UTC", false));
        // Capture sign-in event and send user data to logrocket:
        logRocketIdentify(user);
      })
      .catch(error => {
        // just set the cognito info - cannot reach gql server and redirect to error page
        dispatch({
          type: USER_SET_CURRENT,
          payload: { user: { ...user }, isAdmin },
        });
        logRocketIdentify(user);
        console.error(error);
        history.push("/error");
      });
  };
}

export function logout() {
  return dispatch => {
    // amplify will redirect to sign in on completion
    Auth.signOut()
      .then(() => {
        localStorage.clear();
        setApiWorkspace("");
        window.location.reload();
      })
      .catch(err => {
        console.error(err);
        dispatch(snackbarError("Error Signing Out"));
      });
  };
}

export function refreshUserData() {
  return dispatch => {
    return callGqlApi(userQuery)
      .then(data => {
        dispatch({
          type: USER_REFRESH_DATA,
          payload: {
            user: { ...data.currentUser, displayName: userDisplayName(data.currentUser) },
          },
        });
      })
      .catch(error => {
        console.error(error);
        dispatch(snackbarError("Error refreshing user data"));
      });
  };
}

export function openAcceptPP() {
  return dispatch => {
    dispatch({
      type: USER_ACCEPT_PRIVACY_POLICY,
    });
  };
}

export function openAccept() {
  console.log("openAccept");
  return dispatch => {
    dispatch({
      type: USER_ACCEPT_WHATS_NEW,
    });
  };
}

export function openViewPP() {
  return dispatch => {
    dispatch({
      type: USER_VIEW_PRIVACY_POLICY,
    });
  };
}

export function closeWhatsNew() {
  return dispatch => {
    dispatch({
      type: USER_CLOSE_WHATS_NEW,
    });
    callGqlApi(
      gql`
        mutation {
          viewedAllNewFeatures
        }
      `
    ).catch(e => handleGqlErrors(e));
  };
}

export function closePP() {
  return dispatch => {
    dispatch({
      type: USER_CLOSE_PRIVACY_POLICY,
    });
  };
}

export function viewWhatsNew() {
  return dispatch => {
    dispatch({
      type: USER_VIEW_WHATS_NEW,
    });
  };
}

export function reviewWhatsNew() {
  return dispatch => {
    dispatch({
      type: USER_REVIEW_WHATS_NEW,
    });
  };
}
