import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Tooltip } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  iconMain: {
    flexGrow: 1,
  },
  iconInfo: {
    justifyContent: "center",
  },
  iconImg: {
    justifyContent: "center",
  },
});

function StudyIcon({ size = 64, quantity, icon, imgSrc, title, label, margin, onClick }) {
  const alt = ``;
  const classes = useStyles();
  return (
    <div className={classes.flexRow}>
      <div className={classNames(classes.flexColCenter, classes.iconMain)}>
        <div className={classNames(classes.flexRow, classes.iconImg)}>
          <Tooltip title={label}>
            <IconButton onClick={onClick}>
              {imgSrc ? (
                <img
                  src={imgSrc}
                  alt={alt}
                  width={size}
                  style={{ marginLeft: margin, marginRight: margin, filter: "opacity(60%)" }}
                />
              ) : (
                icon
              )}
            </IconButton>
          </Tooltip>
        </div>
        <div className={classNames(classes.flexRow, classes.iconInfo)}>
          <Typography> {`${title}: ${quantity}`}</Typography>
        </div>
      </div>
    </div>
  );
}
StudyIcon.propTypes = {
  size: PropTypes.number,
  quantity: PropTypes.number,
  icon: PropTypes.any,
  imgSrc: PropTypes.string,
  onClick: PropTypes.func,
  margin: PropTypes.number,
  title: PropTypes.string,
  label: PropTypes.string,
};

export default StudyIcon;
