import { PureComponent } from "react";
import PropTypes from "prop-types";
import { TextField, Typography } from "@material-ui/core";
import { Check as CheckIcon, Close as CloseIcon } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { passwordPolicyValidation } from "../../../fathom-brella";
import DialogWrapper from "../../common/DialogWrapper";

const styles = theme => ({
  check: {
    display: "flex",
    flexDirection: "row",
  },
  password: {
    paddingLeft: theme.spacing(0.5),
    minWidth: 200,
    marginBottom: theme.spacing(2),
  },
});

class AdminPasswordChange extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      lowerCaseCheck: false,
      upperCaseCheck: false,
      numberCheck: false,
      lengthCheck: false,
      whitespaceCaseCheck: false,
      allValid: false,
    };
  }

  onChange(password) {
    const validationResult = passwordPolicyValidation(password);

    this.setState({
      password,
      ...validationResult,
    });
  }

  renderCheck(checkText, checkKey) {
    const pass = this.state[checkKey];
    return (
      <div className={this.props.classes.check} style={{ color: pass ? "green" : "red" }}>
        {pass ? <CheckIcon /> : <CloseIcon />}
        <Typography> {checkText}</Typography>
      </div>
    );
  }
  render() {
    const { classes } = this.props;
    const { password } = this.state;
    return (
      <DialogWrapper
        open={this.props.open}
        title="Change User's Password"
        okAction={() => this.props.onSubmit(password)}
        okButtonContent="Change"
        okDisabled={!this.state.allValid}
        cancelAction={this.props.onClose}
        fullWidth={false}
      >
        <TextField
          value={password}
          onChange={e => this.onChange(e.target.value)}
          className={classes.password}
        />
        {this.renderCheck("Lower case letter", "lowerCaseCheck")}
        {this.renderCheck("Upper case letter", "upperCaseCheck")}
        {this.renderCheck("Number", "numberCheck")}
        {this.renderCheck("At least 8 characters", "lengthCheck")}
        {this.renderCheck("No white space", "whitespaceCaseCheck")}
      </DialogWrapper>
    );
  }
}

AdminPasswordChange.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(AdminPasswordChange);
