// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

type Props = {
  text?: string;
};

// This component requires the innovasea fish to be available at /img/innovasea-fish-icon.png in the client application
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  spinnerContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundImage: "radial-gradient(ellipse closest-side, white,transparent)",
    alignItems: "center",
    justifyContent: "center",
    padding: "100px",
  },
  rotate: {
    animation: "$rotation 2s infinite linear",
    transformOrigin: "52% 47%",
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(359deg)",
    },
  },
  text: {
    marginTop: 30,
  },
}));

export default function SpinnerInnovasea({ text }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.spinnerContainer}>
        <img className={classes.rotate} src="/img/innovasea-fish-icon.png" alt="Loading..." />
        {text && <Typography className={classes.text}>{text}</Typography>}
      </div>
    </div>
  );
}
