import { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";

// MATERIAL UI
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";

// COMPONENTS
import GraphFieldInput from "../common/GraphFieldInput";
import MenuMore from "../common/MenuMore";

const styles = theme => ({
  inputSection: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  menuMoreContainer: {
    marginLeft: theme.spacing(1),
  },
  checkBox: {
    fontSize: "0.9rem",
  },
  formErrors: {
    marginLeft: theme.spacing(2),
  },
});

class GraphObjectInput extends Component {
  constructor(props) {
    super(props);
    this.getCheckBoxes = memoize(getCheckBoxes);
  }

  render() {
    const {
      graphFields,
      fieldValues,
      selectedFields = [],
      fieldErrors,
      formErrors = [],
      handleInput,
      handleSelectFields,
      classes,
    } = this.props;

    const checkBoxes = this.getCheckBoxes(graphFields, selectedFields, handleSelectFields, classes);

    return (
      <div>
        <div className={classes.inputSection}>
          <div className={classes.inputsContainer}>
            {graphFields.map(
              field =>
                selectedFields.includes(field.dataKey) && (
                  <GraphFieldInput
                    key={field.dataKey}
                    field={field}
                    fieldValue={fieldValues[field.dataKey]}
                    fieldError={fieldErrors && fieldErrors[field.dataKey]}
                    handleInput={handleInput}
                    attachedDevices={this.props.attachedDevices}
                  />
                )
            )}
          </div>
          {checkBoxes && (
            <div className={classes.menuMoreContainer}>
              <MenuMore options={checkBoxes} title={"Control which fields to input"} />
            </div>
          )}
        </div>
        <div className={classes.formErrors}>
          {formErrors.map(error => (
            <Typography color="error" variant="body2" key={error}>
              {error}
            </Typography>
          ))}
        </div>
      </div>
    );
  }
}

function getCheckBoxes(graphFields, selectedFields, handleSelectFields, classes) {
  // Generate checkboxes for fields not marked required
  const checkBoxes = [];
  graphFields.forEach(field => {
    const { dataKey, label, isRequired } = field;

    const checked = selectedFields.includes(dataKey);
    if (!isRequired) {
      checkBoxes.push(
        <FormControlLabel
          control={
            <Checkbox
              classes={{ root: classes.checkBox }}
              size="small"
              checked={checked}
              name={dataKey}
              onClick={() => {
                // if checked is true, the checkbox has just been UNchecked & vice-versa
                const fields = checked
                  ? selectedFields.filter(field => field !== dataKey)
                  : [...selectedFields, dataKey];
                handleSelectFields(fields);
              }}
            />
          }
          label={label}
        />
      );
    }
  });

  return checkBoxes.length > 0 ? checkBoxes : null;
}

GraphObjectInput.propTypes = {
  graphFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  fieldValues: PropTypes.object.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
  fieldErrors: PropTypes.object,
  formErrors: PropTypes.arrayOf(PropTypes.string),
  handleInput: PropTypes.func,
  handleSelectFields: PropTypes.func,
  attachedDevices: PropTypes.array,
};

export default withStyles(styles)(GraphObjectInput);
