import { Typography } from "@material-ui/core";

// Common warning when deleting items within a study context
export default function DeleteWarning({ type }) {
  return (
    <Typography paragraph color="error">
      This will delete the {type}(s) from the workspace. If you want to just remove them from the
      study, you may unlink them instead.
    </Typography>
  );
}
