import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import autoBind from "auto-bind/react";
import { withStyles } from "@material-ui/core/styles";

import FormDialog from "../common/FormDialog";
import GraphFieldInput from "../common/GraphFieldInput";
import { Typography } from "@material-ui/core";

import { addDeviceAttachment } from "../../redux/deployments/deployments-actions";
import { deploymentSizeUnits, deviceLabels } from "./constants";
import { initialFormState, typeCastValues, removeEmptyFields } from "../../helpers/common";
import { isEmpty } from "lodash";
import { validateDeploymentSubObject } from "./utils";

const styles = theme => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  bodyText: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
});

const fields = [
  {
    dataKey: "deviceId",
    label: deviceLabels.device.label,
    helperText: deviceLabels.device.helperText,
    inputType: "autocomplete",
    autoCompleteOptions: [],
    autoCompleteGroupBy: option => option.inStatus,
    autoCompleteLabel: "label",
    autoCompleteValueKey: "id",
    autoCompleteMultiple: false,
    isRequired: true,
    width: 260,
  },
  {
    dataKey: "height",
    label: deviceLabels.height.label,
    helperText: deviceLabels.height.helperText,
    inputType: "measurement",
    unitOptions: deploymentSizeUnits,
    unitDefault: "m",
  },
  {
    dataKey: "start",
    label: deviceLabels.start.label,
    helperText: deviceLabels.start.helperText,
    inputType: "datetime",
    disableFuture: true,
  },
  {
    dataKey: "end",
    label: deviceLabels.end.label,
    helperText: deviceLabels.end.helperText,
    inputType: "datetime",
    disableFuture: true,
  },
];

class AddDevAttachmentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = initialFormState(fields);
    autoBind(this);
  }

  validate() {
    const { fieldErrors, formErrors } = validateDeploymentSubObject(
      this.state.values,
      fields,
      this.props.deployment
    );
    this.setState({ fieldErrors, formErrors });
    return isEmpty(fieldErrors) && isEmpty(formErrors);
  }

  handleSubmit() {
    if (this.validate()) {
      const parsedValues = typeCastValues(this.state.values, fields);
      const cleanedValues = removeEmptyFields(parsedValues, fields);
      // reshape deviceId from {label, id} -> id
      cleanedValues.deviceId = cleanedValues.deviceId.id;
      this.props.dispatch(addDeviceAttachment(this.props.deployment.id, cleanedValues));
      this.close();
    }
  }

  close() {
    this.setState(initialFormState(fields));
    this.props.toggle();
  }

  handleInput(dataKey, value) {
    this.setState({
      values: { ...this.state.values, [dataKey]: value },
      errors: { ...this.state.errors, [dataKey]: null },
    });
  }

  renderInput(dataKey, autoCompleteOptions) {
    return (
      <GraphFieldInput
        field={{ ...fields.find(fld => fld.dataKey === dataKey), autoCompleteOptions }}
        fieldValue={this.state.values[dataKey]}
        fieldError={this.state.fieldErrors[dataKey]}
        handleInput={this.handleInput}
      />
    );
  }

  render() {
    const { open, deviceOptions, classes } = this.props;
    const { formErrors } = this.state;

    return (
      <FormDialog
        open={open}
        mode={"add"}
        title={"Attach a Device"}
        handleSubmit={this.handleSubmit}
        handleClose={this.close}
        formErrors={formErrors}
      >
        <div className={classes.row}>
          {this.renderInput("deviceId", deviceOptions)}
          {this.renderInput("height")}
        </div>
        <Typography variant="body2" className={classes.bodyText}>
          If start and end time are blank the device will be attached for the whole deployment.
        </Typography>
        <div className={classes.row}>
          {this.renderInput("start")}
          {this.renderInput("end")}
        </div>
      </FormDialog>
    );
  }
}

AddDevAttachmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  deployment: PropTypes.object.isRequired,
  deviceOptions: PropTypes.array,
};

export default connect()(withStyles(styles)(AddDevAttachmentDialog));
