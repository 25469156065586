import PropTypes from "prop-types";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Popover, Tooltip, Typography } from "@material-ui/core";
import { LinkOff as IconLinkOff } from "@material-ui/icons";
import FlexCol from "../common/FlexCol";
import FlexRow from "../common/FlexRow";

const useStyles = makeStyles(theme => ({
  chipRoot: {
    maxWidth: "100%",
    textShadow: "0.5px 0.5px 0.5px white",
    "&:hover": {
      boxShadow: "1px 1px 2px -1px #222",
    },
  },
  popover: {
    padding: theme.spacing(2),
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(1.5),
    },
  },
  moreCount: {
    flexShrink: 0,
    borderRadius: "50%",
    border: `1px solid ${theme.palette.secondary.dark}`,
    fontSize: 9,
    height: 20,
    width: 20,
    marginLeft: theme.spacing(1),
    marginRight: -6,
  },
}));

const StudyChip = ({ study, classes, removeAndClear, objectId, setAnchorEl, moreCount }) => (
  <Tooltip
    title={
      setAnchorEl ? (
        <>
          <Typography variant="body2" display="block">
            {study.name}
          </Typography>
          <Typography variant="caption">(click for more options)</Typography>
        </>
      ) : (
        ""
      )
    }
  >
    <Chip
      size="small"
      label={
        <FlexRow vAlign="center">
          <Typography variant="caption" noWrap>
            {study.name}
          </Typography>
          {moreCount > 0 && (
            <FlexCol hAlign="center" vAlign="center" className={classes.moreCount}>
              <span>+{moreCount}</span>
            </FlexCol>
          )}
        </FlexRow>
      }
      classes={{
        root: classes.chipRoot,
      }}
      {...(removeAndClear
        ? {
            onDelete: () => removeAndClear(study.id, objectId),
            deleteIcon: (
              <Tooltip title="Unlink from study">
                <IconLinkOff />
              </Tooltip>
            ),
          }
        : {})}
      onClick={setAnchorEl && (event => setAnchorEl(event.currentTarget))}
    />
  </Tooltip>
);

function StudyLinkChips({ studies, removeFromStudy, objectId }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  if (!studies) {
    return null;
  }
  function removeAndClear(studyId, objectId) {
    // if this is the last study link, clear the popover as well:
    if (studies.length === 1) {
      setAnchorEl(null);
    }
    removeFromStudy(studyId, objectId);
  }
  return (
    <>
      {studies[0] && (
        <StudyChip
          study={studies[0]}
          classes={classes}
          setAnchorEl={setAnchorEl}
          moreCount={studies.length - 1}
        />
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FlexCol hAlign="right" className={classes.popover}>
          {studies.map(study => (
            <StudyChip key={study.id} {...{ study, removeAndClear, classes, objectId }} />
          ))}
        </FlexCol>
      </Popover>
    </>
  );
}

StudyLinkChips.propTypes = {
  studies: PropTypes.array,
  removeFromStudy: PropTypes.func,
  objectId: PropTypes.string,
};

export default StudyLinkChips;
