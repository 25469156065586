import { useState } from "react";
import { PlainObject, SignalListeners, Vega, ViewListener, VisualizationSpec } from "react-vega";
import { Actions } from "vega-embed";
import { AutoSize } from "vega";
import { CircularProgress } from "@material-ui/core";
import ResizeHandler from "../ResizeHandler";

type VegaAutosizedProps = {
  spec: VisualizationSpec;
  data?: PlainObject;
  signalListeners?: SignalListeners;
  actions?: boolean | Actions;
  /**
   * Specify if the chart should fit to only the width, height, or both. Default: "both"
   * If you limit to one of the dimensions, the other can be controlled by the original spec.
   */
  fit?: "width" | "height" | "both";
  loading?: boolean;
  loaderPosition?: { top: number; left: number };
  onNewView?: ViewListener | undefined;
};

/**
 * This top level vega spec parameter ensures that the width, height refer to the outer bounds of
 * the chart and not just the plot area (so padding and axes dimensions are included, not added)
 */
const autosize: AutoSize = { type: "fit", resize: true, contains: "padding" };

/**
 * Wraps the Vega component in a resize handler that updates the dimensions in the spec to
 * whatever the containing element dimensions are whenever the containing element changes size.
 * So you can control the size of the chart by controlling the size of the parent (by any means).
 * NOTE: The top level vega spec parameters `width`, `height`, and `autosize` will be overwritten
 * unless `fit` is constrained to only one of the dimensions.
 */
export function VegaAutosized({
  spec,
  data,
  signalListeners,
  actions,
  fit = "both",
  loading = false,
  loaderPosition,
  onNewView,
}: VegaAutosizedProps) {
  const [size, setSize] = useState<{ width?: number; height?: number }>();
  const uSpec: VisualizationSpec = { ...spec, autosize, ...size };

  function updateSize(w: number, h: number) {
    // console.log("VegaAutosized updateSize", { width: w, height: h });
    if (fit == "height") {
      setSize({ height: h });
    } else if (fit == "width") {
      setSize({ width: w });
    } else {
      // both
      setSize({ width: w, height: h });
    }
  }

  return (
    <ResizeHandler onResize={updateSize}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: loaderPosition?.top,
            left: loaderPosition?.left,
            zIndex: 1,
          }}
        >
          <CircularProgress size={28} />
        </div>
      )}
      <Vega
        spec={uSpec}
        data={data}
        signalListeners={signalListeners}
        actions={actions}
        onNewView={onNewView}
        logLevel={process.env.NODE_ENV === "production" ? 1 : undefined}
      />
    </ResizeHandler>
  );
}
