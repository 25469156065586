import { Typography } from "@material-ui/core";
import { FormatQuote } from "@material-ui/icons";
import FlexCol from "./FlexCol";

const IconQuoteStart = () => {
  return <FormatQuote style={{ color: "#999", transform: "rotate(180deg)" }} />;
};
const IconQuoteEnd = () => {
  return <FormatQuote style={{ color: "#999" }} />;
};

function Testimonial({ customer, company, description, className }) {
  return (
    <FlexCol marginLevel={1} paddingLevel={1} rounded className={className}>
      <Typography variant="h5" gutterBottom>
        <IconQuoteStart />
        {description}
        <IconQuoteEnd />
      </Typography>
      <Typography variant="h6" align="right">
        - {customer},
      </Typography>
      <Typography variant="body2" align="right">
        ({company})
      </Typography>
    </FlexCol>
  );
}
export default Testimonial;
