import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { WindowedTable } from "../../../fathom-brella";
import { formatDateTime } from "../../../helpers/time";
import { useSelectorTyped as useSelector, useThunkDispatch } from "../../../redux/common";
import { latestDetections, websocketDetections } from "../../../redux/admin/live2/live-2-actions";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    height: "100%",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  top: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  tableRoot: {
    minHeight: 200,
    maxHeight: "100%",
    height: "100%",
    width: "100%",
  },
  side: {
    paddingTop: theme.spacing(1),
    width: 250,
    borderRight: "1px solid lightgrey",
  },
  drawer: {},
}));

function Live2() {
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const newDetections = useSelector(state => state.adminLive2.detections);

  useEffect(() => {
    dispatch(websocketDetections());
    dispatch(latestDetections());
  }, [dispatch]);

  return (
    <div className={classes.main}>
      <div className={classes.top}>
        <div>
          {"Recent detections, listening for new detections..."}
          <CircularProgress size={15} />
        </div>
        <div className={classes.tableRoot}>
          <WindowedTable
            initialSortBy={"Timecode"}
            initialSortDir={"DESC"}
            rows={newDetections}
            rowIdKey={"Timecode"}
            columns={[
              {
                width: 125,
                label: "Time",
                dataKey: "Timecode",
                renderFn: time => formatDateTime(time),
                sortFn: (t1, t2) => t1 - t2,
              },
              {
                width: 125,
                label: "Transmitter ID",
                dataKey: "TransmitterID",
                renderFn: id => `A69-${id}`,
              },
              {
                width: 125,
                label: "Receiver ID",
                dataKey: "ReceiverID",
              },
              {
                width: 125,
                label: "Hub ID",
                dataKey: "HubID",
              },
              {
                width: 125,
                label: "Site ID",
                dataKey: "SiteID",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Live2;
