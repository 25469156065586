import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { listStudies, selectStudy } from "../../redux/study/study-actions";
import { snackbarError } from "../../redux/snackbar/snackbar-actions";

function StudyUrlHandler({ useStudy, requireStudy, children }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const studies = useSelector(state => state.study.studies);
  const studiesLoaded = useSelector(state => state.study.isLoaded);
  const selectedStudyId = useSelector(state => state.study.selectedId);
  const storedStudyId = localStorage.getItem("selectedStudyId");
  const enabled = useStudy || requireStudy;

  useEffect(() => {
    if (enabled) {
      dispatch(listStudies());
    }
  }, [dispatch, enabled]);

  useEffect(() => {
    if (enabled && studiesLoaded) {
      const urlStudyId = queryString.parse(history.location.search).study;
      let defaultStudyId = null;
      if (storedStudyId != "null") {
        defaultStudyId = storedStudyId;
      }

      if (requireStudy && !urlStudyId) {
        history.push("home");
        return;
      }

      if (!urlStudyId && selectedStudyId) {
        // No study in URL but study selected in redux store => clear selected study in redux
        dispatch(selectStudy(null));
      } else if ((urlStudyId || defaultStudyId || null) !== selectedStudyId) {
        // Study in URL does not match study in redux store => check if study ID from URL is in study list
        const validStudy =
          studies.find(study => study.id === urlStudyId) ||
          studies.find(study => study.id === defaultStudyId);

        if (validStudy) {
          // Study in the list was found with matching UUID => update selected study in redux
          if (!urlStudyId) history.push("?study=" + validStudy.id);
          dispatch(selectStudy(validStudy));
        } else {
          // No study found => show error and remove it from URL
          dispatch(snackbarError("Study not found"));
          history.push(history.location.pathname);
        }
      }
    }
  });

  return children;
}

export default StudyUrlHandler;
