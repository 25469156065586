import { useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography, Card } from "@material-ui/core";
import { SnackbarContent } from "notistack";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  linearBarColourPrimary: {
    backgroundColor: theme.palette.primary.dark,
  },
  linearColourPrimary: {
    backgroundColor: theme.palette.secondary.light,
    opacity: 0.7,
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  row: {
    marginBottom: theme.spacing(0.75),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    width: 350,
    backgroundColor: "#2196f3",
    padding: theme.spacing(2),
    color: "white",
  },
}));

const FileDownloadSnack = forwardRef(({ onClose }, ref) => {
  const classes = useStyles();
  const inProgFiles = useSelector(state => state.files.downloads);
  const numFiles = Object.keys(inProgFiles).length;

  // close once files are done downloading
  useEffect(() => {
    if (numFiles === 0) {
      onClose();
    }
  }, [numFiles, onClose]);

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.header}>
          <Typography>{`Downloading file${numFiles > 1 ? "s" : ""}`}</Typography>
        </div>
        {Object.keys(inProgFiles).map(k => (
          <div key={k} className={classes.row}>
            <div> {inProgFiles[k].name} </div>
            <LinearProgress
              classes={{
                barColorPrimary: classes.linearBarColourPrimary,
                colorPrimary: classes.linearColourPrimary,
              }}
              variant={"determinate"}
              value={inProgFiles[k].progress}
            />
          </div>
        ))}
      </Card>
    </SnackbarContent>
  );
});

FileDownloadSnack.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
  snackbarKey: PropTypes.string,
};

export default FileDownloadSnack;
