import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { makeStyles } from "@material-ui/core/styles";
import { RxSeriesDatum, RxSeriesType } from "../../redux/rxdiag/rx-diag-types";
import { MARGIN } from "./constants";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: "100%",
    display: "flex",
  },
  svg: {
    display: "block",
    flexGrow: 1,
  },
  plotLine: {
    fill: "none",
    strokeWidth: 1.25,
  },
  highlighted: {
    strokeWidth: 2.25,
    filter:
      "drop-shadow( 0px 0px 1px rgb(255, 255, 255)) drop-shadow( 2px 2px 2px rgba(0, 0, 0, 0.4))",
  },
});

// from https://go.innovasea.com/receiver_noise.pdf
const NOISE_BOUNDS_MV = [
  { label: "HIGH", color: "#f5cccc", start: 650, end: 1000 },
  { label: "MODERATE", color: "#f5f5cc", start: 300, end: 650 },
  { label: "LOW", color: "#cce1cc", start: 0, end: 300 },
];

export type FileData = { filename: string; data: RxSeriesDatum[]; serial: string };

type Props = {
  height: number;
  dataByFile: FileData[];
  category: string;
  xScale: d3.ScaleTime<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  yTickValues: number[];
  serialColorScale: d3.ScaleOrdinal<string, string>;
  yVariable: string;
  seriesType: RxSeriesType;
  showNoiseGuide?: boolean;
  highlightedFileNames?: string[];
};

function Chart({
  height,
  yVariable,
  dataByFile,
  serialColorScale,
  xScale,
  yScale,
  yTickValues,
  category,
  seriesType,
  showNoiseGuide = false,
  highlightedFileNames,
}: Props) {
  const classes = useStyles();
  const svgRef = useRef<SVGSVGElement>(null);

  const yAxisGroupRef = useRef<SVGGElement>(null);
  // npm update to typscript revealed possibility of undefined output of yScale, xScale, so I clamped and cast as number
  const lineGenerator = d3
    .line<RxSeriesDatum>()
    .x(d => xScale(d.x) as number)
    .y(d => yScale(d.y) as number)
    .curve(category === "count" ? d3.curveStepAfter : d3.curveLinear);

  // update the yAxis if the scale or tick values change
  useEffect(() => {
    const yAxis = d3
      .axisLeft(yScale)
      .tickValues(yTickValues)
      .tickFormat(value => (value < 1 ? d3.format("~r")(value) : d3.format("~s")(value)))
      .tickSizeOuter(0);

    d3.select(yAxisGroupRef.current).call(yAxis);
  }, [yScale, yTickValues]);

  return (
    <div className={classes.root}>
      <svg ref={svgRef} className={classes.svg} style={{ height }}>
        <text
          transform="rotate(-90)"
          y={0}
          x={-height / 2}
          dy="1em"
          fontFamily="sans-serif"
          fontSize="12px"
          textAnchor="middle"
          style={{ pointerEvents: "none", userSelect: "none" }}
        >
          {yVariable}
        </text>
        <g name="margin-group" transform={`translate(${MARGIN.left}, 0)`}>
          <g ref={yAxisGroupRef} style={{ pointerEvents: "none", userSelect: "none" }} />
          <clipPath id="clip">
            <rect x={0} y={0} width="100%" height={height} />
          </clipPath>
          <g clipPath="url(#clip)">
            <g name="interior">
              {seriesType === "noise" &&
                showNoiseGuide &&
                NOISE_BOUNDS_MV.map(bound => {
                  // if (yScale.range()
                  const top = yScale(bound.start) as number;
                  const bottom = yScale(bound.end) as number;
                  return (
                    <>
                      <rect
                        key={bound.color}
                        height={top - bottom}
                        x={0}
                        y={bottom}
                        width="100%"
                        fill={bound.color}
                        strokeWidth={0.5}
                        stroke="black"
                        strokeOpacity={0.2}
                      />
                      {bottom < yScale.range()[0] && (
                        <text
                          x="100%"
                          y={Math.min(top, height)}
                          dx={-MARGIN.left - 3}
                          dy={-3}
                          textAnchor="end"
                          fontSize="8pt"
                          opacity={0.5}
                        >
                          {bound.label}
                        </text>
                      )}
                    </>
                  );
                })}
              {dataByFile.map(file => (
                <path
                  d={lineGenerator(file.data)!}
                  stroke={serialColorScale(file.serial)}
                  className={`${classes.plotLine} ${
                    highlightedFileNames?.includes(file.filename) ? classes.highlighted : ""
                  }`}
                  key={file.filename}
                />
              ))}
            </g>
          </g>
        </g>
      </svg>
      <div style={{ width: MARGIN.right }} />
    </div>
  );
}

export default Chart;
