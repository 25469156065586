import moment from "moment";

/** Alias for string to communicate ISO8601 formatted string */
export type ISOTimeString = string;

/**
 * Display name of the utc offset and the offset in minutes which is the format moment expects
 *
 * Populated from list from (https://en.wikipedia.org/wiki/Coordinated_Universal_Time)
 * Ignoring unofficial offsets that aren't a 15 min multiple
 */
// prettier-ignore
export const UTC_OFFSETS = [
  { name: "UTC−12:00", offset: -12 * 60  },
  { name: "UTC−11:00", offset: -11 * 60  },
  { name: "UTC−10:30", offset: -10.5 * 60  },
  { name: "UTC−10:00", offset: -10 * 60  },
  { name: "UTC−09:30", offset: -9.5 * 60 },
  { name: "UTC−09:00", offset: -9 * 60   },
  { name: "UTC−08:30", offset: -8.5 * 60   },
  { name: "UTC−08:00", offset: -8 * 60   },
  { name: "UTC−07:00", offset: -7 * 60   },
  { name: "UTC−06:00", offset: -6 * 60   },
  { name: "UTC−05:00", offset: -5 * 60   },
  { name: "UTC−04:30", offset: -4.5 * 60 },
  { name: "UTC−04:00", offset: -4 * 60   },
  { name: "UTC−03:30", offset: -3.5 * 60 },
  { name: "UTC−03:00", offset: -3 * 60   },
  { name: "UTC−02:30", offset: -2.5 * 60 },
  { name: "UTC−02:00", offset: -2 * 60   },
  { name: "UTC−01:00", offset: -1 * 60   },
  { name: "UTC",       offset: 0         },
  { name: "UTC+01:00", offset: 1 * 60    },
  { name: "UTC+01:30", offset: 1.5 * 60 },
  { name: "UTC+02:00", offset: 2 * 60    },
  { name: "UTC+02:30", offset: 2.5 * 60 },
  { name: "UTC+03:00", offset: 3 * 60    },
  { name: "UTC+03:30", offset: 3.5 * 60 },
  { name: "UTC+04:00", offset: 4 * 60    },
  { name: "UTC+04:30", offset: 4.5 * 60  },
  { name: "UTC+05:00", offset: 5 * 60    },
  { name: "UTC+05:30", offset: 5.5 * 60  },
  { name: "UTC+05:45", offset: 5.75 * 60 },
  { name: "UTC+06:00", offset: 6 * 60    },
  { name: "UTC+06:30", offset: 6.5 * 60  },
  { name: "UTC+07:00", offset: 7 * 60    },
  { name: "UTC+07:30", offset: 7.5 * 60 },
  { name: "UTC+08:00", offset: 8 * 60    },
  { name: "UTC+08:30", offset: 8.5 * 60 },
  { name: "UTC+08:45", offset: 8.75 * 60 },
  { name: "UTC+09:00", offset: 9 * 60    },
  { name: "UTC+09:30", offset: 9.5 * 60  },
  { name: "UTC+09:45", offset: 9.75 * 60 },
  { name: "UTC+10:00", offset: 10 * 60   },
  { name: "UTC+10:30", offset: 10.5 * 60 },
  { name: "UTC+11:00", offset: 11 * 60   },
  { name: "UTC+11:30", offset: 11.5 * 60 },
  { name: "UTC+12:00", offset: 12 * 60   },
  { name: "UTC+12:45", offset: 12.75 * 60 },
  { name: "UTC+13:00", offset: 13 * 60   },
  { name: "UTC+13:45", offset: 13.75 * 60 },
  { name: "UTC+14:00", offset: 14 * 60   },
];

let currentOffsetMinutes = 0;

/**
 * @typedef {import('moment').Moment} Moment
 */

//
// CONSTANTS
//
export const MINUTE_IN_MS = 60 * 1000;
export const HOUR_IN_MS = 60 * MINUTE_IN_MS;
// these are user-facing display formats. Our APIs may accept or return different formats
const DATE_FORMAT = "YYYY-MM-DD";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const DATETIME_FORMAT_TZ = DATETIME_FORMAT + "Z";
const DATETIME_FORMAT_NO_SECONDS = "YYYY-MM-DD HH:mm";
const DATETIME_FORMAT_FILENAME = "YYYY-MM-DD_HH-mm-ss";

export function setGlobalUTCOffset(offsetName) {
  const selectedOffset = getOffsetInMinutes(offsetName);
  if (selectedOffset !== undefined) {
    currentOffsetMinutes = selectedOffset;
  } else {
    throw new Error("Cannot find offset named " + offsetName);
  }
}

export function getOffsetInMinutes(offsetName) {
  return UTC_OFFSETS.find(x => x.name === offsetName)?.offset;
}

/**
 *
 * Wrapper around moment to return a moment in the current offset
 *
 * Generally the regular moment constructor can be used if you just comparing
 * moments. But if you are displaying a time to the user the format functions should
 * be used which call this function to make sure the date is displayed in the
 * correct offset
 *
 * @param {...any} args
 * @returns {Moment} - Moment in the current offset
 */
export function offsettedMoment(...args) {
  return moment(...args).utcOffset(currentOffsetMinutes);
}

/**
 * ***********************************************************************************************
 * FORMATTING FUNCTIONS
 *
 * All formatting functions will format the time into the offset defined by currentOffsetMinutes.
 * EXCEPT formatTimeFromISOStringUTC.
 *
 * There are 3 functions to format datetimes into the user display format.
 *
 * The fastest is formatTimeFromISOStringUTC but it can be used if the input is an iso stamp
 * and the current offset to utc
 *
 * Next fastest is formatTimeFromISOStringDt the input has to be an iso stamp but it will convert
 * to the current set offset (currentOffsetMinutes)
 *
 * The slowest is formatDateTime but the input can be any datetime input moment() supports
 *
 * Here is the time it takes for each to process to 10000 ISO stamps (on Matt's Machine)
 *
 * Function                   | Time
 * ------------------------------------------------
 * formatTimeFromISOStringUTC |   4.97 ms
 * formatTimeFromISOStringDt  |  14.95 ms
 * formatDateTime             | 660.64 ms
 */

/**
 * Takes an ISO 8601 datetime string and formats it for the user. Since we
 * store times in UTC in this format and display them to the user in UTC,
 * it makes sense to simply manipulate the string. The output options are
 * very limited, but it's all we use and it's ~95% faster than using moment
 * to parse into object and format into string and ~82% faster than dayjs.
 *
 * IMPORTANT: The time will ALWAYS be formatted to the UTC timezone. So only use
 *            it if currentOffsetMinutes == 0
 *
 * @param {string} timeISO - I.e. the output of (new Date()).toISOString().
 *  The time must be a string in simplified extended ISO format (ISO 8601).
 * @param {string} toThe - Resolution of output.
 *  Options: "toTheDay" | "toTheMinute" | "toTheSecond" (default)
 *  For more advanced formats or timezone conversion, use moment or dayjs
 * @returns {string} - The formated time string in UTC
 */
export function formatTimeFromISOStringUTC(timeISO, toThe = "toTheSecond") {
  if (!timeISO) {
    return "";
  }
  const [date, time] = timeISO.split("T");
  if (!time) {
    // not ISO time string
    console.warn(`${timeISO} is not an ISO time string`);
    return "";
  }
  const [hms, ms] = time.split(".");
  if (!ms || ms[3] !== "Z") {
    // not ISO time string
    console.warn(`${timeISO} is not an ISO time string`);
    return "";
  }
  if (toThe === "toTheDay") return date;
  if (toThe === "toTheMinute") {
    const [h, m] = hms.split(":");
    return `${date} ${h}:${m}`;
  }
  return `${date} ${hms}`;
}

/**
 * Formats date according to default format.
 *
 * The output date will be in the current offset (currentOffsetMinutes)
 *
 * @param {string} timeISO - I.e. the output of (new Date()).toISOString().
 *  The time must be a string in simplified extended ISO format (ISO 8601).
 * @param {boolean} showSeconds - Set to false to exclude seconds from the output
 * @returns {string} The formatted time in the current offset
 */
export function formatTimeFromISOStringDt(timeISO, showSeconds = true) {
  if (!timeISO) return "";
  const pad = number => number.toString().padStart(2, "0");
  const d = new Date(timeISO);
  if (timeISO !== d.toISOString()) return "";
  /**
   * Adding the d.getTimezoneOffset() resets the timestamp back to utc then
   * Adding the currentOffsetMinutes converts the datetime to the user set offset
   */
  d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000 + currentOffsetMinutes * 60 * 1000);
  const date = d.getFullYear() + "-" + pad(d.getMonth() + 1) + "-" + pad(d.getDate());
  const time = `${pad(d.getHours())}:${pad(d.getMinutes())}${
    (showSeconds && `:${pad(d.getSeconds())}`) || ""
  }`;
  return date + " " + time;
}

/**
 * Formats date according to default format.
 *
 * The output date will be in the current offset (currentOffsetMinutes)
 *
 * @param {Date|number|string} date - Any date input moment supports default is current
 *                                    time in the current offset
 * @returns {string} Formatted date in current offset
 */
export function formatDate(date) {
  if (!date === null || date === undefined) return "";
  return offsettedMoment(date).format(DATE_FORMAT);
}

/**
 * Formats date/time according to default format.
 *
 * The output date will be in the current offset (currentOffsetMinutes)
 *
 * @param {Date|number|string} time - Milliseconds or Date object. Defaults to now.
 * @param {boolean} showSeconds - Include seconds?
 * @returns {string} Formatted date.
 */
export function formatDateTime(time, showSeconds = true) {
  if (time === null || time === undefined) return "";
  const format = showSeconds ? DATETIME_FORMAT : DATETIME_FORMAT_NO_SECONDS;
  return offsettedMoment(time).format(format);
}
/**
 * Takes the Date object that has been manually offsetted by offsetDateD3 and
 * formats it into the correct Date / time to be displayed.
 *
 * @param {Date|number} time
 * @returns {string} Formatted date
 */
export function formatD3DateTime(time) {
  if (time === null || time === undefined) return "";
  const d = new Date(time);
  d.setTime(d.getTime() - currentOffsetMinutes * 60 * 1000);
  return offsettedMoment(d).format(DATETIME_FORMAT_NO_SECONDS);
}

/**
 * Formats date/time according to default filename format that has no spaces or colons
 * which is more suitable for a filename
 *
 * The output date will be in the current offset (currentOffsetMinutes)
 *
 * @param {Date|number|string} time - Milliseconds or Date object. Defaults to now.
 * @returns {string} Formatted date in current offset
 */
export function formatDateTimeFilename(time = new Date()) {
  return offsettedMoment(time).format(DATETIME_FORMAT_FILENAME);
}

/**
 * END FORMATTING FUNCTIONS
 * ***********************************************************************************************
 */

/**
 * Parses date/time string in user-facing format to moment date. It it assumed that
 * the input date is in the current offset (currentOffsetMinutes)
 *
 * The parsing in this function is STRICT, so the resulting moment object can be used to
 * check that the string is complete and valid.
 *
 *
 * @param {string} timeString - Milliseconds or Date object
 * @returns {Moment} Moment object constructed from the parsed date in the current offset.
 */
export function parseDateTime(timeString) {
  // add the timezone offset to string to input time to parsed
  // for whatever reason I had to add it with .format or else the strict parsing would fail
  const input = timeString + offsettedMoment().format("Z");
  const m = offsettedMoment(input, DATETIME_FORMAT_TZ, true);
  return m;
}

/* MB: When originally implementing the RxDiag I learned that Safari at least at that time
 * did not properly parse ISO strings ending in Z to UTC. This might have changed but just
 * wanted to note it in case we decided to use the below function again somewhere.
 * It's not needed now because we are not applying the timezone when the CSV file is parsed. */

// /**
//  *
//  * @param {string} dataServerTimeString
//  *
//  * Parses a time string in the format the data api sends.
//  *
//  * @returns {Moment} Moment object contructed with dataServerTimeString set in the current offset
//  */
// export function parseDataApiTime(dataServerTimeString) {
//   const DATA_SERVER_FORMAT = "YYYY-MM-DD HH:mm:ss.SSSSSSZ";
//   // Adding the Z to the data api format allows moment know the data is UTC then
//   // convert it to the selected timezone
//   return offsettedMoment(new Date(dataServerTimeString + "Z"), DATA_SERVER_FORMAT, null, true);
// }

/**
 * Parses date/time string in user-facing format to moment date.
 *
 * Have to use moment for this because Safari cannot parse ISO date strings.
 *
 * @param {string} isoTimeString - Timestamp in ISO format
 * @returns {Date} JS date object
 */
export function parseISOTimeUTC(isoTimeString) {
  return moment.utc(isoTimeString, moment.ISO_8601).toDate();
}

/**
 *
 * This offsets a date object by the current offset so d3 can display the times in the
 * selected offset. This is a bit of a hack to trick d3 into displaying an offset. The
 * date object won't actually be the correct time.
 *
 * @param {Date} date
 * @returns {Date}
 */
export function offsetDateD3(date) {
  date.setTime(date.getTime() + currentOffsetMinutes * 60 * 1000);
  return date;
}

export default offsettedMoment;

/** Checks if time is within the bounds, if given. ISO8601 time strings only. */
export function isTimeWithinBounds({
  time,
  start,
  end,
}: {
  time: ISOTimeString;
  start?: ISOTimeString | null;
  end?: ISOTimeString | null;
}): boolean {
  if (!time) {
    return false;
  }
  if (start && time < start) {
    return false;
  }
  if (end && time > end) {
    return false;
  }
  return true;
}
