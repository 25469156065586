export type WorkspaceCountRecord = {
  countDate: Date;
  animals: number;
  deployments: number;
  studies: number;
  devicesSyspro: number;
  devicesManual: number;
  devicesMobile: number;
  invitesPending: number;
  invitesAccepted: number;
  invitesDeclined: number;
  submissionsOtn: number;
  usersInternal: number;
  usersExternal: number;
  files: number;
  rxLogs: number;
};

export type SeriesDatum = { x: Date; y: number };

export type SeriesGroup = { group: string; data: SeriesDatum[] };

export const countTypes = [
  "animals",
  "deployments",
  "studies",
  "devicesSyspro",
  "devicesManual",
  "devicesMobile",
  "invitesPending",
  "invitesAccepted",
  "invitesDeclined",
  "submissionsOtn",
  "usersInternal",
  "usersExternal",
  "files",
  "rxLogs",
] as const;

export type CountType = typeof countTypes[number];
