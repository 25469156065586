import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Error as IconError, Warning as IconWarning, Info as IconInfo } from "@material-ui/icons";

import { hideStudyExportDetails } from "../../redux/study/study-actions";
import ListBox from "../common/ListBox";
import DialogWrapper from "../common/DialogWrapper";

function Icon({ mode }) {
  return mode === "error" ? (
    <IconError color="error" />
  ) : mode === "warning" ? (
    <IconWarning />
  ) : (
    <IconInfo />
  );
}

function StudyExportDetails({
  dispatch,
  open,
  studyId,
  title,
  subTitle,
  actionMessage,
  details = [],
}) {
  if (!open) {
    return null;
  }

  return (
    <DialogWrapper
      open={open}
      title={title}
      okAction={() => dispatch(hideStudyExportDetails(studyId))}
      okButtonContent="Dismiss"
      maxWidth="md"
    >
      <Typography paragraph variant="subtitle1">
        {subTitle}
      </Typography>
      <ListBox>
        {details.map(detail => (
          <ListItem key={detail.message}>
            <ListItemIcon>
              <Icon mode={detail.mode} />
            </ListItemIcon>
            <ListItemText>{detail.message}</ListItemText>
          </ListItem>
        ))}
      </ListBox>
      <Typography paragraph>{actionMessage}</Typography>
    </DialogWrapper>
  );
}

StudyExportDetails.propTypes = {
  open: PropTypes.bool,
  studyId: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  actionMessage: PropTypes.string,
  details: PropTypes.arrayOf({
    mode: PropTypes.string,
    message: PropTypes.string,
  }),
};

const mapStateToProps = state => {
  const studyId = state.study.showExportDetailsId;
  if (!studyId) {
    return {};
  }

  return {
    open: true,
    ...state.study.exports[studyId],
  };
};

export default connect(mapStateToProps)(StudyExportDetails);
