import { Component } from "react";
import PropTypes from "prop-types";

// MATERIAL UI
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import InputWrapper from "./InputWrapper";
import MeasurementInput from "../common/MeasurementInput";
import { ifEnterOrEsc } from "../../helpers/input";

class GraphFieldInput extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.fieldValue !== nextProps.fieldValue ||
      this.props.fieldError !== nextProps.fieldError
    );
  }

  render() {
    const { field, fieldValue, fieldError, handleInput, attachedDevices, onSubmit, onCancel } =
      this.props;
    const {
      dataKey,
      autoCompleteOptions,
      autoCompleteLabel,
      autoCompleteValueKey,
      autoCompleteMultiple = true,
      autoCompleteGroupBy,
      helperText,
      ...inputProps
    } = field;
    // Avoid unrecognized prop on DOM element
    delete inputProps.isCarryOver;
    delete inputProps.isDefaultSelected;
    delete inputProps.isRequired;

    const fieldText = fieldError ? fieldError : helperText;

    if (inputProps.inputType === "autocomplete") {
      return (
        <div style={{ width: inputProps.width, marginLeft: 16 }}>
          <Autocomplete
            multiple={autoCompleteMultiple}
            id={dataKey}
            options={
              attachedDevices && attachedDevices.length > 0 // this stuff is specific to release events and shouldn't be in here
                ? autoCompleteOptions.sort((a, b) =>
                    attachedDevices && attachedDevices.find(device => device.id === a.id)
                      ? -1
                      : attachedDevices.find(device => device.id === b.id)
                      ? 1
                      : -1
                  )
                : autoCompleteOptions
            }
            groupBy={
              autoCompleteGroupBy
                ? autoCompleteGroupBy
                : option =>
                    attachedDevices && attachedDevices.length > 0
                      ? attachedDevices.find(device => device.id === option.id)
                        ? "Attached tag(s)"
                        : "More tags"
                      : null
            }
            getOptionLabel={option => (option[autoCompleteLabel] ? option[autoCompleteLabel] : "")}
            getOptionSelected={option => {
              // needed so the correct option can be selected in edit mode
              if (!autoCompleteMultiple) {
                return fieldValue[autoCompleteValueKey] === option[autoCompleteValueKey];
              } else {
                const currentVal = fieldValue || [];
                return (
                  currentVal.findIndex(
                    v => v[autoCompleteValueKey] === option[autoCompleteValueKey]
                  ) > -1
                );
              }
            }}
            value={!autoCompleteMultiple ? fieldValue : fieldValue || []}
            autoComplete
            autoHighlight
            onChange={(_event, value) => handleInput(dataKey, value)}
            renderInput={params => (
              <TextField
                {...params}
                error={Boolean(fieldError)}
                helperText={fieldText}
                required={inputProps.required}
                variant="standard"
                label={inputProps.label}
                autoFocus={inputProps.autoFocus}
                onKeyDown={keyEvent =>
                  ifEnterOrEsc({
                    keyEvent,
                    onEnter: onSubmit,
                    onEsc: onCancel,
                  })
                }
              />
            )}
            ChipProps={{ size: "small" }}
          />
        </div>
      );
    }

    if (inputProps.inputType === "measurement") {
      return (
        <MeasurementInput
          label={inputProps.label}
          valueWidth={inputProps.valueWidth}
          unitWidth={inputProps.unitWidth}
          unitOptions={inputProps.unitOptions}
          unitDefault={inputProps.unitDefault}
          helperText={fieldText}
          value={fieldValue}
          error={Boolean(fieldError)}
          onChange={value => handleInput(dataKey, value)}
          onKeyDown={keyEvent =>
            ifEnterOrEsc({
              keyEvent,
              onEnter: onSubmit,
              onEsc: onCancel,
            })
          }
        />
      );
    }

    return (
      <InputWrapper
        {...inputProps}
        helperText={fieldText}
        value={fieldValue}
        error={Boolean(fieldError)}
        onChange={value => handleInput(dataKey, value)}
        onKeyDown={keyEvent =>
          ifEnterOrEsc({
            keyEvent,
            onEnter: onSubmit,
            onEsc: onCancel,
          })
        }
      />
    );
  }
}

GraphFieldInput.propTypes = {
  fieldValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]), // array for autocomplete
  fieldError: PropTypes.string,
  field: PropTypes.object.isRequired,
  handleInput: PropTypes.func,
  attachedDevices: PropTypes.array, // this prop is specific to animal release events and should be factored out
};

export default GraphFieldInput;
