import { MouseEventHandler, useEffect, useMemo, useState } from "react";
import { BubblePlot } from "../fathom-brella";
import { readStudy } from "../redux/study/study-actions";
import { Typography, Link } from "@material-ui/core";
import WarningIcon from "../components/common/WarningIcon";
import history from "../helpers/history";
import { getOffsetInMinutes } from "../helpers/time";
import { fetchData, clearData } from "../redux/bubbleplot/bubbleplot-actions";
import { useThunkDispatch, useSelectorTyped as useSelector } from "../redux/common";
import DialogWrapper from "../components/common/DialogWrapper";
import { Slider, Checkbox, FormControlLabel } from "@material-ui/core";
import { BubblePlotError } from "../redux/bubbleplot/bubble-plot-types";
import useFileProcessingWarning from "../components/hooks/useFileProcessingWarning";

// prettier-ignore
const sliderToHourLookup: { value: number; label: string; scale: number }[] = [
  { value: 1, label: "1 hour",   scale: 1  },
  { value: 2, label: "2 hours",  scale: 2  },
  { value: 3, label: "4 hours",  scale: 4  },
  { value: 4, label: "8 hours",  scale: 8  },
  { value: 5, label: "12 hours", scale: 12 },
  { value: 6, label: "24 hours", scale: 24 },
];
const sliderToHours = (value: number) => {
  const lookup = sliderToHourLookup.find(m => m.value === value);
  if (lookup) {
    return lookup.scale;
  } else {
    return 1;
  }
};

function BubblePlotContainer() {
  const dispatch = useThunkDispatch();

  const [curSliderSelection, setCurSliderSelection] = useState(5);
  const [binSelection, setBinSelection] = useState(5);
  const [rememberBin, setRememberBin] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const selectedHours = sliderToHours(binSelection);
  const { data, dataStatus, dataError } = useSelector(state => state.bubbleplot);

  const study = useSelector(({ study }: any) => study.studies.find(s => s.id === study.selectedId));
  const studyId = study?.id;
  const studyReady = Boolean(study?.devicePositions);
  const studyError = getError(study, dataError);
  const animalTagIds = useMemo(() => getAnimalTagIds(study), [study]);
  const demoMode = study?.name === "ICFT Demo Mode";

  const offsetMinutes = useSelector(({ user }) => getOffsetInMinutes(user.selectedOffset) || 0);

  function submitBinSize() {
    // If the slider selection isn't the same it will refetch
    setBinSelection(curSliderSelection);
    if (rememberBin) {
      localStorage.setItem("hourlyDetBinSelect", curSliderSelection.toString());
    } else {
      localStorage.removeItem("hourlyDetBinSelect");
    }
    dispatch(clearData());
    dispatch(fetchData(demoMode, sliderToHours(curSliderSelection)));
    setDialogOpen(false);
  }

  useFileProcessingWarning(true);

  // If they have selected a period already automatically load the plot
  useEffect(() => {
    const binSize = localStorage.getItem("hourlyDetBinSelect");
    if (binSize) {
      setCurSliderSelection(parseInt(binSize));
      setBinSelection(parseInt(binSize));
    }
  }, [setBinSelection]);

  // when study changes, refresh to make sure it's up to date, and fetch its device positions
  useEffect(() => {
    if (studyId && !studyReady && !demoMode) {
      dispatch(readStudy(studyId, true));
    }
  }, [studyId, demoMode, dispatch, studyReady]);

  // fetch data when new study selected (and it is "ready")
  useEffect(() => {
    if (studyId && ((studyReady && !studyError) || demoMode)) {
      dispatch(fetchData(demoMode, selectedHours));
    }
  }, [studyId, studyReady, studyError, demoMode, dispatch, selectedHours]);

  // clear data when component unmounts
  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
  }, [dispatch]);

  return (
    <>
      <BubblePlot
        data={data}
        dataStatus={dataStatus}
        demoMode={demoMode}
        animalIds={animalTagIds}
        customError={studyError}
        offsetMinutes={offsetMinutes}
        handleBinSizeClicked={() => setDialogOpen(true)}
        minBinSize={selectedHours}
      />
      <DialogWrapper
        title={"Select bin size"}
        okAction={() => submitBinSize()}
        okButtonContent="Continue"
        open={dialogOpen}
        cancelAction={() => setDialogOpen(false)}
      >
        <Typography gutterBottom>
          {" "}
          Detection map aggregates detections into bins over a selected number of hours. When the
          hours are set smaller your detection map will have more detail but it will take longer to
          generate.
        </Typography>
        <Typography style={{ marginBottom: 20 }}>
          We recommend starting with a large hour selection when generating your first detection map
          or if your study is over a long period of time.
        </Typography>
        <Typography id="non-linear-slider" gutterBottom>
          Bin size
        </Typography>
        <Slider
          style={{ maxWidth: 400, marginLeft: 20, marginBottom: 20 }}
          marks={sliderToHourLookup}
          step={null}
          min={1}
          max={6}
          scale={sliderToHours}
          value={curSliderSelection}
          onChange={(_, v: number) => setCurSliderSelection(v)}
          aria-labelledby="non-linear-slider"
        />
        <FormControlLabel
          label="Remember my choice for next time"
          control={<Checkbox checked={rememberBin} onChange={() => setRememberBin(!rememberBin)} />}
        />
      </DialogWrapper>
    </>
  );
}

function getAnimalTagIds(study: any): string[] {
  const animalTagIds: string[] = [];
  if (study) {
    study.animals.forEach(animal => {
      animal.devices.forEach(device => {
        device.transmitters.forEach(transmitter => {
          animalTagIds.push(transmitter.displayId);
        });
      });
    });
  }
  return animalTagIds;
}

/* Check if study is valid for generating a detection map.
 * Moving forward this check should be to the data-server. */
function getError(study: any, dataError: BubblePlotError | null): JSX.Element | null {
  if (!study || !study.devicePositions) return null;

  const filesLink = (
    <Link href="#" underline="always" onClick={linkHandler("files", study.id)}>
      Files
    </Link>
  );
  const deploymentsLink = (
    <Link href="#" underline="always" onClick={linkHandler("deployments", study.id)}>
      Deployments
    </Link>
  );
  const iconExample = (
    <span style={{ verticalAlign: "middle" }}>
      <WarningIcon />
    </span>
  );

  const logFiles = study.files.filter(f => f.type.includes("receiver-log"));

  const error = study.hasDevicePositionConflicts ? (
    <>
      <Typography paragraph align="justify">
        Oops! A detection map could not be generated due to conflicts in the study's deployment
        data. This usually means that a device was marked as deployed in two different places at the
        same time.
      </Typography>
      <Typography align="justify">
        Please navigate to <Link href="#">Deployments</Link> and resolve these conflicts to
        continue. The conflicts are marked with a warning icon: {iconExample}.
      </Typography>
    </>
  ) : logFiles.length === 0 ? (
    <>
      <Typography paragraph align="justify">
        Oops! Could not generate a detection map for this study because there are no associated log
        files (.vrl or .vdat).
      </Typography>
      <Typography align="justify">
        Please navigate to {filesLink} to link some files to this study to continue.
      </Typography>
    </>
  ) : study.deployments.length === 0 ? (
    <>
      <Typography paragraph align="justify">
        Oops! Could not generate a detection map for this study: There are no associated
        deployments.
      </Typography>
      <Typography align="justify">
        Please navigate to {deploymentsLink} to link some deployments to this study to continue.
      </Typography>
    </>
  ) : study.devicePositions.length === 0 ? (
    <>
      <Typography paragraph align="justify">
        Oops! Could not generate a detection map for this study: No device positions could be
        derived from your deployment data.
      </Typography>
      <Typography align="justify">
        Please navigate to {deploymentsLink} tab and ensure that you have at least one deployment
        with both a position and an attached device.
      </Typography>
    </>
  ) : dataError?.type === "GQL_ERROR" ? (
    <Typography align="justify">
      Oops! Could not generate a detection map for this study: {dataError?.message}
    </Typography>
  ) : null;

  return error;
}

function linkHandler(tab: string, studyId: string): MouseEventHandler {
  return event => {
    event.preventDefault();
    const search = new URLSearchParams({ study: studyId });
    history.push(`/${tab}?${search.toString()}`);
  };
}

export default BubblePlotContainer;
