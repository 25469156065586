import { Component } from "react";
import autoBind from "auto-bind/react";
import PropTypes from "prop-types";
import FormDialog from "../common/FormDialog";
import GraphObjectInput from "./GraphObjectInput";
import { validateAnimalSubObj } from "./utils";
import { isEmpty, merge, pick } from "lodash";
import { removeEmptyFields, typeCastValues, initialFormState } from "../../helpers/common";
import { formatDateTime } from "../../helpers/time";
import { subObjNames } from "./animal-forms-config";

class AnimalSubObjectDialog extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = this.initialState();
  }

  initialState() {
    return initialFormState(this.props.formConfig.fields);
  }

  // Generate initial state object for use in edit mode, by ingesting the editState prop
  initialStateEditMode() {
    const { editState, formConfig } = this.props;
    const cleanedValues = removeEmptyFields(editState, formConfig.fields);

    /* Cast date time field values from graphql API format to format displayed to user.
     * Note: this step also happens for the other field types but it is implicit.
     * E.g. The input component automatically casts numeric input to string */
    formConfig.fields.forEach(field => {
      const fieldKey = field.dataKey;
      const fieldValue = cleanedValues[fieldKey];
      if (field.inputType === "datetime" && fieldValue) {
        cleanedValues[fieldKey] = formatDateTime(fieldValue);
      }
    });

    this.setState({
      ...merge(this.initialState(), {
        values: cleanedValues,
        selectedFields: Object.keys(cleanedValues),
      }),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mode === "closed")
      if (this.props.mode === "add") {
        this.setState(this.initialState());
      } else if (this.props.mode === "edit") {
        this.setState(this.initialStateEditMode());
      }
  }

  handleValue(field, value) {
    this.setState({ values: { ...this.state.values, [field]: value } });
  }

  displayedValues() {
    const { values, selectedFields } = this.state;
    return pick(values, selectedFields);
  }

  handleSubmit() {
    if (this.validate()) {
      const { formConfig, mode } = this.props;
      const { fields } = formConfig;
      const cleanedValues = removeEmptyFields(this.displayedValues(), fields, mode === "edit");
      const formInput = typeCastValues(cleanedValues, fields);
      this.props.handleSubmit(formInput);
    }
  }

  // check values, setting state.fieldErrors as needed. Returns boolean indicating if form is valid
  validate() {
    const { type, mode, formConfig, animalsSelected } = this.props;
    const { fieldErrors, formErrors } = validateAnimalSubObj(
      this.displayedValues(),
      formConfig,
      type,
      mode,
      animalsSelected
    );
    this.setState({ fieldErrors, formErrors });
    return isEmpty(fieldErrors) && isEmpty(formErrors);
  }

  render() {
    const { mode, formConfig, handleClose, animalsSelected } = this.props;
    const { selectedFields, values, fieldErrors, formErrors } = this.state;

    const attachedDevices =
      mode === "recoveryEvent" && animalsSelected ? animalsSelected[0].devices : null;

    return (
      <FormDialog
        open={mode !== "closed"}
        mode={mode}
        title={`${mode === "add" ? "Add" : "Edit"} ${formConfig.label}`}
        handleSubmit={this.handleSubmit}
        handleClose={handleClose}
        onKeyDown={event => {
          if (event.key === "Escape") {
            handleClose();
          }
          if (event.key === "Enter") {
            this.handleSubmit();
          }
        }}
      >
        {mode === "add" && (
          <BulkMessage label={formConfig.label} animalsSelected={animalsSelected} />
        )}

        <GraphObjectInput
          graphFields={formConfig.fields}
          fieldValues={values}
          fieldErrors={fieldErrors}
          formErrors={formErrors}
          selectedFields={selectedFields}
          handleInput={this.handleValue}
          handleSelectFields={selectedFields => this.setState({ selectedFields })}
          attachedDevices={attachedDevices}
        />
      </FormDialog>
    );
  }
}

function BulkMessage({ label, animalsSelected }) {
  const s = animalsSelected.length === 1 ? "" : "s";
  const title = `${label} will be added to the following animal${s}:`;

  return (
    <div>
      {title}
      <ul>
        {animalsSelected.map(animal => {
          const displayName = animal.name || animal.devices[0].transmitters[0].displayId;
          return <li key={animal.id}>{displayName}</li>;
        })}
      </ul>
    </div>
  );
}

AnimalSubObjectDialog.propTypes = {
  mode: PropTypes.oneOf(["closed", "add", "edit"]).isRequired,
  type: PropTypes.oneOf(Object.values(subObjNames)).isRequired,
  formConfig: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  animalsSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  editState: PropTypes.object,
};

export default AnimalSubObjectDialog;
