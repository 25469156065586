import { Button } from "@material-ui/core";

type Props = {
  cancelAction: () => void;
  unlinkAction: () => void;
  okAction: () => void;
  isStudy: boolean;
};

function DeleteDialogButtons({ cancelAction, okAction, unlinkAction, isStudy }: Props) {
  return (
    <>
      <Button onClick={cancelAction} variant="contained">
        Cancel
      </Button>
      {isStudy && (
        <Button onClick={unlinkAction} color="inherit" variant="contained">
          Unlink from Study
        </Button>
      )}
      <Button onClick={okAction} color="primary" variant="contained">
        Delete from Workspace
      </Button>
    </>
  );
}
export default DeleteDialogButtons;
