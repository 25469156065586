import { Typography } from "@material-ui/core";

/** Common style element for sub headings */
export function SoftTitle({ children, ...restProps }) {
  return (
    <Typography variant="subtitle2" color="textSecondary" {...restProps}>
      {children}
    </Typography>
  );
}
