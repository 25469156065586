import axios from "axios";

const LAMBDA_API_GATEWAY_URL = "https://pq070w9st7.execute-api.us-east-1.amazonaws.com";
const LAMBDA_API_GATEWAY_STAGE = "all";
const ADST_ENDPOINT = `${LAMBDA_API_GATEWAY_URL}/${LAMBDA_API_GATEWAY_STAGE}/adst`;

// Avoids no auth error that occurs in certain browsers
const axiosNoCreds = axios.create({
  withCredentials: false,
});

export function sendAdstEmail({ name, phonePref, phone, emailPref, email, serials }) {
  return axiosNoCreds.post(ADST_ENDPOINT, { name, phonePref, phone, emailPref, email, serials });
}
