import React from "react";

type Props = {
  color: string;
  disabled?: boolean;
  size?: number;
  style?: React.CSSProperties;
};

// SVG element of same size & shape as a MUI checkbox, just in a solid color
function ColorSample({ color, disabled, size, style }: Props) {
  size = size || 24;
  return (
    <svg
      style={{
        height: "1em",
        width: "1em",
        fontSize: `${size}px`,
        float: "right",
        marginRight: 10,
        ...style,
      }}
      viewBox={`0 0 ${size} ${size}`}
    >
      <path
        d="M5,3 c-1.1 0-2 .9-2 2 v14 c0 1.1.9 2 2 2 h14 c1.1 0 2-.9 2-2 V5 c0-1.1-.9-2-2-2 z"
        style={{ fill: color, opacity: disabled ? 0.5 : 1 }}
      />
    </svg>
  );
}

export default ColorSample;
