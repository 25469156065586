import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";
import DateTimePicker from "./DateTimePicker";

const styles = theme => ({
  input: {
    margin: theme.spacing(2),
    marginRight: 0,
    marginTop: 0,
  },
});

function InputWrapper({
  classes,
  value,
  onChange,
  label,
  inputType,
  selectOptions = [],
  width,
  minWidth,
  maxWidth,
  helperText,
  errorText,
  autoFocus,
  required,
  ...extraProps
}) {
  const Component = inputType === "datetime" ? DateTimePicker : TextField;
  const changeFn =
    inputType === "datetime"
      ? onChange
      : inputType === "number"
      ? event => onChange(event.target.value.replace(/[^0-9.-]/g, ""))
      : event => onChange(event.target.value);

  const isSelect = inputType === "select";
  let selectOptionsArray = [];
  if (isSelect) {
    if (selectOptions[0] && selectOptions[0].constructor !== Object) {
      // convert simple array into options as objects:
      selectOptionsArray = selectOptions.map(value => ({ label: value, value }));
    } else if (selectOptions[0] && selectOptions[0].constructor === Object) {
      selectOptionsArray = selectOptions;
    }
  }
  return (
    <Component
      label={label}
      helperText={errorText || helperText}
      className={classes.input}
      select={isSelect}
      style={{ minWidth, maxWidth, width }}
      value={value}
      onChange={changeFn}
      autoFocus={autoFocus}
      required={required}
      error={Boolean(errorText)}
      {...extraProps} // Allow overrides
    >
      {isSelect &&
        selectOptionsArray.map(option => (
          <MenuItem key={option.value} value={option.value} title={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </Component>
  );
}

InputWrapper.propTypes = {
  /** The value provided by the parents' state. It is required to use controlled inputs. */
  value: PropTypes.string,
  /** The change handler provided by the parent. It is required to use controlled inputs. */
  onChange: PropTypes.func,
  /** The label applied to the input */
  label: PropTypes.string,
  /** The type of input to make. */
  inputType: PropTypes.oneOf(["text", "number", "datetime", "select"]).isRequired,
  /** Required if inputType is "select". Can be simple array of options, or object of the shape { label, value } */
  selectOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Width of input. Overwrites "minwidth"/"maxwidth" */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Minimum width of input */
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** maximum width of input */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Text to show under input to guide the user */
  helperText: PropTypes.string,
  /** Text to show under input when an error ocurrs. This overrides helperText */
  errorText: PropTypes.string,
  /** The input will auto-focus on component mount if true */
  autoFocus: PropTypes.bool,
  /** The input will be marked as required, but validation must be handled separately */
  required: PropTypes.bool,
};

export default withStyles(styles)(InputWrapper);
