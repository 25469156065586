import { callGqlApi } from "../../../helpers/api";
import gql from "../../gqlTag";
import { ADMIN_WORKSPACES_SET_DATA, ADMIN_WORKSPACES_SET_ERROR } from "../../action-types";
import { snackbarError, basicSnackbar } from "../../snackbar/snackbar-actions";
import { handleGqlErrors } from "../../gql-error/gql-error-actions";
import { setCreatedWorkspaceId, setIncludeDeleted } from "../../workspace/workspace-actions";

export function toggleIncludeDeletedWorkspaces() {
  return (dispatch, getState) => {
    dispatch(setIncludeDeleted(!getState().workspaces.includeDeleted));
    dispatch(getAllWorkspaces());
  };
}

export function getAllWorkspaces() {
  return (dispatch, getState) => {
    const includeDeleted = getState().workspaces.includeDeleted || false;
    const allWorkspacesQuery = gql`
      {
        allWorkspaces(includeDeleted: ${includeDeleted}) {
          id
          name
          isPersonal
          isDeleted
          description
          creator {
            id
            email
          }
          created
          users {
            id
            email
            isOwner
            givenName
            familyName
          }
          invites {
            id
            recipientEmail
            isOwner
            status
          }
        }
      }
    `;

    callGqlApi(allWorkspacesQuery)
      .then(data => {
        dispatch({
          type: ADMIN_WORKSPACES_SET_DATA,
          payload: {
            allWorkspaces: data.allWorkspaces,
          },
        });
      })
      .catch(errors => {
        dispatch({
          type: ADMIN_WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        dispatch(handleGqlErrors(errors));
      });
  };
}

export function createWorkspace({ name, description }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: CreateWorkspaceInput!) {
          createWorkspace(input: $input) {
            workspace {
              id
            }
          }
        }
      `,
      { input: { name, description } }
    )
      .then(result => {
        const workspaceId = result.createWorkspace.workspace.id;
        dispatch(getAllWorkspaces());
        dispatch(basicSnackbar(`Created new workspace`, "success"));
        dispatch(setCreatedWorkspaceId(workspaceId));
      })
      .catch(errors => {
        dispatch({
          type: ADMIN_WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function updateWorkspace({ workspaceId, name, description }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: UpdateWorkspaceInput!) {
          updateWorkspace(input: $input) {
            workspace {
              id
            }
          }
        }
      `,
      { input: { workspaceId, name, description } }
    )
      .then(() => {
        dispatch(getAllWorkspaces());
        dispatch(basicSnackbar(`Updated workspace`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: ADMIN_WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function deleteWorkspaces({ workspaceIds }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: DeleteWorkspacesInput!) {
          deleteWorkspaces(input: $input) {
            deletedWorkspaceIds
          }
        }
      `,
      { input: { workspaceIds } }
    )
      .then(() => {
        dispatch(getAllWorkspaces());
        dispatch(basicSnackbar(`Deleted workspace`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: ADMIN_WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function restoreWorkspaces({ workspaceIds }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: DeleteWorkspacesInput!) {
          restoreWorkspaces(input: $input) {
            restoredWorkspaceIds
          }
        }
      `,
      { input: { workspaceIds } }
    )
      .then(() => {
        dispatch(getAllWorkspaces());
        dispatch(basicSnackbar(`Restored workspace`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: ADMIN_WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function removeWorkspaceUser({ workspaceId, userId }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: RemoveWorkspaceUserInput!) {
          removeWorkspaceUser(input: $input) {
            workspace {
              id
            }
          }
        }
      `,
      { input: { workspaceId, userId } }
    )
      .then(() => {
        dispatch(getAllWorkspaces());
        dispatch(basicSnackbar(`Removed user from workspace`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: ADMIN_WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function addWorkspaceUser({ workspaceId, userId, isOwner }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: UpdateWorkspaceUserInput!) {
          addWorkspaceUser(input: $input) {
            workspace {
              id
            }
          }
        }
      `,
      { input: { workspaceId, userId, isOwner } }
    )
      .then(() => {
        dispatch(getAllWorkspaces());
        dispatch(basicSnackbar(`Added user to workspace`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: ADMIN_WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}

export function updateWorkspaceUser({ workspaceId, userId, isOwner }) {
  return dispatch => {
    callGqlApi(
      gql`
        mutation ($input: UpdateWorkspaceUserInput!) {
          updateWorkspaceUser(input: $input) {
            workspace {
              id
            }
          }
        }
      `,
      { input: { workspaceId, userId, isOwner } }
    )
      .then(() => {
        dispatch(getAllWorkspaces());
        dispatch(basicSnackbar(`Updated workspace user`, "success"));
      })
      .catch(errors => {
        dispatch({
          type: ADMIN_WORKSPACES_SET_ERROR,
          payload: {
            errors: errors,
          },
        });
        console.log(errors);
        errors.forEach(e => {
          dispatch(snackbarError(e.message));
        });
      });
  };
}
