import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind/react";

import ListBox from "../common/ListBox";
import DialogWrapper from "../common/DialogWrapper";

import { withStyles } from "@material-ui/core/styles";
import { Button, MenuItem, TextField } from "@material-ui/core";

const styles = theme => ({
  fileList: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class UploadLinkStudyDialog extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      selectedStudyId: null,
    };
  }

  onDialogOpen() {
    // handle setting the selected study when dialog is opened
    // if a user is in a study context it will be that study
    // if not it will be clear
    this.setState({ selectedStudyId: this.props.appSelectedStudyId });
  }

  render() {
    const { open, submitAction, classes, filenames } = this.props;
    const { selectedStudyId } = this.state;

    if (!open) {
      return null;
    }

    const singleFile = filenames.length === 1;

    const title = singleFile
      ? `Your upload is ready to begin. Would you like to link the new file to a study?`
      : `Your upload of ${filenames.length} files is ready to begin.  ` +
        `Would you like to link the new files to a study?`;

    const fileList = !singleFile && (
      <>
        <div>Files included in this upload:</div>
        <div className={classes.fileList}>
          <ListBox textItems={filenames} />
        </div>
      </>
    );

    return (
      <DialogWrapper
        open={true}
        onEnter={this.onDialogOpen}
        title={title}
        cancelAction={() => submitAction(null)}
        okAction={() => submitAction(selectedStudyId)}
        buttons={({ cancelAction, okAction }) => (
          <>
            <Button variant="outlined" onClick={cancelAction}>
              Skip
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedStudyId}
              onClick={okAction}
            >
              Link
            </Button>
          </>
        )}
      >
        {fileList}
        <TextField
          label="Study"
          size="small"
          required
          select
          fullWidth={true}
          value={selectedStudyId || ""}
          onChange={e => this.setState({ selectedStudyId: e.target.value })}
        >
          {this.props.studies.map(study => (
            <MenuItem key={study.id} value={study.id}>
              {study.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogWrapper>
    );
  }
}

UploadLinkStudyDialog.propTypes = {
  open: PropTypes.any.isRequired,
  submitAction: PropTypes.func,
  filenames: PropTypes.arrayOf(PropTypes.string),
  studies: PropTypes.arrayOf(PropTypes.object),
  appSelectedStudyId: PropTypes.string,
};

export default withStyles(styles)(UploadLinkStudyDialog);
