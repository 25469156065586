import React from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";
import IconMenu from "@material-ui/icons/Menu";
import Dropdown from "../../Dropdown";
import WindowedTable from "../../WindowedTable";

const HEADER_HEIGHT = 35;
const ROW_HEIGHT = 35;
const MAX_HEIGHT = 400;
const WIDTH = 275;

const pickerStyles = theme => {
  return {
    root: {
      width: WIDTH,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      boxShadow: theme.shadows[8],
    },
    filterContainer: {
      padding: theme.spacing(1),
    },
    textAbove: {
      padding: theme.spacing(1),
    },
  };
};

function Toggle() {
  return (
    <ListItem button>
      <ListItemIcon>
        <IconMenu />
      </ListItemIcon>
      <ListItemText>Transmitters</ListItemText>
    </ListItem>
  );
}

class Picker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: "",
    };
  }
  render() {
    const { selection, tagList, selectionHandler, classes } = this.props;
    const { filterText } = this.state;

    const rows = filterText.length > 0 ? tagList.filter(d => d.id.includes(filterText)) : tagList;

    let displayedSelection = [...selection];
    const hiddenSelection = [];

    if (filterText.length > 0) {
      displayedSelection = [];
      selection.forEach(id => {
        if (id.includes(filterText)) {
          displayedSelection.push(id);
        } else {
          hiddenSelection.push(id);
        }
      });
    }

    return (
      <Paper className={classes.root}>
        <Typography className={classes.textAbove} variant="body2">
          This list has been filtered to remove the detections of any transmitters deployed at
          stations as part of the study.
        </Typography>

        <Divider />

        <div className={classes.filterContainer}>
          <TextField
            value={filterText}
            onChange={event => this.setState({ filterText: event.target.value })}
            placeholder="Filter Tag IDs"
          />
        </div>
        <div
          style={{
            /* Table size should fit to list if the list is short, and then scroll if beyond MAX_HEIGHT.
             * If there is a built in way to do this with react-virtualized that would be preferrable,
             * but I couldn't find one. */
            height: Math.min(HEADER_HEIGHT + rows.length * ROW_HEIGHT, MAX_HEIGHT),
          }}
        >
          <WindowedTable
            headerHeight={HEADER_HEIGHT}
            rows={rows}
            rowHeight={ROW_HEIGHT}
            rowIdKey="id"
            selectable
            selectOnRowClick
            selection={selection}
            onSelect={selectionHandler}
            columns={[
              {
                width: 100,
                label: "Full Tag ID",
                dataKey: "id",
                renderFn: id => <span title={id}>{id}</span>,
              },
              {
                width: 50,
                label: "Total Dets.",
                dataKey: "dets",
                numeric: true,
                flexGrow: 1,
                renderFn: dets => <span title={dets}>{dets}</span>,
              },
            ]}
          />
        </div>
      </Paper>
    );
  }
}

export default Dropdown(Toggle, withStyles(pickerStyles)(Picker));
