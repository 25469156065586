import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Divider } from "@material-ui/core";

const styles = () => ({
  root: {
    flexShrink: 0,
  },
  content: {
    height: "100%",
    width: "100%",
    position: "absolute",
    overflow: "auto",
  },
  headerBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 12,
  },
});

function DetailsPanel({ classes, header, content }) {
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.headerBar}>{header}</div>
        <Divider />
        {content}
      </div>
    </div>
  );
}

DetailsPanel.propTypes = {
  open: PropTypes.bool,
  header: PropTypes.object,
  content: PropTypes.object,
};

export default withStyles(styles)(DetailsPanel);
