import { Component } from "react";
import autoBind from "auto-bind/react";
import PropTypes from "prop-types";
import { cleanFileName, toTitleCase } from "../../helpers/common";
import { formatDateTimeFilename, formatDateTime } from "../../helpers/time";
import { updateStudyMetadata, exportStudyApi } from "../../redux/study/study-actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { WindowedTable } from "../../fathom-brella";
import { SoftTitle } from "../common/typography";
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography, MenuItem, Collapse } from "@material-ui/core";
import { KeyboardArrowDown as DownIcon, KeyboardArrowUp as UpIcon } from "@material-ui/icons";
import { networkKeys, StudyNetworkInputs } from "./StudyNetworkInputs";
import DialogWrapper from "../common/DialogWrapper";
import FlexRow from "../common/FlexRow";

const styles = theme => {
  return {
    form: {
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing(1),
    },
    formInput: {
      marginTop: theme.spacing(1),
      flexGrow: 1,
    },
    networkContainer: {
      display: "flex",
      flexDirection: "column",
    },
    network: {
      maxWidth: 160,
      minWidth: 160,
    },
    networkInputs: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: theme.spacing(1),
    },
    submissionTitle: { marginLeft: theme.spacing(1), marginTop: theme.spacing(2) },
  };
};

class StudyNetworkSubmitDialog extends Component {
  /* This component takes a given 'trigger' component and uses it to activate the Study Export
   * Dialog. This way this component can keep track of its own state, making it simple to move
   * around the application's UI. */

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      network: "otn",
      comment: "",
      errorNetwork: false,
      study: {},
      readyToSubmit: false,
      confirmOpen: false,
      fileName: "",
      previousOpen: false,
    };
  }

  onEnter() {
    this.setState({
      study: this.props.study,
      fileName: cleanFileName(`${this.props.study.name}-${formatDateTimeFilename()}.zip`),
    });
  }

  validate() {
    let valid = true;

    // network is required:
    if (this.state.network === "") {
      valid = false;
      this.setState({ errorNetwork: true });
    }

    return valid;
  }

  changeNetwork(e) {
    const network = e.target.value;
    this.setState({ network: network, errorNetwork: false });
  }

  changeComment(e) {
    this.setState({ comment: e.target.value });
  }

  studyNetworkMetadataChange(valid, networkMetadata) {
    if (valid) {
      this.props
        .dispatch(updateStudyMetadata({ studyId: this.props.study.id, networkMetadata }))
        .then(() => {
          this.setState({ readyToSubmit: true });
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      this.setState({ readyToSubmit: false });
    }
  }

  submitToNetwork() {
    this.props.dispatch(
      exportStudyApi(
        this.state.fileName,
        this.props.study.id,
        this.state.network,
        true, // submitToNetwork
        this.state.comment
      )
    );
    this.props.onClose();
  }

  download() {
    this.props.dispatch(
      exportStudyApi(
        this.state.fileName,
        this.props.study.id,
        this.state.network,
        false, // submitToNetwork
        this.state.comment
      )
    );
  }

  render() {
    const { classes, open, study } = this.props;
    const { readyToSubmit, previousOpen } = this.state;
    const submissions = study && study.networkMetadata && study.networkMetadata.submissions;

    return (
      <>
        <DialogWrapper
          open={open}
          title="Submit Study to Telemetry Network"
          onEnter={this.onEnter}
          onClose={this.props.onClose}
          maxWidth="md"
          cancelAction={this.props.onClose}
          okAction={() => this.setState({ confirmOpen: true })}
          buttons={({ cancelAction, okAction }) => (
            <>
              <Button onClick={cancelAction} variant="outlined">
                Cancel
              </Button>
              <Button onClick={() => this.download()} variant="outlined" color="primary">
                Download
              </Button>
              <Button onClick={okAction} color="primary" variant="contained" disabled={false}>
                Submit
              </Button>
            </>
          )}
        >
          <Typography paragraph>
            Your study data will be exported to a ZIP archive and submitted to your network
            directly. You may also choose to download the archive without submitting it. Either way,
            the archive will be saved to your Files as &quot;{this.state.fileName}&quot;.
          </Typography>

          <form autoComplete="off" noValidate className={classes.form}>
            <div className={classes.networkContainer}>
              <TextField
                select
                label="Telemetry Network"
                helperText={
                  this.state.errorNetwork
                    ? "Telemetry network is required"
                    : this.state.network
                    ? ""
                    : "Please choose a telemetry network"
                }
                defaultValue={"otn"}
                onChange={this.changeNetwork}
                error={this.state.errorNetwork}
                className={`${classes.formInput} ${classes.network}`}
                margin="dense"
              >
                {networkKeys.map(network => (
                  <MenuItem key={network} value={network}>
                    {network.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
              <div className={classes.networkInputs}>
                {!this.state.network ? null : (
                  <StudyNetworkInputs
                    study={this.props.study}
                    network={this.state.network}
                    onChange={(valid, data) => this.studyNetworkMetadataChange(valid, data)}
                  />
                )}
              </div>
            </div>
            <TextField
              multiline
              label="Comment"
              helperText={this.state.comment ? "" : "Please add some context information"}
              value={this.state.comment}
              onChange={this.changeComment}
              className={classes.formInput}
              margin="dense"
              fullWidth
            />
          </form>
          {submissions && submissions.length > 0 && (
            <div className={classes.submissionTitle}>
              <FlexRow
                vAlign="center"
                onClick={() => this.setState({ previousOpen: !previousOpen })}
              >
                <SoftTitle> Previous Submissions</SoftTitle>
                {!previousOpen ? <DownIcon /> : <UpIcon />}
              </FlexRow>
              <Collapse in={previousOpen}>
                <div style={{ height: 150, maxWidth: 500 }}>
                  <WindowedTable
                    rows={submissions}
                    columns={[
                      {
                        width: 80,
                        label: "Network",
                        dataKey: "network",
                        numeric: false,
                        flexShrink: 1,
                        flexGrow: 0,
                      },
                      {
                        width: 100,
                        label: "Time",
                        dataKey: "time",
                        numeric: false,
                        renderFn: time => formatDateTime(time),
                      },
                      {
                        width: 100,
                        label: "Result",
                        dataKey: "status",
                        numeric: false,
                        renderFn: status => toTitleCase(status),
                      },
                    ]}
                  />
                </div>
              </Collapse>
            </div>
          )}
        </DialogWrapper>

        <DialogWrapper
          title="Confirm Network Submission"
          open={this.state.confirmOpen}
          cancelAction={() => this.setState({ confirmOpen: false })}
          okDisabled={!readyToSubmit}
          okAction={() => {
            this.setState({ confirmOpen: false });
            this.submitToNetwork();
          }}
          okButtonContent="Yes, go ahead"
        >
          Are you sure you want to submit to {this.state.network.toUpperCase()}?
        </DialogWrapper>
      </>
    );
  }
}

StudyNetworkSubmitDialog.propTypes = {
  handleStudySubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  study: PropTypes.object,
};

export default compose(withStyles(styles), connect())(StudyNetworkSubmitDialog);
