import PropTypes from "prop-types";
import { Button, DialogContentText, Typography } from "@material-ui/core";
import DialogWrapper from "../common/DialogWrapper";
import CustomButton from "../common/CustomButton";

function StudyDeleteDialog({ open, studyName, okAction, cancelAction }) {
  return (
    <DialogWrapper
      open={open}
      title={`Delete ${studyName}`}
      okAction={okAction}
      cancelAction={cancelAction}
      buttons={({ okAction, cancelAction }) => (
        <>
          <Button onClick={cancelAction} variant="outlined">
            Cancel
          </Button>
          <CustomButton onClick={okAction} color="error" variant="contained">
            Delete Study
          </CustomButton>
        </>
      )}
    >
      <DialogContentText id="alert-dialog-description">
        {`Are you sure you want to delete ${studyName}?`}
      </DialogContentText>
      <Typography paragraph>
        This action will not delete your uploaded receiver logs, devices, animals and deployments.
      </Typography>
    </DialogWrapper>
  );
}

StudyDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  studyName: PropTypes.string,
  okAction: PropTypes.func,
  cancelAction: PropTypes.func,
};

export default StudyDeleteDialog;
