import {
  animalSizeUnits,
  animalMassUnits,
  animalOrigins,
  animalAgeUnits,
  animalSexes,
  taggingMethods,
  animalFields,
  taggingEventFields,
  deviceRecoveryEventFields,
  captureEventFields,
  releaseEventFields,
  measurementSetFields,
} from "./constants";

// convert from a object to an array of [{ value, label}] pairs
// e.g. {a: 1, b: 2} => [{ value: 'a', label: 1 }, { value: 'b', label: 1 }]
function valueLabelMap(options) {
  return Object.entries(options).map(([value, label]) => ({ value, label }));
}

const animalSizeMeasurementProps = {
  inputType: "measurement",
  valueWidth: 120,
  unitWidth: 55,
  unitOptions: valueLabelMap(animalSizeUnits),
  unitDefault: "cm",
};

export const subObjNames = {
  Animal: "animal",
  AnimalEventTagging: "taggingEvent",
  AnimalEventDeviceRecovery: "recoveryEvent",
  AnimalMeasurementSet: "measurementSet",
  AnimalEventCapture: "captureEvent",
  AnimalEventRelease: "releaseEvent",
};

export const subObjectConfig = (tags = []) => ({
  animal: animalCoreConfig,
  taggingEvent: {
    label: "Tagging Event",
    helperText: "Add an event to record tag(s) attached to this animal",
    isEvent: true,
    fields: [
      {
        dataKey: "devices",
        label: taggingEventFields.devices.label,
        helperText: taggingEventFields.devices.helperText,
        inputType: "autocomplete",
        autoCompleteOptions: tags,
        autoCompleteLabel: "label",
        autoCompleteGroupBy: option => option.inStatus,
        autoCompleteValueKey: "id",
        width: 300,
        isCarryOver: false,
        isDefaultSelected: true,
        autoFocus: true,
        isRequired: true,
      },
      {
        dataKey: "time",
        label: taggingEventFields.time.label,
        helperText: taggingEventFields.time.helperText,
        inputType: "datetime",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: true,
        isRequired: true,
      },
      {
        dataKey: "taggingMethod",
        label: taggingEventFields.taggingMethod.label,
        helperText: taggingEventFields.taggingMethod.helperText,
        inputType: "select",
        selectOptions: valueLabelMap(taggingMethods),
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: true,
      },
      {
        dataKey: "anatomicLocation",
        label: taggingEventFields.anatomicLocation.label,
        helperText: taggingEventFields.anatomicLocation.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "pitTagId",
        label: taggingEventFields.pitTagId.label,
        helperText: taggingEventFields.pitTagId.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: false,
        isDefaultSelected: false,
      },
      {
        dataKey: "floyTagId",
        label: taggingEventFields.floyTagId.label,
        helperText: taggingEventFields.floyTagId.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: false,
        isDefaultSelected: false,
      },
      {
        dataKey: "anaesthetic",
        label: taggingEventFields.anaesthetic.label,
        helperText: taggingEventFields.anaesthetic.helperText,
        inputType: "string",
        minWidth: 100,
        isCarryOver: true,
      },
      {
        dataKey: "sedative",
        label: taggingEventFields.sedative.label,
        helperText: taggingEventFields.sedative.helperText,
        inputType: "string",
        width: 100,
        isCarryOver: true,
      },
      {
        dataKey: "buffer",
        label: taggingEventFields.buffer.label,
        helperText: taggingEventFields.buffer.helperText,
        inputType: "string",
        width: 100,
        isCarryOver: true,
      },
      {
        dataKey: "notes",
        label: taggingEventFields.notes.label,
        helperText: taggingEventFields.notes.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: false,
        isDefaultSelected: true,
      },
      {
        dataKey: "researcherName",
        label: taggingEventFields.researcherName.label,
        helperText: taggingEventFields.researcherName.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "locationName",
        label: taggingEventFields.locationName.label,
        helperText: taggingEventFields.locationName.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "latitude",
        label: taggingEventFields.latitude.label,
        helperText: taggingEventFields.latitude.helperText,
        inputType: "number",
        width: 120,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "longitude",
        label: taggingEventFields.longitude.label,
        helperText: taggingEventFields.longitude.helperText,
        inputType: "number",
        width: 120,
        isCarryOver: true,
        isDefaultSelected: false,
      },
    ],
  },
  recoveryEvent: {
    label: "Tag Recovery Event",
    helperText: "Add an event to recover tag(s) attached to this animal",
    isEvent: true,
    fields: [
      {
        dataKey: "devices",
        label: deviceRecoveryEventFields.devices.label,
        helperText: deviceRecoveryEventFields.devices.helperText,
        inputType: "autocomplete",
        autoCompleteOptions: tags,
        autoCompleteLabel: "label",
        autoCompleteValueKey: "id",
        width: 300,
        isCarryOver: false,
        isDefaultSelected: true,
        autoFocus: true,
        isRequired: true,
      },
      {
        dataKey: "time",
        label: deviceRecoveryEventFields.time.label,
        helperText: deviceRecoveryEventFields.time.helperText,
        inputType: "datetime",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: true,
        isRequired: true,
      },
      {
        dataKey: "locationName",
        label: deviceRecoveryEventFields.locationName.label,
        helperText: deviceRecoveryEventFields.locationName.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: true,
      },
      {
        dataKey: "latitude",
        label: deviceRecoveryEventFields.latitude.label,
        helperText: deviceRecoveryEventFields.latitude.helperText,
        inputType: "number",
        width: 120,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "longitude",
        label: deviceRecoveryEventFields.longitude.label,
        helperText: deviceRecoveryEventFields.longitude.helperText,
        inputType: "number",
        width: 120,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "researcherName",
        label: deviceRecoveryEventFields.researcherName.label,
        helperText: deviceRecoveryEventFields.researcherName.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "notes",
        label: deviceRecoveryEventFields.notes.label,
        helperText: deviceRecoveryEventFields.notes.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: false,
        isDefaultSelected: false,
      },
    ],
  },
  measurementSet: {
    label: "Measurements",
    helperText: "Add an event to record measurements taken of this animal",
    fields: [
      {
        dataKey: "mass",
        label: measurementSetFields.mass.label,
        helperText: "",
        inputType: "measurement",
        valueWidth: 120,
        unitWidth: 45,
        unitOptions: valueLabelMap(animalMassUnits),
        unitDefault: "kg",
        isDefaultSelected: true,
      },
      {
        dataKey: "lifeStage",
        label: measurementSetFields.lifeStage.label,
        helperText: measurementSetFields.lifeStage.label,
        inputType: "text",
        minWidth: 80,
        maxWidth: 150,
        isCarryOver: false,
        isDefaultSelected: false,
      },
      {
        dataKey: "age",
        label: measurementSetFields.age.label,
        helperText: "",
        inputType: "measurement",
        valueWidth: 120,
        unitWidth: 80,
        unitOptions: valueLabelMap(animalAgeUnits),
        unitDefault: "WEEKS",
        isDefaultSelected: false,
      },
      {
        dataKey: "sex",
        label: measurementSetFields.sex.label,
        inputType: "select",
        selectOptions: valueLabelMap(animalSexes),
        width: 100,
        isDefaultSelected: false,
      },
      {
        dataKey: "totalLength",
        label: measurementSetFields.totalLength.label,
        helperText: measurementSetFields.totalLength.helperText,
        ...animalSizeMeasurementProps,

        isDefaultSelected: true,
      },
      {
        dataKey: "forkLength",
        label: measurementSetFields.forkLength.label,
        helperText: measurementSetFields.forkLength.helperText,
        ...animalSizeMeasurementProps,
        isDefaultSelected: false,
      },
      {
        dataKey: "standardLength",
        label: measurementSetFields.standardLength.label,
        helperText: measurementSetFields.standardLength.helperText,
        ...animalSizeMeasurementProps,
        isDefaultSelected: false,
      },
      {
        dataKey: "hoodLength",
        label: measurementSetFields.hoodLength.label,
        helperText: measurementSetFields.hoodLength.helperText,
        ...animalSizeMeasurementProps,
        isDefaultSelected: false,
      },
      {
        dataKey: "width",
        label: measurementSetFields.width.label,
        helperText: measurementSetFields.width.helperText,
        ...animalSizeMeasurementProps,
        isDefaultSelected: false,
      },
      {
        dataKey: "girth",
        label: measurementSetFields.girth.label,
        helperText: measurementSetFields.girth.helperText,
        ...animalSizeMeasurementProps,
        isDefaultSelected: false,
      },
      {
        dataKey: "measurerName",
        label: measurementSetFields.measurerName.label,
        helperText: measurementSetFields.measurerName.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "time",
        label: measurementSetFields.time.label,
        helperText: measurementSetFields.time.helperText,
        inputType: "datetime",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: true,
        isRequired: true,
      },
    ],
  },
  captureEvent: {
    label: "Capture Event",
    helperText: "Add an event to record capture details",
    isEvent: true,
    fields: [
      {
        dataKey: "captureMethod",
        label: captureEventFields.captureMethod.label,
        helperText: captureEventFields.captureMethod.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: true,
      },
      {
        dataKey: "time",
        label: captureEventFields.time.label,
        helperText: captureEventFields.time.helperText,
        inputType: "datetime",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: true,
        isRequired: true,
      },
      {
        dataKey: "locationName",
        label: captureEventFields.locationName.label,
        helperText: captureEventFields.locationName.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: true,
      },
      {
        dataKey: "latitude",
        label: captureEventFields.latitude.label,
        helperText: captureEventFields.latitude.helperText,
        inputType: "number",
        width: 120,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "longitude",
        label: captureEventFields.longitude.label,
        helperText: captureEventFields.longitude.helperText,
        inputType: "number",
        width: 120,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "researcherName",
        label: captureEventFields.researcherName.label,
        helperText: captureEventFields.researcherName.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "notes",
        label: captureEventFields.notes.label,
        helperText: captureEventFields.notes.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: false,
        isDefaultSelected: false,
      },
    ],
  },
  releaseEvent: {
    label: "Release Event",
    helperText: "",
    isEvent: true,
    fields: [
      {
        dataKey: "time",
        label: releaseEventFields.time.label,
        helperText: releaseEventFields.time.helperText,
        inputType: "datetime",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: true,
        isRequired: true,
      },
      {
        dataKey: "locationName",
        label: releaseEventFields.locationName.label,
        helperText: releaseEventFields.locationName.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: true,
      },
      {
        dataKey: "latitude",
        label: releaseEventFields.latitude.label,
        helperText: releaseEventFields.latitude.helperText,
        inputType: "number",
        width: 120,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "longitude",
        label: releaseEventFields.longitude.label,
        helperText: releaseEventFields.longitude.helperText,
        inputType: "number",
        width: 120,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "researcherName",
        label: releaseEventFields.researcherName.label,
        helperText: releaseEventFields.researcherName.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: true,
        isDefaultSelected: false,
      },
      {
        dataKey: "notes",
        label: releaseEventFields.notes.label,
        helperText: releaseEventFields.notes.helperText,
        inputType: "text",
        minWidth: 150,
        isCarryOver: false,
        isDefaultSelected: false,
      },
    ],
  },
});

const animalCoreConfig = {
  label: "Animal",
  helperText: "The static attributes of the animal",
  isRequired: true,
  fields: [
    {
      label: animalFields.name.label,
      dataKey: "name",
      inputType: "text",
      minWidth: 150,
      helperText: animalFields.name.helperText,
      isCarryOver: false,
      isDefaultSelected: true,
      isRequired: false,
    },
    {
      label: animalFields.notes.label,
      dataKey: "notes",
      inputType: "text",
      helperText: animalFields.notes.helperText,
      minWidth: 150,
      isDefaultSelected: false,
    },
    {
      label: animalFields.speciesCommonName.label,
      dataKey: "speciesCommonName",
      inputType: "text",
      minWidth: 150,
      helperText: animalFields.speciesCommonName.helperText,
      isCarryOver: true,
      isDefaultSelected: true,
    },
    {
      label: animalFields.speciesScientificName.label,
      dataKey: "speciesScientificName",
      inputType: "text",
      minWidth: 150,
      helperText: animalFields.speciesScientificName.helperText,
      isCarryOver: true,
      isDefaultSelected: true,
    },
    {
      label: animalFields.origin.label,
      dataKey: "origin",
      inputType: "select",
      selectOptions: valueLabelMap(animalOrigins),
      minWidth: 150,
      helperText: animalFields.origin.helperText,
      isCarryOver: true,
      isDefaultSelected: true,
    },
    {
      label: animalFields.stock.label,
      dataKey: "stock",
      inputType: "text",
      minWidth: 150,
      helperText: animalFields.stock.helperText,
      isCarryOver: true,
    },
  ],
};

/* configuration for the full add animal form, which combines most of the subobject configs,
 * with some modifications */
export const addAnimalConfig = (tags = []) => {
  const subObjects = subObjectConfig(tags);
  delete subObjects.recoveryEvent;

  const timeField = subObjects.measurementSet.fields.find(field => field.dataKey === "time");
  timeField.isRequired = false;
  timeField.helperText = "Tagging time will be used by default";

  return { animal: animalCoreConfig, ...subObjects };
};
