import {
  Typography,
  Input,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { ChangeEventHandler, ReactElement, useState } from "react";
import { useSelectorTyped as useSelector } from "../../../redux/common";
import DialogWrapper from "../DialogWrapper";
import FlexRow from "../FlexRow";
import { SpecFormat } from "./types";

type SpecFormatDef = { value: SpecFormat; label: string; adminOnly: boolean };
const deploymentFormats: SpecFormatDef[] = [
  { value: "deployment-simple", label: "Fathom Deployment XLSX", adminOnly: false },
  { value: "deployment-otn", label: "OTN Instrument Deployment XLSX", adminOnly: false },
  { value: "deployment-standard", label: "Fathom Deployment XLSX (Internal)", adminOnly: true },
];
const animalFormats: SpecFormatDef[] = [
  { value: "animal-standard", label: "Fathom Animal XLSX", adminOnly: false },
  { value: "animal-otn", label: "OTN Tagging Metadata XLSX", adminOnly: false },
];

type Props = {
  open: boolean;
  dataType: "animal" | "deployment";
  format: SpecFormat | null;
  setFormat: (format: SpecFormat) => void;
  onCancel: () => void;
  onSubmit: (file: File) => void;
  processing: boolean;
};

function SpecUploadDialog({
  open,
  dataType,
  format,
  setFormat,
  onCancel,
  onSubmit,
  processing,
}: Props) {
  const [error, setError] = useState<string>("");
  const [filesToUpload, setFilesToUpload] = useState<FileList | null>(null);

  const isAdmin = useSelector(({ user }) => user.isAdmin);

  const onFileChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { files } = event.target;
    setFilesToUpload(files);
    if (files && files.length > 0) {
      if (files[0].name.endsWith(".xlsx")) {
        setError("");
      } else {
        setError("File must be an .xlsx");
      }
    }
  };

  const close = () => {
    setError("");
    setFilesToUpload(null);
    onCancel();
  };

  const okAction = () => {
    const file = filesToUpload?.[0];
    if (file) {
      onSubmit(file);
    }
    setFilesToUpload(null);
  };

  const title = `Upload ${dataType === "deployment" ? "Deployment" : "Animal"} Data from File`;

  let content: string | ReactElement<any> = format || "None";
  let formatOptions = dataType === "deployment" ? deploymentFormats : animalFormats;
  if (!isAdmin) formatOptions = formatOptions.filter(opt => !opt.adminOnly);

  switch (format) {
    case "deployment-standard":
      content = (
        <>
          This format is currently for <strong>internal use only</strong>. It is hidden from regular
          users. To download a sample file with field descriptions{" "}
          <ExampleDownloadLink filename="deployment-internal-spec-example.xlsx">
            click here
          </ExampleDownloadLink>
          .
        </>
      );
      break;

    case "deployment-otn":
      content = (
        <>
          You can upload data in the Ocean Tracking Network (OTN) Instrument Deployment XSLX format.
          For more information about this format, or to download a sample file{" "}
          <Link href="https://members.oceantrack.org/data/data-collection">click here</Link>.
        </>
      );
      break;

    case "deployment-simple":
      content = (
        <>
          This format was developed by the Fathom team and fits the data model used in the Fathom
          Suite. To download a sample file with field descriptions{" "}
          <ExampleDownloadLink filename="Fathom Deployment Data Sheet.xlsx">
            click here
          </ExampleDownloadLink>
          .
        </>
      );
      break;

    case "animal-otn":
      content = (
        <>
          You can upload data in the Ocean Tracking Network (OTN) Tagging Metadata XSLX format. For
          more information about this format, or to download a sample file{" "}
          <Link href="https://members.oceantrack.org/data/data-collection" underline="always">
            click here
          </Link>
          .
        </>
      );
      break;

    case "animal-standard":
      content = (
        <>
          This format was developed by the Fathom team and fits the data model used in the Fathom
          Suite. To download a sample file with field descriptions{" "}
          <ExampleDownloadLink filename="Fathom Animal Data Sheet V2.xlsx">
            click here
          </ExampleDownloadLink>
          .
        </>
      );
      break;
    default:
      content = "Choose a file to upload";
      break;
  }

  return (
    <DialogWrapper
      open={open}
      title={title}
      okAction={okAction}
      cancelAction={close}
      onClose={close}
      maxWidth="md"
      buttons={({ cancelAction, okAction }) =>
        processing ? (
          <Button
            onClick={cancelAction}
            variant="outlined"
            title="Close this dialog box (processing will continue on the server)"
          >
            Close
          </Button>
        ) : (
          <Button
            onClick={okAction}
            color="primary"
            variant="contained"
            disabled={Boolean(!filesToUpload || filesToUpload.length < 1 || error)}
          >
            Upload
          </Button>
        )
      }
    >
      {processing ? (
        <FlexRow vAlign="center" itemSpacing={1}>
          <Typography variant="subtitle1">Processing uploaded file ... </Typography>
          <CircularProgress size={20} />
        </FlexRow>
      ) : (
        <>
          <Typography>
            Uploading {dataType} data from a file allows you to easily upload a large amount of
            data. Multiple file formats are accepted. Please select a format to get started:
          </Typography>

          <RadioGroup
            value={format}
            onChange={event => setFormat(event.target.value as SpecFormat)}
          >
            {formatOptions.map(fmt => (
              <FormControlLabel
                key={fmt.value}
                value={fmt.value}
                control={<Radio />}
                label={fmt.label}
              />
            ))}
          </RadioGroup>

          {format && (
            <>
              <Divider style={{ marginTop: 5, marginBottom: 10 }} />
              <Typography paragraph>{content}</Typography>

              <Input
                type="file"
                inputProps={{ accept: ".xlsx" }}
                onChange={onFileChange}
                error={Boolean(error)}
                disableUnderline
              />
            </>
          )}
        </>
      )}
      {error && <Typography color="error">{error}</Typography>}
    </DialogWrapper>
  );
}

function ExampleDownloadLink(props: { filename: string; children: any }) {
  return (
    <Link href={process.env.PUBLIC_URL + "/data/" + props.filename} download underline="always">
      {props.children}
    </Link>
  );
}

export default SpecUploadDialog;
