import { useEffect, useRef } from "react";

/**
 * Custom hook for replicating "previous props" of componentDidUpdate for functional components.
 *
 * @param {any} value - The value to be retained.
 * @returns {any} The value that was passed on the previous call.
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
