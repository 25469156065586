import { Component } from "react";
import autoBind from "auto-bind/react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FormDialog from "../common/FormDialog";
import GraphObjectInput from "./GraphObjectInput";
import { Typography } from "@material-ui/core";
import { validateAnimalSubObj } from "./utils";
import { initialFormState, removeEmptyFields, typeCastValues } from "../../helpers/common";
import { set, isEmpty, cloneDeep, pick } from "lodash";
import { AddCircleOutline as IconAdd, RemoveCircleOutline as IconRemove } from "@material-ui/icons";

const styles = theme => ({
  sectionContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:first-of-type": {
      marginTop: 0,
    },
  },
  sectionAddBtn: {
    marginLeft: theme.spacing(2),
    cursor: "pointer",
    alignSelf: "center",
  },
});

// this constant determines the order & presence of animal subobject sections in the form
const SECTIONS = ["taggingEvent", "measurementSet", "animal", "captureEvent", "releaseEvent"];

class AnimalAddDialog extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = this.initialState();

    /* Create handlers for each section. This is an optimization: if these functions are re-created
     * on each render it disrupts the memoization in GraphObjectInput, affecting performance. */
    this.inputHandlers = {};
    this.selectedFieldsHandlers = {};
    SECTIONS.forEach(section => {
      this.inputHandlers[section] = (field, value) => this.handleValue(section, field, value);
      this.selectedFieldsHandlers[section] = fields => this.handleSelectFields(section, fields);
    });
  }

  initialState() {
    const state = { openSections: ["taggingEvent", "measurementSet", "animal"] };
    SECTIONS.forEach(section => {
      state[section] = initialFormState(this.props.formConfig[section].fields);
    });
    return state;
  }

  handleSectionOpen(section) {
    const openSections = [section, ...this.state.openSections];
    this.setState({ openSections });
  }

  handleSectionClose(section) {
    // Don't erase the values in case user clicked by mistake. Ignore them on save.
    const openSections = this.state.openSections.filter(s => s !== section);
    this.setState({ openSections });
  }

  handleValue(section, field, value) {
    const values = { ...this.state[section].values, [field]: value };
    this.setState({ [section]: { ...this.state[section], values } });
  }

  handleSelectFields(section, selectedFields) {
    this.setState({ [section]: { ...this.state[section], selectedFields } });
  }

  displayedValues(section) {
    const { values, selectedFields } = this.state[section];
    return pick(values, selectedFields);
  }

  handleSubmit(reset = true) {
    const sectionsToSubmit = this.getSectionsToSubmit();
    const valid = this.validate(sectionsToSubmit);

    if (valid) {
      const formInput = {};
      sectionsToSubmit.forEach(section => {
        const { fields } = this.props.formConfig[section];
        const cleanedValues = removeEmptyFields(this.displayedValues(section), fields);
        const castValues = typeCastValues(cleanedValues, fields);
        formInput[section] = castValues;
      });

      this.props.handleAdd(formInput);

      if (reset) {
        this.setState(this.initialState());
      }
    }

    return valid;
  }

  handleAnother() {
    const submitted = this.handleSubmit(false); // submit but don't reset form

    if (!submitted) {
      return; // submission wasn't valid: abort
    }

    // clear all fields except measurement units & those marked as isCarryOver
    const newState = cloneDeep(this.state);
    SECTIONS.forEach(section => {
      this.props.formConfig[section].fields.forEach(field => {
        if (field.inputType === "measurement") {
          newState[section].values[field.dataKey].value = ""; // clear value but leave unit
        } else if (!field.isCarryOver) {
          newState[section].values[field.dataKey] = field.defaultValue || "";
        }
        newState[section].fieldErrors = {};
      });
    });

    this.setState(newState);
    this.props.handleAnother();
  }

  validate(sections) {
    const fieldErrors = {};
    const formErrors = {};

    // validate each requested section
    sections.forEach(section => {
      const graph = this.props.formConfig[section];
      const errors = validateAnimalSubObj(this.displayedValues(section), graph, section, "add");
      fieldErrors[section] = errors.fieldErrors;
      formErrors[section] = errors.formErrors;
    });

    // validate state as a whole:
    const { animal, taggingEvent, measurementSet } = this.state;

    // check either animal.name or taggingEvent.devices are populated
    if (!animal.values.name && taggingEvent.values.devices.length === 0) {
      const message = "Either add a name or attach a tag to add animal";
      set(fieldErrors, "animal.name", message);
      set(fieldErrors, "taggingEvent.devices", message);
    }
    // if adding measurements, check either measurementSet.time or taggingEvent.time are populated
    if (
      sections.includes("measurementSet") &&
      !measurementSet.values.time &&
      !taggingEvent.values.time
    ) {
      const message = "Fill in measurement time or tagging time to add measurements";
      set(fieldErrors, "measurementSet.time", message);
      set(fieldErrors, "taggingEvent.time", message);
    }

    // Update the state of ALL sections to reflect the errors
    // (This clears the errors on sections not marked for validation)
    SECTIONS.forEach(section => {
      this.setState({
        [section]: {
          ...this.state[section],
          fieldErrors: fieldErrors[section] || {},
          formErrors: formErrors[section] || [],
        },
      });
    });

    return Object.values(fieldErrors).every(isEmpty) && Object.values(formErrors).every(isEmpty);
  }

  // Get the names of sections that are open & have something entered
  getSectionsToSubmit() {
    const openSections = SECTIONS.filter(section => this.state.openSections.includes(section));

    return openSections.filter(section => {
      const openFields = this.props.formConfig[section].fields.filter(field =>
        this.state[section].selectedFields.includes(field.dataKey)
      );
      for (const field of openFields) {
        const fieldValue = this.state[section].values[field.dataKey];

        if (field.inputType === "measurement") {
          if (fieldValue.value !== "") {
            return true;
          }
        } else if (fieldValue !== null && fieldValue !== undefined && fieldValue !== "") {
          return true;
        }
      }
      return false;
    });
  }

  handleCancel() {
    this.setState(this.initialState());
    this.props.handleClose();
  }

  render() {
    const { open, formConfig, classes } = this.props;
    const { openSections } = this.state;

    return (
      <FormDialog
        onKeyDown={event => {
          if (event.key === "Escape") {
            this.handleCancel();
          }
          if (event.key === "Enter") {
            this.handleSubmit();
          }
        }}
        open={open}
        mode={"add"}
        title={"Add Animal"}
        handleSubmit={this.handleSubmit}
        handleClose={this.handleCancel}
        handleAnother={this.handleAnother}
      >
        {SECTIONS.map(section => {
          const config = formConfig[section];
          const isSectionOpen = openSections.includes(section);
          const addRemoveIcon = isSectionOpen ? (
            <IconRemove
              className={classes.sectionAddBtn}
              onClick={() => this.handleSectionClose(section)}
            />
          ) : (
            <IconAdd
              className={classes.sectionAddBtn}
              onClick={() => this.handleSectionOpen(section)}
            />
          );

          const { values, fieldErrors, formErrors } = this.state[section];

          return (
            <div key={section} className={classes.sectionContainer}>
              <div style={{ display: "flex" }}>
                <Typography variant="subtitle1" title={config.helperText}>
                  {config.label}
                </Typography>

                {!config.isRequired && addRemoveIcon}
              </div>

              <div className={classes.fieldsContainer}>
                {isSectionOpen && (
                  <GraphObjectInput
                    graphFields={config.fields}
                    fieldValues={values}
                    fieldErrors={fieldErrors}
                    formErrors={formErrors}
                    selectedFields={this.state[section].selectedFields}
                    handleInput={this.inputHandlers[section]}
                    handleSelectFields={this.selectedFieldsHandlers[section]}
                  />
                )}
              </div>
            </div>
          );
        })}
      </FormDialog>
    );
  }
}

AnimalAddDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAnother: PropTypes.func.isRequired,
  formConfig: PropTypes.object.isRequired,
};

export default withStyles(styles)(AnimalAddDialog);
