import { useEffect, useRef, useState } from "react";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SignalListeners, VisualizationSpec, Vega, View } from "react-vega";
import { Actions } from "vega-embed";
import { ColorScheme as VegaColorScheme } from "vega";
import { getBinInfo } from "../../containers/Detections";
import FlexCol from "../common/FlexCol";
import FlexRow from "../common/FlexRow";

import { DetectionCountAbacus } from "../../redux/detections/detection-types";
import { AbacusGroupMode, DevicesInScope, SelectionInterval, TimeRangeTs } from "./detection-types";
import {
  detectionsVegaConfig,
  LABEL_OFFSET,
  X_AXIS_EXTENT,
  Y_AXIS_EXTENT,
  Y_AXIS_TITLE_SPACE,
  Y_STEP,
} from "./detection-consts";
import { NavDivider } from "./DetectionVisualizer";
import { Duration } from "./detection-helper";
import { NominalDomain } from "./detection-types";

import { DomainScrollbar } from "./DomainScrollbar";
import ResizeHandler from "../common/ResizeHandler";
import { SpinnerInnovasea } from "../../fathom-brella";
import { Transmitter } from "../../helpers/glossary";

type ColorScale = "linear" | "log";

type ColorScheme = {
  vegaName: VegaColorScheme;
  displayName: string;
};
const defaultColorScheme: ColorScheme = { vegaName: "yellowgreenblue", displayName: "Default" };
const colorSchemes: ColorScheme[] = [
  defaultColorScheme,
  { vegaName: "turbo", displayName: "Turbo" },
];

const useStyles = makeStyles(theme => ({
  toolBarGroup: {
    flexWrap: "wrap",
  },
  toolBarCaption: {
    paddingRight: theme.spacing(1),
    whiteSpace: "nowrap",
  },
}));

function DetectionAbacus({
  detectionCounts,
  actions,
  loading,
  setZoomWindow,
  selectionInterval,
  filterInterval,
  dataInterval,
  devicesInScope,
  abacusGroupBy,
  setAbacusGroupBy,
}: {
  detectionCounts: DetectionCountAbacus[];
  actions?: Actions;
  loading: boolean;
  setZoomWindow: (interval: SelectionInterval) => void;
  selectionInterval: SelectionInterval;
  filterInterval: TimeRangeTs;
  dataInterval: TimeRangeTs;
  devicesInScope: DevicesInScope;
  abacusGroupBy: AbacusGroupMode;
  setAbacusGroupBy: (groupBy: AbacusGroupMode) => void;
}) {
  const classes = useStyles();

  const [zoom, setZoom] = useState<SelectionInterval>([]); // the interval as time
  const [colorScale, setColorScale] = useState<ColorScale>("log"); // the horizontal start of the interval as pixel offset from chart container
  const [colorScheme, setColorScheme] = useState<VegaColorScheme>(defaultColorScheme.vegaName);
  const [chartOuter, setChartOuter] = useState({ width: 0, height: 0 });
  const [view, setView] = useState(null as View | null);
  const [visibleYDomain, setVisibleYDomain] = useState<NominalDomain>([]);
  const [fullDomain, setFullDomain] = useState<NominalDomain>([]);
  const scrollRef = useRef<HTMLDivElement>(null);

  // interval changed, resetting zoom:
  useEffect(() => {
    setZoom([]);
  }, [selectionInterval]);

  // Changes to y domain:
  // reset domain scrolling effect when changing the y-axis field:
  useEffect(() => {
    const newFullDomain = (
      abacusGroupBy == "TX" ? devicesInScope.transmitterIDs : devicesInScope.receiverSerials
    ) as NominalDomain;
    setFullDomain(newFullDomain);
  }, [abacusGroupBy, devicesInScope]);

  // listen for changes to visibleYDomain and update chart with
  useEffect(() => {
    if (view && visibleYDomain) {
      view.signal("visibleYDomain", visibleYDomain);
      view.runAsync();
    }
  }, [view, visibleYDomain]);

  // listen for changes to data to determine min/max
  useEffect(() => {
    if (view && detectionCounts?.length) {
      let min;
      let max;

      detectionCounts.forEach(({ count }) => {
        if (min === undefined) {
          min = count;
          max = count;
        } else {
          min = count < min ? count : min;
          max = count > max ? count : max;
        }
      });
      view.signal("countMaxMin", [min, max]);
      view.runAsync();
    }
  }, [view, detectionCounts]);

  const xScale = { domain: [] as number[], nice: false };

  /** timestamp of bin to mark end of OVERVIEW data */
  let dtDataInvervalMaxBin = 0;
  /** timestamp of bin to mark beginning of OVERVIEW data */
  let dtDataInvervalMinBin = 0;

  /** timestamp to mark end of SELECTION */
  let dtSelectionIntervalMax = 0;
  /** timestamp to mark beginning of SELECTION */
  let dtSelectionIntervalMin = 0;

  let binWidth = new Duration("hour", 1);

  // min / max of SELECTION INTERVAL
  dtSelectionIntervalMin = selectionInterval?.[0] || 0;
  dtSelectionIntervalMax = selectionInterval?.[1] || 0;

  // temporal domain is min / max of data unless selection lies outside:
  xScale.domain = [
    dtSelectionIntervalMin || filterInterval.min || dataInterval.min,
    dtSelectionIntervalMax || filterInterval.max || dataInterval.max,
  ];
  // if the domain is being determined by the filter, but the first data time is _before_ the
  // filter min (because it's set to the start of the bin that the filter min is in), then use
  // the first data time. This ensures the first data points are rendered:
  if (xScale.domain[0] == filterInterval.min && dataInterval.min < filterInterval.min) {
    xScale.domain[0] = dataInterval.min;
  }
  binWidth = getBinInfo((xScale.domain[1] - xScale.domain[0]) / 1000);

  // min / max of OVERVIEW data for EOF markers (timestamp of the last data bin +/- bin width):
  const binWidthMS = binWidth.getValueSeconds() * 1000;
  dtDataInvervalMaxBin = dataInterval.max + binWidthMS;
  dtDataInvervalMinBin = dataInterval.min - binWidthMS;

  const fitY = Y_STEP * visibleYDomain.length;

  const plotWidth =
    chartOuter.width -
    (Y_AXIS_EXTENT + Y_AXIS_TITLE_SPACE + detectionsVegaConfig.chartPadding.right);

  const spec: VisualizationSpec = {
    usermeta: { embedOptions: { renderer: "svg" } },
    padding: { ...detectionsVegaConfig.chartPadding, right: 0 }, // override right padding since it's applied in plotWitdh
    params: [
      {
        name: "visibleYDomain",
        value: [""],
      },
      {
        name: "countMaxMin",
        value: [],
      },
    ],
    hconcat: [
      {
        height: fitY,
        width: plotWidth,
        layer: [
          {
            transform: [
              {
                filter: `datum.dt >= ${xScale.domain?.[0] || 0} && datum.dt <= ${
                  xScale.domain?.[1] || 0
                }`,
              },
              {
                filter: `visibleYDomain && indexof(visibleYDomain, datum.${abacusGroupBy.toLowerCase()}) != -1`,
              },
            ],
            data: { name: "byGroup" },
            params: [
              {
                name: "brush",
                select: {
                  type: "interval",
                  encodings: ["x"],
                  mark: {
                    stroke: "black",
                    strokeWidth: 2,
                  },
                },
              },
            ],
            mark: "rect",
            encoding: {
              x: {
                field: "dt",
                type: "temporal",
                timeUnit: {
                  binned: true,
                  utc: true,
                  unit: binWidth.getVegaTimeUnit(),
                  step: binWidth.value,
                },
                axis: {
                  title: "",
                  bandPosition: 0,
                  ...detectionsVegaConfig.font,
                  ...detectionsVegaConfig.xAxis,
                },
                scale: xScale,
              },
              color: {
                field: "count",
                type: "quantitative",
                scale: {
                  type: colorScale === "log" ? "log" : "linear",
                  domain: { expr: "countMaxMin" },
                  scheme: colorScheme,
                },
                legend: {
                  ...detectionsVegaConfig.font,
                  title: "Detections",
                  orient: "none",
                  direction: "vertical",
                  gradientLength: 200,
                  legendX: plotWidth + 10,
                },
              },
              y: {
                field: abacusGroupBy.toLowerCase(),
                type: "nominal",
                axis: {
                  title: abacusGroupBy === "TX" ? Transmitter.title : "Receiver",
                  ...detectionsVegaConfig.font,
                  ...detectionsVegaConfig.yAxis,
                  labelAlign: "left",
                  labelPadding: LABEL_OFFSET[abacusGroupBy],
                },
                scale: { domain: { expr: "visibleYDomain" } },
              },
              tooltip: {
                value: {
                  signal: `{
                  'From': utcFormat(datum.dt, '%b %d, %Y %H:%M'),
                  'Duration': '${binWidth.getDisplay()}',
                  'Device': ${abacusGroupBy === "TX" ? "datum.tx" : "datum.rx"},
                  'Count': datum.count
                }`,
                },
              },
            },
          },
          {
            transform: [
              {
                filter:
                  "datum.dtSelectionIntervalMax > 0 && datum.dtSelectionIntervalMax >= datum.dtDataInvervalMaxBin",
              },
            ],
            data: {
              values: [{ dtDataInvervalMaxBin, dtSelectionIntervalMax }],
            },
            mark: { type: "rule", size: 4, color: "red", clip: true },
            encoding: {
              x: {
                type: "temporal",
                field: "dtDataInvervalMaxBin",
                scale: xScale,
              },
            },
          },
          {
            transform: [
              {
                filter:
                  "datum.dtSelectionIntervalMin > 0 && datum.dtSelectionIntervalMin <= datum.dtDataInvervalMinBin",
              },
            ],
            data: {
              values: [{ dtDataInvervalMinBin, dtSelectionIntervalMin }],
            },
            mark: { type: "rule", size: 4, color: "red", clip: true },
            encoding: {
              x: {
                type: "temporal",
                field: "dtDataInvervalMinBin",
                scale: xScale,
              },
            },
          },
        ],
      },
      {
        width: 1,
        height: 200 + X_AXIS_EXTENT, // this is just to force the chart outer container to leave space for the legend
        mark: "rect",
      },
    ],
  };

  const signalListeners: SignalListeners = {
    brush: (_, { dt }) => setZoom(dt as SelectionInterval),
  };

  const showZoomButton = zoom?.constructor === Array && zoom.length === 2;

  return (
    <FlexCol style={{ flexGrow: 1 }}>
      <FlexRow itemSpacing={1} paddingLevel={0.5} vAlign="center">
        <FlexRow vAlign="center" className={classes.toolBarGroup}>
          <Typography variant="caption" className={classes.toolBarCaption}>
            Group By
          </Typography>
          <RadioGroup
            row
            value={abacusGroupBy}
            onChange={e => setAbacusGroupBy(e.target.value as AbacusGroupMode)}
          >
            <FormControlLabel value="RX" control={<Radio size="small" />} label="Receivers" />
            <FormControlLabel
              value="TX"
              control={<Radio size="small" />}
              label={Transmitter.title}
            />
          </RadioGroup>
        </FlexRow>
        <NavDivider />
        <FlexRow vAlign="center" className={classes.toolBarGroup}>
          <Typography variant="caption" className={classes.toolBarCaption}>
            Color Scale
          </Typography>
          <Tooltip title="Set the detection density color indicator scale to linear or logarithmic">
            <RadioGroup
              row
              value={colorScale}
              onChange={e => setColorScale(e.target.value as ColorScale)}
            >
              <FormControlLabel value="log" control={<Radio size="small" />} label="Log" />
              <FormControlLabel value="linear" control={<Radio size="small" />} label="Linear" />
            </RadioGroup>
          </Tooltip>
        </FlexRow>
        <NavDivider />
        <FlexRow vAlign="center" className={classes.toolBarGroup}>
          <Typography variant="caption" className={classes.toolBarCaption}>
            Color Scheme
          </Typography>
          <Tooltip title="Set the detection density color scheme">
            <RadioGroup
              row
              value={colorScheme}
              onChange={e => setColorScheme(e.target.value as VegaColorScheme)}
            >
              {colorSchemes.map(scheme => {
                return (
                  <FormControlLabel
                    key={scheme.vegaName}
                    value={scheme.vegaName}
                    control={<Radio size="small" />}
                    label={scheme.displayName}
                  />
                );
              })}
            </RadioGroup>
          </Tooltip>
        </FlexRow>
        {showZoomButton && (
          <>
            <NavDivider />
            <Button
              variant="outlined"
              onClick={() => {
                setZoomWindow([...zoom]);
                setZoom([]);
              }}
            >
              zoom to selection
            </Button>
          </>
        )}
      </FlexRow>
      <FlexRow style={{ flexGrow: 1 }} ref={scrollRef}>
        <div style={{ position: "relative", width: "100%", height: "100%", overflow: "hidden" }}>
          <ResizeHandler onResize={(width, height) => setChartOuter({ width, height })}>
            {chartOuter.height && chartOuter.width && (
              <Vega
                spec={spec}
                data={{ byGroup: detectionCounts }}
                signalListeners={signalListeners}
                onNewView={setView}
                actions={actions}
              />
            )}
          </ResizeHandler>
          {loading && <SpinnerInnovasea />}
        </div>

        <DomainScrollbar
          domain={fullDomain}
          stepHeight={Y_STEP}
          setViewDomain={setVisibleYDomain}
          scrollRef={scrollRef}
        />
      </FlexRow>
    </FlexCol>
  );
}

export default DetectionAbacus;
