import Switch from "@material-ui/core/Switch";
import { Tooltip, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

type Props = {
  text: string;
  checked: boolean;
  tooltip: {
    text: string;
    placement:
      | "bottom-end"
      | "bottom-start"
      | "bottom"
      | "left-end"
      | "left-start"
      | "left"
      | "right-end"
      | "right-start"
      | "right"
      | "top-end"
      | "top-start"
      | "top";
  };
  onChange: () => void;
  listItemIconStyle?: string;
  listItemTextStyle?: string;
} & any;

export default function ListItemSwitch({
  text,
  checked,
  tooltip,
  onChange,
  listItemIconStyle = "",
  listItemTextStyle = "",
  ...switchProps
}: Props) {
  return (
    <ListItem>
      <Tooltip title={tooltip.text} placement={tooltip.placement}>
        <ListItemIcon className={listItemIconStyle}>
          <Switch checked={checked} onChange={onChange} {...switchProps} />
        </ListItemIcon>
      </Tooltip>
      <ListItemText classes={{ primary: `${listItemTextStyle}` }} primary={text} />
    </ListItem>
  );
}
