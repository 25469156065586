import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  delaysTable: {
    fontSize: "0.8rem",
    borderCollapse: "collapse",
    marginBottom: theme.spacing(1),
    "& tr": {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    "& th": {
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
      padding: theme.spacing(0.5),
      // border: "1px solid red",
    },
    "& td": {
      textAlign: "center",
      // border: "1px solid red",
      padding: theme.spacing(0.5),
    },

    "& .duration": {
      textAlign: "right",
      whiteSpace: "nowrap",
    },
  },
  fixedDelay: { width: 60 },
  codespace: { width: 55 },
  minMax: { width: 40 },
}));

function TransmitterDelaySequence({ delays }) {
  const classes = useStyles();
  const titles = {
    duration: "(days hr:min:sec)",
    power: "(Low / High)",
    minMax: "(Seconds)",
    fixedDelay: "Fixed Delay",
    codespacePPM: "PPM Code Space",
    codespaceHR: "HR Code Space",
  };

  const fixedDelay = delays.isNormal ? "" : "Fixed Delay";

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">Custom Transmitter Programming</Typography>
      <table className={classes.delaysTable}>
        <thead>
          <tr>
            <th>Interval</th>
            <th>Status</th>
            <th title={titles.duration} className="duration">
              Days
            </th>
            <th title={titles.power}>Power</th>
            <th className={classNames({ [classes.fixedDelay]: !delays.isNormal })}>{fixedDelay}</th>
            <th className={classes.codespace}>Code Space</th>
            <th className={classes.minMax} title={titles.minMax}>
              Min
            </th>
            <th className={classes.minMax} title={titles.minMax}>
              Max
            </th>
          </tr>
        </thead>
        <tbody>
          {delays.steps.map(step => {
            // format a duration specified in days as "D HH:mm:ss" IFF there is a fractional component
            // UGH the fraction is not a number. It is a string disguised as a number :|
            const [days, time] = step.duration.toString().split(".");
            let timeStr = "";
            if (time) {
              timeStr = ` ${time.substring(0, 2) || "00"}:${time.substring(2, 4) || "00"}:${
                time.substring(4, 6) || "00"
              }`;
            }
            const duration = `${days}${timeStr}`;

            return (
              <tr key={step.number}>
                <td>STEP {step.number}</td>
                <td>{step.state}</td>
                <td title={titles.duration} className="duration">
                  {duration}
                </td>
                <td title={titles.power}>{step.state === "ON" && step.power}</td>
                <td colSpan="4" style={{ padding: 0 }}>
                  {step.state === "ON" && (
                    <table style={{ borderCollapse: "collapse" }}>
                      <tbody>
                        {!step.ppmCodespace ? null : (
                          <tr>
                            <td
                              className={classNames({ [classes.fixedDelay]: !delays.isNormal })}
                              title={titles.fixedDelay}
                            >
                              {delays.isNormal ? "" : step.ppmMin === step.ppmMax ? "YES" : "NO"}
                            </td>
                            <td className={classes.codespace} title={titles.codespacePPM}>
                              {step.ppmCodespace}
                            </td>
                            <td className={classes.minMax} title={titles.minMax}>
                              {step.ppmMin}
                            </td>
                            <td className={classes.minMax} title={titles.minMax}>
                              {step.ppmMax}
                            </td>
                          </tr>
                        )}
                        {!step.hrCodespace ? null : (
                          <tr>
                            <td
                              className={classNames({ [classes.fixedDelay]: !delays.isNormal })}
                              title={titles.fixedDelay}
                            >
                              {delays.isNormal ? "" : step.ppmMin === step.ppmMax ? "YES" : "NO"}
                            </td>
                            <td className={classes.codespace} title={titles.codespaceHR}>
                              {step.hrCodespace}
                            </td>
                            <td className={classes.minMax} title={titles.minMax}>
                              {step.hrMin}
                            </td>
                            <td className={classes.minMax} title={titles.minMax}>
                              {step.hrMax}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Typography variant="body2">When finished LOOP back to step {delays.jump}</Typography>
    </div>
  );
}

TransmitterDelaySequence.propTypes = {
  delays: PropTypes.object,
};

export default TransmitterDelaySequence;
