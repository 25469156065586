import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  errorBox: {
    backgroundColor: `${red[50]}`,
    borderRadius: 4,
    border: `1px solid ${red[600]}`,
    display: "flex",
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

function ErrorBox({ children, hidden, style }) {
  const classes = useStyles();
  return (
    hidden && (
      <div style={style} className={classes.errorBox}>
        {children}
      </div>
    )
  );
}

ErrorBox.propTypes = {
  style: PropTypes.object,
  hidden: PropTypes.bool,
};

export default ErrorBox;
