import { Component } from "react";
import DialogWrapper from "./DialogWrapper";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import autoBind from "auto-bind";
import { selectOffset } from "../../redux/user/user-actions";
import { UTC_OFFSETS } from "../../helpers/time";

class UTCOffsetDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: "UTC",
    };
    autoBind(this);
  }

  changeTimezone() {
    const { selection } = this.state;
    this.props.dispatch(selectOffset(selection));
    this.props.onClose();
  }

  getSelectedOffset() {
    this.setState({ selection: this.props.selectedOffset });
  }

  render() {
    const { open, onClose } = this.props;
    const { selection } = this.state;

    return (
      <DialogWrapper
        open={open}
        cancelAction={onClose}
        title={"Change UTC Offset Display Preference"}
        okButtonContent={"Change"}
        okAction={this.changeTimezone}
        okDisabled={selection === this.props.selectedOffset || !selection}
        onEnter={this.getSelectedOffset}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography style={{ marginBottom: 20 }}>
            {" "}
            By default Fathom Central displays all times in UTC and takes input in UTC. You can
            optionally select an UTC offset to display and input times. This is just a display
            preference and can be changed at any time.
          </Typography>
          <Typography>
            Please note that this is a UTC offset not a timezone and <b> does not </b> account for
            daylight savings
          </Typography>
          <Autocomplete
            options={UTC_OFFSETS}
            getOptionLabel={option => option?.name || ""}
            getOptionSelected={option => option?.name === selection}
            renderInput={params => (
              <TextField {...params} label="Select UTC Offset" margin="normal" />
            )}
            value={UTC_OFFSETS.find(offset => offset.name === selection) || ""}
            onChange={(_, option) => this.setState({ selection: option?.name })}
          />
        </div>
      </DialogWrapper>
    );
  }
}

const mapStateToProps = store => ({
  selectedOffset: store.user.selectedOffset,
});

export default connect(mapStateToProps)(UTCOffsetDialog);
