import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import { Delete as IconDelete } from "@material-ui/icons";
import autoBind from "auto-bind/react";
import { WindowedTable } from "../../fathom-brella";
import TableTitleWithIcon from "../common/TableTitleWithIcon";
import DateTimePicker from "../common/DateTimePicker";
import GraphFieldInput from "../common/GraphFieldInput";
import MeasurementInput from "../common/MeasurementInput";
import { detailRowHeight, positionLabels, deploymentSizeUnits } from "./constants";
import { validateFormField, parseFormField } from "../../helpers/common";
import { formatDateTime } from "../../helpers/time";
import InlineEditor from "../common/InlineEditor";
import WarningIcon from "../common/WarningIcon";
import { updatePosition } from "../../redux/deployments/deployments-actions";
import { validateLatitude, validateLongitude } from "./utils";

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  latLonEditFields: {
    paddingTop: theme.spacing(1),
  },
});

const fieldConfig = {
  latitude: {
    dataKey: " ",
    label: positionLabels.latitude.label,
    inputType: "number",
  },
  longitude: {
    dataKey: " ",
    label: positionLabels.longitude.label,
    inputType: "number",
  },
};

class DeploymentPositionsTable extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      editAnchorEl: null, // the anchor element for the edit field popup
      editValue: "", // the current edited value
      editKey: null, // the datakey for the column being edited
      editId: null, // the id of the deployment being edited
      editError: null, // the error message displayed in the edit field popup
    };
  }

  startEdit(el, rowData, dataKey) {
    const editValue =
      dataKey === "latLon"
        ? rowData.latLon || { latitude: "", longitude: "" }
        : dataKey === "depth"
        ? rowData.depth || { value: "", unit: "m" }
        : rowData[dataKey];

    this.setState({
      editAnchorEl: el,
      editValue,
      editKey: dataKey,
      editId: rowData.id,
      editError: null,
    });
  }

  stopEdit() {
    this.setState({ editAnchorEl: null });
  }

  handleEditChange(value) {
    this.setState({ editValue: value });
  }

  handleLatLonChange(newVal, dataKey) {
    this.setState({
      editValue: {
        ...this.state.editValue,
        [dataKey]: newVal,
      },
    });
  }

  submitEdit() {
    const { editValue, editKey, editId } = this.state;
    const inputType = ["start", "end"].includes(editKey) ? "datetime" : "measurement";

    let editError;

    if (editKey === "latLon") {
      const { latitude, longitude } = editValue;
      const missingMsg = "Required field";
      const latError = latitude ? validateLatitude(latitude) : missingMsg;
      const lonError = longitude ? validateLongitude(longitude) : missingMsg;
      if (latError || lonError) {
        editError = { latitude: latError, longitude: lonError };
      }
    } else {
      editError = validateFormField(editValue, inputType, false);
    }

    this.setState({ editError });

    if (!editError) {
      let parsedValue;
      let apiKey = editKey;
      if (editKey === "latLon") {
        apiKey = "manualLatLon";
        parsedValue = {
          latitude: parseFormField("number", editValue.latitude),
          longitude: parseFormField("number", editValue.longitude),
        };
      } else {
        parsedValue = parseFormField(inputType, editValue);
      }

      if (inputType === "measurement" && parsedValue.value === "") {
        parsedValue = null;
      }

      this.props.dispatch(updatePosition(editId, { [apiKey]: parsedValue }));
      this.stopEdit();
    }
  }

  handleDeleteClick(event, position) {
    event.preventDefault();
    event.stopPropagation(); //prevent parent element click event
    this.props.onDeleteClick(position);
  }

  render() {
    const { classes, deploymentPositions, handleAddClick } = this.props;
    const { editAnchorEl, editValue, editKey, editError } = this.state;
    const anyConflicts = deploymentPositions.map(p => p.hasConflict).some(x => x);

    const rows = deploymentPositions.map(de => {
      return {
        ...de,
        latitude: de.latLon ? de.latLon.latitude : null,
        longitude: de.latLon ? de.latLon.longitude : null,
        start: formatDateTime(de.start),
        end: formatDateTime(de.end),
      };
    });

    const columns = [
      {
        width: 40,
        dataKey: "delete",
        renderFn: (_, rowData) => (
          <Tooltip title="Delete deployment position">
            <IconButton size="small" onClick={event => this.handleDeleteClick(event, rowData)}>
              <IconDelete fontSize="small" />
            </IconButton>
          </Tooltip>
        ),
        flexShrink: 1,
        flexGrow: 0,
      },
      {
        dataKey: "latLon",
        label: "Lat / Lon",
        width: 100,
        disableSort: true,
        noEllipses: true,
        renderFn: latLon => (latLon ? `(${latLon.latitude}, ${latLon.longitude})` : ""),
        onEdit: (event, rowData) => this.startEdit(event, rowData, "latLon"),
        editable: true,
      },
      {
        dataKey: "depth",
        label: "Depth",
        width: 65,
        renderFn: depth => (depth ? `${depth.value} ${depth.unit}` : ""),
        onEdit: (event, rowData) => this.startEdit(event, rowData, "depth"),
        editable: true,
        flexShrink: 1,
        flexGrow: 0,
      },
      {
        dataKey: "start",
        label: "Start Time",
        width: 100,
        disableSort: true,
        editable: true,
        noEllipses: true,
        onEdit: (event, rowData) => this.startEdit(event, rowData, "start"),
      },
      {
        dataKey: "end",
        label: "End Time",
        width: 100,
        disableSort: true,
        editable: true,
        noEllipses: true,
        onEdit: (event, rowData) => this.startEdit(event, rowData, "end"),
      },
    ];

    if (anyConflicts) {
      columns.push({
        dataKey: "hasConflict",
        label: "",
        renderFn: hasConflict =>
          hasConflict && <WarningIcon tooltip="Deployment positions overlap" />,
        width: 30,
        disableSort: true,
        noEllipses: true,
      });
    }

    return (
      <div className={classes.root}>
        <div>
          <TableTitleWithIcon
            title={"Deployment Positions"}
            addClick={handleAddClick}
            toolTip={"Add deployment position"}
          />
        </div>
        {deploymentPositions && deploymentPositions.length > 0 && (
          <WindowedTable rows={rows} columns={columns} rowHeight={detailRowHeight} />
        )}
        <InlineEditor anchorEl={editAnchorEl} onSubmit={this.submitEdit} onCancel={this.stopEdit}>
          {editKey === "start" || editKey === "end" ? (
            <div style={{ padding: 10 }}>
              <DateTimePicker
                value={editValue}
                onChange={this.handleEditChange}
                helperText={editError}
                error={Boolean(editError)}
                onSubmit={this.submitEdit}
                onCancel={this.stopEdit}
                autoFocus
                disableFuture={true}
              />
            </div>
          ) : editKey === "depth" ? (
            <MeasurementInput
              valueWidth={40}
              unitWidth={40}
              unitOptions={deploymentSizeUnits}
              unitDefault="m"
              helperText={editError}
              value={editValue}
              error={Boolean(editError)}
              onChange={this.handleEditChange}
              onSubmit={this.submitEdit}
              onCancel={this.stopEdit}
              autoFocus={true}
            />
          ) : (
            <div className={classes.latLonEditFields}>
              <GraphFieldInput
                field={fieldConfig.latitude}
                fieldValue={editValue && editValue.latitude}
                fieldError={editError && editError.latitude}
                handleInput={(_, value) => this.handleLatLonChange(value, "latitude")}
                onSubmit={this.submitEdit}
                onCancel={this.stopEdit}
              />
              <GraphFieldInput
                field={fieldConfig.longitude}
                fieldValue={editValue && editValue.longitude}
                fieldError={editError && editError.longitude}
                handleInput={(_, value) => this.handleLatLonChange(value, "longitude")}
                onSubmit={this.submitEdit}
                onCancel={this.stopEdit}
              />
            </div>
          )}
        </InlineEditor>
      </div>
    );
  }
}

DeploymentPositionsTable.propTypes = {
  deploymentPositions: PropTypes.arrayOf(PropTypes.object),
  handleAddClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default connect()(withStyles(styles)(DeploymentPositionsTable));
